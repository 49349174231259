import * as QuestionCommentsService from '../services/QuestionCommentsService';
import React from 'react';

export const GET_COMMENTS_DATA = "GET_COMMENTS_DATA";
export const GET_QUESTION_DATA = "GET_QUESTION_DATA";
export const UPDATE_STATUS ="UPDATE_STATUS";
export const CLOSED_QUESTION="CLOSED_QUESTION";


export const GetQuestionList = (data) => {

    return (dispatch) => {
        QuestionCommentsService.GetQuestions(data)
            .then((response) => {
                dispatch({ type: GET_QUESTION_DATA, payload: response });
            });
    }
}

export const GetClientQuestion= (data) => {
    return (dispatch) => {
        QuestionCommentsService.GetClientQuestion(data)
            .then((response) => {
                dispatch({ type: GET_QUESTION_DATA, payload: response });
            });
    }
}

export const AddQuestion = (body) => {
    return (dispatch) => {
        QuestionCommentsService.AddQuestion(body)
            .then((response) => {
                dispatch({ type: UPDATE_STATUS, payload: response });
            });
    }
}

export const ClosedQuestion = (id) => {    
    return (dispatch) => {
        QuestionCommentsService.CloseQuestion(id)
            .then((response) => {                
                dispatch({ type: CLOSED_QUESTION, payload: response });
            });
    }
}

export const MarkAsCPAReview = (data) => {
    return (dispatch) => {
        QuestionCommentsService.MarkAsCPAReview(data)
            .then((response) => {                
                dispatch({ type: CLOSED_QUESTION, payload: response });
            });
    }
}

export const MarkAsResolvedQueAndComm = (data) => {
    return (dispatch) => {
        QuestionCommentsService.MarkAsResolvedQueAndComm(data)
            .then((response) => {                
                dispatch({ type: CLOSED_QUESTION, payload: response });
            });
    }
}


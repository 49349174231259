import { GET_COMMENTS, RELOAD, ADD_COMMENT, ADD_CLIENTCOMMENT, GET_HISTORY, COMMENT_MARK_AS_UNREAD } from './../actions/CommentsAction';
const initial_state = {
    CommentsData: null,
    AddComments: null,
    HistoryData: null,
    MarkAdUnread: null
};


export default function authReducer(state = initial_state, action) {
    switch (action.type) {
        case GET_COMMENTS:
            return { ...state, CommentsData: action.payload.data };
        case ADD_COMMENT:
            return { ...state, AddComments: action.payload.data };
        case ADD_CLIENTCOMMENT:
            return { ...state, AddComments: action.payload.data };
        case GET_HISTORY:
            return { ...state, HistoryData: action.payload.data };
        case COMMENT_MARK_AS_UNREAD:
            return { ...state, MarkAdUnread: action.payload.data };
        case RELOAD:
            return { ...state, reload: true };
        default:
            return state;
    }
}
import React, { Component } from 'react';
import IntlMessages from "../../../util/IntlMessages";
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ReportBox from '../../../components/ReportBox/index';
import ContainerHeader from '../../../components/ContainerHeader/index';
import { Check, Warning } from '@material-ui/icons';
import MarketingTable from '../../../components/MarketingTable';
import jwt_decode from 'jwt-decode';
import { FinancialData, ComplianceData } from '../../../components/dashboard/data.js';
import UserDashboard from './UserDashboard.js';
import CompanyDashboard from './CompanyDashboard';
import AccoutantDashboard from './AccoutantDashboard.js';

export default class DashboardPage extends Component {

  render() {

    const user = JSON.parse(localStorage.getItem('userData'));
    let decode = jwt_decode(user.Authorization);
    var role = decode['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];

    if (role == 'Contact') {
      return (<UserDashboard {...this.props} />);
    } else {
      return (<CompanyDashboard {...this.props} />);
      //       return(   <div className="dashboardPage" style={{padding:15}}>

      //      <ContainerHeader match={this.props.match} title={`${user.companyName !== undefined ? `${user.companyName}` : 'Company'}`}/>


      // </div>  )
    }
  }
}

import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import Settings from './Settings';
import Auth from './Auth';
import vendorReducer from './vendorReducer';
import QuickbookReducer from './QuickbookReducer';
import LedgerReducer from './LedgerReducer';
import TrialBalanceReducer from './TrialBalanceReducer';
import CompanyReducer from './companyReducer';
import accountReducer from './accountReducer';
import leaseReducer from './leaseReducer';
import loansReducer from './loansReducer';
import accountantReducer from './accountantReducer';
import insuranceReducer from './insuranceReducer';
import userReducer from './userReducer';
import retirementPlanReducer from './retirementPlanReducer';
import employeeReducer from './employeeReducer';
import reconcillitionReducer from './ReconcillitionReducer';
import companyDasbhoardReducer from './CompanyDashboardReducer';
import reportDataRedcuer from './ReportDataReducer';
import CommentReducer from './commentReducer';
import AccountantDashboardReducer from "./AccountantDashboardReducer";
import BalanceSheetReducer from "./BalanceSheetReducer";
import taxableWagesComputionReducer from "./TaxableWagesComputionReducer";
import QuestionCommentsReducer from "./QuestionCommentsReducer";
import SyncLogsReducer from "./SyncLogsReducer";
import PayrollTaxReducer from "./PayrollTaxReducer";

const reducers = combineReducers({
    routing: routerReducer,
    settings: Settings,
    auth: Auth,
    vendors: vendorReducer,
    quickbook: QuickbookReducer,
    ledgers: LedgerReducer,
    companyReducer: CompanyReducer,
    accounts: accountReducer,
    lease: leaseReducer,
    loans: loansReducer,
    insurance: insuranceReducer,
    accountants: accountantReducer,
    user: userReducer,
    retirementPlan: retirementPlanReducer,
    employee: employeeReducer,
    trialbalance: TrialBalanceReducer,
    reconcillition: reconcillitionReducer,
    companyDashboardData:companyDasbhoardReducer,
    ReportData:reportDataRedcuer,
    CommentData:CommentReducer,
    AccountantDashboardData: AccountantDashboardReducer,
    BalanceSheetData: BalanceSheetReducer,
    TaxableWagesComputionReducer: taxableWagesComputionReducer,
    QuestionCommentsData:QuestionCommentsReducer,
    Synclog:SyncLogsReducer,
    PYTaxData:PayrollTaxReducer    
});

export default reducers;

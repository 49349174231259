import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import TextField from '@material-ui/core/TextField';
import { toast } from 'react-toastify';

class UpdatedByNameModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            updateByNameObj: this.props.updateByNameObj
        }
        console.log('this.props.updateByNameObj:', this.props.updateByNameObj)

    }

    HandleClose = () => {
        this.props.closeUpdateByNameModal();
    };

    handleFormChange = (e, target) => {
        let data = { ...this.state.updateByNameObj };
        data[e.target.name] = e.target.value;
        this.setState({ updateByNameObj: data });
    }

    SaveButtonClick = () => {
        if (this.state.updateByNameObj.createdByName != null && this.state.updateByNameObj.createdByName != '') {
            this.props.getDetailsFromUpdateByNameModal(this.state.updateByNameObj);
        } else {
            toast.error("Required updated by name field.");
        }
    }

    render() {
        return (
            <React.Fragment>
                <Dialog
                    className="comment-Top-align"
                    maxWidth="sm"
                    fullWidth={true}
                    open={true}>
                    <React.Fragment>
                        <div className="Comments modal-header"><span className="log-history-title float-left">Updated By Name </span>
                            <Button className="jr-btn close-btn float-right" onClick={this.HandleClose}><ClearIcon /></Button>
                        </div>
                        <DialogContent>
                            <TextField
                                name="createdByName"
                                onChange={this.handleFormChange}
                                defaultValue={this.state.updateByNameObj.createdByName}
                                margin="normal"
                                placeholder="Updated By Name"
                            />
                            <div>
                                <Button className="jr-btn text-white float-right" style={{ backgroundColor: '#f0ca4c' }} onClick={this.SaveButtonClick}>SAVE</Button>
                            </div>
                        </DialogContent>
                    </React.Fragment>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default UpdatedByNameModal;
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import CommonComments from "./../../../app/common/Comments";
import PaginationComponent from "./../../../../src/app/common/Pagination";
import { ReportType, ModuleType, makeLinkForQuickbooks, Base64, numberWithCommas,LinkType } from "./../../../app/common/commonServices";
import moment from 'moment';
import { bindActionCreators } from 'redux';
import CircularProgress from './../../../components/CircularProgress';
import { GET_REPORT_DATA_BES, UPDATE_DESCRIPTION_DATA_BES ,MARK_IGNORED_DATA_BES,Revert_Ignored_DATA_BES} from "./../../../appRedux/actions/ReportDetailAction";
import { toast } from 'react-toastify';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from 'react-router-dom';
import VimeoInstructionLoader from './../../../app/common/VimeoInstructionLoader'
import queryString from 'query-string';
import RefreshDataModal from '../../../components/RefreshDataModal'

class MissingDescription_Embedded extends Component {

    constructor(props) {
        super(props);

        this.state = {
            parantData: this.props.location.state ? this.props.location.state : null,
            MissingData: [],
            companyId: 0,
            sortField: 'date',
            sortFormat: "D",
            PageNo: 1,
            PageSize: 10,
            ColorCode: "#e27b40",
            IsCommentModelOpen: false,
            sDate: new Date(2019, 0, 1),
            eDate: new Date(),
            loading: false,
            TotalRecords: 0,
            CommentReferenceId: 0,
            editData: null,
            editIndex: -1,
            editedBy: "",
            IsEmbeddedPage: true,
            HomeLink: "",
            SelectALL: false,
            SelectedRecords: [],
            IsIgnoredFilter:false,
            ShowIgnorerInput: false,
            IgnorerName: null,
            companyName: "",
            isRefreshDataModalOpen:false
        }

    }


    onSortChange = (fieldName) => {
        if (this.state.sortField == fieldName) {
            let formate = this.state.sortFormat == 'A' ? 'D' : 'A';
            this.setState({ sortFormat: formate, loading: true,ShowIgnorerInput: false }, () => {
                this.GetReportData();
            });
        } else {
            this.setState({ sortField: fieldName, sortFormat: 'A', loading: true ,ShowIgnorerInput: false}, () => {
                this.GetReportData();
            });
        }
    }

    componentDidMount() {
        let value = queryString.parse(this.props.location.search);

        // localStorage.setItem("homeURL", window.location.href.replace(window.location.origin, ""));
        let dashboardURl = localStorage.getItem("homeURL");
        this.setState({
            loading: true,
            HomeLink: dashboardURl ? dashboardURl : "?q="+ value.q + "&p=" + value.p
        }, () => {
            this.getCompanyId();
        });
    }

    getCompanyId = () => {
        let id = 0;
        let value = queryString.parse(this.props.location.search);
        localStorage.setItem("q", JSON.stringify(value));
        let data = localStorage.getItem("q");
        if (data != null && data != undefined) {
            data = JSON.parse(data);
            this.setState({
                companyId: parseInt(Base64.decode(data.q)),
                companyName:Base64.decode(data.p),
                loading: true,
                ShowIgnorerInput: false
            }, () => {
                this.GetReportData();
            });
        }
        else {
            this.setState({ loading: false });
        }
    }


    ChangePageSize = (size) => {

        this.setState({ loading: true, PageSize: parseInt(size), PageNo: 1 }, () => {
            this.GetReportData();
        });
    }

    ChangePage = (page) => {
        this.setState({ loading: true, PageNo: page, }, () => {
            this.GetReportData();
        });
    }

    LoadComments = (e, item) => {
        e.stopPropagation();
        this.setState({ IsCommentModelOpen: true, CommentReferenceId: item.id });
    }

    CloseComments = () => {
        this.setState({ IsCommentModelOpen: false });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.ReportData !== this.props.ReportData) {
            if (nextProps.ReportData.transactionStatus === 0) {
                var totalrecords = 0;
                if (nextProps.ReportData.returnObject != null && nextProps.ReportData.returnObject.length > 0) {
                    totalrecords = nextProps.ReportData.returnObject[0].totalrows
                }
                this.setState({ 
                    MissingData: nextProps.ReportData.returnObject, TotalRecords: totalrecords, PageSize: this.state.PageSize, PageNo: this.state.PageNo, loading: false, SelectALL: false, SelectedRecords: [],
                    ShowIgnorerInput: false, IgnorerName: null
                });
            }
            else {
                toast.error(nextProps.ReportData.resultMsg);
                this.setState({ loading: false });
            }
        }

        if (nextProps.UpdateDescription !== this.props.UpdateDescription) {
            if (nextProps.UpdateDescription.transactionStatus === 0) {
                this.setState({ 
                    loading: true, editData: null, editIndex: -1,PageNo:1, SelectALL: false, SelectedRecords: [],
                    ShowIgnorerInput: false, IgnorerName: null
                }, () => {
                    toast.success(`${nextProps.UpdateDescription.resultMsg}`);
                    this.GetReportData();
                });
            }
            else {
                this.setState({ loading: false, SelectedRecords: [],ShowIgnorerInput: false, IgnorerName: null, SelectALL: false}, () => {
                    toast.error(`${nextProps.UpdateDescription.resultMsg}`);
                });
            }
        }
    }

    refreshData = () => {
        if (!this.state.loading) {
            this.setState({ loading: true,isRefreshDataModalOpen:false }, () => {
                this.GetReportData(true);
            });
        }
    }

    onRefreshDataClick = () => {
        
        this.setState({isRefreshDataModalOpen:true})
    }

    handleRefreshDataModal=()=>{
        this.setState({isRefreshDataModalOpen:false})
      }

    OnApplyIgnored = () => {
        let selectedData = this.state.SelectedRecords;
        if (selectedData == null || selectedData.length === 0) {
            toast.error("Please select atleast 1 record for processing request");
            return false;
        }

        if(!this.state.IsIgnoredFilter)
        {
            let ignorer = this.state.IgnorerName;
            if (ignorer == null || ignorer.trim().replace(/\s/g, '') === "") {
                toast.error("Please enter the ignorer name");
                return false;
            }
        }

        this.setState({ loading: true }, () => {
            if(this.state.IsIgnoredFilter)
            {
                let body1 = {
                    "ids": selectedData,
                    "reportType": ReportType.MissingDiscription,
                    "companyID": this.state.companyId,
                }
                this.props.Revert_Ignored_DATA_BES(body1);                
            }
            else
            {
                let body2 = {
                    "ids": selectedData,
                    "reportType": ReportType.MissingDiscription,
                    "companyID": this.state.companyId,
                    "createdByName" : this.state.IgnorerName,
                    "isClient":true
                }
                this.props.MARK_IGNORED_DATA_BES(body2);                
            }      
        });
    }

    onCheckboxClick = (e, item) => {
        e.stopPropagation();


        let existInArray = false;
        let data = this.state.SelectedRecords;
        if (data != null && data.length > 0 && data.indexOf(item.id) > -1) {
            existInArray = true;
        }

        if (e.target.checked) {
            if (!existInArray) {
                data.push(item.id);
            }
        }
        else {
            if (existInArray) {
                data.splice(data.indexOf(item.id), 1);
            }
        }

        let all = (data.length == this.state.MissingData.length) ? true : false;
        let showinput = data.length > 0 ? true : false;
        this.setState({ SelectedRecords: data,ShowIgnorerInput: showinput, SelectALL: all, editIndex: -1 });

    }

    SelectALL = (e) => {
        if (this.state.MissingData.length > 0) {
            if (e.target.checked) {
                let all = [];
                let data = this.state.MissingData;
                for (let i = 0; i < data.length; i++) {
                    if(data[i].amount <50) {
                        all.push(data[i].id);
                    }
                   
                }
                this.setState({ SelectALL: true, SelectedRecords: all,ShowIgnorerInput: true });
            }
            else {
                this.setState({ SelectALL: false, SelectedRecords: [],ShowIgnorerInput: false });
            }
        }
    }

    HandleIgnored = (event) => {
        
        this.setState({ IsIgnoredFilter: (event.target.value=="true"?true:false),loading:true },()=>{ this.GetReportData()});
    }

    handleIgnoredBy = (e) => {
        this.setState({ IgnorerName: e.target.value });
    }

    GetReportData(IsRefresh = false) {
        let state = this.state;
        let body = {
            companyID: state.companyId,
            reportType: ReportType.MissingDiscription,
            fromDate: moment(state.sDate).format("MM/DD/YYYY"),
            toDate: moment(state.eDate).format("MM/DD/YYYY"),
            sortField: state.sortField,
            sortFormat: state.sortFormat,
            pageNumber: state.PageNo,
            pageSize: state.PageSize,
            needUpdatQBORecords: IsRefresh,
            isAllIgnoreUnignore:state.IsIgnoredFilter
        };

        this.props.GET_REPORT_DATA_BES(body);
    }


    onLinkClick = (e, rowData) => {
        e.stopPropagation();
        let origin = makeLinkForQuickbooks();
        window.open(origin + rowData.transactionLink);
    }


    onRowSave = (index, data) => {
        let editedData = this.state.editData;
        if (editedData.memoOrDescription == null || editedData.memoOrDescription.trim() == "") {
            toast.error(`Please enter description`);
            return false;
        }
        let user = this.state.editedBy;
        if (user == null || user.trim() == "" || user.replace(/\s/g, '') == "") {
            toast.error(`Please enter your name`);
            return false;
        }

        let body =
        {
            "id": data.id,
            "memoOrDescription": editedData.memoOrDescription,
            "reportType": ReportType.MissingDiscription,
            "createdByName": user
        }

        this.setState({
            loading: true
        }, () => {
            this.props.UPDATE_DESCRIPTION_DATA_BES(body);
        });
    }

    onRowCancel = (rowIndex, rowData) => {
        rowData.memoOrDescription = null;
        this.setState({ editData: null, editIndex: -1 });
    }

    onRowEdit = (j, rowData) => {
        this.setState({ editData: rowData, editIndex: j });
    };


    ChangeDescription = (e, index, rowdata) => {
        var data = this.state.editData;
        data.memoOrDescription = e.target.value
        this.setState({ editData: data });
    }

    GetEditBy = (e) => {
        this.setState({ editedBy: e.target.value });
    }

    UpdateItemCommentCount = (id) => {
        let data = this.state.MissingData;
        data.forEach(function (item) {

            if (item.id === id) {
                item.commentCount++
            }
        }); 

        this.setState({ MissingData: data });
    }

    render() {

        let selectedItem = this.state.SelectedRecords;
        return (
            <React.Fragment>
                <RefreshDataModal visible={this.state.isRefreshDataModalOpen}
                 handlePopUpClose={this.handleRefreshDataModal} refreshData={this.refreshData}/>
                {this.state.IsCommentModelOpen ?
                    <CommonComments
                        CloseComments={this.CloseComments}
                        ColorCode={this.state.ColorCode}
                        ModuleType={ModuleType.GeneralReportType}
                        ReportType={ReportType.MissingDiscription}
                        CompanyId={this.state.companyId}
                        RecordId={this.state.CommentReferenceId}
                        IsEmbeddedPage={this.state.IsEmbeddedPage}
                        UpdateItemCommentCount={this.UpdateItemCommentCount}
                    >
                    </CommonComments> : ""}                
                    <div className="dashboardPage embeded-dashboard">
                        <ToastContainer autoClose={3000} />
                        {this.state.loading ? <CircularProgress></CircularProgress>
                            :
                            <React.Fragment>
                                <div className="  page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
                                    <h2 className="mb-3 mb-sm-0">{this.state.companyName} | {LinkType.TranMissDescription}</h2>
                                    <Link className="home-link" to={this.state.HomeLink} > Home </Link>
                                </div>
                                <div className="padding-box app-wrapper bgcolor">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="float-right">
                                            {/* <span> <b> Show Previously Ignored Transactions  </b> : </span>
                                                <select style={{ backgroundColor: "#fff", marginRight: "10px" }} className="select-box arrow-box" onChange={this.HandleIgnored} value={this.state.IsIgnoredFilter} >
                                                    <option value={true}> YES </option>
                                                    <option value={false}> NO </option>                                                    
                                                </select> */}
                                               
                                                <Button variant="raised" className=" tr-bgcolor orangeColor jr-btn bg-teal text-white refresh-btn" onClick={() => this.onRefreshDataClick()} >
                                                    <i className="zmdi zmdi-refresh "></i> Refresh Data</Button>
                                                <VimeoInstructionLoader 
                                                   //URL={"https://vimeo.com/687412271"} 
                                                   URL={"https://player.vimeo.com/video/687412271?h=df22da040e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"} 
                                                   buttonName="Instructions"
                                                   buttonColor="orangeColor"
                                                   PageName={LinkType.TranMissDescription}
                                                ></VimeoInstructionLoader>
                                            </div>
                                        </div>
                                    </div>
                                    {/* {this.state.ShowIgnorerInput && !this.state.IsIgnoredFilter?
                                        <div className="row" style={{ marginTop: "20px" }}>
                                            <div className="col-md-12">
                                                <div className="float-right">
                                                    <label><strong> Ignored By </strong></label> &nbsp;&nbsp;
                                            <input type="text" placeholder="enter your name" onChange={this.handleIgnoredBy} />
                                                </div>
                                            </div>
                                        </div>
                                        : ""} */}


                                <div class="row mt-2">
                                    <div className="col-md-12">
                                        <div className='d-flex justify-content-end align-items-center'>
                                            <div className='show-previously-box'>
                                                <div class="form-group d-flex mb-0">                                            
                                                    <h4 for="staticEmail" class="col-form-label">Show Previously Ignored Transactions:</h4>
                                                    <div class="dropdown-input-box">
                                                        <select style={{ backgroundColor: "#fff", marginRight: "10px" }} className="select-box arrow-box" onChange={this.HandleIgnored} value={this.state.IsIgnoredFilter} >
                                                            <option value={true}> YES </option>
                                                            <option value={false}> NO </option>                                                    
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=''>
                                                <div class="form-group d-flex mb-0 align-items-center"> 
                                                {this.state.ShowIgnorerInput && !this.state.IsIgnoredFilter?
                                                     <React.Fragment>
                                                      <h4 for="staticEmail" class="col-form-label">Ignored By:</h4>
                                                      <div className='ignore-by-box'>
                                                      <input type="text" placeholder="enter your name" onChange={this.handleIgnoredBy} />
                                                      </div>
                                                      </React.Fragment>
                                                    : ""}
                                                   
                                                <Button variant="raised" className=" jr-btn graycolor text-white refresh-btn" onClick={() => this.OnApplyIgnored()} >{this.state.IsIgnoredFilter?"Revert":"Ignore"}</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    <div className="table-responsive-material">
                                        <table className="table tablebox account-reconcilliation">
                                            <thead>
                                                <tr className="orangeColor">
                                                    <th className={`td-width ${this.state.sortField == 'date' ? this.state.sortFormat == 'A'
                                                        ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('date')}><p className="table-th-title"> Date</p> </th>
                                                    <th className={`td-width ${this.state.sortField == 'transactionType' ? this.state.sortFormat == 'A'
                                                        ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('transactionType')}><p className="table-th-title"> Type</p></th>
                                                    <th className={`td-width ${this.state.sortField == 'num' ? this.state.sortFormat == 'A'
                                                        ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('num')}><p className="table-th-title"> Number</p></th>
                                                    <th className={`td-width ${this.state.sortField == 'name' ? this.state.sortFormat == 'A'
                                                        ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('name')}><p className="table-th-title">Name</p></th>
                                                    <th className={`td-width ${this.state.sortField == 'account' ? this.state.sortFormat == 'A'
                                                        ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('account')}><p className="table-th-title"> A/C No.</p></th>
                                                    {/* <th className={`td-width ${this.state.sortField == 'memoOrDescription' ? this.state.sortFormat == 'A'
                                                        ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('memoOrDescription')}><p className="table-th-title"> Memo/Des.</p></th> */}
                                                    <th className={`td-width ${this.state.sortField == 'amount' ? this.state.sortFormat == 'A'
                                                        ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('amount')}><p className="table-th-title"> Amount</p></th>
                                                   
                                                    <th className="td-width-90 text-center">
                                                        <label className="container101 header-check ">
                                                            {this.state.SelectALL ?
                                                                <input className="second-check-box" checked="checked" type="checkbox" onClick={(e) => this.SelectALL(e)} />
                                                                :
                                                                <input className="second-check-box" type="checkbox" onClick={(e) => this.SelectALL(e)} />
                                                            }
                                                            <span className="checkmark101 white_border"></span>
                                                        </label>
                                                    </th>
                                                    <th className="td-width-90 text-center"><img src="/vendors/WhiteCommnet.png" alt="comments" /></th>
                                                    <th className="td-width-90 text-center "><img src="/vendors/Link-arrow_header.png" alt="link" /></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(this.state.MissingData && this.state.MissingData.length > 0) && this.state.MissingData.map((item, j) => (
                                                    <React.Fragment key={j}>
                                                        {<tr onClick={(e) => this.onRowEdit(j, item)}>
                                                            <td>{item.date}</td>
                                                            <td>{item.transactionType}</td>
                                                            <td>{item.num}</td>
                                                            <td>{item.name}</td>
                                                            <td>{item.account}</td>
                                                            {/* <td>{item.memoOrDescription}</td> */}
                                                            <td className="text-right">{numberWithCommas(item.amount)}</td>
                                                            <td className="text-center">
                                                                {(item.amount >= 50?
                                                                    
                                                                    // <label className="container101">
                                                                    //     <input className="second-check-box" type="checkbox" disabled />
                                                                    //     <span className="checkmark101" style={{backgroundColor:"#c1c9cd"}}></span>
                                                                    // </label> 
                                                                    ""
                                                                    :
                                                                    <label className="container101">
                                                                        {(this.state.SelectALL || selectedItem.length > 0 && (selectedItem.indexOf(item.id) > -1)) ?
                                                                            <input className="second-check-box" checked="checked" type="checkbox" onClick={(e) => this.onCheckboxClick(e, item)} />
                                                                            :
                                                                            <input className="second-check-box" type="checkbox" onClick={(e) => this.onCheckboxClick(e, item)} />
                                                                        }
                                                                        <span className="checkmark101"></span>
                                                                    </label>
                                                                    
                                                                )}
                                                            </td>
                                                            <td className="text-center">
                                                                <div className="panel-commentCount">
                                                                    <img src="/vendors/Vector Smart Object.png" alt="comments" className={item.commentCount === 0 ? "disableCommentsOpacity" : ""} onClick={(e) => this.LoadComments(e, item)}></img>
                                                                    {item.unreviewCommentCount !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">{item.unreviewCommentCount}</span>}
                                                                </div>
                                                            </td>
                                                            <td className="text-center"><img src="/vendors/Shape 1.png" alt="link" onClick={(e) => this.onLinkClick(e, item)}></img></td>
                                                        </tr>}
                                                        {this.state.editIndex === j &&
                                                            <tr >
                                                                <td> <strong>Memo/Description </strong></td>
                                                                <td colSpan="2">
                                                                    <textarea cols="50" value={this.state.editData.memoOrDescription} onChange={(e) => this.ChangeDescription(e, j, item)}></textarea>
                                                                </td>
                                                                <td> <strong>Updated By </strong></td>
                                                                <td >
                                                                    <input type="text" onChange={(e) => this.GetEditBy(e, j, item)} />
                                                                </td>

                                                                <td colSpan="3">
                                                                    <div className="col-md-12">
                                                                        <Button variant="raised" className="jr-btn jr-btn-sm bg-teal text-white" onClick={() => this.onRowSave(j, item)}>SAVE</Button>
                                                                        <Button variant="raised" className="jr-btn jr-btn-sm bg-danger text-white" onClick={() => this.onRowCancel(j, item)}>CANCEL</Button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        }
                                                    </React.Fragment>
                                                ))}
                                                {this.state.MissingData.length == 0 ?
                                                    <tr>
                                                        <td className="text-center" colSpan="9">
                                                        No issues here! Keep up the good work!
                                                    </td>
                                                    </tr>
                                                    : ""}
                                            </tbody>
                                        </table>
                                    </div>
                                    <PaginationComponent
                                        selectedPageNo={this.state.PageNo}
                                        selectedSize={this.state.PageSize}
                                        TotalRecords={this.state.TotalRecords}
                                        ChangePageSize={this.ChangePageSize}
                                        ChangePage={this.ChangePage}
                                        CurrentRecords={this.state.MissingData.length}
                                    >
                                    </PaginationComponent>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ReportData: state.ReportData.ReportData,
        UpdateDescription: state.ReportData.UpdateDescription
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        GET_REPORT_DATA_BES,
        UPDATE_DESCRIPTION_DATA_BES,
        MARK_IGNORED_DATA_BES,
        Revert_Ignored_DATA_BES
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(MissingDescription_Embedded);
import { VENDOR_GET, VENDOR_TYPES_GET,GET_DONOTISSUE_VALUES, ENTITY_TYPES_GET, WAGES_LIST_GET, WAGES_UPDATE,VENDOR_ADD,ATTACHMENT_UPLOAD,GET_VENDOR_HISTORY } from '../actions/vendorAction';
const initial_state = {
    vendorTypes: [],
    vendors: [],
    entityTypes: [],
    wagesList: null,
    wagesUpdate: null,
    vendorAdd:null,
    attachmentupload:null,
    vendorhistory:null
};
export default function authReducer(state = initial_state, action) {
    switch (action.type) {
        case VENDOR_GET:
            return {
                ...state,
                vendors: action.payload.data, reload: false
            };
        case VENDOR_TYPES_GET:
            return {
                ...state,
                vendorTypes: action.payload.data
            };
        case GET_DONOTISSUE_VALUES:
                return {
                    ...state,
                    doNotIssueDeopdownValues: action.payload.data
                };    
        case ENTITY_TYPES_GET:
            return {
                ...state,
                entityTypes: action.payload.data
            }
        case WAGES_LIST_GET:
            return {
                ...state,
                wagesList: action.payload.data, reload: false
            };
        case WAGES_UPDATE:
            return {
                ...state,
                wagesUpdate: action.payload.data
            };
        case VENDOR_ADD:
            return {
                ...state,
                vendorAdd: action.payload.data
            };
        case ATTACHMENT_UPLOAD:
            return {
                ...state,
                attachmentupload: action.payload.data
            };
        case GET_VENDOR_HISTORY:
            return {
                ...state,
                vendorhistory: action.payload.data
            };
        case 'RELOAD':
            return { ...state, reload: true };
        default:
            return state;
    }
}

import React, { Component } from 'react';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { ReportType, Base64, makeLinkForQuickbooks, ModuleType,LinkType } from "./../../../app/common/commonServices";
import CommonComments from "./../../../app/common/Comments";
import PaginationComponent from "./../../../app/common/Pagination";
import moment from 'moment';
import { bindActionCreators } from 'redux';
import CircularProgress from './../../../components/CircularProgress';
import { GET_CompanyDashboard_PayrollVendorReportDetailList_BES, UpdateVendorNamesDiscrepancy, MarkAsIgnoreForVendor } from "./../../../appRedux/actions/ReportDetailAction";
import { toast } from 'react-toastify';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from 'react-router-dom';
import ClearIcon from '@material-ui/icons/Clear';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import queryString from 'query-string';
import RefreshDataModal from '../../../components/RefreshDataModal'


class VendorDiscrepancies_Embedded extends Component {
    constructor(props) {
        super(props);
        this.updateByNameObj = null;
        let endDate = new Date();
        let startDate = new Date(endDate.getFullYear(), 0, 1);

        if (endDate.getMonth() <= 2) {
            startDate = new Date(startDate.getFullYear() - 1, 0, 1);
            endDate = new Date(endDate.getFullYear() - 1, 11, 31);
        }
        this.state = {
            parantData: this.props.location.state ? this.props.location.state : null,
            MissingData: [],
            companyId: 0,
            sortField: 'name',
            sortFormat: "A",
            PageNo: 1,
            PageSize: 10,
            ColorCode: "#f0ca4c",
            IsCommentModelOpen: false,
            sDate: startDate,// new Date(2019, 0, 1),
            eDate: endDate,// new Date(),
            loading: false,
            TotalRecords: 0,
            CommentReferenceId: 0,
            IsEmbeddedPage: true,
            HomeLink: "",
            ShowGuidlines: false,
            editData: null,
            editIndex: -1,
            editedBy: null,
            SelectedRecords: [],
            companyName: "",
            isRefreshDataModalOpen:false
        }
    }


    OnShowInstruction = () => {
        this.setState({ ShowGuidlines: true });
    }

    CloseInstruction = () => {
        this.setState({ ShowGuidlines: false });
    }


    onSortChange = (fieldName) => {
        if (this.state.sortField == fieldName) {
            let formate = this.state.sortFormat == 'A' ? 'D' : 'A';
            this.setState({ sortFormat: formate, loading: true }, () => {
                this.GetReportData();
            });
        } else {
            this.setState({ sortField: fieldName, sortFormat: 'A', loading: true }, () => {
                this.GetReportData();
            });
        }
    }

    refreshData = () => {
        if (!this.state.loading) {
            this.setState({ loading: true,isRefreshDataModalOpen:false }, () => {
                this.GetReportData(true);
            });
        }
    }

    onRefreshDataClick = () => {
        
        this.setState({isRefreshDataModalOpen:true})
    }

    handleRefreshDataModal=()=>{
        this.setState({isRefreshDataModalOpen:false})
      }

    ChangePageSize = (size) => {

        this.setState({ loading: true, PageSize: parseInt(size), PageNo: 1 }, () => {
            this.GetReportData();
        });
    }

    ChangePage = (page) => {
        this.setState({ loading: true, PageNo: page, }, () => {
            this.GetReportData();
        });
    }


    GetReportData(IsRefresh = false) {
        let state = this.state;
        let body = {
            companyID: state.companyId,
            reportType: ReportType.Resolve1099VendorNameDiscrepancies,
            fromDate: moment(state.sDate).format("MM/DD/YYYY"),
            toDate: moment(state.eDate).format("MM/DD/YYYY"),
            sortField: state.sortField,
            sortFormat: state.sortFormat,
            pageNumber: state.PageNo,
            pageSize: state.PageSize,
            needUpdatQBORecords: IsRefresh
        };

        this.props.GET_CompanyDashboard_PayrollVendorReportDetailList_BES(body);
    }

    getCompanyId = () => {
        let value = queryString.parse(this.props.location.search);
        localStorage.setItem("q", JSON.stringify(value));
        let data = localStorage.getItem("q");

        if (data != null && data != undefined) {
            data = JSON.parse(data);
            this.setState({
                companyId: parseInt(Base64.decode(data.q)),
                companyName:Base64.decode(data.p),
                loading: true
            }, () => {
                this.GetReportData();
            });
        }
        else {
            this.setState({ loading: false });
        }
    }

    onLinkClick = (rowData) => {
        let origin = makeLinkForQuickbooks();
        window.open(origin + rowData.transactionLink);
    }

    LoadComments = (item) => {
        this.setState({ IsCommentModelOpen: true, CommentReferenceId: item.vendorID });
    }

    UpdateItemCommentCount = (id) => {
        let item = this.state.MissingData;
        item.forEach(function (records) {
            if (records.vendorID === id) {
                records.commentCount++
            }

        });

        this.setState({ lst_GL: item });
    }

    CloseComments = () => {
        this.setState({ IsCommentModelOpen: false });
    }

    OnIgnoreRecords = () => {

        let selectedData = this.state.SelectedRecords;
        if (selectedData == null || selectedData.length === 0) {
            toast.error("Please select atleast 1 record for processing request");
            return false;
        }

        let ignoreBy = this.state.editedBy;

        if (ignoreBy == null || ignoreBy.trim() === "") {
            toast.error("Please enter the name of Ignore By");
            return false;
        }


        let body = {
            "ids": selectedData,
            "reportType": ReportType.Resolve1099VendorNameDiscrepancies,
            "companyID": this.state.companyId,
            "createdByName": ignoreBy,
        }

        this.setState({ loading: true }, () => {
            this.props.MarkAsIgnoreForVendor(body);
        });
    }

    SelectALL = (e) => {

        if (this.state.MissingData.length > 0) {
            if (e.target.checked) {
                let all = [];
                let data = this.state.MissingData;
                for (let i = 0; i < data.length; i++) {
                    all.push(parseInt(data[i].listID));
                }
                this.setState({ SelectALL: true, SelectedRecords: all });
            }
            else {
                this.setState({ SelectALL: false, SelectedRecords: [] });
            }
        }
    }

    onCheckboxClick = (e, item) => {
        e.stopPropagation();

        let existInArray = false;
        let data = this.state.SelectedRecords;
        if (data != null && data.length > 0 && data.indexOf(parseInt(item.listID)) > -1) {
            existInArray = true;
        }

        if (e.target.checked) {
            if (!existInArray) {
                data.push(parseInt(item.listID));
            }
        }
        else {
            if (existInArray) {
                data.splice(data.indexOf(parseInt(item.listID)), 1);
            }
        }
        let all = (data.length === this.state.MissingData.length) ? true : false;

        this.setState({ SelectedRecords: data, SelectALL: all });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.ReportData !== this.props.ReportData) {
            if (nextProps.ReportData.transactionStatus === 0 && nextProps.ReportData.returnObject != null) {
                var totalrecords = 0;
                if (nextProps.ReportData.returnObject != null && nextProps.ReportData.returnObject.length > 0) {
                    totalrecords = nextProps.ReportData.returnObject[0].totalrows
                }
                this.setState({
                    MissingData: nextProps.ReportData.returnObject, TotalRecords: totalrecords, PageSize: this.state.PageSize, PageNo: this.state.PageNo, loading: false
                });
            }
            else {
                this.setState({ loading: false }, () => {
                    toast.error(nextProps.ReportData.resultMsg);
                });
            }
        }

        if (nextProps.UpdateDescription !== this.props.UpdateDescription) {
            if (nextProps.UpdateDescription.transactionStatus === 0) {
                this.setState({ loading: true, editData: null, editIndex: -1 }, () => {
                    toast.success(`${nextProps.UpdateDescription.resultMsg}`);
                    this.GetReportData();
                });
            }
            else {
                this.setState({ loading: false, }, () => {
                    toast.error(`${nextProps.UpdateDescription.resultMsg}`);
                });
            }
        }
    }

    componentDidMount() {
        let value = queryString.parse(this.props.location.search);

        // localStorage.setItem("homeURL", window.location.href.replace(window.location.origin, ""))
        let dashboardURl = localStorage.getItem("homeURL");
        this.setState({
            loading: true,
            HomeLink: dashboardURl ? dashboardURl : "?q="+ value.q + "&p=" + value.p,
        }, () => {
            this.getCompanyId();
        });
    }

    onRowSave = (index, data) => {
        let editedData = this.state.editData;
        if (editedData.displayName == null || editedData.displayName.trim() == "") {
            toast.error(`Please enter display name`);
            return false;
        }

        if (editedData.companyName == null || editedData.companyName.trim() == "") {
            if (editedData.name == null || editedData.name.trim() == "") {
                toast.error(`Please enter first name`);
                return false;
            }

            if (editedData.familyName == null || editedData.familyName.trim() == "") {
                toast.error(`Please enter last name`);
                return false;
            }
        }

        if (editedData.createdByName == null || editedData.createdByName.trim() == "") {
            toast.error(`Please enter update by name`);
            return false;
        }

        let body =
        {
            "companyID": this.state.companyId,
            "vendorID": data.vendorID,
            "listID": data.listID,
            "firstName": editedData.name,
            "lastName": editedData.familyName,
            "companyName": editedData.companyName,
            "displayName": editedData.displayName,
            "createdByName": editedData.createdByName,
            "middleName": editedData.middleName,
            "suffix": editedData.suffix,
            "title": editedData.title,
        }
        this.setState({
            loading: true
        }, () => {
            this.props.UpdateVendorNamesDiscrepancy(body);
        });
    }

    onRowEdit = (j, rowData) => {
        var originaldata = {
            name: rowData.name,
            familyName: rowData.familyName,
            companyName: rowData.companyName,
            displayName: rowData.displayName,
            "createdByName": null,
            title: rowData.title,
            middleName: rowData.middleName,
            suffix: rowData.suffix,
        };
        this.setState({ editData: originaldata, editIndex: j });
    };
    onRowCancel = (rowIndex, rowData) => {
        this.setState({ editData: null, editIndex: -1 });
    }

    UpdateData = (e, i, item, field) => {
        var data = this.state.editData;
        if (data == null) {
            data = {
                Name: item.name,
                familyName: item.familyName,
                companyName: item.companyName,
                displayName: item.displayName,
                "createdByName": null,
                title: item.title,
                middleName: item.middleName,
                suffix: item.suffix,
            };
        }
        if (field === "name") {
            data.name = e.target.value;
        }
        else if (field === "familyName") {
            data.familyName = e.target.value;
        }
        else if (field === "companyName") {
            data.companyName = e.target.value;
        }
        else if (field === "displayName") {
            data.displayName = e.target.value;
        }
        else if (field === "createdByName") {
            data.createdByName = e.target.value;
        }
        else if (field === "title") {
            data.title = e.target.value;
        }
        else if (field === "middleName") {
            data.middleName = e.target.value;
        }
        else if (field === "suffix") {
            data.suffix = e.target.value;
        }

        this.setState({ editData: data });
    }

    handleApprovedBy = (e) => {
        this.setState({ editedBy: e.target.value });
    }


    render() {

        let selectedItem = this.state.SelectedRecords;
        return (
            <div className="dashboardPage embeded-dashboard">
                <RefreshDataModal visible={this.state.isRefreshDataModalOpen}
                 handlePopUpClose={this.handleRefreshDataModal} refreshData={this.refreshData}/>
                {this.state.IsCommentModelOpen ? <CommonComments
                    CloseComments={this.CloseComments}
                    ColorCode={this.state.ColorCode}
                    ModuleType={ModuleType.Vendors}
                    ReportType={ReportType.Resolve1099VendorNameDiscrepancies}
                    CompanyId={this.state.companyId}
                    RecordId={this.state.CommentReferenceId}
                    IsEmbeddedPage={this.state.IsEmbeddedPage}
                    UpdateItemCommentCount={this.UpdateItemCommentCount}
                ></CommonComments> : ""}

                {this.state.ShowGuidlines &&
                    <Dialog
                        className="comment-Top-align"
                        maxWidth="sm"
                        fullWidth={true}
                        open={true}>
                        <React.Fragment>
                            <div className="Comments modal-header"><span className="log-history-title float-left">Guideline </span>
                                <Button className="jr-btn close-btn float-right" onClick={this.CloseInstruction}><ClearIcon /></Button>
                            </div>
                            <DialogContent style={{ margin: "8px" }}>
                                <p >
                                    <b>Note</b> : It is important that the vendor information in QuickBooks match the checks you issue to the vendor.
                                </p>
                                <p >
                                    <b>Rule #1 </b> - The display name should match the payee name on the checks issued to the vendor.
                                </p>
                                <p >
                                    <b>Rule #2 </b> - If the vendor is a person, the display name should match the First and Last Name.
                                </p>
                                <p >
                                    <b>Rule #3 </b> - If the vendor is a company, checks should be made payable to the company name.  The company name should be included in the appropriate field of the vendor record.  You may include a first and last name for the primary contact purposes only. But do not issue checks payable to a person (e.g. John Doe) and list it in QB as paid to a company (e.g. ABC Exterminating).
                                </p>
                            </DialogContent>
                        </React.Fragment>
                    </Dialog>

                }
                <div className=" page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
                    <h2 className="mb-3 mb-sm-0">{this.state.companyName} | {LinkType.Resolve1099VendorNameDiscrepancies}</h2>
                    <Link className="home-link" to={this.state.HomeLink} > Home </Link>
                </div>
                {this.state.loading ? <CircularProgress></CircularProgress>
                    :
                    <React.Fragment>
                        <div className="padding-box app-wrapper bgcolor">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="float-right">
                                                {/* <Button variant="raised" className="graycolor jr-btn text-white refresh-btn" onClick={() => this.OnIgnoreRecords()}  > Ignore</Button> */}
                                                <Button variant="raised" className="graycolor jr-btn text-white refresh-btn" onClick={() => this.OnShowInstruction()}  > Instructions</Button>
                                                <Button variant="raised" style={{ backgroundColor: this.state.ColorCode }} className="jr-btn text-white refresh-btn" onClick={() => this.onRefreshDataClick()}  ><i className="zmdi zmdi-refresh "></i> Refresh Data</Button>
                                            </div>
                                        </div>
                                    </div>
                                    {/* {this.state.SelectedRecords.length > 0 &&
                                        <div className="row" style={{ marginTop: "20px" }}>
                                            <div className="col-md-12">
                                                <div className="float-right ">
                                                    <label>
                                                        <strong>Ignore By &nbsp;&nbsp; </strong>
                                                    </label>
                                                    <input type="text" placeholder="Ignore by" onChange={this.handleApprovedBy} />
                                                </div>
                                            </div>
                                        </div>
                                    } */}
                                </div>
                            </div>
                            <div className="table-responsive-material">
                                <table className="table tablebox account-reconcilliation">
                                    <thead>
                                        <tr style={{ backgroundColor: this.state.ColorCode }}>
                                            <th className={`td-width ${this.state.sortField === 'title' ? this.state.sortFormat === 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('title')}><p className="table-th-title">Title</p> </th>
                                            <th className={`td-width ${this.state.sortField === 'name' ? this.state.sortFormat === 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('name')}><p className="table-th-title">First Name</p> </th>
                                            <th className={`td-width ${this.state.sortField === 'middleName' ? this.state.sortFormat === 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('middleName')}><p className="table-th-title">Middle Name</p> </th>
                                            <th className={`td-width ${this.state.sortField === 'familyName' ? this.state.sortFormat === 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('familyName')}><p className="table-th-title"> Last Name</p> </th>
                                            <th className={`td-width ${this.state.sortField === 'suffix' ? this.state.sortFormat === 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('suffix')}><p className="table-th-title"> Suffix</p> </th>
                                            <th className={`td-width ${this.state.sortField === 'companyName' ? this.state.sortFormat === 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('companyName')}><p className="table-th-title"> Company Name</p> </th>
                                            <th className={`td-width ${this.state.sortField === 'displayName' ? this.state.sortFormat === 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('displayName')}><p className="table-th-title"> Display Name</p> </th>
                                            {/* <th className="td-width-90 text-center">
                                                <label className="container101 header-check">
                                                    {this.state.SelectALL ?
                                                        <input className="second-check-box" checked="checked" type="checkbox" onClick={(e) => this.SelectALL(e)} />
                                                        :
                                                        <input className="second-check-box" type="checkbox" onClick={(e) => this.SelectALL(e)} />
                                                    }
                                                    <span className="checkmark101 yellowMark white_border"></span>
                                                </label>
                                            </th> */}
                                            <th className="td-width-90 text-center"><img src="/vendors/WhiteCommnet.png" alt="comments" /></th>
                                            <th className="td-width-90 text-center "><img src="/vendors/Link-arrow_header.png" alt="link" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.MissingData.map((item, i) => (
                                            <React.Fragment key={i}>
                                                {this.state.editIndex !== i && <tr onClick={(e) => this.onRowEdit(i, item)}>
                                                <td>{item.title}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.middleName}</td>
                                                    <td>{item.familyName}</td>
                                                    <td>{item.suffix}</td>
                                                    <td>{item.companyName}</td>
                                                    <td>{item.displayName}</td>
                                                    {/* <td onClick={(e) => e.stopPropagation()}>
                                                        <label className="container101">
                                                            {(this.state.SelectALL || selectedItem.length > 0 && (selectedItem.indexOf(item.id) > -1)) ?

                                                                <input className="second-check-box" checked="checked" type="checkbox" onClick={(e) => this.onCheckboxClick(e, item)} />

                                                                :
                                                                <input className="second-check-box" type="checkbox" onClick={(e) => this.onCheckboxClick(e, item)} />
                                                            }
                                                            <span className="checkmark101 green_CheckMark"></span>
                                                        </label>
                                                    </td> */}
                                                    <td className="text-center" onClick={(e) => e.stopPropagation()}>
                                                        <div className="panel-commentCount">
                                                            <img src="/vendors/Vector Smart Object.png" alt="comments" className={item.commentCount === 0 ? "disableCommentsOpacity" : ""} onClick={() => this.LoadComments(item)}></img>
                                                            {item.unreviewCommentCount !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">{item.unreviewCommentCount}</span>}
                                                        </div>
                                                    </td>
                                                    <td className="text-center" onClick={(e) => e.stopPropagation()}><img src="/vendors/Yellow-link.png" alt="link" onClick={() => this.onLinkClick(item)}></img></td>
                                                </tr>}

                                                {this.state.editIndex === i && <tr>
                                                    <td>  <input type="text" className="form-control" value={this.state.editData != null ? this.state.editData.title : ''} onChange={(e) => this.UpdateData(e, i, item, "title")} placeholder='title' /> </td>
                                                    <td>  <input type="text" className="form-control" value={this.state.editData != null ? this.state.editData.name : ''} onChange={(e) => this.UpdateData(e, i, item, "name")} placeholder='first name' /> </td>
                                                    <td>  <input type="text" className="form-control" value={this.state.editData != null ? this.state.editData.middleName : ''} onChange={(e) => this.UpdateData(e, i, item, "middleName")} placeholder='middleName' /> </td>
                                                    <td>  <input type="text" className="form-control" value={this.state.editData != null ? this.state.editData.familyName : ''} onChange={(e) => this.UpdateData(e, i, item, "familyName")} placeholder='last name' /> </td>
                                                    <td>  <input type="text" className="form-control" value={this.state.editData != null ? this.state.editData.suffix : ''} onChange={(e) => this.UpdateData(e, i, item, "suffix")} placeholder='suffix' /> </td>
                                                    <td>  <input type="text" className="form-control" value={this.state.editData != null ? this.state.editData.companyName : ''} onChange={(e) => this.UpdateData(e, i, item, "companyName")} placeholder='company' /> </td>
                                                    <td>  <input type="text" className="form-control" value={this.state.editData != null ? this.state.editData.displayName : ''} onChange={(e) => this.UpdateData(e, i, item, "displayName")} placeholder='display name' /> </td>
                                                    <td colSpan="3">
                                                        <div className="col-md-12">
                                                            <input type="text" className="form-control" value={this.state.editData != null ? this.state.editData.createdByName : ''} onChange={(e) => this.UpdateData(e, i, item, "createdByName")} placeholder='updated by' />
                                                            <Button variant="raised" className="jr-btn jr-btn-sm bg-teal text-white" onClick={() => this.onRowSave(i, item)}>SAVE</Button>
                                                            <Button variant="raised" className="jr-btn jr-btn-sm bg-danger text-white" onClick={() => this.onRowCancel(i, item)}>CANCEL</Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                }
                                            </React.Fragment>
                                        ))}
                                        {this.state.MissingData.length === 0 && <tr>
                                            <td className="text-center" colSpan="9" >
                                            No issues here! Keep up the good work!
                                            </td>
                                        </tr>}
                                    </tbody>
                                </table>
                            </div>
                            <PaginationComponent
                                key={this.state.MissingData.length}
                                selectedPageNo={this.state.PageNo}
                                selectedSize={this.state.PageSize}
                                TotalRecords={this.state.TotalRecords}
                                ChangePageSize={this.ChangePageSize}
                                ChangePage={this.ChangePage}
                                CurrentRecords={this.state.MissingData.length}
                            >
                            </PaginationComponent>
                        </div>
                        <ToastContainer autoClose={3000} />
                    </React.Fragment>
                }
            </div>
        )
    }

}
const mapStateToProps = (state) => {
    return {
        ReportData: state.ReportData.ReportData,
        UpdateDescription: state.ReportData.UpdateDescription
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        GET_CompanyDashboard_PayrollVendorReportDetailList_BES,
        UpdateVendorNamesDiscrepancy,
        MarkAsIgnoreForVendor
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(VendorDiscrepancies_Embedded);
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import CircularProgress from "../../../../components/CircularProgress";
import { GetLastQuarterEndDate, numberWithCommas, Getsum, makeLinkForQuickbooks, LinkType } from '../../../common/commonServices';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Button from '@material-ui/core/Button';
import RSelect from 'react-select';
import { toast } from 'react-toastify';
import { GetPayrollTaxComputation, MarkVendorReview } from "../../../../appRedux/actions/PayrollTaxComputationAction";
import { LedgerUpdate } from '../../../../appRedux/actions/LedgerAction';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogContent';
import ClearIcon from '@material-ui/icons/Clear';
import "./style.css";
import Textarea from 'react-expanding-textarea';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';


const FontTooltip = withStyles((theme) => ({
    tooltip: {
        fontSize: 12
    },
}))(Tooltip);


class accountPayrollTaxComputations extends Component {
    constructor(props) {
        super(props);
        //set last quater start and end date 
        this.sDateRef = React.createRef();
        this.eDateRef = React.createRef();
        let eDate = new Date();
        let sDate = new Date(eDate.getFullYear(), 0, 1);
        if (eDate.getMonth() <= 2) {
            sDate.setFullYear(eDate.getFullYear() - 1, 0, 1);
            eDate = new Date(eDate.getFullYear() - 1, 11, 31);
        }
        else {
            var d = new Date();
            var quarter = Math.floor((d.getMonth() / 3))
            sDate = new Date(d.getFullYear(), quarter * 3 - 3, 1);
            eDate = GetLastQuarterEndDate();
        }
        // initialize states
        this.state = {
            loading: false,
            companyId: 0,
            eDate: eDate,
            sDate: sDate,
            Total: 0,
            PayrollTaxData: [],
            EmployeePayrollTaxData: [],
            ReviewList: [],
            ShowHistory: false,
            ShowReview: false,
            collapse: false,
            NonClergyEmpData: [],
            NonClergyEmpDataTemp: [],
            OtherPaymentEmpDataTemp: [],
            ClergyEmpData: [],
            collapse2: false,
            Addnotes: false,
            ConfirmPopup: false,
            TotalOfClergyEmp: 0,
            TotalOfNonClergyEmp: 0,
            NoOfEmpdate: "",
            editData: null,
            TaxReurnReportData: null,
            TaxReurnReportDataRowData: null,
            DropdownListTaxReturn: null,
            SearchDateOBJ: {
                from: null,
                to: null
            },
            payrollTaxReturnIndex: 0,
            selectedPayrollTaxReturn: { value: 0, label: "" },
            companyName: '',
            companyAddress: '',
            companyCity: '',
            companyState: '',
            companyZipCode: '',
            companyEinNumber: ''
        }


    }

    handleFocus = (e, selecter) => {
        if (selecter !== undefined) {
            if (selecter === "sDate") {
                if (this.sDateRef.current != null) {
                    this.sDateRef.current.setFocus();
                }
            }
            else if (selecter === "eDate") {
                if (this.eDateRef.current != null) {
                    this.eDateRef.current.setFocus();
                }
            }
        }
    }
    //set state for star and end date of filter on change event
    handleDateChange = (e, target) => {
        if (target === "fromDate") {
            this.setState({ sDate: new Date(e), selectedDateRange: { startDate: moment(new Date(e)).format("MM/DD/YYYY"), endDate: moment(this.state.eDate).format("MM/DD/YYYY") } })
        }
        else if (target === "uptoDate") {
            this.setState({ eDate: new Date(e), selectedDateRange: { startDate: moment(this.state.sDate).format("MM/DD/YYYY"), endDate: moment(new Date(e)).format("MM/DD/YYYY") } })
        }
    }
    //for sum of values and return comma seprated amount Value
    GetSumwithAmountFormat(list, fieldName) {
        var AmountTotal = Getsum(list, fieldName);
        return numberWithCommas(AmountTotal);
    }
    //component Function (After API call update State of our list and flags)
    componentWillReceiveProps(nextProps) {
        const data = nextProps.PYTaxData.returnObject;
        if (nextProps.PYTaxData !== undefined && nextProps.PYTaxData !== null && nextProps.PYTaxData !== this.props.PYTaxData) {
            if (nextProps.PYTaxData.transactionStatus === 0) {
                if (data !== null) {
                    this.setState({ NoOfEmpdate: this.state.eDate })

                    data.payrollTaxComputationsReportData.otherPaymentToEmployees.map((item, j) => {
                        item.isExpand = false;
                    })
                    data.payrollTaxComputationsReportData.addWorkerToEmployeeList.map((item, j) => {
                        item.isExpand = false;
                    })


                    if (data.payrollTaxComputationsReportData.payrollTaxComputations.clergyEmployeeData.length != 0) {
                        var obj = this.groupBy(data.payrollTaxComputationsReportData.payrollTaxComputations.clergyEmployeeData, "employeeName");
                        var Clergyresult = Object.keys(obj);
                        this.setState({ TotalOfClergyEmp: Clergyresult.length });
                    }
                    else {
                        this.setState({ TotalOfClergyEmp: 0 });
                    }


                    if (data.payrollTaxComputationsReportData.payrollTaxComputations.nonClergyEmployeeData.length != 0) {
                        var obj = this.groupBy(data.payrollTaxComputationsReportData.payrollTaxComputations.nonClergyEmployeeData, "employeeName");
                        var nonClergyresult = Object.keys(obj);
                        this.setState({ TotalOfNonClergyEmp: nonClergyresult.length });

                    }
                    else {
                        this.setState({ TotalOfNonClergyEmp: 0 });
                    }



                    if (data.payrollTaxComputationsReportData.otherPaymentToEmployees.length != 0) {
                        var obj = this.groupBy(data.payrollTaxComputationsReportData.otherPaymentToEmployees, "employeeName");
                        var otherPaymentToEmployeesResult = Object.keys(obj);

                    }

                    if (data.payrollTaxComputationsReportData.addWorkerToEmployeeList.length != 0) {
                        var obj = this.groupBy(data.payrollTaxComputationsReportData.addWorkerToEmployeeList, "employeeName");
                        var addWorkerToEmployeeListResult = Object.keys(obj);
                    }

                    if (data.payrollTaxComputationsReviewList.length > 0) {
                        data.payrollTaxComputationsReviewList.filter(a => a.isExpand == true)
                        this.setState({ ReviewList: data.payrollTaxComputationsReviewList, ShowReview: true })
                        let LatestFiled = data.payrollTaxComputationsReviewList.filter(a => a.stToDate <= moment(this.state.eDate).format("MM/DD/YYYY") && a.stFromDate >= moment(this.state.sDate).format("MM/DD/YYYY") && a.isFiled == true)
                        if (LatestFiled !== null && LatestFiled.length > 0) {
                            let taxReturnDropDownList = [];
                            taxReturnDropDownList = LatestFiled.map((data) => (
                                { value: moment(data.filedDate).format("MM/DD/YYYY HH:mm:ss"), label: data.filedBy + " At " + moment(data.filedDate).format("MM/DD/YYYY HH:mm:ss") }
                            ))

                            this.state.selectedPayrollTaxReturn = { value: moment(LatestFiled[0].filedDate).format("MM/DD/YYYY HH:mm:ss"), label: LatestFiled[0].filedBy + " At " + moment(LatestFiled[0].filedDate).format("MM/DD/YYYY HH:mm:ss") }
                            this.setState({ DropdownListTaxReturn: taxReturnDropDownList, TaxReurnReportData: JSON.parse(LatestFiled[this.state.payrollTaxReturnIndex].taxReurnReportData), TaxReurnReportDataRowData: LatestFiled });
                        }
                        else {
                            this.setState({ DropdownListTaxReturn: null, TaxReurnReportData: null, TaxReurnReportDataRowData: null });
                        }

                        // TaxReurnReportData
                    }
                    else {
                        this.setState({ DropdownListTaxReturn: null, TaxReurnReportData: null, TaxReurnReportDataRowData: null });
                    }
debugger;
                    this.setState({ loading: false, PayrollTaxData: data.payrollTaxComputationsReportData.generalLedgerTest.generalLedgerTestAccount })
                    this.setState({ loading: false, EmployeePayrollTaxData: data.payrollTaxComputationsReportData.generalLedgerTest.generalLedgerTestEmployee })
                    this.setState({ ClergyEmpData: data.payrollTaxComputationsReportData.payrollTaxComputations.clergyEmployeeData })
                    this.setState({ NonClergyEmpDataTemp: data.payrollTaxComputationsReportData.payrollTaxComputations.nonClergyEmployeeData })
                    this.setState({ NonClergyEmpData: nonClergyresult })
                    this.setState({ ClergyEmpDataTemp: Clergyresult })
                    this.setState({ OtherPaymentEmpDataTemp: otherPaymentToEmployeesResult })
                    this.setState({ addWorkerToEmployeeListTemp: addWorkerToEmployeeListResult })
                    this.setState({ OtherPaymentEmpData: data.payrollTaxComputationsReportData.otherPaymentToEmployees })
                    this.setState({ addWorkerToEmployeeList: data.payrollTaxComputationsReportData.addWorkerToEmployeeList })

                    this.setState({ companyName: data.payrollTaxComputationsReportData.companyDetails.name,
                        companyAddress: data.payrollTaxComputationsReportData.companyDetails.address,
                        companyCity: data.payrollTaxComputationsReportData.companyDetails.city,
                        companyState: data.payrollTaxComputationsReportData.companyDetails.state,
                        companyZipCode: data.payrollTaxComputationsReportData.companyDetails.postalCode,
                        companyEinNumber: data.payrollTaxComputationsReportData.companyDetails.eiNnumber
                    });

                    toast.success(nextProps.PYTaxData.resultMsg);
                }
                else {
                    this.setState({ loading: false }, () => { toast.error(nextProps.PYTaxData.resultMsg); });
                }
            }
            else {
                this.setState({ loading: false }, () => { toast.error(`${nextProps.PYTaxData.resultMsg}`); });
            }
        }

        if (nextProps.MarkStatus !== null && nextProps.MarkStatus !== undefined && nextProps.MarkStatus !== this.props.MarkStatus) {
            if (nextProps.MarkStatus.transactionStatus === 0) {
                this.setState({ loading: false }, () => { toast.success(`${nextProps.MarkStatus.resultMsg}`); });
                this.onRefreshDataClick(false);


            }
            else {
                this.setState({ loading: false }, () => { toast.error(`${nextProps.MarkStatus.resultMsg}`); });
            }
        }

        if (nextProps.LEDGER_UPDATE != undefined && nextProps.LEDGER_UPDATE !== this.props.LEDGER_UPDATE) {
            if (nextProps.LEDGER_UPDATE.transactionStatus === 0) {
                this.state.OtherPaymentEmpData.filter(a => a.isExpand == true).map((item) => {
                    item.isExpand = false;
                });
                this.state.addWorkerToEmployeeList.filter(a => a.isExpand == true).map((item) => {
                    item.isExpand = false;
                });
                this.setState({ OtherPaymentEmpData: this.state.OtherPaymentEmpData, addWorkerToEmployeeList: this.state.addWorkerToEmployeeList }, () => {
                    toast.success("Record updated successfully");

                });

            }
            else {
                this.setState({
                    loading: false, SelectedRecords: [], SelectALL: false
                }, () => {
                    toast.error(`${nextProps.LEDGER_UPDATE.resultMsg}`);
                });

            }
        }

    }
    //Get API for data of all tables and log history data.
    GetPayrollComputationData = (refreshStatus) => {
        var body = {
            companyID: this.state.companyId,
            fromDate: moment(this.state.sDate).format("MM/DD/YYYY"),
            toDate: moment(this.state.eDate).format("MM/DD/YYYY"),
            NeedUpdatQBORecords: refreshStatus
        }
        this.props.GetPayrollTaxComputation(body);
    }
    //group by function for employee wise grouping in employee list and other payment and add to worker table.
    groupBy(data, key) {

        return data.reduce(function (acc, item) {
            (acc[item[key]] = acc[item[key]] || []).push(item);
            return acc;
        }, {});
    };


    LoadHistory = (e, item) => {
        e.stopPropagation();
        this.setState({ HistoryRecordId: item.id, ShowHistory: true })
    }
    //close History popup
    CloseHistory = () => {
        this.setState({ ShowHistory: false });
    }
    //for update internal notes API in other payment and add to worker Table.
    onRowUpdateClick = (rowData) => {
        let data = {
            id: rowData.id,
            transactionTypeId: rowData.transactionTypeId,
            transactionType: rowData.transactionType,
            accountId: rowData.accountId,
            newAccountId: Number(rowData.newAccountID == '' ? 0 : rowData.newAccountID),
            needsBetterDescription: rowData.needsBetterDescription,
            transferCodedIncorrectly: rowData.transferCodedIncorrectly,
            cpaReview: rowData.cpaReview,
            pcCheck: rowData.pcCheck,
            accountantComment: rowData.accountantComment,
            internalNotes: rowData.internalNotes,
            taxableFringeBenefit: rowData.taxableFringeBenefit,
            vendor1099: rowData.vendor1099,
            taxReporting: rowData.taxReporting,
            isArchived: rowData.isArchived || false,
            benefitTypeId: rowData.benefitTypeId || 0,
            employeeName: rowData.employeeName || '',
            clientComment: rowData.clientComment || '',
            accountChangeApprovedByClient: rowData.accountChangeApprovedByClient || false,
            clientReviewStatus: rowData.clientReviewStatus || false,
            accountantReviewStatus: rowData.accountantReviewStatus || false,
            descriptionNeedsEmployeeName: rowData.descriptionNeedsEmployeeName || false,
            clientApprovedTaxableFringeBenefit: rowData.clientApprovedTaxableFringeBenefit || false,
            accountantPostedTaxableFringeBenefit: rowData.accountantPostedTaxableFringeBenefit || false,
            memoOrDescription: rowData.memo,
        };

        this.props.LedgerUpdate(data, this.state.companyId);
    }


    //component DidMount Function.
    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('userData'));
        this.setState({
            companyId: user.companyId,
            loading: true
        }, () => {
            this.GetPayrollComputationData(false);
        });

    }
    //for collpase clergy and non-clergy employee list in payroll tax return table.
    onRowExpandCollapseClick = (Type) => {
        if (Type == 1) {
            this.setState({ collapse: !this.state.collapse });
        }
        else if (Type == 2) {
            this.setState({ collapse2: !this.state.collapse2 });
        }
    }

    CloseReview = () => {
        this.setState({ ShowReview: false });
    }
    //for find difference between two value
    difference = (a, b) => {
        a = a.toString().replace(/,/g, '');
        b = b.toString().replace(/,/g, '');
        let ans = Math.abs(a - b).toFixed(2);
        return ans
    }
    //refresh data and get update data through API
    onRefreshDataClick = (status) => {
        if (!this.state.loading) {
            this.setState({ loading: true }, () => {
                this.GetPayrollComputationData(status);
            });
        }
    }
    //for mark review ,file and compute using Excel button and set into the database 
    onMarkReviewd = (refreshStatus) => {
        if (refreshStatus === 'Filed') {
            let EmployeeCount = this.state.TotalOfClergyEmp + this.state.TotalOfNonClergyEmp;
            let TotalWages = numberWithCommas(parseFloat(Getsum(this.state.NonClergyEmpDataTemp, "grossPay")) + parseFloat(Getsum(this.state.ClergyEmpData, "grossPay")));
            let TotalSSWHMedWH = this.GetSumwithAmountFormat(this.state.NonClergyEmpDataTemp, "grossPay");
            let TotalTaxDue = numberWithCommas(parseFloat(Getsum(this.state.NonClergyEmpDataTemp, "grossPay")) * 0.153);
            let TotalTaxDuePerDetailCalculation = numberWithCommas((parseFloat(Getsum(this.state.NonClergyEmpDataTemp, "sswh")) + parseFloat(Getsum(this.state.NonClergyEmpDataTemp, "medWH")) + parseFloat(Getsum(this.state.ClergyEmpData, "sswh")) + parseFloat(Getsum(this.state.ClergyEmpData, "medWH"))) * 2);
            let Difference = this.difference((parseFloat(Getsum(this.state.NonClergyEmpDataTemp, "grossPay")) * 0.153).toFixed(2), ((parseFloat(Getsum(this.state.NonClergyEmpDataTemp, "sswh")) + parseFloat(Getsum(this.state.NonClergyEmpDataTemp, "medWH")) + parseFloat(Getsum(this.state.ClergyEmpData, "sswh")) + parseFloat(Getsum(this.state.ClergyEmpData, "medWH"))) * 2).toFixed(2));

            const taxReurnReportData = {
                EmployeeCount: EmployeeCount,
                TotalWages: TotalWages,
                TotalSSWHMedWH: TotalSSWHMedWH,
                TotalTaxDue: TotalTaxDue,
                TotalTaxDuePerDetailCalculation: TotalTaxDuePerDetailCalculation,
                Difference: Difference
            };
            var body = {
                companyID: this.state.companyId,
                fromDate: moment(this.state.sDate).format("MM/DD/YYYY"),
                toDate: moment(this.state.eDate).format("MM/DD/YYYY"),
                isMark: 'Filed',
                taxReurnReportData: taxReurnReportData
            }

        }
        else if (refreshStatus === 'ComputeExcel') {
            var body = {
                companyID: this.state.companyId,
                fromDate: moment(this.state.sDate).format("MM/DD/YYYY"),
                toDate: moment(this.state.eDate).format("MM/DD/YYYY"),
                isMark: 'ComputeExcel'
            }
        }
        else if (refreshStatus === 'Review') {
            var body = {
                companyID: this.state.companyId,
                fromDate: moment(this.state.sDate).format("MM/DD/YYYY"),
                toDate: moment(this.state.eDate).format("MM/DD/YYYY"),
                isMark: 'Review'
            }
        }
        this.props.MarkVendorReview(body);
        this.setState({ ConfirmPopup: false });
    }
    //for filter data date wise filter
    ApplyDate = () => {
        this.setState({
            loading: true,
            searchDate: "",
            SearchDateOBJ: {
                from: null,
                to: null
            }
        }, () => {
            this.GetPayrollComputationData(false);
        });
    }
    //for filter data and expand row for internal notes and set data into the internotes input box.
    onRowClick = (table, j, data, item, rowdata, element) => {
        if (table == "1") {
            this.state.OtherPaymentEmpData.filter(a => a.isExpand == true).map((item) => {
                item.isExpand = false;
            })
            this.setState(this.state.OtherPaymentEmpData);

            if (data == "Bill") {
                this.state.OtherPaymentEmpData.filter(a => a.employeeName == item && a.transactionType === "Bill")[j].isExpand = true
            }
            else {
                this.state.OtherPaymentEmpData.filter(a => a.employeeName == item && a.transactionType !== "Bill")[j].isExpand = true
            }
            this.setState(this.state.OtherPaymentEmpData);
        }
        else {

            this.state.addWorkerToEmployeeList.filter(a => a.isExpand == true).map((item) => {
                item.isExpand = false;
            })
            this.setState(this.state.addWorkerToEmployeeList);

            if (data == "Bill") {
                this.state.addWorkerToEmployeeList.filter(a => a.employeeName == item && a.transactionType === "Bill")[j].isExpand = true
            }
            else {
                this.state.addWorkerToEmployeeList.filter(a => a.employeeName == item && a.transactionType !== "Bill")[j].isExpand = true
            }
            this.setState(this.state.addWorkerToEmployeeList);
        }
        element.target.value = rowdata.internalNotes;
        let olddata = {
            internalNotes: rowdata.internalNotes
        };
        this.setState({
            // editIndex1: accountIndex,
            //editIndex: rowIndex,
            editData: olddata
            //selectedAccount:{ value: data.newAccId, label: data.newAccName }
        });

    }
    //for change event of interal notes in last table (otherpayment and Add to worker Table.)
    handalTextArea = (e, j, data, item, table) => {
        if (table == "1") {
            if (data == "Bill") {
                this.state.OtherPaymentEmpData.filter(a => a.employeeName == item && a.transactionType === "Bill")[j].internalNotes = e.target.value
            }
            else {
                this.state.OtherPaymentEmpData.filter(a => a.employeeName == item && a.transactionType !== "Bill")[j].internalNotes = e.target.value
            }
            this.setState(this.state.OtherPaymentEmpData);
        }
        else {
            if (data == "Bill") {
                this.state.addWorkerToEmployeeList.filter(a => a.employeeName == item && a.transactionType === "Bill")[j].internalNotes = e.target.value
            }
            else {
                this.state.addWorkerToEmployeeList.filter(a => a.employeeName == item && a.transactionType !== "Bill")[j].internalNotes = e.target.value
            }
            this.setState(this.state.addWorkerToEmployeeList);
        }
    }
    //for click cancel button of internal notes and close all collapsed row of internal notes
    onRowCancelClick = (table, j, data, item, rowData) => {
        let editData = this.state.editData;

        if (table == "1") {

            var lstlist = [...this.state.OtherPaymentEmpData];



            if (data == "Bill") {
                lstlist.filter(a => a.employeeName == item && a.transactionType == "Bill")[j].isExpand = false;
                lstlist.filter(a => a.employeeName == item && a.transactionType == "Bill")[j].internalNotes = editData.internalNotes;
            }
            else {
                lstlist.filter(a => a.employeeName == item && a.transactionType !== "Bill")[j].isExpand = false;
                lstlist.filter(a => a.employeeName == item && a.transactionType !== "Bill")[j].internalNotes = editData.internalNotes;
            }

            this.setState(this.state.OtherPaymentEmpData);
        }
        else {
            var Nonlstlist = [...this.state.addWorkerToEmployeeList];
            if (data == "Bill") {
                Nonlstlist.filter(a => a.employeeName == item && a.transactionType === "Bill")[j].isExpand = false;
                Nonlstlist.filter(a => a.employeeName == item && a.transactionType == "Bill")[j].internalNotes = editData.internalNotes;
            }
            else {
                Nonlstlist.filter(a => a.employeeName == item && a.transactionType !== "Bill")[j].isExpand = false;
                Nonlstlist.filter(a => a.employeeName == item && a.transactionType !== "Bill")[j].internalNotes = editData.internalNotes;
            }
            this.setState(this.state.addWorkerToEmployeeList);
        }


    }

    //for close confirmation box of filed Review Button
    handleClose = (i) => {
        this.setState({ ConfirmPopup: false })
    }

    //for confirmation and  checking the conditions after clicking the filed button
    FiledConfirmation = () => {

        var obj = this.state.ReviewList.filter((accfill) => (accfill.IsComputeUsingExcel == null || accfill.IsComputeUsingExcel == 0) && (accfill.isFiled == null || accfill.isFiled == 0) && (accfill.isReviewed == 1)).reduce((acc, shot) => acc = acc > shot.stToDate ? acc : shot.stToDate, 0);
        var ListOfobj = this.state.ReviewList.filter((acc) => (acc.stToDate = obj) && (acc.isFiled == null || acc.isFiled == 0) && (acc.isReviewed == 1));
        if ((ListOfobj == null || ListOfobj.length == 0) || (moment(this.state.eDate).format("MM/DD/YYYY") > obj)) {
            this.setState({ ConfirmPopup: true });
        }
        else {
            this.onMarkReviewd('Filed');
            this.setState({ ConfirmPopup: false });
        }
    }
    //for create Deep Link.
    onLinkClick = (rowData) => {
        let origin = makeLinkForQuickbooks();
        window.open(origin + rowData.transactionLink);
    }

    //set selected value for dropdown in 941/944 Tax Return Totals Per Filed.
    searchTranTypeChange = (e) => {
        let FiledPayrollindex = this.state.TaxReurnReportDataRowData.findIndex(a => moment(a.filedDate).format("MM/DD/YYYY HH:mm:ss") === e.value);
        this.state.selectedPayrollTaxReturn = { value: moment(this.state.TaxReurnReportDataRowData[FiledPayrollindex].filedDate).format("MM/DD/YYYY HH:mm:ss"), label: this.state.TaxReurnReportDataRowData[FiledPayrollindex].filedBy + " At " + moment(this.state.TaxReurnReportDataRowData[FiledPayrollindex].filedDate).format("MM/DD/YYYY HH:mm:ss") };
        this.setState(this.state.selectedPayrollTaxReturn);
        this.setState({ payrollTaxReturnIndex: FiledPayrollindex });
    }


    render() {
        return (
            <React.Fragment key="1">
                {this.state.ShowReview ?
                    <React.Fragment>
                        <Dialog
                            className="comment-Top-align"
                            maxWidth="sm"
                            fullWidth={true}
                            open={true}>
                            <DialogTitle><span className="log-history-title float-left">History</span>
                                <Button className="jr-btn close-btn float-right " onClick={this.CloseReview}><ClearIcon /></Button>
                            </DialogTitle>
                            <DialogContent>
                                {this.state.ReviewList.map((item, i) => (
                                    <div className="history-box">
                                        {(item.isFiled == null || item.isFiled == 0) && (item.isReviewed == null || item.isReviewed == 0) ?
                                            <React.Fragment key={i}>
                                                <p className="history-item-text">Dates {item.stFromDate} - {item.stToDate}</p>
                                                <p className="history-date-text"> <strong> Computed Using Excel By </strong> {item.computeUsingExcelBy}<strong> on </strong>{item.stComputeUsingExcelDate}</p>
                                            </React.Fragment>
                                            : (item.isFiled == null || item.isFiled == 0) ?
                                                <React.Fragment key={i}>
                                                    <p className="history-item-text">Dates {item.stFromDate} - {item.stToDate}</p>
                                                    <p className="history-date-text"> <strong> Approved By </strong> {item.reviewedBy}<strong> on </strong>{item.stReviewedDate}</p>
                                                </React.Fragment>
                                                :
                                                <React.Fragment key={i}>
                                                    <p className="history-item-text">Dates {item.stFromDate} - {item.stToDate}</p>
                                                    <p className="history-date-text"> <strong> Filed By </strong> {item.filedBy}<strong> on </strong>{item.stFiledDate}</p>
                                                </React.Fragment>
                                        }
                                    </div>
                                ))}
                            </DialogContent>
                        </Dialog>
                    </React.Fragment>
                    : ""}
                <div id="Mypdf">
                    <div className="page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
                        <h2 className="title mb-3 mb-sm-0">{JSON.parse(localStorage.getItem('userData')) != null ? JSON.parse(localStorage.getItem('userData')).companyName : ""} | Payroll Tax Computations</h2>
                    </div>
                    <div className="container-fluid">
                        <div className="row ml-3">
                            <div className="col-md-4">
                                <div className="select-quater-box">
                                    <p className="sub-title">Date Selection</p>
                                    <div className="row">
                                        <div className="col-sm-4 col-md-12">
                                            <div className="datepicker-box">
                                                <div className="date-box">
                                                    <DatePicker
                                                        selected={this.state.sDate}
                                                        onChange={(e) => this.handleDateChange(e, 'fromDate')}
                                                        name="fromDate"
                                                        id="date"
                                                        required={true}
                                                        margin="normal"
                                                        disabledKeyboardNavigation
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        yearDropdownItemNumber={5}
                                                        maxDate={this.state.eDate}
                                                        ref={this.sDateRef}
                                                    />
                                                    <div className="date-icon" onClick={(e) => this.handleFocus(e, 'sDate')}>
                                                        <i className="zmdi zmdi-calendar-note"></i>
                                                    </div>
                                                </div>
                                                <div className="to-box">
                                                    <span className="text-center ledgerto-text">To</span>
                                                </div>
                                                <div className="date-box">
                                                    <DatePicker
                                                        selected={this.state.eDate}
                                                        onChange={(e) => this.handleDateChange(e, 'uptoDate')}
                                                        name="uptoDate"
                                                        id="date"
                                                        required={true}
                                                        margin="normal"
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        yearDropdownItemNumber={5}
                                                        disabledKeyboardNavigation
                                                        minDate={this.state.sDate}
                                                        maxDate={new Date()}
                                                        ref={this.eDateRef}
                                                    />
                                                    <div className="date-icon" onClick={(e) => this.handleFocus(e, 'eDate')}>
                                                        <i className="zmdi zmdi-calendar-note"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="genera-apply-btn m5">
                                                <React.Fragment>
                                                    <Button variant="raised" className="greencolor jr-btn bg-teal text-white refresh-btn" onClick={() => this.GetPayrollComputationData(false)} >apply</Button>
                                                </React.Fragment>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="row p-1 d-flex justify-content-end m20">
                                    <div>
                                        <div className="checkbox pointer" >
                                            {/* <div className="chiller_cb">
                                            <input id="TFB" type="checkbox" onClick={(e) => this.showArchived(e)} />
                                            <label for="TFB">
                                                <p className="checkbox-title"><strong>Show Filed Computations</strong></p>
                                                <p className="parag-text"></p>
                                            </label>
                                            <span></span>
                                        </div> */}
                                        </div>
                                    </div>
                                    <div className="ml-2">
                                        <React.Fragment>
                                            <Button variant="raised" className="graycolor jr-btn bg-teal text-white refresh-btn" onClick={(e) => this.onMarkReviewd('Review')} >Mark as Approved</Button>
                                        </React.Fragment>
                                    </div>
                                    <div className="ml-2">
                                        <React.Fragment>
                                            <Button variant="raised" className="graycolor jr-btn bg-teal text-white refresh-btn" onClick={(e) => this.FiledConfirmation()} >Filed Payroll Return</Button>
                                        </React.Fragment>
                                    </div>
                                    <div className="ml-2">
                                        <React.Fragment>
                                            <Button variant="raised" className="colorRed jr-btn bg-teal text-white refresh-btn" onClick={(e) => this.onMarkReviewd('ComputeExcel')} >Compute Using Excel</Button>
                                        </React.Fragment>
                                    </div>
                                    <div className="ml-2 mr-5">
                                        <Button variant="raised" className="share-btn orangeColor jr-btn bg-teal text-white refresh-btn" onClick={() => this.onRefreshDataClick(true)}  ><i className="zmdi zmdi-refresh "></i> Refresh Data</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.ConfirmPopup ? //Confirmation Dailog Box
                        <React.Fragment>
                            <Dialog
                                className="comment-Top-align"
                                maxWidth="sm"
                                fullWidth={true}
                                open={true}>
                                <DialogTitle><span className="log-history-title float-left"></span>
                                    <Button className="jr-btn close-btn float-right " onClick={this.handleClose}><ClearIcon /></Button>
                                    <h1 color="primary" >Are You sure?</h1>
                                </DialogTitle>
                                <DialogContent>
                                    <h3>These computations have not been marked as approved. Are you sure you want to mark this as "Filed Payroll Return"?</h3>
                                </DialogContent>
                                <DialogActions >
                                    <Button className="greencolor jr-btn bg-teal text-white refresh-btn" variant="outlined" onClick={() => this.onMarkReviewd('Filed')} autoFocus>
                                        Yes, Marked as Filed
                                    </Button>
                                    &nbsp;&nbsp;
                                    <Button className="graycolor jr-btn bg-teal text-white refresh-btn" variant="outlined" autoFocus onClick={this.handleClose}>
                                        Cancel
                                    </Button>
                                </DialogActions>
                            </Dialog>

                        </React.Fragment>
                        : ""}

                    {this.state.loading ? <CircularProgress /> :
                        <React.Fragment key="2">
                            <div>
                                <div className="row padding-box app-wrapper">
                                    <div className="col-6">
                                        <div className="table-responsive-material">
                                            <table className="table tablebox account-reconcilliation">
                                                <thead>
                                                    <tr className="bg header-bg-color">
                                                        <th className="td-width-90">General Ledger Test</th>
                                                        <th className="td-width-90"></th>
                                                        <th className="td-width-90"></th>
                                                    </tr>
                                                    <tr>
                                                        <td className="td-width-10 bold-500">General Ledger</td>
                                                        <td className="td-width-90 customTd bold-500">Salaries & Wages</td>
                                                        <td className="td-width-90 customTd bold-500">Housing Allowance</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.PayrollTaxData && this.state.PayrollTaxData.length > 0 && this.state.PayrollTaxData.map((item, j) => (
                                                        <tr>
                                                            <td className="td-width-10">{item.accountValue} </td>
                                                            <td className="td-width-90 customTd">{item.salaryWages == 0 ? "-" : "$ " + numberWithCommas(item.salaryWages)}</td>
                                                            <td className="td-width-90 customTd">{item.housingAllowance == 0 ? "-" : "$ " + numberWithCommas(item.housingAllowance)}</td>
                                                        </tr>

                                                    ))}
                                                    <tr className="totol-font-color">
                                                        <th className="td-width-10">Total Per General Ledger</th>
                                                        <th className="td-width-90 customTd"> $&nbsp;{numberWithCommas(Getsum(this.state.PayrollTaxData, "salaryWages"))}</th>
                                                        <th className="td-width-90 customTd"> $&nbsp;{numberWithCommas(Getsum(this.state.PayrollTaxData, "housingAllowance"))}</th>
                                                    </tr>

                                                    <tr>
                                                        <td colSpan="10" className="bold-500">Employees Details</td>
                                                    </tr>
                                                    {this.state.EmployeePayrollTaxData && this.state.EmployeePayrollTaxData.length > 0 && this.state.EmployeePayrollTaxData.map((item, j) => (
                                                        <tr>
                                                            <td className="td-width-10">{item.employeeName}</td>
                                                            <td className="td-width-90 customTd">{item.salaryWages == 0 ? "-" : "$ " + numberWithCommas(item.salaryWages)}</td>
                                                            <td className="td-width-90 customTd">{item.housingAllowance == 0 ? "-" : "$ " + numberWithCommas(item.housingAllowance)}</td>
                                                        </tr>
                                                    ))}

                                                    <tr className="totol-font-color">
                                                        <th className="td-width-10">Total Per Employee Detail</th>
                                                        <th className="td-width-90 customTd">$&nbsp;{numberWithCommas(Getsum(this.state.EmployeePayrollTaxData, "salaryWages"))}</th>
                                                        <th className="td-width-90 customTd">$&nbsp;{numberWithCommas(Getsum(this.state.EmployeePayrollTaxData, "housingAllowance"))}</th>
                                                    </tr>
                                                    <tr>
                                                        <th className="td-width-10"></th>
                                                        <th className="td-width-90"></th>
                                                        <th className="td-width-90"></th>
                                                    </tr>


                                                    <tr className="totol-font-color">
                                                        <th className="td-width-10">Difference</th>
                                                        <th className="td-width-90 customTd">$&nbsp;{numberWithCommas(this.difference(Getsum(this.state.PayrollTaxData, "salaryWages"), Getsum(this.state.EmployeePayrollTaxData, "salaryWages")))}</th>
                                                        <th className="td-width-90 customTd">$&nbsp;{numberWithCommas(this.difference(Getsum(this.state.PayrollTaxData, "housingAllowance"), Getsum(this.state.EmployeePayrollTaxData, "housingAllowance")))}</th>
                                                    </tr>

                                                    <tr className="color-Black" >
                                                        <th className="td-width-10 text-center" style={{ height: "60px" }} colspan="3">*Excludes Gross-up Computations for Social Security and Medicare Taxes</th>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="table-responsive-material">
                                            <table className="table tablebox account-reconcilliation">
                                                <thead>
                                                    <tr className="header-bg-color">
                                                        <th className="td-width-100">941/944 Tax Return Totals</th>
                                                        <th className="td-width-10"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="td-width-90 bold-500">Company Name</td>
                                                        <td className="td-width-90">{this.state.companyName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="td-width-90 bold-500">Legal Address</td>
                                                        <td className="td-width-90">{this.state.companyAddress}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="td-width-90 bold-500">City</td>
                                                        <td className="td-width-90">{this.state.companyCity}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="td-width-90 bold-500">State</td>
                                                        <td className="td-width-90">{this.state.companyState}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="td-width-90 bold-500">Zip Code</td>
                                                        <td className="td-width-90">{this.state.companyZipCode}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="td-width-90 bold-500">EIN Number</td>
                                                        <td className="td-width-90">{this.state.companyEinNumber}</td>
                                                    </tr>



                                                    <tr>
                                                        <td className="td-width-90">No. Of Employees {moment(this.state.NoOfEmpdate).format("MMMM DD")}</td>
                                                        <td className="td-width-90 customTd bold-500">{this.state.TotalOfClergyEmp + this.state.TotalOfNonClergyEmp}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="td-width-90">Total Wages – 941-Line 2; 944 Line 1</td>
                                                        <td className="td-width-90 customTd bold-500" >$&nbsp;{numberWithCommas(parseFloat(Getsum(this.state.NonClergyEmpDataTemp, "grossPay")) + parseFloat(Getsum(this.state.ClergyEmpData, "grossPay")))}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="td-width-90">No wages subject to social security or Medicare Tax - 941-Line 4; 944 Line 3</td>
                                                        <td className="td-width-90 customTd bold-500" ><Checkbox color="success" checked={this.GetSumwithAmountFormat(this.state.NonClergyEmpDataTemp, "grossPay") == "0.00" ? true : false}></Checkbox></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="td-width-80">Total Social Security & Medicare Wages 941-Line 5a and 5c; 944 Line 4a and 4c</td>
                                                        <td className="td-width-90 customTd bold-500">$&nbsp;{this.GetSumwithAmountFormat(this.state.NonClergyEmpDataTemp, "grossPay")}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="td-width-90">Total Tax Due for 941/944</td>
                                                        <td className="td-width-90 customTd bold-500" >$&nbsp;{numberWithCommas(parseFloat(Getsum(this.state.NonClergyEmpDataTemp, "grossPay")) * 0.153)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="td-width-90">Total Tax Due Per Detail Calculation</td>
                                                        <td className="td-width-90 customTd bold-500">$&nbsp;{numberWithCommas((parseFloat(Getsum(this.state.NonClergyEmpDataTemp, "sswh")) + parseFloat(Getsum(this.state.NonClergyEmpDataTemp, "medWH")) + parseFloat(Getsum(this.state.ClergyEmpData, "sswh")) + parseFloat(Getsum(this.state.ClergyEmpData, "medWH"))) * 2)}</td>
                                                    </tr>

                                                    <tr>
                                                        <td className="td-width-90">Difference</td>
                                                        <td
                                                            className={this.difference((parseFloat(Getsum(this.state.NonClergyEmpDataTemp, "grossPay")) * 0.153).toFixed(2), ((parseFloat(Getsum(this.state.NonClergyEmpDataTemp, "sswh")) + parseFloat(Getsum(this.state.NonClergyEmpDataTemp, "medWH")) + parseFloat(Getsum(this.state.ClergyEmpData, "sswh")) + parseFloat(Getsum(this.state.ClergyEmpData, "medWH"))) * 2).toFixed(2)) > 2.00 ? "colorRed td-width-90 bold-500" : "td-width-90 bold-500"}
                                                            style={{ textAlign: "right" }}>
                                                            $&nbsp;{this.difference((parseFloat(Getsum(this.state.NonClergyEmpDataTemp, "grossPay")) * 0.153).toFixed(2), ((parseFloat(Getsum(this.state.NonClergyEmpDataTemp, "sswh")) + parseFloat(Getsum(this.state.NonClergyEmpDataTemp, "medWH")) + parseFloat(Getsum(this.state.ClergyEmpData, "sswh")) + parseFloat(Getsum(this.state.ClergyEmpData, "medWH"))) * 2).toFixed(2))}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>


                                        {this.state.TaxReurnReportData !== null ?
                                            <div className="table-responsive-material scroll-y">
                                                <table className="table tablebox account-reconcilliation">
                                                    <thead>
                                                        <tr className="header-bg-color">
                                                            <th className="td-width-100">941/944 Tax Return Totals Per Filed Return  By
                                                            </th>
                                                            <th className="width-Custom">
                                                                <RSelect
                                                                    value={this.state.selectedPayrollTaxReturn}
                                                                    onChange={(e) => this.searchTranTypeChange(e)}
                                                                    options={this.state.DropdownListTaxReturn}
                                                                    defaultValue={this.state.TaxReurnReportData.filedDate}
                                                                    placeholder="Select Payroll Field Time"
                                                                    className="custom-dropdown css-bg1rzq-control-Custom"
                                                                /></th>
                                                            {/* By {this.state.TaxReurnReportDataRowData[this.state.payrollTaxReturnIndex].filedBy} At {moment(this.state.selectedPayrollTaxReturn.value).format("MM/DD/YYYY HH:mm:ss")} */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="td-width-90">Total Wages – 941-Line 2; 944 Line 1</td>
                                                            <td className="td-width-90 customTd bold-500" >
                                                                {JSON.parse(this.state.TaxReurnReportDataRowData[this.state.payrollTaxReturnIndex].taxReurnReportData).TotalWages != numberWithCommas(parseFloat(Getsum(this.state.NonClergyEmpDataTemp, "grossPay")) + parseFloat(Getsum(this.state.ClergyEmpData, "grossPay"))) ?
                                                                    <FontTooltip id="tooltip-controlled" title={"Difference : " + this.difference(JSON.parse(this.state.TaxReurnReportDataRowData[this.state.payrollTaxReturnIndex].taxReurnReportData).TotalWages, parseFloat(Getsum(this.state.NonClergyEmpDataTemp, "grossPay")) + parseFloat(Getsum(this.state.ClergyEmpData, "grossPay")))} placement="top-start">
                                                                        <img src="/vendors/warning.png" alt="link" className="icon-size"></img>
                                                                    </FontTooltip>
                                                                    : ""}
                                                                &nbsp;&nbsp;
                                                                $&nbsp;{JSON.parse(this.state.TaxReurnReportDataRowData[this.state.payrollTaxReturnIndex].taxReurnReportData).TotalWages}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="td-width-80">Total Social Security & Medicare Wages 941-Line 5a and 5b; 944 Line 6a and 6b</td>
                                                            <td className="td-width-90 customTd bold-500">
                                                                {JSON.parse(this.state.TaxReurnReportDataRowData[this.state.payrollTaxReturnIndex].taxReurnReportData).TotalSSWHMedWH != this.GetSumwithAmountFormat(this.state.NonClergyEmpDataTemp, "grossPay") ?
                                                                    <FontTooltip id="tooltip-controlled" title={"Difference : " + this.difference(JSON.parse(this.state.TaxReurnReportDataRowData[this.state.payrollTaxReturnIndex].taxReurnReportData).TotalSSWHMedWH, this.GetSumwithAmountFormat(this.state.NonClergyEmpDataTemp, "grossPay"))} placement="top-start">
                                                                        <img src="/vendors/warning.png" alt="link" className="icon-size"></img>
                                                                    </FontTooltip>
                                                                    : ""}
                                                                &nbsp;&nbsp;
                                                                $&nbsp;{JSON.parse(this.state.TaxReurnReportDataRowData[this.state.payrollTaxReturnIndex].taxReurnReportData).TotalSSWHMedWH}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="td-width-90">Total Tax Due for 941/944</td>
                                                            <td className="td-width-90 customTd bold-500" >$&nbsp;{JSON.parse(this.state.TaxReurnReportDataRowData[this.state.payrollTaxReturnIndex].taxReurnReportData).TotalTaxDue}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="td-width-90">Total Tax Due Per Current Calculations</td>
                                                            <td className="td-width-90 customTd bold-500" >$&nbsp;{numberWithCommas(parseFloat(Getsum(this.state.NonClergyEmpDataTemp, "grossPay")) * 0.153)}</td>
                                                        </tr>

                                                        <tr>
                                                            <td className="td-width-90">Difference</td>
                                                            <td
                                                                className={this.difference(numberWithCommas(parseFloat(Getsum(this.state.NonClergyEmpDataTemp, "grossPay")) * 0.153), JSON.parse(this.state.TaxReurnReportDataRowData[this.state.payrollTaxReturnIndex].taxReurnReportData).TotalTaxDue) > 2.00 ? "colorRed td-width-90 bold-500" : "td-width-90 bold-500"}
                                                                style={{ textAlign: "right" }}>$&nbsp;{this.difference(Getsum(this.state.NonClergyEmpDataTemp, "grossPay") * 0.153, JSON.parse(this.state.TaxReurnReportDataRowData[this.state.payrollTaxReturnIndex].taxReurnReportData).TotalTaxDue)}

                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            : ""}

                                    </div>

                                </div>
                                <hr></hr>
                                <div className="padding-box app-wrapper">
                                    <div className="table-responsive-material">
                                        <table className='table tablebox account-reconcilliation'>


                                            <thead>
                                                <tr className="header-bg-color">
                                                    <th className="td-width-90" colSpan="12">Payroll Tax Computation</th>
                                                </tr>
                                                <tr className='bold-500' >
                                                    <td className="W200">
                                                        Employee
                                                    </td>
                                                    <td className="W150">
                                                        Date
                                                    </td>
                                                    <td className="W100">
                                                        Number
                                                    </td>
                                                    <td className="W100">
                                                        Type
                                                    </td>
                                                    <td className="customTd W100" >
                                                        Total
                                                    </td>
                                                    <td className="customTd W100" >
                                                        Gross Pay

                                                    </td>
                                                    <td className="customTd W100">
                                                        Housing
                                                    </td>
                                                    <td className="customTd W100">
                                                        SS WH
                                                    </td>
                                                    <td className="customTd W100">
                                                        Med WH
                                                    </td>
                                                    <td className="customTd W100">
                                                        Net Pay
                                                    </td>
                                                    <td className="customTd W100">
                                                        Difference
                                                    </td >
                                                    <td className="text-center"><img src="/vendors/Shape1_head.png" alt="link"></img></td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className={this.state.collapse ? "bg-active" : ""} onClick={() => this.onRowExpandCollapseClick(1)}>
                                                    <td className="title-name" colSpan="16">
                                                        {
                                                            this.state.collapse ? <i className="zmdi zmdi-minus-circle-outline"></i> : <i className="zmdi zmdi-plus-circle-o"></i>
                                                        }
                                                        <span className="account-text">Clergy Employee</span>
                                                    </td>
                                                </tr>
                                                {this.state.collapse && <React.Fragment>
                                                    {(this.state.ClergyEmpDataTemp && this.state.ClergyEmpDataTemp.length > 0) && this.state.ClergyEmpDataTemp.map((data) => (
                                                        <React.Fragment>

                                                            {(this.state.ClergyEmpData && this.state.ClergyEmpData.length > 0) && this.state.ClergyEmpData.filter((item) => item.employeeName == data).map((item, j) => (
                                                                <tr>
                                                                    <React.Fragment>
                                                                        <td>{item.employeeName}</td>
                                                                        <td>{moment(item.date).format("MM/DD/YYYY")}</td>
                                                                        <td>{item.num}</td>
                                                                        <td>{item.transactionType} </td>
                                                                        <td className="customTd">{item.amount == 0 ? "-" : "$ " + numberWithCommas(item.amount)}</td>
                                                                        <td className="customTd">{item.grossPay == 0 ? "-" : "$ " + numberWithCommas(item.grossPay)}</td>
                                                                        <td className="customTd">{item.housing == 0 ? "-" : "$ " + numberWithCommas(item.housing)}</td>
                                                                        <td className="customTd">{item.sswh == 0 ? "-" : "$ " + numberWithCommas(item.sswh)}</td>
                                                                        <td className="customTd">{item.medWH == 0 ? "-" : "$ " + numberWithCommas(item.medWH)}</td>
                                                                        <td className="customTd">{item.netPay == 0 ? "-" : "$ " + numberWithCommas(item.netPay)}</td>
                                                                        <td className="customTd">{item.difference == 0 ? "-" : "$ " + numberWithCommas(item.difference)}</td>
                                                                        <td className="text-center" style={{ width: 50 }}> <img src="/vendors/Shape 1.png" alt="link" onClick={() => this.onLinkClick(item)}></img></td>
                                                                    </React.Fragment>
                                                                </tr>
                                                            ))}



                                                            <tr>
                                                                <td colspan='4' className='bold-500 totol-font-color'>Total for {data}</td>
                                                                <td className="customTd bold-500 totol-font-color">$&nbsp;{numberWithCommas(Getsum(this.state.ClergyEmpData.filter(a => a.employeeName === data), "amount"))}</td>
                                                                <td className="customTd bold-500 totol-font-color">$&nbsp;{numberWithCommas(Getsum(this.state.ClergyEmpData.filter(a => a.employeeName === data), "grossPay"))}</td>
                                                                <td className="customTd bold-500 totol-font-color">$&nbsp;{numberWithCommas(Getsum(this.state.ClergyEmpData.filter(a => a.employeeName === data), "housing"))}</td>
                                                                <td className="customTd bold-500 totol-font-color">$&nbsp;{numberWithCommas(Getsum(this.state.ClergyEmpData.filter(a => a.employeeName === data), "sswh"))}</td>
                                                                <td className="customTd bold-500 totol-font-color">$&nbsp;{numberWithCommas(Getsum(this.state.ClergyEmpData.filter(a => a.employeeName === data), "medWH"))}</td>
                                                                <td className="customTd bold-500 totol-font-color">$&nbsp;{numberWithCommas(Getsum(this.state.ClergyEmpData.filter(a => a.employeeName === data), "netPay"))}</td>
                                                                <td className="customTd bold-500 totol-font-color">$&nbsp;{numberWithCommas(Getsum(this.state.ClergyEmpData.filter(a => a.employeeName === data), "difference"))}</td>
                                                                <td className="customTd bold-500 totol-font-color"></td>

                                                            </tr>
                                                        </React.Fragment>
                                                    ))}

                                                    <tr>
                                                        <td colspan='4' classNmae='bold-500 totol-font-color'>Total Clergy Employees</td>
                                                        <td className="customTd bold-500 totol-font-color">$&nbsp;{numberWithCommas(Getsum(this.state.ClergyEmpData, "amount"))}</td>
                                                        <td className="customTd bold-500 totol-font-color">$&nbsp;{numberWithCommas(Getsum(this.state.ClergyEmpData, "grossPay"))}</td>
                                                        <td className="customTd bold-500 totol-font-color">$&nbsp;{numberWithCommas(Getsum(this.state.ClergyEmpData, "housing"))}</td>
                                                        <td className="customTd bold-500 totol-font-color">$&nbsp;{numberWithCommas(Getsum(this.state.ClergyEmpData, "sswh"))}</td>
                                                        <td className="customTd bold-500 totol-font-color">$&nbsp;{numberWithCommas(Getsum(this.state.ClergyEmpData, "medWH"))}</td>
                                                        <td className="customTd bold-500 totol-font-color">$&nbsp;{numberWithCommas(Getsum(this.state.ClergyEmpData, "netPay"))}</td>
                                                        <td className="customTd bold-500 totol-font-color">$&nbsp;{numberWithCommas(Getsum(this.state.ClergyEmpData, "difference"))}</td>
                                                        <td className="customTd bold-500 totol-font-color"></td>

                                                    </tr>



                                                </React.Fragment>}
                                                <tr className={this.state.collapse2 ? "bg-active" : ""} onClick={() => this.onRowExpandCollapseClick(2)}>
                                                    <td className="title-name" colSpan="16">
                                                        {
                                                            this.state.collapse2 ? <i className="zmdi zmdi-minus-circle-outline"></i> : <i className="zmdi zmdi-plus-circle-o"></i>
                                                        }
                                                        <span className="account-text">Non-Clergy Employee</span>
                                                    </td>
                                                </tr>

                                                {this.state.collapse2 && <React.Fragment>
                                                    {(this.state.NonClergyEmpData && this.state.NonClergyEmpData.length > 0) && this.state.NonClergyEmpData.map((item) => (
                                                        <React.Fragment>

                                                            {(this.state.NonClergyEmpDataTemp && this.state.NonClergyEmpDataTemp.length > 0) && this.state.NonClergyEmpDataTemp.filter(a => a.employeeName === item).map((data) => (
                                                                <tr>
                                                                    <React.Fragment>
                                                                        <td>{data.employeeName}</td>
                                                                        <td>{moment(data.date).format("MM/DD/YYYY")}</td>
                                                                        <td>{data.num}</td>
                                                                        <td>{data.transactionType} </td>
                                                                        <td className="customTd" >{data.amount == 0 ? "-" : "$ " + numberWithCommas(data.amount)}</td>
                                                                        <td className="customTd" >{data.grossPay == 0 ? "-" : "$ " + numberWithCommas(data.grossPay)}</td>
                                                                        <td className="customTd" >{data.housing == 0 ? "-" : "$ " + numberWithCommas(data.housing)}</td>
                                                                        <td className="customTd" >{data.sswh == 0 ? "-" : "$ " + numberWithCommas(data.sswh)}</td>
                                                                        <td className="customTd" >{data.medWH == 0 ? "-" : "$ " + numberWithCommas(data.medWH)}</td>
                                                                        <td className="customTd" >{data.netPay == 0 ? "-" : "$ " + numberWithCommas(data.netPay)}</td>
                                                                        <td className="customTd" >{data.difference == 0 ? "-" : "$ " + numberWithCommas(data.difference)}</td>
                                                                        <td className="text-center" style={{ width: 50 }}> <img src="/vendors/Shape 1.png" alt="link" onClick={() => this.onLinkClick(data)} ></img></td>
                                                                    </React.Fragment>
                                                                </tr>
                                                            ))}

                                                            <tr>
                                                                <td colspan='4' className='bold-500 totol-font-color'>Total for {item}</td>
                                                                <td className="customTd bold-500 totol-font-color">$&nbsp;{numberWithCommas(Getsum(this.state.NonClergyEmpDataTemp.filter(a => a.employeeName === item), "amount"))}</td>
                                                                <td className="customTd bold-500 totol-font-color">$&nbsp;{numberWithCommas(Getsum(this.state.NonClergyEmpDataTemp.filter(a => a.employeeName === item), "grossPay"))}</td>
                                                                <td className="customTd bold-500 totol-font-color">$&nbsp;{numberWithCommas(Getsum(this.state.NonClergyEmpDataTemp.filter(a => a.employeeName === item), "housing"))}</td>
                                                                <td className="customTd bold-500 totol-font-color">$&nbsp;{numberWithCommas(Getsum(this.state.NonClergyEmpDataTemp.filter(a => a.employeeName === item), "sswh"))}</td>
                                                                <td className="customTd bold-500 totol-font-color">$&nbsp;{numberWithCommas(Getsum(this.state.NonClergyEmpDataTemp.filter(a => a.employeeName === item), "medWH"))}</td>
                                                                <td className="customTd bold-500 totol-font-color">$&nbsp;{numberWithCommas(Getsum(this.state.NonClergyEmpDataTemp.filter(a => a.employeeName === item), "netPay"))}</td>
                                                                <td className="customTd bold-500 totol-font-color">$&nbsp;{numberWithCommas(Getsum(this.state.NonClergyEmpDataTemp.filter(a => a.employeeName === item), "difference"))}</td>
                                                                <td className="customTd bold-500 totol-font-color"></td>

                                                            </tr>
                                                        </React.Fragment>
                                                    ))}

                                                    <tr>
                                                        <td colspan='4' className='bold-500'>Total Non-Clergy Employees</td>
                                                        <td className="customTd bold-500 totol-font-color">$&nbsp;{numberWithCommas(Getsum(this.state.NonClergyEmpDataTemp, "amount"))}</td>
                                                        <td className="customTd bold-500 totol-font-color">$&nbsp;{numberWithCommas(Getsum(this.state.NonClergyEmpDataTemp, "grossPay"))}</td>
                                                        <td className="customTd bold-500 totol-font-color">$&nbsp;{numberWithCommas(Getsum(this.state.NonClergyEmpDataTemp, "housing"))}</td>
                                                        <td className="customTd bold-500 totol-font-color">$&nbsp;{numberWithCommas(Getsum(this.state.NonClergyEmpDataTemp, "sswh"))}</td>
                                                        <td className="customTd bold-500 totol-font-color">$&nbsp;{numberWithCommas(Getsum(this.state.NonClergyEmpDataTemp, "medWH"))}</td>
                                                        <td className="customTd bold-500 totol-font-color">$&nbsp;{numberWithCommas(Getsum(this.state.NonClergyEmpDataTemp, "netPay"))}</td>
                                                        <td className="customTd bold-500 totol-font-color">$&nbsp;{numberWithCommas(Getsum(this.state.NonClergyEmpDataTemp, "difference"))}</td>
                                                        <td className="customTd bold-500 totol-font-color"></td>

                                                    </tr>



                                                </React.Fragment>}
                                                <tr>
                                                    <td colspan='4' className='bold-500 totol-font-color' style={{ fontSize: 17 }}>Total All Employees</td>
                                                    <td className="customTd bold-500 totol-font-color" style={{ fontSize: 15 }}>$&nbsp;{numberWithCommas(parseFloat(Getsum(this.state.NonClergyEmpDataTemp, "amount")) + parseFloat(Getsum(this.state.ClergyEmpData, "amount")))}</td>
                                                    <td className="customTd bold-500 totol-font-color" style={{ fontSize: 15 }}>$&nbsp;{numberWithCommas(parseFloat(Getsum(this.state.NonClergyEmpDataTemp, "grossPay")) + parseFloat(Getsum(this.state.ClergyEmpData, "grossPay")))}</td>
                                                    <td className="customTd bold-500 totol-font-color" style={{ fontSize: 15 }}>$&nbsp;{numberWithCommas(parseFloat(Getsum(this.state.NonClergyEmpDataTemp, "housing")) + parseFloat(Getsum(this.state.ClergyEmpData, "housing")))}</td>
                                                    <td className="customTd bold-500 totol-font-color" style={{ fontSize: 15 }}>$&nbsp;{numberWithCommas(parseFloat(Getsum(this.state.NonClergyEmpDataTemp, "sswh")) + parseFloat(Getsum(this.state.ClergyEmpData, "sswh")))}</td>
                                                    <td className="customTd bold-500 totol-font-color" style={{ fontSize: 15 }}>$&nbsp;{numberWithCommas(parseFloat(Getsum(this.state.NonClergyEmpDataTemp, "medWH")) + parseFloat(Getsum(this.state.ClergyEmpData, "medWH")))}</td>
                                                    <td className="customTd bold-500 totol-font-color" style={{ fontSize: 15 }}>$&nbsp;{numberWithCommas(parseFloat(Getsum(this.state.NonClergyEmpDataTemp, "netPay")) + parseFloat(Getsum(this.state.ClergyEmpData, "netPay")))}</td>
                                                    <td className="customTd bold-500 totol-font-color" style={{ fontSize: 15 }}>$&nbsp;{numberWithCommas(parseFloat(Getsum(this.state.NonClergyEmpDataTemp, "difference")) + parseFloat(Getsum(this.state.ClergyEmpData, "difference")))}</td>
                                                    <td className="customTd bold-500 totol-font-color" style={{ fontSize: 15 }}></td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <hr></hr>
                                    <div className="table-responsive-material scroll-x">
                                        <div className='bold-500' style={{ fontSize: 22 }}>Other Payments To Employees</div>
                                        <table className="table tablebox account-reconcilliation">

                                            {this.state.OtherPaymentEmpDataTemp != null && this.state.OtherPaymentEmpDataTemp.map((item, i) => (
                                                <div className="" >
                                                    <div className="complince-review-box">
                                                        <div className="left-box">
                                                            <h5>{item}</h5>
                                                        </div>
                                                    </div>
                                                    <div className="">
                                                        {/* table-responsive-material */}
                                                        <div className=""></div>
                                                        <table className="table">
                                                            <thead>
                                                                <tr className="header-bg-color">
                                                                    <th>
                                                                        Date
                                                                    </th>
                                                                    <th>
                                                                        Type
                                                                    </th>
                                                                    <th>
                                                                        Account
                                                                    </th>
                                                                    <th>
                                                                        Number
                                                                    </th>
                                                                    <th>
                                                                        Memo/Description
                                                                    </th>
                                                                    <th className="customTd">
                                                                        Total Payment
                                                                    </th>
                                                                    <th className="textCenter">
                                                                        Internal Notes
                                                                    </th>
                                                                    <th>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.OtherPaymentEmpData != null & this.state.OtherPaymentEmpData.length > 0 ?
                                                                    <React.Fragment key={i}>

                                                                        <tr>
                                                                            <td colSpan="8" align="left" className='bold-500'> <b>Bills</b> </td>
                                                                        </tr>

                                                                        {this.state.OtherPaymentEmpData != null && this.state.OtherPaymentEmpData.filter(a => a.transactionType === "Bill" && a.employeeName == item).map((data, j) => (
                                                                            <React.Fragment>
                                                                                <tr onClick={(e) => this.onRowClick('1', j, 'Bill', item, data, e)}>
                                                                                    <td>{moment(data.date).format("MM/DD/YYYY")}</td>
                                                                                    <td>{data.transactionType}</td>
                                                                                    <td>{data.accountName}</td>
                                                                                    <td>{data.num}</td>
                                                                                    <td className="textOverFlow">{data.memoOrDescription}</td>
                                                                                    <td className="customTd">$&nbsp;{numberWithCommas(data.amount)}</td>
                                                                                    <td className="textOverFlow textCenter">{data.internalNotes}</td>
                                                                                    <td><img src="/vendors/Shape 1.png" alt="link" onClick={() => this.onLinkClick(data)}></img></td>
                                                                                </tr>
                                                                                {data.isExpand &&
                                                                                    <tr>
                                                                                        <td colspan="8">
                                                                                            <div className="row">
                                                                                                <div className="col-md-5">
                                                                                                    <div className="row">
                                                                                                        <div className="col-md-3">
                                                                                                            <label className="input-title">Internal Notes:</label>
                                                                                                        </div>
                                                                                                        <div className="col-md-9">
                                                                                                            <Textarea className="placheholder-color"
                                                                                                                value={data.internalNotes} name="internalNotes"
                                                                                                                style={{ 'lineHeight': '20px', width: '100%', border: 'none', resize: 'none', padding: '6px', paddingLeft: '10px', borderRadius: '3px', border: '1px solid #ccc', overflow: 'hidden' }}
                                                                                                                resize={"false"} placeholder=""
                                                                                                                onChange={(e) => this.handalTextArea(e, j, "Bill", item, "1")}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-2">
                                                                                                    <Button variant="raised" className="jr-btn jr-btn-sm bg-teal text-white" onClick={() => this.onRowUpdateClick(data)}>SAVE</Button>
                                                                                                    <Button variant="raised" className="jr-btn jr-btn-sm bg-danger text-white" onClick={() => this.onRowCancelClick('1', j, 'Bill', item, data)}>CANCEL</Button>
                                                                                                </div>
                                                                                            </div>

                                                                                        </td>
                                                                                    </tr>}
                                                                            </React.Fragment>

                                                                        ))}
                                                                        <tr>
                                                                            <td colSpan="4"></td>
                                                                            <td><h2 className="total-payments-text">Total Bills</h2></td>
                                                                            <td className="customTd bold-500">$&nbsp;{this.GetSumwithAmountFormat(this.state.OtherPaymentEmpData.filter(a => a.transactionType === "Bill" && a.employeeName == item), "amount")}</td>
                                                                            <td colspan="2"></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan="8" align="left" className='bold-500'> <b>Payments </b> </td>
                                                                        </tr>
                                                                        {this.state.OtherPaymentEmpData != null && this.state.OtherPaymentEmpData.filter(a => a.transactionType !== "Bill" && a.employeeName == item).map((data, j) => (
                                                                            <React.Fragment>
                                                                                <tr onClick={(e) => this.onRowClick('1', j, 'NoBill', item, data, e)}>
                                                                                    <td>{moment(data.date).format("MM/DD/YYYY")}</td>
                                                                                    <td>{data.transactionType}</td>
                                                                                    <td>{data.accountName}</td>
                                                                                    <td>{data.num}</td>
                                                                                    <td className="textOverFlow">{data.memoOrDescription}</td>
                                                                                    <td className="customTd">$&nbsp;{numberWithCommas(data.amount)}</td>
                                                                                    <td className="textOverFlow textCenter">{data.internalNotes}</td>
                                                                                    <td><img src="/vendors/Shape 1.png" alt="link" onClick={() => this.onLinkClick(data)}></img></td>
                                                                                </tr>
                                                                                {data.isExpand &&
                                                                                    <tr>
                                                                                        <td colspan="8">
                                                                                            <div className="row">
                                                                                                <div className="col-md-5">
                                                                                                    <div className="row">
                                                                                                        <div className="col-md-3">
                                                                                                            <label className="input-title">Internal Notes:</label>
                                                                                                        </div>
                                                                                                        <div className="col-md-9">
                                                                                                            <Textarea className="placheholder-color"
                                                                                                                value={data.internalNotes} name="internalNotes"
                                                                                                                style={{ 'lineHeight': '20px', width: '100%', border: 'none', resize: 'none', padding: '6px', paddingLeft: '10px', borderRadius: '3px', border: '1px solid #ccc', overflow: 'hidden' }}
                                                                                                                resize={"false"} placeholder=""
                                                                                                                onChange={(e) => this.handalTextArea(e, j, "NoBill", item, "1")}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-2">
                                                                                                    <Button variant="raised" className="jr-btn jr-btn-sm bg-teal text-white" onClick={() => this.onRowUpdateClick(data)}>SAVE</Button>
                                                                                                    <Button variant="raised" className="jr-btn jr-btn-sm bg-danger text-white" onClick={() => this.onRowCancelClick('1', j, 'NoBill', item, data)}>CANCEL</Button>
                                                                                                </div>
                                                                                            </div>

                                                                                        </td>
                                                                                    </tr>}
                                                                            </React.Fragment>
                                                                        ))}

                                                                        <tr>
                                                                            <td colSpan="4"></td>
                                                                            <td><h2 className="total-payments-text">Total Payments</h2></td>
                                                                            <td className="customTd bold-500">$&nbsp;{numberWithCommas(Getsum(this.state.OtherPaymentEmpData.filter(a => a.transactionType !== "Bill" && a.employeeName == item), "amount"))}</td>
                                                                            <td colspan="2"></td>
                                                                        </tr>
                                                                    </React.Fragment>

                                                                    : ""
                                                                }

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            ))

                                            }
                                            {(!this.state.OtherPaymentEmpDataTemp || this.state.OtherPaymentEmpDataTemp.length === 0) &&
                                                <table className="table">
                                                    <thead>
                                                        <tr className="header-bg-color">
                                                            <th>
                                                                Date
                                                            </th>
                                                            <th>
                                                                Type
                                                            </th>
                                                            <th>
                                                                Account
                                                            </th>
                                                            <th>
                                                                Number
                                                            </th>
                                                            <th>
                                                                Memo/Description
                                                            </th>
                                                            <th>
                                                                Total Payment
                                                            </th>
                                                            <th>
                                                                Internal Notes
                                                            </th>
                                                            <th>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-center" colSpan="8">
                                                                No data found
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            }


                                        </table>
                                        <hr></hr>
                                        <div className="bold-500" style={{ fontSize: 22 }}>Add Worker To Employee List</div>
                                        <table className="table tablebox account-reconcilliation">
                                            {this.state.addWorkerToEmployeeListTemp != null && this.state.addWorkerToEmployeeListTemp.map((item, i) => (
                                                <div className="" >
                                                    <div className="complince-review-box">
                                                        <div className="left-box">
                                                            {item == "" ?
                                                                <span className="alert">Transactions Missing Vendor Name</span>
                                                                :
                                                                <h5>{item}</h5>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="">
                                                        {/* table-responsive-material */}
                                                        <div className=""></div>
                                                        <table className="table">
                                                            <thead>
                                                                <tr style={{ backgroundColor: "rgb(200,55,55)" }}>
                                                                    <th>
                                                                        Date
                                                                    </th>
                                                                    <th>
                                                                        Type
                                                                    </th>
                                                                    <th>
                                                                        Account
                                                                    </th>
                                                                    <th>
                                                                        Number
                                                                    </th>
                                                                    <th>
                                                                        Memo/Description
                                                                    </th>
                                                                    <th className="customTd">
                                                                        Total Payment
                                                                    </th>
                                                                    <th className="textCenter">
                                                                        Internal Notes
                                                                    </th>
                                                                    <th>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.addWorkerToEmployeeList != null && this.state.addWorkerToEmployeeList.length > 0 ?
                                                                    <React.Fragment key={i}>

                                                                        <tr>
                                                                            <td colSpan="8" className='bold-500' align="left"  > <b>Bills</b> </td>
                                                                        </tr>

                                                                        {this.state.addWorkerToEmployeeList != null && this.state.addWorkerToEmployeeList.filter(a => a.transactionType === "Bill" && a.employeeName == item).map((data, j) => (
                                                                            <React.Fragment>
                                                                                <tr onClick={(e) => this.onRowClick('0', j, 'Bill', item, data, e)}>
                                                                                    <td>{moment(data.date).format("MM/DD/YYYY")}</td>
                                                                                    <td>{data.transactionType}</td>
                                                                                    <td>{data.accountName}</td>
                                                                                    <td>{data.num}</td>
                                                                                    <td className="textOverFlow">{data.memoOrDescription}</td>
                                                                                    <td className="customTd">$&nbsp;{numberWithCommas(data.amount)}</td>
                                                                                    <td className="textOverFlow textCenter">{data.internalNotes}</td>
                                                                                    <td><img src="/vendors/Shape 1.png" alt="link" onClick={() => this.onLinkClick(data)}></img></td>
                                                                                </tr>
                                                                                {data.isExpand &&
                                                                                    <tr>
                                                                                        <td colspan="8">
                                                                                            <div className="row">
                                                                                                <div className="col-md-5">
                                                                                                    <div className="row">
                                                                                                        <div className="col-md-3">
                                                                                                            <label className="input-title">Internal Notes:</label>
                                                                                                        </div>
                                                                                                        <div className="col-md-9">
                                                                                                            <Textarea className="placheholder-color"
                                                                                                                value={data.internalNotes} name="internalNotes"
                                                                                                                style={{ 'lineHeight': '20px', width: '100%', border: 'none', resize: 'none', padding: '6px', paddingLeft: '10px', borderRadius: '3px', border: '1px solid #ccc', overflow: 'hidden' }}
                                                                                                                resize={"false"} placeholder=""
                                                                                                                onChange={(e) => this.handalTextArea(e, j, "Bill", item, "0")}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-2">
                                                                                                    <Button variant="raised" className="jr-btn jr-btn-sm bg-teal text-white" onClick={() => this.onRowUpdateClick(data)}>SAVE</Button>
                                                                                                    <Button variant="raised" className="jr-btn jr-btn-sm bg-danger text-white" onClick={() => this.onRowCancelClick('0', j, 'Bill', item, data)}>CANCEL</Button>
                                                                                                </div>
                                                                                            </div>

                                                                                        </td>
                                                                                    </tr>}
                                                                            </React.Fragment>
                                                                        ))}
                                                                        <tr>
                                                                            <td colSpan="4"></td>
                                                                            <td><h2 className="total-payments-text">Total Bills</h2></td>
                                                                            <td className="customTd bold-500">$&nbsp;{numberWithCommas(Getsum(this.state.addWorkerToEmployeeList.filter(a => a.transactionType === "Bill" && a.employeeName == item), "amount"))}</td>
                                                                            <td colspan="2" ></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan="8" className='bold-500' align="left" > <b>Payments </b> </td>
                                                                        </tr>
                                                                        {this.state.addWorkerToEmployeeList != null && this.state.addWorkerToEmployeeList.filter(a => a.transactionType !== "Bill" && a.employeeName == item).map((data, j) => (
                                                                            <React.Fragment>
                                                                                <tr onClick={(e) => this.onRowClick('0', j, 'NoBill', item, data, e)}>
                                                                                    <td>{moment(data.date).format("MM/DD/YYYY")}</td>
                                                                                    <td>{data.transactionType}</td>
                                                                                    <td>{data.accountName}</td>
                                                                                    <td>{data.num}</td>
                                                                                    <td className="textOverFlow">{data.memoOrDescription}</td>
                                                                                    <td className="customTd">$&nbsp;{numberWithCommas(data.amount)}</td>
                                                                                    <td className="textOverFlow textCenter">{data.internalNotes}</td>
                                                                                    <td><img src="/vendors/Shape 1.png" alt="link" onClick={() => this.onLinkClick(data)}></img></td>
                                                                                </tr>
                                                                                {data.isExpand &&
                                                                                    <tr>
                                                                                        <td colspan="8">
                                                                                            <div className="row">
                                                                                                <div className="col-md-5">
                                                                                                    <div className="row">
                                                                                                        <div className="col-md-3">
                                                                                                            <label className="input-title">Internal Notes:</label>
                                                                                                        </div>
                                                                                                        <div className="col-md-9">
                                                                                                            <Textarea className="placheholder-color"
                                                                                                                value={data.internalNotes} name="internalNotes"
                                                                                                                style={{ 'lineHeight': '20px', width: '100%', border: 'none', resize: 'none', padding: '6px', paddingLeft: '10px', borderRadius: '3px', border: '1px solid #ccc', overflow: 'hidden' }}
                                                                                                                resize={"false"} placeholder=""
                                                                                                                onChange={(e) => this.handalTextArea(e, j, "NoBill", item, "0")}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-2">
                                                                                                    <Button variant="raised" className="jr-btn jr-btn-sm bg-teal text-white" onClick={() => this.onRowUpdateClick(data)}>SAVE</Button>
                                                                                                    <Button variant="raised" className="jr-btn jr-btn-sm bg-danger text-white" onClick={(e) => this.onRowCancelClick('0', j, 'NoBill', item, data)}>CANCEL</Button>
                                                                                                </div>
                                                                                            </div>

                                                                                        </td>
                                                                                    </tr>}
                                                                            </React.Fragment>

                                                                        ))}
                                                                        <tr>
                                                                            <td colSpan="4"></td>
                                                                            <td><h2 className="total-payments-text">Total Payments</h2></td>
                                                                            <td className="customTd bold-500">$&nbsp;{numberWithCommas(Getsum(this.state.addWorkerToEmployeeList.filter(a => a.transactionType !== "Bill" && a.employeeName == item), "amount"))}</td>
                                                                            <td colspan='2'></td>
                                                                        </tr>
                                                                    </React.Fragment>
                                                                    :
                                                                    <tr>
                                                                        <td className="text-center" colSpan="8">
                                                                            No Data Found!!!
                                                                        </td>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                            ))}

                                            {(!this.state.addWorkerToEmployeeListTemp || this.state.addWorkerToEmployeeListTemp.length === 0) &&
                                                <table className="table">
                                                    <thead>
                                                        <tr style={{ backgroundColor: "rgb(200,55,55)" }}>
                                                            <th>
                                                                Date
                                                            </th>
                                                            <th>
                                                                Type
                                                            </th>
                                                            <th>
                                                                Account
                                                            </th>
                                                            <th>
                                                                Number
                                                            </th>
                                                            <th>
                                                                Memo/Description
                                                            </th>
                                                            <th>
                                                                Total Payment
                                                            </th>
                                                            <th>
                                                                Internal Notes
                                                            </th>
                                                            <th>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-center" colSpan="8">
                                                                No data found
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            }
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {

    return {
        PYTaxData: state.PYTaxData.PYTaxData,
        MarkStatus: state.PYTaxData.MarkStatus,
        LEDGER_UPDATE: state.ledgers.LEDGER_UPDATE
    };
}


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        GetPayrollTaxComputation,
        MarkVendorReview,
        LedgerUpdate
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(accountPayrollTaxComputations);
import React, { Component } from 'react';
import { PageSizeOption } from './../common/commonServices';
import { debuggerStatement } from '@babel/types';


class PaginationComponent extends React.Component {

    constructor(props) {        
        super(props)        
        let displayrecords = "";
        if (props.selectedPageNo === 1) {
            if (props.TotalRecords === 0) {
                displayrecords = "0";
            }
            else {
                displayrecords = "1-" + props.selectedSize;
            }
        }
        else {            
            displayrecords = ((props.selectedSize * props.selectedPageNo) + 1) - props.selectedSize + "-" + (props.selectedSize * props.selectedPageNo);
        }                     
                
        this.state = {
            PageNo: props.selectedPageNo,
            PageSize: props.selectedSize,
            EnablePrevious: false,
            EnableNext: true,
            displayRecords: displayrecords,
            TotalRecords: props.TotalRecords,
            TotalPages: 0,
            pageArray: [1],
            CurrentRecords:props.CurrentRecords
        }


    }

    CalculateTotalPage = () => {        
        var pages = parseInt((this.state.TotalRecords + this.state.PageSize - 1) / this.state.PageSize);
        let eNext = true;
        let ePrevious = true;
        if (this.state.TotalRecords === 0) {
            eNext = false;
        }

        if (this.state.PageNo === 1 || pages === 1) {
            ePrevious = false;
        }


        let temp = [];
        var currentPage = this.state.PageNo;
        if (currentPage + 4 > pages) {
            currentPage = currentPage - (pages - currentPage);
            if (currentPage < 1) {
                currentPage = 1
            }
        }

        for (let k = currentPage; k <= pages; k++) {

            if (k < (currentPage + 4)) {
                temp.push((k));
            }
        }

        if (temp.length > 0) {
            let lastindex = temp[temp.length - 1];
            if (lastindex === pages && currentPage === pages) {
                eNext = false;
            }
        }

        let displayOff = this.state.displayRecords;
        if(currentPage===pages)
        {
            let dt = 0;
            let temp = ((this.state.PageSize * currentPage) + 1) - this.state.PageSize;
            dt = temp>10? ((temp-1) + this.state.CurrentRecords):this.state.CurrentRecords;
            displayOff = temp + "-" + dt;
        }

        this.setState({ pageArray: temp, TotalPages: pages, EnableNext: eNext, EnablePrevious: ePrevious,displayRecords: displayOff});
    }


    HandleChange = (event) => {
        this.props.ChangePageSize(event.target.value);
    }

    HandleNextPage = (event) => {
        if (this.state.PageNo < this.state.PageSize) {
            this.props.ChangePage(this.state.PageNo + 1);
        }
    }

    selectPage = (page) => {

        if (this.state.PageNo !== page) {
            this.props.ChangePage(page);
        }
    }

    HandlePreviousPage = (event) => {
        if (this.state.PageNo > 1) {
            var page = this.state.PageNo === 2 ? 1 : this.state.PageNo - 1;
            this.props.ChangePage(page);
        }
    }

    componentDidMount() {
        this.CalculateTotalPage();
    }

    render() {        
        return (
            <React.Fragment>
                {this.state.TotalRecords > 0 ?
                    <div className="d-table d-table-box">
                        <div className="pagination-main-box float-left">
                            <div className="items-count">
                                <p> <span> {this.state.displayRecords} </span> of <span> {this.state.TotalRecords} items</span></p>
                            </div>
                            <div className="pagination-box">
                                <ul>
                                    <li>
                                        {this.state.EnablePrevious ?
                                            <button onClick={this.HandlePreviousPage} > <i className="zmdi zmdi-chevron-left"></i> </button>
                                            :
                                            <button disabled style={{ opacity: 0.4 }} > <i className="zmdi zmdi-chevron-left"></i> </button>
                                        }
                                    </li>
                                    {/* <li><span>{this.state.PageNo} </span> </li> */}
                                    {
                                        this.state.pageArray.map((j) => (
                                            <li key={j} className={this.state.PageNo === j ? "ActivePage" : ""} onClick={() => this.selectPage(j)}><span>{j} </span></li>
                                        ))
                                    }
                                    <li>
                                        {this.state.EnableNext ?
                                            <button onClick={this.HandleNextPage}><i className="zmdi zmdi-chevron-right"></i></button>
                                            :
                                            <button disabled style={{ opacity: 0.4 }} ><i className="zmdi zmdi-chevron-right"></i></button>
                                        }
                                    </li>
                                </ul>
                            </div>
                            <select className="select-box" onChange={this.HandleChange} value={this.state.PageSize} >
                                {PageSizeOption.map((item, j) => (
                                    <React.Fragment key={j}>
                                        {
                                            <option value={item.value}>  {item.label} </option>
                                        }
                                    </React.Fragment>
                                ))}
                            </select>
                        </div>
                    </div>
                    :
                    ""
                }
            </React.Fragment>
        )
    }
}

export default PaginationComponent;
import React from 'react';
import {connect} from 'react-redux'
import {logoutUser} from '../../appRedux/actions/Auth';
import IntlMessages from '../../util/IntlMessages';
import  jwt_decode from 'jwt-decode';
import {Link} from 'react-router-dom';
import ResetForm from '../../Forms/ResetPassword.js';

class UserInfoPopup extends React.Component {

     constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            open: false,
            isReset:false
        };
       this.handleReset =  this.handleReset.bind(this);
     }

    handleReset = () => {
       this.setState({isReset: true});
    }

    handleCancel = () => {
        this.setState({isReset:false});
    };

    handleSave = (state) => {
        this.setState({
            isReset:state
        });
    }

    render() {
        const user = JSON.parse(localStorage.getItem('userData'));
        let decode=jwt_decode(user.Authorization);
        const name=decode['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'];
        const role = decode['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
        return (
            <div>
             <ResetForm
                    handleSave={this.handleSave}
                    visible={this.state.isReset}
                    handleCancel={this.handleCancel}
                    />
                <div className="user-profile">
                    <img className="user-avatar border-0 size-40 rounded-circle"
                         src="http://via.placeholder.com/150x150"
                         alt="User"/>
                    <div className="user-detail ml-2">
                        <h4 className="user-name mb-0">{name}</h4>
                        <small>{role}</small>
                    </div>
                </div>
                <div style={{ cursor:'pointer'}} onClick={ this.handleReset }className="dropdown-item text-muted">
                    <i className="zmdi zmdi-settings zmdi-hc-fw mr-1"/>
                    <IntlMessages id="popup.resetPassword"/>
                </div>
                <a className="dropdown-item text-muted" href="javascript:void(0)" onClick={() => {
                    this.props.logoutUser()
                }}>
                    <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-1"/>
                    <IntlMessages id="popup.logout"/>
                </a>
            </div>
        );
    }
}

export default connect(null, {logoutUser})(UserInfoPopup);

import baseService from './baseService';

export function Accounts(companyId){
    return baseService.get('QuickbookAccount/all',{params:{companyId}});
}


export function Accounts_BES(companyId){
    return baseService.get('QuickbookAccount/all_BES',{params:{companyId}});
}
export function getAccounts(CompanyID,search,pageNumber,pageSize,sortField,sortFormat){
    return baseService.post(`/QuickbookAccount/all_page`,{ CompanyID,search,pageNumber,pageSize,sortField,sortFormat });
}
export function updateAccocunt(data){
    return baseService.put('QuickbookAccount?companyId=' + data["companyID"], data);
}

export function TransactionTypes(){
    return baseService.get('/TransactionType/Get/all');
}

export function AccountTypes(){
    return baseService.get('/AccountType/Get/all');
}

export function CompanyAccountTypes(companyId){
    return baseService.get('/AccountType/Get',{params:{companyId}});
}

export function CompanyTransactionTypes(companyId){
    return baseService.get('/TransactionType/Get', {params:{companyId}});
}

export function AssignAccountTypes(companyId,data){
    return baseService.post('/AccountType/Assign',data,{params:{companyId}});
}

export function AssignTransactionTypes(companyId,data){
    return baseService.post('/TransactionType/Assign',data,{params:{companyId}});
}

export function searchAccount(companyId,text)
{
    return baseService.get(`/QuickbookAccount/${companyId}/search/${text}`);
}

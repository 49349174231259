import {GET_COMMENTS_DATA,UPDATE_STATUS,GET_QUESTION_DATA,CLOSED_QUESTION} from "../actions/QuestionCommentsAction";
const initial_state ={
    QuestionData :null,
    CommentsData:null,
    AddComman:null,
    ClosedUpdate:null    
};

export default function authReducer(state = initial_state, action) {
    switch (action.type) {
        case GET_QUESTION_DATA:
            return { ...state, QuestionData: action.payload.data };
        case GET_COMMENTS_DATA:
            return { ...state,CommentsData:action.payload.data };
        case UPDATE_STATUS:
            return  { ...state,AddComman:action.payload.data };        
        case CLOSED_QUESTION:            
            return  { ...state,ClosedUpdate:action.payload.data };
        default: 
            return state;
    }
}


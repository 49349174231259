
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
// import TextField from '@material-ui/core/TextField';
// import CommonComments from './../../../common/Comments';
import PaginationComponent from './../../../common/Pagination';
import {
    GET_CompanyDashboard_PayrollVendorReportDetailList,
    Update_CompanyDashboard_UpdateVendorW9,
    Post_Vendor_UpdateVendorStatus
} from '../../../../appRedux/actions/ReportDetailAction';
import { ReportType, MaskTaxIdentifier,LinkType } from '../../../common/commonServices'
import moment from 'moment';
import { bindActionCreators } from 'redux';
import CircularProgress from './../../../../components/CircularProgress';
import { toast } from 'react-toastify';
// import Switch from '@material-ui/core/Switch';

class AddEmpList extends Component {

    constructor(props)
    {
        super(props)
        let endDate = new Date();
        let startDate = new Date(endDate.getFullYear(), 0, 1);

        if (endDate.getMonth() <= 2) {
            startDate = new Date(startDate.getFullYear() - 1, 0, 1);
            endDate = new Date(endDate.getFullYear() - 1, 11, 31);
        }
        this.state = {
            parantData: this.props.location.state ? this.props.location.state : null,
            MissingData: [],
            companyId: 0,
            sortField: 'displayName',
            sortFormat: "A",
            PageNo: 1,
            PageSize: 10,
            ColorCode: "#f0ca4c",
            IsCommentModelOpen: false,
            sDate:startDate,// new Date(2019, 0, 1),
            eDate: endDate,//new Date(),
            loading: false,
            TotalRecords: 0,
            CommentReferenceId: 0,
            IsEmbeddedPage: false,
            SelectedRecords: [],
            SelectALL: false,
            editIndex: -1,
            editData: null
        }
    }

    onSortChange = (fieldName) => {
        if (this.state.sortField === fieldName) {
            let formate = this.state.sortFormat === 'A' ? 'D' : 'A';
            this.setState({ sortFormat: formate, loading: true }, () => {
                this.GetReportData();
            });
        } else {
            this.setState({ sortField: fieldName, sortFormat: 'A', loading: true }, () => {
                this.GetReportData();
            });
        }
    }

    onRefreshDataClick = () => {
        if (!this.state.loading) {
            this.setState({ loading: true }, () => {
                this.GetReportData(true);
            });
        }
    }

    ChangePageSize = (size) => {

        this.setState({ loading: true, PageSize: parseInt(size), PageNo: 1 }, () => {
            this.GetReportData();
        });
    }

    ChangePage = (page) => {
        this.setState({ loading: true, PageNo: page, }, () => {
            this.GetReportData();
        });
    }

    handleFormChange = (e, target) => {
        let data = { ...this.state.editData };
        data[e.target.name] = e.target.value;
        this.setState({ editData: data });
    }

    GetReportData(IsRefresh = false) {
        let state = this.state;
        let body = {
            companyID: state.companyId,
            reportType: ReportType.AddToEmployee,
            fromDate: moment(state.sDate).format("MM/DD/YYYY"),
            toDate: moment(state.eDate).format("MM/DD/YYYY"),
            sortField: state.sortField,
            sortFormat: state.sortFormat,
            pageNumber: state.PageNo,
            pageSize: state.PageSize,
            needUpdatQBORecords: IsRefresh
        };
        this.props.GET_CompanyDashboard_PayrollVendorReportDetailList(body);
    }

    onRowEdit = (i, rowData) => {
        let body = {
            listID: rowData.listID,
            companyID: rowData.companyID,
            vendorID: rowData.vendorID,
            name: rowData.displayName,
            address: rowData.bill1,
            city: rowData.bill3,
            state: rowData.bill4,
            zip: rowData.bill5,
            tax: rowData.taxIdentifier,
        }
        this.setState({ editData: body, editIndex: i });
    };

    onRowCancel = (rowIndex, rowData) => {
        this.setState({ editData: null, editIndex: -1 });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.ReportData !== this.props.ReportData) {
            if (nextProps.ReportData.transactionStatus === 0) {
                var totalrecords = 0;
                let items = nextProps.ReportData.returnObject;
                if (nextProps.ReportData.returnObject != null && nextProps.ReportData.returnObject.length > 0) {
                    totalrecords = nextProps.ReportData.returnObject[0].totalrows
                    items.map((dt, index) => {
                        items[index].MaskTax = MaskTaxIdentifier(dt.taxIdentifier);
                    });
                }
                this.setState({ MissingData: items, TotalRecords: totalrecords, PageSize: this.state.PageSize, PageNo: this.state.PageNo, loading: false });
            }
            else {
                toast.error(nextProps.ReportData.resultMsg);
                this.setState({ loading: false });
            }
        }       
    }

    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('userData'));
        this.setState({
            companyId: user.companyId,
            loading: true
        }, () => {
            this.GetReportData();
        });
    }

    render() {
        return (
            <div className="dashboardPage">
                <div className="page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
                    <h2 className="mb-3 mb-sm-0">{JSON.parse(localStorage.getItem('userData')) != null ? JSON.parse(localStorage.getItem('userData')).companyName : ""} | {LinkType.AddToEmployeeList}</h2>
                </div>
                {this.state.loading ? <CircularProgress></CircularProgress>
                    :
                    <React.Fragment>
                        <div className="padding-box app-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="float-right">
                                                {/* <Button variant="raised" className=" jr-btn g-teal  text-white refresh-btn graycolor" onClick={() => this.OnApplyApproved()}  >Resolved</Button> */}
                                                <Button variant="raised" style={{ backgroundColor: this.state.ColorCode }} className="jr-btn text-white refresh-btn" onClick={() => this.onRefreshDataClick()}  ><i className="zmdi zmdi-refresh "></i> Refresh Data</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive-material">
                                <table className="table tablebox account-reconcilliation">
                                    <thead>
                                        <tr style={{ backgroundColor: this.state.ColorCode }}>
                                            <th className={`td-width ${this.state.sortField === 'displayName' ? this.state.sortFormat === 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('displayName')}><p className="table-th-title"> Name</p> </th>
                                            {/* <th className={`td-width ${this.state.sortField == 'bill1' ? this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('bill1')}><p className="table-th-title"> Address</p></th>
                                            <th className={`td-width-amount ${this.state.sortField == 'bill3' ? this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('bill3')}><p className="table-th-title"> City</p></th>
                                            <th className={`td-width ${this.state.sortField == 'bill4' ? this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('bill4')}><p className="table-th-title"> State</p></th>
                                            <th className={`td-width ${this.state.sortField == 'bill5' ? this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('bill5')}><p className="table-th-title"> Zip</p></th>
                                            <th className={`td-width ${this.state.sortField == 'taxIdentifier' ? this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('taxIdentifier')}><p className="table-th-title"> Tax Identifier</p></th> */}
                                            {/* <th className={`td-width ${this.state.sortField == 'taxIdentifier' ? this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('taxIdentifier')}><p className="table-th-title"> Total 1099 payments</p></th>                                             */}
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.MissingData.map((item, i) => (
                                            <React.Fragment key={i}>
                                                {this.state.editIndex !== i && <tr>
                                                    <td>{item.displayName}</td>
                                                    {/* <td>{item.bill1}</td>
                                                    <td>{item.bill3}</td>
                                                    <td>{item.bill4}</td>
                                                    <td>{item.bill5}</td>
                                                    <td>{item.MaskTax}</td> */}
                                                    {/* <td className="text-right">${item.totalPayment}</td> */}
                                                    {/* <td className="text-center">
                                                        <Switch className={item.isActive ? 'switch-box' : 'switch-box switch-inactive'} size="small" checked={item.isActive} onChange={(e) => this.vendorStatusChange(e, item)} /> <span className={item.isActive ? 'text-success' : ''}>{item.isActive ? "Active" : "Inactive"}</span>
                                                    </td> */}
                                                    {/* <td className="text-center" onClick={(e) => this.onRowEdit(i, item)}>
                                                        <i className="zmdi zmdi-edit zmdi-hc-lg mr-4 pointer"></i>
                                                    </td> */}
                                                </tr>}                                              
                                            </React.Fragment>
                                        ))}
                                        {this.state.MissingData.length === 0 && <tr>
                                            <td className="text-center" >
                                            No issues here! Keep up the good work!
                                            </td>
                                        </tr>}
                                    </tbody>
                                </table>
                            </div>
                            <PaginationComponent
                                selectedPageNo={this.state.PageNo}
                                selectedSize={this.state.PageSize}
                                TotalRecords={this.state.TotalRecords}
                                ChangePageSize={this.ChangePageSize}
                                ChangePage={this.ChangePage}
                                CurrentRecords={this.state.MissingData.length}
                            >
                            </PaginationComponent>
                        </div>
                    </React.Fragment>
                }
            </div>
        )
    }

}
const mapStateToProps = (state) => {
    return {
        ReportData: state.ReportData.ReportData,        
        UpdateDescription: state.ReportData.UpdateDescription,
        activeInactive: state.ReportData.activeInactive
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        GET_CompanyDashboard_PayrollVendorReportDetailList,
        Update_CompanyDashboard_UpdateVendorW9,
        Post_Vendor_UpdateVendorStatus
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEmpList);
import React from "react";
import Button from '@material-ui/core/Button';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { toast } from "react-toastify";
// import { withStyles } from '@material-ui/core/styles';q
import moment from 'moment';
import CircularProgress from "../../../../components/CircularProgress";
import { companyDetail } from '../../../../appRedux/actions/companiesAction';
import { GetAllSyncLogs, GetSyncDetails } from "../../../../appRedux/actions/SyncLogAction";
import { GetLogTypeName } from "../../../common/commonServices";
import PaginationComponent from "../../../common/Pagination";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toast } from "react-toastify";


class LogsPage extends React.Component {

    constructor(props) {

        super(props);
        this.sDateRef = React.createRef();

        var logDate = new Date();
        logDate.setDate(logDate.getDate() - 1);
        this.state = {
            companyId: 0,
            loading: false,
            companyList: [],
            LogData: [],
            sDate: logDate,
            selectedCompany: 0,
            search: null,
            pageNo: 1,
            pageSize: 50,
            TotalRecords: 0,
        }
    }

    ChangePageSize = (size) => {

        this.setState({ loading: true, pageSize: parseInt(size), PageNo: 1 }, () => {
            this.GetSyncData();
        });
    }

    ChangePage = (page) => {
        this.setState({ loading: true, pageNo: page, }, () => {
            this.GetSyncData();
        });
    }
    handleFocus = (e, selecter) => {
        let control = null;
        if (selecter !== undefined) {
            if (selecter === "sDate") {
                control = this.sDateRef;
                if (control.current != null) {
                    control.current.setFocus();
                }
            }
        }
    }

    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('userData'));
        this.setState({
            companyId: user.companyId,
            loading: true,
            selectedCompany:user.companyID
        }, () => {
            this.props.companyDetail();
            this.GetSyncData()
        });
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.company!==undefined && nextProps.company !== this.props.company) {
            this.setState({ loading: false, companyList: nextProps.company });
        }
        if (nextProps.syncdata!==undefined && nextProps.syncdata !== this.props.syncdata) {
            if (nextProps.syncdata.transactionStatus === 0) {
                var totalrecords = 0;
                if (nextProps.syncdata.returnObject != null && nextProps.syncdata.returnObject.length > 0) {
                    totalrecords = nextProps.syncdata.returnObject[0].totalrows
                }
                this.setState({ loading: false, TotalRecords: totalrecords, LogData: nextProps.syncdata.returnObject })
            }
            else {
                this.setState({ loading: false }, () => { toast.error(nextProps.syncdata.resultMsg) });
            }
        }
        else this.setState({ loading: false });
    }

    HandleChange = (e) => {

        this.setState({ loading: true, selectedCompany: parseInt(e.target.value) }, () => { this.GetSyncData() });
    }

    handleDateChange = (e) => {
        this.setState({ sDate: new Date(e) });
    }

    onRowEdit = (item) => {
        if(item!= null && item.recordsCount>0)
        {
            var data= {
                "companyID": this.state.selectedCompany,
                "syncMasterID": 0,
                "pageNumber": 0,
                "pageSize": 0
              }
        }
    }
    
    GetSyncData() {        
        var stateData = this.state;
        var obj = {
            "CompanyID": stateData.companyId,
            "search": stateData.search,
            "pageNumber": stateData.pageNo,
            "pageSize": stateData.pageSize,
            "fromDate": moment(stateData.sDate).format("MM/DD/YYYY"),
            "toDate": moment(stateData.sDate).format("MM/DD/YYYY")
        }

        this.props.GetAllSyncLogs(obj);
    }

    ApplyDate = () => {
        this.setState({ loading: true }, () => this.GetSyncData());
    }


    render() {
        return (
            <div className="dashboardPage">
                <div className="page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
                    <h2 className="mb-3 mb-sm-0">Logs</h2>
                </div>
                <div className="padding-box app-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="col-md-4">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="select-quater-box">
                                            <p className="sub-title">Date Selection</p>
                                            <div className="row">
                                                <div className="col-md-12 ">
                                                    <div className="datepicker-box">
                                                        <div className="date-box">
                                                            <DatePicker
                                                                selected={this.state.sDate}
                                                                onChange={(e) => this.handleDateChange(e)}
                                                                name="fromDate"
                                                                id="date"
                                                                required={true}
                                                                margin="normal"
                                                                disabledKeyboardNavigation
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                yearDropdownItemNumber={4}
                                                                maxDate={new Date()}
                                                                ref={this.sDateRef}
                                                            />
                                                            <div className="date-icon" onClick={(e) => this.handleFocus(e, 'sDate')}>
                                                                <i className="zmdi zmdi-calendar-note"></i>
                                                            </div>
                                                        </div>
                                                        {/* <div className="to-box">
                                                    <span className="text-center ledgerto-text">Search</span>
                                                </div>
                                                <div className="date-box">                            
                                                <input type="text" placeholder="search" />
                                                </div> */}
                                                    </div>
                                                    <div className="genera-apply-btn" style={{ marginTop: "5px" }}>
                                                        <Button variant="raised" className="greencolor jr-btn bg-teal text-white refresh-btn" onClick={(e) => this.ApplyDate()}  >apply</Button>
                                                        {/*  */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-6">
                                        <p className="sub-title">Select Comapny</p>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <select className="select-box" style={{ marginLeft: "0px", background: "#ffffff", border: "1px solid grey" }} onChange={(e) => this.HandleChange(e)} value={this.state.PageSize} >
                                                    <option value='0'>  All </option>
                                                    {this.state.companyList.map((item, j) => (
                                                        <React.Fragment key={j}>
                                                            {
                                                                <option value={item.companyId}>  {item.name} </option>
                                                            }
                                                        </React.Fragment>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>

                            </div>
                        </div>
                    </div>
                    {this.state.loading ? <CircularProgress></CircularProgress> :
                        <React.Fragment>
                            <div className="table-responsive-material">
                                <table className="table tablebox account-reconcilliation">
                                    <thead>
                                        <tr>
                                            <th className="td-width">Company</th>
                                            <th className="td-width">Start Date</th>
                                            <th className="td-width">End Date</th>
                                            <th className="td-width">Type </th>
                                            <th className="td-width">Total Records</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.state.LogData != null && this.state.LogData.length > 0 ?
                                            <React.Fragment>
                                                {(this.state.LogData && this.state.LogData.length > 0) && this.state.LogData.map((item, j) => (
                                                    <React.Fragment key={j}>
                                                        {
                                                            <tr onClick={(e) => this.onRowEdit(item)}>
                                                                <td >{item.companyName}</td>
                                                                <td >{item.stCreatedDate}</td>
                                                                <td >{item.recordsCount===0?item.stCreatedDate:item.stCompletedDate}</td>
                                                                <td >{GetLogTypeName(item.syncFrom)}</td>
                                                                <td >{item.recordsCount}</td>
                                                            </tr>
                                                        }
                                                    </React.Fragment>
                                                ))}
                                            </React.Fragment>
                                            :
                                            <tr>
                                                <td colSpan='5' align='center'> No Data Found </td>
                                            </tr>
                                        }

                                    </tbody>

                                </table>
                            </div>
                            <PaginationComponent
                                    selectedPageNo={this.state.pageNo}
                                    selectedSize={this.state.pageSize}
                                    TotalRecords={this.state.TotalRecords}
                                    ChangePageSize={this.ChangePageSize}
                                    ChangePage={this.ChangePage}
                                    CurrentRecords={this.state.LogData.length}
                                >
                            </PaginationComponent>
                        </React.Fragment>
                    }
                </div>
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        company: state.companyReducer.companies,
        syncdata: state.Synclog.syncdata,
        syncdetails: state.Synclog.syncdetails
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        companyDetail,
        GetAllSyncLogs,
        GetSyncDetails
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(LogsPage);
import { QB_SYNC_STATUS, QB_IS_COONNECTED, QB_SYNC, QB_TEMP_TOKEN, TOKEN_SET, QB_LOGOUT, QB_CLEAR } from '../actions/QuickbookAction';
const initial_state = {
    isConnected: false,
    syncMessages: [],
    tempToken: ''
};
export default function authReducer(state = initial_state, action) {

    switch (action.type) {
        case QB_SYNC_STATUS:
            return { ...state, syncMessages: action.payload.data };
        case QB_IS_COONNECTED:
            return { ...state, isConnected: action.payload.data };
        case QB_LOGOUT:
            return { ...state, isConnected: false, tempToken: '' };
        case QB_SYNC:
            return { ...state, ...action.payload.data };
        case QB_TEMP_TOKEN:
            return { ...state, tempToken: action.payload };
        case TOKEN_SET:
            return { ...state, tempToken: '' };
        case QB_CLEAR:
            return { ...state, syncMessages: [] };
        default:
            return state;
    }
}

import * as AccountService from '../services/AccountService';
import React from 'react';
import { toast } from "react-toastify";

export const ACCOUNT_GET = 'ACCOUNT_GET';
export const TRANSACTION_GET = 'TRANSACTION_GET';
export const ACCOUNT_TYPES_GET = 'ACCOUNT_TYPES_GET';
export const TRANSACTION_COMPNAY_GET = 'TRANSACTION_COMPNAY_GET';
export const ACCOUNT_TYPES_COMPANY_GET = 'ACCOUNT_TYPES_COMPANY_GET';



function getCompanyId () {
    let user = JSON.parse(localStorage.getItem('userData'));
    const CompanyId = user.companyId;
    return CompanyId;
}

export const searchAccount = (companyId,text) => {
  return (dispatch) => {
        AccountService.searchAccount(companyId,text)
            .then((response)=>{
                dispatch({type: ACCOUNT_GET,payload: response});
            })
            .catch((error)=>{
             toast.error(`${error.response.data}`)
            })
    }
}


export const AssignAccountTypes = (companyId, data) => {
    return (dispatch) => {
        AccountService.AssignAccountTypes(companyId, data)
            .then((response)=>{
                 //toast.success("Account Types Assigned Successfully.");
            })
            .catch((err)=>{
               toast.error(`${err.response.data.message}`)
            })
    }
}

export const AssignTransactionTypes = (companyId, data) => {
    return (dispatch) => {
        AccountService.AssignTransactionTypes(companyId, data)
            .then((response)=>{
                 //toast.success("Transaction Types Assigned Successfully.");
            })
            .catch((err)=>{
               toast.error(`${err.response.data.message}`)
            })
    }
}

export const AccountTypes = () => {
    return (dispatch) => {
        AccountService.AccountTypes()
            .then((response)=>{
                dispatch({type: ACCOUNT_TYPES_GET,payload: response});
            })
            .catch((error)=>{

            })
    }
}

export const CompanyAccountTypes = (id) => {
    return (dispatch) => {
        AccountService.CompanyAccountTypes(id)
            .then((response)=>{
                dispatch({type: ACCOUNT_TYPES_COMPANY_GET,payload: response});
            })
            .catch((error)=>{

            })
    }
}

export const updateAccocunt = (data) => {
    return (dispatch) => {
        AccountService.updateAccocunt(data)
        .then((response)=>{
            dispatch({type:'RELOAD',payload:data});
            toast.success("Account Updated Successfully.");
        })
        .catch((error)=>{

        })
    }
}

export const addAccocunt = () => {
    return (dispatch) => {
        AccountService.Accounts()
            .then((response)=>{
                dispatch({type: ACCOUNT_GET,payload: response});
            })
            .catch((res)=>{

           })
    }
}

export const TransactionTypes = () => {
    return (dispatch) => {
        AccountService.TransactionTypes()
            .then((response)=>{
                dispatch({type: TRANSACTION_GET,payload: response});
            })
            .catch((res)=>{

           })
    }
}

export const CompanyTransactionTypes = (id) => {
    return (dispatch) => {
        AccountService.CompanyTransactionTypes(id)
            .then((response)=>{
                dispatch({type: TRANSACTION_COMPNAY_GET,payload: response});
            })
            .catch((res)=>{

           })
    }
}

export function Accounts(companyId){
    return (dispatch) => {
        // dispatch()
        AccountService.Accounts(companyId)
        .then((response) => {            
            return dispatch({type: ACCOUNT_GET,payload: response});
        })
        .catch(err => {
             
        })
    }
}

export function Accounts_BES(companyId){
    return (dispatch) => {
        // dispatch()
        AccountService.Accounts_BES(companyId)
        .then((response) => {            
            return dispatch({type: ACCOUNT_GET,payload: response});
        })
        .catch(err => {
             
        })
    }
}


export function GetAccounts(companyId,search,pageNumber,pageSize,sortField,sortFormat){
    return (dispatch) => {
        // dispatch()
        AccountService.getAccounts(companyId,search,pageNumber,pageSize,sortField,sortFormat)
        .then((response) => {
            return dispatch({type: ACCOUNT_GET,payload: response});
        })
        .catch(err => {
             
        })
    }
}

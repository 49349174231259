import baseService from './baseService';

export function companyDashboard(data) {
    return baseService.post(`/CompanyDashboard/data`, data);
}

export function Get_ExcelExportData(data) {
    return baseService.post(`/CompanyDashboard/ExportData`, data);
}


export function companyDashboard_BES(data) {
    return baseService.post(`/CompanyDashboard/data_BES`, data);
}

export function MarkMinistersApproved(companyId)
{
    return baseService.post("/CompanyDashboard/VerifyMinistryListApproved?CompanyID="+companyId);
}

export function MarkMinistersApproved_BES(companyId,approver)
{
    return baseService.post("/CompanyDashboard/VerifyMinistryListApproved_BES?CompanyID="+companyId+"&ApproverBy="+approver);
}

export function GetApprovedMinistersHistory(companyId)
{
    return baseService.get("/CompanyDashboard/GetHistoryVerifyMinistryListApproved?CompanyID="+companyId);
}
import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {Base64} from "./commonServices";
import { toast } from 'react-toastify';


class IframeLoader extends Component {

    constructor(props) {
        super(props)        
        let url=props.URL;
        let user = JSON.parse(localStorage.getItem('userData'));        
        url +="?q="+Base64.encode(user.companyId.toString())+"&p="+Base64.encode(user.companyName);                
        //title='"+user.companyName +" Dashboard'
        this.state = {            
            iframeURL: "<iframe src='" + url + "' width='1225' height='1800' scrolling='auto' > </iframe>",
            copied: false
        }
    }


    handleCopied = () => {
        this.setState({ copied: true });
        toast.success("Link copied.");
    }

    handleCancel = () => {
        this.props.CloseModel();
    };    
    
    render() {
        return (
            <React.Fragment >
                <Dialog className="modal-box"
                    maxWidth="sm"
                    fullWidth={true}
                    open={true}
                    onClose={this.handleCancel}
                >
                    <div className="embedded-code-box">
                        <div className="modal-header modal-header-box">
                            <DialogTitle className="embedded-title">Share Dashboard with Client
                    </DialogTitle>
                            <DialogActions>
                                <Button variant="raised" className="close-btn" onClick={this.handleCancel}>x</Button>
                            </DialogActions>
                        </div>
                        <div className="embeddeed-code">
                            <h5 className="embedded-dashboard-title">Embedded Dashboard Code:</h5>
                            <DialogContent className="modal-detail modal-body-box">                               
                                {this.state.iframeURL}
                            </DialogContent>
                            <div className="copy-icon-box">
                                <CopyToClipboard text={this.state.iframeURL}
                                    onCopy={() => this.handleCopied()}>
                                    <button className="copy-icon"><i className="zmdi zmdi-copy" ></i></button>
                                </CopyToClipboard>                                
                            </div>
                        </div>
                    </div>
                </Dialog>
            </React.Fragment>
        )
    }
}


export default IframeLoader;

import React, { Component } from 'react'
import { connect } from "react-redux";
import TextField from '@material-ui/core/TextField';
import { bindActionCreators } from "redux";
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { addUser, assignUserCompanies } from '../appRedux/actions/companiesAction';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import jwt_decode from 'jwt-decode';
import RSelect from 'react-select';
import { Form } from "antd";
import './form.css';
const FormItem = Form.Item;
if (JSON.parse(localStorage.getItem('userData'))) {
  var user = JSON.parse(localStorage.getItem('userData'));
  let decode = jwt_decode(user.Authorization);
  var role = decode['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
}


class AddUserForms extends Component {
  state = {
    userId: 0,
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    password: '',
    selectedCompanies: [],
    role: ''
  }
  componentWillReceiveProps(nextProps) {


    if (nextProps.selectedItem && nextProps.selectedItem != this.state.selectedItem) {

      var selectedCompanies = [];
      if (nextProps.selectedItem.CompanyUsers && nextProps.selectedItem.CompanyUsers != "") {
        var companiesData = this.props.companiesData;
        var sca = nextProps.selectedItem.CompanyUsers.split(",");
        sca.forEach(s => {
          companiesData.forEach(c => {
            if (c.value == s) {
              selectedCompanies.push(c);
            }
          });
        });

      }
      this.setState({
        userId: nextProps.selectedItem.id,
        companyId: nextProps.selectedItem.companyId,
        firstName: nextProps.selectedItem.firstName,
        lastName: nextProps.selectedItem.lastName,
        userName: nextProps.selectedItem.userName,
        email: nextProps.selectedItem.email,
        role: nextProps.selectedItem.role,
        selectedCompanies: selectedCompanies
      })
    }
    else {
      this.setState({
        userId: 0,
        firstName: '',
        lastName: '',
        userName: '',
        email: '',
        password: '',
        selectedCompanies: [],
        role: this.props.companyId != 0 ? 'Contact' : ''
      })
    }
  }
  handleCancel = () => {
    this.props.handleCancel()
  };

  handleDelete = () => {    
    this.props.handleDelete()
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.state.role == "SuperAdmin" || this.state.role == "Admin")
      this.state.selectedCompanies = [];
    let data = {
      userId: this.state.userId,
      companyId: this.props.companyId,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      userName: this.state.email,
      email: this.state.email,
      role: this.state.role,
      selectedCompanies: this.state.selectedCompanies
    };

    if (this.props.isEdit == false && this.props.companyId != 0)
      this.state.selectedCompanies.push({ value: this.props.companyId, label: "" });

    this.props.addUser(data);
    this.setDefaultState();
    //  this.props.handleCancel();

  }
  setDefaultState = () => {
    this.setState({
      userId: 0,
      firstName: '',
      lastName: '',
      userName: '',
      email: '',
      password: '',
      selectedCompanies: [],
      role: this.props.companyId != 0 ? 'Contact' : ''
    })
  }
  handleCompChange = (selectedCompanies) => {

    this.setState({ selectedCompanies, err: false });
  }

  handleFormChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }
  render() {
    const { visible, companiesData } = this.props;
    const { selectedCompanies } = this.state;
    var user = JSON.parse(localStorage.getItem('userData'));
    return (
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        open={visible}
        onClose={this.handleCancel}
      >
        <form method="post" onSubmit={this.handleSubmit} autoComplete="off" style={{ padding: 20 }} >
          <DialogTitle>{this.props.isEdit ? 'Edit' : 'Add'} {this.props.companyId == 0 ? 'User' : 'Contact'}</DialogTitle>
          <DialogContent>
            <fieldset>
              <Grid container spacing={24} >
                <Grid item sm={6} >
                  <TextField
                    name="firstName"
                    label="First Name"
                    fullWidth
                    required
                    onChange={this.handleFormChange}
                    defaultValue={this.state.firstName}
                    margin="normal"
                  />
                </Grid>
                <Grid item sm={6} >
                  <TextField
                    name="lastName"
                    required={true}
                    label={"Last Name"}
                    fullWidth
                    onChange={this.handleFormChange}
                    defaultValue={this.state.lastName}
                    margin="normal"
                  />
                </Grid>
                {/* <Grid item sm={4}>
                  <TextField
                    name="userName"
                    required={true}
                    label={"User Name"}
                    fullWidth
                    onChange={(e) => this.setState({ userName: e.target.value.replace(" ", "") })}
                    value={this.state.userName}
                    margin="normal"
                  />
                </Grid> */}
              </Grid>
              <Grid container spacing={24} >
                <Grid item sm={6}>
                  <TextField
                    name="email"
                    type="email"
                    required={true}
                    label={"Email"}
                    fullWidth
                    onChange={this.handleFormChange}
                    defaultValue={this.state.email}
                    margin="normal"
                  />
                </Grid>
                <Grid item sm={6}>
                  <InputLabel className="labletext">User Role</InputLabel>
                  <Select
                    required={true}
                    name="role"
                    native
                    value={this.state.role}
                    onChange={this.handleFormChange}
                    inputProps={{
                      name: 'role',
                      id: 'teax-native-simple',
                    }}
                    style={{ 'width': '100%' }}
                  >
                    <option value="" disabled>Select Role</option>
                    {this.props.companyId == 0 && role == 'SuperAdmin' ? <option value={"SuperAdmin"}>SuperAdmin</option> : ''}
                    {this.props.companyId == 0 && <option value={"Admin"}>Admin</option>}
                    {this.props.companyId != 0 && <option value={"Contact"}>Contact</option>}
                    {this.props.companyId == 0 && <option value={"Accountant"}>Accountant</option>}
                  </Select>
                </Grid>
                {(this.state.role == 'Contact' || this.state.role == 'Accountant') && this.props.companyId == 0 &&
                  <Grid item sm={6} style={{ paddingTop: 20, position: 'relative' }}>
                    <InputLabel htmlFor="tax-native-simple">Assign Companies</InputLabel>
                    <div style={{ position: 'fixed', zIndex: 10000, width: 270 }}>
                      <RSelect
                        value={selectedCompanies}
                        onChange={this.handleCompChange}
                        options={companiesData}
                        isMulti={true}

                      />
                    </div>
                  </Grid>}
              </Grid>

            </fieldset>

          </DialogContent>
          <DialogActions>

            {this.props.company.loading ? <Button type="button" color="primary">
              Saving
            </Button> : <Button type="submit" color="primary">
                Save
            </Button>}
            <Button onClick={this.handleCancel} color="primary" autoFocus>
              Cancel
            </Button>
            {this.props.isEdit && user.role != 'Accountant' && <Button onClick={this.handleDelete} color="primary">
              Delete
                </Button>}
          </DialogActions>
        </form>
      </Dialog>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    company: state.companyReducer
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    addUser,
    assignUserCompanies
  }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(AddUserForms)

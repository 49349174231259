import {
 ACCOUNT_GET,
 TRANSACTION_GET,
 ACCOUNT_TYPES_GET,
 TRANSACTION_COMPNAY_GET,
 ACCOUNT_TYPES_COMPANY_GET,
} from '../actions/accountAction';

const initial_state = {
     accountsData:[],
     tranactionTypes:[],
     accountTypes:[],
     companyAccountTypes:[],
     companyTransactionTypes:[]
};


export default function authReducer(state = initial_state, action) {
    switch (action.type) {
        case ACCOUNT_TYPES_COMPANY_GET: 
            return {
                ...state,
                companyAccountTypes:action.payload,
            }
        case TRANSACTION_COMPNAY_GET: 
            return {
                ...state,
                companyTransactionTypes:action.payload,
            }
        case ACCOUNT_GET:
            return {
               ...state,
            	accountsData:action.payload,reload:false
             };
        case ACCOUNT_TYPES_GET:
            return {
               ...state,
                accountTypes:action.payload,
             };
        case TRANSACTION_GET:
          return {
           ...state,
            tranactionTypes:action.payload,
         };
         case 'RELOAD':
            return {...state, reload:true};
        default:
            return state;
    }
}
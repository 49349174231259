import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import IntlMessages from '../../util/IntlMessages';
import CustomScrollbars from '../../util/CustomScrollbars';
import jwt_decode from 'jwt-decode';

class SidenavContent extends Component {
    componentDidMount() {
        const { history } = this.props;
        const that = this;
        const pathname = `${history.location.pathname}`;// get current path

        const subMenuLi = document.querySelectorAll('.sub-menu > li');
        for (let i = 0; i < subMenuLi.length; i++) {
            subMenuLi[i].onclick = function (event) {
                event.stopPropagation();
            }
        }

        const menuLi = document.getElementsByClassName('menu');
        for (let i = 0; i < menuLi.length; i++) {
            menuLi[i].onclick = function (event) {
                for (let j = 0; j < menuLi.length; j++) {
                    const parentLi = that.closest(this, 'li');
                    if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains('open'))) {
                        menuLi[j].classList.remove('open')
                    }
                }
                this.classList.toggle('open');
                event.stopPropagation();
            }
        }

        const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element

        try {
            const activeNav = this.closest(activeLi, 'ul'); // select closest ul
            if (activeNav.classList.contains('sub-menu')) {
                this.closest(activeNav, 'li').classList.add('open');
            } else {
                this.closest(activeLi, 'li').classList.add('open');
            }
        } catch (error) {

        }
    }

    closest(el, selector) {
        try {
            let matchesFn;
            // find vendor prefix
            ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
                if (typeof document.body[fn] == 'function') {
                    matchesFn = fn;
                    return true;
                }
                return false;
            });

            let parent;

            // traverse parents
            while (el) {
                parent = el.parentElement;
                if (parent && parent[matchesFn](selector)) {
                    return parent;
                }
                el = parent;
            }
        } catch (error) {

        }

        return null;
    }

    render() {
        let user = JSON.parse(localStorage.getItem('userData'));
        let decode = jwt_decode(user.Authorization);
        let companyId = decode.CompanyId;
        const role = decode['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
        return (
            <CustomScrollbars className="scrollbar">
                <ul className="nav-menu">

                    {/*<li className="nav-header"><IntlMessages id="sidebar.main"/></li>*/}
                    <li className="menu no-arrow">
                        <Link to="/dashboard">
                            <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                            <span className="nav-text">Home</span>
                        </Link>
                    </li>
                    {role == 'Admin' ? <li className="menu collapse-box">
                        <Button href="javascript:void(0)">
                            <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                            <span className="nav-text">
                                <IntlMessages id="sidebar.accoutant" />
                            </span>
                        </Button>
                        <ul className="sub-menu">
                            <li>
                                <Link className="prepend-icon" to="/ledgers">
                                    <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                                    <span className="nav-text">
                                        <IntlMessages id="sidebar.accoutant.ledger" /></span>
                                </Link>
                            </li>
                            <li>
                                <Link className="prepend-icon"
                                    to="/trialBalanceReview">
                                    <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                                    <span className="nav-text text-transform-none">
                                        <IntlMessages
                                            id="sidebar.accoutant.balancesheet" /></span>
                                </Link>
                            </li>
                            <li>
                                <Link className="prepend-icon" to="/accoutantReview">
                                    <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                                    <span className="nav-text">
                                        <IntlMessages id="sidebar.accoutant.review" />
                                    </span>
                                </Link>
                            </li>
                            <li>
                                <Link className="prepend-icon" to="/distributionReview">
                                    <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                                    <span className="nav-text"><IntlMessages id="sidebar.accoutant.distribution" /></span>
                                </Link>
                            </li>                            
                            <li>
                                <Link className="prepend-icon" to="/accoutantTaxableReview">
                                    <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                                    <span className="nav-text"><IntlMessages id="sidebar.accoutant.taxable" /></span>
                                </Link>
                            </li>
                        </ul>
                    </li> : null}
                    {role == 'Contact' || role == 'Admin' ? <li className="menu collapse-box">
                        <Button href="javascript:void(0)">
                            <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                            <span className="nav-text">
                                <IntlMessages id="sidebar.reports" />
                            </span>
                        </Button>
                        <ul className="sub-menu">
                            <li className="menu no-arrow">
                                <Link className="prepend-icon" to="/financialReview">
                                    <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                                    <span className="nav-text">
                                        <IntlMessages id="sidebar.reports.finCheck" />
                                    </span>
                                </Link>
                            </li>
                            <li >
                                <Link className="prepend-icon"
                                    to="/reportReview">
                                    <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                                    <span className="nav-text text-transform-none">
                                        <IntlMessages
                                            id="sidebar.accoutant.review" /></span>
                                </Link>
                            </li>
                            <li className="menu no-arrow">
                                <Link className="prepend-icon"
                                    to="/taxableReview">
                                    <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                                    <span className="nav-text text-transform-none">
                                        <IntlMessages
                                            id="sidebar.accoutant.taxable" /></span>
                                </Link>
                            </li>
                        </ul>
                    </li> : null}
                    {role == 'Admin' || role == 'SuperAdmin' || 'Accountant' ? <li className="menu no-arrow">
                        <Link className="prepend-icon" to="/companies">
                            <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                            <span className="nav-text">
                                Companies
                        </span>
                        </Link>
                    </li> : ''}
                    {role == 'SuperAdmin' ? <li className="menu no-arrow">
                        <Link className="prepend-icon" to="/accountant">
                            <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                            <span className="nav-text">
                                Accountant
                        </span>
                        </Link>
                    </li> : ''}
                    {role == 'Admin' || role == 'SuperAdmin' || 'Accountant' ? <li className="menu no-arrow">
                        <Link className="prepend-icon" to={`/users/${companyId}`}>
                            <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                            <span className="nav-text">
                                Users
                                    </span>
                        </Link>
                    </li> : ''}
                    {role == 'Admin' ? <li className="menu collapse-box">
                        <Button href="javascript:void(0)">
                            <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                            <span className="nav-text">
                                <IntlMessages id="sidebar.admin" />
                            </span>
                        </Button>
                        <ul className="sub-menu">
                            <li className="menu no-arrow">
                                <Link className="prepend-icon"
                                    to="/chartAccounts">
                                    <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                                    <span className="nav-text text-transform-none">
                                        <IntlMessages
                                            id="sidebar.admin.chart" /></span>
                                </Link>
                            </li>
                            <li >
                                <Link className="prepend-icon"
                                    to="/vendor">
                                    <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                                    <span className="nav-text text-transform-none">
                                        <IntlMessages
                                            id="sidebar.admin.vendor" /></span>
                                </Link>
                            </li>
                            {/* <li className="menu">
                                <Link className="prepend-icon"
                                    to="/employees">
                                    <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                                    <span className="nav-text text-transform-none">
                                        <IntlMessages
                                            id="sidebar.admin.employee" /></span>
                                </Link>
                            </li> */}
                            <li className="menu no-arrow">
                                <Link className="prepend-icon"
                                    to="/leases">
                                    <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                                    <span className="nav-text text-transform-none">
                                        <IntlMessages
                                            id="sidebar.admin.lease" /></span>
                                </Link>
                            </li>
                            <li className="menu no-arrow">
                                <Link className="prepend-icon"
                                    to="/loans">
                                    <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                                    <span className="nav-text text-transform-none">
                                        <IntlMessages
                                            id="sidebar.admin.loan" /></span>
                                </Link>
                            </li>
                            <li className="menu no-arrow">
                                <Link className="prepend-icon"
                                    to="/insurancePolicies">
                                    <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                                    <span className="nav-text text-transform-none">
                                        <IntlMessages
                                            id="sidebar.admin.insurance" /></span>
                                </Link>
                            </li>
                            <li className="menu no-arrow">
                                <Link to="/QuickBooks">
                                    <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                                    <span className="nav-text"><IntlMessages id="quickbook" /></span>
                                </Link>
                            </li>
                        </ul>
                    </li> : null}

                </ul>
            </CustomScrollbars>
        );
    }
}

export default withRouter(SidenavContent);
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import PaginationComponent from "./../../../../src/app/common/Pagination";
import { ReportType, CheckTaxFormat, Base64, MaskTaxIdentifier,numberWithCommas,makeLinkForQuickbooks ,LinkType} from "./../../../app/common/commonServices";
import moment from 'moment';
import { bindActionCreators } from 'redux';
import CircularProgress from './../../../components/CircularProgress';
import {
    GET_CompanyDashboard_PayrollVendorReportDetailList_BES,
    Update_CompanyDashboard_UpdateVendorW9_BES,
    Post_Vendor_UpdateVendorStatus
} from "./../../../appRedux/actions/ReportDetailAction";
import { toast } from 'react-toastify';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import UpdatedByNameModal from '../../../app/common/updatedByNameModal';
import VimeoInstructionLoader from '../../../app/common/VimeoInstructionLoader';
import {uploadVendorAttachment_BES} from './../../../appRedux/actions/vendorAction';
import queryString from 'query-string';
import Tooltip from '@material-ui/core/Tooltip';
import HistoryIcon from '@material-ui/icons/History';
import VendorsLogHistory from '../../../app/common/vendorsHistoryModel'
import {dropdownValueMapping} from '../../../app/common/1099ReportingDataMapping'
import {doNotIssueDeopdownValues } from "../../../appRedux/actions/vendorAction";
import RefreshDataModal from '../../../components/RefreshDataModal'




class VendorMissW9Component_Embedded extends Component {

    constructor(props) {
        super(props);
        this.updateByNameObj = null;
        let endDate = new Date();
        let startDate = new Date(endDate.getFullYear(), 0, 1);

        if (endDate.getMonth() <= 2) {
            startDate = new Date(startDate.getFullYear() - 1, 0, 1);
            endDate = new Date(endDate.getFullYear() - 1, 11, 31);
        }
        this.state = {
            parantData: this.props.location.state ? this.props.location.state : null,
            MissingData: [],
            doNotIssueDropdownOptions: [],
            companyId: 0,
            sortField: 'displayName',
            sortFormat: "A",
            PageNo: 1,
            PageSize: 10,
            ColorCode: "#f0ca4c",
            IsCommentModelOpen: false,
            sDate: startDate,// new Date(2019, 0, 1),
            eDate:endDate,// new Date(),
            loading: false,
            TotalRecords: 0,
            CommentReferenceId: 0,
            IsEmbeddedPage: true,
            SelectedRecords: [],
            SelectALL: false,
            HomeLink: "",
            editIndex: -1,
            editData: null,
            isUpdateByName: false,
            companyName: "",
            ShowHistoryModal:false,
            isRefreshDataModalOpen:false
        }
    }

    onSortChange = (fieldName) => {
        if (this.state.sortField == fieldName) {
            let formate = this.state.sortFormat == 'A' ? 'D' : 'A';
            this.setState({ sortFormat: formate, loading: true }, () => {
                this.GetReportData();
            });
        } else {
            this.setState({ sortField: fieldName, sortFormat: 'A', loading: true }, () => {
                this.GetReportData();
            });
        }
    }

    refreshData = () => {
        if (!this.state.loading) {
            this.setState({ loading: true,isRefreshDataModalOpen:false }, () => {
                this.GetReportData(true);
            });
        }
    }

    onRefreshDataClick = () => {
        
        this.setState({isRefreshDataModalOpen:true})
    }

    handleRefreshDataModal=()=>{
        this.setState({isRefreshDataModalOpen:false})
      }
    CloseHistory = () => {
        this.setState({ ShowHistoryModal: false });
    }
    getHistory=(item) => {
        this.setState({vendorID:item.vendorID,ShowHistoryModal: true})
    
      }

    ChangePageSize = (size) => {

        this.setState({ loading: true, PageSize: parseInt(size), PageNo: 1 }, () => {
            this.GetReportData();
        });
    }

    ChangePage = (page) => {
        this.setState({ loading: true, PageNo: page, }, () => {
            this.GetReportData();
        });
    }

    handleFormChange = (e, target) => {
        let data = { ...this.state.editData };
        data[e.target.name] = e.target.value;
        this.setState({ editData: data });
    }

    onRowEdit = (i, rowData) => {
        if (rowData.isActive) {
            let body = {
                listID: rowData.listID,
                companyID: rowData.companyID,
                vendorID: rowData.vendorID,
                name: rowData.displayName,
                address: rowData.bill1,
                city: rowData.bill3,
                state: rowData.bill4,
                zip: rowData.bill5,
                tax: rowData.taxIdentifier,
                createdByName: null,
                doNotIssue1099:rowData.doNotIssue1099
            }
            this.setState({ editData: body, editIndex: i });
        } else {
            toast.warning("Status is inactive so can't edit this row.");
        }
    };

    onRowCancel = (rowIndex, rowData) => {
        this.setState({ editData: null, editIndex: -1 });
    }

    onRowSave = (index, data) => {
        let editedData = this.state.editData;
        if (editedData.tax != null && editedData.tax.replace(/\\/g, '/') !== "") {
            if (CheckTaxFormat(editedData.tax)) {
                toast.error("Please enter tax identifier in below format 99-9999999 or 999-99-9999 or XXXXXX9999 or XXXXXXX9999");
                return false;
            }
        }
        let flag=false;
        if(data.totalPayment >=600 && data.vendor1099 && (this.state.editData.doNotIssue1099==="6" 
        || this.state.editData.doNotIssue1099==="7" ||this.state.editData.doNotIssue1099=== "8")){
        flag=true
        }

        let body = {
            "vendorID": editedData.vendorID,
            "companyID": editedData.companyID,
            "listID": editedData.listID,
            "displayName": editedData.name,
            "bill1": editedData.address,
            "bill3": editedData.city,
            "bill4": editedData.state,
            "bill5": editedData.zip,
            "taxIdentifier": editedData.tax,
            "createdByName": editedData.createdByName,
            "doNotIssue1099": editedData.doNotIssue1099,
            "isUpdateVendorEINAndAddress":flag
        }

        if (body.createdByName == null || body.createdByName.trim().replace(/\s/g, '') === "") {
            toast.error("Please enter the updated by name.");
            return false;
        }

        this.setState({
            loading: true
        }, () => {
            this.props.Update_CompanyDashboard_UpdateVendorW9_BES(body);
        });
    }

    GetReportData(IsRefresh = false) {
        let state = this.state;
        let body = {
            companyID: state.companyId,
            reportType: ReportType.VendorMissW9,
            fromDate: moment(state.sDate).format("MM/DD/YYYY"),
            toDate: moment(state.eDate).format("MM/DD/YYYY"),
            sortField: state.sortField,
            sortFormat: state.sortFormat,
            pageNumber: state.PageNo,
            pageSize: state.PageSize,
            needUpdatQBORecords: IsRefresh
        };

        this.props.GET_CompanyDashboard_PayrollVendorReportDetailList_BES(body);
    }

    getCompanyId = () => {
        let id = 0;
        let value = queryString.parse(this.props.location.search);
        localStorage.setItem("q", JSON.stringify(value));
        let data = localStorage.getItem("q");

        if (data != null && data != undefined) {
            data = JSON.parse(data);
            this.setState({
                companyId: parseInt(Base64.decode(data.q)),
                companyName:Base64.decode(data.p),
                loading: true
            }, () => {
                this.GetReportData();
            });
        }
        else {
            this.setState({ loading: false });
        }
    }

    vendorStatusChange = (e, item) => {
        if (item.balance != 0 && !e.target.checked) {
            toast.error("This Vendor has a non-zero balance. you can not make as inactive.");
            return false;
        }
        this.updateByNameObj = {
            "vendorID": item.vendorID,
            "companyID": item.companyID,
            "isActive": e.target.checked,
            "createdByName": ""
        }
        this.setState({ isUpdateByName: true });
    }

    closeUpdateByNameModal = () => {
        this.setState({ isUpdateByName: false });
    };

    getDetailsFromUpdateByNameModal = (updateByNameObj) => {
        this.props.Post_Vendor_UpdateVendorStatus(updateByNameObj);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.ReportData !== this.props.ReportData) {
            if (nextProps.ReportData.transactionStatus === 0) {
                var totalrecords = 0;
                let items = nextProps.ReportData.returnObject;
                if (nextProps.ReportData.returnObject != null && nextProps.ReportData.returnObject.length > 0) {
                    totalrecords = nextProps.ReportData.returnObject[0].totalrows
                    items.map((dt, index) => {
                        items[index].MaskTax = MaskTaxIdentifier(dt.taxIdentifier);
                    });
                }
                this.setState({ MissingData: items, TotalRecords: totalrecords, PageSize: this.state.PageSize, PageNo: this.state.PageNo, loading: false });
            }
            else {
                toast.error(nextProps.ReportData.resultMsg);
                this.setState({ loading: false });
            }
        }

        if (nextProps.UpdateDescription !== this.props.UpdateDescription) {
            if (nextProps.UpdateDescription.transactionStatus === 0) {
                let data = [...this.state.MissingData];
                let editdata = { ...this.state.editData };
                let editIndex = this.state.editIndex;
                data[editIndex].displayName = editdata.name;
                data[editIndex].bill1 = editdata.address;
                data[editIndex].bill3 = editdata.city;
                data[editIndex].bill4 = editdata.state;
                data[editIndex].bill5 = editdata.zip;
                data[editIndex].taxIdentifier = editdata.tax;
                data[editIndex].doNotIssue1099 = editdata.doNotIssue1099;
                this.setState({
                    loading: false, editData: null, editIndex: -1
                }, () => {
                    toast.success(`${nextProps.UpdateDescription.resultMsg}`);
                    this.GetReportData();
                });
            }
            else {
                this.setState({
                    loading: false, editdata: null, editIndex: -1
                }, () => {
                    toast.error(`${nextProps.UpdateDescription.resultMsg}`);
                });
            }
        }
        if (nextProps.doNotIssueDropdownOptions !== undefined && nextProps.doNotIssueDropdownOptions !== this.props.doNotIssueDropdownOptions) {
            this.setState({ loading: false, doNotIssueDropdownOptions: nextProps.doNotIssueDropdownOptions.returnObject });
          }
        if (nextProps.activeInactive !== this.props.activeInactive) {
            this.setState({
                loading: true, isUpdateByName: false
            }, () => {
                this.GetReportData();
            });
        }

        if (nextProps.attachmentupload !== this.props.attachmentupload) {
            this.setState({
                loading: false
            }, () => {
                //toast.success(`${nextProps.attachmentupload.resultMsg}`);
            });
        }
    }

    componentDidMount() {
        let value = queryString.parse(this.props.location.search);

        // localStorage.setItem("homeURL", window.location.href.replace(window.location.origin, ""))
        let dashboardURl = localStorage.getItem("homeURL");
        this.setState({
            loading: true,
            HomeLink: dashboardURl ? dashboardURl : "?q="+ value.q + "&p=" + value.p,
        }, () => {
            this.getCompanyId();
            this.props.doNotIssueDeopdownValues()
        });
    }
    DownloadFile = (fileURL) => {
        var a = document.createElement('a');
        a.href = fileURL;
        a.target = '_blank';
        a.download = 'bill.pdf';
        document.body.appendChild(a);
        a.click();
        //window.open(fileURL,"_self");

        //var url = window.URL || window.webkitURL;
        //link = url.createObjectURL(blob);
    }

    onLinkClick = (rowData) => {
        let origin = makeLinkForQuickbooks();
        window.open(origin + rowData.transactionLink);
    }

    AttachFile = (item) => {
        const self = this;
        var TF = document.createElement("input");
        TF.setAttribute("type", "file");
        TF.addEventListener('change', function handleClick(e) {
           self.onFileSelect(e,item);
        });
        TF.click();
    }

    onFileSelect = (e,item) => {
        let files = e.target.files;
        var data = {
            "contentType":"application/pdf",
            "entityId":item.listID,
            "companyId":item.companyID
        }
        let form = new FormData();
        for (var index = 0; index < files.length; index++) {
            var element = files[index];
            form.append('formData', element);
        }
        form.append('EntityId', item.listID);
        this.setState({ loading: true }, () => {
            this.props.uploadVendorAttachment_BES(form,data);
        });
    }
    
    render() {
        return (
            <div className="dashboardPage embeded-dashboard">
                <RefreshDataModal visible={this.state.isRefreshDataModalOpen}
                 handlePopUpClose={this.handleRefreshDataModal} refreshData={this.refreshData}/>
                {this.state.isUpdateByName &&
                    <UpdatedByNameModal
                        updateByNameObj={this.updateByNameObj}
                        closeUpdateByNameModal={this.closeUpdateByNameModal}
                        getDetailsFromUpdateByNameModal={this.getDetailsFromUpdateByNameModal}>
                    </UpdatedByNameModal>}
                <div className=" page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
                    <h2 className="mb-3 mb-sm-0">{this.state.companyName} | { LinkType.VendorMissW9}</h2>
                    <Link className="home-link" to={this.state.HomeLink} > Home </Link>
                </div>
                {this.state.loading ? <CircularProgress></CircularProgress>
                    :
                    <React.Fragment>
                        <div className="padding-box app-wrapper bgcolor">
                        {this.state.ShowHistoryModal ?  <VendorsLogHistory  Module="Vendors History"
                    CompanyId={this.state.companyId}
                    VendorId={this.state.vendorID}
                    CloseHistory={this.CloseHistory}/>: '' }
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="float-right">
                                                {/* <Button variant="raised" className=" jr-btn g-teal  text-white refresh-btn graycolor" onClick={() => this.OnApplyApproved()}  >Resolved</Button> */}
                                               
                                                <Button variant="raised" style={{marginRight:"10px",backgroundColor: this.state.ColorCode}} className="jr-btn text-white refresh-btn" onClick={() => this.DownloadFile("https://www.irs.gov/pub/irs-pdf/fw9.pdf")}> <img src="/vendors/Download-img.png" alt="download" className="download-icon" ></img>               
                                                {/* <a href="https://www.irs.gov/pub/irs-pdf/fw9.pdf" target="_blank" download></a>*/}
                                                Download W-9 Form 
                                                </Button>
                                               
                                                <Button variant="raised" style={{ backgroundColor: this.state.ColorCode }} className="jr-btn text-white refresh-btn" onClick={() => this.onRefreshDataClick()}  ><i className="zmdi zmdi-refresh "></i> Refresh Data</Button>
                                                <VimeoInstructionLoader 
                                                       //URL={"https://vimeo.com/687625248"} 
                                                       URL = "https://player.vimeo.com/video/785977287?h=df22da040e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                                                       buttonName="Instructions"
                                                       buttonColor="goldcolor"
                                                       PageName="Vendors Missing W-9"
                                                ></VimeoInstructionLoader>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                    Please request a W-9 from the vendor(s) by phone, email or mail.  If the vendor is a business,
                                    please search Google and add the vendor's address until you receive the completed W-9 form. If
                                    the you cannot obtain a W-9 form, you are required by law to withhold 24% from any future
                                    payments to the vendor. This is called a back-up withholding payment. Once the payment is withheld,
                                    the payment cannot be returned to the vendor upon receipt of a W-9.  The funds must be remitted
                                    to the IRS.  Please contact us concerning back-up withholding payments.</div>
                            <div className="table-responsive-material">
                                <table className="table tablebox account-reconcilliation">
                                    <thead>
                                        <tr style={{ backgroundColor: this.state.ColorCode }}>
                                            <th className={`td-width ${this.state.sortField == 'displayName' ? this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('displayName')}><p className="table-th-title"> Display Name</p> </th>
                                            <th className={`td-width ${this.state.sortField == 'bill1' ? this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('bill1')}><p className="table-th-title"> Address</p></th>
                                            <th className={`td-width-amount ${this.state.sortField == 'bill3' ? this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('bill3')}><p className="table-th-title"> City</p></th>
                                            <th className={`td-width ${this.state.sortField == 'bill4' ? this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('bill4')}><p className="table-th-title"> State</p></th>
                                            <th className={`td-width ${this.state.sortField == 'bill5' ? this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('bill5')}><p className="table-th-title"> Zip</p></th>
                                            <th className={`td-width ${this.state.sortField == 'taxIdentifier' ? this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('taxIdentifier')}><p className="table-th-title"> Tax Identifier</p></th>
                                            {this.state.editIndex > -1 ?
                                                <th className="td-width"> Updated By</th>
                                                : ""}
                                            <th className={`td-width ${this.state.sortField == 'totalPayment' ? this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('totalPayment')}><p className="table-th-title"> Total 1099 payments</p></th>
                                            {/* <th className="td-width text-center">Status</th> */}
                                            <th className="td-width text-center">
                                            W-9/1099 Reporting Issues
                                                {/* Do Not Issue a 1099 */}
                                                </th>
                                            <th className="td-width text-center"> Edit</th>
                                            <th className="td-width text-center">Attachment</th>
                                            <th className="td-width-90 text-center "><img src="/vendors/Link-arrow_header.png" alt="link" /></th>
                                            <th className="td-width-90 text-center">
                                            W-9/1099 Reporting Issues
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.MissingData.map((item, i) => (
                                            <React.Fragment key={i}>
                                                {this.state.editIndex !== i && <tr>
                                                    <td>{item.displayName}</td>
                                                    <td>{item.bill1}</td>
                                                    <td>{item.bill3}</td>
                                                    <td>{item.bill4}</td>
                                                    <td>{item.bill5}</td>
                                                    <td>{item.MaskTax}</td>
                                                    <td className="text-right">${numberWithCommas(item.totalPayment)}</td>
                                                    {/* <td className="text-center">
                                                        <Switch className={item.isActive ? 'switch-box-gold' : 'switch-box-gold switch-inactive'} size="small" checked={item.isActive} onChange={(e) => this.vendorStatusChange(e, item)} /> <span style={{color:'#273f4c'}} className={item.isActive ? '' : ''}>{item.isActive ? "Active" : "Inactive"}</span>
                                                    </td> */}
                                                    <td>{dropdownValueMapping[`${item.doNotIssue1099}`]}</td>
                                                    {/* {this.state.editIndex > -1 ?
                                                        <td></td>
                                                        : ""} */}
                                                    <td className="text-center" onClick={(e) => this.onRowEdit(i, item)}>
                                                        <i className="zmdi zmdi-edit zmdi-hc-lg mr-4 pointer"></i>
                                                    </td>
                                                    {/* <td className="text-center">
                                                        <input type="file" id="case-one" onChange={(e) => this.onFileSelect(e,item)} />
                                                    </td> */}
                                                    <td className="text-center">
                                                        <i className="zmdi zmdi-attachment-alt zmdi-hc-lg mr-4 pointer" onClick={(e) => this.AttachFile(item)}> </i>
                                                    </td>
                                                    <td className="text-center"><img src="/vendors/Yellow-link.png" alt="link" onClick={() => this.onLinkClick(item)}></img></td>
                                                    <td><label className="container101" style={{marginTop:30}}>
                                                    <Tooltip title="W-9/1099 Reporting Issues history"><HistoryIcon onClick={(e)=>{this.getHistory(item)}}/></Tooltip> </label></td>
                                                </tr>}
                                                {this.state.editIndex === i &&
                                                    <tr >
                                                        <td>
                                                            <TextField
                                                                name="name"
                                                                onChange={this.handleFormChange}
                                                                defaultValue={this.state.editData.name}
                                                                margin="normal"
                                                            />
                                                        </td>
                                                        <td>
                                                            <TextField
                                                                name="address"
                                                                onChange={this.handleFormChange}
                                                                defaultValue={this.state.editData.address}
                                                                margin="normal"
                                                            />
                                                        </td>
                                                        <td>
                                                            <TextField
                                                                name="city"
                                                                onChange={this.handleFormChange}
                                                                defaultValue={this.state.editData.city}
                                                                margin="normal"
                                                            />
                                                        </td>
                                                        <td >
                                                            <TextField
                                                                name="state"
                                                                onChange={this.handleFormChange}
                                                                defaultValue={this.state.editData.state}
                                                                margin="normal"
                                                            />
                                                        </td>
                                                        <td >
                                                            <TextField
                                                                name="zip"
                                                                onChange={this.handleFormChange}
                                                                defaultValue={this.state.editData.zip}
                                                                margin="normal"
                                                            />
                                                        </td>
                                                        <td >
                                                            <TextField
                                                                name="tax"
                                                                onChange={this.handleFormChange}
                                                                defaultValue={this.state.editData.tax}
                                                                margin="normal"
                                                            />
                                                        </td>
                                                        <td>
                                                            <TextField
                                                                name="createdByName"
                                                                onChange={this.handleFormChange}
                                                                defaultValue={this.state.editData.createdByName}
                                                                margin="normal"
                                                            />
                                                        </td>
                                                        <td></td>
                                                        <td>
                                                        <select className="select-box arrow-box" name="doNotIssue1099" onChange={this.handleFormChange} value={this.state.editData.doNotIssue1099}>
                                                            <option value=""></option>
                                                            {this.state.doNotIssueDropdownOptions.map(type => (
                                                                <option value={type.id}>
                                                                  {type.description}
                                                                </option>
                                                            ))}                                                   
                                                        </select>
                                                        </td>
                                                        <td colSpan={3}>
                                                            <div className="col-md-12">
                                                                <Button variant="raised" className="jr-btn jr-btn-sm bg-teal text-white" onClick={() => this.onRowSave(i, item)}>SAVE</Button>
                                                                <Button variant="raised" className="jr-btn jr-btn-sm bg-danger text-white" onClick={() => this.onRowCancel(i, item)}>CANCEL</Button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                }
                                            </React.Fragment>
                                        ))}
                                        {this.state.MissingData.length == 0 && <tr>
                                            <td className="text-center" colSpan="11">
                                            No issues here! Keep up the good work!
                                            </td>
                                        </tr>}
                                    </tbody>
                                </table>
                            </div>
                            <PaginationComponent
                                selectedPageNo={this.state.PageNo}
                                selectedSize={this.state.PageSize}
                                TotalRecords={this.state.TotalRecords}
                                ChangePageSize={this.ChangePageSize}
                                ChangePage={this.ChangePage}
                                CurrentRecords={this.state.MissingData.length}
                            >
                            </PaginationComponent>
                        </div>
                        <ToastContainer autoClose={3000} />
                    </React.Fragment>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
 
    return {
        ReportData: state.ReportData.ReportData,
        UpdateDescription: state.ReportData.UpdateDescription,
        activeInactive: state.ReportData.activeInactive,
        doNotIssueDropdownOptions:state.vendors.doNotIssueDeopdownValues,
        attachmentupload:state.vendors.attachmentupload
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        GET_CompanyDashboard_PayrollVendorReportDetailList_BES,
        Update_CompanyDashboard_UpdateVendorW9_BES,
        doNotIssueDeopdownValues,
        Post_Vendor_UpdateVendorStatus,
        uploadVendorAttachment_BES
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(VendorMissW9Component_Embedded);
import {
    LEASES_SUCCESS, LEASES_ERROR,
    ADDLEASE_SUCCESS, ADDLEASE_ERROR,
    UPDATELEASE_SUCCESS, UPDATELEASE_ERROR,
    DELETELEASE_SUCCESS, DELETELEASE_ERROR,
    LEASES_TYPE_SUCCESS, LEASES_TYPE_ERROR
 } from '../actions/leaseAction';


const initial_state = {
    leaseTypes:[],
    leases:[],
};

export default function leaseReducer(state = initial_state, action) {
    switch (action.type) {
        case LEASES_SUCCESS:
            return {...state,
             leases:action.payload.data,reload:false
         };
         case LEASES_TYPE_SUCCESS :
          return {...state,
             leaseTypes:action.payload.data
         };
        case LEASES_ERROR:
            return [...state, ...action.payload.data];
        case ADDLEASE_SUCCESS:
            return [...state, ...action.payload.data];
        case ADDLEASE_ERROR:
            return [...state, ...action.payload.data];
        case DELETELEASE_SUCCESS:
            return [...state, ...action.payload.data];
        case DELETELEASE_ERROR:
            return [...state, ...action.payload.data];
        case UPDATELEASE_SUCCESS:
            return [...state, ...action.payload.data];
        case UPDATELEASE_ERROR:
            return [...state, ...action.payload.data];
        case 'RELOAD':
            return {...state, reload:true};
        default:
            return state;
    }
}

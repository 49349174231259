import * as GetPayrollTaxComputationService from '../services/PayrollTaxComputation';
import React from 'react';
import { toast } from "react-toastify";

export const PAYROLL_TAX_COMPUTATION = 'PAYROLL_TAX_COMPUTATION';
export const MARKSTATUS = 'MARKSTATUS';


export const GetPayrollTaxComputation = (data) => {
    return (dispatch) => {
        
        GetPayrollTaxComputationService.GetPayrollTaxComputation(data)
            .then((response) => {            
                dispatch({ type: PAYROLL_TAX_COMPUTATION, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response.data.message}`)
            })
    }
}


export const MarkVendorReview = (data) => {
    return (dispatch) => {
        GetPayrollTaxComputationService.MarkVendorReview(data)
            .then((response) => {
                dispatch({ type: MARKSTATUS, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}



import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import TextArea from 'antd/lib/input/TextArea';
import ClearIcon from '@material-ui/icons/Clear';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CircularProgress from "./../../components/CircularProgress";
import { toast } from 'react-toastify';
import { AddQuestion } from "../../appRedux/actions/QuestionCommentsAction";


class AddQuestions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            question: '',
            ColorCode: props.ColorCode,
            CompanyId: props.CompanyId,
            loading: true
        }
    }

    HandleClose = () => {
        this.props.onCloseAddQuestions();
    };

    handleMessage = (event) => {
        let data = event.target.value;
        if (data != null && data.trim() != "" && data.trim().length > 0) {
            this.setState({ IsButtonEnable: true, question: data });
        }
        else {
            this.setState({ IsButtonEnable: false, question: null });
        }
    };

    AddNewQues = () => {

        let body = {
            "id": 0,
            "question": this.state.question,
            "status": false,
            "cpaReview": false,
            "companyID": this.state.CompanyId
        }

        this.setState({ loading: true }, () => {
            this.props.AddQuestion(body);
        })

    }

    componentWillReceiveProps(nextprops) {                
        if (nextprops.AddComman!== undefined && this.props.AddComman !== nextprops.AddComman) {
            if (nextprops.AddComman.transactionStatus === 0) {
                toast.success("Question Added Successfully");                
                this.setState({loading:false},() => { this.props.Reload();});
            }
            else 
            {
                this.setState({loading:false},() => { toast.error(`${nextprops.AddComman.resultMsg}`);});
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <Dialog
                    className="comment-Top-align"
                    maxWidth="sm"
                    fullWidth={true}
                    open={true}>
                    <React.Fragment>
                        <div className="Comments modal-header"><span className="log-history-title float-left">Add Questions </span>
                            <Button className="jr-btn close-btn float-right" onClick={this.HandleClose}><ClearIcon /></Button>
                        </div>
                        <DialogContent>
                            {this.loading ? <CircularProgress></CircularProgress>
                                :
                                <React.Fragment>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <TextArea placeholder="Enter Questions"
                                                onChange={this.handleMessage} value={this.state.question} className="textarea-box"
                                                style={{ border: "1px solid " + this.state.ColorCode }}>
                                            </TextArea>
                                        </div>
                                    </div>
                                    <div className="add-comment-btn-box">
                                        {this.state.IsButtonEnable ?
                                            <Button className="add-comment-btn" style={{ backgroundColor: this.state.ColorCode }} onClick={this.AddNewQues}> Add Questions </Button>
                                            :
                                            <Button disabled className="add-comment-btn" style={{ backgroundColor: this.state.ColorCode, opacity: 0.5 }}> Add Questions </Button>
                                        }
                                    </div>
                                </React.Fragment>
                            }
                        </DialogContent>
                    </React.Fragment>
                </Dialog>
            </React.Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        CommentsData: state.QuestionCommentsData.CommentsData,
        AddComman: state.QuestionCommentsData.AddComman
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        AddQuestion,
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(AddQuestions);
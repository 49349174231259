import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import {updateVendorType } from '../appRedux/actions/vendorAction';
import {findIndex} from "lodash";
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Textarea from 'react-expanding-textarea';

class VendorTypeForm extends React.Component {

    constructor() {
        super()
        this.state = {
          vendorTypeId:0,
          name:'',
          comments:'',
          description:''
        }
        this.handleSubmit=this.handleSubmit.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
    }

    handleCancel = () => {
      this.setState({
        vendorTypeId:0,
        name:'',
        comments:'',
        description:''
     });
        this.props.handleCancel()
    };

       componentWillReceiveProps(nextProps){ 

      if(this.props.visible !== nextProps.visible) {
         this.setState({
            vendorTypeId:nextProps.selectedItem.vendorTypeId,
            name:nextProps.selectedItem.name,
            comments:nextProps.selectedItem.comments,
            description:nextProps.selectedItem.description,
         });
      }
    }
    
    handleSubmit = (e) => {
       e.preventDefault();
       let data = {
        vendorTypeId:this.state.vendorTypeId,
        name:this.state.name,
        comments:this.state.comments,
        description:this.state.description
       };

        this.props.updateVendorType(data);       
        this.props.handleSave(false);
        this.setState({
          vendorTypeId:0,
          name:'',
          comments:'',
          description:''
       });
     }

    handleFormChange = (e) => {
       this.setState({[e.target.name]:e.target.value})
    }

    handleFormChecked = name => event => {

      this.setState({ [name]: event.target.checked });
    };
        
    render() {
        const {visible,isEdit,entityTypeData,vendorTypeData,selectedItem} = this.props;
       
        return (
            <Dialog
            maxWidth="md"
            fullWidth={true}
            open={visible}
            onClose={this.handleCancel}
            >
             <form method="post" onSubmit={this.handleSubmit}  autoComplete="off" style={{ padding: 20 }}>
            <DialogTitle>{isEdit?'Edit':'Add'} Vendor</DialogTitle>
             <DialogContent>
                 <fieldset>
                     <Grid container spacing={40} >
                        <Grid item sm={6} >
                            <TextField
                                name="name"
                                label="Name"
                                fullWidth
                                required={true}
                                onChange={this.handleFormChange}
                                defaultValue={this.state.name}
                                margin="normal"
                            />
                        </Grid>  
                        <Grid item sm={12}>
                         <InputLabel htmlFor="comments" style={{marginTop:0}}>Comments</InputLabel>
                         <Textarea
                            value={this.state.comments}
                            style={{'lineHeight':'20px',width:'100%',border:'none',marginTop:'4px',resize:'none',padding:'6px',paddingLeft:'10px',borderRadius:'3px',border:'1px solid #ccc',overflow:'hidden' }} resize={false}   placeholder="Comment"
                            onChange={(e)=>{ this.setState({comments:e.target.value});  }}
                            />
                        </Grid> 
                        <Grid item sm={12}>
                         <InputLabel htmlFor="comments" style={{marginTop:0}}>Description</InputLabel>
                         <Textarea
                            value={this.state.description}
                            style={{'lineHeight':'20px',width:'100%',border:'none',marginTop:'4px',resize:'none',padding:'6px',paddingLeft:'10px',borderRadius:'3px',border:'1px solid #ccc',overflow:'hidden' }} resize={false}   placeholder="Comment"
                            onChange={(e)=>{ this.setState({description:e.target.value});  }}
                            />
                        </Grid> 
                         
                     </Grid>
                    </fieldset>
              
                 </DialogContent>
                <DialogActions>
                <Button type="submit" color="primary">
                {isEdit?'Update':'Save'}
                </Button>
                <Button onClick={this.handleCancel} color="primary" autoFocus>
                  Cancel
                </Button>
              </DialogActions>
                </form>
            </Dialog>
        );
    }
}

   
const mapDispatchToProps=(dispatch)=>{
    return bindActionCreators({
        updateVendorType
    },dispatch)
}

export default connect(null, mapDispatchToProps)(VendorTypeForm);




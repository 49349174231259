import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import {addInsurance,updateInsurance,deleteInsurance} from '../appRedux/actions/insuranceAction';
import {findIndex} from "lodash";
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import moment from 'moment';
import {Form} from "antd";
import MaskedInput from 'react-text-mask';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import './form.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const FormItem = Form.Item;
function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      prefix="$ "
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};


function NumberFormatCustom2(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      suffix=" %"
      decimalScale={3}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value>100?100:(values.value.split(".").length>1 && values.value.split(".")[1].length>3)?values.value.split(".")[0]+'.'+values.value.split(".")[1].substr(0,3):values.value,
          },
        });
      }}


    />
  );
}

NumberFormatCustom2.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};
class AddInsuranceForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
              "insurancePolicyId": 0,
              "insuranceCompanyId": 0,
              "policyTypeId": "",
              "vendorID":"",
              "policyNumber": "",
              "employeeID": "",
              "personalBeneficiary": "",
              "personalBeneficiaryPercent": 0,
              "employeeCoPay": "",
              "comments": "",
              "terminationDate": null,
              "status": true,
              "isReviewComplete": false,
              "policyReviewDate": new Date(),//moment().format('YYYY-MM-DD')
              selectedItem:false,
              employeeIDDisabled:false
        }
        this.handleSubmit=this.handleSubmit.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
    }

    handleCancel = () => {
        this.setDefaultState()
        this.props.handleCancel()
    };

    setDefaultState = () => {
       this.setState({
              "insurancePolicyId": 0,
              "insuranceCompanyId": 0,
              "policyTypeId": "",
              "vendorID": "",
              "policyNumber": "",
              "employeeID":"",
              "personalBeneficiary": "",
              "personalBeneficiaryPercent": 0,
              "employeeCoPay": "",
              "comments": "",
              "terminationDate": null,
              "status": true,
              "isReviewComplete": false,
              "policyReviewDate": new Date(),//moment().format('YYYY-MM-DD')
        });
    }

    handleSubmit = (e) => {
       e.preventDefault();
       
       this.props.form.validateFields((err, values) => {
       if (!err) {
           let data = {
              insurancePolicyId:this.state.insurancePolicyId,
              companyId:this.props.companyId,
              insuranceCompanyId:this.state.insuranceCompanyId,
              policyTypeId:Number(this.state.policyTypeId),
              vendorID:Number(this.state.vendorID),
              policyNumber:this.state.policyNumber,
              employeeID:Number(this.state.employeeID),
              personalBeneficiary:this.state.personalBeneficiary,
              personalBeneficiaryPercent:this.state.personalBeneficiaryPercent,
              employeeCoPay:this.state.employeeCoPay,
              comments:this.state.comments,
              terminationDate:this.state.terminationDate==="Invalid date"?null:this.state.terminationDate,
              status:this.state.status,
              isReviewComplete:this.state.isReviewComplete,
              policyReviewDate:this.state.policyReviewDate==="Invalid date"?null:this.state.policyReviewDate
        };
        if(!this.props.isEdit)
  				this.props.addInsurance(data);
  			else
  				this.props.updateInsurance(data);

        this.setDefaultState();
        this.props.handleSave(false);
        }});
     }

     componentWillReceiveProps(nextProps) {
   		if(nextProps.selectedItem !=this.state.selectedItem)
   		{        
   			this.setState({
          insurancePolicyId:nextProps.selectedItem.insurancePolicyId,
          companyId:nextProps.selectedItem.companyId,
          insuranceCompanyId:nextProps.selectedItem.insuranceCompanyId,
          policyTypeId:nextProps.selectedItem.policyTypeId,
          vendorID:nextProps.selectedItem.vendorID,
          policyNumber:nextProps.selectedItem.policyNumber,
          employeeID:nextProps.selectedItem.employeeID,
          personalBeneficiary:nextProps.selectedItem.personalBeneficiary,
          personalBeneficiaryPercent:nextProps.selectedItem.personalBeneficiaryPercent,
          employeeCoPay:nextProps.selectedItem.employeeCoPay,
          comments:nextProps.selectedItem.comments,
          terminationDate:nextProps.selectedItem.terminationDate!=''?new Date(nextProps.selectedItem.terminationDate):null,
          status:nextProps.selectedItem.status,
          isReviewComplete:nextProps.selectedItem.isReviewComplete,
          policyReviewDate:nextProps.selectedItem.policyReviewDate!=''?new Date(nextProps.selectedItem.policyReviewDate):null,
          selectedItem:nextProps.selectedItem
   			})
   		}
       }
    handleFormChange = (e,target) => {
      
      if(!target)
      {
        this.setState({ [e.target.name]: e.target.value })
      }
      else if(target=="terminationDate")
      {
        this.setState({ terminationDate: new Date(e)  });       //moment(e).format('YYYY-MM-DD')
      }
      else if(target=="policyReviewDate")
      {        
        this.setState({ policyReviewDate: new Date(e) });  
      }

       
    }

    handleFormCheched = name => event => {

    this.setState({ [name]: event.target.checked });
  };
  handleDelete = () => {
      let policyId = this.state.insurancePolicyId;
      this.props.deleteInsurance(policyId);
      this.props.handleSave(false);
  }

   handleChange = name => event => {
      this.setState({ [name]: event.target.value });
      if(name=="personalBeneficiary")
      {
        
        if(event.target.value!="Yes")
        {
          this.setState({personalBeneficiaryPercent:0});
          this.props.form.resetFields();
        }

      }
      if(name=="policyTypeId")
      {
        if(event.target.value==5 || event.target.value==4 || event.target.value==2 || event.target.value==9)
        {
          this.setState({employeeID:''});
          this.props.form.resetFields();
          
        }
      
        if(event.target.value==5 || event.target.value==4)
        {
          this.setState({personalBeneficiary:''});
          this.props.form.resetFields();  
        }
        if(event.target.value==5 || event.target.value==4 || event.target.value==2 || event.target.value==1 || event.target.value==3)
        {
          this.setState({employeeCoPay:''});
          this.props.form.resetFields();
        }

        
      }
    };

    render() {      
        const {getFieldDecorator} = this.props.form;
        const {visible,policyTypes,vendors,employees} = this.props;
        return (
            <Dialog
             maxWidth="md"
            fullWidth={true}
            open={visible}
            onClose={this.handleCancel}
            >

            <DialogTitle>{this.props.isEdit?'Edit':'Add'} Insurance Policy</DialogTitle>
            <Form>
             <DialogContent>
                 <fieldset>
                 <Grid container spacing={24} >
                     <Grid item sm={4} >
     <FormItem>
     <InputLabel className="labletext">Insurance Company</InputLabel>
     {getFieldDecorator('vendorID', {
       initialValue:this.state.vendorID,
       rules: [{
         required: true, message: 'Please Insurance Company',
       }],
       })( <Select
                             name="vendorID"
                             native
                            required={true}
                             label={"Insurance Company"}
                             fullWidth
                             onChange={this.handleChange('vendorID')}
                             inputProps={{
                                 name: 'displayName',
                                 id: 'vendorID',

                             }}

                         >
                          <option value={''}>Select Vendor</option>
                          {vendors.length && vendors.map((value, index)=>(
                           <option value={value.vendorID} key={index}>{value.displayName}</option>
                          ))}
                         </Select>
     )}</FormItem>
                     </Grid>


                      <Grid item sm={4} >
                      <FormItem>
                      <InputLabel className="labletext">Policy Type</InputLabel>
      								{getFieldDecorator('policyTypeId', {
      									initialValue:this.state.policyTypeId,
      									rules: [{
      									  required: true, message: 'Please enter policy',
      									}],
      								  })(


                        <Select
                            name="policyTypeId"
                            native
                            required={true}
                            label={"Policy Type"}
                            fullWidth
                            onChange={this.handleChange('policyTypeId')}
                            inputProps={{
                                name: 'policyTypeId',
                                id: 'policyTypeId',
                            }}

                        >
                         <option value={''}>Select Policy Type</option>
                         {policyTypes.length && policyTypes.map((value, index)=>(
                          <option value={value.id} key={index}>{value.type}</option>
                         ))}
                        </Select>	)}</FormItem>
                    </Grid>


                     <Grid item sm={4}>
                     <FormItem>
     								{getFieldDecorator('policyNumber', {
     									initialValue:this.state.policyNumber,
     									rules: [{
     									  required: true, message: 'Please enter policy number',
     									}],
     								  })(
                             <TextField
                                name="policyNumber"
                                required={true}
                                label={"Policy Number"}
                                fullWidth
                                onChange={this.handleFormChange}
                                margin="normal"
                            />	)}</FormItem>
                        </Grid>
                        <Grid item sm={4} >
                        <FormItem>
                        <InputLabel className="labletext">Employee</InputLabel>
     {getFieldDecorator('employeeID', {
       initialValue:this.state.employeeID,
       rules: [{
        required: this.state.policyTypeId==5 || this.state.policyTypeId==4 || this.state.policyTypeId==2, message: 'Please select employee',
      }],
       })( <Select
                             name="employeeID"
                             native
                             label={"Employee"}
                             fullWidth
                            disabled={!(this.state.policyTypeId==5 || this.state.policyTypeId==4 || this.state.policyTypeId==2 || this.state.policyTypeId==9)}
                             onChange={this.handleChange('employeeID')}
                             inputProps={{
                                 name: 'displayName',
                                 id: 'employeeID',

                             }}
                             
                         >
                          <option value={''}>Select Employee</option>
                          {employees.length && employees.map((value, index)=>(
                           <option value={value.employeeID} key={index}>{value.name}</option>
                          ))}
                         </Select>
     )}</FormItem>
                        </Grid>





                     <Grid item sm={4} >
                     <FormItem>
                     <InputLabel className="labletext">Personal Beneficiary</InputLabel>
     								{getFieldDecorator('personalBeneficiary', {
     									initialValue:this.state.personalBeneficiary,
     									rules: [{
     									  required: this.state.policyTypeId==5 || this.state.policyTypeId==4, message: 'Please enter personal beneficiary',
     									}],
     								  })(
                        <Select
                            name="personalBeneficiary"
                            native
                            required={true}
                            label={"Personal Beneficiary"}
                            fullWidth
                            onChange={this.handleChange('personalBeneficiary')}
                            inputProps={{
                                name: 'personalBeneficiary',
                                id: 'personalBeneficiary',
                            }}
                            disabled={!(this.state.policyTypeId==5 || this.state.policyTypeId==4)}

                        >
                        <option value={''}>Select Personal Beneficiary</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                        </Select>	)}</FormItem>
                        </Grid>

                      <Grid item sm={4}>
                      <FormItem>
                     {getFieldDecorator('personalBeneficiaryPercent', {
                       initialValue:this.state.personalBeneficiaryPercent,
                       rules: [{
                         required: this.state.personalBeneficiary=="Yes", message: 'Please enter personal beneficiary percent',
                       }],
                       })(<TextField
                                name="personalBeneficiaryPercent"
                                required={true}
                                label={"Personal Beneficiary Percent"}
                                fullWidth
                                disabled={!(this.state.personalBeneficiary=="Yes")}
                                onChange={this.handleChange("personalBeneficiaryPercent")}

                                margin="normal"
                                InputProps={{
            											inputComponent: NumberFormatCustom2,
            										  }}
                            />	)}</FormItem>
                        </Grid>

                         <Grid item sm={4}>
                         <FormItem>
                         <InputLabel className="labletext">Employee Co-Pay</InputLabel>
         								{getFieldDecorator('employeeCoPay', {
         									initialValue:this.state.employeeCoPay,
         									rules: [{
         									  required: this.state.policyTypeId==5 || this.state.policyTypeId==4 || this.state.policyTypeId==2 || this.state.policyTypeId==1 || this.state.policyTypeId==3 || (this.state.employeeID!='' && this.state.policyTypeId==9), message: 'Please enter employee co-pay',
         									}],
         								  })(
                            <Select
                                name="employeeCoPay"
                                native
                                disabled={!(this.state.policyTypeId==5 || this.state.policyTypeId==4 || this.state.policyTypeId==2 || this.state.policyTypeId==1 || this.state.policyTypeId==3 || this.state.policyTypeId==9)}
                                label={"Employee Co-Pay"}
                                fullWidth
                                onChange={this.handleChange('employeeCoPay')}
                                inputProps={{
                                    name: 'employeeCoPay',
                                    id: 'employeeCoPay',
                                }}

                            >
                            <option value={''}>Select Employee Co-Pay</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                            </Select>
	                           )}</FormItem>
                        </Grid>

                    
                          <Grid item sm={4}>
                          <FormItem>
                          <InputLabel className="labletext">Termination Date</InputLabel><br></br>
          								<DatePicker
                                selected={this.state.terminationDate}
                                onChange={(e) => this.handleFormChange(e,'terminationDate')}
                                label="Termination Date"
                                name="terminationDate"
                                id="date"                        
                                margin="normal"                       
                              /></FormItem>
                        </Grid>
                        <Grid item sm={4}>
                     <FormItem>
                    {getFieldDecorator('comments', {
                      initialValue:this.state.comments,
                      rules: [{

                      }],
                      })(<TextField
                                name="comments"

                                label={"Comments"}
                                fullWidth
                                onChange={this.handleFormChange}
                                margin="normal"
                            />	)}</FormItem>
                        </Grid>

                         <Grid item sm={4}  style={{ 'paddingTop': '30px' }}>
                         <Checkbox
                            className="label_lease"
                            onChange={this.handleFormCheched('isReviewComplete')}
                            checked={this.state.isReviewComplete}
                                    />
                         <label>Policy Review Complete</label>
                        </Grid>

                        <Grid item sm={4}>
                        <FormItem>
                        <InputLabel className="labletext">Policy Review Date </InputLabel><br></br>
                        <DatePicker
                                  selected={this.state.policyReviewDate}
                                  onChange={(e) => this.handleFormChange(e,'policyReviewDate')}
                                  label="Policy Review Date"
                                  name="policyReviewDate"
                                  id="date"                        
                                  margin="normal"                       
                            /></FormItem>
                      </Grid>

                      </Grid>

                    </fieldset>

                 </DialogContent>
                 </Form>
                 <DialogActions>

                     <Button color="primary" onClick={this.handleSubmit}>
                        {this.props.isEdit?'Update':'Save'}
                     </Button>
                        <Button color="primary" onClick={this.handleCancel} >
                         Cancel
                     </Button>
 					{this.props.isEdit?
                     <Button onClick={this.handleDelete} color="primary">
                         Delete
 					</Button>:null}

                 </DialogActions>
            </Dialog>
        );
    }
}


const AddInsurance = Form.create()(AddInsuranceForm);
const mapStateToProps = (state) => {
return {

}
};

const mapDispatchToProps=(dispatch)=>{
    return bindActionCreators({
        addInsurance,updateInsurance,deleteInsurance
    },dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AddInsurance);

import React from "react";
import Grid from '@material-ui/core/Grid';
import IntlMessages from "../../../../util/IntlMessages";

const TaxableReviewPage = () => {
  return (
    <div>
       <Grid 
	       container
	       spacing={24}
	       direction="column"
	     >
        <Grid item xs={12} style={{padding:20}}>
           <h3 className="user-title">Taxable Review Page </h3>
        </Grid>
         <Grid item xs={12} style={{padding:20}}>
           <h4>Taxable Review  data will be here</h4>
        </Grid>
      </Grid>
    </div>
  );
};

export default TaxableReviewPage;

import React from "react";
import { bindActionCreators } from "redux";
import { updateEmployee, addEmployee } from '../appRedux/actions/employeeAction';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { connect } from "react-redux";
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import moment from 'moment';
import { Form } from "antd";
import MaskedInput from 'react-text-mask';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import Textarea from 'react-expanding-textarea';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from 'sweetalert2';

const FormItem = Form.Item;

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      prefix="$ "
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

function NumberFormatCustom3(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      format="## %"
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}


    />
  );
}

NumberFormatCustom3.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};
function NumberFormatCustom2(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}


    />
  );
}

NumberFormatCustom2.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

class AddEmployee extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      EmployeeID: 0,
      companyID: '',
      name: '',
      employeeType: 'Non-Clergy',
      status: '',
      isAddedPayroll: '',
      filingStatus: '',
      employerSECA: 0,
      housingAllowance: '',
      housingAllowanceApproveDate: null,
      usersChurchParsonage: '',
      taxableLifeInsurance: '',
      companyVehicle: '',
      vehicleFormReceived: '',
      vehicleReported: '',
      complete: '',
      status: '',
      isActive: true,
      dateLastUpdated: moment().format('YYYY-MM-DD'),
      comments: '',
      ShowAddButton: true,
      ListID: null,
      IsOverrideActive:false
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        
        let Active = this.state.isActive;
        if(this.props.IsPayrollCompany == true)
        {
          Active = this.state.IsOverrideActive ? false : this.state.isActive;
        }

        let data = {
          EmployeeID: this.state.EmployeeID,
          companyID: this.props.companyID,
          name: this.state.name,
          employeeType: this.state.employeeType,
          status: this.state.status,
          isAddedPayroll: this.state.isAddedPayroll ? 1 : 0,
          filingStatus: this.state.filingStatus,
          employerSECA: this.state.employerSECA == '' ? 0 : this.state.employerSECA,
          housingAllowance: this.state.housingAllowance == '' ? 0 : this.state.housingAllowance,
          housingAllowanceApproveDate: (!this.state.housingAllowanceApproveDate || this.state.housingAllowanceApproveDate === 'Invalid date') ? null : moment(this.state.housingAllowanceApproveDate).format('YYYY-MM-DD'),
          usersChurchParsonage: this.state.usersChurchParsonage ? 1 : 0,
          taxableLifeInsurance: this.state.taxableLifeInsurance == '' ? 0 : this.state.taxableLifeInsurance,
          companyVehicle: this.state.companyVehicle ? 1 : 0,
          vehicleFormReceived: this.state.vehicleFormReceived ? 1 : 0,
          vehicleReported: this.state.vehicleReported ? 1 : 0,
          complete: this.state.complete ? 1 : 0,
          // status: this.state.status,
          isActive: Active,//this.state.isActive,
          dateLastUpdated: this.state.dateLastUpdated,
          comments: this.state.comments,
          ListID: this.state.ListID,
          isOverrideQBActiveStatus:this.state.IsOverrideActive
        }
        if (!this.props.isEdit)
          this.props.addEmployee(data);
        else
          this.props.updateEmployee(data);
        this.setDefaultState();
        this.props.handleSave(false);
      }
    });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedItem && nextProps.selectedItem != this.state.selectedItem) {
      this.setState({
        selectedItem: nextProps.selectedItem,
        EmployeeID: nextProps.selectedItem.employeeID,
        companyID: nextProps.selectedItem.companyID,
        //name: nextProps.selectedItem.nameWithW4,
        name: nextProps.selectedItem.name,
        employeeType: nextProps.selectedItem.employeeType ? nextProps.selectedItem.employeeType : 'Non-Clergy',
        status: nextProps.selectedItem.status,
        isAddedPayroll: nextProps.selectedItem.isAddedPayroll,
        filingStatus: nextProps.selectedItem.filingStatus,
        employerSECA: nextProps.selectedItem.employerSECA,
        housingAllowance: nextProps.selectedItem.housingAllowance,
        housingAllowanceApproveDate: new Date(nextProps.selectedItem.housingAllowanceApproveDate),
        usersChurchParsonage: nextProps.selectedItem.usersChurchParsonage,
        taxableLifeInsurance: nextProps.selectedItem.taxableLifeInsurance,
        companyVehicle: nextProps.selectedItem.companyVehicle,
        vehicleFormReceived: nextProps.selectedItem.vehicleFormReceived,
        vehicleReported: nextProps.selectedItem.vehicleReported,
        complete: nextProps.selectedItem.complete,
        // status: nextProps.selectedItem.status,
        isActive: nextProps.selectedItem.isActive,
        dateLastUpdated: moment(nextProps.selectedItem.dateLastUpdated).format('YYYY-MM-DD'),
        comments: nextProps.selectedItem.comments,
        ShowAddButton: nextProps.ShowAddButton,
        ListID: nextProps.selectedItem.listID,
        IsOverrideActive:nextProps.selectedItem.isOverrideQBActiveStatus ? true : false
      })
    }
  }
  handleChange = name => event => {
    this.setState({ [name]: event.target.value });

    if (name === "employeeType") {


      this.state.filingStatus = '';
      this.props.form.resetFields();
    }
  };

  handleFormChange = (e, target) => {
    if (!target) {
      this.setState({ [e.target.name]: e.target.value })
    }
    else if (target == "housingAllowanceApproveDate") {

      this.setState({ housingAllowanceApproveDate: new Date(e) })
    }
  }
  handleDelete = () => {
    let id = this.state.EmployeeID;

    this.props.handleDelete(id);
  }
  setDefaultState = () => {
    this.setState({
      EmployeeID: 0,
      companyID: '',
      name: '',
      employeeType: 'Non-Clergy',
      status: '',
      isAddedPayroll: '',
      employerSECA: 0,
      filingStatus: '',
      housingAllowance: '',
      housingAllowanceApproveDate: null,
      usersChurchParsonage: '',
      taxableLifeInsurance: '',
      companyVehicle: '',
      vehicleFormReceived: '',
      vehicleReported: '',
      complete: '',
      // status: '',
      isActive: true,
      dateLastUpdated: new Date(),//moment().format('YYYY-MM-DD')
      comments: '',
      ShowAddButton: true,
      ListID: null,
      IsOverrideActive:false
    })
  }

  handleFormCheched = name => event => {
    
    this.setState({ [name]: event.target.checked });
  };




  handleCancel = () => {
    this.setDefaultState();
    this.props.handleCancel()
  };

  handleOverrideActiveChecked = name => event => {
    
      if(event.target.checked){
        let Name = [name];
        Swal.fire({
          //title: 'Do you want to continue?',
          showCancelButton: true,
          // imageUrl: './assets/images/user-confirm.png',
          // imageHeight: 230,
          icon:"info",
          confirmButtonText: 'Continue',
          // customClass : {
          //   'container' : 'detail-alert'
          // },
          html:'This status change will have no impact on the QuickBooks Online Payroll system. You must make employees inactive within QB Online.',
          allowOutsideClick: false
        }).then((result) => {
          if (result.isConfirmed) {
      
              this.setState({ IsOverrideActive: true });
          }else{
            this.setState({ IsOverrideActive: false });
          }
        });
      }else{
        this.setState({ IsOverrideActive: false });
      }
    
  };
  

  render() {
    const { getFieldDecorator } = this.props.form;
    const { visible, classes, leaseTypes, propertyTypes, vendorTypes, vendors } = this.props;    
    return (
      <Dialog
        maxWidth="lg"
        fullWidth={true}
        open={visible}
        onClose={this.handleCancel}
        style={{zIndex:"1050"}}
      >

        <DialogTitle>{this.props.isEdit ? 'Edit' : 'Add'} Employee</DialogTitle>
        <DialogContent>
          <Form>
            <fieldset>
              <Grid container spacing={24} >
                <Grid item sm={3} >
                  <FormItem>
                    {getFieldDecorator('name', {
                      initialValue: this.state.name,
                      rules: [{
                        required: true, message: 'Please enter name',
                      }],
                    })(
                      <TextField
                        name="name"
                        required={true}
                        label={"Name"}
                        fullWidth
                        margin="normal"
                        onChange={this.handleFormChange}

                      />)}
                  </FormItem>
                </Grid>
                <Grid item sm={3}>
                  <FormItem>
                    <InputLabel className="labletext">Employee Type</InputLabel>
                    {getFieldDecorator('employeeType', {
                      initialValue: this.state.employeeType,
                      rules: [{
                        required: true, message: 'Please enter employee type',
                      }],
                    })(
                      <Select
                        name="employeeType"
                        native
                        required={true}
                        label={"Employee Type"}
                        fullWidth
                        onChange={this.handleChange('employeeType')}
                        inputProps={{
                          name: 'employeeType',
                          id: 'account-native-simple',
                        }}

                      >
                        {/* <option value={''}>Select Employee Type</option> */}
                        <option value={'Clergy'}>Clergy</option>
                        <option value={'Non-Clergy'}>Non-Clergy</option>

                      </Select>)}
                  </FormItem>
                </Grid>

                <Grid item sm={3}>
                  <FormItem>
                    <InputLabel className="labletext">W-4 Status</InputLabel>
                    {/* {getFieldDecorator('status', {
                      initialValue: this.state.status,
                      // rules: [{
                      //   required: true, message: 'Please enter status',
                      // }],
                    })(
)} */}
                    <Select
                      name="status"
                      native
                      // required={true}
                      label={"W-4 Status"}
                      fullWidth
                      // value={this.state.status}
                      onChange={this.handleChange('status')}
                      inputProps={{
                        name: 'status',
                        id: 'account-native-simple',
                      }}


                    >
                      <option value={''}>Select W-4 Status</option>
                      {this.state.status!= null && this.state.status.toLocaleLowerCase()=="clergy" ?
                        <option value={'Clergy'} selected>Pending</option>
                        :
                        <option value={'Clergy'}>Pending</option>
                      }
                       {this.state.status!= null && this.state.status.toLocaleLowerCase()=="non-clergy" ?
                        <option value={'Non-Clergy'} selected>Completed</option>
                        :
                        <option value={'Non-Clergy'}>Completed</option>
                      }
                      
                      {/* <option value={'Non-Clergy'}>Completed</option> */}

                    </Select>
                  </FormItem>

                </Grid>
                <Grid item sm={3}>
                  <InputLabel className="labletext">4361 Filing Status</InputLabel>
                  <FormItem>
                    {getFieldDecorator('filingStatus', {
                      initialValue: this.state.filingStatus,

                      rules: [{
                        required: this.state.employeeType == 'Clergy', message: 'Please enter fling status',
                      }],
                    })(
                      <Select
                        name="filingStatus"
                        native
                        disabled={this.state.employeeType != 'Clergy'}
                        label={"4361 Filing Status"}
                        fullWidth
                        onChange={this.handleChange('filingStatus')}
                        inputProps={{
                          name: 'filingStatus',
                          id: 'account-native-simple',
                        }}

                      >
                        <option value={''}>Select 4361 Filling Status</option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                        <option value={'Pending Confirmation'}>Pending Confirmation</option>
                      </Select>)}
                  </FormItem>
                </Grid>
              </Grid>

              <Grid container spacing={24}>
                <Grid item sm={3}>
                  <FormItem>
                    {getFieldDecorator('housingAllowance', {
                      initialValue: this.state.housingAllowance,

                    })(
                      <TextField
                        name="housingAllowance"

                        label={"Board Approved Housing Allowance"}
                        fullWidth
                        margin="normal"
                        onChange={(e) => this.setState({ housingAllowance: e.target.value })}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}

                      />)}
                  </FormItem>
                </Grid>

                <Grid item sm={3}>
                  <FormItem>
                    <InputLabel className="labletext">Housing Allowance Approval Date</InputLabel>
                    <DatePicker
                      selected={this.state.housingAllowanceApproveDate}
                      onChange={(e) => this.handleFormChange(e, 'housingAllowanceApproveDate')}
                      label="Housing Allowance Approval Date"
                      name="housingAllowanceApproveDate"
                      id="date"
                      margin="normal"
                    /></FormItem>
                </Grid>
                <Grid item sm={3}>
                  <FormItem>
                    {getFieldDecorator('employerSECA', {
                      initialValue: this.state.employerSECA,

                    })(
                      <TextField
                        name="employerSECA"

                        label={"Employer SECA"}
                        fullWidth
                        margin="normal"
                        onChange={(e) => this.setState({ employerSECA: e.target.value })}
                        InputProps={{
                          inputComponent: NumberFormatCustom3,
                        }}

                      />)}
                  </FormItem>
                </Grid>

                <Grid item sm={3}>
                  <FormItem>
                    {getFieldDecorator('taxableLifeInsurance', {
                      initialValue: this.state.taxableLifeInsurance,

                    })(
                      <TextField
                        name="taxableLifeInsurance"

                        label={"Taxable Life Insurance"}
                        fullWidth
                        margin="normal"
                        onChange={(e) => this.setState({ taxableLifeInsurance: e.target.value })}
                        InputProps={{
                          inputComponent: NumberFormatCustom3,
                        }}

                      />)}
                  </FormItem>
                </Grid>
              </Grid>

              <Grid container spacing={24}>


                <Grid item sm={3}>
                  <Checkbox
                    className="label_lease"
                    onChange={this.handleFormCheched('usersChurchParsonage')}
                    checked={this.state.usersChurchParsonage}
                  />
                  <label>Uses Church Parsonage</label>
                </Grid>

                <Grid item sm={3}>
                  <Checkbox
                    className="label_lease"
                    onChange={this.handleFormCheched('companyVehicle')}
                    checked={this.state.companyVehicle}
                  />
                  <label>Company Vehicle</label>
                </Grid>


                <Grid item sm={6}>
                  <FormItem>
                    <InputLabel className="labletext" style={{ marginTop: 0 }}>Comments</InputLabel>
                    <Textarea
                      value={this.state.comments}
                      style={{ 'lineHeight': '19px', width: '100%', border: 'none', marginTop: '0px', resize: 'none', padding: '6px', paddingLeft: '10px', borderBottom: '1px solid rgba(0, 0, 0, 0.42)', overflow: 'hidden' }} resize={false} placeholder="Comment"
                      onChange={(e) => { this.setState({ comments: e.target.value }); }}
                    />
                  </FormItem>
                </Grid>


              </Grid>
              <Grid container spacing={24}>

                <Grid item sm={3}>
                  <Checkbox
                    className="label_lease"
                    onChange={this.handleFormCheched('vehicleFormReceived')}
                    checked={this.state.vehicleFormReceived}
                  />
                  <label>Vehicle Form Received</label>
                </Grid>
                <Grid item sm={2} >

                  <Checkbox
                    className="label_lease"
                    onChange={this.handleFormCheched('isAddedPayroll')}
                    checked={this.state.isAddedPayroll}
                  />
                  <label>Added to Payroll</label>
                </Grid>

                <Grid item sm={2}>
                  <Checkbox
                    className="label_lease"
                    onChange={this.handleFormCheched('complete')}
                    checked={this.state.complete}
                  />
                  <label>Complete</label>
                </Grid>

                <Grid item sm={2}>
                  <Checkbox
                    className="label_lease"
                    onChange={this.handleFormCheched('isActive')}
                    checked={this.state.isActive}
                    disabled={!this.state.ShowAddButton}
                  />
                  <label>Active / Inactive</label>
                </Grid>

                {this.props.IsPayrollCompany == true ? 
                <Grid item sm={3}>
                  <Checkbox
                    className="label_lease"
                    onChange={this.handleOverrideActiveChecked('IsOverrideActive')}
                    checked={this.state.IsOverrideActive}
                    //disabled={!this.state.ShowAddButton}
                  />
                  <label>Override QB Active Status</label>
                </Grid>
                : ""}



              </Grid>
            </fieldset>
          </Form>
        </DialogContent>
        <DialogActions>

          <Button color="primary" onClick={this.handleSubmit}>
            {this.props.isEdit ? 'Update' : 'Save'}
          </Button>
          <Button color="primary" onClick={this.handleCancel} >
            Cancel
                    </Button>
          {this.props.isEdit ?
            <Button onClick={this.handleDelete} color="primary">
              Delete
					</Button> : null}

        </DialogActions>

      </Dialog>
    );
  }
}
const Addlease = Form.create()(AddEmployee);

const mapStateToProps = (state) => {
  return {

  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    updateEmployee,
    addEmployee,
  }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Addlease);

import React, { Component } from 'react';
import IntlMessages from "../../../util/IntlMessages";
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ReportBox from '../../../components/ReportBox/index';
import ContainerHeader from '../../../components/ContainerHeader/index';
import { Check, Warning  }from '@material-ui/icons';
import MarketingTable from '../../../components/MarketingTable';
import {FinancialData, ComplianceData } from '../../../components/dashboard/data.js';
import  jwt_decode from 'jwt-decode';

import Dashboard from '../../../components/dashboard/ECommerce';

export default class UserDashboard extends Component {

    render(){

       const user = JSON.parse(localStorage.getItem('userData'));
        let decode=jwt_decode(user.Authorization);
         var name=decode['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'];

        return(
            <div className="dashboardPage" style={{padding:15}}>
             <ContainerHeader match={this.props.match} title={`${name !== undefined ? `${name} - User` : 'User'} Dashboard`}/>

            </div>
        )
    }
}

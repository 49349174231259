import baseService from './baseService';


export function getEmployees(CompanyID,search,pageNumber,pageSize,sortField,sortFormat,Active){
    return baseService.post(`/Employee/all_page`,{ CompanyID,search,pageNumber,pageSize,sortField,sortFormat,Active });
}
export function getEmployees_BES(CompanyID,search,pageNumber,pageSize,sortField,sortFormat){
    return baseService.post(`/Employee/all_page_BES`,{ CompanyID,search,pageNumber,pageSize,sortField,sortFormat });
}

export function getAllEmployees(value){
    return baseService.get(`/Employee/all?CompanyId=` + value);
}
export function addEmployee(data){
    return baseService.post(`/Employee`, data);
}

export function updateEmployee(data){
    return baseService.put(`/Employee`, data);
}

export function deleteEmployee(value){
    return baseService.delete(`/Employee`, { params:{ id:value } });
}

export function FlagEmployee(data) {
    return baseService.post("/Employee/updateactive",data);
}

export function UpdateVehicleFormReceived(data)
{
    return baseService.post("/Employee/updatevehicleformreceived",data);
}

export function IsAddedPayroll(data)
{
    return baseService.post("/Employee/updateisaddedpayroll",data);
}

export function UpdateEmployeeType(data)
{
    return baseService.post("/Employee/updateemployeetype",data);
}

export function UpdateEmployeeType_BES(data)
{
    return baseService.post("/Employee/updateemployeetype_BES",data);
}
export function SetBatchEmployeeFlag(data) {
    return baseService.post("/Employee/BatchUpdateActive",data);
}

export function GET_HousingAllowanceHistory_DATA(data){
    return baseService.post(`/Employee/GetEmployeeApprovedHousingAllowanceHistory`,data);
}
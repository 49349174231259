import * as BalanceSheetService from '../services/BalanceSheetService';
import React from 'react';


export const GET_BALANCESHEET_DETAILS = "GET_BALANCESHEET_DETAILS";
export const GET_ARCHIVED_DETAILS = "GET_ARCHIVED_DETAILS";
export const UPDATE_CUSTOM_FILEDS ="UPDATE_CUSTOM_FILEDS";
export const GET_GLReviewLastQueterDate ="GET_GLReviewLastQueterDate";
export const GET_EDIT_ARCHIED_DATA ="GET_EDIT_ARCHIED_DATA";
export const RELOAD = 'RELOAD';


export const GetBalanceSheetData = (param) => {

    return (dispatch) => {
        BalanceSheetService.GetBalanceSheetData(param)
            .then((response) => {
                dispatch({ type: GET_BALANCESHEET_DETAILS, payload: response });
            });
    }
}

export const GetArchivedData = (param) => {
    return (dispatch) => {
        BalanceSheetService.GetArchivedBalanceSheet(param)
            .then((response) => {
                dispatch({ type: GET_ARCHIVED_DETAILS, payload: response });
            });
    }
}


export const ArchievedBalanceSheet = (data) => {
    return (dispatch) => {
        BalanceSheetService.ArchievedBalanceSheet(data)
            .then((response) => {
                dispatch({ type: UPDATE_CUSTOM_FILEDS, payload: response });
            });
    }
}


export const UpdateCustomField = (data) => {
    return (dispatch) => {
        BalanceSheetService.UpdateCustomField(data)
            .then((response) => {
                dispatch({ type: UPDATE_CUSTOM_FILEDS, payload: response });
            });
    }
}


export const GetEditArchivedData = (id) => {    
    return (dispatch) => {
        BalanceSheetService.GetArchivedById(id)
            .then((response) => {
                dispatch({ type: GET_EDIT_ARCHIED_DATA, payload: response });
            });
    }
}


export const UpdateArchivedCustomField = (data) => {
    return (dispatch) => {
        BalanceSheetService.UpdateArchivedCustomFields(data)
            .then((response) => {
                dispatch({ type: UPDATE_CUSTOM_FILEDS, payload: response });
            });
    }
}

export const GetLastQuarterGLReviewedDate = (CompanyID) => {
    return (dispatch) => {
        BalanceSheetService.GetLastQuarterGLReviewedDate(CompanyID)
            .then((response) => {
                dispatch({ type: GET_GLReviewLastQueterDate, payload: response });
            });
    }
}
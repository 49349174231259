import React, { Component } from 'react';



class UnauthorizeComponent extends React.Component {

    render() {
        return (
            <div className="app-wrapper page-error-container animated slideInUpTiny animation-duration-3">
                <div className="page-error-content">
                    <div className="error-code mb-4 animated zoomInDown">401</div>
                    <h2 className="text-center fw-regular title bounceIn animation-delay-10 animated">
                            UnAuthourize Request
                    </h2>                   
                </div>
            </div>
        )
    }

}

export default UnauthorizeComponent;
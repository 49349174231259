// import React from 'react';
import { toast } from "react-toastify";
import * as ReportDetailService from './../services/ReportDetailService';
export const GET_REPORT_DETAILS = 'GET_REPORT_DETAILS';
export const UPDATE_ACCOUNT_DESCRIPTION = 'UPDATE_ACCOUNT_DESCRIPTION';
// export const UPDATE_ACCOUNT_APPROVED="UPDATE_ACCOUNT_APPROVED";
export const UPDATE_DESCRIPTION = 'UPDATE_DESCRIPTION';
export const UPDATE_CHECKNO = 'UPDATE_CHECKNO';
export const RELOAD = 'RELOAD';
export const POST_ACTIVE_INACIVE = 'POST_ACTIVE_INACIVE';
export const REPORT_REVIEW_DATA ='REPORT_REVIEW_DATA';
export const APPROVEWORKER_DATA="APPROVEWORKER_DATA";
export const TFB_CATGORIES_DATA="TFB_CATGORIES_DATA";
export const TFB_IN_PAYROLL_DATA="TFB_IN_PAYROLL_DATA";
export const ARCR_SUMMARY="ARCR_SUMMARY";

export const GET_REPORT_DATA = (data) => {

    return (dispatch) => {
        ReportDetailService.ReportData(data)
            .then((response) => {
                dispatch({ type: GET_REPORT_DETAILS, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const GGET_ACCFINANCIAL_REPORT = (data) => {    
    return (dispatch) => {
        ReportDetailService.ACCFinancialCheckup(data)
            .then((response) => {
                dispatch({ type: GET_REPORT_DETAILS, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const GET_SPL_REPORT_DATA = (data) => {    
    return (dispatch) => {
        ReportDetailService.ServicePlanLevels(data)
            .then((response) => {
                dispatch({ type: GET_REPORT_DETAILS, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}
export const GET_CTI_REPORT_DATA =(data) => {
    return (dispatch) => {
        ReportDetailService.CompanyTaxInformation(data)
            .then((response) => {
                dispatch({ type: GET_REPORT_DETAILS, payload: response });
            })
    }
}

export const GetExcludedAccRecCompany = (pageno,size,IsIncludeReportData=false) => {    
    return (dispatch) => {
        ReportDetailService.GetExcludedAccRecCompany(pageno,size,IsIncludeReportData)
            .then((response) => {
                dispatch({ type: GET_REPORT_DETAILS, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}




export const GET_EMPPAYROLL_REPORT_DATA =(data) => {
    return (dispatch) => {
        ReportDetailService.EmployeePayrollReport(data)
            .then((response) => {
                dispatch({ type: GET_REPORT_DETAILS, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const GET_RTC_REPORT_DATA =(data) => {
    return (dispatch) => {
        ReportDetailService.GetRTCData(data)
            .then((response) => {
                dispatch({ type: GET_REPORT_DETAILS, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const GET_REPORT_DATA_BES = (data) => {

    return (dispatch) => {
        ReportDetailService.ReportData_BES(data)
            .then((response) => {
                dispatch({ type: GET_REPORT_DETAILS, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const GET_CompanyDashboard_PayrollEmployeeReportDetailList = (data) => {

    return (dispatch) => {
        ReportDetailService.CompanyDashboard_PayrollEmployeeReportDetailList(data)
            .then((response) => {
                dispatch({ type: GET_REPORT_DETAILS, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const GetHAMemo = (data) => {

    return (dispatch) => {
        ReportDetailService.GetHAMemo(data)
            .then((response) => {
                dispatch({ type: GET_REPORT_DETAILS, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const IncludeHAMemo = (data) => {

    return (dispatch) => {
        ReportDetailService.IncludeHAMemo(data)
            .then((response) => {
                dispatch({ type: UPDATE_DESCRIPTION, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const UpdateVendorNamesDiscrepancy = (data) => {

    return (dispatch) => {
        ReportDetailService.UpdateVendorNamesDiscrepancy(data)
            .then((response) => {
                dispatch({ type: UPDATE_DESCRIPTION, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}




export const GET_CompanyDashboard_PayrollEmployeeReportDetailList_BES = (data) => {

    return (dispatch) => {
        ReportDetailService.CompanyDashboard_PayrollEmployeeReportDetailList_BES(data)
            .then((response) => {
                dispatch({ type: GET_REPORT_DETAILS, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const GET_CompanyDashboard_PayrollVendorReportDetailList = (data) => {

    return (dispatch) => {
        ReportDetailService.CompanyDashboard_PayrollVendorReportDetailList(data)
            .then((response) => {
                dispatch({ type: GET_REPORT_DETAILS, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const GET_CompanyDashboard_PayrollVendorReportDetailList_BES = (data) => {

    return (dispatch) => {
        ReportDetailService.CompanyDashboard_PayrollVendorReportDetailList_BES(data)
            .then((response) => {
                dispatch({ type: GET_REPORT_DETAILS, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const Post_Vendor_UpdateVendorStatus = (data) => {

    return (dispatch) => {
        ReportDetailService.Vendor_UpdateVendorStatus(data)
            .then((response) => {
                dispatch({ type: POST_ACTIVE_INACIVE, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const Update_CompanyDashboard_UpdateVendorW9 = (data) => {

    return (dispatch) => {
        ReportDetailService.CompanyDashboard_UpdateVendorW9(data)
            .then((response) => {
                dispatch({ type: UPDATE_DESCRIPTION, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const Vendor1099Exported = (data) => {
    return (dispatch) => {
        ReportDetailService.Vendor1099Exported(data)
            .then((response) => {
                dispatch({ type: UPDATE_DESCRIPTION, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}


export const Vendor1099Mailed = (data) => {
    return (dispatch) => {
        ReportDetailService.Vendor1099Mailed(data)
            .then((response) => {
                dispatch({ type: UPDATE_DESCRIPTION, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const Vendor1099Mailed_BES = (data) => {
    return (dispatch) => {
        ReportDetailService.Vendor1099Mailed_BES(data)
            .then((response) => {
                dispatch({ type: UPDATE_DESCRIPTION, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}


export const UpdateAccountantComments = (data) => {
    return (dispatch) => {
        ReportDetailService.UpdateAccountantComments(data)
            .then((response) => {
                dispatch({ type: UPDATE_DESCRIPTION, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}


export const Update_CompanyDashboard_UpdateVendorW9_BES = (data) => {

    return (dispatch) => {
        ReportDetailService.CompanyDashboard_UpdateVendorW9_BES(data)
            .then((response) => {
                dispatch({ type: UPDATE_DESCRIPTION, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const UPDATE_ACCOUNT_DATA = (data) => {

    return (dispatch) => {
        ReportDetailService.UpdateAccountDescription(data)
            .then((response) => {
                dispatch({ type: UPDATE_ACCOUNT_DESCRIPTION, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const UPDATE_DESCRIPTION_DATA_BES = (data) => {
    return (dispatch) => {
        ReportDetailService.UpdateDescription_BES(data)
            .then((response) => {
                dispatch({ type: UPDATE_DESCRIPTION, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}


export const UPDATE_DESCRIPTION_DATA = (data) => {
    return (dispatch) => {
        ReportDetailService.UpdateDescription(data)
            .then((response) => {
                dispatch({ type: UPDATE_DESCRIPTION, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}


export const UPDATE_ACCOUNT_APPROVED_DATA = (data) => {
    return (dispatch) => {
        ReportDetailService.UpdateAccountApprove_BES(data)
            .then((response) => {
                dispatch({ type: UPDATE_DESCRIPTION, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}


export const MARK_RESOLVED_DATA = (data) => {
    return (dispatch) => {
        ReportDetailService.MarkAsResolved(data)
            .then((response) => {
                dispatch({ type: UPDATE_DESCRIPTION, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}
export const UPDATE_ACCOUNT_APPROVE = (data) => {
    return (dispatch) => {
        ReportDetailService.UpdateAccountApprove(data)
            .then((response) => {
                dispatch({ type: UPDATE_DESCRIPTION, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const MARK_RESOLVED_BES_DATA = (data) => {
    return (dispatch) => {
        ReportDetailService.MarkAsResolved_BES(data)
            .then((response) => {
                dispatch({ type: UPDATE_DESCRIPTION, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}


export const MARK_IGNORED_DATA = (data) => {
    return (dispatch) => {
        ReportDetailService.MarkAsIgnored(data)
            .then((response) => {
                dispatch({ type: UPDATE_DESCRIPTION, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const MarkAsIgnoreForVendor = (data) => {
    return (dispatch) => {
        ReportDetailService.MarkAsIgnoreForVendor(data)
            .then((response) => {
                dispatch({ type: UPDATE_DESCRIPTION, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const Revert_Ignored_DATA = (data) => {
    return (dispatch) => {
        ReportDetailService.RevertIgnored(data)
            .then((response) => {
                dispatch({ type: UPDATE_DESCRIPTION, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const MARK_IGNORED_DATA_BES = (data) => {
    return (dispatch) => {
        ReportDetailService.MarkAsIgnored_BES(data)
            .then((response) => {
                dispatch({ type: UPDATE_DESCRIPTION, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}


export const Revert_Ignored_DATA_BES = (data) => {
    return (dispatch) => {
        ReportDetailService.RevertIgnored_BES(data)
            .then((response) => {
                dispatch({ type: UPDATE_DESCRIPTION, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const UPDATE_EMPLOYEE_W4_DATA = (data) => {
    return (dispatch) => {
        ReportDetailService.UpdateEmployeeW4Status(data)
            .then((response) => {
                dispatch({ type: UPDATE_DESCRIPTION, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`);
            })
    }
}

export const UPDATE_EMPLOYEE_W4_DATA_BES = (data) => {
    return (dispatch) => {
        ReportDetailService.UpdateEmployeeW4Status_BES(data)
            .then((response) => {
                dispatch({ type: UPDATE_DESCRIPTION, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`);
            })
    }
}

export const MARK_CPA_APPROVED_DATA = (data) => {
    return (dispatch) => {
        ReportDetailService.MarkAsCPAApproved(data)
            .then((response) => {
                dispatch({ type: UPDATE_DESCRIPTION, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            });
    }
}

export const MarkTFBRecordedInPayrollSystem = (data) => {
    return (dispatch) => {
        ReportDetailService.MarkTFBRecordedInPayrollSystem(data)
            .then((response) => {
                dispatch({ type: UPDATE_DESCRIPTION, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            });
    }
}

export const MarkAccountantApproval = (data) => {
    return (dispatch) => {
        ReportDetailService.MarkAccountantApproval(data)
            .then((response) => {
                dispatch({ type: UPDATE_DESCRIPTION, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            });
    }
}

export const MarkClientApproval = (data) => {
    return (dispatch) => {
        ReportDetailService.MarkClientApproval(data)
            .then((response) => {
                dispatch({ type: UPDATE_DESCRIPTION, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            });
    }
}


export const MarkASReviewedReport = (data) => {
    return (dispatch) => {
        ReportDetailService.MarkASReviewedReport(data)
            .then((response) => {
                dispatch({ type: UPDATE_DESCRIPTION, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            });
    }
}

export const MarkASReviewedReport_BES = (data) => {
    return (dispatch) => {
        ReportDetailService.MarkASReviewedReport_BES(data)
            .then((response) => {
                dispatch({ type: UPDATE_DESCRIPTION, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            });
    }
}

export const GetApproveWorkerClassifiedEmployee = (data) => {
    return (dispatch) => {
        ReportDetailService.ApproveWorkerClassifiedAsAnEmployee(data)
            .then((response) => {                
                dispatch({ type: APPROVEWORKER_DATA, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            });
    }
}

export const ApproveWorkerClassifiedAsAnEmployee_BES = (data) => {
    return (dispatch) => {
        ReportDetailService.ApproveWorkerClassifiedAsAnEmployee_BES(data)
            .then((response) => {                
                dispatch({ type: APPROVEWORKER_DATA, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            });
    }
}


export const GetReportReviewData = (data) => {
    return (dispatch) => {
        ReportDetailService.GetReportReviewData(data)
            .then((response) => {
                dispatch({ type: REPORT_REVIEW_DATA, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            });
    }
}

export const GetReportReviewData_BES = (data) => {
    return (dispatch) => {
        ReportDetailService.GetReportReviewData_BES(data)
            .then((response) => {
                dispatch({ type: REPORT_REVIEW_DATA, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            });
    }
}


export const GetTFBCategories = () => {
    return (dispatch) => {
        ReportDetailService.GetTFBCategories()
            .then((response) => {
                dispatch({ type: TFB_CATGORIES_DATA, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            });
    }
}

export const AssignTFBToEmp = (data) => {
    return (dispatch) => {
        ReportDetailService.AssignTFBToEmployee(data)
            .then((response) => {
                dispatch({ type: UPDATE_DESCRIPTION, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            });
    }
}

export const AssignTFBToEmp_BES = (data) => {
    return (dispatch) => {
        ReportDetailService.AssignTFBToEmployee_BES(data)
            .then((response) => {
                dispatch({ type: UPDATE_DESCRIPTION, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            });
    }
}

export const AssignBatchTFBToCategory = (data) => {
    return (dispatch) => {
        ReportDetailService.AssignBatchTFBToCategory(data)
            .then((response) => {
                dispatch({ type: UPDATE_DESCRIPTION, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            });
    }
}

export const AssignBatchTFBToCategory_BES = (data) => {
    return (dispatch) => {
        ReportDetailService.AssignBatchTFBToCategory_BES(data)
            .then((response) => {
                dispatch({ type: UPDATE_DESCRIPTION, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            });
    }
}


export const AssignTFBToCategoryWithEmployee = (data) => {
    return (dispatch) => {
        ReportDetailService.AssignTFBToCategoryWithEmployee(data)
            .then((response) => {
                dispatch({ type: UPDATE_DESCRIPTION, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            });
    }
}


export const AssignTFBToCategory = (data) => {
    return (dispatch) => {
        ReportDetailService.AssignTFBToCategory(data)
            .then((response) => {
                dispatch({ type: UPDATE_DESCRIPTION, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            });
    }
}

export const GetRecordTFBInPayroll = (data) => {
    return (dispatch) => {
        ReportDetailService.GetRecordTFBInPayroll(data)
            .then((response) => {
                dispatch({ type: TFB_IN_PAYROLL_DATA, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            });
    }
}
 
export const AccountReconciliationReviewSummery = (companyId) => {

    return (dispatch) => {
        ReportDetailService.AccountReconciliationReviewSummery(companyId)
            .then((response) => {
                dispatch({ type: ARCR_SUMMARY, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            });
    }
}

export const MarkAsIgnoreResolveExcessHousingAllowanceTxn = (data) => {
    return (dispatch) => {
        ReportDetailService.MarkAsIgnoreResolveExcessHousingAllowanceTxn(data)
            .then((response) => {
                dispatch({ type: UPDATE_DESCRIPTION, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const RevertMarkAsUnIgnoreResolveExcessHousingAllowanceTxn = (data) => {
    return (dispatch) => {
        ReportDetailService.RevertMarkAsUnIgnoreResolveExcessHousingAllowanceTxn(data)
            .then((response) => {
                dispatch({ type: UPDATE_DESCRIPTION, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const UpdateVendorDoNotIssue1099 = (data) => {
    return (dispatch) => {
        ReportDetailService.UpdateVendorDoNotIssue1099(data)
            .then((response) => {
                dispatch({ type: UPDATE_DESCRIPTION, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            });
    }
}

export const UpdateVendorDoNotIssue1099_BES = (data) => {
    return (dispatch) => {
        ReportDetailService.UpdateVendorDoNotIssue1099_BES(data)
            .then((response) => {
                dispatch({ type: UPDATE_DESCRIPTION, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            });
    }
}


export const UpdateTransactionCheckNumber = (data) => {
    return (dispatch) => {
        ReportDetailService.UpdateTransactionCheckNumber(data)
            .then((response) => {
                dispatch({ type: UPDATE_CHECKNO, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            });
    }
}


export const UpdateTransactionCheckNumber_BES = (data) => {
    return (dispatch) => {
        ReportDetailService.UpdateTransactionCheckNumber_BES(data)
            .then((response) => {
                dispatch({ type: UPDATE_CHECKNO, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            });
    }
}
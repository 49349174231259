import React, { Component } from "react";
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AddEmployee from '../../../../Forms/AddEmployee';
import { getEmployees, deleteEmployee, SetEmployeeFlag,SetBatchEmployeeFlag } from '../../../../appRedux/actions/employeeAction';
import { getCompnay } from '../../../../appRedux/actions/companiesAction';
import SearchBox from '../../../../components/SearchBox';
import CircularProgress from "../../../../components/CircularProgress";
import _ from 'lodash';
import "react-toastify/dist/ReactToastify.css";
import { Table, Pagination } from 'antd';
import { Resizable } from 'react-resizable';
import DoneIcon from '@material-ui/icons/Done';
import "../../styles.css";
import Switch from '@material-ui/core/Switch';
import { ModuleType } from "../../../common/commonServices";
import HistoryComponent from "../../../common/History";
import HousingAllowanceHistoryComponent from "../../../common/HousingAllowanceHistory";
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

const ResizeableTitle = props => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable width={width} height={0} onResize={onResize}>
      <th {...restProps} />
    </Resizable>
  );
};

class EmployeesPage extends Component {
  constructor(props) {
    super(props);
 

    this.column = [];

    this.state = {
      pageSize: 50,
      currentPage: 1,
      totalRowsInTable: 0,
      fieldName: 'EmployeeId',
      sortFormat: 'A',
      searchText: '',
      search: false,
      columns: this.column,
      isAdd: false,
      edit: false,
      employees: [],
      loading: true,
      isUservisibile: false,
      selectedLease: '',
      companyId: '',
      selectedItemKey: 0,
      vendors: [],
      company: null,
      ShowAddButton: true,
      recordId: 0,
      ShowHistory: false,
      IsPayrollCompany: false,
      SelectALL: false,
      SelectedRecords: [],
      IsActiveFilter: 1,
      ShowHousingAllowanceHistory: false,
    }
  };
    

  SelectALL = (e) => {
    if (this.state.employees.length > 0) {
        if (e.target.checked) {
            let all = [];
            let data = this.state.employees;
            for (let i = 0; i < data.length; i++) {
                all.push(data[i].employeeID);
            }
            this.setState({ SelectALL: true, SelectedRecords: all });
        }
        else {
            this.setState({ SelectALL: false, SelectedRecords: [] });

        }

    }

}

onCheckboxClick = (e, item) => {
  e.stopPropagation();
  this.setState({edit: false,isAdd: false});
  let existInArray = false;
  let data = this.state.SelectedRecords;
  if (data != null && data.length > 0 && data.indexOf(item.employeeID) > -1) {
      existInArray = true;
  }

  if (e.target.checked) {
      if (!existInArray) {
          data.push(item.employeeID);
      }
  }
  else {
      if (existInArray) {
          data.splice(data.indexOf(item.employeeID), 1);
      }
  }

  let all = (data.length == this.state.employees.length) ? true : false;
  this.setState({ SelectedRecords: data, SelectALL: all,});

}

  employeeStatusChange = (event, record, index) => {
    event.stopPropagation();
    let isChecked = event.target.checked;
    this.setState({edit: false,isAdd: false});
    if(this.state.IsPayrollCompany){  
      Swal.fire({
        //title: 'Do you want to continue?',
        showCancelButton: true,
        // imageUrl: './assets/images/user-confirm.png',
        // imageHeight: 230,
        icon:"info",
        confirmButtonText: 'Continue',
        // customClass : {
        //   'container' : 'detail-alert'
        // },
        html:'This status change will have no impact on the QuickBooks Online Payroll system. You must make employees inactive within QB Online.',
        allowOutsideClick: false
      }).then((result) => {
        if (result.isConfirmed) {
         
          var employeeData = [...this.state.employees];
          employeeData[index].isActive = isChecked;
          let data = {
            "employeeID": employeeData[index].employeeID,
            "isActive": isChecked
          };
      
          this.setState({
            employees: employeeData, loading: true, edit: false,
            isAdd: false
          }, () => {
            this.props.SetEmployeeFlag(data);
          });
        }
      });
    }else{
      var employeeData = [...this.state.employees];
      employeeData[index].isActive = isChecked;
      let data = {
        "employeeID": employeeData[index].employeeID,
        "isActive": isChecked
      };
  
      this.setState({
        employees: employeeData, loading: true, edit: false,
        isAdd: false
      }, () => {
        this.props.SetEmployeeFlag(data);
      });
    }
  
  }

  componentDidMount() {
    let user = JSON.parse(localStorage.getItem('userData'));
    this.props.getCompnay(user.companyId);
    this.listData();
  }

  listData() {
    let user = JSON.parse(localStorage.getItem('userData'));
    const CompanyId = user.companyId;
    this.setState({ companyId: user.companyId })
    this.props.getEmployees(CompanyId, this.state.searchText, this.state.currentPage, this.state.pageSize, this.state.fieldName, this.state.sortFormat,this.state.IsActiveFilter);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.employees!==undefined && nextProps.employees !== this.props.employees) {
      if (nextProps.employees.length > 0)
        this.state.totalRowsInTable = nextProps.employees[0].totalrows;

      this.setState({
        employees: nextProps.employees, loading: false,SelectALL: false, SelectedRecords: []
      });
    }

    if (nextProps.reload == true) { 
      this.setState({ loading: false ,SelectALL: false, SelectedRecords: [] }, () => {
        this.listData();
      });
    }

    if (nextProps.company!==undefined && nextProps.company !== this.props.company) {
      let showAdd = true;
      var columns = this.column;
      let isPayrollCompany = false;
      if (nextProps.company.payrollSoftware === "QuickBooks Payroll" || nextProps.company.payrollSoftware === "QuickBooks Full Service") {
        showAdd = false;
        isPayrollCompany = true;
        //columns = this.column.filter(x => x.title != "Status");
      }
      this.setState({ company: nextProps.company, ShowAddButton: showAdd, columns: columns,IsPayrollCompany: isPayrollCompany}, () => {
        this.setState({ loading: false ,SelectALL: false, SelectedRecords: []});
      });
    }
  }

  handleChange = (text) => {
    var self = this;
    if (text) {

      self.state.searchText = text;


    } else {
      self.state.searchText = '';

    }
    self.state.currentPage = 1;
    this.setState({ search: true });

    clearTimeout(self.state.timeout);

    // Make a new timeout set to go off in 800ms
    self.state.timeout = setTimeout(function () {
      self.listData();

    }, 500);

  }

  handleDelete = (id) => {
    this.props.deleteEmployee(id);
    this.setState({
      edit: false,
    });
  }

  handleAddModal = () => {

    this.setState({
      selectedItem: false,
      isAdd: true,
      edit: false,
    });
  }

  handleEditModal = (record) => {

    //var id = record.employeeID

    //var data = this.state.employees;
    if (record != null) {
      //const selectedEmployee = _.find(data, { employeeID: id });
      this.setState({
        selectedEmployee: _.cloneDeep(record),
        edit: true,
        isAdd: false
      });
    }
  }

  handleAddCancel = () => {
    this.setState({ isAdd: false, edit: false });
  }

  LoadHistory = (e, id) => {
    e.stopPropagation();
    this.setState({ recordId: id, ShowHistory: true })
  }

  CloseHistory = () => {
    this.setState({ ShowHistory: false });
  }

  // handleTableChange = (pagination, filters, sorter) => {    

  //   if (sorter.order) {
  //     this.state.fieldName = sorter.field;
  //     if (sorter.order == "ascend")
  //       this.state.sortFormat = "A";
  //     else
  //       this.state.sortFormat = "D";
  //   }
  //   else {
  //     this.state.fieldName = "EmployeeId";
  //     this.state.sortFormat = "A";
  //   }

  //   if(sorter.columnKey=="stCreateDate")
  //   {
  //     this.state.fieldName ="createDate"
  //   }
  //   this.listData();
  // }

  onSortChange = (fieldName) => {
    if (this.state.sortField == fieldName) {
        let formate = this.state.sortFormat == 'A' ? 'D' : 'A';
        this.setState({ sortFormat: formate, loading: true }, () => {
            this.listData();
        });
    } else {
        this.setState({ sortField: fieldName, sortFormat: 'A', loading: true }, () => {
            this.listData();
        });
    }
}

  onShowSizeChange(current, pageSize) {
    this.state.currentPage = current;
    this.state.pageSize = pageSize;
    this.listData();
  }
  handleResize = index => (e, { size }) => {
    this.setState(({ columns }) => {
      const nextColumns = [...columns];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      };
      return { columns: nextColumns };
    });
  };

  MakeBatchInactive = () => {

    this.setState({edit: false,isAdd: false});
    let selectedData = this.state.SelectedRecords;
    if (selectedData == null || selectedData.length === 0) {
        toast.error("Please select atleast 1 record for processing request");
        return false;
    }

    if(this.state.IsPayrollCompany){
      Swal.fire({
        //title: 'Do you want to continue?',
        showCancelButton: true,
        // imageUrl: './assets/images/user-confirm.png',
        // imageHeight: 230,
        icon:"info",
        confirmButtonText: 'Continue',
        // customClass : {
        //   'container' : 'detail-alert'
        // },
        html:'This status change will have no impact on the QuickBooks Online Payroll system. You must make employees inactive within QB Online.',
        allowOutsideClick: false
      }).then((result) => {
        if (result.isConfirmed) {
         
            let body = {
            "employeeIDs":selectedData,
            "companyID": this.state.companyId,
            "isActive":false,
            "isPayrollCompany":this.state.IsPayrollCompany
            }
    
            this.setState({ loading: true }, () => {
             this.props.SetBatchEmployeeFlag(body);  
            });
        }
      });
    }else{
      let body = {
        "employeeIDs":selectedData,
        "companyID": this.state.companyId,
        "isActive":false
        }

        this.setState({ loading: true }, () => {
         this.props.SetBatchEmployeeFlag(body);  
        });
    }
  }

  HandleActiveInActive = (event) => {
    this.setState({ IsActiveFilter: (event.target.value),loading:true },()=>{ this.listData()});
  }

  LoadHousingAllowanceHistory = (e, id) => {
    
    e.stopPropagation();
    this.setState({ recordId: id, ShowHousingAllowanceHistory: true });
  }

  CloseHousingAllowanceHistory = () => {
    this.setState({ recordId: 0,ShowHousingAllowanceHistory: false });
  }



  render() {
    const { searchText, selectedEmployee } = this.state;
    let { employees } = this.state;
    const columns = this.state.columns.map((col, index) => ({
      ...col,
      onHeaderCell: column => ({
        width: column.width,
        onResize: this.handleResize(index),
      }),
    }));
    const components = {
      header: {
        cell: ResizeableTitle,
      },
    };
    let filteredData = employees.length && employees.map((d, index) => ({
      key: d.employeeID,
      ...d
    }))
    let selectedItems = this.state.SelectedRecords;
    return (
      
      <div style={{ padding: 15 }}>
        {this.state.ShowHistory &&
          <HistoryComponent
            PreText={null}
            ModuleType={ModuleType.Employees}
            RecordId={this.state.recordId}
            CompanyId={this.state.companyId}
            CloseHistory={this.CloseHistory}
          >
          </HistoryComponent>}

          {this.state.ShowHousingAllowanceHistory &&
          <HousingAllowanceHistoryComponent
            RecordId={this.state.recordId}
            CompanyId={this.state.companyId}
            CloseHousingAllowanceHistory={this.CloseHousingAllowanceHistory}
            >
          </HousingAllowanceHistoryComponent>
          }

          
        <AddEmployee
          companyID={this.state.companyId}
          handleSave={this.handleAddCancel}
          visible={this.state.isAdd || this.state.edit}
          isEdit={this.state.edit}
          handleCancel={this.handleAddCancel}
          selectedItem={selectedEmployee}
          ShowAddButton={this.state.ShowAddButton}
          handleDelete={this.handleDelete}
          IsPayrollCompany={this.state.IsPayrollCompany}
        />


        <Grid
          container
          spacing={24}
          style={{ 'marginBottom': 10 }}
        >
          <Grid item xs={12} sm={6} >
            <h2 className="user-title">Employees</h2>
          </Grid>
          <Grid item xs={12} sm={6} >
            <Grid
              container
              spacing={8}
              direction="row"
              justify="flex-end"
              alignItems="flex-end"
            >
              <Grid item  >
                <SearchBox
                  styleName="d-none d-lg-block"
                  placeholder="Search..."
                  value={searchText}
                  onChange={(e) => { this.handleChange(e.target.value) }}
                  style={{ background: '#eeeeeeb8' }}
                />
              </Grid>
              <Grid item  >
               <select style={{ backgroundColor: "#fff" }} className="select-box arrow-box" onChange={this.HandleActiveInActive} value={this.state.IsActiveFilter} >
                                                                                            <option value={1}> Active </option>
                                                                                            <option value={2}> Inactive </option>                                                    
                                                                                        </select>
              </Grid>
              <Grid item  >
                
                
                  
                  {this.state.IsActiveFilter == "1" ?
                  <Button variant="contained" style={{marginRight:"10px"}} color="primary" onClick={this.MakeBatchInactive}>
                    Override Active Status
                  </Button>
                   : 
                  <Button variant="contained" disabled="true" style={{marginRight:"10px"}} color="primary" onClick={this.MakeBatchInactive}>
                   Override Active Status
                  </Button>
                 }

                  {this.state.ShowAddButton ?
                  <Button variant="contained" color="primary" onClick={this.handleAddModal}>
                    Add Employee
                  </Button>
                  : ""}

              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {this.state.loading
          ?
          <CircularProgress />
          :
          (<div className="table-layout">
            <div className="table-responsive" style={{maxHeight:"410px",overflowY:"scroll"}}>
           <table className="table-employeelist table-layout table-bordered">
                                        <thead  >
                                            <tr className="tr-empList" style={{ cursor:"pointer" }}>
                                                <th className={`td-width ${this.state.sortField == 'name' ? this.state.sortFormat == 'A'
                                                    ? "asending" : "desending" : ''} th-name`} onClick={() => this.onSortChange('name')}><p className="table-th-title"> Name</p> </th>
                                                <th className={`td-width ${this.state.sortField == 'employeeType' ? this.state.sortFormat == 'A'
                                                    ? "asending" : "desending" : ''} th-name`} onClick={() => this.onSortChange('employeeType')}><p className="table-th-title">Type</p></th>
                                                <th className={`td-width-amount ${this.state.sortField == 'housingAllowance' ? this.state.sortFormat == 'A'
                                                    ? "asending" : "desending" : ''} th-board-approved`} onClick={() => this.onSortChange('housingAllowance')}><p className="table-th-title"> Board Approved Housing Allowance</p></th>
                                                <th className={`td-width-box ${this.state.sortField == 'employerSECA' ? this.state.sortFormat == 'A'
                                                    ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('employerSECA')}><p className="table-th-title">Employer SECA</p></th>
                                                <th className={` ${this.state.sortField == 'taxableLifeInsurance' ? this.state.sortFormat == 'A'
                                                    ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('taxableLifeInsurance')}><p className="table-th-title"> Life Insurance</p></th>
                                                <th className={`td-width-amount ${this.state.sortField == 'companyVehicle' ? this.state.sortFormat == 'A'
                                                    ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('companyVehicle')}><p className="table-th-title"> Company Vehicle</p></th>
                                                <th className={`td-width-amount ${this.state.sortField == 'usersChurchParsonage' ? this.state.sortFormat == 'A'
                                                    ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('usersChurchParsonage')}><p className="table-th-title">Parsonage</p></th>
                                                <th className={`td-width-amount ${this.state.sortField == 'w4StatusText' ? this.state.sortFormat == 'A'
                                                    ? "asending" : "desending" : ''} date-th`} onClick={() => this.onSortChange('w4StatusText')}><p className="table-th-title"> W-4 Status</p></th>
                                                {/* <th className={`td-width-amount ${this.state.sortField == 'stCreateDate' ? this.state.sortFormat == 'A'
                                                    ? "asending" : "desending" : ''} date-th`} onClick={() => this.onSortChange('stCreateDate')}><p className="table-th-title">Created Date</p></th> */}
                                               {!this.state.IsPayrollCompany ?  
                                                <th className={`td-width-amount ${this.state.sortField == 'isActive' ? this.state.sortFormat == 'A'
                                                    ? "asending" : "desending" : ''} status-th`} onClick={() => this.onSortChange('isActive')}><p className="table-th-title">Status</p></th>
                                                :""}
                                                <th className="td-width-90 text-center">
                                                    <label className="container101">
                                                        {this.state.SelectALL ?
                                                            <input className="second-check-box" checked="checked" type="checkbox" onClick={(e) => this.SelectALL(e)} />
                                                            :
                                                            <input className="second-check-box" type="checkbox" onClick={(e) => this.SelectALL(e)} />
                                                        }
                                                        <span className="checkmark101"></span>
                                                    </label>
                                                </th>
                                                <th className="td-width-90 text-center"><img src="/vendors/Forma 1.png" alt="" /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(this.state.employees && this.state.employees.length > 0) && this.state.employees.map((item, j) => (
                                                <React.Fragment key={j}>
                                                    {<tr className="tr-empList" onClick={(e) => this.handleEditModal(item)}>
                                                        <td>{item.name}</td>
                                                        <td>{item.employeeType}</td>
                                                        <td>
                                                          <span>
                                                            $ {parseFloat(item.housingAllowance).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} <i className="zmdi zmdi-eye zmdi-hc-2x" onClick={(e) => this.LoadHousingAllowanceHistory(e, item.employeeID)} style={{float:"right",cursor:"pointer"}}/>
                                                          </span>
                                                        </td>
                                                        <td>
                                                          <span>
                                                            {item.employerSECA != "" ? item.employerSECA + " %" : ""}
                                                          </span>
                                                        </td>
                                                        <td>
                                                          <span>
                                                            {item.taxableLifeInsurance != "" ? item.taxableLifeInsurance + " %" : ""}
                                                          </span>
                                                          {/* {item.taxableLifeInsurance} */}
                                                        </td>
                                                        <td>
                                                          <span>
                                                            {item.companyVehicle == "1" ? <DoneIcon /> : ""}
                                                          </span>
                                                          {/* {item.companyVehicle} */}
                                                        </td>
                                                        <td>
                                                          <span>
                                                             {item.usersChurchParsonage == "1" ? <DoneIcon /> : ""}
                                                          </span>
                                                          {/* {item.usersChurchParsonage} */}
                                                        </td>
                                                        <td>{item.w4StatusText}</td>
                                                        {/* <td>{item.stCreateDate}</td> */}
                                                        {!this.state.IsPayrollCompany ?  
                                                        <td className="text-center">
                                                        <span>
                                                          {
                                                            <div>
                                                              <Switch size="small" className={item.isActive ? 'switch-box' : 'switch-box switch-inactive'} checked={item.isActive} onChange={(event) => this.employeeStatusChange(event, item, j)} /> <span className={item.isActive ? 'text-success' : ''}>{item.isActive ? "Active" : "Inactive"}</span>
                                                            </div>
                                                          }    
                                                        </span>
                                                        </td>
                                                        :""}
                                                        <td className="text-center">
                                                            <label className="container101">
                                                                {(this.state.SelectALL || selectedItems.length > 0 && (selectedItems.indexOf(item.employeeID) > -1)) ?
                                                                    <input className="second-check-box" checked="checked" type="checkbox" onClick={(e) => this.onCheckboxClick(e, item)} />
                                                                    :
                                                                    <input className="second-check-box" type="checkbox" onClick={(e) => this.onCheckboxClick(e, item)} />
                                                                }
                                                                <span className="checkmark101"></span>
                                                            </label>
                                                        </td>
                                                        <td className="text-center">
                                                          <img alt="" src="/vendors/Forma 1.png" onClick={(e) => this.LoadHistory(e, item.employeeID)} />
                                                        </td>
                                                    </tr>}
                                                </React.Fragment>
                                            ))}

                                            {this.state.employees.length == 0 ?
                                                <tr>
                                                    <td className="text-center" colSpan="12">
                                                    No Record Found
                                                    </td>
                                                </tr>
                                                : ""}
                                        </tbody>
                                    </table>
                                    </div>
            {this.state.employees.length > 0 ?
            <Pagination
              pageSizeOptions={['10', '25', '50', '100']}
              pageSize={this.state.pageSize}
              style={{ marginTop: '10px', marginBottom: '10px' }}
              showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
              showSizeChanger onChange={(a, b) => this.onShowSizeChange(a, b)} onShowSizeChange={(a, b) => this.onShowSizeChange(a, b)} defaultCurrent={1} total={this.state.totalRowsInTable} />
              : ""}
          </div>)
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    employees: state.employee.employees,
    company: state.companyReducer.companyDeatils,
    reload: state.employee.reload,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getEmployees,
    deleteEmployee,
    SetEmployeeFlag,
    getCompnay,
    SetBatchEmployeeFlag
  }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(EmployeesPage);

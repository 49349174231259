import * as RetirementPlanService from '../services/RetirementPlanService';
import  jwt_decode from 'jwt-decode';
import React from 'react';
import { toast } from "react-toastify";

export const RETIREMENTPLAN_SUCCESS = "RETIREMENTPLAN_SUCCESS";
export const RETIREMENTPLAN_ERROR = "RETIREMENTPLAN_ERROR";

export const ADDRETIREMENTPLAN_SUCCESS = "ADDRETIREMENTPLAN_SUCCESS";
export const ADDRETIREMENTPLAN_ERROR = "ADDRETIREMENTPLAN_ERROR";

export const UPDATERETIREMENTPLAN_SUCCESS = "UPDATERETIREMENTPLAN_SUCCESS";
export const UPDATERETIREMENTPLAN_ERROR = "UPDATERETIREMENTPLAN_ERROR";

export const DELETERETIREMENTPLAN_SUCCESS = "DELETERETIREMENTPLAN_SUCCESS";
export const DELETERETIREMENTPLAN_ERROR = "DELETERETIREMENTPLAN_ERROR";

export const RETIREMENTPLAN_TYPE_SUCCESS = "RETIREMENTPLAN_TYPE_SUCCESS";
export const RETIREMENTPLAN_TYPE_ERROR = "RETIREMENTPLAN_TYPE_ERROR";


function retirementPlanSuccess (payload){
    return{
        type: RETIREMENTPLAN_SUCCESS,
        payload
    }
}






export function getRetirementPlans(companyId,search,pageNumber,pageSize,sortField,sortFormat){
    return (dispatch) => {
        // dispatch()
        RetirementPlanService.getRetirementPlans(companyId,search,pageNumber,pageSize,sortField,sortFormat)
        .then((res) => {
            return dispatch(retirementPlanSuccess(res))
        })
        .catch(err => {
             toast.error(`${err.response.data}`)
        })
    }
}

export function addRetirementPlan(data){
    return (dispatch) => {
        RetirementPlanService.addRetirementPlan(data)
        .then(res => {
            dispatch({type:'RELOAD',payload:data});
            toast.success("Retirement Plan Added Successfully.");
        })
        .catch(err => {
             toast.error(`${err.response.data}`)
        })
    }
}

export function updateRetirementPlan(data){
    return (dispatch) => {
        RetirementPlanService.updateRetirementPlan(data)
        .then(res => {
            dispatch({type:'RELOAD',payload:data});
            toast.success("Retirement Plan Updated Successfully.");
        })
        .catch(err => {
             toast.error(`${err.response.data}`)
        })
    }
}

export function deleteRetirementPlan(value){
    return (dispatch) => {
        RetirementPlanService.deleteRetirementPlan(value)
        .then(res => {
            return dispatch({type:'RELOAD',payload:res});
            toast.success("Retirement Plan Deleted Successfully.");
        })
        .catch(err => {
             toast.error(`${err.response.data}`)
        })
    }
}


import React from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../components/Header/index';
import Sidebar from '../containers/SideNav/index';
import Footer from '../components/Footer';
import {
    ABOVE_THE_HEADER,
    BELOW_THE_HEADER,
    COLLAPSED_DRAWER,
    FIXED_DRAWER,
    HORIZONTAL_NAVIGATION,
} from '../constants/ActionTypes';
import { isIOS, isMobile } from 'react-device-detect';
// import asyncComponent from '../util/asyncComponent';
import TopNav from '../components/TopNav';
import jwt_decode from 'jwt-decode';
import UserPage from './routes/Admin/UsersPage'
import Dashboard from './routes/DashboardPage';
import AdminDashboard from '../components/dashboard/Intranet';
import Vendor from './routes/Admin/VendorPage';
import VendorType from './routes/Admin/VendorTypePage';
import Wages from './routes/Admin/WagesListPage/WagesList';
import ACCFinancialCheckup from "./routes/Accountant/FinancialReports/ACCFinancialCheckup";
import ExcludeAccountReconciliation from "./routes/Accountant/FinancialReports/AccountReconciliationCompanies";
import EmbeddedReports from "./routes/Accountant/EmbeddedReports/EmbeddedReports";
import Insurance from './routes/Admin/InsurancePage';
import Leases from './routes/Admin/LeasesPage';
import RetirementPlans from './routes/Admin/RetirementPlanPage';
import Loans from './routes/Admin/LoansPage';
import Logs from './routes/Admin/LogsPage';
import Employees from './routes/Admin/EmployeesPage';
import AccouintChart from './routes/Admin/AccountChartPage';
import Company from './routes/Admin/CompanyPage';
import CompanyDetails from './routes/Admin/CompanyDetailPage';
// import Ledger from './routes/Accountant/LedgerPage';
import TaxableReview from './routes/Accountant/TaxableReviewPage';
import TaxableWagesComputations from './routes/Accountant/TaxableWagesComputations';
import ReviewReoprt from './routes/Accountant/ReviewReportPage';
// import TrialBalance from './routes/Accountant/TrialBalancePage';
import DistributionPage from './routes/Accountant/DistributionPage';
import FinancialReviewPage from './routes/Reports/FinancialReviewPage';
import ReviewReportPage from './routes/Reports/ReviewReportPage';
import TaxableReviewPage from './routes/Reports/TaxableReviewPage';
import QuickBooksPage from './routes/Admin/QuickBookPage';
import AccountantPage from './routes/Accountant/AccountantPage';
import Error from '../components/Error404';
import NewLedgerPage from './routes/Accountant/NewLedgerPage';
import AccountReconcilliationPage from './routes/Reports/AccountReconcilliationPage/Index';
import ReviewTransactionComment from './routes/Accountant/ReviewTransactionComment/ReviewTransactionComment';
import ChangeACNumComponent from './routes/DashboardPage/LinkReports/changeACNum';
import TranNBDComponent from './routes/DashboardPage/LinkReports/tranNBD';
import ReviewNBDComponenet from './routes/DashboardPage/LinkReports/reviewNBD';
import TranMissDescriptionComponent from './routes/DashboardPage/LinkReports/tranMissDescription';
import TranMissVendorComponent from './routes/DashboardPage/LinkReports/tranMissVendor';
import UnCategorizeTransaction from "./routes/DashboardPage/LinkReports/UnCategorizeTransaction";
import ReviewReconciliationDiscrepancies from "./routes/DashboardPage/LinkReports/ReviewReconciliationDiscrepancies"
import OOTranComponent from './routes/DashboardPage/LinkReports/ooTran';
import OtherQueComm from './routes/DashboardPage/LinkReports/otherQueComm';
import ACCLineItemComponent from "./routes/DashboardPage/LinkReports/ACCLineItem";
import GeneralQueustionTagCPA from "./routes/DashboardPage/LinkReports/generalQueTagCPA";
import TransTagCPA from "./routes/DashboardPage/LinkReports/transTagCPA";
import RecordCheckPC from "./routes/DashboardPage/LinkReports/recordCheckPC";
import VendorMissW9Component from "./routes/DashboardPage/LinkReports/VendorsMissW9";
import EmployeePayment from "./routes/DashboardPage/LinkReports/EmployeePayment";
import IgnoreEmployeePayment from "./routes/DashboardPage/LinkReports/IgnoreEmployeePayment";
import HousingAllowanceIssues from "./routes/DashboardPage/LinkReports/HousingAllowanceIssues";
import EmpVehicleForm from "./routes/DashboardPage/LinkReports/EmpVehicleForm";
import RecEmpVehicleUse from "./routes/DashboardPage/LinkReports/RecEmpVehicleUse";
import AddEmpList from "./routes/DashboardPage/LinkReports/AddEmpList";
import RecEmpPayPayroll from "./routes/DashboardPage/LinkReports/RecEmpPayPayroll";
import MinisterList from "./routes/DashboardPage/LinkReports/MinisterList";
import ApproveWorker from "./routes/DashboardPage/LinkReports/ApproveWorker";
import EmployeeMissW4Component from "./routes/DashboardPage/LinkReports/EmployeeMissW4";
import BalanceSheetAccountReviewComponent from './routes/Accountant/BalanceSheetAccountReviewPage/';
import ArchivedBalanceSheetsComponent from "./routes/Accountant/BalanceSheetAccountReviewPage/ArchivedList";
import AssignTFBtoEMP from "./routes/DashboardPage/LinkReports/AssignTFBtoEMP";
import AssignTFBtoCategory from "./routes/DashboardPage/LinkReports/AssignTFBtoCategory";
import RecTFBInPayroll from "./routes/DashboardPage/LinkReports/RecTFBInPayroll";
import MissRefNo from "./routes/DashboardPage/LinkReports/MissRefNo";
import IssueHAMemo from './routes/DashboardPage/LinkReports/IssueHAMemo';
import ACCAproval1099 from "./routes/DashboardPage/LinkReports/ACCAproval1099";
import Export1099NEC from "./routes/DashboardPage/LinkReports/Export1099NEC";
import Export1099MISC from "./routes/DashboardPage/LinkReports/Export1099MISC";
import Confirm1099Mailed from "./routes/DashboardPage/LinkReports/Confirm1099Mailed";
import Report1099Status from "./routes/DashboardPage/LinkReports/Report1099Status";
import VendorDiscrepancies from "./routes/DashboardPage/LinkReports/VendorDiscrepancies";
import CLAccAproval1099 from "./routes/DashboardPage/LinkReports/CLAccAproval1099";
import accountPayrollTaxComputations from './routes/Accountant/accountPayrollTaxComputations/index';
import ServicePlanLevels from './routes/Accountant/FinancialReports/ServicePlanLevels';
import CompanyTaxInformation from './routes/Accountant/FinancialReports/CompanyTaxInformation';
let role;
const isUserAuthenticated = () => {
    let user = JSON.parse(localStorage.getItem('userData'));

    let decode = jwt_decode(user.Authorization);
    role = decode['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];

    if (role) {
        return true
    } else {
        return false;
    }
}

class App extends React.Component {

    render() {
        const { match, drawerType, navigationStyle, horizontalNavPosition } = this.props;
        const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';

        //set default height and overflow for iOS mobile Safari 10+ support.
        if (isIOS && isMobile) {
            document.body.classList.add('ios-mobile-view-height')
        }
        else if (document.body.classList.contains('ios-mobile-view-height')) {
            document.body.classList.remove('ios-mobile-view-height')
        }

        const PrivateRoute = ({ component: Component, allowed, ...rest }) => {

            return (<Route {...rest} render={(routeProps) => (
                isUserAuthenticated()
                    ? (allowed.includes(role) ? <Component {...routeProps} /> : <Redirect to='/' />)
                    : <Route component={Error} />
            )} />
            );
        }

        return (
            <div className={`app-container ${drawerStyle}`}>
                <Sidebar />
                <div className="app-main-container">
                    <div
                        className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION ? 'app-header-horizontal' : ''}`}>
                        {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === ABOVE_THE_HEADER) &&
                            <TopNav styleName="app-top-header" />}
                        <Header />
                        {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) &&
                            <TopNav />}
                    </div>
                    <main className="app-main-content-wrapper maindash-wrapper">
                        <div className="app-main-content">
                            <Switch>
                                <Route exact path={`${match.url}dashboard`} component={Dashboard} />
                                <Route path={`${match.url}changeAcNum`} component={ChangeACNumComponent} />
                                <Route path={`${match.url}ooTran`} component={OOTranComponent} />
                                <Route path={`${match.url}tranMissDes`} component={TranMissDescriptionComponent} />
                                <Route path={`${match.url}tranMissVen`} component={TranMissVendorComponent} />
                                <Route path={`${match.url}UCT`} component={UnCategorizeTransaction} />
                                <Route path={`${match.url}RRD`} component={ReviewReconciliationDiscrepancies} />
                                <Route path={`${match.url}tranNBD`} component={TranNBDComponent} />
                                <Route path={`${match.url}RNBD`} component={ReviewNBDComponenet} />
                                <Route path={`${match.url}accline`} component={ACCLineItemComponent} />
                                <Route path={`${match.url}vendorMissW9`} component={VendorMissW9Component} />
                                <Route path={`${match.url}EmployeePayment`} component={EmployeePayment} />                                
                                <Route path={`${match.url}IgnoreEmpPayment`} component={IgnoreEmployeePayment} />
                                <Route path={`${match.url}HousingAllowance`} component={HousingAllowanceIssues} />
                                <Route path={`${match.url}EmpVehicleForm`} component={EmpVehicleForm} />                                
                                <Route path={`${match.url}employeeMissW4`} component={EmployeeMissW4Component} />
                                <Route path={`${match.url}otherquecomm`} component={OtherQueComm} />
                                <Route path={`${match.url}generalquetagCPA`} component={GeneralQueustionTagCPA} />
                                <Route path={`${match.url}trantagCPA`} component={TransTagCPA} />
                                <Route path={`${match.url}recordCheckPC`} component={RecordCheckPC} />
                                <Route path={`${match.url}vehicleuse`} component={RecEmpVehicleUse} />
                                <Route path={`${match.url}addemplist`} component={AddEmpList} />
                                <Route path={`${match.url}recemppay`} component={RecEmpPayPayroll} />
                                <Route path={`${match.url}ministerlist`} component={MinisterList} />
                                <Route path={`${match.url}approveworker`} component={ApproveWorker} />
                                <Route path={`${match.url}AssignTFBEMP`} component={AssignTFBtoEMP} />
                                <Route path={`${match.url}AssignTFBCAT`} component={AssignTFBtoCategory} />
                                <Route path={`${match.url}TFBPayroll`} component={RecTFBInPayroll} />
                                <Route path={`${match.url}MissRefNo`} component={MissRefNo} />
                                <Route path={`${match.url}HAMEMO`} component={IssueHAMemo} />
                                
                                
                                <Route path={`${match.url}VendorDiscrepancies`} component={VendorDiscrepancies} />
                                
                                <PrivateRoute allowed={['SuperAdmin', 'Admin', 'Accountant']} exact path={`${match.url}home`} component={AdminDashboard} />
                                <PrivateRoute allowed={['Admin', 'SuperAdmin']} path={`${match.url}vendor`} component={Vendor} />
                                <PrivateRoute allowed={['Admin', 'SuperAdmin', 'Accountant']} path={`${match.url}vendorType`} component={VendorType} />
                                <PrivateRoute allowed={['Admin', 'SuperAdmin']} path={`${match.url}Wages`} component={Wages} />
                                <PrivateRoute allowed={['Admin', 'SuperAdmin']} path={`${match.url}ACCFCK`} component={ACCFinancialCheckup} />
                                <PrivateRoute allowed={['Admin', 'SuperAdmin']} path={`${match.url}EARC`} component={ExcludeAccountReconciliation} />
                                
                                <PrivateRoute allowed={['Admin', 'SuperAdmin']} path={`${match.url}BSA`} component={EmbeddedReports} />
                                <PrivateRoute allowed={['Admin', 'SuperAdmin']} path={`${match.url}AP`} component={EmbeddedReports} />
                                <PrivateRoute allowed={['Admin', 'SuperAdmin']} path={`${match.url}TL`} component={EmbeddedReports} />
                                <PrivateRoute allowed={['Admin', 'SuperAdmin']} path={`${match.url}LTL`} component={EmbeddedReports} />
                                <PrivateRoute allowed={['Admin', 'SuperAdmin']} path={`${match.url}OBSA`} component={EmbeddedReports} />
                                <PrivateRoute allowed={['Admin', 'SuperAdmin']} path={`${match.url}RBSN`} component={EmbeddedReports} />
                                <PrivateRoute allowed={['Admin', 'SuperAdmin']} path={`${match.url}SPL`} component={ServicePlanLevels} />
                                <PrivateRoute allowed={['Admin', 'SuperAdmin']} path={`${match.url}CTI`} component={CompanyTaxInformation} />

                                <PrivateRoute allowed={['Admin', 'SuperAdmin']} path={`${match.url}insurancePolicies`} component={Insurance} />
                                <PrivateRoute allowed={['Admin', 'SuperAdmin']} path={`${match.url}leases`} component={Leases} />
                                <PrivateRoute allowed={['Admin', 'SuperAdmin']} path={`${match.url}loans`} component={Loans} />
                                {/* <PrivateRoute allowed={['Admin', 'SuperAdmin']} path={`${match.url}logs`} component={Logs} /> */}
                                <PrivateRoute allowed={['Admin', 'SuperAdmin']} path={`${match.url}employees`} component={Employees} />
                                <PrivateRoute allowed={['Admin', 'SuperAdmin']} path={`${match.url}chartAccounts`} component={AccouintChart} />
                                <PrivateRoute allowed={['SuperAdmin', 'Admin', 'Accountant']} path={`${match.url}companies`} component={Company} />
                                <PrivateRoute allowed={['SuperAdmin', 'Admin']} path={`${match.url}companyDetail`} component={CompanyDetails} />
                                <PrivateRoute allowed={['SuperAdmin', 'Admin']} path={`${match.url}accountant`} component={AccountantPage} />
                                <PrivateRoute allowed={['Admin', 'SuperAdmin']} path={`${match.url}users/:id`} component={UserPage} />
                                <PrivateRoute allowed={['Admin', 'SuperAdmin', 'Accountant']} path={`${match.url}ledgers`} component={NewLedgerPage} />
                                <PrivateRoute allowed={['Admin', 'SuperAdmin', 'Accountant']} path={`${match.url}accoutantTaxableReview`} component={TaxableReview} />
                                <PrivateRoute allowed={['Admin', 'SuperAdmin', 'Accountant']} path={`${match.url}taxableWagesComputations`} component={TaxableWagesComputations} />
                                <PrivateRoute allowed={['Admin', 'SuperAdmin', 'Accountant']} path={`${match.url}accoutantReview`} component={ReviewReoprt} />
                                <PrivateRoute allowed={['Admin', 'SuperAdmin', 'Accountant']} path={`${match.url}trialBalanceReview`} component={BalanceSheetAccountReviewComponent} />
                                <PrivateRoute allowed={['Admin', 'SuperAdmin', 'Accountant']} path={`${match.url}distributionReview`} component={DistributionPage} />
                                <PrivateRoute allowed={['Admin', 'SuperAdmin', 'Accountant']} path={`${match.url}ACCAPP1099`} component={ACCAproval1099} />
                                <PrivateRoute allowed={['Admin', 'SuperAdmin', 'Accountant']} path={`${match.url}ACCCLA`} component={CLAccAproval1099} />
                                <PrivateRoute allowed={['Admin', 'SuperAdmin', 'Accountant']} path={`${match.url}NEC1099`} component={Export1099NEC} />
                                <PrivateRoute allowed={['Admin', 'SuperAdmin', 'Accountant']} path={`${match.url}MISC1099`} component={Export1099MISC} />
                                <PrivateRoute allowed={['Admin', 'SuperAdmin', 'Accountant']} path={`${match.url}Confirm1099`} component={Confirm1099Mailed} />
                                <PrivateRoute allowed={['Admin', 'SuperAdmin', 'Accountant']} path={`${match.url}Status1099`} component={Report1099Status} />                                
                                <PrivateRoute allowed={['Admin', 'SuperAdmin']} path={`${match.url}QuickBooks`} component={QuickBooksPage} />
                                <PrivateRoute allowed={['Admin', 'SuperAdmin', 'Accountant']} path={`${match.url}archivedBL`} component={ArchivedBalanceSheetsComponent} />
                                <PrivateRoute allowed={['User', 'SuperAdmin', 'Admin', 'Accountant']} path={`${match.url}financialReview`} component={FinancialReviewPage} />
                                <PrivateRoute allowed={['User', 'SuperAdmin', 'Admin', 'Accountant']} path={`${match.url}reportReview`} component={ReviewReportPage} />
                                <PrivateRoute allowed={['User', 'SuperAdmin', 'Admin', 'Accountant']} path={`${match.url}taxableReview`} component={TaxableReviewPage} />
                                <PrivateRoute allowed={['User', 'SuperAdmin', 'Admin', 'Accountant']} path={`${match.url}RTC`} component={ReviewTransactionComment} />
                                <PrivateRoute allowed={['User', 'SuperAdmin', 'Admin']} path={`${match.url}retirementPlan`} component={RetirementPlans} />
                                <PrivateRoute allowed={['User', 'SuperAdmin', 'Admin', 'Accountant']} path={`${match.url}accountreconcilliation`} component={AccountReconcilliationPage} />
                                <PrivateRoute allowed={['User', 'SuperAdmin', 'Admin', 'Accountant']} path={`${match.url}ACPYTC`} component={accountPayrollTaxComputations} />
                                <Route component={Error} />
                            </Switch>
                        </div>
                        <div className='maindash-footer'>
                        <Footer />
                        </div>
                    </main>
                </div>
            </div>
        );
    }
}


const mapStateToProps = ({ settings }) => {
    const { drawerType, navigationStyle, horizontalNavPosition } = settings;
    return { drawerType, navigationStyle, horizontalNavPosition }
};
export default withRouter(connect(mapStateToProps)(App));

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
//import CommonComments from './../../../common/Comments';
import PaginationComponent from './../../../common/Pagination';
import { GET_CompanyDashboard_PayrollEmployeeReportDetailList, UPDATE_EMPLOYEE_W4_DATA } from '../../../../appRedux/actions/ReportDetailAction';
import { getCompnay } from '../../../../appRedux/actions/companiesAction';
import { ReportType, EmployeeStatus,LinkType } from '../../../common/commonServices'
import moment from 'moment';
import { bindActionCreators } from 'redux';
import CircularProgress from './../../../../components/CircularProgress';
import { toast } from 'react-toastify';

class EmployeeMissW4Component extends Component {
    constructor(props) {
        super(props);

        let endDate = new Date();
        let startDate = new Date(endDate.getFullYear(), 0, 1);

        if (endDate.getMonth() <= 2) {
            startDate = new Date(startDate.getFullYear() - 1, 0, 1);
            endDate = new Date(endDate.getFullYear() - 1, 11, 31);
        }
        this.state = {
            parantData: this.props.location.state ? this.props.location.state : null,
            MissingData: [],
            companyId: 0,
            sortField: 'name',
            sortFormat: "A",
            PageNo: 1,
            PageSize: 10,
            ColorCode: "#f0ca4c",
            IsCommentModelOpen: false,
            sDate: startDate,// new Date(2019, 0, 1),
            eDate: endDate,//new Date(),
            loading: false,
            TotalRecords: 0,
            CommentReferenceId: 0,
            IsEmbeddedPage: false,
            SelectedRecords: [],
            SelectALL: false,
            ShowComplete: true,
        }
    }

    onSortChange = (fieldName) => {
        if (this.state.sortField == fieldName) {
            let formate = this.state.sortFormat == 'A' ? 'D' : 'A';
            this.setState({ sortFormat: formate, loading: true }, () => {
                this.GetReportData();
            });
        } else {
            this.setState({ sortField: fieldName, sortFormat: 'A', loading: true }, () => {
                this.GetReportData();
            });
        }
    }

    onRefreshDataClick = () => {
        if (!this.state.loading) {
            this.setState({ loading: true }, () => {
                this.GetReportData(true);
            });
        }
    }

    ChangePageSize = (size) => {

        this.setState({ loading: true, PageSize: parseInt(size), PageNo: 1 }, () => {
            this.GetReportData();
        });
    }

    ChangePage = (page) => {
        this.setState({ loading: true, PageNo: page, }, () => {
            this.GetReportData();
        });
    }

    GetReportData(IsRefresh = false) {
        let state = this.state;
        let body = {
            companyID: state.companyId,
            reportType: ReportType.EmployeeMissingW4,
            fromDate: moment(state.sDate).format("MM/DD/YYYY"),
            toDate: moment(state.eDate).format("MM/DD/YYYY"),
            sortField: state.sortField,
            sortFormat: state.sortFormat,
            pageNumber: state.PageNo,
            pageSize: state.PageSize,
            needUpdatQBORecords: IsRefresh
        };

        this.props.GET_CompanyDashboard_PayrollEmployeeReportDetailList(body);
    }


    SelectALL = (e) => {
        if (this.state.MissingData.length > 0) {
            if (e.target.checked) {
                let all = [];
                let data = this.state.MissingData;
                for (let i = 0; i < data.length; i++) {
                    all.push(data[i].employeeID);
                }
                this.setState({ SelectALL: true, SelectedRecords: all });
            }
            else {
                this.setState({ SelectALL: false, SelectedRecords: [] });
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.ReportData !== this.props.ReportData) {
            if (nextProps.ReportData.transactionStatus === 0) {
                var totalrecords = 0;
                if (nextProps.ReportData.returnObject != null && nextProps.ReportData.returnObject.length > 0) {
                    totalrecords = nextProps.ReportData.returnObject[0].totalrows
                }
                this.setState({ MissingData: nextProps.ReportData.returnObject, TotalRecords: totalrecords, PageSize: this.state.PageSize, PageNo: this.state.PageNo, loading: false });
            }
            else {
                toast.error(nextProps.ReportData.resultMsg);
                this.setState({ loading: false });
            }
        }

        if (nextProps.UpdateDescription !== this.props.UpdateDescription) {
            if (nextProps.UpdateDescription.transactionStatus === 0) {
                this.setState({
                    loading: true, editData: null, editIndex: -1, SelectedRecords: [],
                    ShowApprovedInput: false, ApproverName: null, SelectALL: false
                }, () => {
                    toast.success(`${nextProps.UpdateDescription.resultMsg}`);
                    this.GetReportData();
                });
            }
            else {
                this.setState({
                    loading: false, SelectedRecords: [], ShowApprovedInput: false, ApproverName: null, SelectALL: false
                }, () => {
                    toast.error(`${nextProps.UpdateDescription.resultMsg}`);
                });
            }
        }

        if (nextProps.company !== this.props.company) {
            let showAdd = true;
            if (nextProps.company.payrollSoftware === "QuickBooks Payroll" || nextProps.company.payrollSoftware === "QuickBooks Full Service") {
                showAdd = false;
            }
            this.setState({ loading: false, company: nextProps.company, ShowComplete: showAdd });
        }
    }

    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('userData'));
        this.setState({
            companyId: user.companyId,
            loading: true
        }, () => {
            this.props.getCompnay(user.companyId);
            this.GetReportData();
        });
    }


    onCheckboxClick = (e, item) => {
        e.stopPropagation();
        let existInArray = false;
        let data = this.state.SelectedRecords;
        if (data != null && data.length > 0 && data.indexOf(item.employeeID) > -1) {
            existInArray = true;
        }

        if (e.target.checked) {
            if (!existInArray) {
                data.push(item.employeeID);
            }
        }
        else {
            if (existInArray) {
                data.splice(data.indexOf(item.employeeID), 1);
            }
        }
        let all = (data.length == this.state.MissingData.length) ? true : false;
        this.setState({ SelectedRecords: data, SelectALL: all, editIndex: -1 });
    }

    OnApplyApproved = () => {
        let selectedData = this.state.SelectedRecords;
        if (selectedData == null || selectedData.length === 0) {
            toast.error("Please select atleast 1 record for processing request");
            return false;
        }
        let body = {
            "ids": selectedData,
            "reportType": ReportType.EmployeeMissingW4,
            "companyID": this.state.companyId
        }

        this.setState({ loading: true }, () => {
            this.props.UPDATE_EMPLOYEE_W4_DATA(body);
        });
    }

    render() {

        let selectedItem = this.state.SelectedRecords;
        return (
            <div className="dashboardPage">
                <div className="page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
                    <h2 className="mb-3 mb-sm-0">{JSON.parse(localStorage.getItem('userData')) != null ? JSON.parse(localStorage.getItem('userData')).companyName : ""} | {LinkType.EmployeeMissW4}</h2>
                </div>
                {this.state.loading ? <CircularProgress></CircularProgress>
                    :
                    <React.Fragment>
                        <div className="padding-box app-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="float-right">
                                                {this.state.ShowComplete &&
                                                    <Button variant="raised" className=" jr-btn g-teal  text-white refresh-btn graycolor" onClick={() => this.OnApplyApproved()}  >Completed</Button>
                                                }
                                                <Button variant="raised" style={{ backgroundColor: this.state.ColorCode }} className="jr-btn text-white refresh-btn" onClick={() => this.onRefreshDataClick()}  ><i className="zmdi zmdi-refresh "></i> Refresh Data</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive-material">
                                <table className="table tablebox account-reconcilliation">
                                    <thead>
                                        <tr style={{ backgroundColor: this.state.ColorCode }}>
                                            <th className={`td-width ${this.state.sortField == 'name' ? this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('name')}><p className="table-th-title"> Name</p> </th>
                                            <th className={`td-width ${this.state.sortField == 'status' ? this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('status')}><p className="table-th-title"> W-4 Status</p></th>

                                            {this.state.ShowComplete &&
                                                <th className="td-width-90 text-center">
                                                    <label className="container101 header-check">
                                                        {this.state.SelectALL ?
                                                            <input className="second-check-box" checked="checked" type="checkbox" onClick={(e) => this.SelectALL(e)} />
                                                            :
                                                            <input className="second-check-box" type="checkbox" onClick={(e) => this.SelectALL(e)} />
                                                        }
                                                        <span className="checkmark101 yellowMark white_border"></span>
                                                    </label>
                                                </th>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.MissingData.map((item, i) => (
                                            <tr>
                                                <td>{item.name}</td>
                                                <td>{item.status == EmployeeStatus.Pending ? 'Pending' : item.status == EmployeeStatus.Completed ? 'Completed' : 'Not Selected'}</td>
                                                {this.state.ShowComplete &&
                                                    <td className="text-center">
                                                        <label className="container101">
                                                            {(this.state.SelectALL || selectedItem.length > 0 && (selectedItem.indexOf(item.id) > -1)) ?
                                                                <input className="second-check-box" checked="checked" type="checkbox" onClick={(e) => this.onCheckboxClick(e, item)} />
                                                                :
                                                                <input className="second-check-box" type="checkbox" onClick={(e) => this.onCheckboxClick(e, item)} />
                                                            }
                                                            <span className="checkmark101 yellow_CheckMark"></span>
                                                        </label>
                                                    </td>
                                                }
                                            </tr>
                                        ))}
                                        {this.state.MissingData.length == 0 && <tr>
                                            <td className="text-center" colSpan="3">
                                            No issues here! Keep up the good work!
                                    </td>
                                        </tr>}
                                    </tbody>
                                </table>
                            </div>
                            <PaginationComponent
                                selectedPageNo={this.state.PageNo}
                                selectedSize={this.state.PageSize}
                                TotalRecords={this.state.TotalRecords}
                                ChangePageSize={this.ChangePageSize}
                                ChangePage={this.ChangePage}
                                CurrentRecords={this.state.MissingData.length}
                            >
                            </PaginationComponent>
                        </div>
                    </React.Fragment>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ReportData: state.ReportData.ReportData,
        // accounts: state.accounts.accountsData,
        company: state.companyReducer.companyDeatils,
        UpdateDescription: state.ReportData.UpdateDescription
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        GET_CompanyDashboard_PayrollEmployeeReportDetailList,
        UPDATE_EMPLOYEE_W4_DATA,
        getCompnay
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeMissW4Component);
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { addAccountant, RemoveAccountant } from '../appRedux/actions/accountantsAction';
import { findIndex } from "lodash";
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import DeleteIcon from '@material-ui/icons/Delete';
import RSelect from 'react-select';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';

class EditAccoutantForm extends React.Component {

  constructor() {
    super()
    this.state = {
      userId: '',
      companyId: '',
      selectedCompanies: [],
      companiesAct: []
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
  }
  componentWillReceiveProps(nextProps) {


  }

  handleDelete = data => () => {
    let removeChip = {
      userId: this.state.userId,
      companyId: data.companyId,
    };
    this.props.RemoveAccountant(removeChip);
    this.props.handleSave(false);
  }

  handleCancel = () => {
    this.props.handleCancel();
    this.resetForm();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let id = this.props.userId;
    let comps = this.props.selectedCompanies.value;

    if (id && comps.length > 0) {

      let data1 = comps.map((val, i) => {
        return val.value
      });

      let data = {
        userId: id,
        body: data1
      };

      this.props.addAccountant(data);

      this.props.handleSave(false);
    }
  };

  handleFormChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleCompChange = (selectedCompanies) => {
    this.props.selectedCompanies.value = selectedCompanies;
    this.setState({ loading: false });
  }

  resetForm = () => {
    this.setState({
      ...this.state,
      userId: '',
      companyId: '',
      selectedCompanies: null,
      companiesAct: ''
    })
  }


  render() {
    const { visible, selectedItem, selectedCompanies, companiesData } = this.props;
    const { companiesAct } = this.state;


    const options = companiesData.filter(data1 => {
      return data1.companyId !== 1 && data1.isActive == true;
    }).map(data => {
      return {
        value: data.companyId,
        label: data.name
      }
    });


    return (
      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={visible}
        onClose={this.handleCancel}
      >

        <DialogTitle>Edit Assigned companies</DialogTitle>
        <DialogContent>
          <form autoComplete="off" style={{ minHeight: 300, padding: 20 }}>
            <fieldset>
              <Grid container spacing={24} >
                <Grid item xs sm={6} style={{ padding: 10 }}>
                  <h2> Select Companies </h2>
                  <RSelect
                    value={selectedCompanies.value}
                    onChange={this.handleCompChange}
                    options={options}
                    isMulti={true}
                  />
                  {/* <Select
                                required={true}
                                name="companyId"
                                native
                                value={this.state.companyId}
                                onChange={this.handleFormChange}
                                inputProps={{
                                  name: 'companyId',
                                  id: 'teax-native-simple',
                                }}
                                style={{'width':'100%','height':'32px'}}
                              >
                                <option value="" disabled>Select Company</option>
                                {companiesData.length && companiesData.map((value, index)=>(
                                   value.companyId !== 1 ?
                                   <option value={value.companyId} key={index}>{value.name}</option> : ''
                               ))}
                              </Select>*/}
                </Grid>

              </Grid>
            </fieldset>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleSubmit} color="primary">
            Update
                </Button>
          <Button onClick={this.handleCancel} color="primary" autoFocus>
            Cancel
                </Button>
        </DialogActions>
      </Dialog>
    );
  }
}



const mapStateToProps = (state) => {
  return {

  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    addAccountant,
    RemoveAccountant
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAccoutantForm);

import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ClearIcon from '@material-ui/icons/Clear';
import { GET_HousingAllowanceHistory_DATA } from "../../appRedux/actions/employeeAction";
import CircularProgress from "../../components/CircularProgress";
import { toast } from 'react-toastify';


class HousingAllowanceHistoryComponent extends Component {

    constructor(props) {
        super(props)
        
        this.state = {
            HousingAllowanceHistory: [],  
            RecordId: props.RecordId,
            CompanyId: props.CompanyId,
            loading: false,
        }
    }


    HandleHousingAllowancePopupClose = () => {
        this.props.CloseHousingAllowanceHistory();
    }

    componentDidMount() {

        this.setState({
            loading: true
        }, () => {
            this.GetHousingAllowanceHistoryData();
        });

    }

    GetHousingAllowanceHistoryData() {
        let body = {
            CompanyID: this.state.CompanyId,
            EmployeeId: this.state.RecordId,
        }
        this.props.GET_HousingAllowanceHistory_DATA(body);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.HousingAllowanceHistoryData!== undefined && nextProps.HousingAllowanceHistoryData != this.props.HousingAllowanceHistoryData) {
            this.setState({ HousingAllowanceHistory: nextProps.HousingAllowanceHistoryData.returnObject, loading: false });
        }
    }

    render() {
        return (
            <React.Fragment>
               <Dialog
              className="comment-Top-align"
              maxWidth="sm"
              fullWidth={true}
              open={true}>
              <React.Fragment>
                  <div className="Comments modal-header"><span className="log-history-title float-left">Board Approved Housing Allowance History</span>
                      <Button className="jr-btn close-btn float-right" onClick={this.HandleHousingAllowancePopupClose}><ClearIcon /></Button>
                  </div>
                  <DialogContent>

                      {this.state.loading ? <CircularProgress></CircularProgress>
                          :
                          <React.Fragment>
                                <div className="housingallowancehistory-box">
                                    <table className='housingallowancehistorytable'>
                                        <tr>
                                            <th style={{textAlign:"center"}} colspan='2' className='housingallowance_tbody'>Board Approved Housing Allowance <br/> (This is usually done in December and January)</th>
                                        </tr>
                                        <tr>
                                            <th style={{textAlign:"center"}} className='housingallowance_tbody'>Year</th>
                                            <th style={{textAlign:"center"}} className='housingallowance_tbody'>Amount</th>
                                        </tr>
                                {this.state.HousingAllowanceHistory.length > 0 ?
                                    <React.Fragment>
                                        {this.state.HousingAllowanceHistory.map((item, i) => (
                                        <tr>
                                            <td style={{textAlign:"center"}} className='housingallowance_tbody'>{item.housingAllowanceApproveYear}</td>
                                            <td style={{textAlign:"right"}} className='housingallowance_tbody'>$ {parseFloat(item.housingAllowance).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                        </tr>
                                        ))}
                                    </React.Fragment>
                                    : 
                                        <tr>
                                            <td style={{textAlign:"center"}} colspan='2' className='housingallowance_tbody'>No History Found</td>
                                        </tr>
                                }
                                            </table>
                                      </div>
                          </React.Fragment>
                      }
                  </DialogContent>
              </React.Fragment>
          </Dialog>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        HousingAllowanceHistoryData: state.employee.HousingAllowanceHistoryData
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        GET_HousingAllowanceHistory_DATA
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(HousingAllowanceHistoryComponent);
import baseService from './baseService';

export function QuickbookAccount_AccountReconciliations(CompanyId,isExlcuded) {
    if(isExlcuded!= null && isExlcuded!= undefined)
    {
        return baseService.get('/QuickbookAccount/AccountReconciliations?CompanyId='+CompanyId+'&IsExcludedReconciliation='+isExlcuded);
    }
    else
    {
        return baseService.get('/QuickbookAccount/AccountReconciliations?CompanyId='+CompanyId);
    }
    
}

export function QuickbookAccount_updatecustomfield(data) {
    return baseService.put('/QuickbookAccount/updatecustomfield', data);
}

export function QuickbookAccount_UpdateAccountStatus(data) {
    return baseService.put('/QuickbookAccount/UpdateAccountStatus', data);
}


export function AddExcludedAccountReconciliationQuestionsAndComments(data) {
    return baseService.post('/QuickbookAccount/AddExcludedAccountReconciliationQuestionsAndComments', data);
}

export function MarkAccountReconciliationReview(companyId){
    return baseService.post("/QuickbookAccount/AddAccountReconciliationReviewedHistory?companyID="+companyId);
}

export function GetAccountReconciliationReviewedHistory(companyId){
    return baseService.get("/QuickbookAccount/GetAccountReconciliationReviewedHistory?companyId="+companyId);
}

export function GetGeneralReportNotes(CompanyID,ReportType)
{
    return baseService.get("/QuickbookAccount/GetGeneralReportNotes?CompanyId=" + CompanyID + "&ReportType=" + ReportType);
}
export function InsertUpdateGeneralReportNotes(data)
{
    return baseService.post("/QuickbookAccount/InsertUpdateGeneralReportNotes",data);
}
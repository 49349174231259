import {
    APPROVEWORKER_DATA, REPORT_REVIEW_DATA, GET_REPORT_DETAILS, UPDATE_ACCOUNT_DESCRIPTION, RELOAD, UPDATE_DESCRIPTION,UPDATE_CHECKNO, UPDATE_DESCRIPTION_DATA_BES, MARK_RESOLVED_DATA,
    POST_ACTIVE_INACIVE, TFB_CATGORIES_DATA, TFB_IN_PAYROLL_DATA, ARCR_SUMMARY
} from './../actions/ReportDetailAction';
// import { stat } from 'fs';
const initial_state = {
    ReportData: null,
    UpdateDescription: null,
    activeInactive: null,
    reload: false,
    ReviewData: null,
    ApproveWorkerData: null,
    TFBCategories: null,
    ARCRSummary: null,
    UpdateCheckNo : null
};


export default function authReducer(state = initial_state, action) {
    switch (action.type) {
        case GET_REPORT_DETAILS:
            return { ...state, ReportData: action.payload.data };
        case UPDATE_ACCOUNT_DESCRIPTION:
            return { ...state, UpdateDescription: action.payload.data };
        case UPDATE_DESCRIPTION:
            return { ...state, UpdateDescription: action.payload.data };
        case UPDATE_DESCRIPTION_DATA_BES:
            return { ...state, UpdateDescription: action.payload.data };
        case MARK_RESOLVED_DATA:
            return { ...state, UpdateDescription: action.payload.data };
        case POST_ACTIVE_INACIVE:
            return { ...state, activeInactive: action.payload.data };
        case REPORT_REVIEW_DATA:
            return { ...state, ReviewData: action.payload.data };
        case APPROVEWORKER_DATA:
            return { ...state, ApproveWorkerData: action.payload.data };
        case TFB_CATGORIES_DATA:
            return { ...state, TFBCategories: action.payload.data };
        case TFB_IN_PAYROLL_DATA:
            return { ...state, ReportData: action.payload.data };
        case ARCR_SUMMARY:
            return { ...state, ARCRSummary: action.payload.data };
        case RELOAD:
            return { ...state, reload: true };
        case UPDATE_CHECKNO:
                return { ...state, UpdateCheckNo: action.payload.data };
        default:
            return state;
    }
}
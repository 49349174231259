import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Accounts } from '../appRedux/actions/accountAction';
import { getCompanyTaxableWagesAccountsAsyncByCompanyId, updateCompanyTaxableWagesAccountsAsync } from '../appRedux/actions/companiesAction';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import RSelect from 'react-select';
import { passArrayToCommaSepratedString, getTwoArrayOfFilterData } from "../app/common/commonServices";
import { toast } from "react-toastify";

class EditCompanyWagesSettingForm extends React.Component {

    constructor() {
        super();
        this.state = {
            loading: false,
            companyId: null,
            accountsData: [],
            selectedSalariesWages: [],
            selectedHousingAllowance: [],
            selectedOtherTaxableIncome: [],
            selectedOtherPreTaxDeduction: [],
            selectedQualifiedPreTaxEmployee: [],
            selectedRetirementPlanDeductions: [],
            selectedReimbursements: [],
        }
    }


    componentDidMount() {
        this.listData();
    }

    listData() {
        let user = JSON.parse(localStorage.getItem('userData'));
        const CompanyId = this.props.CompanyId ? this.props.CompanyId : user.companyId;
        this.setState({ companyId: CompanyId });
        this.props.Accounts(CompanyId);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.accounts !== this.props.accounts) {

            let items=[];
            if(nextProps.accounts!=null)
            {
                items =  nextProps.accounts.data.filter(function(item) {
                    return item.accountType!=="Bank" && item.accountType!=="CreditCard";
                });   
            }
             
            let data = items.map(item => {
                return {
                    value: item.accountID,
                    label: item.accountNumber==null? item.name : item.name+ " ("+ item.accountNumber + ")"
                }
            });
            
            this.setState({ accountsData: data, }, () => {
                this.props.getCompanyTaxableWagesAccountsAsyncByCompanyId(this.state.companyId);
            });
        }
        if (nextProps.getAccountAsyncByCompany !== this.props.getAccountAsyncByCompany) {
            if (nextProps.getAccountAsyncByCompany.transactionStatus == 0) {
                var obj = nextProps.getAccountAsyncByCompany.returnObject;
                var housingAllowanceAccounts = obj.housingAllowanceAccounts ? getTwoArrayOfFilterData(obj.housingAllowanceAccounts.split(','), this.state.accountsData) : [];
                var employeeHealthInsuranceAccounts = obj.employeeHealthInsuranceAccounts ? getTwoArrayOfFilterData(obj.employeeHealthInsuranceAccounts.split(','), this.state.accountsData) : [];
                var employeeRetirementPlanAccounts = obj.employeeRetirementPlanAccounts ? getTwoArrayOfFilterData(obj.employeeRetirementPlanAccounts.split(','), this.state.accountsData) : [];
                var otherPreTaxDeductionsAccounts = obj.otherPreTaxDeductionsAccounts ? getTwoArrayOfFilterData(obj.otherPreTaxDeductionsAccounts.split(','), this.state.accountsData) : [];
                var otherTaxableIncomeAccounts = obj.otherTaxableIncomeAccounts ? getTwoArrayOfFilterData(obj.otherTaxableIncomeAccounts.split(','), this.state.accountsData) : [];
                var reimbursements = obj.reimbursements ? getTwoArrayOfFilterData(obj.reimbursements.split(','), this.state.accountsData) : [];
                var salariesWagesAccounts = obj.salariesWagesAccounts ? getTwoArrayOfFilterData(obj.salariesWagesAccounts.split(','), this.state.accountsData) : [];

                this.setState({
                    selectedHousingAllowance: housingAllowanceAccounts,
                    selectedOtherPreTaxDeduction: otherPreTaxDeductionsAccounts,
                    selectedOtherTaxableIncome: otherTaxableIncomeAccounts,
                    selectedQualifiedPreTaxEmployee: employeeHealthInsuranceAccounts,
                    selectedReimbursements: reimbursements,
                    selectedRetirementPlanDeductions: employeeRetirementPlanAccounts,
                    selectedSalariesWages: salariesWagesAccounts
                });
            }
        }
        if (nextProps.updateaccountAsyncByCompany !== this.props.updateaccountAsyncByCompany) {
            if (nextProps.updateaccountAsyncByCompany.transactionStatus == 0) {
                toast.success(`Record Update Successfull.`);
                this.handleCancel();
            } else {
                toast.error(` ${nextProps.updateaccountAsyncByCompany.resultMsg}`);
            }
        }
    }

    handleFormChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSalaryWagesChange(e) {
        this.setState({ selectedSalariesWages: e });
    }

    handleHousingAllowanceChange(e) {
        this.setState({ selectedHousingAllowance: e });
    }

    handleOtherTaxableIncomeChange(e) {
        this.setState({ selectedOtherTaxableIncome: e });
    }

    handleOtherPreTaxDeductionChange(e) {
        this.setState({ selectedOtherPreTaxDeduction: e });
    }

    handleQualifiedPreTaxEmployeeChange(e) {
        this.setState({ selectedQualifiedPreTaxEmployee: e });
    }

    handleRetirementPlanDeductionsChange(e) {
        this.setState({ selectedRetirementPlanDeductions: e });
    }

    handleReimbursementsChange(e) {
        this.setState({ selectedReimbursements: e });
    }

    handleCancel = () => {
        this.props.handleDelCancel()
    };

    handleSubmit = () => {
        var state = this.state;
        var selectedSalariesWages = passArrayToCommaSepratedString(state.selectedSalariesWages, 'value');
        var selectedHousingAllowance = passArrayToCommaSepratedString(state.selectedHousingAllowance, 'value');
        var selectedReimbursements = passArrayToCommaSepratedString(state.selectedReimbursements, 'value');
        var selectedOtherTaxableIncome = passArrayToCommaSepratedString(state.selectedOtherTaxableIncome, 'value');
        var selectedOtherPreTaxDeduction = passArrayToCommaSepratedString(state.selectedOtherPreTaxDeduction, 'value');
        var selectedRetirementPlanDeductions = passArrayToCommaSepratedString(state.selectedRetirementPlanDeductions, 'value');
        var selectedQualifiedPreTaxEmployee = passArrayToCommaSepratedString(state.selectedQualifiedPreTaxEmployee, 'value');
        let body = {
            CompanyID: state.companyId,
            SalariesWagesAccounts: selectedSalariesWages,
            HousingAllowanceAccounts: selectedHousingAllowance,
            OtherTaxableIncomeAccounts: selectedOtherTaxableIncome,
            EmployeeHealthInsuranceAccounts: selectedQualifiedPreTaxEmployee,
            EmployeeRetirementPlanAccounts: selectedRetirementPlanDeductions,
            OtherPreTaxDeductionsAccounts: selectedOtherPreTaxDeduction,
            Reimbursements: selectedReimbursements
        }
        this.props.updateCompanyTaxableWagesAccountsAsync(body);
    }

    render() {
        const { visible, states } = this.props;
        var options = this.state.accountsData;

        return (
            <Dialog
                maxWidth="md"
                fullWidth={true}
                open={true}
                onClose={this.handleCancel}
            >
                <form style={{ padding: 20 }}>
                    <DialogTitle>Payroll Settings</DialogTitle>
                    <DialogContent>
                        <fieldset>
                            <Grid container spacing={24}>
                                <Grid item sm={6}>
                                    <InputLabel htmlFor="tax-native-simple">Salaries & Wages</InputLabel>
                                    <RSelect
                                        value={this.state.selectedSalariesWages}
                                        onChange={(e) => this.handleSalaryWagesChange(e)}
                                        options={options}
                                        isMulti={true}
                                    />
                                </Grid>
                                <Grid item sm={6}>
                                    <InputLabel htmlFor="tax-native-simple">Housing Allowance</InputLabel>
                                    <RSelect
                                        value={this.state.selectedHousingAllowance}
                                        onChange={(e) => this.handleHousingAllowanceChange(e)}
                                        options={options}
                                        isMulti={true}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={24}>
                                <Grid item sm={6}>
                                    <InputLabel htmlFor="tax-native-simple">Other Taxable Income</InputLabel>
                                    <RSelect
                                        value={this.state.selectedOtherTaxableIncome}
                                        onChange={(e) => this.handleOtherTaxableIncomeChange(e)}
                                        options={options}
                                        isMulti={true}
                                    />
                                </Grid>
                                <Grid item sm={6}>
                                    <InputLabel htmlFor="tax-native-simple">Other Pre-Tax Deduction</InputLabel>
                                    <RSelect
                                        value={this.state.selectedOtherPreTaxDeduction}
                                        onChange={(e) => this.handleOtherPreTaxDeductionChange(e)}
                                        options={options}
                                        isMulti={true}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={24}>
                                <Grid item sm={6}>
                                    <InputLabel htmlFor="tax-native-simple">Qualified Pre-Tax Employee Health Insurance Co-Pay Deductions</InputLabel>
                                    <RSelect
                                        value={this.state.selectedQualifiedPreTaxEmployee}
                                        onChange={(e) => this.handleQualifiedPreTaxEmployeeChange(e)}
                                        options={options}
                                        isMulti={true}
                                    />
                                </Grid>
                                <Grid item sm={6}>
                                    <InputLabel htmlFor="tax-native-simple">Qualified Pre-Tax Employee Retirement Plan Deductions</InputLabel>
                                    <RSelect
                                        value={this.state.selectedRetirementPlanDeductions}
                                        onChange={(e) => this.handleRetirementPlanDeductionsChange(e)}
                                        options={options}
                                        isMulti={true}
                                    />
                                </Grid>
                                <Grid item sm={6}>
                                    <InputLabel htmlFor="tax-native-simple">Reimbursements</InputLabel>
                                    <RSelect
                                        value={this.state.selectedReimbursements}
                                        onChange={(e) => this.handleReimbursementsChange(e)}
                                        options={options}
                                        isMulti={true}
                                    />
                                </Grid>
                            </Grid>
                        </fieldset>
                    </DialogContent>
                    <DialogActions>
                        <Button type="button" color="primary" onClick={this.handleSubmit}>
                            Save
                        </Button>
                        <Button onClick={this.handleCancel} color="primary" autoFocus>
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        error: state.companyReducer.error,
        getAccountAsyncByCompany: state.companyReducer.accountAsyncByCompany,
        updateaccountAsyncByCompany: state.companyReducer.updateaccountAsyncByCompany,
        accounts: state.accounts.accountsData
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getCompanyTaxableWagesAccountsAsyncByCompanyId,
        updateCompanyTaxableWagesAccountsAsync,
        //updateCompanies,   
        Accounts
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(EditCompanyWagesSettingForm);

import React from "react";
import { bindActionCreators } from "redux";
import { updateRetirementPlan, addRetirementPlan } from '../appRedux/actions/retirementPlanAction';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { connect } from "react-redux";
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import moment from 'moment';
import { Form } from "antd";
import MaskedInput from 'react-text-mask';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import Textarea from 'react-expanding-textarea';
import './form.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const FormItem = Form.Item;

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      prefix="$ "
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};


function NumberFormatCustom2(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}


    />
  );
}

NumberFormatCustom2.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

class AddRetirementPlan extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      retirementPlanID: 0,
      companyID: '',
      dateAdded: new Date(),//moment().format('YYYY-MM-DD')
      serviceProvider: '',
      participants: '',
      planType: '',
      payrollSetup: '',
      comments: '',
      status: '',
      terminationDate: new Date(),      //moment().format('YYYY-MM-DD')
      selectedItem: false
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {

        let data = {
          retirementPlanID: this.state.retirementPlanID,
          companyID: this.props.companyID,
          dateAdded: this.state.dateAdded,
          serviceProvider: this.state.serviceProvider,
          participants: this.state.participants,
          planType: this.state.planType,
          payrollSetup: this.state.payrollSetup,
          comments: this.state.comments,
          status: this.state.status,
          terminationDate: this.state.terminationDate
        }
        if (!this.props.isEdit)
          this.props.addRetirementPlan(data);
        else
          this.props.updateRetirementPlan(data);
        this.setDefaultState();
        this.props.handleSave(false);
      }
    });
  }
  componentWillReceiveProps(nextProps) {

    if (nextProps.selectedItem && nextProps.selectedItem != this.state.selectedItem) {
      this.setState({
        selectedItem: nextProps.selectedItem,
        retirementPlanID: nextProps.selectedItem.retirementPlanID,
        companyID: nextProps.selectedItem.companyID,
        dateAdded: new Date(nextProps.selectedItem.dateAdded),//moment(nextProps.selectedItem.dateAdded).format('YYYY-MM-DD'),
        serviceProvider: nextProps.selectedItem.serviceProvider,
        participants: nextProps.selectedItem.participants,
        planType: nextProps.selectedItem.planType,
        payrollSetup: nextProps.selectedItem.payrollSetup,
        comments: nextProps.selectedItem.comments,
        status: nextProps.selectedItem.status,
        terminationDate: new Date(nextProps.selectedItem.terminationDate) //moment(nextProps.selectedItem.terminationDate).format('YYYY-MM-DD')
      })
    }
  }
  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleFormChange = (e, target) => {
    if (!target) {
      this.setState({ [e.target.name]: e.target.value })
    }
    else if (target == "dateAdded") {
      this.setState({ dateAdded: e });
    }
    else if (target == "terminationDate") {
      
      this.setState({ terminationDate: e });
    }
  }
  handleDelete = () => {
    let id = this.state.retirementPlanID;

    this.props.handleDelete(id);
  }
  setDefaultState = () => {
    this.setState({
      retirementPlanID: 0,
      companyID: '',
      dateAdded: new Date(),//moment().format('YYYY-MM-DD')
      serviceProvider: '',
      participants: '',
      planType: '',
      payrollSetup: '',
      comments: '',
      status: '',
      terminationDate: new Date(),//moment().format('YYYY-MM-DD')     
    })
  }

  handleFormCheched = name => event => {

    this.setState({ [name]: event.target.checked });
  };



  handleCancel = () => {
    this.setDefaultState();
    this.props.handleCancel()
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { visible, classes, leaseTypes, propertyTypes, vendorTypes, vendors } = this.props;    
    return (
      <Dialog 
        maxWidth="md"
        fullWidth={true}
        open={visible}
        onClose={this.handleCancel}
      >

        <DialogTitle>{this.props.isEdit ? 'Edit' : 'Add'} Retirement Plan</DialogTitle>
        <DialogContent className="modal-box">
          <Form>
            <fieldset>
              <Grid container spacing={24} >
                <Grid item sm={4}>
                  <FormItem>
                    <InputLabel className="labletext">Service Provider</InputLabel>
                    {getFieldDecorator('serviceProvider', {
                      initialValue: this.state.serviceProvider,
                      rules: [{
                        required: true, message: 'Please enter service provider',
                      }],
                    })(
                      <Select
                        name="serviceProvider"
                        native
                        required={true}
                        label={"Service Provider"}
                        fullWidth
                        onChange={this.handleChange('serviceProvider')}
                        inputProps={{
                          name: 'serviceProvider',
                          id: 'account-native-simple',
                        }}

                      >
                        <option value={''}>Select Service Provider</option>
                        {vendors.length && vendors.map((value, index) => (
                          <option value={value.vendorID} key={index}>{value.displayName}</option>
                        ))}
                      </Select>)}
                  </FormItem>
                </Grid>
                <Grid item sm={4} >
                  <FormItem>
                    {getFieldDecorator('planType', {
                      initialValue: this.state.planType,
                      rules: [{
                        required: true, message: 'Please enter plan type',
                      }],
                    })(
                      <TextField
                        name="planType"
                        required={true}
                        label={"Plan Type"}
                        fullWidth
                        margin="normal"
                        onChange={this.handleFormChange}

                      />)}
                  </FormItem>
                </Grid>



                <Grid item sm={4}>
                  <FormItem>
                    <InputLabel className="labletext">Participants</InputLabel>
                    {getFieldDecorator('participants', {
                      initialValue: this.state.participants,
                      rules: [{
                        required: true, message: 'Please enter participants',
                      }],
                    })(

                      <Textarea
                        style={{ 'lineHeight': '19px', width: '100%', border: 'none', marginTop: '0px', resize: 'none', padding: '6px', paddingLeft: '10px', borderBottom: '1px solid rgba(0, 0, 0, 0.42)', overflow: 'hidden' }} resize={false} placeholder="Participants"
                        onChange={(e) => { this.setState({ participants: e.target.value }); }}
                      />)}
                  </FormItem>

                </Grid>
              </Grid>
              <Grid container spacing={24}>
                
                <Grid item sm={4} >
                  <FormItem>
                    <InputLabel className="labletext">Start Date</InputLabel>
                    <DatePicker
                      selected={this.state.dateAdded}
                      onChange={(e) => this.handleFormChange(e, 'dateAdded')}
                      margin="normal"
                      name="dateAdded"
                      id="date"
                      required={true}
                    />
                  </FormItem>
                </Grid>
                <Grid item sm={4}>
                  <FormItem>
                    <InputLabel className="labletext">Termination Date</InputLabel>
                    {getFieldDecorator('terminationDate', {
                      //initialValue:this.state.terminationDate,
                      rules: [{
                        required: true, message: 'Please enter termination date',
                      }],
                    })(<DatePicker
                      selected={this.state.terminationDate}
                      onChange={(e) => this.handleFormChange(e, 'terminationDate')}
                      margin="normal"
                      name="terminationDate"
                      id="date"
                      required={true}
                    />)}
                  </FormItem>
                </Grid>
<Grid item sm={4}>
                  <InputLabel className="labletext">Status</InputLabel>
                  <FormItem>
                    {getFieldDecorator('status', {
                      initialValue: this.state.status,
                      rules: [{
                        required: true, message: 'Please select status',
                      }],
                    })(
                      <Select
                        name="status"
                        native
                        required={true}
                        label={"Status"}
                        fullWidth
                        onChange={this.handleChange('status')}
                        inputProps={{
                          name: 'status',
                          id: 'account-native-simple',
                        }}

                      >
                        <option value={''}>Select Status</option>
                        <option value={'Active'}>Active</option>
                        <option value={'Terminated'}>Terminated</option>

                      </Select>
                    )}</FormItem>
                </Grid>


              </Grid>
              <Grid container spacing={24}>
                <Grid item sm={4} >
                  <FormItem>
                    <InputLabel className="labletext">Payroll Setup</InputLabel>
                    {getFieldDecorator('payrollSetup', {
                      initialValue: this.state.payrollSetup,
                      rules: [{
                        required: true, message: 'Please select payroll setup',
                      }],
                    })(
                      <Select
                        name="payrollSetup"
                        native
                        required={true}
                        label={"Payroll Setup"}
                        fullWidth
                        onChange={this.handleChange('payrollSetup')}
                        inputProps={{
                          name: 'payrollSetup',
                          id: 'account-native-simple',
                        }}

                      >
                        <option value={''}>Select Payroll Setup</option>
                        <option value={'Pending'}>Pending</option>
                        <option value={'Complete'}>Complete</option>

                      </Select>
                    )}</FormItem>
                </Grid>
                <Grid item sm={4}>
                  <FormItem>
                    <InputLabel className="labletext">Comments</InputLabel>
                    {getFieldDecorator('comments', {
                      initialValue: this.state.comments,
                      rules: [{
                        required: true, message: 'Please enter comments',
                      }],
                    })(

                      <Textarea
                        value={this.state.comments}
                        style={{ 'lineHeight': '19px', width: '100%', border: 'none', marginTop: '0px', resize: 'none', padding: '6px', paddingLeft: '10px', borderBottom: '1px solid rgba(0, 0, 0, 0.42)', overflow: 'hidden' }} resize={false} placeholder="Comment"
                        onChange={(e) => { this.setState({ comments: e.target.value }); }}
                      />)}
                  </FormItem>
                </Grid>


              </Grid>

            </fieldset>
          </Form>
        </DialogContent>
        <DialogActions>

          <Button color="primary" onClick={this.handleSubmit}>
            {this.props.isEdit ? 'Update' : 'Save'}
          </Button>
          <Button color="primary" onClick={this.handleCancel} >
            Cancel
                    </Button>
          {this.props.isEdit ?
            <Button onClick={this.handleDelete} color="primary">
              Delete
					</Button> : null}

        </DialogActions>

      </Dialog>
    );
  }
}
const Addlease = Form.create()(AddRetirementPlan);

const mapStateToProps = (state) => {
  return {

  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    updateRetirementPlan,
    addRetirementPlan,
  }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Addlease);

import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { updateVendor } from '../appRedux/actions/vendorAction';
import { findIndex } from "lodash";
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { updateProfile } from "../appRedux/actions/userAction";
import { toast } from "react-toastify";


import Snackbar from '@material-ui/core/Snackbar';


class UserProfile extends React.Component {

    constructor() {
        super()
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            showError: false,
            open: false,
            vertical: 'top',
            horizontal: 'center',
            error: '',
            profile: {}
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.profile && nextProps.profile != this.state.profile) {
            this.state.profile = nextProps.profile;
            this.setState({
                firstName: this.state.profile.firstName,
                lastName: this.state.profile.lastName,
                email: this.state.profile.email,
            });
        }
    }
    handleCancel = () => {
        this.props.handleCancel();
        this.setState({
            firstName: '',
            lastName: '',
            email: '',
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        var data = {
            FirstName: this.state.firstName,
            LastName: this.state.lastName,
            Email: this.state.email
        };
        this.props.updateProfile(data);
        this.props.handleSave(false);

    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value, showError: false });
    };

    render() {
        const { visible } = this.props;
        const { firstName, lastName, email, vertical, error, horizontal, open } = this.state;

        return (
            <Dialog
                maxWidth="sm"
                fullWidth={true}
                open={visible}
                onClose={this.handleCancel}
            >
                <form method="post" onSubmit={this.handleSubmit} autoComplete="off" style={{ padding: 20 }}>
                    <DialogTitle>User Profile</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <fieldset>
                                <Grid container spacing={24}
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="flex-start"
                                >
                                    <Grid item xs>
                                        <TextField
                                            name="firstName"
                                            type="text"
                                            required={true}
                                            label={"First Name"}
                                            fullWidth
                                            onChange={this.handleChange}
                                            value={firstName}
                                            defaultValue={'First Name'}
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs >
                                        <TextField
                                            name="lastName"
                                            type="text"
                                            label="Last Name"
                                            fullWidth
                                            required={true}
                                            onChange={this.handleChange}
                                            value={lastName}
                                            defaultValue={'Last Name'}
                                            margin="normal"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={24}
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="flex-start"
                                >

                                    <Grid item xs >
                                        <TextField
                                            name="email"
                                            type="text"
                                            required={true}
                                            disabled={true}
                                            label={"Email"}
                                            fullWidth
                                            onChange={this.handleChange}
                                            value={email}
                                            defaultValue={'Email'}
                                            margin="normal"
                                        />
                                    </Grid>
                                </Grid>
                            </fieldset>

                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" color="primary">
                            Update
                </Button>
                        <Button onClick={this.handleCancel} color="primary" autoFocus>
                            Cancel
                </Button>
                    </DialogActions>
                </form>


            </Dialog>
        );
    }
}



const mapStateToProps = (state) => {
    return {
        loading: state.user.loading,
        profile: state.user.profile
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        updateProfile
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);




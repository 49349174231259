import {GET_ARCHIVED_DETAILS,GET_BALANCESHEET_DETAILS,UPDATE_CUSTOM_FILEDS,GET_EDIT_ARCHIED_DATA,GET_GLReviewLastQueterDate} from "../actions/BalanceSheetAction";
const initial_state ={
    BalanceSheetData :null,
    ArchivedData:null,
    UpdateData:null,
    EditArchivedData:null,
    GLReviewLastQueterDate:null
};


export default function authReducer(state = initial_state, action) {
    switch (action.type) {
        case GET_BALANCESHEET_DETAILS:
            return { ...state, BalanceSheetData: action.payload.data };
        case GET_ARCHIVED_DETAILS:
            return { ...state,ArchivedData:action.payload.data };
        case UPDATE_CUSTOM_FILEDS:
            return  { ...state,UpdateData:action.payload.data };
        case GET_EDIT_ARCHIED_DATA :
            return {...state,EditArchivedData:action.payload.data};
        case GET_GLReviewLastQueterDate :
            return {...state,GLReviewLastQueterDate:action.payload.data};
        default: 
            return state;
    }
}

import * as LedgerService from '../services/LedgerService';
import React from 'react';
import { toast } from "react-toastify";
export const LEDGER_GET = 'LEDGER_GET';
export const LEDGER_ADD = 'LEDGER_ADD';
export const VENDOR1099COMPLIANCEREVIEW = "VENDOR1099COMPLIANCEREVIEW";
export const COMMONUPDATE = "COMMONUPDATE";
export const ComplianceReviewNotesUpdate = "ComplianceReviewNotesUpdate";
export const GetComplianceReviewNotesData = "GetComplianceReviewNotesData";
export const REVIEWHISTORY = "REVIEWHISTORY"
export const LEDGER_UPDATE = "LEDGER_UPDATE"
export const BATCH_LEDGER_UPDATE = "BATCH_LEDGER_UPDATE"
export const REPORT_REVIEWD_HISTORY = "REPORT_REVIEWD_HISTORY"

function getCompanyId() {
    let user = JSON.parse(localStorage.getItem('userData'));
    const CompanyId = user.companyId;
    return CompanyId;
}

export const LedgerDetail = (id) => {
    return (dispatch) => {
        LedgerService.LedgerDetailService(id)
            .then((response) => {
                dispatch({ type: LEDGER_GET, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const GetUncategorizeData = (data) => {
    return (dispatch) => {
        LedgerService.GetUncategorizeData(data)
            .then((response) => {
                dispatch({ type: LEDGER_GET, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}
export const ReviewReconciliationDiscrepanciesData = (data) => {
    return (dispatch) => {
        LedgerService.ReviewReconciliationDiscrepanciesData(data)
            .then((response) => {
                dispatch({ type: LEDGER_GET, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const MarkReportReview = (companyId,reportType) => {
    return (dispatch) => {
        LedgerService.MarkReportReview(companyId,reportType)
            .then((response) => {
                dispatch({ type: COMMONUPDATE, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const GetReportReviewedHistory = (companyId,reportType) => {
    return (dispatch) => {
        LedgerService.GetReportReviewedHistory(companyId,reportType)
            .then((response) => {
                dispatch({ type: REPORT_REVIEWD_HISTORY, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const GetUncategorizeData_BES = (data) => {
    return (dispatch) => {
        LedgerService.GetUncategorizeData_BES(data)
            .then((response) => {
                dispatch({ type: LEDGER_GET, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}
export const GetReviewReconciliationDiscrepancies_BES = (data) => {
    return (dispatch) => {
        LedgerService.GetReviewReconciliationDiscrepancies_BES(data)
            .then((response) => {
                dispatch({ type: LEDGER_GET, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}




export const GetLedgerDetail = (companyId, search, pageNumber, pageSize, sortField, sortFormat, fromDate, toDate, serachField, searchText) => {
    return (dispatch) => {
        LedgerService.GetLedgerDetailService(companyId, search, pageNumber, pageSize, sortField, sortFormat, fromDate, toDate, serachField, searchText)
            .then((response) => {
                dispatch({ type: LEDGER_GET, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}


export const GetLedgerDetail_New = (data) => {
    return (dispatch) => {
        LedgerService.GetLedgerDetailService_New(data)
            .then((response) => {
                dispatch({ type: LEDGER_GET, payload: response });
            })
            .catch((err) => {
                console.log('1', err);
                toast.error(`${err.response}`)
            })
    }
}

export const LedgerUpdate = (data, companyId,message="") => {
    return (dispatch) => {
        LedgerService.LedgerUpdateService(data, companyId)
            .then((response) => {
                //dispatch(LedgerDetail(getCompanyId()))                
                // if (response.data.transactionStatus === 0) {

                //     if(message!= null && message!=="")
                //     {
                //         toast.success(message);
                //     }
                //     else
                //     {
                //         toast.success("Ledger Updated Successfully.");
                //     }                    
                // }
                // else {
                //     toast.error(response.data.resultMsg);
                // }
                dispatch({type: LEDGER_UPDATE,payload: response});
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const LedgerUpdate_BES = (data, companyId,message="") => {
    return (dispatch) => {
        LedgerService.LedgerUpdateService_BES(data, companyId)
            .then((response) => {
                //dispatch(LedgerDetail(getCompanyId()))                
                if (response.data.transactionStatus === 0) {
                    if(message!= null && message!=="")
                    {
                        toast.success(message);
                    }
                    else
                    {
                        toast.success("Ledger Updated Successfully.");
                    }           
                }
                else {
                    toast.error(response.data.resultMsg);
                }
                //  dispatch({type: 'LEDGER_UPDATE',payload: response});
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const MarkReview = (data) => {
    return (dispatch) => {
        LedgerService.MarkAsReview(data)
            .then((response) => {
                dispatch({ type: COMMONUPDATE, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const StartReview = (data) => {
    return (dispatch) => {
        LedgerService.StartReview(data)
            .then((response) => {
                dispatch({ type: COMMONUPDATE, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}



export const LedgerLastLog = (companyId) => {
    return LedgerService.LedgerLastLog(companyId);
}

export const Get1099ComplianceReview = (data) => {
    return (dispatch) => {
        LedgerService.Get1099ComplianceReview(data)
            .then((response) => {
                dispatch({ type: VENDOR1099COMPLIANCEREVIEW, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const Get1099ComplianceReview_BES = (data) => {
    return (dispatch) => {
        LedgerService.Get1099ComplianceReview_BES(data)
            .then((response) => {
                dispatch({ type: VENDOR1099COMPLIANCEREVIEW, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const MarkVendorReview = (companyId) => {
    return (dispatch) => {
        LedgerService.MarkVendorReview(companyId)
            .then((response) => {
                dispatch({ type: COMMONUPDATE, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}


export const GetReviewVendorHistory = (companyId) => {
    return (dispatch) => {
        LedgerService.GetReviewVendorHistory(companyId)
            .then((response) => {
                dispatch({ type: REVIEWHISTORY, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const UpdateVendor1099 = (data) => {
    return (dispatch) => {
        LedgerService.UpdateVendor1099(data)
            .then((response) => {
                dispatch({ type: COMMONUPDATE, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}


export const EmployeeStatusApprove = (data) => {
    return (dispatch) => {
        LedgerService.EmployeeStatusApprove(data)
            .then((response) => {
                dispatch({ type: COMMONUPDATE, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}


export const EmployeeStatusApprove_BES = (data) => {
    return (dispatch) => {
        LedgerService.EmployeeStatusApprove_BES(data)
            .then((response) => {
                dispatch({ type: COMMONUPDATE, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}


export const InsertUpdateComplianceReviewNotes = (data) => {
    return (dispatch) => {
        LedgerService.InsertUpdateComplianceReviewNotes(data)
            .then((response) => {
                dispatch({ type: ComplianceReviewNotesUpdate, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const GetComplianceReviewNotes = (CompanyID,ReportType) => {
    return (dispatch) => {
        LedgerService.GetComplianceReviewNotes(CompanyID,ReportType)
            .then((response) => {
                dispatch({ type: GetComplianceReviewNotesData, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}



export const UpdateBatchNewAccount = (data) => {
    return (dispatch) => {
        LedgerService.UpdateBatchNewAccountService(data)
            .then((response) => {
                dispatch({type: BATCH_LEDGER_UPDATE,payload: response});
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const UpdateBatchNewAccount_BES = (data) => {
    return (dispatch) => {
        LedgerService.UpdateBatchNewAccountService_BES(data)
            .then((response) => {
                dispatch({type: BATCH_LEDGER_UPDATE,payload: response});
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const UpdateBatchVendor = (data) => {
    return (dispatch) => {
        LedgerService.UpdateBatchVendorService(data)
            .then((response) => {
                dispatch({type: BATCH_LEDGER_UPDATE,payload: response});
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const UpdateBatchVendor_BES = (data) => {
    return (dispatch) => {
        LedgerService.UpdateBatchVendorService_BES(data)
            .then((response) => {
                dispatch({type: BATCH_LEDGER_UPDATE,payload: response});
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const Update1099ReviewExcludeTotalStatus = (data) => {
    return (dispatch) => {
        LedgerService.Update1099ReviewExcludeTotalStatus(data)
            .then((response) => {
                dispatch({type: LEDGER_UPDATE,payload: response});
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const Update1099ReviewExcludeTotalStatus_BES = (data) => {
    return (dispatch) => {
        LedgerService.Update1099ReviewExcludeTotalStatus_BES(data)
            .then((response) => {
                dispatch({type: LEDGER_UPDATE,payload: response});
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}
const initial_state = {};
export default function authReducer(state = initial_state, action) {
    switch (action.type) {
        case 'TRIAL_BALANCE_GET':
            action.payload.data.reload = false;
            return [...action.payload.data];
        case 'RELOAD':
            return {...state, reload:true};
        default:
            return state;
    }
}

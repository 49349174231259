import React from "react";
import { Form } from "antd";
import { bindActionCreators } from "redux";
import { updateLoan, addLoan } from '../appRedux/actions/loansAction';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import { connect } from "react-redux";

// import option from '@material-ui/core/option';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import moment from 'moment';
import MaskedInput from 'react-text-mask';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import './form.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const FormItem = Form.Item;


function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      prefix="$ "
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};


function NumberFormatCustom2(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}


    />
  );
}

NumberFormatCustom2.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

function NumberFormatCustom3(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      suffix=" %"
      decimalScale={3}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value > 100 ? 100 : (values.value.split(".").length > 1 && values.value.split(".")[1].length > 3) ? values.value.split(".")[0] + '.' + values.value.split(".")[1].substr(0, 3) : values.value,
          },
        });
      }}


    />
  );
}

NumberFormatCustom3.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};


class Addloadform extends React.Component {
  constructor() {
    super()
    this.state = {
      "loanID": 0,
      "companyID": 0,
      "vendorID": '',
      "financeCompany": "",
      "loanDate": new Date(),
      "terms": '',
      "amortizationPeriod": '',
      "interestRate": '',
      "monthlyPayments": '',
      "balloonPayments": '',
      "originalLoanAmount": '',
      "lastPaymentDueDate": new Date(),
      "propertyTypeID": '',
      "propertyDetails": "",
      "comments": "",
      "alertDate": new Date(),
      "alertComments": "",
      "loanReviewComplete": true,
      "loanReviewDate": new Date(),
      selectedItem: false
    }

  }
  componentWillReceiveProps(nextProps) {

    if (nextProps.selectedItem != this.state.selectedItem) {
      this.setState({
        loanID: nextProps.selectedItem.loanID,
        companyID: nextProps.selectedItem.companyID,
        vendorID: nextProps.selectedItem.vendorID,
        financeCompany: nextProps.selectedItem.financeCompany,
        loanDate: new Date(nextProps.selectedItem.loanDate),
        terms: nextProps.selectedItem.terms,
        amortizationPeriod: nextProps.selectedItem.amortizationPeriod,
        interestRate: nextProps.selectedItem.interestRate,
        monthlyPayments: nextProps.selectedItem.monthlyPayments,
        balloonPayments: nextProps.selectedItem.balloonPayments,
        originalLoanAmount: nextProps.selectedItem.originalLoanAmount,
        lastPaymentDueDate: new Date(nextProps.selectedItem.lastPaymentDueDate),
        propertyTypeID: nextProps.selectedItem.propertyTypeID,
        propertyDetails: nextProps.selectedItem.propertyDetails,
        comments: nextProps.selectedItem.comments,
        alertDate: new Date(nextProps.selectedItem.alertDate),
        alertComments: nextProps.selectedItem.alertComments,
        loanReviewComplete: nextProps.selectedItem.loanReviewComplete,
        loanReviewDate: new Date(nextProps.selectedItem.loanReviewDate),
        selectedItem: nextProps.selectedItem
      })
    }
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let data = {
          loanID: this.state.loanID,
          companyID: this.props.companyID,
          vendorID: this.state.vendorID,
          financeCompany: this.state.financeCompany,
          loanDate: moment(this.state.loanDate).format('YYYY-MM-DD'),
          terms: this.state.terms,
          amortizationPeriod: Number(this.state.amortizationPeriod),
          interestRate: Number(this.state.interestRate),
          monthlyPayments: Number(this.state.monthlyPayments),
          balloonPayments: Number(this.state.balloonPayments),
          originalLoanAmount: Number(this.state.originalLoanAmount),
          lastPaymentDueDate: moment(this.state.lastPaymentDueDate).format('YYYY-MM-DD'),
          propertyTypeID: this.state.propertyTypeID,
          propertyDetails: this.state.propertyDetails,
          comments: this.state.comments,
          alertDate: moment(this.state.alertDate).format('YYYY-MM-DD'),
          alertComments: this.state.alertComments,
          loanReviewComplete: this.state.loanReviewComplete,
          loanReviewDate: moment(this.state.loanReviewDate).format('YYYY-MM-DD'),
        }

        if (!this.props.isEdit)
          this.props.addLoan(data);
        else
          this.props.updateLoan(data);

        this.setDefaultState();
        this.props.handleSave(false);
      }
    });
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleDelete = () => {
    let id = this.state.loanID;

    this.props.handleDelete(id);
  }

  handleFormChange = (e, target) => {

    if (!target) {
      this.setState({ [e.target.name]: e.target.value })
    }
    else if (target == "alertDate") {
      this.setState({ alertDate: new Date(e) })
    }
    else if (target == "lastPaymentDueDate") {
      this.setState({ lastPaymentDueDate: new Date(e) })
    }
    else if (target == "loanReviewDate") {
      this.setState({ loanReviewDate: new Date(e) })
    }
    else if (target == "loanDate") {
      this.setState({ loanDate: new Date(e) })
    }

  }

  handleFormCheched = name => event => {

    this.setState({ [name]: event.target.checked });
  };

  setDefaultState = () => {
    this.setState({
      "loanID": 0,
      "companyID": 0,
      "vendorID": '',
      "financeCompany": "",
      "loanDate": new Date(),
      "terms": '',
      "amortizationPeriod": '',
      "interestRate": '',
      "monthlyPayments": '',
      "balloonPayments": '',
      "originalLoanAmount": '',
      "lastPaymentDueDate": new Date(),
      "propertyTypeID": '',
      "propertyDetails": "",
      "comments": "",
      "alertDate": new Date(),
      "alertComments": "",
      "loanReviewComplete": true
    })
  }


  handleCancel = () => {
    this.setDefaultState();
    this.props.handleCancel();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { visible, classes, vendorTypes, propertyTypes, vendors } = this.props;

    return (
      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={visible}
        onClose={this.handleCancel.bind(this)}
      >

        <DialogTitle>{this.props.isEdit ? 'Edit' : 'Add'} Loan</DialogTitle>
        <DialogContent>
          <Form>
            <fieldset>
              <Grid container spacing={24} >
                <Grid item sm={3} >
                  <FormItem>
                    <InputLabel className="labletext">Vendor</InputLabel>
                    {getFieldDecorator('vendorID', {
                      initialValue: this.state.vendorID,
                      rules: [{
                        required: true, message: 'Please vendor',
                      }],
                    })(
                      <Select
                        name="vendorID"
                        native
                        required={true}
                        label={"Vendor"}
                        fullWidth
                        onChange={this.handleChange('vendorID')}
                        inputProps={{
                          name: 'displayName',
                          id: 'account-native-simple',
                        }}

                      >
                        <option value={''}>Select Vendor</option>
                        {vendors.length && vendors.map((value, index) => (
                          <option value={value.vendorID} key={index}>{value.displayName}</option>
                        ))}
                      </Select>
                    )}</FormItem>
                </Grid>
                <Grid item sm={3}>
                  <FormItem>
                    <InputLabel className="labletext">Property</InputLabel>

                    {getFieldDecorator('propertyTypeID', {
                      initialValue: this.state.propertyTypeID,
                      rules: [{
                        required: true, message: 'Please select property',
                      }],
                    })(
                      <Select
                        name="propertyTypeID"
                        native
                        required={true}
                        onChange={this.handleChange('propertyTypeID')}
                        placeholder="Select Property Type"
                        fullWidth
                        inputProps={{
                          name: 'propertyTypeID',
                          id: 'account-native-simple',
                        }}

                      >
                        <option value={''}>Select Property</option>
                        {propertyTypes.length && propertyTypes.map((value, index) => (
                          <option value={value.propertyTypeID} key={index}>{value.type === null ? value.propertyTypeID : value.type}</option>
                        ))}
                      </Select>)}
                  </FormItem></Grid>
                <Grid item sm={3} >
                  <FormItem>
                    {getFieldDecorator('amortizationPeriod', {
                      initialValue: this.state.amortizationPeriod,
                      rules: [{
                        required: true, message: 'Please enter amortization period',
                      }],
                    })(
                      <TextField
                        name="amortizationPeriod"
                        required={true}
                        label={"Amortization Period"}
                        fullWidth
                        margin="normal"
                        onChange={(e) => this.setState({ amortizationPeriod: e.target.value })}
                        InputProps={{
                          inputComponent: NumberFormatCustom2,
                        }}

                      />)}
                  </FormItem></Grid>
                <Grid item sm={3} >
                  <FormItem>
                    {getFieldDecorator('interestRate', {
                      initialValue: this.state.interestRate,
                      rules: [{
                        required: true, message: 'Please enter interest rate',
                      }],
                    })(
                      <TextField
                        name="interestRate"
                        required={true}
                        label={"Interest Rate"}
                        fullWidth
                        margin="normal"
                        onChange={(e) => this.setState({ interestRate: e.target.value })}
                        InputProps={{
                          inputComponent: NumberFormatCustom3,
                        }}

                      />)}
                  </FormItem></Grid>

              </Grid>
              <Grid container spacing={24}>
                <Grid item sm={3} >
                  <FormItem>
                    {getFieldDecorator('financeCompany', {
                      initialValue: this.state.financeCompany,
                      rules: [{
                        required: true, message: 'Please enter finance company',
                      }],
                    })(
                      <TextField
                        name="financeCompany"
                        required={true}
                        label={"Financing Company"}
                        fullWidth
                        margin="normal"
                        onChange={this.handleFormChange}

                      />)}</FormItem>
                </Grid>

                <Grid item sm={3} >
                  <FormItem>
                    {getFieldDecorator('monthlyPayments', {
                      initialValue: this.state.monthlyPayments,
                      rules: [{
                        required: true, message: 'Please enter monthly payments',
                      }],
                    })(
                      <TextField
                        name="monthlyPayments"
                        onChange={(e) => this.setState({ monthlyPayments: e.target.value })}
                        label="Monthly Payments"
                        fullWidth
                        margin="normal"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}

                      />)}</FormItem>
                </Grid>
                <Grid item sm={3} >
                  <FormItem>
                    {getFieldDecorator('balloonPayments', {
                      initialValue: this.state.balloonPayments,
                      rules: [{
                        required: true, message: 'Please enter balloon payment',
                      }],
                    })(
                      <TextField
                        name="balloonPayments"
                        onChange={(e) => this.setState({ balloonPayments: e.target.value })}
                        label="Balloon Payment"
                        fullWidth
                        margin="normal"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}

                      />)}</FormItem>
                </Grid>

                <Grid item sm={3}>
                  <FormItem>
                    {getFieldDecorator('terms', {
                      initialValue: this.state.terms,
                      rules: [{
                        required: true, message: 'Please enter terms',
                      }],
                    })(
                      <TextField
                        name="terms"
                        onChange={this.handleFormChange}
                        required={true}
                        label={"Terms(Months)"}
                        fullWidth
                        margin="normal"

                      />)}</FormItem>
                </Grid>
              </Grid>
              <Grid container spacing={24}>
                <Grid item sm={3}>
                  <FormItem>
                    <InputLabel className="labletext">Loan Date</InputLabel>
                    <DatePicker
                        selected={this.state.loanDate}
                        onChange={(e) => this.handleFormChange(e, 'loanDate')}
                        label="Loan Date"
                        id="date"
                        name="loanDate"
                        margin="normal"
                      /></FormItem>
                </Grid>


                <Grid item sm={3} >
                  <FormItem>
                    <InputLabel className="labletext">Last Payment Due Date</InputLabel>
                    <DatePicker
                      selected={this.state.lastPaymentDueDate}
                      onChange={(e) => this.handleFormChange(e, 'lastPaymentDueDate')}
                      label="Last Payment Due Date"
                      name="lastPaymentDueDate"
                      id="date"
                      margin="normal"
                      required={true}
                      fullWidth
                    /></FormItem>
                </Grid>
                <Grid item sm={3}>
                  <FormItem>
                    {getFieldDecorator('propertyDetails', {
                      initialValue: this.state.propertyDetails,
                      rules: [{
                        required: true, message: 'Please enter property details',
                      }],
                    })(
                      <TextField
                        name="propertyDetails"
                        required={true}

                        onChange={this.handleFormChange}
                        label={"Property Details"}
                        fullWidth
                        margin="normal"

                      />)}</FormItem>
                </Grid>
                <Grid item sm={3} >
                  <FormItem>
                    {getFieldDecorator('originalLoanAmount', {
                      initialValue: this.state.originalLoanAmount,
                      rules: [{
                        required: true, message: 'Please enter original loan amount',
                      }],
                    })(
                      <TextField
                        name="originalLoanAmount"
                        onChange={(e) => { this.state.originalLoanAmount = e.target.value; }}
                        label="Original Loan Amount"
                        fullWidth
                        margin="normal"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}

                      />)}</FormItem>
                </Grid>


              </Grid>
              <Grid container spacing={24}>
                <Grid item sm={3} >
                  <FormItem>
                    {getFieldDecorator('comments', {
                      initialValue: this.state.comments,

                    })(
                      <TextField
                        name="comments"
                        onChange={this.handleFormChange}

                        label={"Comments"}
                        fullWidth
                        margin="normal"
                      />)}</FormItem>
                </Grid>

                <Grid item sm={3}>
                  <FormItem>
                    {getFieldDecorator('alertComments', {
                      initialValue: this.state.alertComments,

                    })(
                      <TextField
                        name="alertComments"


                        onChange={this.handleFormChange}
                        label={"Alert Comments"}
                        fullWidth
                        margin="normal"
                      />)}</FormItem>
                </Grid>
                <Grid item sm={3}>
                  <FormItem>
                    <InputLabel className="labletext">Alert Date</InputLabel>
                    <DatePicker
                      selected={this.state.alertDate}
                      onChange={(e) => this.handleFormChange(e, 'alertDate')}
                      label="Alert Date"
                      name="alertDate"
                      id="date"
                      margin="normal"
                      fullWidth
                    />
                  </FormItem>
                </Grid>
                <Grid item sm={3}>
                  <FormItem>
                    <InputLabel className="labletext">Loan Review Date</InputLabel>
                    <DatePicker
                      selected={this.state.loanReviewDate}
                      onChange={(e) => this.handleFormChange(e, 'loanReviewDate')}
                      label="Loan Review Date"
                      name="loanReviewDate"
                      id="date"
                      margin="normal"
                    /></FormItem>
                </Grid>
                <Grid item sm={3} style={{ 'paddingTop': '34px' }}  >
                  <Checkbox
                    className="label_lease"
                    onChange={this.handleFormCheched('loanReviewComplete')}
                    checked={this.state.loanReviewComplete}
                  />
                  <label>Loan Review Complete </label>
                </Grid>

              </Grid>
            </fieldset>
          </Form>
        </DialogContent>

        <DialogActions>

          <Button color="primary" onClick={this.handleSubmit}>
            {this.props.isEdit ? 'Update' : 'Save'}
          </Button>
          <Button color="primary" onClick={this.handleCancel} >
            Cancel
                    </Button>
          {this.props.isEdit ?
            <Button onClick={this.handleDelete} color="primary">
              Delete
					</Button> : null}

        </DialogActions>

      </Dialog>
    );
  }
}
const Addloan = Form.create()(Addloadform);
const mapStateToProps = (state) => {
  return {

  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    updateLoan,
    addLoan,
  }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Addloan);



// export default Addloan;

import * as AccountantService from '../services/AccountantService';
import React from 'react';
import { toast } from "react-toastify";

export const ACCOUNTANT_GET = 'ACCOUNTANT_GET';
export const ACCOUNTANT_UPDATE = 'ACCOUNTANT_UPDATE';
export const ACCOUNTANT_ADD = 'ACCOUNTANT_ADD';
export const ACCOUNTANT_DELETE = 'ACCOUNTANT_DELETE';

export const Accountants = () => {
    return (dispatch) => {
        AccountantService.Accountants()
            .then((response)=>{
                dispatch({type: ACCOUNTANT_GET,payload: response});
            })
            .catch((error)=>{
                 toast.error(`${error.response.data}`) 
            })
    }
}

export const updateAccountant = (data) => {
    return (dispatch) => {
        AccountantService.UpdateAccountant(data)
            .then((response)=>{
            	dispatch(Accountants());
                 toast.success("Company Assigned Successfully.");
                dispatch({type: ACCOUNTANT_UPDATE,payload: response});
            })
            .catch((error)=>{
            	 toast.error(`${error.response.data[0].description}`) 
            })
    }
}

export const addAccountant = (data) => {
    return (dispatch) => {
        AccountantService.AddAccountant(data)
            .then((response)=>{
                dispatch(Accountants());
                 toast.success("Company Assigned Successfully.");
                dispatch({type: ACCOUNTANT_ADD,payload: response});
            })
            .catch((error)=>{
            	 toast.error(`${error.response.data[0].description}`) 
            })
    }
}

export const RemoveAccountant = (data) => {
    return (dispatch) => {
        AccountantService.RemoveAccountant(data)
            .then((response)=>{
                dispatch(Accountants());
                 toast.success("Company Revoked Successfully.");
                dispatch({type: ACCOUNTANT_DELETE,payload: response});
            })
            .catch((error)=>{
                  toast.error(`${error.response.data[0].description}`) 
            })
    }
}


import React from 'react';
import Avatar from '@material-ui/core/Avatar'
import { connect } from 'react-redux'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { logoutUser } from '../../appRedux/actions/Auth';
import ResetForm from '../../Forms/ResetPassword.js';
import UserProfile from '../../Forms/UserProfile';
import UserImage from '../../Forms/UserImage';
import IntlMessages from '../../util/IntlMessages';
import jwt_decode from 'jwt-decode';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getProfile, getProfileImage } from "../../appRedux/actions/userAction";
class UserInfo extends React.Component {

    state = {
        anchorEl: null,
        open: false,
        isReset: false,
        isUserProfile: false
    };

    componentDidMount() {
        this.props.getProfileImage();
    }
    handleClick = event => {
        this.setState({ open: true, anchorEl: event.currentTarget });
    };

    handleReset = () => {
        this.setState({ isReset: true });
        this.handleRequestClose();
    }

    handleRequestClose = () => {
        this.setState({ open: false });
    };

    handleCancel = () => {
        this.setState({ isReset: false });
        this.handleRequestClose();
    };

    handleSave = (state) => {

        this.setState({
            isReset: state
        });
        this.handleRequestClose();
    }


    render() {
        const user = JSON.parse(localStorage.getItem('userData'));        
        let decode = jwt_decode(user.Authorization);
        const name = decode['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'];
        const role = decode['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];        
        return (
            <div>
                <ResetForm
                    handleSave={this.handleSave}
                    visible={this.state.isReset}
                    handleCancel={this.handleCancel}
                />
                <UserProfile
                    handleSave={() => this.setState({ isUserProfile: false })}
                    visible={this.state.isUserProfile}
                    handleCancel={() => this.setState({ isUserProfile: false })}
                />
                <UserImage
                    handleSave={() => this.setState({ isUserImage: false })}
                    visible={this.state.isUserImage}
                    handleCancel={() => this.setState({ isUserImage: false })}
                />
                <div className="user-profile d-flex flex-row align-items-center">
                    <Avatar
                        alt='...'
                        src={this.props.profileImage == '' ? '/img/no-user.jpg' : '/' + this.props.profileImage}
                        className="user-avatar "
                        onClick={() => this.setState({ isUserImage: true })}
                    />
                    <div className="user-detail">
                        <h4 className="user-name" onClick={this.handleClick}>Welcome, {name}<i
                            className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
                        </h4>
                    </div>
                    <Menu className="user-info"
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        open={this.state.open}
                        onClose={this.handleRequestClose}
                        PaperProps={{
                            style: {
                                marginTop: 30,
                                paddingTop: 0,
                                paddingBottom: 0
                            }
                        }}
                    >
                        <MenuItem onClick={
                            this.handleReset
                        }>
                            <i className="zmdi zmdi-settings zmdi-hc-fw mr-2" />
                            <IntlMessages id="popup.changePassword" />
                        </MenuItem>
                        <MenuItem onClick={() => {
                            this.props.getProfile();
                            this.setState({ open: false, isUserProfile: true });
                        }
                        }>
                            <i className="zmdi zmdi-account-circle zmdi-hc-fw mr-2" />
                       User Profile
                   </MenuItem>
                        <MenuItem onClick={() => {
                            this.handleRequestClose();
                            this.props.logoutUser()
                        }}>
                            <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />
                            <IntlMessages id="popup.logout" />
                        </MenuItem>
                    </Menu>
                </div>
                <ToastContainer autoClose={3000} />
            </div>
        );
    }
}

const mapStateToProps = ({ settings, user }) => {
    const { locale } = settings;
    return { locale, profileImage: user.profileImage }
};

export default connect(mapStateToProps, { logoutUser, getProfile, getProfileImage })(UserInfo);

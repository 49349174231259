import * as InsuranceService from '../services/InsuranceService';
import  jwt_decode from 'jwt-decode';
import React from 'react';
import { toast } from "react-toastify";

export const INSURANCE_SUCCESS = "INSURANCE_SUCCESS";
export const INSURANCE_ERROR = "INSURANCE_ERROR";

export const ADD_INSURANCE_SUCCESS = "ADD_INSURANCE_SUCCESS";
export const ADD_INSURANCE_ERROR = "ADD_INSURANCE_ERROR";

export const UPDATE_INSURANCE_SUCCESS = "UPDATE_INSURANCE_SUCCESS";
export const UPDATE_INSURANCE_ERROR = "UPDATE_INSURANCE_ERROR";

export const DELETE_INSURANCE_SUCCESS = "DELETE_INSURANCE_SUCCESS";
export const DELETE_INSURANCE_ERROR = "DELETE_INSURANCE_ERROR";

export const INSURANCE_POLICY_SUCCESS = "INSURANCE_POLICY_SUCCESS";
export const INSURANCE_POLICY_ERROR = "INSURANCE_POLICY_ERROR";




function InsuranceSuccess (payload){
    return{
        type: INSURANCE_SUCCESS,
        payload
    }
}

function InsuranceError (payload){
    return{
        type: INSURANCE_ERROR,
        payload
    }
}


function getCompanyId () {
    let user = JSON.parse(localStorage.getItem('userData'));
   // let decode = jwt_decode(user.Authorization);
    const CompanyId = user.companyId;
    return CompanyId;
}



export const searchInsurance = (companyId,text) => {
  return (dispatch) => {
        InsuranceService.searchInsurance(companyId,text)
            .then((response)=>{
                return dispatch(InsuranceSuccess(response))
            })
            .catch((err)=>{
              toast.error(`${err.response.data}`)
            })
    }
}

export function getPolicyTypes(){
    return (dispatch) => {
        InsuranceService.getPolicyTypes()
        .then((res) => {
            return dispatch({type: INSURANCE_POLICY_SUCCESS, payload: res})
        })
        .catch(err => {
             toast.error(`${err.response.data}`)
        })
    }
}

export function getInsurances(companyId,search,pageNumber,pageSize,sortField,sortFormat){
    return (dispatch) => {
        // dispatch()
        InsuranceService.getInsurances(companyId,search,pageNumber,pageSize,sortField,sortFormat)
        .then((res) => {
            return dispatch(InsuranceSuccess(res))
        })
        .catch(err => {
            toast.error(`${err.response.data}`)
        })
    }
}

export function addInsurance(data){
    return (dispatch) => {
        InsuranceService.addInsurance(data)
        .then(res => {
             dispatch({type:'RELOAD',payload:res});
             dispatch(getPolicyTypes());
            toast.success("Insurance added Successfully.");
        })
        .catch(err => {
           toast.error(`${err.response.data}`)
        })
    }
}

export function updateInsurance(data){
    return (dispatch) => {
        InsuranceService.updateInsurance(data)
        .then(res => {
            dispatch({type:'RELOAD',payload:res});
            dispatch(getPolicyTypes());
            toast.success("Insurance Updated Successfully.");
            //return dispatch({type: UPDATE_INSURANCE_SUCCESS, payload: res});
        })
        .catch(err => {
           toast.error(`${err.response.data}`)
            //return dispatch({type: UPDATE_INSURANCE_ERROR, payload: err.response})
        })
    }
}

export function deleteInsurance(value){
    return (dispatch) => {
        InsuranceService.deleteInsurance(value)
        .then(res => {
            dispatch({type:'RELOAD',payload:res});
            dispatch(getPolicyTypes());
            toast.success("Insurance Deleted Successfully.");
            //return dispatch({type: DELETE_INSURANCE_SUCCESS, payload: res});
        })
        .catch(err => {
             toast.error(`${err.response.data}`)
            //return dispatch({type: DELETE_INSURANCE_ERROR, payload: err.response})
        })
    }
}

import React, {Component} from "react";
import IntlMessages from "../../../../util/IntlMessages";
import {GetAccounts,searchAccount} from '../../../../appRedux/actions/accountAction';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import _ from 'lodash';
import ReactTable from "react-table";
import "react-table/react-table.css";
import CircularProgress from "../../../../components/CircularProgress";
import EditAccountForm from '../../../../Forms/EditAccountForm.js';
import SearchBox from '../../../../components/SearchBox';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import { ToastContainer } from "react-toastify";
import {Table, Pagination  } from 'antd';
import moment from 'moment';
import "react-toastify/dist/ReactToastify.css";
import { Resizable } from 'react-resizable';
import "../../styles.css";

const ResizeableTitle = props => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable width={width} height={0} onResize={onResize}>
      <th {...restProps} />
    </Resizable>
  );
};

class AccountChartPage extends Component {
    constructor(props) {
        super(props);
        let column = [{
            title: 'Account Number',
            dataIndex: 'accountNumber',
            className:'table-heading',
            sorter:  true,
            width:150
        },{
            title: 'Name',
            dataIndex: 'name',
            className:'table-heading',
            sorter:  true,
            width:350


        },{
            title: 'Account Type',
            dataIndex: 'accountType',
            className:'table-heading',
            sorter:  true,
            width:180
        },{
          title: 'Last Reconciled',
          dataIndex: 'lastReconciledTransactionDate',
          className:'table-heading',
          sorter:  true,
          width:150,
          render: text => text!=null?<span title={moment(text).format("LL")}>
          {moment(text).format("L")}
        </span>:'',
      },{
        title: 'Reconciled Through',
        dataIndex: 'reconciledThrough',
        className:'table-heading',
        sorter:  true,
        width:200,
        render: text => text!=null?<span title={moment(text).format("LL")}>
          {moment(text).format("L")}
        </span>:'',
    },{
      title: 'Review Status',
      dataIndex: 'reviewStatus',
      className:'table-heading',
      sorter:  true,
      
  }
        ];
        this.state = {
            pageSize:50,
            currentPage:1,
            totalRowsInTable:0,
            fieldName:'accountId',
            sortFormat:'A',
            columns:column,
            accountsData:[],
            loading:true,
            searchText:'',
            search:false,
            selectedAccount:'',
            isOpen:false,
            isAddOpen:false,
            isEdit:false,
            companyId:''
        }
    }
    componentDidMount() {
       this.listData();
    }
    listData()
    {
      let user = JSON.parse(localStorage.getItem('userData'));
      const CompanyId = user.companyId;
      this.setState({companyId:user.companyId})
      this.props.GetAccounts(CompanyId,this.state.searchText,this.state.currentPage,this.state.pageSize,this.state.fieldName,this.state.sortFormat);
    }
    componentWillReceiveProps(nextProps){
        if(nextProps.accounts.data!==undefined && nextProps.accounts.data !== this.state.accountsData){
          if(nextProps.accounts.data.length>0)
             this.state.totalRowsInTable = nextProps.accounts.data[0].totalrows;
        this.setState({loading:false, accountsData:nextProps.accounts.data});
        }
        if(nextProps.reload==true)
          this.listData();
    }
    handleCancel = () => {
        this.setState({isOpen:false,isEdit:false});
    };

    handleAddModel = () => {
        this.setState({isAddOpen:true,isEdit:false});
    }

    handleEditModal= (record) =>{
        var id=record.key
        var data=this.state.accountsData;
       
        if(id!=0)
         {
            const selectedAccount = _.find( data, {accountID:id});
            
            this.setState({
                selectedAccount:selectedAccount,
                isEdit:true,
            });
        }

    }

    handleChange = (text) => {
      var self=this;
      if(text) {

        self.state.searchText = text;


        } else {
          self.state.searchText = '';

        }
        self.state.currentPage =1;
        this.setState({search:true});

      clearTimeout(self.state.timeout);

      // Make a new timeout set to go off in 800ms
      self.state.timeout = setTimeout(function () {
        self.listData();

      }, 500);

      }
    handleSave = (state) => {
        this.setState({
            isOpen:state,
            isEdit:state
        });
    }
    handleTableChange = (pagination, filters, sorter) => {
      if(sorter.order)
      {
        this.state.fieldName = sorter.field;
        if(sorter.order =="ascend")
          this.state.sortFormat = "A";
        else
          this.state.sortFormat = "D";
      }
      else
      {
        this.state.fieldName = "accountId";
        this.state.sortFormat = "A";
      }
      this.listData();
    }
    onShowSizeChange(current, pageSize) {
		this.state.currentPage = current;
		this.state.pageSize = pageSize;
		this.listData();	  
  }
  handleResize = index => (e, { size }) => {
    this.setState(({ columns }) => {
      const nextColumns = [...columns];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      };
      return { columns: nextColumns };
    });
  }

    render() {
      const { searchText , search} = this.state;
      let { accountsData } = this.state;
      const columns = this.state.columns.map((col, index) => ({
        ...col,
        onHeaderCell: column => ({
          width: column.width,
          onResize: this.handleResize(index),
        }),
      }));
      const components = {
        header: {
          cell: ResizeableTitle,
        },
      };
		if(search)
         accountsData = accountsData.length && accountsData.filter(obj=> {
            if(obj.name.toLowerCase().includes(searchText.toLowerCase()) ===  true) {
               return obj;
            }
        });

        let filteredData = accountsData.length && accountsData.map((d,index)=>({
            key:d.accountID,
            ...d
        }))
        return (
            <div className="vendorsPage" style={{padding:15}}>
             <Grid
               container
               spacing={24}
               style={{ 'marginBottom': 10 }}
             >
                <Grid item xs={12} sm={6}>
                   <h3 className="user-title">Accounts</h3>
                </Grid>
                 <Grid item xs={12} sm={6}>
                <Grid
                 container
                 spacing={8}
                 direction="row"
                 justify="flex-end"
                 alignItems="flex-end"
                >
                <Grid item  >
                <SearchBox
                   styleName="d-none d-lg-block"
                   placeholder="Search... "
                   value={searchText}
                   onChange= { (e) => {  this.handleChange(e.target.value) }}
                   style={{ background: '#eeeeeeb8'}}
                 />
                 </Grid>
                  
            </Grid>
          </Grid>
             </Grid>
              <EditAccountForm
                    isEdit={this.state.isEdit}
                    handleSave={this.handleSave}
                    visible={this.state.isEdit}
                    handleCancel={this.handleCancel}
                    selectedItem={this.state.selectedAccount}
                    />
             {this.state.loading
              ?
               <CircularProgress/>
              :<div>
              <Table style={{maxHeight:'410px',overflowY:'scroll'}}
                  className="table-layout"
                  columns={columns}
                  components={components}
                  dataSource={filteredData}
                  rowClassName={ (record) =>  this.state.selectedItemKey == record.key  ? 'data-row selected' : 'data-row'  }
                  bordered={true}
                  size={"small"}
                  onRowClick={ (record, index, event) => this.handleEditModal(record) }
                  onRowDoubleClick={ (record, index, event) => this.handleEditModal(record) }
                  onChange={this.handleTableChange}
                  loading={this.state.loading}
                  pagination={false}
                  //scroll={{y:window.innerHeight-301}}
                  />
                  <Pagination
                  pageSizeOptions={['10','25','50','100']}
                  pageSize={this.state.pageSize}
                  style={{marginTop:'10px',marginBottom:'10px'}}
                  showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                  showSizeChanger onChange={(a,b)=>this.onShowSizeChange(a,b)} onShowSizeChange={(a,b)=>this.onShowSizeChange(a,b)} defaultCurrent={1} total={this.state.totalRowsInTable} />
                  </div>
            }
            </div>
        );
    }
}

const mapStateToProps=(state)=>{
    return {
        accounts:state.accounts.accountsData,
        reload: state.accounts.reload,
    }
}
const mapDispatchToProps=(dispatch)=>{
    return bindActionCreators({
        GetAccounts,
        searchAccount,
    },dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(AccountChartPage);

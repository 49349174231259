import React, { Component } from 'react';
import { Link } from 'react-router';
import {
    quickbooksIsConnected,
    quickbooksSyncStatus,
    quickbooksSync,
    getTempToken,
    quickbooksLogout,
    quickbookSynClear
} from '../../../../appRedux/actions/QuickbookAction';
import {
    companyDetail, getCompnay,companyStatus
} from '../../../../appRedux/actions/companiesAction';
// import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
// import { ToastContainer } from "react-toastify";
import Select from '@material-ui/core/Select';
import "react-toastify/dist/ReactToastify.css";
import "./style.css";
// import { timingSafeEqual } from 'crypto';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InputLabel from '@material-ui/core/InputLabel';
import Switch from '@material-ui/core/Switch';


class QuickBookPage extends Component {
    constructor(props) {
        super(props);
        var sDate = new Date();
        sDate.setMonth(0);
        sDate.setDate(1);
        sDate.setFullYear(2019);
        var eDate = moment(new Date()).endOf('month');
        eDate = eDate._d;
        this.state = {
            StartDate: sDate,
            EndDate: eDate,
            AccountingMethod: '',
            isSyncing: false,
            syncLedgers: false,
            syncAccounts: false,
            syncVendors: false,
            syncClass: false,
            syncBill: false,
            syncDeposits: false,
            syncPayment: false,
            SyncCustomers: false,
            SyncCompanyInfo: false,
            SaveJsonInFile: false,
            SyncPurchase: false,
            SyncJournalEntry: false,
            syncInvoices: false,
            SyncTrialBalance: false,
            SyncEmployee: false,
            selectAll: false,
            doSync: false,
            grantURl: "",
            CompanyInfo: null,
            AllowEmployee: false,
            connectAndSync:false

        };
        this.sync = this.sync.bind(this);
        // checking new branch commit
        this.handleFormChange = this.handleFormChange.bind(this);
    }

    componentDidMount() {
        const user = JSON.parse(localStorage.getItem('userData'));
        var previousCompanyId = localStorage.getItem("connedtedCompanyId");
        if (previousCompanyId != null && previousCompanyId != "") {
            clearInterval(parseInt(previousCompanyId));
        }
        this.props.quickbooksIsConnected(user.companyId);
        this.props.getTempToken(user.companyId);
        this.props.getCompnay(user.companyId);
        this._isConnectedIntervalId = setInterval(function () {
            this.props.quickbooksIsConnected(user.companyId);
        }.bind(this), 10000);
        localStorage.setItem("connedtedCompanyId", this._isConnectedIntervalId);
    }
    componentWillUnmount() {
        clearInterval(this._isConnectedIntervalId);
        clearInterval(this._syncStatusInvervalId);
        this.props.quickbookSynClear();
    }

    componentWillReceiveProps(nextProps) {
        var self = this;
        
        try {
            if (!nextProps.isConnected && nextProps.tempToken == '') {
                const user = JSON.parse(localStorage.getItem('userData'));
                this.props.getTempToken(user.companyId);
            }
            else if (!nextProps.isConnected && nextProps.tempToken != '') {//&& this.props.tempToken != nextProps.tempToken            
                var script = window.document.createElement("script");
                script.type = "text/javascript";
                script.src = "https://js.appcenter.intuit.com/Content/IA/intuit.ipp.anywhere.js";
                window.document.body.appendChild(script);

                var tempToken = nextProps.tempToken;
                // this.setState({grantURl:nextProps.tempToken});
                // var intervalId = setInterval(function () {
                //     if (!window.intuit) return;
                //     window.intuit.ipp.anywhere.setup({
                //         //grantUrl: 'http:' + window.location.href.substring(5, window.location.href.indexOf('#')) + 'API/quickbooks/OAuth/Grant?temp-auth-token=' + encodeURIComponent(tempToken)

                //         grantUrl: nextProps.tempToken

                //     });
                //     self.props.companyDetail();
                //     clearInterval(intervalId);
                // }, 50);
                this.setState({
                    grantURl: nextProps.tempToken,
                    syncLedgers: false,
                    syncAccounts: false,
                    syncVendors: false,
                    syncClass: false,
                    syncBill: false,
                    syncDeposits: false,
                    syncPayment: false,
                    SyncCustomers: false,
                    SyncCompanyInfo: false,
                    SaveJsonInFile: false,
                    SyncPurchase: false,
                    SyncJournalEntry: false,
                    syncInvoices: false,
                    SyncTrialBalance: false
                });
                const user = JSON.parse(localStorage.getItem('userData'));
                // this.state.doSync = true;
                if(this._isConnectedIntervalId){
                    clearInterval(this._isConnectedIntervalId)
                }
                this._isConnectedIntervalId = setInterval(function () {
                    this.props.quickbooksIsConnected(user.companyId);
                }.bind(this), 10000);
            }
            if (nextProps.syncMessages !== this.props.syncMessages) {
                if (nextProps.syncMessages.Status === "Completed") {
                    this.props.companyDetail();
                    clearInterval(this._syncStatusInvervalId);
                    this.setState({ isSyncing: false });
                }
            }
            if (nextProps.isConnected === true) {
                this.props.companyDetail();
                // clearInterval(this._isConnectedIntervalId);
                if (this.state.connectAndSync === true) {

                    this.syncAll();
                }
                this.state.doSync = false;
                this.state.connectAndSync = false;
            }
            if (nextProps.companies !== this.props.companies) {
                this.setState({ companiesData: nextProps.companies });
            }

            if (nextProps.company !== this.props.company) {
                var allowEmp = false;
                if (nextProps.company.payrollSoftware != null && nextProps.company.payrollSoftware.toLowerCase().indexOf("quickbook") > -1) {
                    allowEmp = true;
                }

                this.setState({...this.state, loading: false, CompanyInfo: nextProps.company, AllowEmployee: allowEmp });
            }
            if (nextProps.reload) {
                this.setState({ loading: false }, () => {
                    this.props.companyDetail();
                });
            };
        }
        catch (err) {

        }
    }
    accountStatusChange = (event) => {
        let isChecked = event.target.checked;
        this.setState(prevState => ({
            CompanyInfo: {
            ...prevState.CompanyInfo,
            isActive: isChecked
          }
        }),()=>{
          let data = {
            "companyId": this.state.CompanyInfo.companyId,
            "status": isChecked
        };
          this.props.companyStatus(data)
          
        }
        
        );
        
        
    }

    handleFormChange = (e, target) => {
        if (!target) {
            this.setState({ [e.target.name]: e.target.value })
        }
        else if (target == "StartDate") {
            this.setState({ StartDate: new Date(e) });
        }
        else if (target == "EndDate") {
            this.setState({ EndDate: new Date(e) });
        }
    }

    handleFormCheched = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    disconnect = (e) => {

        var self = this;
        const user = JSON.parse(localStorage.getItem('userData'));
        self.props.quickbooksLogout(user.companyId);
    };
    syncAll() {
        var self = this;
        const user = JSON.parse(localStorage.getItem('userData'));
        var sync = {
            CompanyId: user.companyId,
            StartDate: moment(self.state.StartDate).format('YYYY-MM-DD'),
            EndDate: moment(self.state.EndDate).format('YYYY-MM-DD'),
            AccountingMethod: self.state.AccountingMethod,
            syncLedgers: true,
            syncAccounts: true,
            syncVendors: true,
            syncClass: true,
            syncInvoices: false,
            syncBill: false,
            syncDeposits: false,
            syncPayment: false,
            SyncCustomers: true,
            SyncCompanyInfo: true,
            SaveJsonInFile: true,
            SyncPurchase: false,
            SyncJournalEntry: false,
            SyncTrialBalance: true,
            SyncEmployee: this.state.AllowEmployee
        }

        this.props.quickbooksSync(sync);
        this._syncStatusInvervalId = setInterval(function () {
            this.props.quickbooksSyncStatus();
        }.bind(this), 10000);
        this.setState({ isSyncing: true });
    }
    sync() {
        var self = this;
        const user = JSON.parse(localStorage.getItem('userData'));
        var sync = {
            CompanyId: user.companyId,
            StartDate: moment(self.state.StartDate).format('YYYY-MM-DD'),
            EndDate: moment(self.state.EndDate).format('YYYY-MM-DD'),
            AccountingMethod: self.state.AccountingMethod,
            syncLedgers: self.state.syncLedgers,
            syncAccounts: self.state.syncAccounts,
            syncVendors: self.state.syncVendors,
            syncClass: self.state.syncClass,
            syncInvoices: self.state.syncInvoices,
            syncBill: self.state.syncBill,
            syncDeposits: self.state.syncDeposits,
            syncPayment: self.state.syncPayment,
            SyncCustomers: self.state.SyncCustomers,
            SyncCompanyInfo: self.state.SyncCompanyInfo,
            SaveJsonInFile: false,
            SyncPurchase: self.state.SyncPurchase,
            SyncJournalEntry: self.state.SyncJournalEntry,
            SyncTrialBalance: false,
            SyncEmployee: this.state.AllowEmployee ? self.state.SyncEmployee : false
        }
        this.props.quickbooksSync(sync);
        this._syncStatusInvervalId = setInterval(function () {
            this.props.quickbooksSyncStatus();
        }.bind(this), 10000);
        this.setState({ isSyncing: true });
    }

    openConnection = (e) => {
        e.preventDefault();
        this.setState({ connectAndSync: true });
        window.open(this.state.grantURl, 'targetWindow',
        `toolbar=no,
        location=no,
        status=no,
        menubar=no,
        scrollbars=yes,
        resizable=yes,
        width=600px,
        height=550px`)
    }
    render() {
        const { userData } = this.props;
        var self = this;
        var connectionStatus = this.props.isConnected
            ?
            <> 
                <h3><b>Make Inactive : </b><Switch className={this.state.CompanyInfo ?this.state.CompanyInfo.isActive ? 'switch-box-blue' : 'switch-box-blue switch-inactive' : ''} size="small"
                checked={this.state.CompanyInfo ?this.state.CompanyInfo.isActive :false} onChange={(e) => this.accountStatusChange(e)} /></h3>

                <span style={{ color: '#46B29D' }}> You are Connected </span>
            </>
            :
            
            <>
               <h3><b>Make Inactive : </b><Switch className={this.state.CompanyInfo ?this.state.CompanyInfo.isActive ? 'switch-box-blue' : 'switch-box-blue switch-inactive' : ''} size="small"
                checked={this.state.CompanyInfo ?this.state.CompanyInfo.isActive :false} onChange={(e) => this.accountStatusChange(e)} /></h3>

            <a href="#" onClick={(e) => this.openConnection(e)}> <img src="/vendors/C2QB_green_btn_tall_hover.png" title="connect" /> </a>
            </>
        var syncControl = this.props.isConnected
            ?

            <Grid container spacing={40} direction="column" >
                <form style={{ padding: 20, border: '1px solid #eee', 'boxShadow': 'inset 0 0 0 0 #e2e2e200' }}>
                    <fieldset>
                    <h3> <b>QB Company Id : </b> {this.state.CompanyInfo ? this.state.CompanyInfo.realmID:``} </h3>
                    <h3> <b>QB Company Name : </b> {this.state.CompanyInfo ? this.state.CompanyInfo.name:``} </h3>
                        General Ledger Custom Dates (Optional) <Checkbox
                            name="SelectAll"
                            value={this.state.selectAll}
                            checked={this.state.selectAll}
                            onChange={(e) => {
                                this.setState({
                                    syncLedgers: e.target.checked,
                                    syncAccounts: e.target.checked,
                                    syncVendors: e.target.checked,
                                    syncClass: e.target.checked,
                                    syncBill: e.target.checked,
                                    syncDeposits: e.target.checked,
                                    syncPayment: e.target.checked,
                                    SyncCustomers: e.target.checked,
                                    SyncCompanyInfo: e.target.checked,
                                    SaveJsonInFile: e.target.checked,
                                    SyncPurchase: e.target.checked,
                                    SyncJournalEntry: e.target.checked,
                                    syncInvoices: e.target.checked,
                                    SyncTrialBalance: e.target.checked,
                                    SyncEmployee: e.target.checked,
                                    selectAll: e.target.checked
                                });
                            }}
                        />
                        <label>Select All</label>
                        <Grid container spacing={24} >
                            <Grid item sm={4} >
                                <InputLabel className="labletext">Start Date</InputLabel>
                                <DatePicker
                                    selected={this.state.StartDate}
                                    onChange={(e) => this.handleFormChange(e, 'StartDate')}
                                    label="Start Date"
                                    id="date"
                                    name="StartDate"
                                    margin="normal"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item sm={4} >
                                <InputLabel className="labletext">End Date</InputLabel>
                                <DatePicker
                                    selected={this.state.EndDate}
                                    onChange={(e) => this.handleFormChange(e, 'EndDate')}
                                    label="End Date"
                                    id="date"
                                    name="EndDate"
                                    margin="normal"
                                    fullWidth
                                />

                            </Grid>
                            <Grid item sm={4} >
                                <Select
                                    name="AccountingMethod"
                                    native
                                    required={true}
                                    label={"Accounting Method"}
                                    fullWidth
                                    onChange={this.handleFormChange}
                                    style={{ 'paddingTop': '30px' }}
                                >
                                    {/* <option value={'Cash'}>Cash</option> */}
                                    <option value={'Accrual'}>Accrual</option>
                                </Select>
                            </Grid>

                        </Grid>
                        <Grid container spacing={24} >
                            <Grid item sm={4}>
                                <Checkbox
                                    name="syncAccounts"
                                    value={this.state.syncAccounts}
                                    checked={this.state.syncAccounts}
                                    onChange={this.handleFormCheched('syncAccounts')}
                                />
                                <label>Accounts</label>
                            </Grid>
                            <Grid item sm={4}>
                                <Checkbox
                                    name="syncLedgers"
                                    value={this.state.syncLedgers}
                                    checked={this.state.syncLedgers}
                                    onChange={this.handleFormCheched('syncLedgers')}
                                />
                                <label>Ledgers</label>
                            </Grid>
                            <Grid item sm={4}>
                                <Checkbox
                                    name="syncVendors"
                                    value={this.state.syncVendors}
                                    checked={this.state.syncVendors}
                                    onChange={this.handleFormCheched('syncVendors')}

                                />
                                <label>Vendors </label>
                            </Grid>
                            {/* <Grid item sm={4}>
                                <Checkbox
                                    name="syncBill"
                                    value={this.state.syncBill}
                                    checked={this.state.syncBill}
                                    onChange={this.handleFormCheched('syncBill')}
                                />
                                <label>Bill</label>
                            </Grid> */}
                            {/* <Grid item sm={4}>
                                <Checkbox
                                    name="syncDeposits"
                                    value={this.state.syncDeposits}
                                    checked={this.state.syncDeposits}
                                    onChange={this.handleFormCheched('syncDeposits')}
                                />
                                <label>Deposits</label>
                            </Grid> */}
                        </Grid>
                        <Grid container spacing={24} >
                            {/* <Grid item sm={4}>
                                <Checkbox
                                    name="syncInvoices"
                                    value={this.state.syncInvoices}
                                    checked={this.state.syncInvoices}
                                    onChange={this.handleFormCheched('syncInvoices')}
                                />
                                <label>Invoices</label>
                            </Grid> */}

                            {/* <Grid item sm={4}>
                                <Checkbox
                                    name="syncPayment"
                                    value={this.state.syncPayment}
                                    checked={this.state.syncPayment}
                                    onChange={this.handleFormCheched('syncPayment')}
                                />
                                <label>Payments</label>
                            </Grid> */}
                        </Grid>
                        <Grid container spacing={24} >

                            {/* <Grid item sm={4}>
                                <Checkbox
                                    name="SyncPurchase"
                                    value={this.state.SyncPurchase}
                                    checked={this.state.SyncPurchase}
                                    onChange={this.handleFormCheched('SyncPurchase')}
                                />
                                <label>Purchase</label>
                            </Grid>
                            <Grid item sm={4}>
                                <Checkbox
                                    name="SyncJournalEntry"
                                    value={this.state.SyncJournalEntry}
                                    checked={this.state.SyncJournalEntry}
                                    onChange={this.handleFormCheched('SyncJournalEntry')}
                                />
                                <label>Journal Entry</label>
                            </Grid> */}

                        </Grid>
                        <Grid container spacing={24} >
                            <Grid item sm={4}>
                                <Checkbox
                                    name="SyncCustomers"
                                    value={this.state.SyncCustomers}
                                    checked={this.state.SyncCustomers}
                                    onChange={this.handleFormCheched('SyncCustomers')}
                                />
                                <label>Customers </label>
                            </Grid>
                            <Grid item sm={4}>
                                <Checkbox
                                    name="SyncCompanyInfo"
                                    value={this.state.SyncCompanyInfo}
                                    checked={this.state.SyncCompanyInfo}
                                    onChange={this.handleFormCheched('SyncCompanyInfo')}
                                />
                                <label>Company Info</label>
                            </Grid>
                            <Grid item sm={4}>
                                <Checkbox
                                    name="SaveJsonInFile"
                                    value={this.state.SaveJsonInFile}
                                    checked={this.state.SaveJsonInFile}
                                    onChange={this.handleFormCheched('SaveJsonInFile')}
                                />
                                <label>Save Json InFile</label>
                            </Grid>
                        </Grid>
                        {this.state.AllowEmployee &&
                            <Grid container spacing={24}>
                                <Grid item sm={4}>
                                    <Checkbox
                                        name="SyncEmployee"
                                        value={this.state.SyncEmployee}
                                        checked={this.state.SyncEmployee}
                                        onChange={this.handleFormCheched('SyncEmployee')}

                                    />
                                    <label>Employees </label>
                                </Grid>
                            </Grid>
                        }
                    </fieldset>
                </form>
                <Grid item sm={12}>

                    <Button style={{ width: 200 }} disabled={this.state.isSyncing}
                        onClick={this.sync} variant="contained" color="primary">{this.state.isSyncing ? 'Syncing...' : 'Sync Data'}</Button>
                    <Button style={{ width: 200, marginLeft: 20 }}
                        onClick={self.disconnect} variant="contained" color="primary">Disconnect</Button>

                </Grid>
            </Grid> : '';

        var syncMessages = (this.props.syncMessages.Messages || []).map(function (sm) {
            return <li href="#" className="list-group-item list-group-item-info">
                <h4 className="list-group-item-heading">{sm.Date != null ? moment(sm.Date).format('MM/DD/YYYY') : ''}</h4>

                <p className="list-group-item-text">{sm.Message}</p>
            </li>;
        });
        return (
            <div className="dashboard-companies-pagequick" style={{ 'padding': 20 }}>
                <Grid container spacing={24} direction="row" style={{ 'marginBottom': 20 }}>
                    <Grid item xs={12} sm={4} >
                        {connectionStatus}
                    </Grid>
                </Grid>
                <Grid container spacing={24} direction="row">
                    <Grid item xs={12} sm={6} >
                        {syncControl}
                    </Grid>
                    <Grid item xs={12} sm={6} className="syncScroll" >
                        {this.state.isSyncing ? '' : syncMessages}
                    </Grid>
                </Grid>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        isConnected: state.quickbook.isConnected,
        syncMessages: state.quickbook.syncMessages,
        tempToken: state.quickbook.tempToken,
        company: state.companyReducer.companyDeatils,
        reload: state.companyReducer.reload,
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        quickbooksIsConnected, quickbooksSyncStatus, quickbooksSync, getTempToken, quickbooksLogout, companyDetail, quickbookSynClear,
        getCompnay,companyStatus
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(QuickBookPage)

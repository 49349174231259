import * as QuickbookService from '../services/QuickbookService';
export const QB_IS_COONNECTED = 'QB_IS_COONNECTED';
export const QB_SYNC = 'QB_SYNC';
export const QB_SYNC_STATUS = 'QB_SYNC_STATUS';
export const QB_TEMP_TOKEN = 'QB_TEMP_TOKEN';
export const TOKEN_SET = 'TOKEN_SET';
export const QB_LOGOUT = 'QB_LOGOUT';
export const QB_CLEAR = 'QB_CLEAR';


export const quickbooksIsConnected = (company) => {
    return (dispatch) => {
        QuickbookService.isConnected(company)
            .then((response) => {
                dispatch({ type: QB_IS_COONNECTED, payload: response });
            })
            .catch((res) => {

            })
    }
}

export const quickbooksIsConnectedCheck = (company) => {

    return QuickbookService.isConnected(company);
}
export const clearsynmessage = () => {
    return (dispatch) => {
        // QuickbookService.LedgerDetailService()
        //     .then((response)=>{
        //         dispatch({type: LEDGER_GET,payload: response});
        //     })
        //     .catch((res)=>{
        //
        //     })
    }
}
export const quickbooksLogout = (company) => {
    return (dispatch) => {
        QuickbookService.quickbooksLogout(company)
            .then((response) => {
                dispatch({ type: QB_LOGOUT, payload: response });
            })
            .catch((res) => {

            })
    }
}
export const getTempToken = (id) => {
    return (dispatch) => {
        dispatch({ type: TOKEN_SET });
        QuickbookService.getTempToken(id)
            .then(response => {
                dispatch({ type: QB_TEMP_TOKEN, payload: response.data });
            })
            .catch((res) => {

            })
    }
}

export const quickbooksSync = (sync) => {
    return (dispatch) => {
        QuickbookService.quickbooksSync(sync)
            .then((response) => {
                dispatch({ type: QB_SYNC, payload: response });
            })
            .catch((res) => {

            })
    }
}
export const quickbooksSyncA = (sync) => {

    return QuickbookService.quickbooksSync(sync);

}
export const quickbookSynClear = () => {
    return (dispatch) => {
        dispatch({ type: QB_CLEAR });
    }
}
export const quickbooksSyncStatus = () => {
    return (dispatch) => {
        QuickbookService.quickbooksSyncStatus()
            .then((response) => {
                dispatch({ type: QB_SYNC_STATUS, payload: response });
            })
            .catch((res) => {

            })
    }
}
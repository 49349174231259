import React from 'react';
import { toast } from "react-toastify";
import * as CommentService from './../services/CommentService';
export const GET_COMMENTS = 'GET_COMMENTS';
export const ADD_COMMENT = "ADD_COMMENT";
export const ADD_CLIENTCOMMENT = "ADD_CLIENTCOMMENT";
export const GET_HISTORY = "GET_HISTORY";
export const COMMENT_MARK_AS_UNREAD = "COMMENT_MARK_AS_UNREAD";
export const RELOAD = 'RELOAD';

export const GET_COMMENTS_DATA = (data) => {

    return (dispatch) => {
        CommentService.CommentsData(data)
            .then((response) => {
                dispatch({ type: GET_COMMENTS, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`);
            });
    }
}

export const GET_COMMENTS_DATA_BES = (data) => {

    return (dispatch) => {
        CommentService.CommentsData_BES(data)
            .then((response) => {
                dispatch({ type: GET_COMMENTS, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`);
            });
    }
}

export const ADD_COMMENTS_DATA = (data) => {

    return (dispatch) => {
        CommentService.AddComment(data)
            .then((response) => {
                dispatch({ type: GET_COMMENTS, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`);
            })
    }
}

export const ADD_CLIENTCOMMENTS_DATA = (data) => {

    return (dispatch) => {
        CommentService.AddClientComment(data)
            .then((response) => {
                dispatch({ type: GET_COMMENTS, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`);
            });
    }
}

export const Post_Comment_unread = (data) => {

    return (dispatch) => {
        CommentService.Comment_unread(data)
            .then((response) => {
                dispatch({ type: COMMENT_MARK_AS_UNREAD, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`);
            });
    }
}

export const Post_Comment_unread_BES = (data) => {

    return (dispatch) => {
        CommentService.Comment_unread_BES(data)
            .then((response) => {
                dispatch({ type: COMMENT_MARK_AS_UNREAD, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`);
            });
    }
}

export const GET_HISTORY_DATA = (data) => {

    return (dispatch) => {
        CommentService.GetHistory(data)
            .then((response) => {
                dispatch({ type: GET_HISTORY, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`);
            });
    }
}
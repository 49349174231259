import {PROFILE_GET,PROFILE_UPDATE,PROFILE_LOAD} from '../actions/userAction';
const initial_state = {
    loading:false,
    profile:{},
    profileImage:""
};
export default function userReducer(state = initial_state, action) {
    switch (action.type) {
        case PROFILE_GET:
        return {
            ...state,
            profile:action.payload
        };
        case PROFILE_LOAD:
        return {
            ...state,
            loading:true
        };
        case PROFILE_UPDATE:
        return {
            ...state,
            loading:false
        };
        case 'PROFILE_IMAGE_SUCCESS':
        var img = "";
        if(action.payload && action.payload.length > 0)
            img = action.payload[0].ProfileImage;
            
        return {
            ...state,
            profileImage:img
        };      
        default:
            return state;
    }
}

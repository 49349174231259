import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CircularProgress from './../../../../components/CircularProgress';
import { toast } from 'react-toastify';
import PaginationComponent from './../../../common/Pagination';
import { GetExcludedAccRecCompany } from '../../../../appRedux/actions/ReportDetailAction';
import { GET_AccountReconciliations, POST_updatecustomfield, AddExcludedAccountReconciliationQuestionsAndComments } from '../../../../appRedux/actions/ReconcillitionAction';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import TextArea from 'antd/lib/input/TextArea';

const FontTooltip = withStyles((theme) => ({
    tooltip: {
        fontSize: 12
    },
}))(Tooltip);

class AccountReconciliationCompanies extends Component {

    constructor(props) {
        super(props);

        this.state = {
            parantData: this.props.location.state ? this.props.location.state : null,
            MissingData: [],
            companyId: 0,
            PageNo: 1,
            PageSize: 10,
            ColorCode: "#46b29d",
            loading: false,
            TotalRecords: 0,
            ExpandCompanyId: -1,
            ExpandedList: [],
            bulKOpen: -1,
            editIndex: -1,
            isEdited: false,
            editedData: null
        }
    }

    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('userData'));
        this.setState({
            companyId: user.companyId,
            loading: true
        }, () => {
            this.GetReportData();
        });
    }
    onRowExpandCollapseClick = (data) => {
        // if (this.state.ExpandCompanyId === data.companyID) {
        //     this.setState({ ExpandCompanyId: -1 });
        // }
        // else {
        //     this.setState({ ExpandCompanyId: data.companyID, loading: true }, () => { this.LoadAccountReconcillition(); });
        // }

        let openList = this.state.ExpandedList;
        if (openList.indexOf(data.companyID) > -1) {
            openList.splice(openList.indexOf(data.companyID), 1);
        }
        else {
            openList.push(data.companyID);
        }

        this.setState({ ExpandedList: openList, bulKOpen: -1 });
    }

    onExpandAllChange = (isChecked) => {
        let data = this.state.MissingData;

        var openList = [];
        for (let i = 0; i < data.length; i++) {
            openList.push(data[i].companyID);
        }
        this.setState({ ExpandedList: openList, bulKOpen: 2 })
    }
    onCollapseAllChange = (isChecked) => {
        this.setState({ ExpandedList: [], bulKOpen: 1 })
    }

    onCheckboxClick = (e, j, k, item) => {
        var data = { ...this.state.lst_reconData };
        data.accountlist[j].data[k].isExcludedReconciliation = e.target.checked ? true : false;
        var obj = {
            "accountId": data.accountlist[j].data[k].accountID,
            "reconciledThrough": null,
            "isExcludedReconciliation": e.target.checked ? true : false
        }

        this.setState({ loading: true, editData: data.accountlist[j].data[k] }, () => {
            this.props.POST_updatecustomfield(obj);
        });

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.ReportData !== this.props.ReportData) {
            if (nextProps.ReportData.transactionStatus === 0) {
                var totalrecords = 0;
                if (nextProps.ReportData.returnObject != null && nextProps.ReportData.returnObject.length > 0) {
                    totalrecords = nextProps.ReportData.returnObject[0].totalrows
                }


                this.setState({ TotalRecords: totalrecords, MissingData: nextProps.ReportData.returnObject, loading: false, editedData: null, editIndex: -1, isEdited: false }, () => {
                });
                //PageSize: this.state.PageSize, PageNo: this.state.PageNo,


            }
            else {
                toast.error(nextProps.ReportData.resultMsg);
                this.setState({ loading: false, editedData: null, editIndex: -1, isEdited: false });
            }
        }
        if (nextProps.reconcillition !== undefined && nextProps.reconcillition !== this.props.reconcillition) {
            if (nextProps.reconcillition.transactionStatus !== undefined && nextProps.reconcillition.transactionStatus == 0) {
                var accountlist = nextProps.reconcillition.returnObject;
                var data = {
                    accountlist: accountlist,
                    // lastQuaterAllBankAccount: { key: 'Last quater All Bank Account Reconcilled', value: '' },
                    // allCheckingReconThrogh: { key: 'having all checking account been reconcilled ', value: '' }
                }
                if (data.accountlist != null) {
                    this.setState({ lst_reconData: data, loading: false, editedData: null, editIndex: -1, isEdited: false });
                }
                else {
                    this.setState({ loading: false, editedData: null, editIndex: -1, isEdited: false });
                }

            } else {
                this.setState({ loading: false, editedData: null, editIndex: -1, isEdited: false }, () => {
                    toast.error(`${nextProps.reconcillition.resultMsg}`);
                });
            }
        }
        if (nextProps.Update_reconciled !== undefined && nextProps.Update_reconciled !== this.props.Update_reconciled) {
            var returnObj = nextProps.Update_reconciled;
            if (returnObj.transactionStatus == 0) {
                toast.success(`${returnObj.resultMsg}`);
                this.GetReportData();
                this.LoadAccountReconcillition();
            } else {
                this.setState({ loading: false, editedData: null, editIndex: -1, isEdited: false }, () => {
                    toast.error(`${returnObj.resultMsg}`);
                });
            }
        }

        if (nextProps.Update_reconciled_CustomField !== undefined && nextProps.Update_reconciled_CustomField !== this.props.Update_reconciled_CustomField) {
            debugger;
            var returnObj = nextProps.Update_reconciled_CustomField;
            if (returnObj.transactionStatus == 0) {
                toast.success(`${returnObj.resultMsg}`);
                this.GetReportData();
                // this.LoadAccountReconcillition();
            } else {
                this.setState({ loading: false, editedData: null, editIndex: -1, isEdited: false }, () => {
                    toast.error(`${returnObj.resultMsg}`);
                    this.GetReportData();
                });
            }
        }
    }
    GetReportData(IsRefresh = false) {
        let state = this.state;
        this.props.GetExcludedAccRecCompany(state.PageNo, state.PageSize, true);
    }


    LoadAccountReconcillition = () => {

        this.setState({ loading: true }, () => {
            this.props.GET_AccountReconciliations(this.state.ExpandCompanyId, true);
        })

    }


    onRefreshDataClick = () => {
        if (!this.state.loading) {
            this.setState({ loading: true }, () => {
                this.GetReportData(true);
            });
        }
    }

    ChangePageSize = (size) => {

        this.setState({ loading: true, PageSize: parseInt(size), PageNo: 1 }, () => {
            this.GetReportData();
        });
    }

    ChangePage = (page) => {
        this.setState({ loading: true, PageNo: page, }, () => {
            this.GetReportData();
        });
    }

    onRowEdit = (i, companyData) => {
        // var companyData = this.state.MissingData[i];
        // let item = { AccountId: data.accountID, Notes: data.comments, questionID: data.questionId, question: data.question };
        this.setState({ editedData: companyData, editIndex: i, isEdited: true });
    }

    onRowCancelClick = () => {
        this.setState({
            loading: true
        }, () => {
            this.GetReportData();
        });
        // this.setState({ editedData: null, editIndex: -1, isEdited: false });
    }

    onRowUpdateClick = () => {
        debugger;
        let updationData = this.state.editedData;
        if (updationData != null) {
            let arrayData = [];
            updationData.report.map((mainData, j) => (
                mainData.data.map((chlditem, k) => (
                    arrayData.push({ "accountId": chlditem.accountID, "comments": chlditem.comments, "questionId": chlditem.questionId, "question": chlditem.question })
                ))
            ));

            if (arrayData != null && arrayData.length > 0) {
                let finalData = arrayData.filter(x => x.question || x.questionID || x.comments || !x.comments);
                if (finalData != null && finalData.length > 0) {
                    let body = {
                        "companyId": updationData.companyID,
                        "lstAccountQuestionsAndComments": finalData
                    }

                    this.setState({ loading: true }, () => {
                        this.props.AddExcludedAccountReconciliationQuestionsAndComments(body);
                    });
                }else{
                    toast.error("Please fill at least one transaction Notes or Questions & Comments.");
                }
            }
        }
    }

    handleNote = (event, i, j, k) => {
        debugger;
        let companyData = this.state.editedData;
        companyData.report[j].data[k].comments = event.target.value;
        this.setState({ editedData: companyData });
    }

    handleQuestion = (event, i, j, k) => {
        let companyData = this.state.editedData;
        companyData.report[j].data[k].question = event.target.value;
        this.setState({ editedData: companyData });
    }

    render() {

        let expandedData = this.state.ExpandedList;
        let openOption = this.state.bulKOpen;
        return (
            <div className="dashboardPage">
                <div className="page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
                    <h2 className="mb-3 mb-sm-0">Account Reconciliation – Excluded Accounts</h2>
                </div>

                <div className="padding-box app-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="button-right float-right">

                                {this.state.isEdited === true &&
                                    <React.Fragment>
                                        <Button variant="raised" className=" jr-btn g-teal  text-white refresh-btn graycolor" onClick={() => this.onRowUpdateClick()}>Update</Button>
                                        <Button variant="raised" className=" jr-btn g-teal  text-white refresh-btn redishOrangecolor" onClick={() => this.onRowCancelClick()}>Cancel</Button>
                                    </React.Fragment>
                                }


                                <Button variant="raised" className="jr-btn bg-teal text-white refresh-btn greencolor" onClick={() => this.onRefreshDataClick()}   ><i className="zmdi zmdi-refresh "></i> Refresh Data</Button>
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive-material">
                        <table className="table tablebox account-reconcilliation">
                            <thead  >
                                <tr style={{ backgroundColor: this.state.ColorCode }}>
                                    <th className="td-width" ><p> Company</p> </th>
                                    <th className="td-width" ><p > No. Excluded Accounts</p></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.loading ? <CircularProgress />
                                    :
                                    <React.Fragment key={this.state.MissingData.length + "totalRecord" + this.state.PageNo}>
                                        {this.state.MissingData.map((item, i) => (
                                            <React.Fragment key={i}>
                                                <tr data-id={item.companyID} className="bg-active" onClick={() => this.onRowExpandCollapseClick(item)} >
                                                    < td>
                                                        {(expandedData != null && expandedData.length > 0 && expandedData.indexOf(item.companyID) > -1) ?
                                                            <span className="title-name">
                                                                <i className="zmdi zmdi-minus-circle-outline"></i>
                                                            </span>
                                                            :
                                                            <span className="title-name">
                                                                <i className="zmdi zmdi-plus-circle-o"></i>
                                                            </span>
                                                        }

                                                        &nbsp;&nbsp;
                                                        {item.companyName}</td>
                                                    <td>{item.excludedAccountCount}</td>
                                                </tr>

                                                {expandedData != null && expandedData.length > 0 && expandedData.indexOf(item.companyID) > -1 &&
                                                    <tr>
                                                        <td colSpan="2">
                                                            <table className="table" >
                                                                <thead className="panel-bg white_header_text">
                                                                    <tr >
                                                                        <th style={{width:"15%"}}>Accounts</th>
                                                                        <th className="text-center" style={{width:"10%"}}>Last Reconciled <br></br> Transaction</th>
                                                                        <th style={{width:"22%"}}>Notes</th>
                                                                        <th style={{width:"22%"}}>Questions &<br></br> Comments</th>
                                                                        <th className="text-center" style={{width:"11%"}}>Exclude From Account <br></br> Reconciliation Review</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {item.report != null && item.report.length > 0 && item.report.map((mainData, j) => (
                                                                        <React.Fragment key={j}>
                                                                            {mainData.accountType &&
                                                                                <tr>
                                                                                    <td className="table-sub-title" colSpan="7">{mainData.accountType}</td>
                                                                                </tr>
                                                                            }
                                                                            {mainData.data.map((chlditem, k) => (
                                                                                <React.Fragment key={k}>
                                                                                    {
                                                                                        <tr onClick={(e) => this.onRowEdit(i, this.state.MissingData[i])}>
                                                                                            <td> {chlditem.accountName}</td>
                                                                                            <td className="text-center">{chlditem.lastReconciledTransection}</td>
                                                                                            <td>{chlditem.comments}</td>
                                                                                            <td>{chlditem.question}</td>
                                                                                            <td className="text-center">
                                                                                                {chlditem.isExcludedReconciliation ?
                                                                                                    <input checked="checked" className="second-check-box" type="checkbox" onClick={(e) => this.onCheckboxClick(e, j, k, item)} />
                                                                                                    :
                                                                                                    <input className="second-check-box" type="checkbox" onClick={(e) => this.onCheckboxClick(e, j, k, item)} />
                                                                                                }
                                                                                            </td>
                                                                                        </tr>
                                                                                    }

                                                                                    {this.state.editIndex === i &&
                                                                                        <tr>
                                                                                            <td colSpan="2"></td>
                                                                                            <td>
                                                                                                Notes:
                                                                                                <TextArea placeholder="Enter Notes" onChange={(e) => this.handleNote(e, i, j, k)} value={this.state.editedData.report[j].data[k].comments} className="textarea-box" >
                                                                                                </TextArea>
                                                                                            </td>
                                                                                            <td>
                                                                                                Questions and Comments:
                                                                                                <TextArea placeholder="Enter Questions" onChange={(e) => this.handleQuestion(e, i, j, k)} value={this.state.editedData.report[j].data[k].question} className="textarea-box" >
                                                                                                </TextArea>
                                                                                            </td>
                                                                                            <td style={{ verticalAlign: "middle" }}>
                                                                                                <div className="col-md-12">
                                                                                                    {/* <Button variant="raised" className="jr-btn jr-btn-sm bg-teal text-white" onClick={() => this.onRowUpdateClick()}>SAVE</Button> */}
                                                                                                    <Button variant="raised" style={{ marginTop: "15px" }} className="jr-btn jr-btn-sm bg-danger text-white" onClick={() => this.onRowCancelClick()}>CANCEL</Button>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>}
                                                                                </React.Fragment>
                                                                            ))}
                                                                        </React.Fragment>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                }
                                            </React.Fragment>
                                        ))}
                                        {this.state.MissingData.length == 0 &&
                                            <tr>
                                                <td className="text-center" colSpan="2">
                                                    No data found
                                                </td>
                                            </tr>}
                                    </React.Fragment>
                                }
                            </tbody>
                        </table>
                    </div>
                    <PaginationComponent
                        key={this.state.MissingData.length + "totalRecord" + this.state.PageNo}
                        selectedPageNo={this.state.PageNo}
                        selectedSize={this.state.PageSize}
                        TotalRecords={this.state.TotalRecords}
                        ChangePageSize={this.ChangePageSize}
                        ChangePage={this.ChangePage}
                        CurrentRecords={this.state.MissingData.length}
                    >
                    </PaginationComponent>
                    {!this.state.loading &&
                        <div className="sticky-button">
                            <FontTooltip id="tooltip-icon" title="Collapse All" placement="top">
                                <Button variant="raised" className={`jr-btn text-white sticky-btn collapse-btn ${openOption === 1 ? "bg-teal" : "bg-blue-grey"}`}
                                    onClick={() => this.onCollapseAllChange(false)}>
                                    <img src="/vendors/Collapse.png" alt=""></img>
                                </Button>
                            </FontTooltip>
                            <FontTooltip id="tooltip-icon" title="Expand All" placement="bottom">
                                <Button variant="raised" className={`jr-btn text-white sticky-btn expand-btn ${openOption === 2 ? "bg-teal" : "bg-blue-grey"}`}
                                    onClick={() => this.onExpandAllChange(true)}>
                                    <img src="/vendors/Expand.png" alt=""></img>
                                </Button>
                            </FontTooltip>
                        </div>}
                </div>
            </div>
        )

    }

}

const mapStateToProps = (state) => {
    return {
        ReportData: state.ReportData.ReportData,
        reconcillition: state.reconcillition.reconcillition,
        Update_reconciled_CustomField: state.reconcillition.Update_reconciled_CustomField
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        GetExcludedAccRecCompany,
        GET_AccountReconciliations,
        POST_updatecustomfield,
        AddExcludedAccountReconciliationQuestionsAndComments
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(AccountReconciliationCompanies);
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { insertCompanies } from '../appRedux/actions/companiesAction';
import { findIndex } from "lodash";
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import RSelect from 'react-select';
import Checkbox from '@material-ui/core/Checkbox';

const theme = {
    'border-color': 'hsl(0, 0%, 58%)',
    'border-bottom': 'solid',
    'border-width': 1
}

class AddCompnayForm extends React.Component {

    constructor() {
        super()
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let year = currentYear + 77; year >= currentYear - 50; year--) {
            years.push(year);
        }
        this.years = years
        this.state = {
            country: '',
            name: '',
            state: '',
            city: '',
            postalCode: '',
            eiNnumber:'',
            address: '',
            mpwAccountantEmail: '',
            infusionsoftContactId: '',
            accountingMethod: '',
            error: false,
            isFullServiceBookkeeping: false,
            isReligeousOrganization: false,
            QuickbooksDataSyncBeginningYear: null
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
    }

    handleCancel = () => {
        this.setState({
            name: '',
            state: '',
            city: '',
            postalCode: '',
            eiNnumber:'',
            address: '',
            mpwAccountantEmail: '',
            infusionsoftContactId: '',
            accountingMethod: '',
            error: false,
            isFullServiceBookkeeping: false,
            isReligeousOrganization: false,
            QuickbooksDataSyncBeginningYear: null
        });

        this.props.handleCancel()
    };

    handleSubmit = (e) => {
        
        e.preventDefault();
        let data = {
            "name": this.state.name,
            "state": this.state.state,
            "city": this.state.city,
            "postalCode": this.state.postalCode,
            "eiNnumber": this.state.eiNnumber,
            "address": this.state.address,
            "companyId": 0,
            "quickbookOnline": true,
            "accountantId": 0,
            "mpwAccountantEmail": this.state.mpwAccountantEmail,
            "infusionsoftContactId": this.state.infusionsoftContactId,
            "isActive": true,
            "isFullServiceBookkeeping": this.state.isFullServiceBookkeeping,
            "isReligeousOrganization": this.state.isReligeousOrganization,
            "QuickbooksDataSyncBeginningYear": this.state
                .QuickbooksDataSyncBeginningYear
                ? this.state.QuickbooksDataSyncBeginningYear.toString()
                : null

        }
        this.props.nextForm(data);
    }


    handleCompChange = (state) => {
        this.setState({ state: state, err: false });
    }

    handleFormChange = (e) => {
        this.setState({ [e.target.name]: e.target.value, error: false })
    }

    handleFullServiceBookkeepingChecked = (e) => {
        this.setState({ isFullServiceBookkeeping: e.target.checked });
    }

    handleReligeousOrganizationChecked = (e) => {
        this.setState({ isReligeousOrganization: e.target.checked });
    }
    handleBeginningYearChange = (e) => {
        console.log("e", e.target.value);
        this.setState({ QuickbooksDataSyncBeginningYear: e.target.value });
    };

    componentDidUpdate() {

        if (this.props.cancel == true) {
            this.handleCancel()
        }
    }

    render() {
        const { visible, states, cancel } = this.props;
        const { error, selectedState } = this.state;

        const options = states.map(data => {
            return {
                value: data.stateVchID,
                label: data.vchStateLongName
            }
        });

        return (
            <Dialog
                maxWidth="md"
                fullWidth={true}
                open={visible}
            >
                <form method="post" onSubmit={this.handleSubmit} autoComplete="off" style={{ padding: 10 }}>
                    <DialogTitle>Add Company Details</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={24} >
                            <Grid item sm={4}>
                                <TextField
                                    name="name"
                                    required={true}
                                    error={error}
                                    label={"Company Name"}
                                    fullWidth
                                    onChange={this.handleFormChange}
                                    defaultValue={this.state.name}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item sm={4} >
                                <TextField
                                    name="address"
                                    label="Legal Address"
                                    fullWidth

                                    error={error}
                                    onChange={this.handleFormChange}
                                    defaultValue={this.state.address}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item sm={4}>
                                <TextField
                                    name="city"

                                    error={error}
                                    label={"City"}
                                    fullWidth
                                    onChange={this.handleFormChange}
                                    defaultValue={this.state.city}
                                    margin="normal"
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={24} >
                            <Grid item sm={4} style={{ 'paddingTop': 35 }}>
                                {/* <RSelect
                                 isClearable
                                 value={this.state.state}
                                 onChange={this.handleCompChange}
                                 options={options}
                                 theme={theme}
                                 placeholder="Select State.."
                                 isMulti={false}
                               /> */}
                                <Select
                                    name="state"
                                    native

                                    fullWidth
                                    value={this.state.state}
                                    onChange={this.handleFormChange}
                                    inputProps={{
                                        name: 'state',
                                        id: 'account-native-simple',
                                    }}
                                    style={{ 'paddingTop': '10px' }}
                                >
                                    <option value={""}>Select State</option>
                                    {states.length && states.map((value, index) => (
                                        <option value={value.stateVchID} key={index}>{value.vchStateLongName}</option>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item sm={4}>
                                <TextField
                                    name="postalCode"
                                    error={error}
                                    label={"Zip Code"}
                                    fullWidth
                                    onChange={(e) => { this.setState({ postalCode: e.target.value.substring(0, 5) }) }}
                                    value={this.state.postalCode}
                                    margin="normal"
                                    maxlength="5"
                                />
                            </Grid>
                            <Grid item sm={4}>
                                <TextField
                                    name="eiNnumber"
                                    error={error}
                                    label={"EIN Nnumber"}
                                    fullWidth
                                    onChange={this.handleFormChange}
                                    defaultValue={this.state.eiNnumber}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item sm={4} style={{ marginTop: "2%" }}>
                                <Select
                                    name="beginingYear"
                                    native
                                    fullWidth
                                    value={this.state.QuickbooksDataSyncBeginningYear}
                                    style={{ paddingTop: "10px" }}
                                    onChange={(e) => this.handleBeginningYearChange(e)}
                                >
                                    {" "}
                                    <option value={null} disabled selected>
                                        Select Beginning Year
                                    </option>
                                    {this.years.map((option, index) => (
                                        <option key={index} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item sm={4} style={{ marginTop: "2%" }}>
                                <Checkbox
                                    name="fullServiceBookkeeping"
                                    value={this.state.isFullServiceBookkeeping}
                                    checked={this.state.isFullServiceBookkeeping}
                                    onChange={(e) => this.handleFullServiceBookkeepingChecked(e)}
                                />
                                <InputLabel htmlFor={""}>Full Service Bookkeeping</InputLabel>
                            </Grid>

                            <Grid item sm={4} style={{ marginTop: "2%" }}>
                                <Checkbox
                                    name="ReligeousOrganization"
                                    value={this.state.isReligeousOrganization}
                                    checked={this.state.isReligeousOrganization}
                                    onChange={(e) => this.handleReligeousOrganizationChecked(e)}
                                />
                                <InputLabel htmlFor={""}>Religious Organization</InputLabel>
                            </Grid>
                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Button type='submit' color="primary">
                            Next
                        </Button>
                        <Button onClick={this.handleCancel} color="primary" autoFocus>
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }
}



const mapStateToProps = (state) => {
    return {

    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        insertCompanies
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCompnayForm);




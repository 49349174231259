import baseService from './baseService';

export function login(credentails){
    return baseService.post('Account/Login',credentails);
}

export function logout(){
    return baseService.post('Account/Logout');
}

export function ForgotPassword(data) {
	return baseService.post('Account/FogotPassword', data);
}
export function ResetPassword(data) {
	return baseService.post('Account/ResetPassword', data);
}
import * as TaxableWages from '../services/TaxableWagesComputionService';
import { toast } from "react-toastify";

export const GET_TAXABLE_WAGES = 'GET_TAXABLE_WAGES';

export const post_TaxableWagesReport = (data) => {
    return (dispatch) => {
        TaxableWages.post_TaxableWagesReport(data)
            .then((response) => {
                dispatch({ type: GET_TAXABLE_WAGES, payload: response });
            })
            .catch((error) => {
                toast.error(`${error.response.data}`)
            })
    }
}
import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import {updateVendor} from '../appRedux/actions/vendorAction';
import {findIndex} from "lodash";
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {ResetPassword} from  "../appRedux/actions/Auth";
import { toast } from "react-toastify";


import Snackbar from '@material-ui/core/Snackbar';


class ResetForm extends React.Component {

    constructor() {
        super()
        this.state = {
            newPass:'',
            oldPassword:'',
            confirmPass:'',
            showError:false,
            open: false,
            vertical: 'top',
            horizontal: 'center',
            error:'',
        }
        this.handleSubmit=this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    handleCancel = () => {
        this.props.handleCancel();
        this.setState({
          newPass:'',
          oldPassword:'',
          confirmPass:'',
        });
    };
    
    handleSubmit = (e) => {
         e.preventDefault();

        if(this.state.newPass === this.state.confirmPass) {
           let data = {
           newPassword:this.state.confirmPass,
           oldPassword:this.state.oldPassword
         };
        this.props.ResetPassword(data);
        this.props.handleSave(false);
        this.setState({
          newPass:'',
          oldPassword:'',
          confirmPass:'',
        });

        } else {
          this.setState({showError:true})
        }
      
     }

    handleChange = (e) => {
      this.setState({[e.target.name] : e.target.value,showError:false});
    };
        
    render() {
        const {visible} = this.props;
        const {newPass,showError,oldPassword,confirmPass,vertical, error,horizontal, open} = this.state;
       
        return (
            <Dialog
               maxWidth="sm"
              fullWidth={true}
              open={visible}
              onClose={this.handleCancel}
             >
             <form method="post" onSubmit={this.handleSubmit}  autoComplete="off" style={{ padding: 20 }}>
             <DialogTitle>Change Password</DialogTitle>
             <DialogContent>
             <DialogContentText>
                 <fieldset>
                     <Grid container spacing={24} 
                       direction="row"
                       justify="flex-start"
                       alignItems="flex-start"
                      >
                        <Grid item sm={6}>
                           <TextField
                                name="oldPassword"
                                type="password"
                                required={true}
                                label={"Old Password"}
                                fullWidth
                                onChange={this.handleChange}
                                defaultValue={oldPassword}
                                margin="normal"
                            />
                        </Grid>
                        </Grid>
                          <Grid container spacing={24} 
                       direction="row"
                       justify="flex-start"
                       alignItems="flex-start"
                      >
                         <Grid item xs >
                            <TextField
                                name="newPass"
                                type="password"
                                label="New Password"
                                fullWidth
                                error={showError}
                                required={true}
                                onChange={this.handleChange}
                                defaultValue={newPass}
                                margin="normal"
                            />
                        </Grid>  
                        <Grid item xs >
                           <TextField
                                name="confirmPass"
                                type="password"
                                required={true}
                                label={"Confirm Password"}
                                fullWidth
                                error={showError}
                                helperText={showError ? 'Password is not matched' : ''}
                                onChange={this.handleChange}
                                defaultValue={confirmPass}
                                margin="normal"
                            />
                        </Grid>
                    </Grid>
                    </fieldset>
              
                </DialogContentText>
                 </DialogContent>
                <DialogActions>
                <Button type="submit" color="primary">
                  Update
                </Button>
                <Button onClick={this.handleCancel} color="primary" autoFocus>
                  Cancel
                </Button>
              </DialogActions>
                </form>
               
                
            </Dialog>
        );
    }
}

   

const mapStateToProps = (state) => {
return {
    error:state.auth.error,
    loading:state.auth.loading,
    authUser:state.auth.authUser
}
};

const mapDispatchToProps=(dispatch)=>{
    return bindActionCreators({
        ResetPassword
    },dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetForm);




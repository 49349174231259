import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import Button from '@material-ui/core/Button';
// import Grid from '@material-ui/core/Grid';
// import IntlMessages from "../../../../util/IntlMessages";
import { Get1099ComplianceReview, UpdateVendor1099 ,Update1099ReviewExcludeTotalStatus} from "../../../../appRedux/actions/LedgerAction";
import { MarkClientApproval, MarkAccountantApproval,UpdateVendorDoNotIssue1099 } from "../../../../appRedux/actions/ReportDetailAction";
import { makeLinkForQuickbooks, numberWithCommas, Getsum, MaskTaxIdentifier, ReportType, ModuleType,LinkType } from "../../../common/commonServices";
import { vendorTypes, SetVendorType,doNotIssueDeopdownValues } from "../../../../appRedux/actions/vendorAction";
import CommonComments from "../../../common/Comments";
import CircularProgress from '../../../../components/CircularProgress';
import { toast } from "react-toastify";
import { red } from "@material-ui/core/colors";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import HistoryIcon from '@material-ui/icons/History';
import Tooltip from '@material-ui/core/Tooltip';
import VendorsLogHistory from '../../../common/vendorsHistoryModel'
import {dropdownValueMapping} from '../../../common/1099ReportingDataMapping'
class DistributionPage extends Component {

  constructor(props) {

    super(props)
    let eDate = new Date();
    let sDate = new Date(eDate.getFullYear(), 0, 1);

    if (eDate.getMonth() <= 2) {
      sDate = new Date(sDate.getFullYear() - 1, 0, 1);
      eDate = new Date(eDate.getFullYear() - 1, 11, 31);
    }
    const years = [];
    const currentYear = new Date().getFullYear();
    for (let year = currentYear ; year >= currentYear - 25; year--) {
      if (year >= 2020) {
        years.push(year);
      }
    }
    this.years = years

    this.state = {
      companyId: 0,
      loading: false,
      sDate: sDate,
      eDate: eDate,
      ReportData: [],
      ShowComment: false,
      CommentReferenceId: 0,
      ColorCode: "#46b29d",
      vendorTypeData: [],
      doNotIssueDropdownOptions: [],
      selectedListId: "",
      selectedTypeId: 0,
      editIndex: -1,
      exclude1099Note : "This transaction is not included in the 1099 total.",
      isExcludeFrom1099ReviewTotal : null,
      editDoNotIssueIndex: -1,
      editData : null,
      prevDoNotIssueText:"",
      ShowHistoryModal:false
    }
  }

  componentDidMount() {
    let user = JSON.parse(localStorage.getItem('userData'));
    this.setState({
      companyId: user.companyId,
      loading: true
    }, () => {
      this.props.vendorTypes();
      this.props.doNotIssueDeopdownValues()
      this.GetReportData();
    });
  }


  YearChange = (e) => {

    this.setState({ eDate: new Date(e.target.value, 11, 31),sDate: new Date(e.target.value, 0, 1)},() => {

      this.GetReportData();
    });
  }

  GetReportData = () => {
    let body = {
      "companyID": this.state.companyId,
      "fromDate": moment(this.state.sDate).format("MM/DD/YYYY"),
      "toDate": moment(this.state.eDate).format("MM/DD/YYYY"),
      "needUpdatQBORecords": false,
      "reportType": ReportType.Vendor1099ReviewReport,
    }

    this.props.Get1099ComplianceReview(body);
  }

  componentWillReceiveProps(nextprops) {

    if (nextprops.ComplianceReviewData !== undefined && nextprops.ComplianceReviewData !== this.props.ComplianceReviewData) {
      if (nextprops.ComplianceReviewData.transactionStatus === 0) {
        if (nextprops.ComplianceReviewData.returnObject != null) {
          this.setState({ loading: false, ReportData: nextprops.ComplianceReviewData.returnObject })
        }
        else {
          this.setState({ loading: false,ReportData:[] }, () => { toast.error(`No Data Found!!!`); });
        }
      }
      else {
        this.setState({ loading: false }, () => { toast.error(`${nextprops.ComplianceReviewData.resultMsg}`); });
      }
    }
    if (nextprops.UpdateDescription !== undefined && nextprops.UpdateDescription !== this.props.UpdateDescription) {
      if (nextprops.UpdateDescription.transactionStatus === 0) {
        this.setState({ 
          // loading: true,
          loading: false,
          editDoNotIssueIndex: -1,editData:null,prevDoNotIssueText:"" }, () => {
          toast.success(`${nextprops.UpdateDescription.resultMsg}`);
          // this.GetReportData();
        });
      }
      else {
        this.setState({
          loading: false,editDoNotIssueIndex: -1,editData:null,prevDoNotIssueText:""
        }, () => {
          toast.error(`${nextprops.UpdateDescription.resultMsg}`);
        });
      }
    }

    if (nextprops.CommonUpdate !== undefined && nextprops.CommonUpdate !== this.props.CommonUpdate) {
      if (nextprops.CommonUpdate.transactionStatus === 0) {
        this.setState({ ShowComment: false, CommentReferenceId: 0, }, () => {
          toast.success("Request Process Successfully");
          // this.GetReportData();
        });
      }
      else {
        this.setState({ loading: false }, () => { toast.error(`${nextprops.CommonUpdate.resultMsg}`); });
      }

    }
    if (nextprops.vendorTypeData !== undefined && nextprops.vendorTypeData !== this.props.vendorTypeData) {
      this.setState({ loading: false, vendorTypeData: nextprops.vendorTypeData });
    }

    if (nextprops.doNotIssueDropdownOptions !== undefined && nextprops.doNotIssueDropdownOptions !== this.props.doNotIssueDropdownOptions) {
      this.setState({ loading: false, doNotIssueDropdownOptions: nextprops.doNotIssueDropdownOptions.returnObject });
    }
    if (nextprops.wagesUpdate !== undefined && nextprops.wagesUpdate !== this.props.wagesUpdate) {
      this.setState({ loading: false }, () => {

        if (nextprops.wagesUpdate.transactionStatus === 0) {
          const data = [...this.state.ReportData];
          let selectedid = this.state.selectedListId;
          data.forEach(element => {
            if (element.listID === selectedid) {
              element.vendorTypeId = parseInt(this.state.selectedTypeId);
            }
          });
          this.setState({ ReportData: data, selectedListId: "", selectedTypeId: 0 }, () => toast.success("Updated successfully."));

        }
        else {
          this.setState({ loading: false }, () => { toast.error("failed to update.") });
        }
      });
    }

    if (nextprops.LEDGER_UPDATE != undefined && nextprops.LEDGER_UPDATE !== this.props.LEDGER_UPDATE) {
      if (nextprops.LEDGER_UPDATE.transactionStatus === 0) {

          this.setState({
            loading: false, editIndex: -1, isExcludeFrom1099ReviewTotal: null
        }, () => {
          toast.success("Record updated successfully");
          // this.GetReportData();
        });

      }
      else {
          this.setState({
              loading: false, editIndex: -1, isExcludeFrom1099ReviewTotal: null
          }, () => {
              toast.error(`${nextprops.LEDGER_UPDATE.resultMsg}`);
          });
      }
  }
  }


  ChangeType = (e, listId) => {

    var obj =
    {
      "listId": listId,
      "vendorTypeId": e.target.value === "" ? null : e.target.value,
      "companyId": this.state.companyId
    }

    this.setState({ 
      // loading: true,
      loading: false,
      selectedListId: listId, selectedTypeId: e.target.value }, () => {
      this.props.SetVendorType(obj)
    });

  }
  getHistory=(item) => {
    this.setState({vendorID:item.vendorID,ShowHistoryModal: true})
   
    
  }
  onLinkClick = (rowData) => {
    let origin = makeLinkForQuickbooks();
    window.open(origin + rowData.transactionLink);
  }

  onAccountantApproved = (e, item) => {
    let body = {
      "companyID": this.state.companyId,
      "ids": [parseInt(item.listID)],
      "reportType": ReportType.Vendor1099PendingAccountantApproval,
      "isRemove":e.target.checked ? false : true
    }

    this.setState({ 
      // loading: true
      loading: false
     }, () => {
      this.props.MarkAccountantApproval(body);
    })
  }

  onClientApproved = (e, item) => {
    let body = {
      "companyID": this.state.companyId,
      "reportType": ReportType.Vendor1099PendingClientApproval,
      "ids": [parseInt(item.listID)],
      "isRemove":e.target.checked ? false : true
    }

    this.setState({ editedData: body,
      //  loading: true
      loading: false
      }, () => {
      this.props.MarkClientApproval(body);
    })
  }

  LoadComments = (e, item) => {
    e.stopPropagation();
    this.setState({ ShowComment: true, CommentReferenceId: item.id });
  }

  CloseComments = () => {
    this.setState({ ShowComment: false });
  }

  UpdateItemCommentCount = (id) => {
    let item = this.state.ReportData;
    item.forEach(function (records) {

      records.reportData.forEach(function (details) {
        if (details.id === id) {
          details.commentCount++
        }
      });
    });

    this.setState({ ReportData: item });
  }

  ChangeType = (e, listId) => {
    var obj =
    {
      "listId": listId,
      "vendorTypeId": e.target.value === "" ? null : e.target.value,
      "companyId": this.state.companyId
    }

    this.setState({ 
      // loading: true,
      loading: false,
       selectedListId: listId, selectedTypeId: e.target.value }, () => {
      this.props.SetVendorType(obj)
    });

  }

  onCheckboxClick = (e, item) => {
    let body = {
      "companyID": this.state.companyId,
      "vendor1099": e.target.checked,
      "listID": item.listID
    }

    this.setState({ editedData: body,
      //  loading: true
      loading: false
       }, () => {
      this.props.UpdateVendor1099(body);
    })
  }

  BulkApproveAccountant = () => {

    var data = this.state.ReportData;
    if (data != null && data.length > 0) {
      let body = {
        "companyID": this.state.companyId,
        "ids": [],
        "reportType": ReportType.Vendor1099PendingAccountantApproval,
      }

      for (let i = 0; i < data.length; i++) {
        if (data[i].vendor1099ApprovedByAccountant === false) {
          body.ids.push(parseInt(data[i].listID));
        }
      }

      if (body.ids.length > 0) {
        this.setState({ 
          // loading: true
          loading: false }, () => {
          this.props.MarkAccountantApproval(body);
        });
      }
      else toast.error("No data to approve");
    }
  }

  BulkApproveClient = () => {
    var data = this.state.ReportData;
    if (data != null && data.length > 0) {
      let body = {
        "companyID": this.state.companyId,
        "ids": [],
        "reportType": ReportType.Vendor1099PendingClientApproval,
      }

      for (let i = 0; i < data.length; i++) {
        if (data[i].vendor1099ApprovedByClient === false) {
          body.ids.push(parseInt(data[i].listID));
        }
      }

      if (body.ids.length > 0) {
        this.setState({
          //  loading: true
          loading: false,
           }, () => {
          this.props.MarkClientApproval(body);
        });
      }
      else toast.error("No data to approve");
    }
  }

  onRowSave = (index, rowData,vendorData) => {

    if(this.state.isExcludeFrom1099ReviewTotal){
      if(vendorData.vendor1099 != true){
        toast.error("Not able to exclude transactions because the vendor is not selected as a 1099 vendor.");
        return false;
      }
    }
  

    let body =
    {
        "id": rowData.id,
        "isExcludeFrom1099Total": this.state.isExcludeFrom1099ReviewTotal,
        "companyID": this.state.companyId,
        "reportType": ReportType.Vendor1099ComplianceReview
    }

    this.setState({
        // loading: true
        loading: false,
    }, () => {
        this.props.Update1099ReviewExcludeTotalStatus(body);
    });
}

onRowCancel = () => {
  //rowData.memoOrDescription = null;
  this.setState({ isExcludeFrom1099ReviewTotal: null, editIndex: -1 });
}

onRowEdit = (j, rowData) => {
  this.setState({ isExcludeFrom1099ReviewTotal: rowData.isExcludeFrom1099Total, editIndex: j });
};

onChange1099ExcludeCheckbox = (e) => {
  this.setState({ isExcludeFrom1099ReviewTotal: e.target.checked });
}

editDoNot1099Data = (index,rowData) => {
  let data = [...this.state.ReportData];
  if(this.state.editData != null && this.state.editDoNotIssueIndex != -1){
    data[this.state.editDoNotIssueIndex].doNotIssue1099 = this.state.prevDoNotIssueText;
  }
  this.setState({ReportData:data,editDoNotIssueIndex: index,editData:rowData,prevDoNotIssueText:rowData.doNotIssue1099});
}
CloseHistory = () => {
  this.setState({ ShowHistoryModal: false });
}

handleFormChange = (e,index) => {
  
  var data = this.state.editData;
  data.doNotIssue1099 = e.target.value
  this.setState({ editData: data });
}

onClickCloseDoNot1099 = (e,index) => {
  let data = [...this.state.ReportData];
  data[index].doNotIssue1099 = this.state.prevDoNotIssueText;
  this.setState({ReportData:data, editData: null,editDoNotIssueIndex: -1});
}

saveDoNotIssue1099 = (item) => {
  
  let editedData = this.state.editData;
  const totalpayments= Getsum(item.reportData.filter(a => a.transactionType !== "Bill"), "amount")
  let flag=false;
  if(Getsum(item.reportData.filter(a => a.transactionType !== "Bill"), "amount") >=600 && this.state.editData.vendor1099 && (this.state.editData.doNotIssue1099==="6" 
  || this.state.editData.doNotIssue1099==="7" ||this.state.editData.doNotIssue1099=== "8")){
  flag=true
  }
  let body = {
      "companyID": this.state.companyId,
      "id": editedData.vendorID,
      "doNotIssue1099": editedData.doNotIssue1099,
      "reportType": ReportType.Vendor1099PendingClientApproval,
      // "listID": item.listID,
      "isUpdateVendorEINAndAddress":flag

  }

  this.setState({ 
    // loading: true
    loading: false,
   }, () => {
      this.props.UpdateVendorDoNotIssue1099(body);
  })
}
  
render() {
    return (
      <div className="dashboardPage">
        <div className="page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
          <h2 className="mb-3 mb-sm-0">{JSON.parse(localStorage.getItem('userData')) != null ? JSON.parse(localStorage.getItem('userData')).companyName : ""} | 1099 Review Report</h2>
        </div>
        <div className="padding-box app-wrapper compliance-review">

          {this.state.loading ? <CircularProgress></CircularProgress>
            :
            <React.Fragment>
              {this.state.ShowComment ? <CommonComments
                CloseComments={this.CloseComments}
                ColorCode={this.state.ColorCode}
                ModuleType={ModuleType.GeneralReportType}
                ReportType={ReportType.Vendor1099ReviewReport}
                CompanyId={this.state.companyId}
                RecordId={this.state.CommentReferenceId}
                IsEmbeddedPage={false}
                UpdateItemCommentCount={this.UpdateItemCommentCount}
              >
              </CommonComments> : ""}
              {/* {this.state.ReportData != null && this.state.ReportData.length > 0 && */}
                <div className="row mb-3" >
                  <div className="col-md-12">
                    <div className="float-right">
                      <span>Select Year:</span>
                  <select className="select-box arrow-box" name="year" onChange={(e) => this.YearChange(e)} value={this.state.eDate.getFullYear()} style={{marginRight:"10px",height:'40px'}}>
                   {this.years.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  {this.state.ReportData != null && this.state.ReportData.length > 0 && <>
                      <Button variant="raised" style={{ backgroundColor: this.state.ColorCode }} className="jr-btn text-white refresh-btn" onClick={() => this.BulkApproveAccountant()} >Bulk Accountant Approval</Button>
                      <Button variant="raised" style={{ backgroundColor: this.state.ColorCode }} className="jr-btn text-white refresh-btn" onClick={() => this.BulkApproveClient()} >Bulk Client Approval</Button>
                      </>
                  }
                      </div>
                  </div>
                </div>
              {/* } */}
              {this.state.ShowHistoryModal ?  <VendorsLogHistory  Module="Vendors History"
                    CompanyId={this.state.companyId}
                    VendorId={this.state.vendorID}
                    CloseHistory={this.CloseHistory}/>: '' }
              {this.state.ReportData != null && this.state.ReportData.map((item, i) => (
                <div className="" >
                  <div className="complince-review-box" style={{ marginBottom: "5px" }}>
                    <div className="left-box">
                      <h5>{item.vendor}</h5>
                    </div>
                    <div className="centar-box" style={{padding: "0 15px !important"}}>
                      <div className="d-flex" style={{alignItems:"center"}}>
                          <div style={{maxWidth:"280px", marginRight:"40px"}}>
                              <strong> Tax ID Number: {MaskTaxIdentifier(item.taxIdentifier)}</strong>
                          </div>
                          <div className="d-flex" style={{alignItems:"center"}}>
                            {this.state.editDoNotIssueIndex == i ?
                                 <React.Fragment>
                                    <strong>W-9/1099 Reporting Issues: </strong>
                                    <select className="select-box arrow-box" name="doNotIssue1099" onChange={(e) => this.handleFormChange(e,i)} value={this.state.editData.doNotIssue1099} style={{marginRight:"10px"}}>
                                      <option value=""></option>
                                      {this.state.doNotIssueDropdownOptions.map(type => (
                                        <option value={type.id}>
                                          {type.description}
                                        </option>
                                      ))}                                                    

                                    </select>
                                    <Button className="jr-btn close-btn" onClick={(e) => this.saveDoNotIssue1099(item)}><CheckIcon /></Button>&nbsp;&nbsp;
                                    <Button className="jr-btn close-btn" onClick={(e) => this.onClickCloseDoNot1099(e,i)}><ClearIcon /></Button>
                                   </React.Fragment>
                                   :
                                   <React.Fragment>
                                    {item.doNotIssue1099 ?
                                     <strong style={{color:"red",marginRight:"55%"}}> W-9/1099 Reporting Issues. {dropdownValueMapping[`${item.doNotIssue1099}`]}.
                                      <span style={{color:"black"}} onClick={(e) => this.editDoNot1099Data(i,item)}>
                                       <i className="zmdi zmdi-edit zmdi-hc-lg ml-4 pointer" style={{marginLeft:"10px !important"}}></i>
                                      </span>
                                     </strong>
                                    :  
                                     <strong> W-9/1099 Reporting Issues: 
                                      <span style={{color:"black"}} onClick={(e) => this.editDoNot1099Data(i,item)}>
                                       <i className="zmdi zmdi-edit zmdi-hc-lg ml-4 pointer" style={{marginLeft:"10px !important"}}></i>
                                      </span>
                                     </strong>
                                    }
                                   
                                 </React.Fragment>
                             }
                          </div>
                      </div>
                    </div>
                    <div className='d-flex' style={{marginLeft:20}} >
                    <Tooltip title="W-9/1099 Reporting Issues history">
                          <Button className="jr-btn close-btn" onClick={(e)=>{this.getHistory(item)}}><HistoryIcon/></Button>
                          </Tooltip>
                        </div>
                    <div className="right-box">
                      <div className="d-flex" style={{alignItems:"center"}}>
                        <div className='d-flex' >
                          <span style={{margin:"5px"}}><strong>Type:</strong> </span>
                          <select className="select-box" name={item.listID} onChange={(e) => this.ChangeType(e, item.listID)} style={{ background: "white", marginTop: "-2px", marginRight: "10px",cursor:"pointer" }} >
                            <option value="" >Select</option>
                            {this.state.vendorTypeData.map(type => (
                              <option value={type.vendorTypeId} selected={type.vendorTypeId === item.vendorTypeId ? "selected" : ""}>
                                {type.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="d-flex">
                          <p>Accountant Approval </p>
                          <label className="container101 ">
                            {item.vendor1099ApprovedByAccountant ?
                              <input className="second-check-box" name={item.listID}  checked="checked" type="checkbox" onChange={(e) => this.onAccountantApproved(e, item)} />
                              :
                              <input className="second-check-box" type="checkbox"  name={item.listID} onChange={(e) => this.onAccountantApproved(e, item)} />                               
                            }
                            <span className="checkmark101 green_CheckMark"></span>
                          </label>
                        </div>
                        {/* ascasc */}
                        <div className="d-flex">
                          <p>Client Approval </p>
                          <label className="container101 " >
                            {item.vendor1099ApprovedByClient ?
                              <input className="second-check-box" onChange={(e) => this.onClientApproved(e, item)} name={item.listID}  checked="checked" type="checkbox" />
                              :
                              <input className="second-check-box" type="checkbox" onChange={(e) => this.onClientApproved(e, item)}  name={item.listID} />                               
                            }
                            <span className="checkmark101 green_CheckMark"></span>
                          </label>
                        </div>
                        <div className="d-flex">
                          <p>1099 Vendor </p>
                          <label className="container101">
                            {item.vendor1099 ?
                              <input className="second-check-box" name={item.listID} checked="checked" type="checkbox" onChange={(e) => this.onCheckboxClick(e, item)} />
                              :
                              <input className="second-check-box" type="checkbox" name={item.listID} onChange={(e) => this.onCheckboxClick(e, item)} />
                            }
                            <span className="checkmark101 green_CheckMark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    {/* table-responsive-material */}
                    <div className=""></div>
                    <table className="table" style={{tableLayout:"fixed",width:"100%"}}>
                      <thead>
                        <tr className="header-bg-color">
                          <th>  Date  </th>
                          <th>  Type </th>
                          <th>  Number  </th>
                          <th> Memo/Description  </th>
                          <th style={{width:"18%"}}>  Account </th>
                          <th className="text-center">  Amount</th>
                          <th className="text-center">
                          Exclude from 1099 Total
                          </th>
                          <th className="text-center"><img src="/vendors/WhiteCommnet.png" alt=""></img></th>
                          <th className="td-width-90 text-center "><img src="/vendors/Link-arrow_header.png" alt="link" /></th>
                        </tr>
                      </thead>
                      <tbody>
                        {item.reportData != null & item.reportData.length > 0 ?
                          <React.Fragment key={i}>
                            {item.reportData != null && item.reportData.map((data, index) => (
                              <React.Fragment>
                              {data.isExcludeFrom1099Total ?
                                <tr onClick={() => this.onRowEdit(data.id,data)}>
                                 <td style={{backgroundColor:"lightgrey"}} title={this.state.exclude1099Note}> {data.date}</td>
                                 <td style={{backgroundColor:"lightgrey"}} title={this.state.exclude1099Note}>{data.transactionType}</td>
                                 <td style={{backgroundColor:"lightgrey"}} title={this.state.exclude1099Note} >{data.num}</td>
                                 <td style={{backgroundColor:"lightgrey"}} title={this.state.exclude1099Note}>{data.memoOrDescription}</td>
                                 <td style={{backgroundColor:"lightgrey"}} title={this.state.exclude1099Note}> {data.accountValue}</td>
                                 <td style={{backgroundColor:"lightgrey"}} title={this.state.exclude1099Note} className="text-center pr-2">${numberWithCommas(data.amount)}</td>
                                 <td style={{backgroundColor:"lightgrey"}} title={this.state.exclude1099Note} className="text-center"> <i class="zmdi zmdi-close zmdi-hc-2x" style={{color:"red"}}></i></td>
                                 <td style={{backgroundColor:"lightgrey"}} title={this.state.exclude1099Note} className="text-center">
                                   <div className="panel-commentCount">
                                     <img src="/vendors/Vector Smart Object.png" alt="" className={data.commentCount === 0 ? "disableCommentsOpacity" : ""} onClick={(e) => this.LoadComments(e, data)}></img>
                                     {data.unreviewCommentCount !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">{data.unreviewCommentCount}</span>}
                                   </div>
                                 </td>
                                 <td style={{backgroundColor:"lightgrey"}} title={this.state.exclude1099Note} className="text-center"><img alt="link" src="/vendors/grrenLink.png" onClick={(e) => this.onLinkClick(data)}></img></td>
                                </tr>
                              :
                                <tr onClick={() => this.onRowEdit(data.id,data)}>
                                  <td> {data.date}</td>
                                  <td>{data.transactionType}</td>
                                  <td >{data.num}</td>
                                  <td>{data.memoOrDescription}</td>
                                  <td> {data.accountValue}</td>
                                  <td className="text-center pr-2">${numberWithCommas(data.amount)}</td>
                                  <td></td>
                                  <td className="text-center">
                                    <div className="panel-commentCount">
                                      <img src="/vendors/Vector Smart Object.png" alt="" className={data.commentCount === 0 ? "disableCommentsOpacity" : ""} onClick={(e) => this.LoadComments(e, data)}></img>
                                      {data.unreviewCommentCount !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">{data.unreviewCommentCount}</span>}
                                    </div>
                                  </td>
                                  <td className="text-center"><img alt="link" src="/vendors/grrenLink.png" onClick={(e) => this.onLinkClick(data)}></img></td>
                                </tr>
                              }
                              {this.state.editIndex === data.id && //item.vendor1099 &&
                                  <React.Fragment>
                                    <tr className="editRowColor">
                                      <td colSpan={6}></td>
                                      <td className="text-center">                                                                        
                                        <div className="chiller_cb ">
                                            <input id="isExcludeFrom1099Total" name="isExcludeFrom1099Total" type="checkbox" checked={this.state.isExcludeFrom1099ReviewTotal} onChange={(e) => this.onChange1099ExcludeCheckbox(e)}/>
                                            <label for="isExcludeFrom1099Total">
                                            <p className="checkbox-title" style={{fontSize:"14px"}}>Exclude From 1099 Total</p>
                                            </label>
                                            <span></span>
                                        </div>                                                                            
                                      </td>
                                      <td className="text-center" colSpan="2">
                                        <div className="col-md-12">
                                            <Button variant="raised" className="jr-btn jr-btn-sm bg-teal text-white" onClick={() => this.onRowSave(data.id, data,item)}>SAVE</Button>
                                            <Button variant="raised" className="jr-btn jr-btn-sm bg-danger text-white" onClick={() => this.onRowCancel(data.id, data)}>CANCEL</Button>
                                        </div>
                                      </td>
                                    </tr>
                                  </React.Fragment>
                              }
                              </React.Fragment>
                            ))}
                            {item.reportData.filter(a => a.isExcludeFrom1099Total == true).length > 0 &&
                            <React.Fragment>
                              <tr>
                                <td colSpan="4"></td>
                                <td><h2 className="total-payments-text">Adjusted Total 1099 Payments</h2></td>
                                <td className="text-center pr-2"><h1 className="total-text"> ${Getsum(item.reportData.filter(a => a.isExcludeFrom1099Total != true), "amount")} </h1></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                            </React.Fragment>
                            }
                            <tr>
                              <td colSpan="4"></td>
                              <td><h2 className="total-payments-text">Total Payments</h2></td>
                              <td className="text-center pr-2"><h1 className="total-text"> ${Getsum(item.reportData, "amount")} </h1></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </React.Fragment>
                          :
                          <tr>
                            <td className="text-center" colSpan="9">
                              No Data Found!!!
                            </td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              ))}
            </React.Fragment>
          }
        </div>
      </div>
    )
  }

}


const mapStateToProps = (state) => {
  return {
    ComplianceReviewData: state.ledgers.ComplianceReviewData,
    UpdateDescription: state.ReportData.UpdateDescription,
    CommonUpdate: state.ledgers.CommonUpdate,
    doNotIssueDropdownOptions:state.vendors.doNotIssueDeopdownValues,
    vendorTypeData: state.vendors.vendorTypes,
    wagesUpdate: state.vendors.wagesUpdate,
    LEDGER_UPDATE: state.ledgers.LEDGER_UPDATE
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    Get1099ComplianceReview,
    MarkClientApproval,
    doNotIssueDeopdownValues,
    MarkAccountantApproval,
    UpdateVendor1099,
    vendorTypes,
    SetVendorType,
    Update1099ReviewExcludeTotalStatus,
    UpdateVendorDoNotIssue1099
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(DistributionPage);
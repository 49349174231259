import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import CommonComments from "./../../../app/common/Comments";
import PaginationComponent from "./../../../../src/app/common/Pagination";
import { ReportType, ModuleType, makeLinkForQuickbooks, Base64, numberWithCommas,LinkType } from "./../../../app/common/commonServices";
import moment from 'moment';
import { bindActionCreators } from 'redux';
import CircularProgress from './../../../components/CircularProgress';
import { GET_REPORT_DATA_BES , MARK_IGNORED_DATA_BES,Revert_Ignored_DATA_BES } from "./../../../appRedux/actions/ReportDetailAction";
import { toast } from 'react-toastify';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from 'react-router-dom';
import VimeoInstructionLoader from './../../../app/common/VimeoInstructionLoader'
import RSelect,{ components } from 'react-select';
import _ from 'lodash';
import { UpdateBatchVendor_BES } from './../../../appRedux/actions/LedgerAction';
import { Vendors_BES,AddNewVendor_BES  } from './../../../appRedux/actions/vendorAction';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import queryString from 'query-string';
import RefreshDataModal from '../../../components/RefreshDataModal'

class MissingVenderName_Embedded extends Component {

    constructor(props) {
        super(props);
        this.state = {
            parantData: this.props.location.state ? this.props.location.state : null,
            MissingData: [],
            companyId: 0,
            sortField: 'date',
            sortFormat: "D",
            PageNo: 1,
            PageSize: 10,
            ColorCode: "#e27b40",
            IsCommentModelOpen: false,
            sDate: new Date(2019, 0, 1),
            eDate: new Date(),
            loading: false,
            TotalRecords: 0,
            CommentReferenceId: 0,
            IsEmbeddedPage: true,
            HomeLink: "",
            SelectALL: false,
            SelectedRecords: [],
            SelectedRecordsItem: [],
            IsIgnoredFilter:false,
            ShowIgnorerInput: false,
            IgnorerName: null,
            vendors:[],
            DisplayVendors: [],
            selectedVendors: { value: 0, label: "Select" },
            isOpen: false,
            DisplayName: "",
            companyName: "",
            isRefreshDataModalOpen:false
        }
    }

    SelectALL = (e) => {
        if (this.state.MissingData.length > 0) {
            if (e.target.checked) {
                let all = [];
                let allItem = [];
                let data = this.state.MissingData;
              
                for (let i = 0; i < data.length; i++) {
                    // if(data[i].memoOrDescription.toLowerCase().includes("cash app") || data[i].memoOrDescription.toLowerCase().includes("zelle") || data[i].transactionType === "Check" || data[i].amount >= 50){
                      
                    // }else{
                        all.push(data[i].id);
                        allItem.push(data[i]);
                    //}
                }
                this.setState({ SelectALL: true, SelectedRecords: all,SelectedRecordsItem:allItem,ShowIgnorerInput: true });
            }
            else {
                this.setState({ SelectALL: false, SelectedRecords: [],SelectedRecordsItem:[] ,ShowIgnorerInput: false,IgnorerName:null});

            }

        }

    }

    onCheckboxClick = (e, item) => {
        e.stopPropagation();
        

        let existInArray = false;
        let data = this.state.SelectedRecords;
        let validateData = this.state.SelectedRecordsItem;
        if (data != null && data.length > 0 && data.indexOf(item.id) > -1) {
            existInArray = true;
        }

        if (e.target.checked) {
            if (!existInArray) {
                data.push(item.id);
                validateData.push(item);
            }
        }
        else {
            if (existInArray) {
                data.splice(data.indexOf(item.id), 1);
                validateData.splice(validateData.indexOf(item), 1);
            }
        }

        let all = (data.length == this.state.MissingData.length) ? true : false;
        let showinput = data.length > 0 ? true : false;
        let ignorer = data.length > 0 ? this.state.IgnorerName : null;
        this.setState({ SelectedRecords: data,SelectedRecordsItem:validateData,ShowIgnorerInput: showinput, SelectALL: all, editIndex: -1,IgnorerName:ignorer });

    }

    OnApplyIgnored = () => {
        
        let selectedData = this.state.SelectedRecords;
        let data = this.state.SelectedRecordsItem;
        if (selectedData == null || selectedData.length === 0) {
            toast.error("Please select atleast 1 record for processing request");
            return false;
        }

        if(!this.state.IsIgnoredFilter)
        {
            let ignorer = this.state.IgnorerName;
            if (ignorer == null || ignorer.trim().replace(/\s/g, '') === "") {
                toast.error("Please enter the ignorer name");
                return false;
            }
        }

        let MemoInlculdeValidate = data.length && data.filter(obj => obj.memoOrDescription.toLowerCase().includes("cash app") || obj.memoOrDescription.toLowerCase().includes("zelle"));
        if(MemoInlculdeValidate != null &&  MemoInlculdeValidate.length > 0){
            toast.error("Do not have permission to ignore transaction like description includes 'cash app' and 'zelle'.");
            return false;
        }
        let transactionTypeValidate = data.length && data.filter(obj => obj.transactionType == "Check");
        if(transactionTypeValidate != null &&  transactionTypeValidate.length > 0){
            toast.error("Do not have permission to ignore transaction like transaction type 'Check'");
            return false;
        }
        let amountValidate = data.length && data.filter(obj => obj.amount >= 50);
        if(amountValidate != null &&  amountValidate.length > 0){
            toast.error("Do not have permission to ignore transaction like amount is equal to or greater than 50");
            return false;
        }

      
       
        
        this.setState({ loading: true }, () => {
            if(this.state.IsIgnoredFilter)
            {
                let body1 = {
                    "ids": selectedData,
                    "reportType": ReportType.MissingVendorName,
                    "companyID": this.state.companyId
                }
                this.props.Revert_Ignored_DATA_BES(body1);                
            }
            else
            {
                let body2 = {
                    "ids": selectedData,
                    "reportType": ReportType.MissingVendorName,
                    "companyID": this.state.companyId,
                    "createdByName" : this.state.IgnorerName,
                    "isClient":true
                }
                this.props.MARK_IGNORED_DATA_BES(body2);                
            }      
        });
    }

    HandleIgnored = (event) => {
        
        this.setState({ IsIgnoredFilter: (event.target.value=="true"?true:false),loading:true,SelectedRecords:[],SelectedRecordsItem:[] },()=>{ this.GetReportData()});
    }

    handleIgnoredBy = (e) => {
        this.setState({ IgnorerName: e.target.value });
    }

    onSortChange = (fieldName) => {
        if (this.state.sortField == fieldName) {
            let formate = this.state.sortFormat == 'A' ? 'D' : 'A';
            this.setState({ sortFormat: formate, loading: true , ShowIgnorerInput: false}, () => {
                this.GetReportData();
            });
        } else {
            this.setState({ sortField: fieldName, sortFormat: 'A', loading: true, ShowIgnorerInput: false }, () => {
                this.GetReportData();
            });
        }
    }

    componentDidMount() {
        
        let value = queryString.parse(this.props.location.search);
        //localStorage.setItem("homeURL", window.location.href.replace(window.location.origin, ""));
        let dashboardURl = localStorage.getItem("homeURL");
        this.setState({
            loading: true,
            HomeLink: dashboardURl ? dashboardURl : "?q="+ value.q + "&p=" + value.p
        }, () => {
            this.getCompanyId();
        });
    }

    getCompanyId = () => {
        
        let id = 0;
        let value = queryString.parse(this.props.location.search);
        localStorage.setItem("q", JSON.stringify(value));
        let data = localStorage.getItem("q");

        if (data != null && data != undefined) {
            data = JSON.parse(data);
            let CName = Base64.decode(data.p);
            this.setState({
                companyId: parseInt(Base64.decode(data.q)),
                companyName:Base64.decode(data.p),
                loading: true,
                ShowIgnorerInput: false
            }, () => {
                this.GetReportData();
                this.props.Vendors_BES(this.state.companyId);
            });
        }
        else {
            this.setState({ loading: false });
        }
    }


    ChangePageSize = (size) => {

        this.setState({ loading: true, PageSize: parseInt(size), PageNo: 1 }, () => {
            this.GetReportData();
        });
    }

    ChangePage = (page) => {
        this.setState({ loading: true, PageNo: page, }, () => {
            this.GetReportData();
        });
    }

    LoadComments = (item) => {
        this.setState({ IsCommentModelOpen: true, CommentReferenceId: item.id });
    }

    CloseComments = () => {
        this.setState({ IsCommentModelOpen: false });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.ReportData !== this.props.ReportData) {
            if (nextProps.ReportData.transactionStatus === 0) {
                var totalrecords = 0;
                if (nextProps.ReportData.returnObject != null && nextProps.ReportData.returnObject.length > 0) {
                    totalrecords = nextProps.ReportData.returnObject[0].totalrows
                }
                this.setState({ 
                    MissingData: nextProps.ReportData.returnObject, TotalRecords: totalrecords, PageSize: this.state.PageSize, PageNo: this.state.PageNo, loading: false,
                    ShowIgnorerInput: false, IgnorerName: null, SelectALL: false 
                });
            } else {
                toast.error("Error- " + nextProps.ReportData.resultMsg);
                this.setState({ loading: false });
            }
        }

        if (nextProps.UpdateDescription !== this.props.UpdateDescription) {
            if (nextProps.UpdateDescription.transactionStatus === 0) {
                this.setState({
                    loading: true, editData: null, editIndex: -1, SelectALL: false, SelectedRecords: [],SelectedRecordsItem:[],
                    ShowIgnorerInput: false, IgnorerName: null
                }, () => {
                    toast.success(`${nextProps.UpdateDescription.resultMsg}`);
                    this.GetReportData();
                });
            }
            else {
                this.setState({
                    loading: false,SelectedRecords: [],SelectedRecordsItem:[],ShowIgnorerInput: false, IgnorerName: null, SelectALL: false
                }, () => {
                    toast.error(`${nextProps.UpdateDescription.resultMsg}`);
                });
            }
        }

         if(nextProps.vendors !==undefined && nextProps.vendors !== this.props.vendors  ){

            let searchableVendors = [];
            if (nextProps.vendors != null && nextProps.vendors.length > 0) {
                searchableVendors = _.sortBy(nextProps.vendors, ['displayName']).map((value, index) => (
                    { value: value.vendorID, label: value.displayName }
                ))
            }
            let defaultValue = { value: 0, label: "Select" };
            searchableVendors.unshift(defaultValue);
            this.setState({
                vendors: nextProps.vendors,
                DisplayVendors: searchableVendors
            });

            // this.setState({
            //   vendors:nextProps.vendors
            // });
         }

         if (nextProps.BATCH_LEDGER_UPDATE != undefined && nextProps.BATCH_LEDGER_UPDATE !== this.props.BATCH_LEDGER_UPDATE) {
            if (nextProps.BATCH_LEDGER_UPDATE.transactionStatus === 0) {
                this.setState({
                    loading: true, editData: null, editIndex: -1, SelectedRecords: [],SelectedRecordsItem:[], SelectALL: false,selectedVendors:{ value: 0, label: "Select" }
                }, () => {
                    toast.success("Record updated successfully");
                    this.GetReportData();
                });
            }
            else {
                this.setState({
                    loading: false, SelectedRecords: [],SelectedRecordsItem:[], SelectALL: false
                }, () => {
                    toast.error(`${nextProps.BATCH_LEDGER_UPDATE.resultMsg}`);
                });
    
            }
        }

        if (nextProps.vendorAdd != undefined && nextProps.vendorAdd !== this.props.vendorAdd) {
            if (nextProps.vendorAdd.transactionStatus === 0) {
                this.setState({
                    loading: true, editData: null, editIndex: -1, SelectedRecords: [],SelectedRecordsItem:[], SelectALL: false,selectedVendors:{ value: 0, label: "Select" },isOpen:false,DisplayName:""
                }, () => {
                    toast.success("Vendor Added successfully");
                    this.GetReportData();
                    this.props.Vendors_BES(this.state.companyId);
                });
            }
            else {
                this.setState({
                    loading: false, SelectedRecords: [],SelectedRecordsItem:[], SelectALL: false
                }, () => {
                    toast.error(`${nextProps.vendorAdd.resultMsg}`);
                });
            }
        }


    }

    refreshData = () => {
        if (!this.state.loading) {
            this.setState({ loading: true,isRefreshDataModalOpen:false }, () => {
                this.GetReportData(true);
            });
        }
    }

    onRefreshDataClick = () => {
        
        this.setState({isRefreshDataModalOpen:true})
    }

    handleRefreshDataModal=()=>{
        this.setState({isRefreshDataModalOpen:false})
      }

    GetReportData(IsRefresh = false) {
        let state = this.state;
        let body = {
            companyID: state.companyId,
            reportType: ReportType.MissingVendorName,
            fromDate: moment(state.sDate).format("MM/DD/YYYY"),
            toDate: moment(state.eDate).format("MM/DD/YYYY"),
            sortField: state.sortField,
            sortFormat: state.sortFormat,
            pageNumber: state.PageNo,
            pageSize: state.PageSize,
            needUpdatQBORecords: IsRefresh,
            isAllIgnoreUnignore:state.IsIgnoredFilter
        };

        this.props.GET_REPORT_DATA_BES(body);
    }


    onLinkClick = (rowData) => {
        let origin = makeLinkForQuickbooks();
        window.open(origin + rowData.transactionLink);
    }


    UpdateItemCommentCount = (id) => {
        let data = this.state.MissingData;
        data.forEach(function (item) {

            if (item.id === id) {
                item.commentCount++
            }
        });

        this.setState({ MissingData: data });
    }

    handalBatchUpdateVendorDropdown = (e) => {
        if (e.value == null || e.value == 0) {
            this.setState({ selectedVendors: e });
        }
        else {
            this.setState({ selectedVendors: e });
        }
    }

    OnAssignVendor = () => {
        
        let selectedData = this.state.SelectedRecords;
        let selectedVendor = this.state.selectedVendors;
        if (selectedData == null || selectedData.length === 0) {
            toast.error("Please select atleast 1 record for processing request");
            return false;
        }

        if (selectedVendor.value == null || selectedVendor.value == 0) {
            toast.error("Please select vendor");
            return false;
        }

        let body = {
            // "ids": selectedData,
            // "reportType": ReportType.UnCategorizeTransaction,
            // "companyID": this.state.companyId
            "txnId":selectedVendor.value,
            "glIds":selectedData,
            "companyID": this.state.companyId
        }

        this.setState({ loading: true }, () => {
             this.props.UpdateBatchVendor_BES(body);  
        });
    }

    handleCancel = () => {
        this.setState({ isOpen: false ,DisplayName:""});
    };

    onPopupOpen = () => {
        this.setState({ isOpen: true,DisplayName:"" });
    };

    handleDisplayName = (e) => {
        this.setState({ DisplayName: e.target.value });
    }

    AddVendor = () => {
        let DisplayName = this.state.DisplayName;
        if (DisplayName == null || DisplayName == "") {
            toast.error("Display name required");
            return false;
        }

        let body = {
            "displayName":DisplayName,
            "companyId": this.state.companyId
        }

        this.setState({ loading: true,isOpen:false }, () => {
            this.props.AddNewVendor_BES(body); 
        });
    }
    render() {
        let selectedItem = this.state.SelectedRecords;
        const msgStyles = {
            background: "#334c5a",
            color: "white"
          };
          
          const NoOptionsMessage = props => {
            return (
              <components.NoOptionsMessage {...props}>
                <span className="custom-css-class" style={{cursor:"pointer"}} onClick={() => this.onPopupOpen()}><i class="zmdi zmdi-plus-circle"></i> Add new</span> 
              </components.NoOptionsMessage>
            );
          };
        return (
          

            <React.Fragment>
                <RefreshDataModal visible={this.state.isRefreshDataModalOpen}
                 handlePopUpClose={this.handleRefreshDataModal} refreshData={this.refreshData}/>
                   <Dialog
                maxWidth="sm"
                fullWidth={true}
                open={this.state.isOpen}
                onClose={this.handleCancel}
            >
                
                    <DialogTitle>Add Vendor</DialogTitle>
                    <DialogContent>
                        <fieldset>
                            
                            <Grid container spacing={10} >

                                <Grid item sm={10} >
                                    <TextField
                                        name="displayname"
                                        label={"Display Name"}
                                        fullWidth
                                        onChange={(e) => this.handleDisplayName(e)}
                                        //defaultValue={this.state.printOnCheckName}
                                        value={this.state.DisplayName}
                                        margin="normal"
                                    />
                                </Grid>

                                
                            </Grid>
                            
                        </fieldset>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.AddVendor} color="primary">
                            Add
                       </Button>
                        <Button onClick={this.handleCancel} color="primary" autoFocus>
                            Cancel
                       </Button>
                    </DialogActions>
               
                </Dialog>
                {this.state.IsCommentModelOpen ?
                    <CommonComments
                        CloseComments={this.CloseComments}
                        ColorCode={this.state.ColorCode}
                        ModuleType={ModuleType.GeneralReportType}
                        ReportType={ReportType.MissingVendorName}
                        CompanyId={this.state.companyId}
                        RecordId={this.state.CommentReferenceId}
                        IsEmbeddedPage={this.state.IsEmbeddedPage}
                        UpdateItemCommentCount={this.UpdateItemCommentCount}
                    >
                    </CommonComments> : ""}

                
                    <div className="dashboardPage embeded-dashboard">
                        <ToastContainer autoClose={3000} />
                        {this.state.loading ? <CircularProgress></CircularProgress>
                            :
                            <React.Fragment>
                                <div className=" page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
                                    <h2 className="mb-3 mb-sm-0">{this.state.companyName} |  {LinkType.TranMissVendor}</h2>
                                    <Link className="home-link" to={this.state.HomeLink} > Home </Link>
                                </div>
                                <div className="padding-box app-wrapper bgcolor">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="float-right">
                                               {/* <span> <b> Show Previously Ignored Transactions  </b> : </span>
                                                <select style={{ backgroundColor: "#fff", marginRight: "10px" }} className="select-box arrow-box" onChange={this.HandleIgnored} value={this.state.IsIgnoredFilter} >
                                                    <option value={true}> YES </option>
                                                    <option value={false}> NO </option>                                                    
                                                </select>
                                                <Button variant="raised" className=" jr-btn graycolor text-white refresh-btn" onClick={() => this.OnApplyIgnored()} >{this.state.IsIgnoredFilter?"Revert":"Ignore"}</Button>                                             */}

                                                <Button variant="raised" className=" tr-bgcolor orangeColor jr-btn bg-teal text-white refresh-btn" onClick={() => this.onRefreshDataClick()} >
                                                    <i className="zmdi zmdi-refresh "></i> Refresh Data</Button>    
                                                
                                                <VimeoInstructionLoader 
                                                   //URL={"https://vimeo.com/687409045"} 
                                                   URL={"https://player.vimeo.com/video/687409045?h=df22da040e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"} 
                                                   buttonName="Instructions"
                                                   buttonColor="orangeColor"
                                                   PageName={LinkType.TranMissVendor}
                                                ></VimeoInstructionLoader>

                                            </div>
                                        </div>
                                    </div>
                                    {/* {this.state.ShowIgnorerInput && !this.state.IsIgnoredFilter?
                                        <div className="row" style={{ marginTop: "20px" }}>
                                            <div className="col-md-12">
                                                <div className="float-right">
                                                    <label><strong> Ignored By </strong></label> &nbsp;&nbsp;
                                                    <input type="text" placeholder="enter your name" onChange={this.handleIgnoredBy} />
                                                </div>
                                            </div>
                                        </div>
                                        : ""} */}

                                  <div class="row mt-2">
                                    <div className="col-md-12">
                                        <div className='d-flex justify-content-end align-items-center'>
                                            <div className='show-previously-box'>
                                                <div class="form-group d-flex mb-0">                                            
                                                    <h4 for="staticEmail" class="col-form-label">Show Previously Ignored Transactions:</h4>
                                                    <div class="dropdown-input-box">
                                                        <select style={{ backgroundColor: "#fff" }} className="select-box arrow-box" onChange={this.HandleIgnored} value={this.state.IsIgnoredFilter} >
                                                                                                    <option value={true}> YES </option>
                                                                                                    <option value={false}> NO </option>                                                    
                                                                                                </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='assign-vendor-box'>
                                                <div class="form-group d-flex mb-0"> 
                                                    <h4 for="staticEmail" class="col-form-label">Assign Vendor Name:</h4>
                                                    <div className='rselect-box'>
                                                        <RSelect
                                                            required={true}
                                                            name="BatchVendorID"
                                                            value={this.state.selectedVendors}
                                                            onChange={(e) => this.handalBatchUpdateVendorDropdown(e)}
                                                            options={this.state.DisplayVendors}
                                                            //defaultValue={{ value: 0, label: "select" }}
                                                            placeholder="Select"
                                                            components={{ NoOptionsMessage }}
                                                            styles={{ noOptionsMessage: base => ({ ...base, ...msgStyles }) }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=''>
                                                <div class="form-group d-flex mb-0 align-items-center"> 
                                                    <Button variant="raised" className=" jr-btn graycolor text-white refresh-btn" onClick={() => this.OnAssignVendor()} >Save</Button>
                                                   
                                                    {this.state.ShowIgnorerInput && !this.state.IsIgnoredFilter?
                                                     <React.Fragment>
                                                      <h4 for="staticEmail" class="col-form-label">Ignored By:</h4>
                                                      <div className='ignore-by-box'>
                                                      <input type="text" placeholder="enter your name" onChange={this.handleIgnoredBy} />
                                                      </div>
                                                      </React.Fragment>
                                                    : ""}
                                                   
                                                    <Button variant="raised" className=" jr-btn graycolor text-white refresh-btn" onClick={() => this.OnApplyIgnored()} >{this.state.IsIgnoredFilter?"Revert":"Ignore"}</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>  
                                </div>

                                {/* <div class="row mt-2">
                                    <div className="col-md-6 ml-auto">
                                        <div class="justify-content-end d-flex align-items-center pr-3">
                                            <div class="col-md-auto"> <span> <b> Assign Vendor  </b> : </span></div>
                                                <div class="col-md-6">
                                            
                                                  <RSelect
                                                    required={true}
                                                    name="BatchVendorID"
                                                    value={this.state.selectedVendors}
                                                    onChange={(e) => this.handalBatchUpdateVendorDropdown(e)}
                                                    options={this.state.DisplayVendors}
                                                    //defaultValue={{ value: 0, label: "select" }}
                                                    placeholder="Select"
                                                    components={{ NoOptionsMessage }}
                                                    styles={{ noOptionsMessage: base => ({ ...base, ...msgStyles }) }}
                                                  />
                                                </div>
                                                <div>
                                                  <Button variant="raised" className=" jr-btn graycolor text-white refresh-btn" onClick={() => this.OnAssignVendor()} >Save</Button>
                                                </div>
                                        </div>
                                    </div>  
                                </div> */}
                                    <div className="table-responsive-material">
                                        <table className="table tablebox account-reconcilliation table-hover">
                                            <thead>
                                                <tr className="orangeColor">
                                                    <th className={`td-width  ${this.state.sortField == 'date' ? this.state.sortFormat == 'A'
                                                        ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('date')}><p className="table-th-title"> Date</p> </th>
                                                    <th className={`td-width ${this.state.sortField == 'transactionType' ? this.state.sortFormat == 'A'
                                                        ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('transactionType')}><p className="table-th-title">Type</p></th>
                                                    <th className={`td-width ${this.state.sortField == 'num' ? this.state.sortFormat == 'A'
                                                        ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('num')}><p className="table-th-title"> Number</p></th>
                                                    <th className={`td-width-box ${this.state.sortField == 'account' ? this.state.sortFormat == 'A'
                                                        ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('account')}><p className="table-th-title"> A/C No.</p></th>
                                                    <th className={` ${this.state.sortField == 'memoOrDescription' ? this.state.sortFormat == 'A'
                                                        ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('memoOrDescription')}><p className="table-th-title"> Memo/Description</p></th>
                                                    <th className={`td-width  ${this.state.sortField == 'amount' ? this.state.sortFormat == 'A'
                                                        ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('amount')}><p className="table-th-title"> Amount</p></th>
                                                    <th className="td-width-90 text-center">
                                                        <label className="container101 header-check">
                                                            {this.state.SelectALL ?
                                                                <input className="second-check-box" checked="checked" type="checkbox" onClick={(e) => this.SelectALL(e)} />
                                                                :
                                                                <input className="second-check-box" type="checkbox" onClick={(e) => this.SelectALL(e)} />
                                                            }
                                                            <span className="checkmark101 white_border"></span>
                                                        </label>
                                                    </th>
                                                    <th className="td-width-90 text-center"><img src="/vendors/WhiteCommnet.png" alt="comments" /></th>
                                                    <th className="td-width-90 text-center "><img src="/vendors/Link-arrow_header.png" alt="link" /></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(this.state.MissingData && this.state.MissingData.length > 0) && this.state.MissingData.map((item, j) => (
                                                    <React.Fragment key={j}>
                                                        {<tr>
                                                            <td >{item.date}</td>
                                                            <td>{item.transactionType}</td>
                                                            <td>{item.num}</td>
                                                            <td>{item.account}</td>
                                                            <td>{item.memoOrDescription}</td>
                                                            <td className="text-right">{numberWithCommas(item.amount)}</td>
                                                            <td className="text-center">
                                                                {/* <label className="container101">
                                                                    {(item.memoOrDescription.toLowerCase().contains("cash app") || item.memoOrDescription.toLowerCase().contains("zelle") || item.transactionType === "Check" || item.amount >= 50?
                                                                         <input className="second-check-box" type="checkbox" disabled />
                                                                        : (this.state.SelectALL || selectedItem.length > 0 && (selectedItem.indexOf(item.id) > -1)) ?
                                                                        <input className="second-check-box" checked="checked" type="checkbox" onClick={(e) => this.onCheckboxClick(e, item)} />
                                                                        :
                                                                        <input className="second-check-box" type="checkbox" onClick={(e) => this.onCheckboxClick(e, item)} />
                                                                      
                                                                    )}
                                                                  
                                                                    <span className="checkmark101"></span>
                                                                </label> */}


                                                                {/* {(item.memoOrDescription.toLowerCase().includes("cash app") || item.memoOrDescription.toLowerCase().includes("zelle") || item.transactionType === "Check" || item.amount >= 50?                                                            
                                                                    ""
                                                                    : */}
                                                                    <label className="container101">
                                                                        {(this.state.SelectALL || selectedItem.length > 0 && (selectedItem.indexOf(item.id) > -1)) ?
                                                                            <input className="second-check-box" checked="checked" type="checkbox" onClick={(e) => this.onCheckboxClick(e, item)} />
                                                                            :
                                                                            <input className="second-check-box" type="checkbox" onClick={(e) => this.onCheckboxClick(e, item)} />
                                                                        }
                                                                        <span className="checkmark101"></span>
                                                                    </label>
                                                                {/* )} */}
                                                            </td>
                                                            <td className="text-center">
                                                                <div className="panel-commentCount">
                                                                    <img src="/vendors/Vector Smart Object.png" alt="comments" className={item.commentCount === 0 ? "disableCommentsOpacity" : ""} onClick={() => this.LoadComments(item)}></img>
                                                                    {item.unreviewCommentCount !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">{item.unreviewCommentCount}</span>}
                                                                </div>
                                                            </td>
                                                            <td className="text-center"><img src="/vendors/Shape 1.png" alt="link" onClick={() => this.onLinkClick(item)}></img></td>
                                                        </tr>}
                                                    </React.Fragment>
                                                ))}

                                                {this.state.MissingData.length == 0 ?
                                                    <tr>
                                                        <td className="text-center" colSpan="9">
                                                        No issues here! Keep up the good work!
                                                    </td>
                                                    </tr>
                                                    : ""}
                                            </tbody>
                                        </table>
                                    </div>
                                    <PaginationComponent
                                        selectedPageNo={this.state.PageNo}
                                        selectedSize={this.state.PageSize}
                                        TotalRecords={this.state.TotalRecords}
                                        ChangePageSize={this.ChangePageSize}
                                        ChangePage={this.ChangePage}
                                        CurrentRecords={this.state.MissingData.length}
                                    >
                                    </PaginationComponent>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                
            </React.Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        ReportData: state.ReportData.ReportData,
        UpdateDescription: state.ReportData.UpdateDescription,
        vendors:state.vendors.vendors,
        BATCH_LEDGER_UPDATE : state.ledgers.BATCH_LEDGER_UPDATE,
        vendorAdd:state.vendors.vendorAdd
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        GET_REPORT_DATA_BES,
        MARK_IGNORED_DATA_BES,
        Revert_Ignored_DATA_BES,
        Vendors_BES,
        UpdateBatchVendor_BES,
        AddNewVendor_BES
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(MissingVenderName_Embedded);


import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
// import Grid from '@material-ui/core/Grid';
// import IntlMessages from "../../../../util/IntlMessages";
import { Get1099ComplianceReview,Update1099ReviewExcludeTotalStatus } from "../../../../appRedux/actions/LedgerAction";
import { MarkAccountantApproval, AccountReconciliationReviewSummery } from "../../../../appRedux/actions/ReportDetailAction";
// import { tsConstructorType } from "@babel/types";
import { numberWithCommas, Getsum, MaskTaxIdentifier, ReportType, ModuleType, GetLastQuarterEndDate,LinkType } from "../../../common/commonServices";
import CommonComments from "../../../common/Comments";
import CircularProgress from '../../../../components/CircularProgress';
import { toast } from "react-toastify";
import Button from '@material-ui/core/Button';

class ACCAproval1099 extends Component {

  constructor(props) {

    super(props)
    let eDate = new Date();
    let sDate = new Date(eDate.getFullYear(), 0, 1);

    if (eDate.getMonth() <= 2) {
      sDate = new Date(sDate.getFullYear() - 1, 0, 1);
      eDate = new Date(eDate.getFullYear() - 1, 11, 31);
    }
    const years = [];
    const currentYear = new Date().getFullYear();
    for (let year = currentYear ; year >= currentYear - 25; year--) {
      if (year >= 2020) {
        years.push(year);
      }
    }
    this.years = years

    this.state = {
      companyId: 0,
      loading: false,
      sDate: sDate,
      eDate: eDate,
      ReportData: [],
      ShowComment: false,
      ColorCode: "#f0ca4c",
      ACRSummary: "No",
      lastReconciledThrough: null,
      LastUpdate:false,
      editIndex: -1,
      exclude1099Note : "This transaction is not included in the 1099 total.",
      isExcludeFrom1099ReviewTotal : null
    }
  }

  componentDidMount() {
    let user = JSON.parse(localStorage.getItem('userData'));
    this.setState({
      companyId: user.companyId,
      loading: true
    }, () => {
      this.props.AccountReconciliationReviewSummery(user.companyId);
      this.GetReportData();
    });
  }
  YearChange = (e) => {

    this.setState({ eDate: new Date(e.target.value, 11, 31),sDate: new Date(e.target.value, 0, 1)},() => {

      this.GetReportData();
    });
  }

  GetReportData = () => {
    let body = {
      "companyID": this.state.companyId,
      "fromDate": moment(this.state.sDate).format("MM/DD/YYYY"),
      "toDate": moment(this.state.eDate).format("MM/DD/YYYY"),
      "needUpdatQBORecords": false,
      "reportType": ReportType.Vendor1099PendingAccountantApproval,
    }

    this.props.Get1099ComplianceReview(body);
  }

  componentWillReceiveProps(nextprops) {

    if (nextprops.ComplianceReviewData !== this.props.ComplianceReviewData) {
      if (nextprops.ComplianceReviewData.transactionStatus === 0) {
        if (nextprops.ComplianceReviewData.returnObject != null) {
          this.setState({ loading: false, ReportData: nextprops.ComplianceReviewData.returnObject })
        }
        else {
          if(this.state.LastUpdate)
          {
              this.setState({ loading: false,ReportData: []});
          }
          else
          {
              this.setState({ loading: false,ReportData: [] }, () => { toast.error(`No issues here! Keep up the good work!`); });
          }
        }
      }
      else {
        this.setState({ loading: false }, () => { toast.error(`${nextprops.ComplianceReviewData.resultMsg}`); });
      }
    }
    if (nextprops.UpdateDescription !== this.props.UpdateDescription) {
      if (nextprops.UpdateDescription.transactionStatus === 0) {
        this.setState({ loading: true }, () => {
          toast.success(`${nextprops.UpdateDescription.resultMsg}`);
          if(this.state.ReportData!= null &&  this.state.ReportData.length===0)
          {
              this.setState({LastUpdate:true},()=> {this.GetReportData(); });
              
          }
          else
          {
              this.GetReportData();
          }
        });
      }
      else {
        this.setState({
          loading: false
        }, () => {
          toast.error(`${nextprops.UpdateDescription.resultMsg}`);
        });
      }
    }

    if (nextprops.ARCRSummary !== this.props.ARCRSummary) {
      if (nextprops.ARCRSummary.transactionStatus === 0) {
        if (nextprops.ARCRSummary.returnObject.length > 0) {
          this.setState({ loading: false, ACRSummary: nextprops.ARCRSummary.returnObject[0].haveAllCheckingAccounts ? "Yes" : "No", lastReconciledThrough: nextprops.ARCRSummary.returnObject[0].lastReconciledThrough });
        }
      }
      else {
        this.setState({ loading: false }, () => { toast.error(`${nextprops.ARCRSummary.resultMsg}`) });
      }

    }

    if (nextprops.LEDGER_UPDATE != undefined && nextprops.LEDGER_UPDATE !== this.props.LEDGER_UPDATE) {
      if (nextprops.LEDGER_UPDATE.transactionStatus === 0) {

          this.setState({
            loading: false, editIndex: -1, isExcludeFrom1099ReviewTotal: null
        }, () => {
          toast.success("Record updated successfully");
          this.GetReportData();
        });

      }
      else {
          this.setState({
              loading: false, editIndex: -1, isExcludeFrom1099ReviewTotal: null
          }, () => {
              toast.error(`${nextprops.LEDGER_UPDATE.resultMsg}`);
          });
      }
  }
  
  }

  onAccountantApproved = (e, item) => {
    let body = {
      "companyID": this.state.companyId,
      "ids": [parseInt(item.listID)],
      "reportType": ReportType.Vendor1099PendingAccountantApproval,
      // "listID": item.listID
    }

    this.setState({ loading: true }, () => {
      this.props.MarkAccountantApproval(body);
    })
  }



  LoadComments = (e, item) => {
    e.stopPropagation();
    this.setState({ ShowComment: true, CommentReferenceId: item.id });
  }

  CloseComments = () => {
    this.setState({ ShowComment: false });
  }

  UpdateItemCommentCount = (id) => {
    let item = this.state.ReportData;
    item.forEach(function (records) {

      records.reportData.forEach(function (details) {
        if (details.id === id) {
          details.commentCount++
        }
      });
    });

    this.setState({ ReportData: item });
  }

  onRowSave = (index, rowData,vendorData) => {

    if(this.state.isExcludeFrom1099ReviewTotal){
      if(vendorData.vendor1099 != true){
        toast.error("Not able to exclude transactions because the vendor is not selected as a 1099 vendor.");
        return false;
      }
    }
    
    let body =
    {
        "id": rowData.id,
        "isExcludeFrom1099Total": this.state.isExcludeFrom1099ReviewTotal,
        "companyID": this.state.companyId,
        "reportType": ReportType.Vendor1099ComplianceReview
    }

    this.setState({
        loading: true
    }, () => {
        this.props.Update1099ReviewExcludeTotalStatus(body);
    });
  }

  onRowCancel = () => {
    //rowData.memoOrDescription = null;
    this.setState({ isExcludeFrom1099ReviewTotal: null, editIndex: -1 });
  }
  
  onRowEdit = (j, rowData) => {
    this.setState({ isExcludeFrom1099ReviewTotal: rowData.isExcludeFrom1099Total, editIndex: j });
  };
  
  onChange1099ExcludeCheckbox = (e) => {
    this.setState({ isExcludeFrom1099ReviewTotal: e.target.checked });
  }


  render() {
    return (
      <div className="dashboardPage">
        <div className="page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
          <h2 className="mb-3 mb-sm-0">{JSON.parse(localStorage.getItem('userData')) != null ? JSON.parse(localStorage.getItem('userData')).companyName : ""} | 1099's Pending Accountant's Approval</h2>
        </div>
        <div className="padding-box app-wrapper compliance-review">

          {this.state.loading ? <CircularProgress></CircularProgress>
            :
            <React.Fragment>
              {this.state.ShowComment ? <CommonComments
                CloseComments={this.CloseComments}
                ColorCode={this.state.ColorCode}
                ModuleType={ModuleType.GeneralReportType}
                ReportType={ReportType.Vendor1099ReviewReport}
                CompanyId={this.state.companyId}
                RecordId={this.state.CommentReferenceId}
                IsEmbeddedPage={this.state.IsEmbeddedPage}
                UpdateItemCommentCount={this.UpdateItemCommentCount}
              >
              </CommonComments> : ""}
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="float-left arc_summary">
                      <span>Have all checking accounts been reconciled through {this.state.lastReconciledThrough}?: {this.state.ACRSummary}  </span>
                      </div>
                      <div className="float-right">
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="float-right arc_summary">
                      <span>Select Year:</span>
                  <select className="select-box arrow-box" name="year" onChange={(e) => this.YearChange(e)} value={this.state.eDate.getFullYear()} style={{marginRight:"10px",height:'40px'}}>
                   {this.years.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                     </div>
                      <div className="float-right">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {this.state.ReportData != null && this.state.ReportData.map((item, i) => (
                <div className="" >

                  <div className="complince-review-box" style={{ marginBottom: "5px" }}>
                    <div className="left-box">
                      <h5>{item.vendor}</h5>
                    </div>
                    <div className="center-box">
                      <strong> Tax ID Number: {MaskTaxIdentifier(item.taxIdentifier)}</strong>
                    </div>
                    <div className="right-box">
                      <div className="row">
                        <div className="d-flex">
                          <p>Accountant Approval </p>
                          <label className="container101">
                            {item.vendor1099ApprovedByAccountant ?
                              <input className="second-check-box" name={item.listID} checked="checked" type="checkbox" onChange={(e) => this.onAccountantApproved(e, item)} />
                              :
                              <input className="second-check-box" type="checkbox" name={item.listID} onChange={(e) => this.onAccountantApproved(e, item)} />
                            }
                            <span className="checkmark101 green_CheckMark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <table className="table">
                      <thead >
                        <tr style={{ backgroundColor: this.state.ColorCode }}>
                          <th> Date </th>
                          <th> Type</th>
                          <th> Number </th>
                          <th>   Memo/Description   </th>
                          <th>   Account   </th>
                          <th className="text-center">   Amount  </th>
                          <th className="text-center">
                            Exclude from 1099 Total
                          </th>
                          <th className="text-center"><img src="/vendors/WhiteCommnet.png" alt=""></img></th>
                        </tr>
                      </thead>
                      <tbody>
                        {item.reportData != null & item.reportData.length > 0 ?
                          <React.Fragment key={i}>
                            {item.reportData != null && item.reportData.map((data, index) => (
                               <React.Fragment>
                               {data.isExcludeFrom1099Total ?
                                  <tr onClick={() => this.onRowEdit(data.id,data)}>
                                    <td style={{backgroundColor:"lightgrey"}} title={this.state.exclude1099Note}> {data.date}</td>
                                    <td style={{backgroundColor:"lightgrey"}} title={this.state.exclude1099Note}>{data.transactionType}</td>
                                    <td style={{backgroundColor:"lightgrey"}} title={this.state.exclude1099Note} >{data.num}</td>
                                    <td style={{backgroundColor:"lightgrey"}} title={this.state.exclude1099Note}>{data.memoOrDescription}</td>
                                    <td style={{backgroundColor:"lightgrey"}} title={this.state.exclude1099Note}> {data.accountValue}</td>
                                    <td style={{backgroundColor:"lightgrey"}} title={this.state.exclude1099Note} className="text-center pr-2">${numberWithCommas(data.amount)}</td>
                                    <td style={{backgroundColor:"lightgrey"}} title={this.state.exclude1099Note} className="text-center"> <i class="zmdi zmdi-close zmdi-hc-2x" style={{color:"red"}}></i></td>
                                    <td style={{backgroundColor:"lightgrey"}} title={this.state.exclude1099Note} className="text-center">
                                      <div className="panel-commentCount">
                                        <img src="/vendors/Vector Smart Object.png" alt="" className={data.commentCount === 0 ? "disableCommentsOpacity" : ""} onClick={(e) => this.LoadComments(e, data)}></img>
                                        {data.unreviewCommentCount !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">{data.unreviewCommentCount}</span>}
                                      </div>
                                    </td>
                                  </tr>
                                :
                                  <tr onClick={() => this.onRowEdit(data.id,data)}>
                                    <td> {data.date}</td>
                                    <td>{data.transactionType}</td>
                                    <td >{data.num}</td>
                                    <td>{data.memoOrDescription}</td>
                                    <td> {data.accountValue}</td>
                                    <td className="text-center pr-2">${numberWithCommas(data.amount)}</td>
                                    <td></td>
                                    <td className="text-center">
                                      <div className="panel-commentCount">
                                        <img src="/vendors/Vector Smart Object.png" alt="" className={data.commentCount === 0 ? "disableCommentsOpacity" : ""} onClick={(e) => this.LoadComments(e, data)}></img>
                                        {data.unreviewCommentCount !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">{data.unreviewCommentCount}</span>}
                                      </div>
                                    </td>
                                  </tr>
                                }
                              {this.state.editIndex === data.id && //item.vendor1099 &&
                                <React.Fragment>
                                  <tr className="editRowColor">
                                    <td colSpan={6}></td>
                                    <td className="text-center">                                                                        
                                      <div className="chiller_cb ">
                                          <input id="isExcludeFrom1099Total" name="isExcludeFrom1099Total" type="checkbox" checked={this.state.isExcludeFrom1099ReviewTotal} onChange={(e) => this.onChange1099ExcludeCheckbox(e)}/>
                                          <label for="isExcludeFrom1099Total">
                                          <p className="checkbox-title" style={{fontSize:"14px"}}>Exclude From 1099 Total</p>
                                          </label>
                                          <span></span>
                                      </div>                                                                            
                                    </td>
                                    <td className="text-center" colSpan="2">
                                      <div className="col-md-12">
                                          <Button variant="raised" className="jr-btn jr-btn-sm bg-teal text-white" onClick={() => this.onRowSave(data.id, data,item)}>SAVE</Button>
                                          <Button variant="raised" className="jr-btn jr-btn-sm bg-danger text-white" onClick={() => this.onRowCancel(data.id, data)}>CANCEL</Button>
                                      </div>
                                    </td>
                                  </tr>
                                </React.Fragment>
                              }
                              </React.Fragment>
                            ))}
                            {item.reportData.filter(a => a.isExcludeFrom1099Total == true).length > 0 &&
                              <React.Fragment>
                                  <tr>
                                       <td colSpan="4"></td>
                                       <td><h2 className="total-payments-text">Adjusted Total 1099 Payments</h2></td>
                                       <td className="text-center pr-2"><h1 className="total-text"> ${Getsum(item.reportData.filter(a => a.isExcludeFrom1099Total != true), "amount")} </h1></td>
                                       <td></td>
                                       <td></td>
                                  </tr>
                               </React.Fragment>
                            }
                                  <tr>
                                   <td colSpan="4"></td>
                                   <td><h2 className="total-payments-text">Total Payments</h2></td>
                                   <td className="text-center pr-2"><h1 className="total-text"> ${Getsum(item.reportData, "amount")} </h1></td>
                                   <td></td>
                                   <td></td>
                                  </tr>
                            
                          </React.Fragment>
                          :
                          <tr>
                            <td className="text-center" colSpan="8">
                            No issues here! Keep up the good work!
                                </td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              ))}
            </React.Fragment>
          }
        </div>
      </div>
    )
  }


}

const mapStateToProps = (state) => {
  return {
    ComplianceReviewData: state.ledgers.ComplianceReviewData,
    UpdateDescription: state.ReportData.UpdateDescription,
    ARCRSummary: state.ReportData.ARCRSummary,
    LEDGER_UPDATE: state.ledgers.LEDGER_UPDATE
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    Get1099ComplianceReview,
    MarkAccountantApproval,
    AccountReconciliationReviewSummery,
    Update1099ReviewExcludeTotalStatus
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ACCAproval1099);
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import CircularProgress from './../../../components/CircularProgress';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import { toast } from 'react-toastify';
import PaginationComponent from "./../../../../src/app/common/Pagination";
import { GET_REPORT_DATA_BES, MARK_RESOLVED_BES_DATA } from "./../../../appRedux/actions/ReportDetailAction";
import { ReportType, numberWithCommas, makeLinkForQuickbooks, ModuleType, Base64,LinkType } from "./../../../app/common/commonServices";
import CommonComments from './../../../../src/app/common/Comments';
import { ToastContainer } from "react-toastify";
import queryString from 'query-string';
import RefreshDataModal from '../../../components/RefreshDataModal'

class RecEmpPayPayroll_Embedded extends Component {

    constructor(props) {
        super(props)
        this.state = {
            parantData: this.props.location.state ? this.props.location.state : null,
            MissingData: [],
            companyId: 0,
            sortField: 'date',
            sortFormat: "D",
            PageNo: 1,
            PageSize: 10,
            ColorCode: "#f0ca4c",
            sDate: new Date(2019, 0, 1),
            eDate: new Date(),
            loading: false,
            TotalRecords: 0,
            CommentReferenceId: 0,
            IsEmbeddedPage: true,
            SelectedRecords: [],
            SelectALL: false,
            HomeLink: "",
            ApproverName: null,
            companyName: "",
            isRefreshDataModalOpen:false
        }
    }

    componentDidMount() {
        let value = queryString.parse(this.props.location.search);

        // localStorage.setItem("homeURL", window.location.href.replace(window.location.origin, ""))
        let dashboardURl = localStorage.getItem("homeURL");
        this.setState({
            //loading: true,
            HomeLink: dashboardURl ? dashboardURl : "?q="+ value.q + "&p=" + value.p
        }, () => {
            this.getCompanyId();
        });
    }
    getCompanyId = () => {
        let value = queryString.parse(this.props.location.search);
        localStorage.setItem("q", JSON.stringify(value));
        let data = localStorage.getItem("q");

        if (data != null && data != undefined) {
            data = JSON.parse(data);
            this.setState({
                companyId: parseInt(Base64.decode(data.q)),
                companyName:Base64.decode(data.p),
                loading: true
            }, () => {
                this.GetReportData();
            });
        }
        else {
            this.setState({ loading: false });
        }
    }

    onSortChange = (fieldName) => {
        if (this.state.sortField == fieldName) {
            let formate = this.state.sortFormat == 'A' ? 'D' : 'A';
            this.setState({ sortFormat: formate, loading: true }, () => {
                this.GetReportData();
            });
        } else {
            this.setState({ sortField: fieldName, sortFormat: 'A', loading: true }, () => {
                this.GetReportData();
            });
        }
    }


    refreshData = () => {
        if (!this.state.loading) {
            this.setState({ loading: true,isRefreshDataModalOpen:false }, () => {
                this.GetReportData(true);
            });
        }
    }

    onRefreshDataClick = () => {
        
        this.setState({isRefreshDataModalOpen:true})
    }

    handleRefreshDataModal=()=>{
        this.setState({isRefreshDataModalOpen:false})
      }
    ChangePageSize = (size) => {

        this.setState({ loading: true, PageSize: parseInt(size), PageNo: 1 }, () => {
            this.GetReportData();
        });
    }

    ChangePage = (page) => {
        this.setState({ loading: true, PageNo: page, }, () => {
            this.GetReportData();
        });
    }

    onCheckboxClick = (e, item) => {
        e.stopPropagation();
        let existInArray = false;
        let data = this.state.SelectedRecords;
        if (data != null && data.length > 0 && data.indexOf(item.id) > -1) {
            existInArray = true;
        }

        if (e.target.checked) {
            if (!existInArray) {
                data.push(item.id);
            }
        }
        else {
            if (existInArray) {
                data.splice(data.indexOf(item.id), 1);
            }
        }
        let all = (data.length == this.state.MissingData.length) ? true : false;
        this.setState({ SelectedRecords: data, SelectALL: all, editIndex: -1 });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.ReportData !== this.props.ReportData) {
            if (nextProps.ReportData.transactionStatus === 0) {
                var totalrecords = 0;
                if (nextProps.ReportData.returnObject != null && nextProps.ReportData.returnObject.length > 0) {
                    totalrecords = nextProps.ReportData.returnObject[0].totalrows
                }
                this.setState({
                    MissingData: nextProps.ReportData.returnObject, ApproverName: null, TotalRecords: totalrecords, PageSize: this.state.PageSize,
                    PageNo: this.state.PageNo, loading: false, SelectALL: false
                });
            }
            else {
                toast.error(nextProps.ReportData.resultMsg);
                this.setState({ loading: false });
            }
        }
        if (nextProps.UpdateDescription !== this.props.UpdateDescription) {
            if (nextProps.UpdateDescription.transactionStatus === 0) {
                this.setState({
                    loading: true, editData: null, ApproverName: null, editIndex: -1, SelectALL: false, SelectedRecords: [],PageNo:1
                }, () => {
                    toast.success(`Recorded in payroll system successfully`);
                    this.GetReportData();
                });
            }
            else {
                this.setState({
                    loading: false,
                }, () => {
                    toast.error(`${nextProps.UpdateDescription.resultMsg}`);
                });
            }
        }
    }

    GetReportData = (IsRefresh = false) => {
        let state = this.state;
        let body = {
            companyID: state.companyId,
            reportType: ReportType.RecordEmployeePaymentInPayroll,
            fromDate: moment(state.sDate).format("MM/DD/YYYY"),
            toDate: moment(state.eDate).format("MM/DD/YYYY"),
            sortField: state.sortField,
            sortFormat: state.sortFormat,
            pageNumber: state.PageNo,
            pageSize: state.PageSize,
            needUpdatQBORecords: IsRefresh
        };

        this.props.GET_REPORT_DATA_BES(body);
    }



    onLinkClick = (rowData) => {
        let origin = makeLinkForQuickbooks();
        window.open(origin + rowData.transactionLink);
    }


    LoadComments = (item) => {
        this.setState({ IsCommentModelOpen: true, CommentReferenceId: item.id });
    }
    UpdateItemCommentCount = (id) => {
        let data = this.state.MissingData;
        data.forEach(function (item) {

            if (item.id === id) {
                item.commentCount++
            }
        });

        this.setState({ MissingData: data });
    }

    CloseComments = () => {
        this.setState({ IsCommentModelOpen: false });
    }



    handleApprovedBy = (e) => {
        this.setState({ ApproverName: e.target.value });
    }
    SelectALL = (e) => {
        if (this.state.MissingData.length > 0) {
            if (e.target.checked) {
                let all = [];
                let data = this.state.MissingData;
                for (let i = 0; i < data.length; i++) {
                    all.push(data[i].id);
                }
                this.setState({ SelectALL: true, SelectedRecords: all });
            }
            else {
                this.setState({ SelectALL: false, SelectedRecords: [] });
            }
        }
    }

    OnApplyResolved = () => {
        let selectedData = this.state.SelectedRecords;
        if (selectedData == null || selectedData.length === 0) {
            toast.error("Please select atleast 1 record for processing request");
            return false;
        }

        let approver = this.state.ApproverName;
        if (approver == null || approver.trim() === "") {
            toast.error("Please enter approver name");
            return false;
        }

        let body = {
            "ids": selectedData,
            "reportType": ReportType.RecordEmployeePaymentInPayroll,
            "companyID": this.state.companyId,
            "createdByName": approver
        }

        this.setState({ loading: true }, () => {
            this.props.MARK_RESOLVED_BES_DATA(body);
        });
    }


    render() {
        let selectedItem = this.state.SelectedRecords;
        return (
            <React.Fragment>
                <RefreshDataModal visible={this.state.isRefreshDataModalOpen}
                 handlePopUpClose={this.handleRefreshDataModal} refreshData={this.refreshData}/>
                {this.state.IsCommentModelOpen ?
                    <CommonComments
                        CloseComments={this.CloseComments}
                        ColorCode={this.state.ColorCode}
                        ModuleType={ModuleType.GeneralReportType}
                        ReportType={ReportType.RecordEmployeePaymentInPayroll}
                        CompanyId={this.state.companyId}
                        RecordId={this.state.CommentReferenceId}
                        IsEmbeddedPage={this.state.IsEmbeddedPage}
                        UpdateItemCommentCount={this.UpdateItemCommentCount}
                    >
                    </CommonComments> : ""}
                <div className="dashboardPage embeded-dashboard">
                    <ToastContainer autoClose={3000} />
                    <div className=" page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
                        <h2 className="mb-3 mb-sm-0">{this.state.companyName} | {LinkType.RecEmpPayPayroll}</h2>
                        <Link className="home-link" to={this.state.HomeLink} > Home </Link>
                    </div>
                    {this.state.loading ? <CircularProgress></CircularProgress>
                        :
                        <React.Fragment>
                            <div className="padding-box app-wrapper bgcolor">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-12">
                                            <div className='d-flex justify-content-end align-items-center'>
                                                {/* {this.state.SelectedRecords != null && this.state.SelectedRecords.length > 0 &&
                                                   <React.Fragment>
                                                    <h4 for="staticEmail" class=" col-form-label">Approved By:</h4> &nbsp;&nbsp;
                                                    <input type="text" style={{marginRight:"10px"}} placeholder="enter your name" onChange={this.handleApprovedBy} />
                                                    </React.Fragment>
                                            }
                                                    <Button variant="raised" className=" jr-btn g-teal  text-white refresh-btn graycolor" onClick={() => this.OnApplyResolved()}  >Recorded in Payroll System</Button> */}
                                                    <Button variant="raised" style={{ backgroundColor: this.state.ColorCode }} className="jr-btn text-white refresh-btn" onClick={() => this.onRefreshDataClick()}   ><i className="zmdi zmdi-refresh "></i> Refresh Data</Button>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="row" style={{ marginTop: "20px" }}>
                                            <div className="col-md-12">
                                            <div className='d-flex justify-content-end align-items-center'>
                                            {this.state.SelectedRecords != null && this.state.SelectedRecords.length > 0 &&
                                                   <React.Fragment>
                                                    <h4 for="staticEmail" class=" col-form-label">Approved By:</h4> &nbsp;&nbsp;
                                                    <input type="text" style={{marginRight:"10px"}} placeholder="enter your name" onChange={this.handleApprovedBy} />
                                                    </React.Fragment>
                                            }
                                                    <Button variant="raised" className=" jr-btn g-teal  text-white refresh-btn graycolor" onClick={() => this.OnApplyResolved()}  >Recorded in Payroll System</Button>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="table-responsive-material">
                                    <table className="table tablebox account-reconcilliation">
                                        <thead  >
                                            <tr style={{ backgroundColor: this.state.ColorCode }}>
                                                <th className={`td-width ${this.state.sortField == 'date' ? this.state.sortFormat == 'A'
                                                    ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('date')}><p className="table-th-title"> Date</p> </th>
                                                <th className={`td-width ${this.state.sortField == 'name' ? this.state.sortFormat == 'A'
                                                    ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('name')}><p className="table-th-title">Name</p> </th>
                                                <th className={`td-width ${this.state.sortField === 'transactionType' ? this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('transactionType')}><p className="table-th-title">Type</p> </th>
                                                <th className={` ${this.state.sortField == 'memoOrDescription' ? this.state.sortFormat == 'A'
                                                    ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('memoOrDescription')}><p className="table-th-title"> Memo/Desc.</p></th>
                                                
                                                <th className={`td-width text-center ${this.state.sortField == 'amount' ? this.state.sortFormat == 'A'
                                                    ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('amount')}><p className="table-th-title"> Amount</p></th>
                                                <th className="td-width-90 text-center">
                                                    <label className="container101 header-check">
                                                        {this.state.SelectALL ?
                                                            <input className="second-check-box" checked="checked" type="checkbox" onClick={(e) => this.SelectALL(e)} />
                                                            :
                                                            <input className="second-check-box" type="checkbox" onClick={(e) => this.SelectALL(e)} />
                                                        }
                                                        <span className="checkmark101 yellowMark white_border"></span>
                                                    </label>
                                                </th>
                                                <th className="td-width-90 text-center"><img src="/vendors/WhiteCommnet.png" alt="comments" /></th>
                                                <th className="td-width-90 text-center "><img src="/vendors/Link-arrow_header.png" alt="link" /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(this.state.MissingData && this.state.MissingData.length > 0) && this.state.MissingData.map((item, j) => (
                                                <React.Fragment key={j}>
                                                    {<tr>
                                                        <td>{item.date}</td>
                                                        <td>{item.name}</td>
                                                        <td>{item.transactionType}</td>
                                                        <td>{item.memoOrDescription}</td>
                                                        
                                                        <td className="text-center">{numberWithCommas(item.amount)}</td>
                                                        <td onClick={(e) => e.stopPropagation()}>
                                                            <label className="container101">
                                                                {(this.state.SelectALL || selectedItem.length > 0 && (selectedItem.indexOf(item.id) > -1)) ?
                                                                    <input className="second-check-box" checked="checked" type="checkbox" onClick={(e) => this.onCheckboxClick(e, item)} />
                                                                    :
                                                                    <input className="second-check-box" type="checkbox" onClick={(e) => this.onCheckboxClick(e, item)} />
                                                                }
                                                                <span className="checkmark101 green_CheckMark"></span>
                                                            </label>
                                                        </td>
                                                        <td className="text-center" onClick={(e) => e.stopPropagation()}>
                                                            <div className="panel-commentCount">
                                                                <img src="/vendors/Vector Smart Object.png" alt="comments" className={item.commentCount === 0 ? "disableCommentsOpacity" : ""} onClick={() => this.LoadComments(item)}></img>
                                                                {item.unreviewCommentCount !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">{item.unreviewCommentCount}</span>}
                                                            </div>
                                                        </td>
                                                        <td className="text-center" onClick={(e) => e.stopPropagation()}><img src="/vendors/Yellow-link.png" alt="link" onClick={() => this.onLinkClick(item)}></img></td>
                                                    </tr>}
                                                </React.Fragment>
                                            ))}
                                            {this.state.MissingData.length == 0 ?
                                                <tr>
                                                    <td className="text-center" colSpan="10">
                                                    No issues here! Keep up the good work!
                                                </td>
                                                </tr>
                                                : ""}
                                        </tbody>
                                    </table>
                                </div>
                                <PaginationComponent
                                    selectedPageNo={this.state.PageNo}
                                    selectedSize={this.state.PageSize}
                                    TotalRecords={this.state.TotalRecords}
                                    ChangePageSize={this.ChangePageSize}
                                    ChangePage={this.ChangePage}
                                    CurrentRecords={this.state.MissingData.length}
                                >
                                </PaginationComponent>
                            </div>
                        </React.Fragment>
                    }

                </div>
            </React.Fragment>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        ReportData: state.ReportData.ReportData,
        UpdateDescription: state.ReportData.UpdateDescription
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        GET_REPORT_DATA_BES,
        MARK_RESOLVED_BES_DATA
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(RecEmpPayPayroll_Embedded);
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import CircularProgress from './../../../../components/CircularProgress';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import { toast } from 'react-toastify';
import PaginationComponent from './../../../common/Pagination';
import { GET_REPORT_DATA, MARK_IGNORED_DATA, Revert_Ignored_DATA, UpdateTransactionCheckNumber } from '../../../../appRedux/actions/ReportDetailAction';
import { ReportType, numberWithCommas, makeLinkForQuickbooks, ModuleType, LinkType } from '../../../common/commonServices';
import CommonComments from './../../../common/Comments';
import Textarea from 'react-expanding-textarea';
import {MarkReportReview,GetReportReviewedHistory } from '../../../../appRedux/actions/LedgerAction';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ClearIcon from '@material-ui/icons/Clear';


class MissRefNo extends Component {

    constructor(props) {
        super(props);

        this.state = {
            parantData: this.props.location.state ? this.props.location.state : null,
            MissingData: [],
            companyId: 0,
            sortField: 'date',
            sortFormat: "D",
            PageNo: 1,
            PageSize: 10,
            ColorCode: "#e27b40",
            sDate: new Date(2019, 0, 1),
            eDate: new Date(),
            loading: false,
            TotalRecords: 0,
            CommentReferenceId: 0,
            IsEmbeddedPage: false,
            SelectedRecords: [],
            SelectALL: false,
            IsIgnoredFilter: false,
            isEdited: false,
            ShowHistory:true,
            HistoryData:[]
        }
    }

    onCheckboxClick = (e, item) => {
        e.stopPropagation();


        let existInArray = false;
        let data = this.state.SelectedRecords;
        if (data != null && data.length > 0 && data.indexOf(item.id) > -1) {
            existInArray = true;
        }

        if (e.target.checked) {
            if (!existInArray) {
                data.push(item.id);
            }
        }
        else {
            if (existInArray) {
                data.splice(data.indexOf(item.id), 1);
            }
        }

        let all = (data.length == this.state.MissingData.length) ? true : false;
        this.setState({ SelectedRecords: data, SelectALL: all, editIndex: -1 });

    }

    HandleClose = () => {
        this.setState({ ShowHistory: false });
      }
    SelectALL = (e) => {
        if (this.state.MissingData.length > 0) {
            if (e.target.checked) {
                let all = [];
                let data = this.state.MissingData;
                for (let i = 0; i < data.length; i++) {
                    all.push(data[i].id);
                }
                this.setState({ SelectALL: true, SelectedRecords: all });
            }
            else {
                this.setState({ SelectALL: false, SelectedRecords: [] });
            }
        }
    }

    OnApplyIgnore = () => {
        let selectedData = this.state.SelectedRecords;
        if (selectedData == null || selectedData.length === 0) {
            toast.error("Please select atleast 1 record for processing request");
            return false;
        }

        let body = {
            "ids": selectedData,
            "reportType": ReportType.ChecksMissingReferenceNumber,
            "companyID": this.state.companyId
        }

        this.setState({ loading: true }, () => {

            if (this.state.IsIgnoredFilter) {
                this.props.Revert_Ignored_DATA(body);
            }
            else {
                this.props.MARK_IGNORED_DATA(body);
            }
        });

    }

    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('userData'));
        this.setState({
            companyId: user.companyId,
            loading: true
        }, () => {
            this.props.GetReportReviewedHistory(user.companyId,ReportType.ChecksMissingReferenceNumber)
            this.GetReportData();
        });
    }

    onLinkClick = (rowData) => {
        let origin = makeLinkForQuickbooks();
        window.open(origin + rowData.transactionLink);
    }

    LoadComments = (item) => {
        this.setState({ IsCommentModelOpen: true, CommentReferenceId: item.id });
    }

    UpdateItemCommentCount = (id) => {
        let data = this.state.MissingData;
        data.forEach(function (item) {

            if (item.id === id) {
                item.commentCount++
            }
        });

        this.setState({ MissingData: data });
    }

    CloseComments = () => {
        this.setState({ IsCommentModelOpen: false });
    }


    onSortChange = (fieldName) => {
        if (this.state.sortField === fieldName) {
            let formate = this.state.sortFormat === 'A' ? 'D' : 'A';
            this.setState({ sortFormat: formate, loading: true }, () => {
                this.GetReportData();
            });
        } else {
            this.setState({ sortField: fieldName, sortFormat: 'A', loading: true }, () => {
                this.GetReportData();
            });
        }
    }


    onRefreshDataClick = () => {
        if (!this.state.loading) {
            this.setState({ loading: true }, () => {
                this.GetReportData(true);
            });
        }
    }
    onMarkReviewd = () => {
        this.setState({ 
            // IsReviewProcessing: true,
          //  loading: true
          loading: false
           }, () => {
          this.props.MarkReportReview(this.state.companyId,ReportType.ChecksMissingReferenceNumber);
        });
      }
    ChangePageSize = (size) => {

        this.setState({
            loading: true, PageSize: parseInt(size), PageNo: 1, SelectedRecords: [],
            SelectALL: false
        }, () => {
            this.GetReportData();
        });
    }

    ChangePage = (page) => {
        this.setState({
            loading: true, PageNo: page, SelectedRecords: [],
            SelectALL: false
        }, () => {
            this.GetReportData();
        });
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.ReportData !== this.props.ReportData) {
            if (nextProps.ReportData.transactionStatus === 0) {
                var totalrecords = 0;
                if (nextProps.ReportData.returnObject != null && nextProps.ReportData.returnObject.length > 0) {
                    totalrecords = nextProps.ReportData.returnObject[0].totalrows
                }
                this.setState({ MissingData: nextProps.ReportData.returnObject, TotalRecords: totalrecords, PageSize: this.state.PageSize, PageNo: this.state.PageNo, loading: false, SelectALL: false, SelectedRecords: [] });
            } else {
                toast.error(nextProps.ReportData.resultMsg);
                this.setState({ loading: false });
            }
        }

        if (nextProps.ReportReviewdHistory !== undefined && nextProps.ReportReviewdHistory !== this.props.ReportReviewdHistory) {
            if (nextProps.ReportReviewdHistory.transactionStatus === 0) {
                    this.setState({ShowHistory: true,HistoryData:nextProps.ReportReviewdHistory.returnObject});
            } 
        }
        if (nextProps.CommonUpdate !== undefined && nextProps.CommonUpdate !== this.props.CommonUpdate) {
            if (nextProps.CommonUpdate.transactionStatus === 0) {
            //   let data = this.state.ReportData;
            //   if (this.state.IsReviewProcessing == false) {
            //     // let obj = this.state.editedData;
            //     // data.map((item, index) => {
            //     //   if (item.listID === obj.listID) {
            //     //     item.vendor1099 = obj.vendor1099
            //     //   }
            //     // })
      
            //     this.setState({editedData: null, IsReviewProcessing: false, reportData: data }, () => { toast.success("Request Process Successfully");
            //     // this.GetReportData();
            //    });
            //   }
            //   else {
                this.setState({ IsReviewProcessing: false}, () => {
                  toast.success("Reviewed Successfully");
                  this.props.GetReportReviewedHistory(this.state.companyId,ReportType.ChecksMissingReferenceNumber)
                });
            //   }
            }
            else {
              this.setState({ loading: false }, () => { toast.error(`${nextProps.CommonUpdate.resultMsg}`); });
            }
      
          }
        if (nextProps.UpdateDescription !== this.props.UpdateDescription) {
            if (nextProps.UpdateDescription.transactionStatus === 0) {
                this.setState({
                    loading: true, editIndex: -1, SelectALL: false, SelectedRecords: []
                }, () => {
                    toast.success(`${nextProps.UpdateDescription.resultMsg}`);
                    this.GetReportData();
                });
            }
            else {
                this.setState({
                    loading: false,
                }, () => {
                    toast.error(`${nextProps.UpdateDescription.resultMsg}`);
                });
            }
        }

        if (nextProps.UpdateCheckNo !== this.props.UpdateCheckNo) {
            if (nextProps.UpdateCheckNo.transactionStatus === 0) {
                this.setState({
                    loading: true, editIndex: -1, SelectALL: false, SelectedRecords: [], isEdited: false
                }, () => {
                    toast.success(`${nextProps.UpdateCheckNo.resultMsg}`);
                    this.GetReportData();
                });
            }
            else {
                this.setState({
                    loading: false,
                }, () => {
                    toast.error(`${nextProps.UpdateCheckNo.resultMsg}`);
                });
            }
        }
    }

    GetReportData = (IsRefresh = false) => {
        let state = this.state;
        let body = {
            companyID: state.companyId,
            reportType: ReportType.ChecksMissingReferenceNumber,
            fromDate: moment(state.sDate).format("MM/DD/YYYY"),
            toDate: moment(state.eDate).format("MM/DD/YYYY"),
            sortField: state.sortField,
            sortFormat: state.sortFormat,
            pageNumber: state.PageNo,
            pageSize: state.PageSize,
            needUpdatQBORecords: IsRefresh,
            IsIgnore: true,
            isAllIgnoreUnignore: state.IsIgnoredFilter
        };

        this.props.GET_REPORT_DATA(body);
    }

    HandleIgnored = (event) => {

        this.setState({ IsIgnoredFilter: (event.target.value == "true" ? true : false), loading: true }, () => { this.GetReportData() });
    }

    onRowEdit = (item) => {
        if(item.transactionType != "Payroll Check"){
            this.setState({ isEdited: true });
        }
    };

    onCancelEdit = () => {
        const updatedArray = this.state.MissingData.map(item => ({
            ...item,
            num: '', // Change the 'name' property to an empty string
        }));
        this.setState({ MissingData: updatedArray, isEdited: false });
    };

    ChangeCheckNo = (e, index) => {
        var data = this.state.MissingData;
        data[index].num = e.target.value
        this.setState({ MissingData: data });
    }

    UpdateCheckNo = () => {
        
        let filerData = this.state.MissingData.filter(x => x.num);
        if (filerData != null && filerData.length > 0) {
            let arrayData = []

            filerData.forEach((item) => {
                arrayData.push({ id: item.id, checkNumber: item.num });
            });

            let body = {
                lstTransactions: arrayData,
                companyId: this.state.companyId
            }
            this.setState({
                loading: true
            }, () => {
                this.props.UpdateTransactionCheckNumber(body);
            });

        } else {
            toast.error("Please enter atleast 1 record Check No.");
            return false;
        }
    }



    render() {
        let selectedItem = this.state.SelectedRecords;
        return (
            <div className="dashboardPage">
                {this.state.ShowHistory && this.state.HistoryData != null && this.state.HistoryData.length > 0 &&
          <React.Fragment>
            <Dialog
              className="comment-Top-align"
              maxWidth="sm"
              fullWidth={true}
              open={true}>
              <React.Fragment>
                <div className="Comments modal-header"><span className="log-history-title float-left">Reviewed History </span>
                  <Button className="jr-btn close-btn float-right" onClick={this.HandleClose}><ClearIcon /></Button>
                </div>
                <DialogContent>
                  {this.state.HistoryData.length == 0 ?
                    <div className="history-box">
                      <p className="history-item-text">No History Found</p>
                    </div>
                    :
                    <React.Fragment>
                      {this.state.HistoryData.map((item, i) => (
                        <div className="history-box">
                          {/* <p className="history-item-text">
                           
                          </p>
                           */}
                          <p className="history-date-text"> <strong style={{ color: "black" }}>  Last Reviewed  </strong> By <strong> {item.userName}</strong> <strong> on </strong>{item.stCreatedDate}</p>
                        </div>
                      ))}
                    </React.Fragment>
                  }
                </DialogContent>
              </React.Fragment>
            </Dialog>
          </React.Fragment>

        }
                <div className="page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
                    <h2 className="mb-3 mb-sm-0">{JSON.parse(localStorage.getItem('userData')) != null ? JSON.parse(localStorage.getItem('userData')).companyName : ""} | {LinkType.MissRefNo}</h2>
                </div>
                {this.state.IsCommentModelOpen ? <CommonComments
                    CloseComments={this.CloseComments}
                    ColorCode={this.state.ColorCode}
                    ModuleType={ModuleType.GeneralReportType}
                    ReportType={ReportType.ChecksMissingReferenceNumber}
                    CompanyId={this.state.companyId}
                    RecordId={this.state.CommentReferenceId}
                    IsEmbeddedPage={this.state.IsEmbeddedPage}
                    UpdateItemCommentCount={this.UpdateItemCommentCount}
                ></CommonComments> : ""}
                {this.state.loading ? <CircularProgress></CircularProgress>
                    :
                    <React.Fragment>
                        <div className="padding-box app-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="float-right">
                                                {/* <span> <b> Show Previously Ignored Transactions  </b> : </span>
                                                <select style={{ backgroundColor: "#fff", marginRight: "10px" }} className="select-box arrow-box" onChange={this.HandleIgnored} value={this.state.IsIgnoredFilter} >
                                                    <option value={true}> YES </option>
                                                    <option value={false}> NO </option>                                                    
                                                </select> */}
                                                <Button variant="raised" className="graycolor jr-btn bg-teal text-white refresh-btn" onClick={() => this.onMarkReviewd()}>Mark as Reviewed</Button>
                                                <Button variant="raised" style={{ backgroundColor: this.state.ColorCode }} className="jr-btn text-white refresh-btn" onClick={() => this.onRefreshDataClick()}  ><i className="zmdi zmdi-refresh "></i> Refresh Data</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div className="col-md-12">
                                    <div className='d-flex justify-content-end align-items-center'>
                                        {this.state.isEdited === true &&
                                            <div className=''>
                                                <div class="form-group d-flex mb-0 align-items-center">
                                                    <Button variant="raised" className=" jr-btn g-teal  text-white refresh-btn graycolor" onClick={() => this.UpdateCheckNo()}>Update Check No.</Button>
                                                    <Button variant="raised" className=" jr-btn g-teal  text-white refresh-btn redishOrangecolor" onClick={() => this.onCancelEdit()}>Cancel</Button>
                                                </div>
                                            </div>
                                        }

                                        <div className='show-previously-box'>
                                            <div class="form-group d-flex mb-0">
                                                <h4 for="staticEmail" class="col-form-label">Show Previously Ignored Transactions:</h4>
                                                <div class="dropdown-input-box">
                                                    <select style={{ backgroundColor: "#fff", marginRight: "10px" }} className="select-box arrow-box" onChange={this.HandleIgnored} value={this.state.IsIgnoredFilter} >
                                                        <option value={true}> YES </option>
                                                        <option value={false}> NO </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=''>
                                            <div class="form-group d-flex mb-0 align-items-center">
                                                <Button variant="raised" className=" jr-btn g-teal  text-white refresh-btn graycolor" onClick={() => this.OnApplyIgnore()}>{this.state.IsIgnoredFilter ? "Revert" : "Ignore"}</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive-material">
                                <table className="table tablebox account-reconcilliation">
                                    <thead>
                                        <tr style={{ backgroundColor: this.state.ColorCode }}>
                                            <th className={`td-width ${this.state.sortField === 'date' ? this.state.sortFormat === 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('date')}><p className="table-th-title">Date</p> </th>
                                            <th className={`td-width  ${this.state.sortField === 'transactionType' ? this.state.sortFormat === 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('transactionType')}><p className="table-th-title">Transaction Type</p> </th>
                                            <th className={`td-width ${this.state.sortField === 'num' ? this.state.sortFormat === 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('num')}><p className="table-th-title">Check No.</p> </th>
                                            <th className={`td-width ${this.state.sortField === 'name' ? this.state.sortFormat === 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('name')}><p className="table-th-title">Name</p> </th>
                                            <th className={`td-width ${this.state.sortField === 'memoOrDescription' ? this.state.sortFormat === 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('memoOrDescription')}><p className="table-th-title">Memo/Description</p> </th>
                                            <th className={`td-width  text-center ${this.state.sortField === 'amount' ? this.state.sortFormat === 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('amount')}><p className="table-th-title">Amount</p> </th>

                                            <th className="td-width-90 text-center">
                                                <label className="container101 header-check">
                                                    {this.state.SelectALL ?
                                                        <input className="second-check-box" checked="checked" type="checkbox" onClick={(e) => this.SelectALL(e)} />
                                                        :
                                                        <input className="second-check-box" type="checkbox" onClick={(e) => this.SelectALL(e)} />
                                                    }
                                                    <span className="checkmark101 yellowMark white_border"></span>
                                                </label>
                                            </th>
                                            <th className="td-width-90 text-center"><img src="/vendors/WhiteCommnet.png" alt="comments" /></th>
                                            <th className="td-width-90 text-center "><img src="/vendors/Link-arrow_header.png" alt="link" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.MissingData.length > 0 ?
                                            (this.state.MissingData && this.state.MissingData.length > 0) && this.state.MissingData.map((item, j) => (
                                                <React.Fragment key={j}>
                                                    {<tr onClick={(e) => this.onRowEdit(item)}>
                                                        <td className={item.cpaApproved ? "icon-check" : ""}>{item.date}</td>
                                                        <td>{item.transactionType} </td>
                                                        {this.state.isEdited === true && item.transactionType != "Payroll Check" ?
                                                            <td>
                                                                <React.Fragment>
                                                                    <Textarea className="placheholder-color"
                                                                        value={item.num} name="num"
                                                                        style={{ 'lineHeight': '20px', resize: 'none', borderRadius: '5px', border: '2px solid #ccc', height: '28px', verticalAlign: 'middle', marginTop: '10px' }}
                                                                        resize={"false"} placeholder="" onChange={(e) => this.ChangeCheckNo(e, j)}
                                                                    />
                                                                </React.Fragment>
                                                            </td>
                                                            :
                                                            <td>{item.num} </td>
                                                        }
                                                        <td>{item.name}</td>
                                                        <td>{item.memoOrDescription}</td>
                                                        <td className="text-center">{numberWithCommas(item.amount)}</td>

                                                        <td onClick={(e) => e.stopPropagation()}>

                                                            <label className="container101">
                                                                {(this.state.SelectALL || selectedItem.length > 0 && (selectedItem.indexOf(item.id) > -1)) ?

                                                                    <input className="second-check-box" checked="checked" type="checkbox" onClick={(e) => this.onCheckboxClick(e, item)} />
                                                                    :
                                                                    <input className="second-check-box" type="checkbox" onClick={(e) => this.onCheckboxClick(e, item)} />
                                                                }
                                                                <span className="checkmark101 green_CheckMark"></span>
                                                            </label>
                                                        </td>
                                                        <td className="text-center" onClick={(e) => e.stopPropagation()}>
                                                            <div className="panel-commentCount">
                                                                <img src="/vendors/Vector Smart Object.png" alt="comments" className={item.commentCount === 0 ? "disableCommentsOpacity" : ""} onClick={() => this.LoadComments(item)}></img>
                                                                {item.unreviewCommentCount !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">{item.unreviewCommentCount}</span>}
                                                            </div>
                                                        </td>
                                                        <td className="text-center" onClick={(e) => e.stopPropagation()}><img src="/vendors/Shape 1.png" alt="link" onClick={() => this.onLinkClick(item)}></img></td>
                                                    </tr>
                                                    }
                                                </React.Fragment>
                                            ))
                                            :
                                            <tr>
                                                <td colSpan="9" align="center">
                                                    No issues here! Keep up the good work!
                                                </td>
                                            </tr>
                                        }

                                    </tbody>
                                </table>
                            </div>
                            <PaginationComponent
                                selectedPageNo={this.state.PageNo}
                                selectedSize={this.state.PageSize}
                                TotalRecords={this.state.TotalRecords}
                                ChangePageSize={this.ChangePageSize}
                                ChangePage={this.ChangePage}
                                CurrentRecords={this.state.MissingData.length}
                            >
                            </PaginationComponent>
                        </div>
                    </React.Fragment>
                }
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        ReportData: state.ReportData.ReportData,
        UpdateDescription: state.ReportData.UpdateDescription,
        UpdateCheckNo: state.ReportData.UpdateCheckNo,
        CommonUpdate: state.ledgers.CommonUpdate,
        ReportReviewdHistory:state.ledgers.ReportReviewedHistory
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        GET_REPORT_DATA,
        MARK_IGNORED_DATA,
        MarkReportReview,
        Revert_Ignored_DATA,
        GetReportReviewedHistory,
        UpdateTransactionCheckNumber
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(MissRefNo);
import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import {updateUser, assignUserCompanies} from '../appRedux/actions/companiesAction';
import {findIndex} from "lodash";
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import  jwt_decode from 'jwt-decode';
import RSelect from 'react-select';

if(JSON.parse(localStorage.getItem('userData'))) {
   var user=JSON.parse(localStorage.getItem('userData'));
let decode=jwt_decode(user.Authorization);
var role=decode['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
}




class EditUserForm extends React.Component {

    constructor() {
        super()
        this.state = {
            userId:'',
            companyId:'',
            firstName:'',
            lastName:'',
            userName:'',
            email:'',
            role:'',
            selectedCompanies:[]
        }
        this.handleSubmit=this.handleSubmit.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
    }
     componentWillReceiveProps(nextProps){

      if(this.props.visible !== nextProps.visible) {
         this.setState({
               userId:nextProps.selectedItem.id,
            companyId:nextProps.selectedItem.companyId,
            firstName:nextProps.selectedItem.firstName,
            lastName:nextProps.selectedItem.lastName,
            userName:nextProps.selectedItem.userName,
            email:nextProps.selectedItem.email,
            role:nextProps.selectedItem.role,
         });
      }
    }

    handleDelete = () => {
        this.props.handleDelete();
    }

    handleCancel = () => {
        this.props.handleCancel()
    };

    handleSubmit = (e) => {
        e.preventDefault();

        if(this.state.selectedCompanies.length > 0) {
        let comps = this.state.selectedCompanies;

        let coms = comps.map((val,i) => {
             let neData;
          if(i == 0 ) {
           neData = {
            "companyId": val.value,
            "isDefaultCompany": true
          }
          } else {
              neData = {
                "companyId": val.value,
              }
          }
          return neData;
        }) ;

        this.props.assignUserCompanies(coms,this.state.userId);

      }

           let data = {
                userId:this.state.userId,
                companyId:this.state.companyId,
                firstName:this.state.firstName,
                lastName:this.state.lastName,
                userName:this.state.userName,
                email:this.state.email,
                role:this.state.role
        };


        this.props.updateUser(data);
        this.props.handleSave(false);
     }


  handleCompChange = (selectedCompanies) => {
        this.setState({ selectedCompanies, err:false });
    }

    handleFormChange = (e) => {
       this.setState({[e.target.name]:e.target.value})
    }

    render() {
        const {visible, companiesData} = this.props;
      const { selectedCompanies } = this.state;
         const options = companiesData.filter(data1 => {
          return data1.companyId !== 1;
         }).map(data => {
            return {
              value:data.companyId,
              label:data.name
            }
         });

        return (
            <Dialog
            maxWidth="md"
            fullWidth={true}
            open={visible}
            onClose={this.handleCancel}
            >
            <form method="post" onSubmit={this.handleSubmit}  autoComplete="off" style={{ padding: 20 }}>
            <DialogTitle>Edit User</DialogTitle>
             <DialogContent>
                 <fieldset>
                     <Grid container spacing={24} >
                        <Grid item sm={4} >
                            <TextField
                                name="firstName"
                                label="First Name"
                                fullWidth
                                required
                                onChange={this.handleFormChange}
                                defaultValue={this.state.firstName}
                                margin="normal"
                            />
                        </Grid>
                        <Grid item sm={4} >
                           <TextField
                                name="lastName"
                                required={true}
                                label={"Last Name"}
                                fullWidth
                                onChange={this.handleFormChange}
                                defaultValue={this.state.lastName}
                                margin="normal"
                            />
                        </Grid>
                        <Grid item sm={4}>
                         <TextField
                                name="userName"
                                required={true}
                                label={"User Name"}
                                fullWidth
                                onChange={this.handleFormChange}
                                defaultValue={this.state.userName}
                                margin="normal"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={24} >
                         <Grid item sm={4}>
                             <TextField
                                name="email"
                                type="email"
                                required={true}
                                label={"Email"}
                                fullWidth
                                onChange={this.handleFormChange}
                                defaultValue={this.state.email}
                                margin="normal"
                            />
                        </Grid>
                  <Grid item sm={4} style={{paddingTop:25}}>
                              <InputLabel htmlFor="teax-native-simple">User Role</InputLabel>
                              <Select
                                required={true}
                                name="role"
                                native
                                value={this.state.role}
                                onChange={this.handleFormChange}
                                inputProps={{
                                  name: 'role',
                                  id: 'teax-native-simple',
                                }}
                                style={{'width':'100%','height':'32px'}}
                              >
                                <option value="0" disabled>Select Role</option>
                                { role == 'SuperAdmin' ? <option value={"SuperAdmin"}>SuperAdmin</option> : ''}
                                <option value={"Admin"}>Admin</option>
                                <option value={"User"}>User</option>
                                <option value={"Accountant"}>Accountant</option>
                              </Select>
                        </Grid>
                         <Grid item sm={4}  style={{paddingTop:20}}>
                          <InputLabel htmlFor="tax-native-simple">Assign Companies</InputLabel>
                             <RSelect
                               value={selectedCompanies}
                               onChange={this.handleCompChange}
                               options={options}
                               isMulti={true}
                             />
                        </Grid>
                    </Grid>

                    </fieldset>

                 </DialogContent>
                <DialogActions>
                <Button type="submit" color="primary">
                  Update
                </Button>
                <Button onClick={this.handleCancel} color="primary" autoFocus>
                  Cancel
                </Button>
                <Button onClick={this.handleDelete} color="primary">
                  Delete
                </Button>
              </DialogActions>
               </form>
            </Dialog>
        );
    }
}



const mapStateToProps = (state) => {
return {

}
};

const mapDispatchToProps=(dispatch)=>{
    return bindActionCreators({
        updateUser,
        assignUserCompanies
    },dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUserForm);

import React from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from '../util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import jwt_decode from 'jwt-decode';
import {
    loginUser
} from '../appRedux/actions/Auth';
import Snackbar from '@material-ui/core/Snackbar';




class SignIn extends React.Component {
    constructor() {
        super();
        this.state = {
            UserName: '',
            password: '',
            error: '',
            open: false,
            errShow1: false,
            errShow2: false,
            vertical: 'top',
            horizontal: 'center',
        }
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.state.UserName == "") {
            this.setState({
                errShow1: true
            });
        } else if (this.state.password == "") {
            this.setState({
                errShow2: true
            });
        } else {
            let data = {
                UserName: this.state.UserName,
                password: this.state.password,
            }
            this.props.loginUser(data);

            
        }
    };

    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('userData'));
        if (user != null) {
            const { history } = this.props;
            let decode = jwt_decode(user.Authorization);
            try
            {                
                if(decode.CompanyId!= user.companyId)
                {
                  history.push("/dashboard");
                }                
                if(decode.CompanyId== user.companyId)
                {
                  history.push("/home");
                }
            }
            catch(err)
            {  }            
        }        
    }

    componentWillReceiveProps(nextProps) {
        
        if (nextProps.error !== undefined && nextProps.error !== this.props.error) {
            this.setState({ open: true, error: nextProps.error });
        }
    }

    handleClose = () => {
        this.setState({ open: false });
    };


    render() {
        const { UserName, errShow1, errShow2, error, password, vertical, horizontal, open } = this.state;

        return (
            <div style={{ display: 'flex', width: '100%', 'backgroundImage': 'url(/vendors/compBack.png)' }}>
                <div
                    className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                    <div className="app-login-main-content">

                        <div className="app-logo-content d-flex align-items-center justify-content-center">
                            <Link className="logo-lg" to="/" title="Compliance">
                                <img style={{ 'maxWidth': 220 }} src="/vendors/Logo1.png" alt="Compliance" title="Compliance" />
                            </Link>
                        </div>

                        <div className="app-login-content">
                            <div className="app-login-header mb-4">
                                <h1><IntlMessages id="appModule.signIn" /></h1>
                            </div>
                          
                            <div className="app-login-form">
                                <form onSubmit={this.handleSubmit}>
                                    <fieldset>
                                        <TextField
                                            label={<IntlMessages id="appModule.username" />}
                                            fullWidth
                                            required={true}
                                            error={errShow1}
                                            onChange={(event) => this.setState({ UserName: event.target.value })}
                                            defaultValue={UserName}
                                            margin="normal"
                                            className="mt-1 my-sm-3"
                                        />
                                        <TextField
                                            type="password"
                                            required={true}
                                            error={errShow2}
                                            label={<IntlMessages id="appModule.password" />}
                                            fullWidth
                                            onChange={(event) => this.setState({ password: event.target.value })}
                                            defaultValue={password}
                                            margin="normal"
                                            className="mt-1 my-sm-3"
                                        />



                                        {/*  <div className="app-social-block my-1 my-sm-3">
                                                                          <IntlMessages
                                                                              id="signIn.connectWith"/>
                                                                          <ul className="social-link">
                                                                              <li>
                                                                                  <IconButton className="icon"
                                                                                              onClick={() => {
                                                                                                  this.props.showAuthLoader();
                                                                                                  this.props.userFacebookSignIn();
                                                                                              }}>
                                                                                      <i className="zmdi zmdi-facebook"/>
                                                                                  </IconButton>
                                                                              </li>

                                                                              <li>
                                                                                  <IconButton className="icon"
                                                                                              onClick={() => {
                                                                                                  this.props.showAuthLoader();
                                                                                                  this.props.userTwitterSignIn();
                                                                                              }}>
                                                                                      <i className="zmdi zmdi-twitter"/>
                                                                                  </IconButton>
                                                                              </li>

                                                                              <li>
                                                                                  <IconButton className="icon"
                                                                                              onClick={() => {
                                                                                                  this.props.showAuthLoader();
                                                                                                  this.props.userGoogleSignIn();

                                                                                              }}>
                                                                                      <i className="zmdi zmdi-google-plus"/>
                                                                                  </IconButton>
                                                                              </li>

                                                                              <li>
                                                                                  <IconButton className="icon"
                                                                                              onClick={() => {
                                                                                                  this.props.showAuthLoader();
                                                                                                  this.props.userGithubSignIn();
                                                                                              }}>
                                                                                      <i className="zmdi zmdi-github"/>
                                                                                  </IconButton>
                                                                              </li>
                                                                          </ul>
                                                                      </div>
                                  */}
                                    </fieldset>
                                    <div className="mb-3 d-flex align-items-center justify-content-between" style={{ marginTop: 20 }}>
                                        <Button type="submit" variant="contained" color="primary">
                                            <IntlMessages id="appModule.signIn" />
                                        </Button>

                                        <Link to="/forgotPassword">
                                            <IntlMessages id="appModule.forgotPassword" />
                                        </Link>

                                    </div>
                                    {/* <div>
                                        Build Number : 03-04-2020 1.0.035
									</div> */}
                                </form>
                            </div>
                            {this.props.loading === true ?
                                <div className="gx-loader-view">
                                    <CircularProgress />
                                </div> : null}
                        </div>
                    </div>

                    <Snackbar
                        anchorOrigin={{ vertical, horizontal }}
                        open={open}
                        onClose={this.handleClose}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<div id="message-id">{open ? error.status == 400 ? error.data.message : error.data.message : ''}</div>}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { auth } = state;
    return {
        user: auth.authUser,
        loading: auth.loading,
        error: auth.error
    }
};

export default connect(mapStateToProps, {
    loginUser
})(SignIn);

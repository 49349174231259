import React from "react";
import { bindActionCreators } from "redux";
import { updateLease, addLease } from '../appRedux/actions/leaseAction';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { connect } from "react-redux";
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import moment from 'moment';
import { Form } from "antd";
import MaskedInput from 'react-text-mask';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import Textarea from 'react-expanding-textarea';
import './form.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const FormItem = Form.Item;

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      prefix="$ "
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};


function NumberFormatCustom2(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}


    />
  );
}

NumberFormatCustom2.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

class Addleaseform extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      leaseID: 0,
      companyID: '',
      vendorID: '',
      financeCompany: '',
      terms: '',
      lastPaymentDate: new Date(),//moment().format('YYYY-MM-DD')
      monthlyPayments: '',
      automaticRenewal: '',
      securityDeposit: '',
      propertyTypeID: '',
      leaseType: '',
      comments: '',
      alertDate: new Date(),
      alertComments: '',
      leaseReviewComplete: '',
      completedBy: '',
      lastUpdatedDate: new Date(),
      selectedItem: false
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {

        let data = {
          leaseID: this.state.leaseID,
          companyID: this.props.companyID,
          vendorID: this.state.vendorID,
          financeCompany: this.state.financeCompany,
          terms: this.state.terms,
          lastPaymentDate:moment(this.state.lastPaymentDate).format('YYYY-MM-DD') ,
          monthlyPayments: Number(this.state.monthlyPayments),
          automaticRenewal: this.state.automaticRenewal,
          securityDeposit: Number(this.state.securityDeposit),
          propertyTypeID: this.state.propertyTypeID,
          leaseType: this.state.leaseType,
          comments: this.state.comments,
          alertDate: moment(this.state.alertDate).format('YYYY-MM-DD'),
          alertComments: this.state.alertComments,
          leaseReviewComplete: this.state.leaseReviewComplete,
          completedBy: this.state.completedBy,
          lastUpdatedDate: moment(this.state.lastUpdatedDate).format('YYYY-MM-DD')
        }
        if (!this.props.isEdit)
          this.props.addLease(data);
        else
          this.props.updateLease(data);
        this.setDefaultState();
        this.props.handleSave(false);
      }
    });
  }
  componentWillReceiveProps(nextProps) {    
    if (nextProps.selectedItem != this.state.selectedItem) {
      this.setState({
        selectedItem: nextProps.selectedItem,
        leaseID: nextProps.selectedItem.leaseID,
        companyID: nextProps.selectedItem.companyID,
        vendorID: nextProps.selectedItem.vendorID,
        financeCompany: nextProps.selectedItem.financeCompany,
        terms: nextProps.selectedItem.terms,
        lastPaymentDate:new Date(nextProps.selectedItem.lastPaymentDate),// moment(nextProps.selectedItem.lastPaymentDate).format('YYYY-MM-DD'),
        monthlyPayments: nextProps.selectedItem.monthlyPayments,
        automaticRenewal: nextProps.selectedItem.automaticRenewal,
        securityDeposit: nextProps.selectedItem.securityDeposit,
        propertyTypeID: nextProps.selectedItem.propertyTypeID,
        leaseType: nextProps.selectedItem.leaseType,
        comments: nextProps.selectedItem.comments,
        alertDate:new Date(nextProps.selectedItem.alertDate),// moment(nextProps.selectedItem.alertDate).format('YYYY-MM-DD'),
        alertComments: nextProps.selectedItem.alertComments,
        leaseReviewComplete: nextProps.selectedItem.leaseReviewComplete,
        completedBy: nextProps.selectedItem.completedBy,
        lastUpdatedDate:new Date(nextProps.selectedItem.lastUpdatedDate),// moment(nextProps.selectedItem.lastUpdatedDate).format('YYYY-MM-DD')
      })
    }
  }
  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleFormChange = (e,target) => {
   
    if(!target)
    {
      this.setState({ [e.target.name]: e.target.value })
    }
    else if(target=="alertDate")
    {
      this.setState({ alertDate: new Date(e)  })       //moment(e).format('YYYY-MM-DD')
    }
    else if(target=="lastUpdatedDate")
    {
      
      this.setState({ lastUpdatedDate: new Date(e) })       
    }
    else if(target=="lastPaymentDate")
    {
      this.setState({ lastPaymentDate: new Date(e) })       //moment(e).format('YYYY-MM-DD')
    }
    
  }
  handleDelete = () => {
    let id = this.state.leaseID;

    this.props.handleDelete(id);
  }
  setDefaultState = () => {
    this.setState({
      leaseID: 0,
      companyID: '',
      vendorID: '',
      financeCompany: '',
      terms: '',
      lastPaymentDate: new Date(), //moment().format('YYYY-MM-DD')
      monthlyPayments: '',
      automaticRenewal: '',
      securityDeposit: '',
      propertyTypeID: '',
      leaseType: '',
      comments: '',
      alertDate: new Date(), //moment().format('YYYY-MM-DD')
      alertComments: '',
      leaseReviewComplete: '',
      completedBy: '',
      lastUpdatedDate: new Date(),//moment().format('YYYY-MM-DD')
    })
  }

  handleFormCheched = name => event => {

    this.setState({ [name]: event.target.checked });
  };



  handleCancel = () => {
    this.setDefaultState();
    this.props.handleCancel()
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { visible, classes, leaseTypes, propertyTypes, vendorTypes, vendors } = this.props;    
    return (
      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={visible}
        onClose={this.handleCancel}
      >

        <DialogTitle>{this.props.isEdit ? 'Edit' : 'Add'} Lease</DialogTitle>
        <DialogContent>
          <Form>
            <fieldset>
              <Grid container spacing={24} >
                <Grid item sm={4} >
                  <FormItem>
                    <InputLabel className="labletext">Vendor</InputLabel>
                    {getFieldDecorator('vendorID', {
                      initialValue: this.state.vendorID,
                      rules: [{
                        required: true, message: 'Please vendor',
                      }],
                    })(
                      <Select
                        name="vendorID"
                        native
                        required={true}
                        label={"Vendor Name"}
                        fullWidth
                        onChange={this.handleChange('vendorID')}
                        inputProps={{
                          name: 'vendorName',
                          id: 'account-native-simple',
                        }}

                      >
                        <option value={''}>Select Vendor</option>
                        {vendors.length && vendors.map((value, index) => (
                          <option value={value.vendorID} key={index}>{value.displayName}</option>
                        ))}
                      </Select>
                    )}</FormItem>
                </Grid>
                <Grid item sm={4}>
                  <FormItem>
                    <InputLabel className="labletext">Lease Type</InputLabel>
                    {getFieldDecorator('leaseType', {
                      initialValue: this.state.leaseType,
                      rules: [{
                        required: true, message: 'Please select lease type',
                      }],
                    })(
                      <Select
                        name="leaseType"
                        native
                        required={true}
                        label={"Lease Type"}
                        onChange={this.handleChange('leaseType')}
                        inputProps={{
                          name: 'propertytype',
                          id: 'account-native-simple',
                        }}
                        fullWidth

                      >
                        <option value={''}>Select Lease Type</option>
                        {leaseTypes.length && leaseTypes.map((value, index) => (
                          <option value={value.leaseTypeID} key={index}>{value.type}</option>
                        ))}

                      </Select>
                    )}
                  </FormItem>
                </Grid>

                <Grid item sm={4}>
                  <FormItem>
                    <InputLabel className="labletext">Property Type</InputLabel>
                    {getFieldDecorator('propertyTypeID', {
                      initialValue: this.state.leaseType,
                      rules: [{
                        required: true, message: 'Please select property type',
                      }],
                    })(
                      <Select
                        name="propertyTypeID"
                        native
                        required={true}
                        label={"Property Type"}
                        onChange={this.handleChange('propertyTypeID')}
                        value={this.state.propertyTypeID}
                        fullWidth
                        inputProps={{
                          name: 'propertyTypeID',
                          id: 'account-native-simple',
                        }}

                      >
                        <option value={''}>Select Property Type</option>
                        {propertyTypes.length && propertyTypes.map((value, index) => (
                          <option value={value.propertyTypeID} key={index}>{value.type}</option>
                        ))}
                      </Select>)}</FormItem>
                </Grid>
              </Grid>
              <Grid container spacing={24}>
                <Grid item sm={3} >
                  <FormItem>
                    {getFieldDecorator('financeCompany', {
                      initialValue: this.state.financeCompany,
                      rules: [{
                        required: true, message: 'Please enter financing company',
                      }],
                    })(
                      <TextField
                        name="financeCompany"
                        required={true}
                        label={"Financing Company"}
                        fullWidth
                        margin="normal"
                        onChange={this.handleFormChange}

                      />)}
                  </FormItem>
                </Grid>

                <Grid item sm={3} >
                  <FormItem>
                    {getFieldDecorator('monthlyPayments', {
                      initialValue: this.state.monthlyPayments,
                      rules: [{
                        required: true, message: 'Please enter monthly payments',
                      }],
                    })(
                      <TextField
                        name="monthlyPayments"
                        onChange={(e) => this.state.monthlyPayments = e.target.value}
                        label="Monthly Payments"
                        fullWidth
                        required={true}
                        margin="normal"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                      />)}
                  </FormItem>
                </Grid>

                <Grid item sm={3}>
                  <FormItem>
                    {getFieldDecorator('securityDeposit', {
                      initialValue: this.state.securityDeposit,
                      rules: [{
                        required: true, message: 'Please enter security deposit',
                      }],
                    })(
                      <TextField
                        name="securityDeposit"
                        required={true}
                        onChange={(e) => this.state.securityDeposit = e.target.value}
                        label={"Security Deposit"}
                        fullWidth
                        margin="normal"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                      />)}
                  </FormItem>
                </Grid>

                <Grid item sm={3}>
                  <FormItem>
                    {getFieldDecorator('terms', {
                      initialValue: this.state.terms,
                      rules: [{
                        required: true, message: 'Please enter terms',
                      }],
                    })(
                      <TextField
                        name="terms"
                        onChange={(e) => this.state.terms = e.target.value}
                        required={true}
                        label={"Term (Months)"}
                        fullWidth
                        margin="normal"
                        InputProps={{
                          inputComponent: NumberFormatCustom2,
                        }}
                      />)}</FormItem>
                </Grid>
              </Grid>
              <Grid container spacing={24}>
                <Grid item sm={3} >
                  <FormItem>
                    <InputLabel className="labletext">Last Payment Due Date </InputLabel>
                    <DatePicker
                      selected={this.state.lastPaymentDate}
                        onChange={(e) => this.handleFormChange(e,'lastPaymentDate')}
                        label="Last Payment Due Date"
                        name="lastPaymentDate"
                        id="date"
                        required={true}
                        margin="normal"                       
                      />
                  </FormItem>
                </Grid>
                <Grid item sm={3} style={{ 'paddingTop': '34px' }}  >
                  <Checkbox
                    className="label_lease"
                    onChange={this.handleFormCheched('automaticRenewal')}
                    checked={this.state.automaticRenewal}
                  />
                  <label>Automatic Renewal </label>

                </Grid>
                <Grid item sm={6} >
                  <FormItem>
                    <InputLabel className="labletext">Comments</InputLabel>
                    <Textarea
                      value={this.state.comments}
                      style={{ 'lineHeight': '19px', width: '100%', border: 'none', marginTop: '0px', resize: 'none', padding: '6px', paddingLeft: '10px', borderBottom: '1px solid rgba(0, 0, 0, 0.42)', overflow: 'hidden' }} resize={false} placeholder="Comment"
                      onChange={(e) => { this.setState({ comments: e.target.value }); }}
                    />
                  </FormItem>
                </Grid>


              </Grid>
              <Grid container spacing={24}>



                <Grid item sm={3}>
                  <FormItem>
                    <InputLabel className="labletext">Alert Date</InputLabel>
                    <DatePicker
                      selected={this.state.alertDate}
                      onChange={(e) => this.handleFormChange(e,"alertDate")}
                      label="Alert Date"
                      name="alertDate"
                      id="date"
                      margin="normal"
                    />
                  </FormItem>
                </Grid>
                <Grid item sm={3}>
                  <FormItem>
                    {getFieldDecorator('alertComments', {
                      initialValue: this.state.alertComments,

                    })(
                      <TextField
                        name="alertComments"

                        onChange={this.handleFormChange}
                        label={"Alert Comments"}
                        fullWidth
                        margin="normal"
                      />)}
                  </FormItem>
                </Grid>

                <Grid item sm={3}>
                  <FormItem>
                    <InputLabel className="labletext">Last Review Date </InputLabel>
                    <DatePicker                      
                      selected = {this.state.lastUpdatedDate}
                      onChange={(e) => this.handleFormChange(e,'lastUpdatedDate')}
                      label="Last Review Date"
                      name="lastUpdatedDate"
                      id="date"
                      required={true}
                      margin="normal"
                    />
                  </FormItem>
                </Grid>
                <Grid item sm={3} style={{ 'paddingTop': '34px' }}  >
                  <Checkbox
                    className="label_lease"
                    onChange={this.handleFormCheched('leaseReviewComplete')}
                    checked={this.state.leaseReviewComplete}
                  />
                  <label>Lease Review Complete</label>


                </Grid>

              </Grid>
            </fieldset>
          </Form>
        </DialogContent>
        <DialogActions>

          <Button color="primary" onClick={this.handleSubmit}>
            {this.props.isEdit ? 'Update' : 'Save'}
          </Button>
          <Button color="primary" onClick={this.handleCancel} >
            Cancel
                    </Button>
          {this.props.isEdit ?
            <Button onClick={this.handleDelete} color="primary">
              Delete
					</Button> : null}

        </DialogActions>

      </Dialog>
    );
  }
}
const Addlease = Form.create()(Addleaseform);

const mapStateToProps = (state) => {
  return {

  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    updateLease,
    addLease,
  }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Addlease);

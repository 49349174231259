import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import ClearIcon from '@material-ui/icons/Clear';
import { GET_VENDORS_HISTORY_DATA } from '../../appRedux/actions/vendorAction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CircularProgress from "./../../components/CircularProgress";
import { toast } from 'react-toastify';

class VendorsLogHistory extends Component {

    constructor(props) {
        super(props);



        this.state = {
            lst_history: [],
            loading: false,
            // Module: props.Module,
            CompanyId: props.CompanyId,
            VendorId: props.VendorId
        };
    }

    componentDidMount() {
        this.setState({
            loading: true
        }, () => {
            this.GetHistory();
        });


    }

    GetHistory() {
        let body = {
            "VendorID": this.state.VendorId,
            "CompanyID": this.state.CompanyId
        }
        this.props.GET_VENDORS_HISTORY_DATA(body);

    }


    CloseModel = () => {
        this.props.CloseHistory();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.HistoryData!==undefined && nextProps.HistoryData !== this.props.HistoryData) {
            if (nextProps.HistoryData.transactionStatus === 0) {
                this.setState({ loading: false, lst_history: nextProps.HistoryData.returnObject });
            }
            else {
                toast.error(nextProps.HistoryData.resultMsg);
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <Dialog
                    maxWidth="sm"
                    fullWidth={true}
                    open={true}
                >
                    <DialogTitle><span className="log-history-title float-left"> Log History </span>
                        <Button className="jr-btn close-btn float-right " onClick={this.CloseModel}><ClearIcon /></Button>
                    </DialogTitle>
                    <DialogContent>

                        {this.state.loading ? <CircularProgress></CircularProgress> :
                            <React.Fragment>
                                {this.state.lst_history.length == 0 ?
                                    <React.Fragment>
                                        <div className="history-box">
                                            <p className="history-item-text">No History Found</p>                                            
                                        </div>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        {this.state.lst_history.map((item, i) => (
                                            <div className="history-box">
                                                <p className="history-item-text">{item.selectedField}</p>
                                                <p className="history-date-text"> <strong> By </strong> {item.createdByName ? item.createdByName : "System"}<strong> on </strong>{item.createdDate}</p>
                                            </div>
                                        ))}
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        }
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        HistoryData: state.vendors
        .vendorhistory
    };
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        GET_VENDORS_HISTORY_DATA
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(VendorsLogHistory);
import {
    LOANS_SUCCESS, LOANS_ERROR,
    ADDLOANS_SUCCESS, ADDLOANS_ERROR,
    UPDATELOANS_SUCCESS, UPDATELOANS_ERROR,
    DELETELOANS_SUCCESS, DELETELOANS_ERROR ,
    PROPERTY_SUCCESS,PROPERTY_ERROR,
} from '../actions/loansAction';


const initial_state = {
     propertyTypes:[],
     loans:[],
};

export default function loansReducer(state = initial_state, action) {
    switch (action.type) {
        case LOANS_SUCCESS:
            return {
                ...state,
                loans:action.payload.data,reload:false
            };
        case LOANS_ERROR:
            return [...state, ...action.payload.data];
        case ADDLOANS_SUCCESS:
            return [...state, ...action.payload.data];
        case ADDLOANS_ERROR:
            return [...state, ...action.payload.data];
        case UPDATELOANS_SUCCESS:
            return [...state, ...action.payload.data];
        case UPDATELOANS_ERROR:
            return [...state, ...action.payload.data];
        case DELETELOANS_SUCCESS:
            return [...state, ...action.payload.data];
        case DELETELOANS_ERROR:
            return [...state, ...action.payload.data];
        case PROPERTY_SUCCESS:
            return {
                ...state,
                propertyTypes:action.payload.data
            };
        case PROPERTY_ERROR:
            return [...state, ...action.payload.data];
        case 'RELOAD':
            return {...state, reload:true};
        default:
            return state;
    }
}

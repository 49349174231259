import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { Get_FinancialIssues, Get_PrecheckIssues, Get_AccountantDashboard_GetReviewCommentsSummary, GetPayrollAdministrationSummary, Get1099AdministrationSummary,CompanyTaxInformation } from "./../../../appRedux/actions/AccountantDashboardAction";
import { Get_ExcelExportData } from './../../../appRedux/actions/CompanyDashboardAction';
import Button from '@material-ui/core/Button';
import CircularProgress from "./../../../components/CircularProgress";
import PaginationComponent from "./../../../app/common/Pagination";
import { AccountDashboardTabsType, GetLastQuarterEndDate } from '../../../app/common/commonServices';
import { toast } from 'react-toastify';
import ReactExport from 'react-export-excel';
import PrintReport from '../../../Forms/PrintReportForm'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


class Intranet extends React.Component {

  onOptionMenuSelect = event => {
    this.setState({ menuState: true, anchorEl: event.currentTarget });
  };
  handleRequestClose = () => {
    this.setState({ menuState: false });
  };

  constructor(props) {
    super(props);

    this.sDateRef = React.createRef();
    this.eDateRef = React.createRef();

    let eDate = new Date();
    let sDate = new Date(eDate.getFullYear(), 0, 1);
    // if (eDate.getMonth() === 0) {
    //   sDate.setFullYear(eDate.getFullYear() - 1);
    // }

    if (eDate.getMonth() <= 2) {
      sDate = new Date(sDate.getFullYear() - 1, 0, 1);
      eDate = new Date(sDate.getFullYear() - 1, 11, 31);
    }

    this.state = {
      anchorEl: undefined,
      menuState: false,
      lst_data: [],
      currentTab: 1,
      FinancialData: [],
      CompanyTaxData:[],
      eDate: GetLastQuarterEndDate(),
      sDate: sDate,
      companyId: 0,
      PageSize: 100,
      PageNo: 1,
      loading: false,
      PreCheckIssues: [],
      ReviewCommentsData: [],
      Administartion1099Data: [],
      PayrollData: [],
      TotalRecords: 0,
      fileName: "Report as on " + (moment(new Date()).format("MM_DD_YYYY")),
      dataSet2: [],
      dataSet1: [], // this is for download excel file data 
      isPrintReportModalOpen:false
    }
  }

  //handlePrintReport
  handlePrintReport=()=>{
    this.setState({isPrintReportModalOpen:true})
  }
   //handlePrintReport
   handlePrintReportClose=()=>{
    this.setState({isPrintReportModalOpen:false})
  }

  handleFocus = (e, selecter) => {
    let control = null;
    if (selecter !== undefined) {
      if (selecter === "sDate") {
        control = this.sDateRef;
        if (control.current != null) {
          control.current.setFocus();
        }
      }
      else if (selecter === "eDate") {
        control = this.eDateRef;
        if (control.current != null) {
          control.current.setFocus();
        }
      }
    }
  }
  handleDateChange = (e, target) => {

    if (target === "fromDate") {

      this.setState({ sDate: new Date(e), selectedDateRange: { startDate: moment(new Date(e)).format("MM/DD/YYYY"), endDate: moment(this.state.eDate).format("MM/DD/YYYY") } })
    }
    else if (target === "uptoDate") {
      this.setState({ eDate: new Date(e), selectedDateRange: { startDate: moment(this.state.sDate).format("MM/DD/YYYY"), endDate: moment(new Date(e)).format("MM/DD/YYYY") } })
    }
  }

  onTabClick = (tabValue) => {

    this.setState({ currentTab: tabValue, loading: true, PageNo: 1, PageSize: 100 }, () => {
      this.LoadData(tabValue);
    });
  };


  componentDidMount() {

    let user = JSON.parse(localStorage.getItem('userData'));
    this.setState({
      companyId: user.companyId,
      loading: true
    }, () => {
      this.LoadData(AccountDashboardTabsType.ClientsReadyForFinancialCheckup);
    });
  }

  LoadData = (type) => {

    let data = {
      "companyID": this.state.companyId,
      "pageNumber": this.state.PageNo,
      "pageSize": this.state.PageSize,
      "fromDate": moment(this.state.sDate).format("MM/DD/YYYY"),
      "toDate": moment(this.state.eDate).format("MM/DD/YYYY"),
    }

    if (type === AccountDashboardTabsType.ClientsReadyForFinancialCheckup) {
      this.props.Get_FinancialIssues(data);

    } else if (type === AccountDashboardTabsType.PreCheckIssues) {
      this.props.Get_PrecheckIssues(data);
    } else if (type === AccountDashboardTabsType.ReviewComments) {
      this.props.Get_AccountantDashboard_GetReviewCommentsSummary(data);
    }
    else if (type === AccountDashboardTabsType.PayrollAdministaration) {
      this.props.GetPayrollAdministrationSummary(data);
    }
    else if (type === AccountDashboardTabsType.Administaration_1099) {
      this.props.Get1099AdministrationSummary(data);
    }
    // else if (type === AccountDashboardTabsType.CompanyTaxInformation) {
    //   this.props.CompanyTaxInformation(data);
    // }
  }

  BindExcelData = (data) => {
    let companyName = null;
    let AR_Data = [];
    let querryData = [];
    let item = [];
    let companyData = {};
    for (let i = 0; i < data.length; i++) {
      companyData = {};
      companyName = data[i].companyName;
      if (data[i].lstAccountReconciliation != null && data[i].lstAccountReconciliation.length > 0) {
        item = data[i].lstAccountReconciliation;
        for (let k = 0; k < item.length; k++) {
          AR_Data.push({ "company": companyName, "accountName": item[k].accountName, "balance": item[k].balance, "reconciledThrough": item[k].reconciledThrough, "lastReconciledEnd": item[k].lastReconciledEnd,"lastReconciledTransection":item[k].lastReconciledTransection })
        }
      }

      companyData.company = companyName;
      companyData.missingVendorNameCount = data[i].preCheckIssue.missingVendorNameCount;
      companyData.missingVendorNameNewCommentCount = data[i].preCheckIssue.missingVendorNameNewCommentCount;
      companyData.missingVendorNameCount = data[i].preCheckIssue.missingVendorNameCount;
      companyData.missingVendorNameNewCommentCount = data[i].preCheckIssue.missingVendorNameNewCommentCount;
      companyData.missingDescriptionCount = data[i].preCheckIssue.missingDescriptionCount;
      companyData.missingDescriptionNewCommentCount = data[i].preCheckIssue.missingDescriptionNewCommentCount;
      companyData.oldOutstadingCount = data[i].preCheckIssue.oldOutstadingCount;
      companyData.oldOutstadingNewCommentCount = data[i].preCheckIssue.oldOutstadingNewCommentCount;
      companyData.missingReferenceNumberCount = data[i].preCheckIssue.missingReferenceNumberCount;
      companyData.missingReferenceNumberCommentCount = data[i].preCheckIssue.missingReferenceNumberCommentCount;
      companyData.uncategorizedTransactionsCount = data[i].preCheckIssue.uncategorizedTransactionsCount;
      companyData.uncategorizedTransactionsCommentCount = data[i].preCheckIssue.uncategorizedTransactionsCommentCount;
      companyData.reviewUpdatedTransactionDescriptionsCount = data[i].financialCheckup.reviewUpdatedTransactionDescriptionsCount;
      companyData.reviewUpdatedTransactionDescriptionsNewCommentCount = data[i].financialCheckup.reviewUpdatedTransactionDescriptionsNewCommentCount;
      companyData.needBetterDescriptionCount = data[i].financialCheckup.needBetterDescriptionCount;
      companyData.needBetterDescriptionNewCommentCount = data[i].financialCheckup.needBetterDescriptionNewCommentCount;
      companyData.changeAccountNumberCount = data[i].financialCheckup.changeAccountNumberCount;
      companyData.changeAccountNumberNewCommentCount = data[i].financialCheckup.changeAccountNumberNewCommentCount;
      companyData.accountsLineItemQueCommentsCount = data[i].financialCheckup.accountsLineItemQueCommentsCount;
      companyData.accountsLineItemQueCommentsNewCommentCount = data[i].financialCheckup.accountsLineItemQueCommentsNewCommentCount;
      companyData.transactionsTaggedAsCPACount = data[i].financialCheckup.transactionsTaggedAsCPACount;
      companyData.transactionsTaggedAsCPACommentsCount = data[i].financialCheckup.transactionsTaggedAsCPACommentsCount;
      companyData.otherQuestionsAndCommentsCount = data[i].financialCheckup.otherQuestionsAndCommentsCount;
      companyData.otherQuestionsAndCommentsCommentsCount = data[i].financialCheckup.otherQuestionsAndCommentsCommentsCount;
      companyData.otherQuestionsAndCommentsTaggedAsCPACount = data[i].financialCheckup.otherQuestionsAndCommentsTaggedAsCPACount;
      companyData.otherQuestionsAndCommentsTaggedAsCPACommentsCount = data[i].financialCheckup.otherQuestionsAndCommentsTaggedAsCPACommentsCount;
      companyData.vendorsMissingW9Count = data[i].queriesForVendor1099.vendorsMissingW9Count;
      companyData.vendor1099PendingAccountantApprovalCount = data[i].queriesForVendor1099.vendor1099PendingAccountantApprovalCount;
      companyData.vendor1099PendingAccountantApprovalCommentCount = data[i].queriesForVendor1099.vendor1099PendingAccountantApprovalCommentCount;
      companyData.vendor1099PendingClientApprovalCount = data[i].queriesForVendor1099.vendor1099PendingClientApprovalCount;
      companyData.vendor1099PendingClientApprovalCommentCount = data[i].queriesForVendor1099.vendor1099PendingClientApprovalCommentCount;
      companyData.vendor1099NECReadyToExportCount = data[i].queriesForVendor1099.vendor1099NECReadyToExportCount;
      companyData.vendor1099MISCReadyToExportCount = data[i].queriesForVendor1099.vendor1099MISCReadyToExportCount;
      companyData.confirm1099WereMailedCount = data[i].queriesForVendor1099.confirm1099WereMailedCount;
      companyData.resolve1099VendorNameDiscrepanciesCount = data[i].queriesForVendor1099.resolve1099VendorNameDiscrepanciesCount;
      companyData.resolve1099VendorNameDiscrepanciesCommentCount = data[i].queriesForVendor1099.resolve1099VendorNameDiscrepanciesCommentCount;
      companyData.employeesMissingW4Count = data[i].queriesForPayroll1099.employeesMissingW4Count;
      companyData.recordCheckInPayrollSystemCount = data[i].queriesForPayroll1099.recordCheckInPayrollSystemCount;
      companyData.recordCheckInPayrollSystemCommentsCount = data[i].queriesForPayroll1099.recordCheckInPayrollSystemCommentsCount;
      companyData.recordEmployeePaymentInQBPayrollSystemCount = data[i].queriesForPayroll1099.recordEmployeePaymentInQBPayrollSystemCount;
      companyData.recordEmployeePaymentInQBPayrollSystemCommentsCount = data[i].queriesForPayroll1099.recordEmployeePaymentInQBPayrollSystemCommentsCount;
      companyData.reviewIgnoredEmployeePaymentsCount = data[i].queriesForPayroll1099.reviewIgnoredEmployeePaymentsCount + "/" + data[i].queriesForPayroll1099.reviewIgnoredEmployeePaymentsTotalCount;
      companyData.reviewIgnoredEmployeePaymentsCommentsCount = data[i].queriesForPayroll1099.reviewIgnoredEmployeePaymentsCommentsCount;
      companyData.resolveExcessHousingAllowanceIssuesCount = data[i].queriesForPayroll1099.resolveExcessHousingAllowanceIssuesCount;
      companyData.submitEmployeeUseOfVehicleFormCount = data[i].queriesForPayroll1099.submitEmployeeUseOfVehicleFormCount;
      companyData.recordEmployeeUseofVehicleCount = data[i].queriesForPayroll1099.recordEmployeeUseofVehicleCount + "/" +
        data[i].queriesForPayroll1099.recordEmployeeUseofVehicleTotalCount;
      companyData.addtoEmployeeListCount = data[i].queriesForVendor1099.addtoEmployeeListCount;
      companyData.recordEmployeePaymentInPayrollSystemCount = data[i].queriesForPayroll1099.recordEmployeePaymentInPayrollSystemCount;
      companyData.recordEmployeePaymentInPayrollSystemCommentsCount = data[i].queriesForPayroll1099.recordEmployeePaymentInPayrollSystemCommentsCount;
      companyData.verifyListofMinistersCount = data[i].queriesForPayroll1099.verifyListofMinistersCount + "/" +
        data[i].queriesForPayroll1099.verifyListofMinistersTotalCount;
      companyData.approveWorkerClassifiedAsAnEmployeeCount = data[i].queriesForVendor1099.approveWorkerClassifiedAsAnEmployeeCount + "/" +
        data[i].queriesForVendor1099.approveWorkerClassifiedAsAnEmployeeTotalCount;
      companyData.assignTaxableFringeBenefitToEmployeeCount = data[i].queriesForPayroll1099.assignTaxableFringeBenefitToEmployeeCount + "/" +
        data[i].queriesForPayroll1099.assignTaxableFringeBenefitToEmployeeTotalCount;
      companyData.assignTaxableFringeBenefitToEmployeeCommentsCount = data[i].queriesForPayroll1099.assignTaxableFringeBenefitToEmployeeCommentsCount;
      companyData.recordTaxableFringeBenefitInPayrollSystemCount = data[i].queriesForPayroll1099.recordTaxableFringeBenefitInPayrollSystemCount + "/" +
        data[i].queriesForPayroll1099.recordTaxableFringeBenefitInPayrollSystemTotalCount;
      companyData.recordTaxableFringeBenefitInPayrollSystemCommentsCount = data[i].queriesForPayroll1099.recordTaxableFringeBenefitInPayrollSystemCommentsCount;
      companyData.issueHousingAllowanceMemoCount = data[i].queriesForPayroll1099.issueHousingAllowanceMemoCount + "/" +
        data[i].queriesForPayroll1099.issueHousingAllowanceMemoTotalCount;

      querryData.push(companyData);
    }

    this.setState({ dataSet1: AR_Data, dataSet2: querryData }, () => {      
      if (AR_Data.length > 0 || querryData.length > 0) {
        this.inputElement.click();
      }
    });

  }


  componentWillReceiveProps(nextProps) {
    let records = 0;

    if (nextProps.companyDashboardData !== undefined && nextProps.companyDashboardData !== this.props.companyDashboardData) {
      if (nextProps.companyDashboardData.transactionStatus === 0) {

        if (nextProps.companyDashboardData.returnObject != null) {

          this.BindExcelData(nextProps.companyDashboardData.returnObject);

        }
        else {
          this.setState({ dataSet1: [], dataSet2: [] }, () => {
            toast.error("No data found for Excel report");
          });
        }
      }
      else {
        this.setState({ dataSet1: [], dataSet2: [] }, () => {
          toast.error("No data found for Excel report");
        });
      }
    }
    if (nextProps.FinancialData !== this.props.FinancialData) {
      if (nextProps.FinancialData.transactionStatus === 0) {
        records = nextProps.FinancialData.returnObject.length > 0 ? nextProps.FinancialData.returnObject[0].totalrows : 0;
        this.setState({ FinancialData: nextProps.FinancialData.returnObject, loading: false, TotalRecords: records });
      }
      else {
        toast.error(`${nextProps.FinancialData.resultMsg}`);
      }
    }
    if (nextProps.CompanyTaxData !== this.props.CompanyTaxData) {
      if (nextProps.CompanyTaxData.transactionStatus === 0) {
        records = nextProps.CompanyTaxData.returnObject.length > 0 ? nextProps.CompanyTaxData.returnObject[0].totalrows : 0;
        this.setState({ CompanyTaxData: nextProps.CompanyTaxData.returnObject, loading: false, TotalRecords: records });
      }
      else {
        toast.error(`${nextProps.CompanyTaxData.resultMsg}`);
      }
    }

    if (nextProps.PrecheckData !== this.props.PrecheckData) {
      if (nextProps.PrecheckData.transactionStatus === 0) {
        records = nextProps.PrecheckData.returnObject.length > 0 ? nextProps.PrecheckData.returnObject[0].totalrows : 0;
        this.setState({ PreCheckIssues: nextProps.PrecheckData.returnObject, loading: false, TotalRecords: records });
      }
      else {
        toast.error(`${nextProps.PrecheckData.resultMsg}`);
      }
    }

    if (nextProps.ReviewCommentsData !== this.props.ReviewCommentsData) {
      if (nextProps.ReviewCommentsData.transactionStatus === 0) {
        records = nextProps.ReviewCommentsData.returnObject.length > 0 ? nextProps.ReviewCommentsData.returnObject[0].totalrows : 0;
        this.setState({ ReviewCommentsData: nextProps.ReviewCommentsData.returnObject, loading: false, TotalRecords: records });
      }
      else {
        toast.error(`${nextProps.ReviewCommentsData.resultMsg}`);
      }
    }
    if (nextProps.Adminstration1099Data !== this.props.Adminstration1099Data) {
      if (nextProps.Adminstration1099Data.transactionStatus === 0) {
        records = nextProps.Adminstration1099Data.returnObject.length > 0 ? nextProps.Adminstration1099Data.returnObject[0].totalrows : 0;
        this.setState({ Administartion1099Data: nextProps.Adminstration1099Data.returnObject, loading: false, TotalRecords: records });
      }
      else {
        toast.error(`${nextProps.Adminstration1099Data.resultMsg}`);
      }
    }

    if (nextProps.PayrollAdminstrationData !== this.props.PayrollAdminstrationData) {
      if (nextProps.PayrollAdminstrationData.transactionStatus === 0) {
        records = nextProps.PayrollAdminstrationData.returnObject.length > 0 ? nextProps.PayrollAdminstrationData.returnObject[0].totalrows : 0;
        this.setState({ PayrollData: nextProps.PayrollAdminstrationData.returnObject, loading: false, TotalRecords: records });
      }
      else {
        toast.error(`${nextProps.PayrollAdminstrationData.resultMsg}`);
      }
    }
  }

  ChangePageSize = (size) => {

    this.setState({ loading: true, PageSize: parseInt(size), PageNo: 1 }, () => {
      this.LoadData(this.state.currentTab);
    });
  }

  ChangePage = (page) => {

    this.setState({ loading: true, PageNo: page, }, () => {
      this.LoadData(this.state.currentTab);
    });
  }


  ApplyDate = () => {
    this.setState({
      loading: true,
    }, () => {
      this.LoadData(this.state.currentTab);
    });
  }

  DownloadReport = () => {

    let body = {
      companyId: 0,
      fromDate: moment(new Date(2019, 0, 1)).format("MM/DD/YYYY"),
      toDate: moment(new Date()).format("MM/DD/YYYY"),
      NeedUpdatQBORecords: false
    }

    toast.info("Please wait for a while,we are generating excel sheet. you will notify in few mins.");
    this.setState({ dataSet1: [], dataSet2: [] }, () => {
      this.props.Get_ExcelExportData(body);
    })
  }

  render() {
    const { anchorEl, menuState } = this.state;
    
    return (<>
      <div className="dashboard animated slideInUpTiny animation-duration-3 accountant-dashboard-main-box dashboardPage embeded-dashboard">
        <div className="page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
          <h2 className="title mb-3 mb-sm-0">Accountant’s Dashboard</h2>
        </div>

        <div className="padding-box app-wrapper">
          <div className="row">

            <div className="col-12 col-md-4">
              <div className="dashboard-box " style={{ flexWrap: "wrap" }}>
                <div className="datepicker-box">
                  <div className="date-box">
                    <DatePicker
                      selected={this.state.sDate}
                      onChange={(e) => this.handleDateChange(e, 'fromDate')}
                      name="fromDate"
                      id="date"
                      required={true}
                      margin="normal"
                      disabledKeyboardNavigation
                      showMonthDropdown
                      showYearDropdown
                      yearDropdownItemNumber={5}
                      maxDate={this.state.eDate}
                      ref={this.sDateRef}
                    />
                    <div className="date-icon" onClick={(e) => this.handleFocus(e, 'sDate')}>
                      <i className="zmdi zmdi-calendar-note"></i>
                    </div>
                  </div>
                  <div className="to-box">
                    <span className="text-center ledgerto-text">To</span>
                  </div>
                  <div className="date-box">
                    <DatePicker
                      selected={this.state.eDate}
                      onChange={(e) => this.handleDateChange(e, 'uptoDate')}
                      name="uptoDate"
                      id="date"
                      required={true}
                      margin="normal"
                      showMonthDropdown
                      showYearDropdown
                      yearDropdownItemNumber={5}
                      disabledKeyboardNavigation
                      minDate={this.state.sDate}
                      maxDate={new Date()}
                      ref={this.eDateRef}
                    />
                    <div className="date-icon" onClick={(e) => this.handleFocus(e, 'eDate')}>
                      <i className="zmdi zmdi-calendar-note"></i>
                    </div>


                  </div>
                </div>
                <div className="genera-apply-btn" style={{ marginTop: "10px", textAlign: "left" }}>
                  <Button variant="raised" className="greencolor jr-btn bg-teal text-white refresh-btn" onClick={(e) => this.ApplyDate()} >apply</Button>
                  <div onClick={this.handleClick} style={{ display: "none" }}>
                    <ExcelFile filename={this.state.fileName} element={<button ref={input => this.inputElement = input} >Download</button>}>
                      {this.state.dataSet1 != null &&
                        <ExcelSheet data={this.state.dataSet1} name="Account Reconciliation Status">
                          <ExcelColumn label="Company" value="company" />
                          <ExcelColumn label="Account" value="accountName" />
                          <ExcelColumn label="Balance" value="balance" />
                          <ExcelColumn label="Last Reconciled Transaction" value="lastReconciledTransection" />
                          <ExcelColumn label="Reconciled Through" value="reconciledThrough" />
                          <ExcelColumn label="Last Quarter End" value="lastReconciledEnd" />                          
                          {/* <ExcelColumn label="Last Quarter End"
                          value={(col) => col.is_married ? "Married" : "Single"} /> */}
                        </ExcelSheet>
                      }
                      {this.state.dataSet2 != null &&
                        <ExcelSheet data={this.state.dataSet2} name="Query Count">
                          <ExcelColumn label="Company" value="company" />
                          <ExcelColumn label="Missing Vendor Name" value="missingVendorNameCount" />
                          <ExcelColumn label="MVN-UnreadCommentCount" value="missingVendorNameNewCommentCount" />
                          <ExcelColumn label="Missing Description" value="missingDescriptionCount" />
                          <ExcelColumn label="MD-UnreadCommentCount" value="missingDescriptionNewCommentCount" />
                          <ExcelColumn label="Old Outstanding Transactions" value="oldOutstadingCount" />
                          <ExcelColumn label="OOT-UnreadCommentCount" value="oldOutstadingNewCommentCount" />
                          <ExcelColumn label="Checks Missing Reference Number" value="missingReferenceNumberCount" />
                          <ExcelColumn label="MRN-UnreadCommentCount" value="missingReferenceNumberCommentCount" />
                          <ExcelColumn label="Uncategorized Transactions" value="uncategorizedTransactionsCount" />
                          <ExcelColumn label="UT-UnreadCommentCount" value="uncategorizedTransactionsCommentCount" />
                          <ExcelColumn label="Review Updated Transactions Descriptions" value="reviewUpdatedTransactionDescriptionsCount" />
                          <ExcelColumn label="RUTD-UnreadCommentCount" value="reviewUpdatedTransactionDescriptionsNewCommentCount" />
                          <ExcelColumn label="Need a Better Description" value="needBetterDescriptionCount" />
                          <ExcelColumn label="NBD-UnreadCommentCount" value="needBetterDescriptionNewCommentCount" />
                          <ExcelColumn label="Need Account Number Corrections" value="changeAccountNumberCount" />
                          <ExcelColumn label="NABC-UnreadCommentCount" value="changeAccountNumberNewCommentCount" />
                          <ExcelColumn label="Transactions with Questions and Comments" value="accountsLineItemQueCommentsCount" />
                          <ExcelColumn label="Q&C-UnreadCommentCount" value="accountsLineItemQueCommentsNewCommentCount" />
                          <ExcelColumn label="Transactions tagged as CPA" value="transactionsTaggedAsCPACount" />
                          <ExcelColumn label="TCPA-UnreadCommentCount" value="transactionsTaggedAsCPACommentsCount" />
                          <ExcelColumn label="Other Questions and Comments" value="otherQuestionsAndCommentsCount" />
                          <ExcelColumn label="OQ&C-UnreadCommentCount" value="otherQuestionsAndCommentsCommentsCount" />
                          <ExcelColumn label="Other Questions tagged as CPA" value="otherQuestionsAndCommentsTaggedAsCPACount" />
                          <ExcelColumn label="OCPA-UnreadCommentCount" value="otherQuestionsAndCommentsTaggedAsCPACommentsCount" />
                          <ExcelColumn label="Vendors Missing W-9" value="vendorsMissingW9Count" />
                          <ExcelColumn label="1099's Pending Accountant's Approval" value="vendor1099PendingAccountantApprovalCount" />
                          <ExcelColumn label="PAA-UnreadCommentCount" value="vendor1099PendingAccountantApprovalCommentCount" />
                          <ExcelColumn label="1099's Pending Client Approval" value="vendor1099PendingClientApprovalCount" />
                          <ExcelColumn label="PCA-UnreadCommentCount" value="vendor1099PendingClientApprovalCommentCount" />
                          <ExcelColumn label="1099 NEC's Ready to Export" value="vendor1099NECReadyToExportCount" />
                          <ExcelColumn label="1099 MISC's Ready to Export" value="vendor1099MISCReadyToExportCount" />
                          <ExcelColumn label="Confirm 1099's Were Mailed" value="confirm1099WereMailedCount" />
                          <ExcelColumn label="Resolve 1099 Vendor Name Discrepancies" value="resolve1099VendorNameDiscrepanciesCount" />
                          <ExcelColumn label="VND-UnreadCommentCount" value="resolve1099VendorNameDiscrepanciesCommentCount" />
                          <ExcelColumn label="Employees Missing W-4" value="employeesMissingW4Count" />
                          <ExcelColumn label="Record Check in Payroll System" value="recordCheckInPayrollSystemCount" />
                          <ExcelColumn label="RCPS-UnreadCommentCount" value="recordCheckInPayrollSystemCommentsCount" />
                          <ExcelColumn label="Record Employee Payment in QB Payroll System" value="recordEmployeePaymentInQBPayrollSystemCount" />
                          <ExcelColumn label="REPQB-UnreadCommentCount" value="recordEmployeePaymentInQBPayrollSystemCommentsCount" />
                          <ExcelColumn label="Review Ignored Employee Payments" value="reviewIgnoredEmployeePaymentsCount" />
                          <ExcelColumn label="RIEP-UnreadCommentCount" value="reviewIgnoredEmployeePaymentsCommentsCount" />
                          <ExcelColumn label="Resolve Excess Housing Allowance Issues" value="resolveExcessHousingAllowanceIssuesCount" />
                          <ExcelColumn label="Submit Employee Use of Vehicle Form" value="submitEmployeeUseOfVehicleFormCount" />
                          <ExcelColumn label="Record Employee Use of Vehicle" value="recordEmployeeUseofVehicleCount" />
                          <ExcelColumn label="Add to Employee List" value="addtoEmployeeListCount" />
                          <ExcelColumn label="Record Employee Payment in Payroll System" value="recordEmployeePaymentInPayrollSystemCount" />
                          <ExcelColumn label="REPIPS-UnreadCommentCount" value="recordEmployeePaymentInPayrollSystemCommentsCount" />
                          <ExcelColumn label="Verify List of Ministers" value="verifyListofMinistersCount" />
                          <ExcelColumn label="Approve Worker Classified as an Employee" value="approveWorkerClassifiedAsAnEmployeeCount" />
                          <ExcelColumn label="Assign Taxable Fringe Benefit to Employee and Category" value="assignTaxableFringeBenefitToEmployeeCount" />
                          <ExcelColumn label="ATFB-UnreadCommentCount" value="assignTaxableFringeBenefitToEmployeeCommentsCount" />
                          <ExcelColumn label="Record Taxable Fringe Benefit in Payroll System" value="recordTaxableFringeBenefitInPayrollSystemCount" />
                          <ExcelColumn label="RTFB-UnreadCommentCount" value="recordTaxableFringeBenefitInPayrollSystemCommentsCount" />
                          <ExcelColumn label="Issue Housing Allowance Memo" value="issueHousingAllowanceMemoCount" />
                        </ExcelSheet>
                      }
                    </ExcelFile>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-md-8 text-right">
              <Button variant="raised" style={{ top: "76px" }} className="greencolor jr-btn bg-teal text-white refresh-btn" onClick={(e) => this.DownloadReport()} >Download Report</Button>
            </div> */}
             <div className="col-md-8 text-right">
              <Button variant="raised" style={{ top: "76px" }} className="greencolor jr-btn bg-teal text-white refresh-btn" onClick={this.handlePrintReport} >Print QBO Payroll Reports</Button>
            </div>
          </div>
          <div className="accountant-dashboard" style={{ display: 'flex' }}>
            <div className={`tab-main-box ${this.state.currentTab === AccountDashboardTabsType.ClientsReadyForFinancialCheckup ? 'dashboard-tab' : ''}`}
              onClick={() => this.onTabClick(AccountDashboardTabsType.ClientsReadyForFinancialCheckup)}
              style={{ backgroundColor: '#46b29d', color: 'white', marginRight: '5px' }}>
              <div className="tab-box financial-box">
                <div className="tab-icon">
                  <img src="/vendors/Vector Smart Object1.png" alt=""></img>
                </div>
                <div className="tab-name ">
                  Clients Ready For <br></br> Financial Check-Up
                  </div>
              </div>
            </div>

            <div className={`tab-main-box ${this.state.currentTab === AccountDashboardTabsType.PreCheckIssues ? 'dashboard-tab' : ''}`}
              onClick={() => this.onTabClick(AccountDashboardTabsType.PreCheckIssues)}
              style={{ backgroundColor: '#e27b40', color: 'white', marginRight: '5px' }}>
              <div className="tab-box pre-check-box">
                <div className="tab-icon">
                  <img src="/vendors/PreCheckNewLogoWhite.png" alt=""></img>
                </div>
                <div className="tab-name ">
                Data Entry <br></br> Issues
                </div>
              </div>
            </div>

            <div className={`tab-main-box ${this.state.currentTab === AccountDashboardTabsType.OtherIssues ? 'dashboard-tab' : ''}`}
              onClick={() => this.onTabClick(AccountDashboardTabsType.OtherIssues)}
              style={{ backgroundColor: '#de5948', color: 'white', marginRight: '5px' }}>
              <div className="tab-box other-issues-box">
                <div className="tab-icon">
                  <img src="/vendors/Other-Issues-1.png" alt=""></img>
                </div>
                <div className="tab-name">
                  Other Issues
                </div>
              </div>
            </div>

            <div className={`tab-main-box ${this.state.currentTab === AccountDashboardTabsType.PayrollAdministaration ? 'dashboard-tab' : ''}`}
              onClick={() => this.onTabClick(AccountDashboardTabsType.PayrollAdministaration)}
              style={{ backgroundColor: '#f0ca4c', color: 'white', marginRight: '5px' }}>
              <div className="tab-box payroll-box">
                <div className="tab-icon">
                  <img src="/vendors/Payroll-Administration.png" alt=""></img>
                </div>
                <div className="tab-name">
                  Payroll <br></br> Administration
                </div>
              </div>
            </div>

            <div className={`tab-main-box ${this.state.currentTab === AccountDashboardTabsType.Administaration_1099 ? 'dashboard-tab' : ''}`}
              onClick={() => this.onTabClick(AccountDashboardTabsType.Administaration_1099)}
              style={{ backgroundColor: '#334c5a', color: 'white', marginRight: '5px' }}>
              <div className="tab-box administaration-box">
                <div className="tab-icon">
                  <img src="/vendors/Payroll-Administration.png" alt=""></img>
                </div>
                <div className="tab-name ">
                  1099 <br></br> Administration
                </div>
              </div>
            </div>

            <div className={`tab-main-box ${this.state.currentTab === AccountDashboardTabsType.ReviewComments ? 'dashboard-tab' : ''}`}
              onClick={() => this.onTabClick(AccountDashboardTabsType.ReviewComments)}
              style={{ backgroundColor: '#999999', color: 'white', marginRight: '5px' }}>
              <div className="tab-box reviewcomments-box">
                <div className="tab-icon">
                  <img src="/vendors/Review-Commnets.png" alt=""></img>
                </div>
                <div className="tab-name ">
                  Review <br></br> Comments
                </div>
              </div>
            </div>

            {/* <div className={`tab-main-box ${this.state.currentTab === AccountDashboardTabsType.CompanyTaxInformation ? 'dashboard-tab' : ''}`}
              onClick={() => this.onTabClick(AccountDashboardTabsType.CompanyTaxInformation)}
              style={{ backgroundColor: '#4D2DB7', color: 'white', marginRight: '5px' }}>
              <div className="tab-box companytaxinformation-box">
                <div className="tab-icon">
                  <img src="/vendors/Company-Tax-Information.png" alt=""></img>
                </div>
                <div className="tab-name ">
                Company Tax <br></br>Information
                </div>
              </div>
            </div> */}
          </div>
          {
            this.state.currentTab === AccountDashboardTabsType.ClientsReadyForFinancialCheckup && <div className="accountants-dashboard-table accountants-table-box">
              {this.state.loading ? <CircularProgress></CircularProgress>
                :
                <React.Fragment>
                  <div className=" table-responsive-material">
                    <table className="table">
                      <thead>
                        <tr className="color-tr">
                          <th>Company Name</th>
                          <th>General Ledger Review </th>
                          <th>Balance Sheet Review</th>
                          <th className="table-th">All Bank Accounts Reconciled</th>
                          <th className="table-th">All Credit Card Accounts Reconciled</th>
                          <th className="table-th">No Missing Vendor Names</th>
                          <th className="table-th">No Missing Descriptions</th>
                          <th className="table-th">No Account # Changes</th>
                          <th className="table-th">No Transactions that Need a Better Descriptions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.FinancialData.map((item, i) => (
                          <tr key={i}>
                            <td>{item.companyName}</td>
                            <td style={{ textAlign: "center" }}>
                              {item.isGeneralLedgerReviewed == null ?
                                "" :
                                <label className="container101">
                                  <input className="second-check-box" checked={item.isGeneralLedgerReviewed} type="checkbox" disabled />
                                  <span className="checkmark101 green_CheckMark"></span>
                                </label>
                              }
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {item.isBalanceSheetArchived == null ?
                                "" :
                                <label className="container101">
                                  <input className="second-check-box" checked={item.isBalanceSheetArchived} type="checkbox" disabled />
                                  <span className="checkmark101 green_CheckMark"></span>
                                </label>
                              }
                            </td>
                            <td>
                              <label className="container101">
                                <input className="second-check-box" checked={item.isAllBankAccountsReconcilied} type="checkbox" disabled />
                                <span className="checkmark101 green_CheckMark"></span>
                              </label>
                            </td>

                            <td style={{ textAlign: "center" }}>
                              {item.isAllCreditCardAccountsReconcilied == null ?
                                <strong> N/A </strong> :
                                <label className="container101">
                                  <input className="second-check-box" checked={item.isAllCreditCardAccountsReconcilied} type="checkbox" disabled />
                                  <span className="checkmark101 green_CheckMark"></span>
                                </label>
                              }
                            </td>
                            <td>
                              <label className="container101">
                                <input className="second-check-box" checked={item.isNoMissingVendorNames} type="checkbox" disabled />
                                <span className="checkmark101 green_CheckMark"></span>
                              </label>
                            </td>
                            <td>
                              <label className="container101">
                                <input className="second-check-box" checked={item.isNoMissingDescriptions} type="checkbox" disabled />
                                <span className="checkmark101 green_CheckMark"></span>
                              </label>
                            </td>
                            <td>
                              <label className="container101">
                                <input className="second-check-box" checked={item.isNoAccountNoChanges} type="checkbox" disabled />
                                <span className="checkmark101 green_CheckMark"></span>
                              </label>
                            </td>
                            <td>
                              <label className="container101">
                                <input className="second-check-box" checked={item.isNoTransectionNBD} type="checkbox" disabled />
                                <span className="checkmark101 green_CheckMark"></span>
                              </label>
                            </td>
                          </tr>
                        ))}
                        {this.state.FinancialData == null || this.state.FinancialData.length === 0 &&
                          <tr>
                            <td colSpan="9" className="text-center"> No Data Found </td>
                          </tr>}
                      </tbody>
                    </table>
                  </div>
                  <div style={{ marginLeft: "10px" }}>
                    <PaginationComponent
                      key={this.state.currentTab + "_" + this.state.PageNo + "_" + this.state.FinancialData.length}
                      selectedPageNo={this.state.PageNo}
                      selectedSize={this.state.PageSize}
                      TotalRecords={this.state.TotalRecords}
                      ChangePageSize={this.ChangePageSize}
                      ChangePage={this.ChangePage}
                      CurrentRecords={this.state.FinancialData.length}
                    >
                    </PaginationComponent>
                  </div>

                </React.Fragment>
              }
            </div>
          }
          {
            this.state.currentTab === AccountDashboardTabsType.PreCheckIssues && <div className="accountants-table-box pre-check-detail">
              {this.state.loading ? <CircularProgress></CircularProgress>
                :
                <React.Fragment>
                  <div className=" table-responsive-material">
                    <table className="table">
                      <thead>
                        <tr className="color-tr">
                          <th>Company Name</th>
                          <th className="table-th">Please Add a Vendor Name for These Payments</th>
                          <th className="table-th">Please Add a Description for These Transactions</th>
                          <th className="table-th">Please Investigate These Old Unreconciled Transactions</th>
                          <th className="table-th">Please Add a Good Description for These Transactions</th>
                          <th className="table-th">Please Approve These Account Category Corrections</th>
                          <th className="table-th">Line Item Questions & Comments</th>
                          <th className="table-th">General Questions & Comments</th>
                          <th className="table-th">UnCategorized Transaction</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.PreCheckIssues.map((item, i) => (
                          <tr key={i}>
                            <td >{item.companyName}</td>
                            <td className="text-center">
                              {item.missingVendorNamesCount != null && item.missingVendorNamesCount > 0 &&
                                <span><b>{item.missingVendorNamesCount}</b></span>}
                            </td>
                            <td className="text-center">
                              {item.missingDescriptionsCount != null && item.missingDescriptionsCount > 0 && <span><b>{item.missingDescriptionsCount} </b></span>}
                            </td>
                            <td className="text-center">
                              {item.oldOutstadingTransactionsCount != null && item.oldOutstadingTransactionsCount > 0 && <span><b> {item.oldOutstadingTransactionsCount} </b> </span>}
                            </td>
                            <td className="text-center">
                              {item.nbdCount != null && item.nbdCount > 0 && <span><b>{item.nbdCount} </b></span>}
                            </td>
                            <td className="text-center">
                              {item.accountNoChangesCount != null && item.accountNoChangesCount > 0 && <span><b> {item.accountNoChangesCount} </b></span>}
                            </td>
                            <td className="text-center">
                              {item.lineItemQuestionandCommentsCount != null && item.lineItemQuestionandCommentsCount > 0 && <span><b>{item.lineItemQuestionandCommentsCount} </b></span>}
                            </td>
                            <td className="text-center">
                              {item.generalQuestionsAndCommentsCount != null && item.generalQuestionsAndCommentsCount > 0 && <span><b> {item.generalQuestionsAndCommentsCount} </b></span>}
                            </td>
                            <td className="text-center">
                              {item.uncategorizedTransactionsCount != null && item.uncategorizedTransactionsCount > 0 && <span><b> {item.uncategorizedTransactionsCount} </b></span>}
                            </td>
                          </tr>
                        ))}
                        {this.state.PreCheckIssues == null || this.state.PreCheckIssues.length === 0 && <tr>
                          <td colSpan="9" className="text-center"> No Data Found</td>
                        </tr>}
                      </tbody>
                    </table>
                  </div>
                  <div style={{ marginLeft: "10px" }}>
                    <PaginationComponent
                      key={this.state.currentTab + "_" + this.state.PageNo + "_" + this.state.PreCheckIssues.length}
                      selectedPageNo={this.state.PageNo}
                      selectedSize={this.state.PageSize}
                      TotalRecords={this.state.TotalRecords}
                      ChangePageSize={this.ChangePageSize}
                      ChangePage={this.ChangePage}
                      CurrentRecords={this.state.PreCheckIssues.length}
                    >
                    </PaginationComponent>
                  </div>


                </React.Fragment>
              }
            </div>
          }
          {
            this.state.currentTab === AccountDashboardTabsType.OtherIssues && <div className="accountants-table-box other-issues-detail">
              Other Issues Under Development
          </div>
          }
          {
            this.state.currentTab === AccountDashboardTabsType.PayrollAdministaration && <div className="accountants-table-box payroll-administaration-detail">
              {this.state.loading ? <CircularProgress></CircularProgress>
                :
                <React.Fragment>
                  <div className=" table-responsive-material">
                    <table className="table">
                      <thead>
                        <tr className="color-tr">
                          <th>Company Name</th>
                          <th className="table-th">Please Obtain a W-4 from These Employees</th>
                          <th className="table-th">Record Check in Payroll System</th>
                          <th className="table-th">Record Employee Payment in QB Payroll System</th>
                          <th className="table-th">Review Ignored Employee Payments</th>
                          <th className="table-th">Resolve Excess Housing Allowance Issues</th>
                          <th className="table-th">Please Submit Employee Use of Vehicle Form</th>
                          <th className="table-th">Record Employee Use of Vehicle</th>
                          <th className="table-th">Add to Employee List</th>
                          <th className="table-th">Please Approve Workers as Employees</th>
                          <th className="table-th"> Please Let Us Know Which Employee These Taxable Fringe Benefits Were For  </th>
                          <th className="table-th"> Record Taxable Fringe Benefit in Payroll System  </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.PayrollData.map((item, i) => (
                          <tr key={i}>
                            <td >{item.companyName}</td>
                            <td className="text-center"> {item.employeeW4Count != null && item.employeeW4Count > 0 && <span><b> {item.employeeW4Count} </b></span>} </td>
                            <td className="text-center"> {item.recordCheckInPayrollSystemCount != null && item.recordCheckInPayrollSystemCount > 0 && <span><b> {item.recordCheckInPayrollSystemCount} </b></span>} </td>
                            <td className="text-center"> {item.recordEmployeePaymentInQBPayrollSystemCount != null && item.recordEmployeePaymentInQBPayrollSystemCount > 0 && <span><b> {item.recordEmployeePaymentInQBPayrollSystemCount} </b></span>} </td>
                            <td className="text-center"> {item.reviewIgnoredEmployeePaymentsCount != null && item.reviewIgnoredEmployeePaymentsCount > 0 && <span><b>{item.reviewIgnoredEmployeePaymentsCount} </b></span>} </td>
                            <td className="text-center"> {item.resolveExcessHousingAllowanceIssuesCount != null && item.resolveExcessHousingAllowanceIssuesCount > 0 && <span><b> {item.resolveExcessHousingAllowanceIssuesCount} </b></span>} </td>
                            <td className="text-center"> {item.submitEmployeeUseofVehicleCount != null && item.submitEmployeeUseofVehicleCount > 0 && <span><b>{item.submitEmployeeUseofVehicleCount} </b></span>} </td>
                            <td className="text-center"> {item.recordEmployeeUseOfVehicleCount != null && item.recordEmployeeUseOfVehicleCount > 0 && <span><b> {item.recordEmployeeUseOfVehicleCount} </b></span>} </td>
                            <td className="text-center"> {item.addToEmployeeListCount != null && item.addToEmployeeListCount > 0 && <span><b> {item.addToEmployeeListCount} </b></span>} </td>
                            <td className="text-center"> {item.approveWorkerClassifiedAsAnEmployeeCount != null && item.approveWorkerClassifiedAsAnEmployeeCount > 0 && <span><b> {item.approveWorkerClassifiedAsAnEmployeeCount} </b></span>} </td>
                            <td className="text-center"> {item.assignTaxableFringeBenefitToEmployeeCount != null && item.assignTaxableFringeBenefitToEmployeeCount > 0 && <span><b> {item.assignTaxableFringeBenefitToEmployeeCount} </b></span>} </td>
                            <td className="text-center"> {item.recordTaxableFringeBenefitInPayrollSystemCount != null && item.recordTaxableFringeBenefitInPayrollSystemCount > 0 && <span><b> {item.recordTaxableFringeBenefitInPayrollSystemCount} </b></span>} </td>
                          </tr>
                        ))}
                        {this.state.PayrollData == null || this.state.PayrollData.length === 0 && <tr>
                          <td colSpan="12" className="text-center"> No Data Found</td>
                        </tr>}
                      </tbody>
                    </table>
                  </div>
                  <div style={{ marginLeft: "10px" }}>
                    <PaginationComponent
                      key={this.state.currentTab + "_" + this.state.PageNo + "_" + this.state.PayrollData.length}
                      selectedPageNo={this.state.PageNo}
                      selectedSize={this.state.PageSize}
                      TotalRecords={this.state.TotalRecords}
                      ChangePageSize={this.ChangePageSize}
                      ChangePage={this.ChangePage}
                      CurrentRecords={this.state.PayrollData.length}
                    >
                    </PaginationComponent>
                  </div>


                </React.Fragment>
              }
            </div>
          }
          {
            this.state.currentTab === AccountDashboardTabsType.Administaration_1099 && <div className="accountants-table-box administaration-detail">
              {this.state.loading ? <CircularProgress></CircularProgress>

                :
                <React.Fragment>
                  <div className=" table-responsive-material">
                    <table className="table">
                      <thead>
                        <tr className="color-tr">
                          <th>Company Name</th>
                          <th className="table-th"> All checking accounts been reconciled </th>
                          <th className="table-th">Last 1099 Compliance Review</th>
                          <th className="table-th">Please Request a W-9 From These Vendors</th>
                          <th className="table-th">Resolve Vendor Name Discrepancies</th>
                          <th className="table-th">1099's  Pending Accountant Review</th>
                          <th className="table-th">Please Approve These 1099's</th>
                          <th className="table-th">1099 NEC's Ready to Export</th>
                          <th className="table-th">1099 MISC's Ready to Export</th>
                          <th className="table-th">Confirm 1099's Were Mailed</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.Administartion1099Data.map((item, i) => (
                          <tr key={i}>
                            <td >{item.companyName}</td>
                            <td className="text-center">{item.allCheckingAccountsBeenReconciled}</td>
                            <td className="text-center"> {item.readyfor1099ComplianceReview} </td>
                            <td className="text-center"> {item.vendorsMissingW9Count != null && item.vendorsMissingW9Count > 0 && <span><b> {item.vendorsMissingW9Count} </b></span>} </td>
                            <td className="text-center"> {item.resolveVendorNameDiscrepanciesCount != null && item.resolveVendorNameDiscrepanciesCount > 0 && <span><b> {item.resolveVendorNameDiscrepanciesCount} </b></span>} </td>
                            <td className="text-center"> {item.vendor1099PendingAccountantReviewCount != null && item.vendor1099PendingAccountantReviewCount > 0 && <span><b> {item.vendor1099PendingAccountantReviewCount} </b></span>} </td>
                            <td className="text-center"> {item.vendor1099PendingClientReviewCount != null && item.vendor1099PendingClientReviewCount > 0 && <span><b> {item.vendor1099PendingClientReviewCount} </b></span>} </td>
                            <td className="text-center"> {item.vendor1099NECReadyToExportCount != null && item.vendor1099NECReadyToExportCount > 0 && <span><b> {item.vendor1099NECReadyToExportCount} </b></span>} </td>
                            <td className="text-center"> {item.vendor1099MISCReadyToExportCount != null && item.vendor1099MISCReadyToExportCount > 0 && <span><b> {item.vendor1099MISCReadyToExportCount} </b></span>} </td>
                            <td className="text-center"> {item.confirm1099WereMailedCount != null && item.confirm1099WereMailedCount > 0 && <span><b> {item.confirm1099WereMailedCount} </b></span>} </td>
                          </tr>
                        ))}
                        {this.state.Administartion1099Data == null || this.state.Administartion1099Data.length === 0 && <tr>
                          <td colSpan="9" className="text-center"> No Data Found</td>
                        </tr>}
                      </tbody>
                    </table>
                  </div>
                  <div style={{ marginLeft: "10px" }}>
                    <PaginationComponent
                      key={this.state.currentTab + "_" + this.state.PageNo + "_" + this.state.Administartion1099Data.length}
                      selectedPageNo={this.state.PageNo}
                      selectedSize={this.state.PageSize}
                      TotalRecords={this.state.TotalRecords}
                      ChangePageSize={this.ChangePageSize}
                      ChangePage={this.ChangePage}
                      CurrentRecords={this.state.Administartion1099Data.length}
                    >
                    </PaginationComponent>
                  </div>
                </React.Fragment>
              }
            </div>
          }
          {
            this.state.currentTab === AccountDashboardTabsType.ReviewComments && <div className="accountants-table-box review-comments-detail">
              {this.state.loading ? <CircularProgress></CircularProgress>
                :
                <React.Fragment>
                  <div className=" table-responsive-material">
                    <table className="table">
                      <thead>
                        <tr className="color-tr">
                          <th className="">Company Name</th>
                          <th className="table-th"> Review Comments</th>
                          <th className="table-th"> CPA Review</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.ReviewCommentsData.map((item, i) => (
                          <tr key={i}>
                            <td> {item.companyName}</td>
                            <td className="text-center">
                              <label className="container101">
                                <input className="second-check-box" checked={item.isReviewComments} type="checkbox" disabled />
                                <span className="checkmark101 green_CheckMark"></span>
                              </label>
                            </td>
                            <td className="text-center">
                              <label className="container101">
                                <input className="second-check-box" checked={item.isCPAReview} type="checkbox" disabled />
                                <span className="checkmark101 green_CheckMark"></span>
                              </label>
                            </td>
                          </tr>
                        ))}

                        {this.state.ReviewCommentsData == null || this.state.ReviewCommentsData.length === 0 && <tr>
                          <td colSpan="8" className="text-center"> No Data Found</td>
                        </tr>}
                      </tbody>
                    </table>
                  </div>
                  <div style={{ marginLeft: "10px" }}>
                    <PaginationComponent
                      key={this.state.currentTab + "_" + this.state.PageNo + "_" + this.state.ReviewCommentsData.length}
                      selectedPageNo={this.state.PageNo}
                      selectedSize={this.state.PageSize}
                      TotalRecords={this.state.TotalRecords}
                      ChangePageSize={this.ChangePageSize}
                      ChangePage={this.ChangePage}
                      CurrentRecords={this.state.ReviewCommentsData.length}
                    >
                    </PaginationComponent>
                  </div>
                </React.Fragment>
              }
            </div>
          }

          {/* {
            this.state.currentTab === AccountDashboardTabsType.CompanyTaxInformation && <div className="accountants-table-box company-tax-detail">
              {this.state.loading ? <CircularProgress></CircularProgress>
                :
                <React.Fragment>
                  <div className=" table-responsive-material">
                    <table className="table">
                      <thead>
                        <tr className="color-tr">
                          <th > Company Name </th>
                          <th > Legal Address </th>
                          <th > EIN </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.CompanyTaxData.map((item, i) => (
                          <tr key={i}>
                            <td> {item.name ? `${item.name}` : ''}</td>
                            <td className="text-center">
                            {item.address ? `${item.address},` : ''} 
                            {item.city ? `${item.city},` : ''} 
                            {item.state ? `${item.state},` : ''} 
                            {item.postalCode ? `${item.postalCode}` : ''}
                            </td>
                            <td className="text-center">
                              {item.eiNnumber? `${item.eiNnumber}` : ''}
                            </td>
                          </tr>
                        ))}

                        {this.state.CompanyTaxData == null || this.state.CompanyTaxData.length === 0 && <tr>
                          <td colSpan="8" className="text-center"> No Data Found</td>
                        </tr>}
                      </tbody>
                    </table>
                  </div>
                  <div style={{ marginLeft: "10px" }}>
                    <PaginationComponent
                      key={this.state.currentTab + "_" + this.state.PageNo + "_" + this.state.CompanyTaxData.length}
                      selectedPageNo={this.state.PageNo}
                      selectedSize={this.state.PageSize}
                      TotalRecords={this.state.TotalRecords}
                      ChangePageSize={this.ChangePageSize}
                      ChangePage={this.ChangePage}
                      CurrentRecords={this.state.CompanyTaxData.length}
                    >
                    </PaginationComponent>
                  </div>
                </React.Fragment>
              }
            </div>
          } */}
        </div>
      </div>
      <PrintReport visible={this.state.isPrintReportModalOpen}
      handlePopUpClose={this.handlePrintReportClose}></PrintReport>
      </>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    FinancialData: state.AccountantDashboardData.FinancialData,
    PrecheckData: state.AccountantDashboardData.PrecheckData,
    ReviewCommentsData: state.AccountantDashboardData.ReviewCommentsData,
    Adminstration1099Data: state.AccountantDashboardData.Adminstration1099Data,
    PayrollAdminstrationData: state.AccountantDashboardData.PayrollAdminstrationData,
    CompanyTaxData:state.AccountantDashboardData.CompanyTaxData,
    companyDashboardData: state.companyDashboardData.companyDashboardData,
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    Get_PrecheckIssues,
    Get_FinancialIssues,
    Get_AccountantDashboard_GetReviewCommentsSummary,
    GetPayrollAdministrationSummary,
    Get1099AdministrationSummary,
    Get_ExcelExportData,
    // CompanyTaxInformation
  }, dispatch)
}



export default connect(mapStateToProps, mapDispatchToProps)(Intranet);

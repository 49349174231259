import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import PaginationComponent from "./../../../../src/app/common/Pagination";
import { ReportType, Base64, MaskTaxIdentifier, numberWithCommas,LinkType } from "./../../../app/common/commonServices";
import moment from 'moment';
import { bindActionCreators } from 'redux';
import CircularProgress from './../../../components/CircularProgress';
import { GET_CompanyDashboard_PayrollVendorReportDetailList_BES, MarkClientApproval,AccountReconciliationReviewSummery } from "./../../../appRedux/actions/ReportDetailAction";
import { toast } from 'react-toastify';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import queryString from 'query-string';
import Grid from '@material-ui/core/Grid';
import { getCompnay } from '../../../appRedux/actions/companiesAction';
import RefreshDataModal from '../../../components/RefreshDataModal'



class Report1099Status_Embedded extends Component {


    constructor(props) {
        super(props);

        let eDate = new Date();
        let sDate = new Date(eDate.getFullYear(), 0, 1);

        if (eDate.getMonth() <= 2) {
            sDate = new Date(sDate.getFullYear() - 1, 0, 1);
            eDate = new Date(eDate.getFullYear() - 1, 11, 31);
        }

        const years = [];
    const currentYear = new Date().getFullYear();
    for (let year = currentYear ; year >= currentYear - 25; year--) {
        if (year >= 2020) {
            years.push(year);
          }
    }
    this.years = years
        this.state = {
            parantData: this.props.location.state ? this.props.location.state : null,
            MissingData: [],
            companyId: 0,
            sortField: 'name',
            sortFormat: "A",
            PageNo: 1,
            PageSize: 10,
            ColorCode: "#f0ca4c",
            sDate: sDate,
            eDate: eDate,
            loading: false,
            TotalRecords: 0,
            IsEmbeddedPage: true,
            HomeLink: "",
            ACRSummary:"No",
            lastReconciledThrough:null,
            companyName: "",
            isRefreshDataModalOpen:false
        }
    }

    onSortChange = (fieldName) => {
        if (this.state.sortField == fieldName) {
            let formate = this.state.sortFormat == 'A' ? 'D' : 'A';
            this.setState({ sortFormat: formate, loading: true }, () => {
                this.GetReportData();
            });
        } else {
            this.setState({ sortField: fieldName, sortFormat: 'A', loading: true }, () => {
                this.GetReportData();
            });
        }
    }
    YearChange = (e) => {

        this.setState({ eDate: new Date(e.target.value, 11, 31),sDate: new Date(e.target.value, 0, 1)},() => {
    
          this.GetReportData();
        });
      }

    refreshData = () => {
        if (!this.state.loading) {
            this.setState({ loading: true,isRefreshDataModalOpen:false }, () => {
                this.GetReportData(true);
            });
        }
    }

    onRefreshDataClick = () => {
        
        this.setState({isRefreshDataModalOpen:true})
    }

    handleRefreshDataModal=()=>{
        this.setState({isRefreshDataModalOpen:false})
      }

    ChangePageSize = (size) => {

        this.setState({ loading: true, PageSize: parseInt(size), PageNo: 1 }, () => {
            this.GetReportData();
        });
    }

    ChangePage = (page) => {
        this.setState({ loading: true, PageNo: page, }, () => {
            this.GetReportData();
        });
    }

    GetReportData(IsRefresh = false) {
        let state = this.state;
        let body = {
            companyID: state.companyId,
            reportType: ReportType.Vendor1099StatusReport,
            fromDate: moment(state.sDate).format("MM/DD/YYYY"),
            toDate: moment(state.eDate).format("MM/DD/YYYY"),
            sortField: state.sortField,
            sortFormat: state.sortFormat,
            pageNumber: state.PageNo,
            pageSize: state.PageSize,
            needUpdatQBORecords: IsRefresh
        };

        this.props.GET_CompanyDashboard_PayrollVendorReportDetailList_BES(body);
    }

    getCompanyId = () => {
        let value = queryString.parse(this.props.location.search);
        localStorage.setItem("q", JSON.stringify(value));
        let data = localStorage.getItem("q");

        if (data != null && data != undefined) {
            data = JSON.parse(data);
            this.setState({
                companyId: parseInt(Base64.decode(data.q)),
                companyName:Base64.decode(data.p),
                loading: true
            }, () => {
            this.props.getCompnay(Base64.decode(data.q));
                this.props.AccountReconciliationReviewSummery(Base64.decode(data.q));
                this.GetReportData();
            });
        }
        else {
            this.setState({ loading: false });
        }
    }

    UpdateVendorAction = (e, type, item) => {
        let body = {
            "companyID": this.state.companyId,
            "ids": [parseInt(item.listID)],
            "reportType": ReportType.Vendor1099PendingClientApproval,
            "IsRemove": e.target.checked?false:true
        }
        this.setState({ loading: true }, () => {
            this.props.MarkClientApproval(body);
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.ReportData !== this.props.ReportData) {
            if (nextProps.ReportData.transactionStatus === 0) {
                var totalrecords = 0;
                let items = nextProps.ReportData.returnObject;
                if (nextProps.ReportData.returnObject != null && nextProps.ReportData.returnObject.length > 0) {
                    totalrecords = nextProps.ReportData.returnObject[0].totalrows
                    items.map((dt, index) => {
                        items[index].MaskTax = MaskTaxIdentifier(dt.taxIdentifier);
                    });
                }
                this.setState({ MissingData: items, TotalRecords: totalrecords, PageSize: this.state.PageSize, PageNo: this.state.PageNo, loading: false });
            }
            else {
                toast.error(nextProps.ReportData.resultMsg);
                this.setState({ loading: false });
            }
        }
        if (nextProps.company !== this.props.CompanyInfo) {
            this.setState({ loading: false, CompanyInfo: nextProps.company});
        }
        if (nextProps.UpdateDescription !== this.props.UpdateDescription) {
            if (nextProps.UpdateDescription.transactionStatus === 0) {
                this.setState({ loading: true }, () => {
                    toast.success(`${nextProps.UpdateDescription.resultMsg}`);
                    this.GetReportData();
                });
            }
            else {
                this.setState({
                    loading: false
                }, () => {
                    toast.error(`${nextProps.UpdateDescription.resultMsg}`);
                });
            }
        }

        if (nextProps.ARCRSummary !== this.props.ARCRSummary) {
            if (nextProps.ARCRSummary.transactionStatus === 0) 
            {
                if(nextProps.ARCRSummary.returnObject.length>0)
                {
                    this.setState({ loading: false,ACRSummary:nextProps.ARCRSummary.returnObject[0].haveAllCheckingAccounts?"Yes":"No",lastReconciledThrough:nextProps.ARCRSummary.returnObject[0].lastReconciledThrough});
                }                
            }
            else
            {
                this.setState({ loading: false},() =>{ toast.error(`${nextProps.ARCRSummary.resultMsg}`)});
            }
            
        }
    }

    componentDidMount() {
        let value = queryString.parse(this.props.location.search);
        // localStorage.setItem("homeURL", window.location.href.replace(window.location.origin, ""))
        let dashboardURl = localStorage.getItem("homeURL");
        this.setState({
            loading: true,
            HomeLink: dashboardURl ? dashboardURl : "?q="+ value.q + "&p=" + value.p
        }, () => {            
            this.getCompanyId();
        });
    }

    render() {
        return (
            <div className="dashboardPage embeded-dashboard">
                 <RefreshDataModal visible={this.state.isRefreshDataModalOpen}
                 handlePopUpClose={this.handleRefreshDataModal} refreshData={this.refreshData}/>
                <div className=" page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
                    <h2 className="mb-3 mb-sm-0">{this.state.companyName} | {LinkType.StatusReport1099}</h2>
                    <Link className="home-link" to={this.state.HomeLink} > Home </Link>
                </div>
                {this.state.loading ? <CircularProgress></CircularProgress>
                    :
                    <React.Fragment>
                        <div className="padding-box app-wrapper bgcolor">
                            <div className="row">
                                <div className="col-md-12">
                                <div className="row">
                      <div className="col-md-12">
                        <div className="float-left arc_summary">
                            
                        {this.state.CompanyInfo ? 
                    <Grid container spacing={24} style={{ paddingBottom: 13 , columnGap:40 }}>
                      <Grid item>
                        <h3>
                          <b>Company Legal Name test: </b> {this.state.CompanyInfo.name ? this.state.CompanyInfo.name : ''}
                        </h3>
                        <h3>
                          <b>Legal Address : </b> {this.state.CompanyInfo.address ? this.state.CompanyInfo.address :""}
                        </h3> 
                      </Grid>
                      <Grid item>
                        <h3>
                          <b>City : </b> {this.state.CompanyInfo.city ? this.state.CompanyInfo.city :``}
                        </h3>
                        <h3>
                          <b>State : </b> {this.state.CompanyInfo.state ? this.state.CompanyInfo.state :``}
                        </h3> 
                        </Grid>
                        <Grid item>
                        <h3>
                          <b>Zip : </b> {this.state.CompanyInfo.postalCode ? this.state.CompanyInfo.postalCode :``}
                        </h3>
                        <h3>
                          <b>EIN Number : </b> {this.state.CompanyInfo.eiNnumber ? this.state.CompanyInfo.eiNnumber : ``}
                        </h3> 
                        
                        </Grid>
                    </Grid> :``}
                        </div>
                        </div>
                        </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                        <div className="float-left arc_summary">
                                        <span>Have all checking accounts been reconciled through {this.state.lastReconciledThrough}?: {this.state.ACRSummary}  </span>
                                        </div>
                                            <div className="float-right">
                                            <span>Select Year:</span>
                                            <select className="select-box arrow-box" name="year" onChange={(e) => this.YearChange(e)} value={this.state.eDate.getFullYear()} style={{marginRight:"10px",height:'40px'}}>
                                            {this.years.map((option, index) => (
                                            <option key={index} value={option}>
                                              {option}
                                            </option>
                                          ))}
                                        </select>
                                                {/* <Button variant="raised" className=" jr-btn g-teal  text-white refresh-btn graycolor" onClick={() => this.OnApplyApproved()}  >Resolved</Button> */}
                                                <Button variant="raised" style={{ backgroundColor: this.state.ColorCode }} className="jr-btn text-white refresh-btn" onClick={() => this.onRefreshDataClick()}  ><i className="zmdi zmdi-refresh "></i> Refresh Data</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive-material">
                                <table className="table tablebox account-reconcilliation">
                                    <thead>
                                        <tr style={{ backgroundColor: this.state.ColorCode }}>
                                            <th className={`td-width ${this.state.sortField == 'name' ? this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('name')}><p className="table-th-title">Display Name</p> </th>
                                            {/* <th className={`td-width  ${this.state.sortField === 'familyName' ? this.state.sortFormat == 'A'
                                            ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('familyName')}><p className="table-th-title"> Last Name</p></th> */}
                                            <th className={`td-width ${this.state.sortField == 'companyName' ? this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('companyName')}><p className="table-th-title">Company Name</p> </th>
                                            <th className={`td-width  ${this.state.sortField === 'address' ? this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('address')}><p className="table-th-title"> Address</p></th>
                                            <th className={`td-width  ${this.state.sortField === 'taxIdentifier' ? this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('taxIdentifier')}><p className="table-th-title"> Tax Identifier</p></th>
                                            <th className={`td-width  ${this.state.sortField === 'vendorType' ? this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('vendorType')}><p className="table-th-title"> Vendor Type</p></th>
                                            <th className={`td-width text-center ${this.state.sortField === 'totalPayment' ? this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('totalPayment')}><p className="table-th-title"> Total Payment</p></th>
                                            <th className="td-width-90 text-center">
                                                Approved by Accountant
                                        </th>
                                            <th className="td-width-90 text-center">
                                                Approved by Client
                                        </th>
                                            <th className="td-width-90 text-center">
                                                Exported
                                        </th>
                                            <th className="td-width-90 text-center">
                                                Mailed
                                        </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.MissingData != null && this.state.MissingData.length > 0 ?
                                            <React.Fragment>
                                                {this.state.MissingData.map((item, i) => (
                                                    <tr>
                                                        <td>{item.displayName}</td>
                                                        {/* <td>{item.familyName}</td> */}
                                                        <td>{item.companyName}</td>
                                                        <td>{item.address}</td>
                                                        <td>{item.taxIdentifier}</td>
                                                        <td>{item.vendorType}</td>
                                                        <td className="text-center">{numberWithCommas(item.totalPayment)}</td>
                                                        <td>

                                                            <label className="container101">
                                                                {item.vendor1099ApprovedByAccountant ?
                                                                    <input className="second-check-box" checked="checked" disabled type="checkbox" />
                                                                    :
                                                                    <input className="second-check-box" type="checkbox" disabled />
                                                                }
                                                                <span className="checkmark101 green_CheckMark"></span>
                                                            </label>
                                                        </td>
                                                        <td>
                                                            <label className="container101">
                                                                {item.vendor1099ApprovedByClient ?
                                                                    <input className="second-check-box" checked="checked" onChange={(e) => this.UpdateVendorAction(e,"Client", item)} type="checkbox" />
                                                                    :
                                                                    <input className="second-check-box" type="checkbox" onChange={(e) => this.UpdateVendorAction(e,"Client", item)}  />
                                                                }
                                                                <span className="checkmark101 green_CheckMark"></span>
                                                            </label>
                                                        </td>
                                                        <td>
                                                            <label className="container101">
                                                                {item.alreadyExported ?
                                                                    <input className="second-check-box" checked="checked" disabled type="checkbox" />
                                                                    :
                                                                    <input className="second-check-box" type="checkbox" disabled />
                                                                }
                                                                <span className="checkmark101 green_CheckMark"></span>
                                                            </label>
                                                        </td>
                                                        <td>
                                                            <label className="container101">
                                                                {item.alreadyMailed ?
                                                                    <input className="second-check-box" checked="checked" disabled type="checkbox" />
                                                                    :
                                                                    <input className="second-check-box" type="checkbox" disabled />
                                                                }
                                                                <span className="checkmark101 green_CheckMark"></span>
                                                            </label>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </React.Fragment>
                                            :
                                            <tr>
                                                <td className="text-center" colSpan="10">
                                                No issues here! Keep up the good work!
                                            </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <PaginationComponent
                                key={this.state.MissingData.length}
                                selectedPageNo={this.state.PageNo}
                                selectedSize={this.state.PageSize}
                                TotalRecords={this.state.TotalRecords}
                                ChangePageSize={this.ChangePageSize}
                                ChangePage={this.ChangePage}
                                CurrentRecords={this.state.MissingData.length}
                            >
                            </PaginationComponent>
                        </div>
                        <ToastContainer autoClose={3000} />
                    </React.Fragment>
                }
            </div>
        )
    }

}

const mapStateToProps = (state) => {

    return {
        ReportData: state.ReportData.ReportData,
        UpdateDescription: state.ReportData.UpdateDescription,
        company: state.companyReducer.companyDeatils,
        ARCRSummary:state.ReportData.ARCRSummary
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        GET_CompanyDashboard_PayrollVendorReportDetailList_BES,
        MarkClientApproval,
        AccountReconciliationReviewSummery,
        getCompnay
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Report1099Status_Embedded);
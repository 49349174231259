import baseService from './baseService';

export function GetQuestions(data){
    return baseService.post("QuestionsAndComments/GetQuestions",data);
}

export function AddQuestion(data) {
    return baseService.post("QuestionsAndComments/AddQuestion",data);
}

export function MarkAsCPAReview(data)
{
    return baseService.post("QuestionsAndComments/MarkAsCPAReview",data);
}

export function MarkAsResolvedQueAndComm(data)
{
    return baseService.post("QuestionsAndComments/MarkAsResolvedQueAndComm",data);
}

export function CloseQuestion(id)
{
    return baseService.put("QuestionsAndComments/ClosedQuestion/"+id);
}

export function GetClientQuestion(data){
    return baseService.post("QuestionsAndComments/GetClientQuestions",data);
}
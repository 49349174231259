import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from '@material-ui/core/Button';
// import TextField from '@material-ui/core/TextField';
import Textarea from 'react-expanding-textarea';
import { toast } from "react-toastify";
// import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { GetLastQuarterEndDate, numberWithCommas, makeLinkForQuickbooks, ModuleType, ReportType,LinkType } from '../../../common/commonServices';
import { Accounts } from '../../../../appRedux/actions/accountAction';
import CircularProgress from "../../../../components/CircularProgress";
import { ReviewReconciliationDiscrepanciesData,MarkReportReview, LedgerUpdate,UpdateBatchNewAccount,GetReportReviewedHistory } from '../../../../appRedux/actions/LedgerAction';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CommonComments from './../../../common/Comments';
import RSelect from 'react-select';
import "./companyDash.css";
import { MARK_IGNORED_DATA,Revert_Ignored_DATA } from '../../../../appRedux/actions/ReportDetailAction';
import Badge from '@material-ui/core/Badge';
import { DuoOutlined } from '@material-ui/icons';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ClearIcon from '@material-ui/icons/Clear';

class ReviewReconciliationDiscrepancies extends Component {

    constructor(props) {
        super(props);
        this.sDateRef = React.createRef();
        this.eDateRef = React.createRef();
        // let eDate = new Date();
        // let sDate = new Date(eDate.getFullYear(), 0, 1);
        // if (eDate.getMonth() <= 2) {
        //     sDate.setFullYear(eDate.getFullYear() - 1, 0, 1);
        //     eDate = new Date(eDate.getFullYear() - 1, 11, 31);
        // }
        // else {
        //     sDate = new Date(eDate.getFullYear(), 0, 1);
        //     eDate = GetLastQuarterEndDate();
        // }

        this.state = {
            lst_GL: [],
            mergelst_GL: [],
            loading: false,
            companyId: 0,
            eDate: new Date(),
            sDate: new Date(2019,0,1),
            ColorCode: "#e27b40",
            IsEmbeddedPage: false,
            sortField: "date",
            sortFormat: "D",
            DisplayAccounts: [],
            selectedAccount: { value: 0, label: "Select" },
            SelectALL: false,
            SelectedRecords: [],
            IsIgnoredFilter:false,
            selectedBatchAccountId:{ value: 0, label: "Select" },
            ShowHistory:true,
            HistoryData:[]
        }
    }

    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('userData'));
        this.setState({
            companyId: user.companyId,
            loading: true
        }, () => {
            this.props.GetReportReviewedHistory(user.companyId,ReportType.ReviewReconciliationDiscrepancies)
            this.GetReportData();
            this.props.Accounts(this.state.companyId);
        });
    }

    handleFocus = (e, selecter) => {
        let control = null;
        if (selecter !== undefined) {
            if (selecter === "sDate") {
                control = this.sDateRef;
                if (control.current != null) {
                    control.current.setFocus();
                }
            }
            else if (selecter === "eDate") {
                control = this.eDateRef;
                if (control.current != null) {
                    control.current.setFocus();
                }
            }
        }
    }

    LoadComments = (item) => {
        this.setState({ ShowComment: true, CommentReferenceId: item.id });
    }

    CloseComments = () => {
        this.setState({ ShowComment: false });
    }
    HandleClose = () => {
        this.setState({ ShowHistory: false });
      }

    UpdateItemCommentCount = (id) => {
        let item = this.state.lst_GL;
        item.forEach(function (records) {

            records.data.forEach(function (details) {
                if (details.id === id) {
                    details.commentCount++
                }
            });
        });

        this.setState({ lst_GL: item });
    }

    GetReportData(IsRefresh = false) {
        let state = this.state;
        let body = {
            "companyID": state.companyId,
            "sortField": state.sortField,
            "sortFormat": state.sortFormat,
            "fromDate": moment(state.sDate).format("MM/DD/YYYY"),
            "toDate": moment(state.eDate).format("MM/DD/YYYY"),
            "needUpdatQBORecords": IsRefresh,
            "isAllIgnoreUnignore":state.IsIgnoredFilter
        };
        this.props.ReviewReconciliationDiscrepanciesData(body);
    }

    componentWillReceiveProps(nextProps) {      
        if (nextProps.ledgers !== undefined && nextProps.ledgers !== this.props.ledgers) {
            let arr =[];
            if (nextProps.ledgers.transactionStatus === 0 && nextProps.ledgers.returnObject.reviewReconciliationDiscrepancies) {
                nextProps.ledgers.returnObject.reviewReconciliationDiscrepancies.map((item)=>{
                    arr = [...arr,...item.data ]
                    //arr.push.apply(item.data)
                    // Array.prototype.push.apply(arr, item.data)
                }) 
                
                this.setState({ loading: false,mergelst_GL:arr, lst_GL: nextProps.ledgers.returnObject.reviewReconciliationDiscrepancies,SelectALL: false, SelectedRecords: [] })           
            }
            else {
                // toast.error(nextProps.ledgers.resultMsg);
                // this.setState({ loading: false });
                this.setState({ loading: false,mergelst_GL:arr, lst_GL:[]
                    //  nextProps.ledgers.returnObject.reviewReconciliationDiscrepancies
                     ,SelectALL: false, SelectedRecords: [] }, () => { !nextProps.ledgers.returnObject && toast.error(nextProps.ledgers.resultMsg); })
            }
        }

        if (nextProps.accounts != undefined && nextProps.accounts !== this.props.accounts) {

            let searchableAccounts = [];
            if (nextProps.accounts.data != null && nextProps.accounts.data.length > 0) {
                searchableAccounts = _.sortBy(nextProps.accounts.data, ['accountNumber', 'name']).map((value, index) => (
                    { value: value.accountID, label: (value.accountNumber != null ? value.accountNumber + ' ' + value.name : value.name) }
                ))
            }
            let defaultValue = { value: 0, label: "Select" };
            searchableAccounts.unshift(defaultValue);
            this.setState({
                accounts: nextProps.accounts.data,
                DisplayAccounts: searchableAccounts
            });
        }

     
        if (nextProps.LEDGER_UPDATE != undefined && nextProps.LEDGER_UPDATE !== this.props.LEDGER_UPDATE) {
            if (nextProps.LEDGER_UPDATE.transactionStatus === 0) {
                this.setState({
                    loading: true, editData: null, editIndex: -1, SelectedRecords: [], SelectALL: false
                }, () => {
                    toast.success("Record updated successfully");
                    this.GetReportData();
                });
            }
            else {
                this.setState({
                    loading: false, SelectedRecords: [], SelectALL: false
                }, () => {
                    toast.error(`${nextProps.LEDGER_UPDATE.resultMsg}`);
                });
    
            }
        }

        if (nextProps.BATCH_LEDGER_UPDATE != undefined && nextProps.BATCH_LEDGER_UPDATE !== this.props.BATCH_LEDGER_UPDATE) {
            if (nextProps.BATCH_LEDGER_UPDATE.transactionStatus === 0) {
                this.setState({
                    loading: true, editData: null, editIndex: -1, SelectedRecords: [], SelectALL: false,selectedBatchAccountId:{ value: 0, label: "Select" }
                }, () => {
                    toast.success("Record updated successfully");
                    this.GetReportData();
                });
            }
            else {
                this.setState({
                    loading: false, SelectedRecords: [], SelectALL: false
                }, () => {
                    toast.error(`${nextProps.BATCH_LEDGER_UPDATE.resultMsg}`);
                });
    
            }
        }

        if (nextProps.ReportReviewdHistory !== undefined && nextProps.ReportReviewdHistory !== this.props.ReportReviewdHistory) {
            if (nextProps.ReportReviewdHistory.transactionStatus === 0) {
                    this.setState({ShowHistory: true,HistoryData:nextProps.ReportReviewdHistory.returnObject});
            } 
        }
        if (nextProps.CommonUpdate !== undefined && nextProps.CommonUpdate !== this.props.CommonUpdate) {
            if (nextProps.CommonUpdate.transactionStatus === 0) {
            //   let data = this.state.ReportData;
            //   if (this.state.IsReviewProcessing == false) {
            //     let obj = this.state.editedData;
            //     data.map((item, index) => {
            //       if (item.listID === obj.listID) {
            //         item.vendor1099 = obj.vendor1099
            //       }
            //     })
      
            //     this.setState({editedData: null, IsReviewProcessing: false, reportData: data }, () => { toast.success("Request Process Successfully");
            //     // this.GetReportData();
            //    });
            //   }
            //   else {
                this.setState({ IsReviewProcessing: false}, () => {
                  toast.success("Reviewed Successfully");
                this.props.GetReportReviewedHistory(this.state.companyId,ReportType.ReviewReconciliationDiscrepancies)
                });
            //   }
            }
            else {
              this.setState({ loading: false }, () => { toast.error(`${nextProps.CommonUpdate.resultMsg}`); });
            }
      
          }
        if (nextProps.UpdateDescription !== this.props.UpdateDescription) {
            if (nextProps.UpdateDescription.transactionStatus === 0) {
                this.setState({
                    loading: true, editData: null, editIndex: -1, SelectALL: false, SelectedRecords: []
                }, () => {
                    toast.success(`${nextProps.UpdateDescription.resultMsg}`);
                    this.GetReportData();
                });
            }
            else {
                this.setState({
                    loading: false,
                }, () => {
                    toast.error(`${nextProps.UpdateDescription.resultMsg}`);
                });
            }
        }
    }

    onRefreshDataClick = () => {
        if (!this.state.loading) {
            this.setState({ loading: true }, () => {
                this.GetReportData(true);
            });
        }
    }
    onMarkReviewd = () => {
        this.setState({ 
            // IsReviewProcessing: true,
          //  loading: true
          loading: false
           }, () => {
          this.props.MarkReportReview(this.state.companyId,ReportType.ReviewReconciliationDiscrepancies);
        });
      }

    onRowExpandCollapseClick = (accountIndex, rowData) => {
        var dataOfGL = [...this.state.lst_GL];
        dataOfGL[accountIndex].isExpand = !dataOfGL[accountIndex].isExpand;
        if (this.state.editData && this.state.editIndex1 > -1 && this.state.editIndex > -1) {
            dataOfGL = [...this.state.lst_GL];
            let editIndex1 = this.state.editIndex1;
            let editIndex = this.state.editIndex;
            let editData = this.state.editData;
            dataOfGL[editIndex1].data[editIndex].vendor1099 = editData.vendor1099;
            dataOfGL[editIndex1].data[editIndex].taxableFringeBenefit = editData.tfb;
            dataOfGL[editIndex1].data[editIndex].needsBetterDescription = editData.nbd;
            dataOfGL[editIndex1].data[editIndex].transferCodedIncorrectly = editData.tci;
            dataOfGL[editIndex1].data[editIndex].accountantComment = editData.ac;
            dataOfGL[editIndex1].data[editIndex].internalNotes = editData.in;
            dataOfGL[editIndex1].data[editIndex].newAccountID = editData.newAccId;
            dataOfGL[editIndex1].data[editIndex].newAccountName = editData.newAccName;
            dataOfGL[editIndex1].data[editIndex].cpaReview = editData.cpa;
            dataOfGL[editIndex1].data[editIndex].pcCheck = editData.pc;
            dataOfGL[editIndex1].data[editIndex].memoOrDescription = editData.memoOrDescription;
            this.setState({
                lst_GL: dataOfGL, editIndex1: -1,
                editIndex: -1,
                editData: null
            });
        } else {
            this.setState({
                lst_GL: dataOfGL,
                editIndex1: -1,
                editIndex: -1,
                editData: null
            });
        }
    }

    // ApplyDate = () => {
    //     this.setState({
    //         loading: true,
    //     }, () => {
    //         this.GetReportData();
    //     });
    // }

    onLinkClick = (e, accountIndex, rowIndex, rowData) => {
        e.stopPropagation()
        let origin = makeLinkForQuickbooks();
        window.open(origin + rowData.transactionLink);
    }

    onRowUpdateClick = (accountIndex, rowIndex, rowData) => {
        let data = {
            id: rowData.id,
            transactionTypeId: rowData.transactionTypeId,
            transactionType: rowData.transactionType,
            accountId: rowData.accountId,
            newAccountId: Number(rowData.newAccountID == '' ? 0 : rowData.newAccountID),
            needsBetterDescription: rowData.needsBetterDescription,
            transferCodedIncorrectly: rowData.transferCodedIncorrectly,
            cpaReview: rowData.cpaReview,
            pcCheck: rowData.pcCheck,
            accountantComment: rowData.accountantComment,
            internalNotes: rowData.internalNotes,
            taxableFringeBenefit: rowData.taxableFringeBenefit,
            vendor1099: rowData.vendor1099,
            taxReporting: rowData.taxReporting,
            isArchived: rowData.isArchived || false,
            benefitTypeId: rowData.benefitTypeId || 0,
            employeeName: rowData.employeeName || '',
            clientComment: rowData.clientComment || '',
            accountChangeApprovedByClient: rowData.accountChangeApprovedByClient || false,
            clientReviewStatus: rowData.clientReviewStatus || false,
            accountantReviewStatus: rowData.accountantReviewStatus || false,
            descriptionNeedsEmployeeName: rowData.descriptionNeedsEmployeeName || false,
            clientApprovedTaxableFringeBenefit: rowData.clientApprovedTaxableFringeBenefit || false,
            accountantPostedTaxableFringeBenefit: rowData.accountantPostedTaxableFringeBenefit || false,
            memoOrDescription:rowData.memoOrDescription,
        };

        this.props.LedgerUpdate(data, this.state.companyId,"UnCategorized transaction updated successfully.");
        this.setState({
            editIndex1: -1,
            editIndex: -1,
            editData: null
        });
    }

    handleDateChange = (e, target) => {

        if (target === "fromDate") {

            this.setState({ sDate: new Date(e) });
        }
        else if (target === "uptoDate") {
            this.setState({ eDate: new Date(e) });
        }
    }

    onRowClick = (e, accountIndex, rowIndex, rowData) => {      
        if (this.state.editData && this.state.editIndex1 > -1 && this.state.editIndex > -1) {
            var dataOfGL = [...this.state.lst_GL];
            let editIndex1 = this.state.editIndex1;
            let editIndex = this.state.editIndex;
            let editData = this.state.editData;
            dataOfGL[editIndex1].data[editIndex].vendor1099 = editData.vendor1099;
            dataOfGL[editIndex1].data[editIndex].taxableFringeBenefit = editData.tfb;
            dataOfGL[editIndex1].data[editIndex].needsBetterDescription = editData.nbd;
            dataOfGL[editIndex1].data[editIndex].transferCodedIncorrectly = editData.tci;
            dataOfGL[editIndex1].data[editIndex].accountantComment = editData.ac;
            dataOfGL[editIndex1].data[editIndex].internalNotes = editData.in;
            dataOfGL[editIndex1].data[editIndex].newAccountID = editData.newAccId;
            dataOfGL[editIndex1].data[editIndex].newAccountName = editData.newAccName;
            dataOfGL[editIndex1].data[editIndex].cpaReview = editData.cpa;
            dataOfGL[editIndex1].data[editIndex].pcCheck = editData.pc;
            dataOfGL[editIndex1].data[editIndex].memoOrDescription = editData.memoOrDescription;
            this.setState({ lst_GL: dataOfGL });
        }
        let data = {
            tfb: rowData.taxableFringeBenefit,
            vendor1099: rowData.vendor1099,
            nbd: rowData.needsBetterDescription,
            tci: rowData.transferCodedIncorrectly,
            ac: rowData.accountantComment,
            in: rowData.internalNotes,
            newAccId: rowData.newAccountID,
            newAccName: rowData.newAccountName,
            cpa: rowData.cpaReview,
            pc: rowData.pcCheck,
            memoOrDescription:rowData.memoOrDescription
        }
        this.setState({
            editIndex1: accountIndex,
            editIndex: rowIndex,
            editData: data,
            selectedAccount:{ value: data.newAccId, label: data.newAccName }
        });
    }

    onRowCancelClick = (accountIndex, rowIndex, rowData) => {
        var data = [...this.state.lst_GL];
        let editData = this.state.editData;
        data[accountIndex].data[rowIndex].vendor1099 = editData.vendor1099;
        data[accountIndex].data[rowIndex].taxableFringeBenefit = editData.tfb;
        data[accountIndex].data[rowIndex].needsBetterDescription = editData.nbd;
        data[accountIndex].data[rowIndex].transferCodedIncorrectly = editData.tci;
        data[accountIndex].data[rowIndex].accountantComment = editData.ac;
        data[accountIndex].data[rowIndex].internalNotes = editData.in;
        data[accountIndex].data[rowIndex].newAccountID = editData.newAccId;
        data[accountIndex].data[rowIndex].newAccountName = editData.newAccName;
        data[accountIndex].data[rowIndex].cpaReview = editData.cpa;
        data[accountIndex].data[rowIndex].pcCheck = editData.pc;
        data[accountIndex].data[rowIndex].memoOrDescription = editData.memoOrDescription;
        this.setState({
            lst_GL: data,
            editIndex1: -1,
            editIndex: -1,
            editData: null
        });
    }
    
    handalAccountDropdown = (e, accountIndex, rowIndex, rowData) => {

        if (e.value == null || e.value == 0) {            
            var data = [...this.state.lst_GL];
            data[accountIndex].data[rowIndex].newAccountID = null;
            data[accountIndex].data[rowIndex].newAccountName = null;
            this.setState({ lst_GL: data, selectedAccount: e });
        }
        else {
            let obj = this.state.accounts.find(x => x.accountID == e.value);
            var data = [...this.state.lst_GL];
            data[accountIndex].data[rowIndex].newAccountID = e.value;
            data[accountIndex].data[rowIndex].newAccountName = obj.name;
            this.setState({ lst_GL: data, selectedAccount: e });
        }
    }

    

    handalCheckbox = (e, accountIndex, rowIndex, rowData) => {
        var data = [...this.state.lst_GL];
        data[accountIndex].data[rowIndex][e.target.name] = e.target.checked;
        this.setState({ lst_GL: data });
    }


     handalTextArea = (e, accountIndex, rowIndex, rowData) => {
        var data = [...this.state.lst_GL];
        data[accountIndex].data[rowIndex][e.target.name] = e.target.value;
        this.setState({ lst_GL: data });
    }


    onSortChange = (fieldName) => {
        if (this.state.sortField === fieldName) {
            let formate = this.state.sortFormat === 'A' ? 'D' : 'A';
            this.setState({ sortFormat: formate, loading: true }, () => {
                this.GetReportData();
            });
        } else {
            this.setState({ sortField: fieldName, sortFormat: 'A', loading: true }, () => {
                this.GetReportData();
            });
        }
    }

    OnApplyIgnored = () => {
        let selectedData = this.state.SelectedRecords;
        if (selectedData == null || selectedData.length === 0) {
            toast.error("Please select atleast 1 record for processing request");
            return false;
        }

        let body = {
            "ids": selectedData,
            "reportType": ReportType.ReviewReconciliationDiscrepancies,
            "companyID": this.state.companyId
        }

        this.setState({ loading: true }, () => {
            if(this.state.IsIgnoredFilter)
            {
                this.props.Revert_Ignored_DATA(body);                
            }
            else
            {
                this.props.MARK_IGNORED_DATA(body);                
            }      
        });
    }

   
    onCheckboxClick = (e, item) => {
        e.stopPropagation();


        let existInArray = false;
        let data = this.state.SelectedRecords;
        if (data != null && data.length > 0 && data.indexOf(item.id) > -1) {
            existInArray = true;
        }

        if (e.target.checked) {
            if (!existInArray) {
                data.push(item.id);
            }
        }
        else {
            if (existInArray) {
                data.splice(data.indexOf(item.id), 1);
            }
        }

        let all = (data.length == this.state.mergelst_GL.length) ? true : false;
        this.setState({ SelectedRecords: data, SelectALL: all, editIndex: -1 });

    }

    SelectALL = (e) => {
        if (this.state.mergelst_GL.length > 0) {
            if (e.target.checked) {
                let all = [];
                let data = this.state.mergelst_GL;
                for (let i = 0; i < data.length; i++) {
                    all.push(data[i].id);
                }
                this.setState({ SelectALL: true, SelectedRecords: all });
            }
            else {
                this.setState({ SelectALL: false, SelectedRecords: [] });
            }
        }
    }

    HandleIgnored = (event) => {
        
        this.setState({...this.state, IsIgnoredFilter: (event.target.value=="true"?true:false),loading:true },()=>{ this.GetReportData()});
    }

    OnAssignNewAccount = () => {
        let selectedData = this.state.SelectedRecords;
        let selectedBatchAccountId = this.state.selectedBatchAccountId;
        if (selectedData == null || selectedData.length === 0) {
            toast.error("Please select atleast 1 record for processing request");
            return false;
        }

        if (selectedBatchAccountId.value == null || selectedBatchAccountId.value == 0) {
            toast.error("Please select account");
            return false;
        }

        let body = {
            // "ids": selectedData,
            // "reportType": ReportType.ReviewReconciliationDiscrepancies,
            // "companyID": this.state.companyId
            "txnId":selectedBatchAccountId.value,
            "glIds":selectedData
        }

        this.setState({ loading: true }, () => {
             this.props.UpdateBatchNewAccount(body);  
        });
    }

    handalBatchUpdateAccountDropdown = (e) => {

        if (e.value == null || e.value == 0) {            
            // var data = [...this.state.lst_GL];
            // data[accountIndex].data[rowIndex].newAccountID = null;
            // data[accountIndex].data[rowIndex].newAccountName = null;
            this.setState({ selectedBatchAccountId: e });
        }
        else {
            // let obj = this.state.accounts.find(x => x.accountID == e.value);
            // var data = [...this.state.lst_GL];
            // data[accountIndex].data[rowIndex].newAccountID = e.value;
            // data[accountIndex].data[rowIndex].newAccountName = obj.name;
            this.setState({ selectedBatchAccountId: e });
        }
    }
    render() {
        let selectedItem = this.state.SelectedRecords;
        return (
            <React.Fragment>
                {this.state.ShowHistory && this.state.HistoryData != null && this.state.HistoryData.length > 0 &&
          <React.Fragment>
            <Dialog
              className="comment-Top-align"
              maxWidth="sm"
              fullWidth={true}
              open={true}>
              <React.Fragment>
                <div className="Comments modal-header"><span className="log-history-title float-left">Reviewed History </span>
                  <Button className="jr-btn close-btn float-right" onClick={this.HandleClose}><ClearIcon /></Button>
                </div>
                <DialogContent>
                  {this.state.HistoryData.length == 0 ?
                    <div className="history-box">
                      <p className="history-item-text">No History Found</p>
                    </div>
                    :
                    <React.Fragment>
                      {this.state.HistoryData.map((item, i) => (
                        <div className="history-box">
                          {/* <p className="history-item-text">
                           
                          </p>
                           */}
                          <p className="history-date-text"> <strong style={{ color: "black" }}>  Last Reviewed  </strong> By <strong> {item.userName}</strong> <strong> on </strong>{item.stCreatedDate}</p>
                        </div>
                      ))}
                    </React.Fragment>
                  }
                </DialogContent>
              </React.Fragment>
            </Dialog>
          </React.Fragment>

        }
                {this.state.ShowComment ? <CommonComments
                    CloseComments={this.CloseComments}
                    ColorCode={this.state.ColorCode}
                    ModuleType={ModuleType.GeneralReportType}
                    ReportType={ReportType.ReviewReconciliationDiscrepancies}
                    CompanyId={this.state.companyId}
                    RecordId={this.state.CommentReferenceId}
                    IsEmbeddedPage={this.state.IsEmbeddedPage}
                    UpdateItemCommentCount={this.UpdateItemCommentCount}
                >
                </CommonComments> : ""}
                <div className="page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
                    <h2 className="mb-3 mb-sm-0">{JSON.parse(localStorage.getItem('userData')) != null ? JSON.parse(localStorage.getItem('userData')).companyName : ""} | Please Review Reconciliation Discrepancies</h2>
                </div>
                <div className="padding-box app-wrapper general-ledger-report">
                    {/* <div className="row">
                        <div className="col-md-3">
                             <div className="select-quater-box">
                                <p className="sub-title">Date Selection</p>
                                <div className="row">
                                    <div className="col-sm-4 col-md-12 ">
                                        <div className="datepicker-box">
                                            <div className="date-box">
                                                <DatePicker
                                                    selected={this.state.sDate}
                                                    onChange={(e) => this.handleDateChange(e, 'fromDate')}
                                                    name="fromDate"
                                                    id="date"
                                                    required={true}
                                                    margin="normal"
                                                    disabledKeyboardNavigation
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    yearDropdownItemNumber={5}
                                                    maxDate={this.state.eDate}
                                                    ref={this.sDateRef}
                                                />
                                                <div className="date-icon" onClick={(e) => this.handleFocus(e, 'sDate')}>
                                                    <i className="zmdi zmdi-calendar-note"></i>
                                                </div>
                                            </div>
                                            <div className="to-box">
                                                <span className="text-center ledgerto-text">To</span>
                                            </div>
                                            <div className="date-box">
                                                <DatePicker
                                                    selected={this.state.eDate}
                                                    onChange={(e) => this.handleDateChange(e, 'uptoDate')}
                                                    name="uptoDate"
                                                    id="date"
                                                    required={true}
                                                    margin="normal"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    yearDropdownItemNumber={5}
                                                    disabledKeyboardNavigation
                                                    minDate={this.state.sDate}
                                                    maxDate={new Date()}
                                                    ref={this.eDateRef}
                                                />
                                                <div className="date-icon" onClick={(e) => this.handleFocus(e, 'eDate')}>
                                                    <i className="zmdi zmdi-calendar-note"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="genera-apply-btn" style={{ marginTop: "5px" }}>
                                            <Button variant="raised" className="orangeColor jr-btn bg-teal text-white refresh-btn" onClick={(e) => this.ApplyDate()} >apply</Button>

                                        </div>

                                    </div>
                                </div>
                            </div> *
                        </div>
                        <div className="col-md-6">
                        </div>
                        <div className="col-md-3 pt-4">
                            <div className="button-right float-right">
                                <Button variant="raised" className="orangeColor jr-btn bg-teal text-white refresh-btn" onClick={() => this.onRefreshDataClick()}><i className="zmdi zmdi-refresh "></i>Refresh Data</Button>
                            </div>
                        </div>
                    </div> */}
                    <div className="row">
                        <div className="col-md-12" style={{padding:'0px'}}>
                            <div className="float-right">
                            <div className="col-md-12">
                            {/* <span> <b> Show Previously Ignored Transactions  </b> : </span>
                                    <select style={{ backgroundColor: "#fff", marginRight: "10px" }} className="select-box arrow-box" onChange={this.HandleIgnored} value={this.state.IsIgnoredFilter} >
                                        <option value={true}> YES </option>
                                        <option value={false}> NO </option>                                                    
                                    </select>
                                <Button variant="raised" className=" jr-btn graycolor text-white refresh-btn" onClick={() => this.OnApplyIgnored()} >{this.state.IsIgnoredFilter?"Revert":"Ignore"}</Button> */}
                                <Button variant="raised" className="graycolor jr-btn bg-teal text-white refresh-btn" onClick={() => this.onMarkReviewd()}>Mark as Reviewed</Button>
                                <Button variant="raised" className="orangeColor jr-btn bg-teal text-white refresh-btn" onClick={() => this.onRefreshDataClick()}><i className="zmdi zmdi-refresh "></i>Refresh Data</Button>
                                
                            </div>  
                            
                            </div>                                        
                        </div>
                    </div>
                    {/* <div class="row mt-2">
                    <div className="col-md-6 ml-auto">
                                <div class="justify-content-end d-flex align-items-center pr-3">
                                <div class="col-md-auto"> <span> <b> New Account  </b> : </span></div>
                                <div class="col-md-6">
                                
                                <RSelect
                                     required={true}
                                     name="BatchNewAccountID"
                                     value={this.state.selectedBatchAccountId}
                                     onChange={(e) => this.handalBatchUpdateAccountDropdown(e)}
                                     options={this.state.DisplayAccounts}
                                     //defaultValue={{ value: 0, label: "select" }}
                                     placeholder="Select"
                                 />
                                </div>
                                 <div>
                                 <Button variant="raised" className=" jr-btn graycolor text-white refresh-btn" onClick={() => this.OnAssignNewAccount()} >Save</Button>
                                 </div>
                                </div>
                            </div>  
                    </div> */}
                    <div class="row mt-2">
                                    <div className="col-md-12 ">
                                        <div className='d-flex justify-content-end align-items-center'>
                                            <div className='show-previously-box'>
                                            <div class="form-group d-flex mb-0">                                            
                                            <h4 for="staticEmail" class=" col-form-label">Show Previously Ignored Transactions:</h4>
                                            <div class="dropdown-input-box">
                                            <select style={{ backgroundColor: "#fff", marginRight: "10px" }} className="select-box arrow-box" onChange={this.HandleIgnored} value={this.state.IsIgnoredFilter} >
                                        <option value={true}> YES </option>
                                        <option value={false}> NO </option>                                                    
                                    </select>
                                            </div>
                                            </div>
                                            </div>
                                            <div className='assign-vendor-box'>
                                            <div class="form-group d-flex mb-0"> 
                                            <h4 for="staticEmail" class=" col-form-label">New Account:</h4>
                                            <div className='rselect-box'>
                                            <RSelect
                                     required={true}
                                     name="BatchNewAccountID"
                                     value={this.state.selectedBatchAccountId}
                                     onChange={(e) => this.handalBatchUpdateAccountDropdown(e)}
                                     options={this.state.DisplayAccounts}
                                     //defaultValue={{ value: 0, label: "select" }}
                                     placeholder="Select"
                                 />
                                            </div>
                                            </div>
                                            </div>
                                            <div className=''>
                                            <Button variant="raised" className=" jr-btn graycolor text-white refresh-btn" onClick={() => this.OnAssignNewAccount()} >Save</Button>
                                            <Button variant="raised" className=" jr-btn graycolor text-white refresh-btn" onClick={() => this.OnApplyIgnored()} >{this.state.IsIgnoredFilter?"Revert":"Ignore"}</Button>
                                            </div>
                                            </div>
                                    </div>  
                                </div>
                    {this.state.loading ? <CircularProgress /> :
                        <div className="table-responsive-material">
                            {/* <TableScrollbar rows={10}> */}
                            <table className={`table tablebox account-reconcilliation`}>
                                <thead>
                                    <tr style={{ backgroundColor: this.state.ColorCode }}>
                                        <th className={`w-10P ${this.state.sortField == 'date' ? this.state.sortFormat == 'A'
                                            ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('date')}>
                                            <p className="table-th-title">Date</p>
                                        </th>
                                        <th className={`w-15P ${this.state.sortField == 'transactionType' ?
                                            this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('transactionType')}>
                                            <p className="table-th-title"> Type</p>
                                        </th>
                                        <th className={`w-10P ${this.state.sortField == 'num' ?
                                            this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('num')}>
                                            <p className="table-th-title">Num</p>
                                        </th>
                                        <th className={`w-10P ${this.state.sortField == 'name' ?
                                            this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('name')}>
                                            <p className="table-th-title">Name</p>
                                        </th>
                                        <th className={`w-25P  ${this.state.sortField == 'memoOrDescription' ?
                                            this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('memoOrDescription')}>
                                            <p className="table-th-title">Memo/Description</p>

                                        </th>
                                        <th className={`w-10P text-center  ${this.state.sortField == 'amount' ?
                                            this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('amount')}>
                                            <p className="table-th-title"> Amount </p>

                                        </th>
                                        <th className={`w-10P ${this.state.sortField === 'newAccountName' ?
                                            this.state.sortFormat === 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('newAccountName')}>
                                            <p className="table-th-title">  New Account</p>

                                        </th>
                                        <th className="td-width-90 text-center">
                                            <label className="container101 header-check ">
                                                {this.state.SelectALL ?
                                                    <input className="second-check-box" checked="checked" type="checkbox" onClick={(e) => this.SelectALL(e)} />
                                                    :
                                                    <input className="second-check-box" type="checkbox" onClick={(e) => this.SelectALL(e)} />
                                                }
                                                <span className="checkmark101 white_border"></span>
                                            </label>
                                        </th>
                                        <th className="w-5P">
                                            <div class="panel-commentCount col-md-2">
                                                <img src="/vendors/WhiteCommnet.png" alt=""></img>
                                            </div>
                                        </th>
                                        <th className="w-5P text-center "><img src="/vendors/Link-arrow_header.png" alt="link" /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <React.Fragment>
                                        {this.state.lst_GL.map((account, i) => (
                                            <React.Fragment key={i}>
                                                <tr className={account.isExpand ? "bg-active" : ""} onClick={() => this.onRowExpandCollapseClick(i, account)}>
                                                    <td className="title-name" colSpan="10">
                                                        {
                                                            account.isExpand ? <i className="zmdi zmdi-minus-circle-outline"></i> : <i className="zmdi zmdi-plus-circle-o"></i>
                                                        }
                                                        <span className="account-text">{account.account}</span>
                                                    </td>
                                                </tr>
                                                {account.isExpand && account.isBeginningAmount &&
                                                    <tr>
                                                        <td colSpan="5" className="table-sub-title">Beginning Balance</td>
                                                        <td className="text-center">{numberWithCommas(account.beginningAmount)}</td>
                                                        <td colSpan="3"></td>
                                                    </tr>}
                                                {account.isExpand && <React.Fragment>
                                                    {(account.data && account.data.length > 0) && account.data.map((item, j) => (
                                                        <React.Fragment key={j}>                                                            
                                                            <tr className={item.vendor1099 ? 'greenrow' : ''} onClick={(e) => this.onRowClick(e, i, j, item)} >
                                                                
                                                                    <td>
                                                                        
                                                                      {/* {
                                                                        item.memoOrDescription.toLowerCase().includes("reconcile") ?
                                                                        <Badge badgeContent={"R"} color="primary" style={{marginBottom : "5px"}}>
                                                                        </Badge> 
                                                                        :""
                                                                       }
                                                                       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                                                                        {moment(item.date).format("MM/DD/YYYY")}
                                                                    </td>
                                                                    <td>{item.transactionType}</td>
                                                                    <td>
                                                                    {item.num}
                                                                        
                                                                    </td>
                                                                    <td>{item.name}</td>
                                                                    <td className="textOverFlow">{item.memoOrDescription}</td>
                                                                    <td className="text-center">{numberWithCommas(item.amount)}</td>
                                                                    <td>{item.newAccountName}</td>
                                                                    <td className="text-center">
                                                                        <label className="container101">
                                                                            {(this.state.SelectALL || selectedItem.length > 0 && (selectedItem.indexOf(item.id) > -1)) ?
                                                                                <input className="second-check-box" checked="checked" type="checkbox" onClick={(e) => this.onCheckboxClick(e, item)} />
                                                                                :
                                                                                <input className="second-check-box" type="checkbox" onClick={(e) => this.onCheckboxClick(e, item)} />
                                                                            }
                                                                            <span className="checkmark101"></span>
                                                                        </label>
                                                                    </td>
                                                                    <td>
                                                                        <div className="panel-commentCount col-md-2" >
                                                                            <img src="/vendors/Vector Smart Object.png" alt="" className={item.commentCount === 0 ? "disableCommentsOpacity" : ""} onClick={(e) => this.LoadComments(item)}></img>
                                                                            {item.unreviewCommentCount !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">{item.unreviewCommentCount}</span>}
                                                                        </div>
                                                                    </td>
                                                                    <td className="text-center"><img src="/vendors/Shape 1.png" alt="QBDlink" onClick={(e) => this.onLinkClick(e, i, j, item)}></img></td>
                                                               
                                                            </tr>                                                                                                                        
                                                            {this.state.editIndex1 === i && this.state.editIndex === j &&
                                                                <React.Fragment>
                                                                    <tr className="editRowColor">
                                                                        <td colSpan="2">
                                                                            <label>New Account</label>
                                                                            <RSelect
                                                                                required={true}
                                                                                name="NewAccountID"
                                                                                value={this.state.selectedAccount}
                                                                                onChange={(e) => this.handalAccountDropdown(e, i, j, item)}
                                                                                options={this.state.DisplayAccounts}
                                                                                //defaultValue={{ value: 0, label: "select" }}
                                                                                placeholder="Select"
                                                                            />
                                                                        </td>
                                                                        <td colSpan="4">
                                                                                    <label>Memo/Description</label>
                                                                                    <Textarea className="placheholder-color"
                                                                                                        value={item.memoOrDescription} name="memoOrDescription"
                                                                                                        style={{ 'lineHeight': '20px', width: '100%', border: 'none', resize: 'none', padding: '6px', paddingLeft: '10px', borderRadius: '3px', border: '1px solid #ccc', overflow: 'hidden' }}
                                                                                                        resize={"false"} placeholder=""
                                                                                                        onChange={(e) => this.handalTextArea(e, i, j, item)} />
                                                                        </td>
                                                                        <td >                                                                        
                                                                                <div className="chiller_cb " style={{marginTop:"20px"}}>
                                                                                    <input id="needsBetterDescription1" name="needsBetterDescription" type="checkbox" checked={item.needsBetterDescription} onChange={(e) => this.handalCheckbox(e, i, j, item)} />
                                                                                    <label for="needsBetterDescription1">
                                                                                    <p className="checkbox-title">NBD {item.needsBetterDescription}</p>
                                                                                    </label>
                                                                                    <span></span>
                                                                                </div>                                                                            
                                                                        </td>
                                                                        <td >                                                                        
                                                                                <div className="chiller_cb " style={{marginTop:"20px"}}>
                                                                                    <input id="cpaReview1" name="cpaReview" type="checkbox" checked={item.cpaReview } onChange={(e) => this.handalCheckbox(e, i, j, item)} />
                                                                                    <label for="cpaReview1">
                                                                                        <p className="checkbox-title">CPA {item.cpaReview}</p>
                                                                                    </label>
                                                                                    <span></span>
                                                                                </div>                                                                            
                                                                        </td>  
                                                                        <td colSpan="5">&nbsp;</td>                                                                      
                                                                    </tr>
                                                                    <tr className="editRowColor">
                                                                        <td colSpan="10">
                                                                            <div className="row">
                                                                                <div className="col-md-5">
                                                                                    <div className="row">
                                                                                        <div className="col-md-3">
                                                                                            <label className="input-title">Questions and Comments:</label>
                                                                                        </div>
                                                                                        <div className="col-md-9">
                                                                                            <Textarea className="placheholder-color"
                                                                                                value={item.accountantComment} name="accountantComment"
                                                                                                style={{ 'lineHeight': '20px', width: '100%', border: 'none', resize: 'none', padding: '6px', paddingLeft: '10px', borderRadius: '3px', border: '1px solid #ccc', overflow: 'hidden' }}
                                                                                                resize={"false"} placeholder=""
                                                                                                onChange={(e) => this.handalTextArea(e, i, j, item)} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-5">
                                                                                    <div className="row">
                                                                                        <div className="col-md-3">
                                                                                            <label className="input-title">Internal Notes:</label>
                                                                                        </div>
                                                                                        <div className="col-md-9">
                                                                                            <Textarea className="placheholder-color"
                                                                                                value={item.internalNotes} name="internalNotes"
                                                                                                style={{ 'lineHeight': '20px', width: '100%', border: 'none', resize: 'none', padding: '6px', paddingLeft: '10px', borderRadius: '3px', border: '1px solid #ccc', overflow: 'hidden' }}
                                                                                                resize={"false"} placeholder=""
                                                                                                onChange={(e) => this.handalTextArea(e, i, j, item)} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-2">
                                                                                    <Button variant="raised" className="jr-btn jr-btn-sm bg-teal text-white" onClick={() => this.onRowUpdateClick(i, j, item)}>SAVE</Button>
                                                                                    <Button variant="raised" className="jr-btn jr-btn-sm bg-danger text-white" onClick={() => this.onRowCancelClick(i, j, item)}>CANCEL</Button>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </React.Fragment>
                                                            }
                                                        </React.Fragment>
                                                    ))}
                                                    {(!account.data || account.data.length === 0) &&
                                                        <tr>
                                                            <td className="text-center" colSpan="10">
                                                            No issues here! Keep up the good work!
                                                   </td>
                                                        </tr>
                                                    }
                                                </React.Fragment>}
                                                {account.isExpand &&
                                                    <tr>
                                                        <td colSpan="5" className="table-sub-title">Total for {account.account}</td>
                                                        <td className="text-center ">$
                                                       {/* total-amount-text */}
                                                            {account.totalAmount != null ?
                                                                <React.Fragment>
                                                                    {numberWithCommas(account.totalAmount)}
                                                                </React.Fragment>
                                                                : ""
                                                            }
                                                        </td>
                                                        <td colSpan="4"></td>
                                                    </tr>}
                                            </React.Fragment>
                                        ))}
                                        {this.state.lst_GL.length == 0 &&
                                            <tr>
                                                <td className="text-center" colSpan="10">
                                                No issues here! Keep up the good work!
                                       </td>
                                            </tr>
                                        }
                                    </React.Fragment>
                                    
                                </tbody>
                            </table>
                        </div>
                       
                    }
                </div>
            </React.Fragment>
            // <React.Fragment>
                
            // </React.Fragment>
        );
    }

}


const mapStateToProps = (state) => {
    return {
        ledgers: state.ledgers,
        accounts: state.accounts.accountsData,
        CommonUpdate: state.ledgers.CommonUpdate,
        LEDGER_UPDATE:state.ledgers.LEDGER_UPDATE,
        UpdateDescription: state.ReportData.UpdateDescription,
        ReportReviewdHistory:state.ledgers.ReportReviewedHistory,
        BATCH_LEDGER_UPDATE : state.ledgers.BATCH_LEDGER_UPDATE
    };
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        ReviewReconciliationDiscrepanciesData,
        Accounts,
        LedgerUpdate,
        MARK_IGNORED_DATA,
        MarkReportReview,
        Revert_Ignored_DATA,
        GetReportReviewedHistory,
        UpdateBatchNewAccount
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(ReviewReconciliationDiscrepancies);
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import CommonComments from "./../../../app/common/Comments";
import PaginationComponent from "./../../../../src/app/common/Pagination";
import { ReportType, ModuleType, makeLinkForQuickbooks, Base64, numberWithCommas,LinkType } from "./../../../app/common/commonServices";
import moment from 'moment';
import { bindActionCreators } from 'redux';
import CircularProgress from './../../../components/CircularProgress';
import { GET_REPORT_DATA_BES } from "./../../../appRedux/actions/ReportDetailAction";
import { toast } from 'react-toastify';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from 'react-router-dom';
import VimeoInstructionLoader from './../../../app/common/VimeoInstructionLoader'
import queryString from 'query-string';
import RefreshDataModal from '../../../components/RefreshDataModal'

class AccountLineItem_Embedded extends Component {


    constructor(props) {
        super(props)
        this.state = {
            parantData: this.props.location.state ? this.props.location.state : null,
            MissingData: [],
            companyId: 0,
            sortField: 'date',
            sortFormat: "D",
            PageNo: 1,
            PageSize: 10,
            ColorCode: "#46b29d",
            IsCommentModelOpen: false,
            sDate: new Date(2019, 0, 1),
            eDate: new Date(),
            loading: false,
            TotalRecords: 0,
            CommentReferenceId: 0,
            IsEmbeddedPage: true,
            HomeLink: "javascript://",
            companyName: "",
            isRefreshDataModalOpen:false
        }
    }

    onSortChange = (fieldName) => {
        if (this.state.sortField == fieldName) {
            let formate = this.state.sortFormat == 'A' ? 'D' : 'A';
            this.setState({ sortFormat: formate, loading: true }, () => {
                this.GetReportData();
            });
        } else {
            this.setState({ sortField: fieldName, sortFormat: 'A', loading: true }, () => {
                this.GetReportData();
            });
        }
    }

    componentDidMount() {
        let value = queryString.parse(this.props.location.search);
        //localStorage.setItem("homeURL", window.location.href.replace(window.location.origin, ""))
        let dashboardURl = localStorage.getItem("homeURL");
        this.setState({
            loading: true,
            HomeLink: dashboardURl ? dashboardURl : "?q="+ value.q + "&p=" + value.p
        }, () => {
            this.getCompanyId();
        });
    }

    getCompanyId = () => {
        let value = queryString.parse(this.props.location.search);
        localStorage.setItem("q", JSON.stringify(value));
        let data = localStorage.getItem("q");
        if (data != null && data != undefined) {
            data = JSON.parse(data);
            this.setState({
                companyId: parseInt(Base64.decode(data.q)),
                companyName:Base64.decode(data.p),
                loading: true
            }, () => {
                this.GetReportData();
            });
        }
        else {
            this.setState({ loading: false });
        }
    }


    ChangePageSize = (size) => {

        this.setState({ loading: true, PageSize: parseInt(size), PageNo: 1 }, () => {
            this.GetReportData();
        });
    }

    ChangePage = (page) => {
        this.setState({ loading: true, PageNo: page, }, () => {
            this.GetReportData();
        });
    }

    LoadComments = (item) => {
        this.setState({ IsCommentModelOpen: true, CommentReferenceId: item.id });
    }

    CloseComments = () => {
        this.setState({ IsCommentModelOpen: false });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.ReportData !== this.props.ReportData) {
            if (nextProps.ReportData.transactionStatus === 0) {
                var totalrecords = 0;
                if (nextProps.ReportData.returnObject != null && nextProps.ReportData.returnObject.length > 0) {
                    totalrecords = nextProps.ReportData.returnObject[0].totalrows
                }
                this.setState({ MissingData: nextProps.ReportData.returnObject, TotalRecords: totalrecords, PageSize: this.state.PageSize, PageNo: this.state.PageNo, loading: false });
            }
            else {
                toast.error(nextProps.ReportData.resultMsg);
                this.setState({ loading: false });
            }
        }
    }

    refreshData = () => {
        if (!this.state.loading) {
            this.setState({ loading: true,isRefreshDataModalOpen:false }, () => {
                this.GetReportData(true);
            });
        }
    }

    onRefreshDataClick = () => {
        
        this.setState({isRefreshDataModalOpen:true})
    }

    handleRefreshDataModal=()=>{
        this.setState({isRefreshDataModalOpen:false})
      }
    
      

    GetReportData(IsRefresh = false) {
        let state = this.state;
        let body = {
            companyID: state.companyId,
            reportType: ReportType.AccountantsQueAndComments,
            fromDate: moment(state.sDate).format("MM/DD/YYYY"),
            toDate: moment(state.eDate).format("MM/DD/YYYY"),
            sortField: state.sortField,
            sortFormat: state.sortFormat,
            pageNumber: state.PageNo,
            pageSize: state.PageSize,
            needUpdatQBORecords: IsRefresh
        };

        this.props.GET_REPORT_DATA_BES(body);
    }

    onLinkClick = (rowData) => {
        let origin = makeLinkForQuickbooks();
        window.open(origin + rowData.transactionLink);
    }

    UpdateItemCommentCount = (id) => {
        let data = this.state.MissingData;
        data.forEach(function (item) {

            if (item.id === id) {
                item.commentCount++
            }
        });

        this.setState({ MissingData: data });
    }

    render() {
        return (
            <React.Fragment>

            <RefreshDataModal visible={this.state.isRefreshDataModalOpen}
                 handlePopUpClose={this.handleRefreshDataModal} refreshData={this.refreshData}/>
                {this.state.IsCommentModelOpen ?
                    <CommonComments
                        CloseComments={this.CloseComments}
                        ColorCode={this.state.ColorCode}
                        ModuleType={ModuleType.GeneralReportType}
                        ReportType={ReportType.AccountantsQueAndComments}
                        CompanyId={this.state.companyId}
                        RecordId={this.state.CommentReferenceId}
                        IsEmbeddedPage={this.state.IsEmbeddedPage}
                        UpdateItemCommentCount={this.UpdateItemCommentCount}
                    >
                    </CommonComments> : ""}                
                    <div className="dashboardPage embeded-dashboard">
                        <ToastContainer autoClose={3000} />
                        {this.state.loading ? <CircularProgress></CircularProgress>
                            :
                            <React.Fragment>

                                <div className=" page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
                                    <h2 className="mb-3 mb-sm-0">{this.state.companyName} |  {LinkType.ACCLineItem}</h2>
                                    <Link className="home-link" to={this.state.HomeLink} > Home </Link>
                                </div>
                                <div className="padding-box app-wrapper bgcolor">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="float-right">
                                                <Button variant="raised" className="greencolor jr-btn bg-teal text-white refresh-btn" onClick={() => this.onRefreshDataClick()} >
                                                    <i className="zmdi zmdi-refresh "></i> Refresh Data</Button>
                                                <VimeoInstructionLoader 
                                                   //URL={"https://vimeo.com/687417543"} 
                                                   URL={"https://player.vimeo.com/video/687417543?h=df22da040e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"}
                                                   buttonName="Instructions"
                                                   buttonColor="greencolor"
                                                   PageName={LinkType.ACCLineItem}
                                                ></VimeoInstructionLoader>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive-material">
                                        <table className="table tablebox account-reconcilliation">
                                            <thead>
                                                <tr className="greencolor">
                                                    <th className={`td-width ${this.state.sortField == 'date' ? this.state.sortFormat == 'A'
                                                        ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('date')}><p className="table-th-title"> Date</p> </th>
                                                    <th className={`td-width ${this.state.sortField == 'transactionType' ? this.state.sortFormat == 'A'
                                                        ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('transactionType')}><p className="table-th-title"> Type</p></th>
                                                    <th className={`td-width ${this.state.sortField == 'num' ? this.state.sortFormat == 'A'
                                                        ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('num')}><p className="table-th-title"> Number</p></th>
                                                    <th className={`td-width ${this.state.sortField == 'name' ? this.state.sortFormat === 'A'
                                                        ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('name')}><p className="table-th-title"> Name</p> </th>
                                                    <th className={`td-width-box ${this.state.sortField == 'account' ? this.state.sortFormat == 'A'
                                                        ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('account')}><p className="table-th-title"> A/C No.</p></th>
                                                    <th className={` ${this.state.sortField == 'memoOrDescription' ? this.state.sortFormat == 'A'
                                                        ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('memoOrDescription')}><p className="table-th-title"> Memo/Desc.</p></th>
                                                    <th className={`td-width  ${this.state.sortField == 'amount' ? this.state.sortFormat == 'A'
                                                        ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('amount')}><p className="table-th-title"> Amount</p></th>
                                                    <th className={`td-width  ${this.state.sortField == 'accountantComment' ? this.state.sortFormat == 'A'
                                                        ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('accountantComment')}><p className="table-th-title"> Questions and Comments</p></th>
                                                    <th className="td-width-90 text-center"><img src="/vendors/WhiteCommnet.png" alt="comments" /></th>
                                                    <th className="td-width-90 text-center "><img src="/vendors/Link-arrow_header.png" alt="link" /></th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(this.state.MissingData && this.state.MissingData.length > 0) && this.state.MissingData.map((item, j) => (
                                                    <React.Fragment key={j}>
                                                        {<tr>
                                                            <td>{item.date}</td>
                                                            
                                                            <td>{item.transactionType}</td>
                                                            <td>{item.num}</td>
                                                            <td>{item.name} </td>
                                                            <td>{item.account}</td>
                                                            <td>{item.memoOrDescription}</td>
                                                            <td className="text-right">{numberWithCommas(item.amount)}</td>
                                                            <td>{item.accountantComment}</td>
                                                            <td className="text-center" onClick={(e) => e.stopPropagation()}>
                                                                <div className="panel-commentCount">
                                                                    <img src="/vendors/Vector Smart Object.png" alt="comments" className={item.commentCount === 0 ? "disableCommentsOpacity" : ""} onClick={() => this.LoadComments(item)}></img>
                                                                    {item.unreviewCommentCount !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">{item.unreviewCommentCount}</span>}
                                                                </div>
                                                            </td>
                                                            <td className="text-center" onClick={(e) => e.stopPropagation()}><img src="/vendors/grrenLink.png" alt="link" onClick={() => this.onLinkClick(item)}></img></td>
                                                        </tr>}
                                                        {this.state.MissingData.length == 0 ?
                                                            <tr>
                                                                <td className="text-center" colSpan="9">
                                                                No issues here! Keep up the good work!
                                                                </td>
                                                            </tr>
                                                            : ""}
                                                    </React.Fragment>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <PaginationComponent
                                        selectedPageNo={this.state.PageNo}
                                        selectedSize={this.state.PageSize}
                                        TotalRecords={this.state.TotalRecords}
                                        ChangePageSize={this.ChangePageSize}
                                        ChangePage={this.ChangePage}
                                        CurrentRecords={this.state.MissingData.length}
                                    >
                                    </PaginationComponent>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                
            </React.Fragment>
        );
    }

}


const mapStateToProps = (state) => {
    return {
        ReportData: state.ReportData.ReportData
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        GET_REPORT_DATA_BES
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountLineItem_Embedded);
import baseService from './baseService';

export function getInsuranceList(companyId){
    return baseService.get(`/Insurance/${companyId}/all`);
}
export function getInsurances(CompanyID,search,pageNumber,pageSize,sortField,sortFormat){
    return baseService.post(`/Insurance/all_page`,{ CompanyID,search,pageNumber,pageSize,sortField,sortFormat });
}
export function addInsurance(data){
    return baseService.post(`/Insurance`, data);
}

export function updateInsurance(data){
    return baseService.put(`/Insurance`, data);
}

export function deleteInsurance(value){
    return baseService.delete(`/Insurance`, { params:{ policyId:value } });
}

export function getPolicyTypes(){
    return baseService.get(`/Insurance/policytypes`);
}

export function searchInsurance(companyId,text)
{
    return baseService.get(`Insurance/${companyId}/search/${text}`);
}

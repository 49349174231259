
import baseService from './baseService';

export function companyDetail() {
    return baseService.get('Company/all');
}

export function companyStatus(data) {
    return baseService.post('Company/CompanyStatus', data);
}

export function getUsers() {
    return baseService.get('User/all');
}

export function getUsersAll(CompanyID, search, pageNumber, pageSize, sortField, sortFormat) {
    return baseService.post('User/all_page', { CompanyID, search, pageNumber, pageSize, sortField, sortFormat });
}

export function userStatus(data) {
    return baseService.post('User/UserStatus', data);
}

export function getStates() {
    return baseService.get('/Company/states');
}

export function getUsersByCompany(id) {
    return baseService.get('User/bycompany', { params: { companyId: id } });
}

export function getCompnay(id) {
    return baseService.get(`Company/${id}`);
}

export function addUser(data) {
    if (data.userId && data.userId != "")
        return baseService.put('User', data);
    else
        return baseService.post('User/register', data);
}
export function deleteCompany(data) {
    return baseService.delete(`Company/${data.id}/${data.reason}`);
}
export function deleteUser(value) {
    return baseService.delete('User/' + value.userId, { data: { "reason": value.Reason } });
}

export function assignUserCompanies(data, UserId) {
    return baseService.post('/User/assigncompanies', data, { params: { UserId } });
}

export function insertCompanies(model) {
    return baseService.post('Company', model);
}
export function updateCompanies(model) {    
    return baseService.put('Company', model);
}

export function updateUser(model) {
    return baseService.put('User', model);
}

export function searchCompany(text) {
    return baseService.get(`Company/${text}/search`);
}

export function searchUser(text) {
    return baseService.get(`User/${text}/search`);
}

export function accountingsoftware(text) {
    return baseService.get(`/Company/accountingsoftware`);
}

export function payrollsoftware(text) {
    return baseService.get(`/Company/payrollsoftware`);
}

export function post_TaxableWagesReport(data) {
    return baseService.post('/Company/TaxableWagesReport', data);
}

export function getCompanyTaxableWagesAccountsAsync(companyId) {
    return baseService.get(`Company/GetCompanyTaxableWagesAccountsAsync?companyId=` + companyId);
}

export function updateCompanyTaxableWagesAccountsAsync(data) {
    return baseService.put(`Company/UpdateCompanyTaxableWagesAccountsAsync`, data);
}
export function refreshToken(id) {
    return baseService.get(`/callback/RefreshToken?companyId=` + id);
}

export function InsertTaxableWagesQBOValues(data) {
    return baseService.post("/Company/InsertTaxableWagesQBOValues",data);
} 

export function ArchivedTaxableWagesReport(data) {
    return baseService.post("/Company/ArchiveTaxableWagesReport",data);
}
export function printReports(model) {
   
    return baseService.post('/AccountantDashboard/PrintPayrollReports', model);
}
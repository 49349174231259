import { GET_DASHBOARD_DETAILS, RELOAD,COMMONUPDATE,REVIEWHISTORY } from '../actions/CompanyDashboardAction';

const initial_state = {
    companyDashboardData: null,
    CommonUpdate:null,
    HistoryData:null
};

export default function authReducer(state = initial_state, action) {
    switch (action.type) {
        case GET_DASHBOARD_DETAILS:
            return { ...state, companyDashboardData: action.payload.data };
        case RELOAD:
            return { ...state, reload: true };
        case COMMONUPDATE:
            return { ...state,CommonUpdate:action.payload.data};
        case REVIEWHISTORY:
            return { ...state,HistoryData:action.payload.data};
        default:
            return state;
    }
}
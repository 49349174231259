import cookies from "../cookies";

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const RESET_FAILED = 'RESET_FAILED';

const initial_state = {
	initURL: '',
    loading:false,
    error:'',
    authUser: localStorage.getItem('userData'),
};

let url;

if(localStorage.getItem('userData')){
    const user=JSON.parse(localStorage.getItem('userData'));
    if(user.role === 'SuperAdmin' || user.role == 'Admin' || user.role == 'Accountant') {
        url = '/home';
    } else {
        url = '/dashboard';
    }
}

export default function authReducer(state = initial_state, action) {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
            	...state,
            	authUser:action.payload,
                loading:false,
            	initURL: url,
            };
        case LOGIN_REQUEST:
           return {
                ...state,
                loading:true
            };
        case RESET_SUCCESS:
          return {
                ...state,
                authUser:action.payload,
                loading:false,
                initURL: url,
            };
        case RESET_FAILED:
           return {
                ...state,
                loading:false,
                error:action.payload
            };
        case LOGIN_FAILED:
            return {...state,
                 loading:false,
                 error:action.payload };
        default:
            return state;
    }
}
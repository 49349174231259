import React from "react";
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from '@material-ui/core/TextField';
import Select from "react-select";
import ReactTable from "react-table";
import Checkbox from '@material-ui/core/Checkbox';
import Addloan from '../../../../Forms/Addloan';
import Editloan from '../../../../Forms/EditLoanForm';
import { bindActionCreators } from "redux";
import { getLoans , searchLoans, deleteLoan, propertyTypes} from '../../../../appRedux/actions/loansAction';
import { vendorTypes,Vendors } from '../../../../appRedux/actions/vendorAction';
import { connect } from 'react-redux';
import SearchBox from '../../../../components/SearchBox'
import _ from 'lodash';
import  jwt_decode from 'jwt-decode';
import moment from 'moment';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Table, Pagination  } from 'antd';
import { Resizable } from 'react-resizable';
import "../../styles.css";
import CircularProgress from "../../../../components/CircularProgress";
const ResizeableTitle = props => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable width={width} height={0} onResize={onResize}>
      <th {...restProps} />
    </Resizable>
  );
};
class LoansPage extends React.Component {
  constructor(props) {
    super(props);
    let column = [{
        title: 'Vendor Name',
        dataIndex: 'vendorName',
        className:'table-heading',
        sorter:  true,
        width:150
    },{
        title: 'Financing company',
        dataIndex: 'financeCompany',
        className:'table-heading',
        sorter:  true,
        width:140
    },
    {
        title: 'Property Type',
        dataIndex: 'propertyType',
        className:'table-heading',
        sorter:  true,
        width:100
    },
    {
        title: 'Terms (Months)',
        dataIndex: 'terms',
        className:'table-heading',
        sorter:  true,
        width:95
    },
    {
        title: 'Interest Rate',
        dataIndex: 'interestRate',
        className:'table-heading',
        render: text => <span title={text}>
          {text}%
        </span>,
        sorter:  true,
        sorter:  true,
        width:90
    },
    {
        title: 'Original Loan Amount',
        dataIndex: 'originalLoanAmount',
        className:'table-heading',
        render: text => <span>
          $ {parseFloat(text).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
        </span>,
        sorter:  true,
        sorter:  true,
        width:95
    },{
        title: 'Loan Date',
        dataIndex: 'loanDate',
        className:'table-heading',
        sorter:  true,
        width:90,
        render: text => <span title={moment(text).format("LL")}>
          {moment(text).format("L") }
        </span>,
    },{
        title: 'Last Payment Due Date',
        dataIndex: 'lastPaymentDate',
        className:'table-heading',
        sorter:  true,
        width:95,
        render: text => <span title={moment(text).format("LL")}>
        {moment(text).format("L") }
        </span>,
    },
    {
        title: 'Property Details',
        dataIndex: 'propertyDetails',
        className:'table-heading',
        sorter:  true,
        width:150
    },{
        title: 'Comments',
        dataIndex: 'comments',
        className:'table-heading',
        sorter:  true,
        width:150
    },
    {
        title: 'Alert Date',
        dataIndex: 'alertDate',
        className:'table-heading',
        render: text => <span title={moment(text).format("LL")}>
          {moment(text).format("L") }
        </span>,
        sorter:  true,
        width:95
    },{
        title: 'Alert Comments',
        dataIndex: 'alertComments',
        className:'table-heading',
        sorter:  true,
        width:150
    },
    {
        title: 'Loan Review Complete',
        dataIndex: 'loanReviewComplete',
        className:'table-heading',
        sorter:  true,
        width:90,
        render: text => <span title={moment(text).format("LL")}>
          {text ? "Yes" : "No"}
        </span>,
    },
    {
        title: 'Loan Review Date',
        dataIndex: 'loanReviewDate',
        className:'table-heading',
        render: text => <span title={moment(text).format("LL")}>
          {moment(text).format("L") }
        </span>,
        sorter:  true,
        width:90
    }


    ];

    this.state = {
      pageSize:50,
			currentPage:1,
			totalRowsInTable:0,
			fieldName:'loanID',
			sortFormat:'A',
      searchText:"",
      columns: column,
      isAdd: false,
      edit:false,
      loans:[],
      properties:[],
      companyId:'',
      vendorType:[],
	  vendors:[],
      selectedLoan:'',
      isUservisibile: false,
    }
  };


 componentDidMount() {

    this.listData();
    this.props.propertyTypes();
    this.props.vendorTypes();
  	let user = JSON.parse(localStorage.getItem('userData'));
  	this.props.Vendors(user.companyId);
  }
  listData()
  {
    let user = JSON.parse(localStorage.getItem('userData'));
    this.setState({companyId:user.companyId});
    this.props.getLoans(user.companyId,this.state.searchText,this.state.currentPage,this.state.pageSize,this.state.fieldName,this.state.sortFormat);
  }

   componentWillReceiveProps(nextProps){

      if(nextProps.properties!==undefined && nextProps.properties !== this.props.properties){
          this.setState({
           properties:nextProps.properties,
         });
      }
      if(nextProps.vendorType!==undefined && nextProps.vendorType !== this.props.vendorType  ){
         this.setState({
           vendorType:nextProps.vendorType
         });
      }
	  if(nextProps.vendors!==undefined && nextProps.vendors !== this.props.vendors  ){
         this.setState({
           vendors:nextProps.vendors
         });
      }
       if(nextProps.loans!==undefined && nextProps.loans !== this.props.loans ){
         if(nextProps.loans.length>0)
            this.state.totalRowsInTable = nextProps.loans[0].totalrows;
         this.setState({
         loading:false,
         loans:nextProps.loans,
         properties:nextProps.properties,
         vendorType:nextProps.vendorType
       });
     }
     if(nextProps.reload==true)
       this.listData();

    }

  handleAddSave = (state) => {
    this.setState({
      isAdd: state,
    });
  }



   handleEditModal= (record) =>{
        var id=record.key;
        var data=this.state.loans;
        if(id!=0)
         {
            const selectedLoan = _.find(data, {loanID:id});
            this.setState({
                selectedLoan:_.cloneDeep(selectedLoan),
				        isAdd:false,
                isEdit:true,
            });
        }

    }

  handleAddModal = () => {

    this.setState({
		selectedItem:false,
      isAdd: true,
      isEdit:false,
    });
  }


handleDelete = (id) => {
    this.props.deleteLoan(id);
    this.setState({
      isEdit:false,
	  isAdd:false
    });
}

handleChange = (text) => {
  var self=this;
  if(text) {

    self.state.searchText = text;


    } else {
      self.state.searchText = '';

    }
    self.state.currentPage =1;
    this.setState({search:true});

  clearTimeout(self.state.timeout);

  // Make a new timeout set to go off in 800ms
  self.state.timeout = setTimeout(function () {
    self.listData();

  }, 500);

  }



    handleAddCancel = () => {
    this.setState({ isAdd: false,isEdit:false});
  }
  handleTableChange = (pagination, filters, sorter) => {
		if(sorter.order)
		{
			this.state.fieldName = sorter.field;
			if(sorter.order =="ascend")
				this.state.sortFormat = "A";
			else
				this.state.sortFormat = "D";
		}
		else
		{
			this.state.fieldName = "loadId";
			this.state.sortFormat = "A";
		}
		this.listData();
	}
  onShowSizeChange(current, pageSize) {
		this.state.currentPage = current;
		this.state.pageSize = pageSize;
		this.listData();	  
	}
  handleResize = index => (e, { size }) => {
      this.setState(({ columns }) => {
        const nextColumns = [...columns];
        nextColumns[index] = {
          ...nextColumns[index],
          width: size.width,
        };
        return { columns: nextColumns };
      });
    };
  render() {
    const { searchText, loans, selectedLoan, vendorType, properties,vendors } = this.state;
    const columns = this.state.columns.map((col, index) => ({
        ...col,
        onHeaderCell: column => ({
          width: column.width,
          onResize: this.handleResize(index),
        }),
      }));
      const components = {
        header: {
          cell: ResizeableTitle,
        },
      };
    let filteredData = loans.length && loans.map((d,index)=>({
        key:d.loanID,
        ...d
    }))
    return (
      <div style={{padding:15}}>
        <Addloan
          propertyTypes={properties}
          vendorTypes={vendorType}
		  vendors={vendors}
          companyID={this.state.companyId}
          handleSave={this.handleAddCancel}
          visible={this.state.isAdd || this.state.isEdit}
          handleCancel={this.handleAddCancel}
		  selectedItem={selectedLoan}
		  handleDelete={this.handleDelete}
		  isEdit={this.state.isEdit}
        />


        <Grid
          container
          spacing={24}
          style={{ 'marginBottom': 10 }}
        >
          <Grid item xs={12} sm={6} >
            <h2 className="user-title">Loans</h2>
          </Grid>
        <Grid item xs={12} sm={6} >
         <Grid
            container
             spacing={8}
             direction="row"
               justify="flex-end"
                 alignItems="flex-end"
            >
            <Grid item  >
            <SearchBox
               styleName="d-none d-lg-block"
               placeholder="Search... "
               value={searchText}
               onChange= { (e) => {  this.handleChange(e.target.value) }}
               style={{ background: '#eeeeeeb8'}}
             />
             </Grid>
             <Grid item >
            <Button variant="contained" color="primary" onClick={this.handleAddModal}>
                  Add Loan
            </Button>
             </Grid>
            </Grid>
          </Grid>
        </Grid>
          {this.state.loading
              ?
               <CircularProgress/>
          :
          (<div><Table style={{maxHeight:'410px',overflowY:'scroll'}}
              className="table-layout"
              columns={columns}
              components={components}
              dataSource={filteredData}
              rowClassName={ (record) =>  this.state.selectedItemKey == record.key  ? 'data-row selected' : 'data-row'  }
              bordered={true}
              size={"small"}
              onRowClick={ (record, index, event) => this.handleEditModal(record) }
              onRowDoubleClick={ (record, index, event) => this.handleEditModal(record) }
              onChange={this.handleTableChange}
              loading={this.state.loading}
              pagination={false}
    //scroll={{y:window.innerHeight-301}}
          />
          <Pagination
				pageSizeOptions={['10','25','50','100']}
				pageSize={this.state.pageSize}
				style={{marginTop:'10px',marginBottom:'10px'}}
				showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
				showSizeChanger onChange={(a,b)=>this.onShowSizeChange(a,b)} onShowSizeChange={(a,b)=>this.onShowSizeChange(a,b)} defaultCurrent={1} total={this.state.totalRowsInTable} />
          </div>) }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loans: state.loans.loans,
    properties:state.loans.propertyTypes,
    reload:state.loans.reload,
    vendorType:state.vendors.vendorTypes,
	vendors:state.vendors.vendors
  }
}

const mapDispatchToProps=(dispatch)=>{
    return bindActionCreators({
      propertyTypes,
      vendorTypes,
	  Vendors,
      searchLoans,
      getLoans,
      deleteLoan,
    },dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(LoansPage);

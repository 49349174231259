import * as CompanyDashboardService from '../services/CompanyDashboardService';
import React from 'react';
import { toast } from "react-toastify";

export const GET_DASHBOARD_DETAILS = 'GET_DASHBOARD_DETAILS';
export const RELOAD = 'RELOAD';
export const COMMONUPDATE = "COMMONUPDATE";
export const REVIEWHISTORY = "REVIEWHISTORY"

export const GET_CompanyDashboard = (data) => {
    return (dispatch) => {
        CompanyDashboardService.companyDashboard(data)
            .then((response) => {
                dispatch({ type: GET_DASHBOARD_DETAILS, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const Get_ExcelExportData =(data) => {
    return (dispatch) => {
        CompanyDashboardService.Get_ExcelExportData(data)
            .then((response) => {
                dispatch({ type: GET_DASHBOARD_DETAILS, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const GET_CompanyDashboard_BES = (data) => {
    return (dispatch) => {
        CompanyDashboardService.companyDashboard_BES(data)
            .then((response) => {
                dispatch({ type: GET_DASHBOARD_DETAILS, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const MarkMinistersApproved = (companyId) => {
    return (dispatch) => {
        CompanyDashboardService.MarkMinistersApproved(companyId)
            .then((response) => {
                dispatch({ type: COMMONUPDATE, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const MarkMinistersApproved_BES = (companyId,approver) => {
    return (dispatch) => {
        CompanyDashboardService.MarkMinistersApproved_BES(companyId,approver)
            .then((response) => {
                dispatch({ type: COMMONUPDATE, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const GetApprovedMinistersHistory = (companyId) => {
    return (dispatch) => {
        CompanyDashboardService.GetApprovedMinistersHistory(companyId)
            .then((response) => {
                dispatch({ type: REVIEWHISTORY, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}
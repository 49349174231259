import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import CircularProgress from './../../../../components/CircularProgress';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import moment from 'moment';
import { GET_EMPPAYROLL_REPORT_DATA, MarkASReviewedReport, GetReportReviewData } from '../../../../appRedux/actions/ReportDetailAction';
import { Updatetype } from '../../../../appRedux/actions/employeeAction';
import { ReportType, LinkType } from '../../../common/commonServices';
import { toast } from 'react-toastify';
import PaginationComponent from './../../../common/Pagination';
import ReviewPopup from "./../../../common/ReviewPopUp";
import { getEmployees, updateEmployee } from "../../../../appRedux/actions/employeeAction";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ClearIcon from '@material-ui/icons/Clear';
import { MarkMinistersApproved,GetApprovedMinistersHistory } from '../../../../appRedux/actions/CompanyDashboardAction';
// import { stat } from 'fs';

class MinisterList extends Component {

    constructor(props) {
        super(props)
        let endDate = new Date();
        let startDate = new Date(endDate.getFullYear(), 0, 1);

        if (endDate.getMonth() <= 2) {
            startDate = new Date(startDate.getFullYear() - 1, 0, 1);
            endDate = new Date(endDate.getFullYear() - 1, 11, 31);
        }

        this.state = {
            parantData: this.props.location.state ? this.props.location.state : null,
            MissingData: [],
            companyId: 0,
            sortField: 'name',
            sortFormat: "A",
            PageNo: 1,
            PageSize: 10,
            ColorCode: "#f0ca4c",
            sDate: startDate,// new Date(2019, 0, 1),
            eDate: endDate,// new Date(),
            loading: false,
            TotalRecords: 0,
            SelectedRecords: [],
            SelectALL: false,
            editIndex: -1,
            editData: null,
            ShowReview: false,
            ReviewList: [],
            employees: [],
            SelectedEmployee: 0,
            ShowHistory: false,
            IsReviewProcessing: false
        }
    }

    SelectALL = (e) => {
        if (this.state.MissingData.length > 0) {
            if (e.target.checked) {
                let all = [];
                let data = this.state.MissingData;
                for (let i = 0; i < data.length; i++) {
                    all.push(data[i].employeeID);
                }
                this.setState({ SelectALL: true, SelectedRecords: all });
            }
            else {
                this.setState({ SelectALL: false, SelectedRecords: [] });
            }
        }
    }

    onCheckboxClick = (e, item) => {
        e.stopPropagation();
        let existInArray = false;
        let data = this.state.SelectedRecords;
        if (data != null && data.length > 0 && data.indexOf(item.employeeID) > -1) {
            existInArray = true;
        }

        if (e.target.checked) {
            if (!existInArray) {
                data.push(item.employeeID);
            }
        }
        else {
            if (existInArray) {
                data.splice(data.indexOf(item.employeeID), 1);
            }
        }
        let all = (data.length === this.state.MissingData.length) ? true : false;
        this.setState({ SelectedRecords: data, SelectALL: all, editIndex: -1 });
    }

    onSortChange = (fieldName) => {
        if (this.state.sortField == fieldName) {
            let formate = this.state.sortFormat == 'A' ? 'D' : 'A';
            this.setState({ sortFormat: formate, loading: true }, () => {
                this.GetReportData();
            });
        } else {
            this.setState({ sortField: fieldName, sortFormat: 'A', loading: true }, () => {
                this.GetReportData();
            });
        }
    }

    ChangePageSize = (size) => {

        this.setState({ loading: true, PageSize: parseInt(size), PageNo: 1 }, () => {
            this.GetReportData();
        });
    }

    ChangePage = (page) => {
        this.setState({ loading: true, PageNo: page, }, () => {
            this.GetReportData();
        });
    }


    SelectALL = (e) => {

        if (this.state.MissingData.length > 0) {
            if (e.target.checked) {
                let all = [];
                let data = this.state.MissingData;
                for (let i = 0; i < data.length; i++) {
                    all.push(data[i].employeeID);
                }
                this.setState({ SelectALL: true, SelectedRecords: all });
            }
            else {
                this.setState({ SelectALL: false, SelectedRecords: [] });
            }
        }
    }

    onRefreshDataClick = () => {
        if (!this.state.loading) {
            this.setState({ loading: true }, () => {
                this.GetReportData(true);
            });
        }
    }

    GetReportData = (IsRefresh = false) => {
        let state = this.state;
        let body = {
            companyID: state.companyId,
            reportType: ReportType.VerifyMinisters,
            fromDate: moment(state.sDate).format("MM/DD/YYYY"),
            toDate: moment(state.eDate).format("MM/DD/YYYY"),
            sortField: state.sortField,
            sortFormat: state.sortFormat,
            pageNumber: state.PageNo,
            pageSize: state.PageSize,
            needUpdatQBORecords: IsRefresh
        };

        this.props.GET_EMPPAYROLL_REPORT_DATA(body);
    }

    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('userData'));
        this.setState({
            companyId: user.companyId,
            loading: true
        }, () => {
            this.props.GetApprovedMinistersHistory(user.companyId);
            this.props.getEmployees(user.companyId, "", 1, 10000, 'name', 'A');
            this.GetReportData();
        });
    }

    componentWillReceiveProps(nextProps) {
         
        if (nextProps.employees !== this.props.employees) {
            this.setState({
                employees: nextProps.employees, loading: false
            });
        }
        if (nextProps.ReportData !== this.props.ReportData) {
            if (nextProps.ReportData.transactionStatus === 0) {
                var totalrecords = 0;
                if (nextProps.ReportData.returnObject != null && nextProps.ReportData.returnObject.length > 0) {
                    totalrecords = nextProps.ReportData.returnObject[0].totalrows
                }
                this.setState({
                    MissingData: nextProps.ReportData.returnObject, TotalRecords: totalrecords, PageSize: this.state.PageSize,
                    PageNo: this.state.PageNo, loading: false, SelectALL: false
                });
            }
            else {
                toast.error(nextProps.ReportData.resultMsg);
                this.setState({ loading: false });
            }
        }
        if (nextProps.Update !== this.props.Update) {
            if (nextProps.Update.transactionStatus === 0) {
                this.setState({ editData: null, editIndex: -1 }, () => {
                    toast.success("Employee updated successfully");
                    this.GetReportData();
                });
            }
            else {
                this.setState({ loading: false }, () => toast.error(nextProps.Update.resultMsg));
            }
        }
        if (nextProps.UpdateDescription !== this.props.UpdateDescription) {
            if (nextProps.UpdateDescription.transactionStatus === 0) {
                this.setState({
                    SelectedRecords: [], PageNo: 1,
                    SelectALL: false,
                }, () => {
                    toast.success("Mark as Reviewed successfully");
                    this.GetReportData();
                });
            }
            else {
                this.setState({ loading: false }, () => toast.error(nextProps.UpdateDescription.resultMsg));
            }
        }

        if (nextProps.ReviewData !== this.props.ReviewData) {
            if (nextProps.ReviewData.transactionStatus === 0) {
                if (nextProps.ReviewData.returnObject == null || nextProps.ReviewData.returnObject.length < 1) {
                    this.setState({ loading: false, ReviewList: nextProps.ReviewData.returnObject, ShowReview: false }, () => {
                        toast.error("No history found");
                    });

                }
                else {
                    this.setState({ loading: false, ReviewList: nextProps.ReviewData.returnObject, ShowReview: true });
                }
            }
            else {
                this.setState({ loading: false }, () => toast.error(nextProps.ReviewData.resultMsg));
            }
        }

        if (nextProps.HistoryData !== undefined && nextProps.HistoryData!= null  && nextProps.HistoryData.returnObject != this.props.HistoryData) {
            if (nextProps.HistoryData.transactionStatus === 0) {
              this.setState({ loading: false, ShowHistory: true, HistoryData: nextProps.HistoryData.returnObject });
            }
        }

        if (nextProps.CommonUpdate !== undefined && nextProps.CommonUpdate !== this.props.CommonUpdate) {
            
            if (nextProps.CommonUpdate.transactionStatus === 0) {
              let data = this.state.ReportData;
              if (this.state.IsReviewProcessing == false) {
                // let obj = this.state.editedData;
                // data.map((item, index) => {
                //   if (item.listID === obj.listID) {
                //     item.vendor1099 = obj.vendor1099
                //   }
                // })

                // this.setState({editedData: null, IsReviewProcessing: false, reportData: data }, () => { toast.success("Request Process Successfully");
                this.setState({IsReviewProcessing: false}, () => { toast.success("Request Process Successfully");
                this.GetReportData();
               });
              }
              else {
                this.setState({ IsReviewProcessing: false }, () => {
                  toast.success("Mark as Reviewed Successfully");
                  this.props.GetApprovedMinistersHistory(this.state.companyId);
                });
              }
            }
            else {
              this.setState({ loading: false }, () => { toast.error(`${nextProps.CommonUpdate.resultMsg}`); });
            }
      
          }
    }

    onRowEdit = (i, rowData) => {
        let body = {
            EmployeId: rowData.employeeID,
            employeeType: rowData.employeeType,
            value: rowData.employeeType === "Regular Employees" ? "Non-Clergy" : "Clergy"
        }
        this.setState({ editData: body, editIndex: i });
    };

    onRowCancel = (rowIndex, rowData) => {
        this.setState({ editData: null, editIndex: -1 });
    }

    handleChange = (e) => {
        var stateData = this.state.editData;
        if (stateData != null) {
            stateData.employeeType = e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text;
            stateData.value = e.target.value;
        }
    }

    onRowSave = (index, data) => {
        let stateData = this.state.editData;

        let body = {
            "employeeID": stateData.EmployeId,
            "employeeType": stateData.value,
            "reportType": 1
        }

        this.setState({
            loading: true
        }, () => {
            this.props.Updatetype(body);
        });
    }

    MarkReview = () => {
        let selectedData = this.state.SelectedRecords;
        if (selectedData == null || selectedData.length === 0) {
            toast.error("Please select atleast 1 record for processing request");
            return false;
        }


        let body = {
            "ids": selectedData,
            "companyID": this.state.companyId,
            "reportType": ReportType.VerifyMinisters
        }

        this.setState({ loading: true }, () => {
            this.props.MarkASReviewedReport(body);
        });
    }

    onMarkApprove = () => {
        
        this.setState({ IsReviewProcessing: true, loading: true }, () => {
            this.props.MarkMinistersApproved(this.state.companyId);
          });
    }

    ShowReview = () => {

        let body = {
            companyId: this.state.companyId,
            ReportType: ReportType.VerifyMinisters
        };
        this.setState({ loading: true }, () => {
            this.props.GetReportReviewData(body)
        });
    }

    CloseReview = () => {
        this.setState({ ShowReview: false });
    }

    HandleEmployee = (event) => {
        this.setState({ SelectedEmployee: event.target.value });
    }


    AddMinister = () => {

        if (this.state.SelectedEmployee == null || this.state.SelectedEmployee == 0) {
            toast.error("Please select employee for processing request");
            return false;
        }
        else {
            let body = {
                "employeeID": this.state.SelectedEmployee,
                "employeeType": "Clergy",
                "reportType": ReportType.VerifyMinisters
            }

            this.setState({
                loading: true
            }, () => {
                this.props.Updatetype(body);
            });
        }

    }
    HandleClose = () => {
        this.setState({ ShowHistory: false });
    }

    render() {
        let selectedItem = this.state.SelectedRecords;
        return (
            <div className="dashboardPage">
                <div className="page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
                    <h2 className="mb-3 mb-sm-0">{JSON.parse(localStorage.getItem('userData')) != null ? JSON.parse(localStorage.getItem('userData')).companyName : ""} | {LinkType.MinisterList}</h2>
                </div>
                {this.state.ShowHistory && this.state.HistoryData != null && this.state.HistoryData.length > 0 &&
                    <React.Fragment>
                        <Dialog
                            className="comment-Top-align"
                            maxWidth="sm"
                            fullWidth={true}
                            open={true}>
                            <React.Fragment>
                                <div className="Comments modal-header"><span className="log-history-title float-left">Reviewed History </span>
                                    <Button className="jr-btn close-btn float-right" onClick={this.HandleClose}><ClearIcon /></Button>
                                </div>
                                <DialogContent>
                                    {this.state.HistoryData.length == 0 ?
                                        <div className="history-box">
                                            <p className="history-item-text">No History Found</p>
                                        </div>
                                        :
                                        <React.Fragment>
                                            {this.state.HistoryData.map((item, i) => (
                                                <div className="history-box">
                                                    <p className="history-date-text"> <strong style={{ color: "black" }}>  Last Reviewed By </strong> {item.userName} <strong> on </strong>{item.stCreatedDate}</p>
                                                </div>
                                            ))}
                                              {/* <div className="history-box">
                                                    <p className="history-date-text"> <strong style={{ color: "black" }}>  Last Approved  </strong> By <strong> Akash</strong> <strong> on </strong>07/28/2022 at 09:32 AM</p>
                                                </div>
                                                <div className="history-box">
                                                    <p className="history-date-text"> <strong style={{ color: "black" }}>  Last Approved  </strong> By <strong> Priya</strong> <strong> on </strong>07/28/2022 at 09:32 AM</p>
                                                </div> */}

                                        </React.Fragment>
                                    }
                                </DialogContent>
                            </React.Fragment>
                        </Dialog>
                    </React.Fragment>

                }
                {this.state.ShowReview ?
                    <ReviewPopup data={this.state.ReviewList} CloseReview={this.CloseReview} IsReportData={true}></ReviewPopup> : ""}
                {this.state.loading ? <CircularProgress></CircularProgress>
                    :
                    <React.Fragment>
                        <div className="padding-box app-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="float-right">
                                                <Button variant="raised" style={{ backgroundColor: this.state.ColorCode }} className="jr-btn text-white refresh-btn" onClick={() => this.onRefreshDataClick()}  ><i className="zmdi zmdi-refresh "></i> Refresh Data</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{ marginTop: "20px" }}>
                                            <div className="col-md-12">

                                               <div className='d-flex justify-content-end align-items-center'>
                                               <h4 for="staticEmail" style={{margin:"0px -10px"}} class=" col-form-label">Employee: </h4>
                                                <select style={{ backgroundColor: "#fff", marginRight: "5px" }} className="select-box arrow-box" onChange={this.HandleEmployee} value={this.state.SelectedEmployee} >
                                                    <option value={0}> Select </option>
                                                    {this.state.employees != null && this.state.employees.map((item, j) => (
                                                        <option value={item.employeeID}>  {item.name} </option>
                                                    ))}
                                                </select>
                                                <Button variant="raised" className=" jr-btn g-teal  text-white refresh-btn graycolor" onClick={() => this.AddMinister()}>Add Minister</Button>
                                                {/* <Button variant="raised" className=" jr-btn g-teal  text-white refresh-btn graycolor" onClick={() => this.ShowReview()} > Show Reviewed </Button> */}
                                                {/* {this.state.MissingData != null && this.state.MissingData.length > 0 &&
                                                    <Button variant="raised" className=" jr-btn g-teal  text-white refresh-btn graycolor" onClick={() => this.MarkReview()}  >Mark as Reviewed</Button>
                                                } */}
                                                {this.state.MissingData != null && this.state.MissingData.length > 0 &&
                                                    <Button variant="raised" className=" jr-btn g-teal  text-white refresh-btn graycolor" onClick={() => this.onMarkApprove()}>Mark as Reviewed</Button>
                                                }
                                                </div>
                                            </div>
                                        </div>
                            <div className="table-responsive-material">
                                <table className="table tablebox account-reconcilliation">
                                    <thead>
                                        <tr style={{ backgroundColor: this.state.ColorCode }}>
                                            <th className={`td-width ${this.state.sortField == 'name' ? this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('name')}><p className="table-th-title">Employee Name</p> </th>
                                            <th className={`td-width ${this.state.sortField == 'employeeType' ? this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('employeeType')}><p className="table-th-title">Type</p> </th>

                                            <th className="td-width-90 text-center">Edit</th>
                                            {/* <th className="td-width-90 text-center">
                                                <label className="container101 header-check">
                                                    {this.state.SelectALL ?
                                                        <input className="second-check-box" checked="checked" type="checkbox" onClick={(e) => this.SelectALL(e)} />
                                                        :
                                                        <input className="second-check-box" type="checkbox" onClick={(e) => this.SelectALL(e)} />
                                                    }
                                                    <span className="checkmark101 yellowMark white_border"></span>
                                                </label>
                                            </th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.MissingData.length > 0 ?
                                            (this.state.MissingData && this.state.MissingData.length > 0) && this.state.MissingData.map((item, j) => (
                                                <React.Fragment key={j}>
                                                    {<tr >
                                                        <td>{item.name}</td>
                                                        <td> {item.employeeType} </td>
                                                        {/* <td onClick={(e) => e.stopPropagation()}>
                                                            <label className="container101">
                                                                {(this.state.SelectALL || selectedItem.length > 0 && (selectedItem.indexOf(item.id) > -1)) ?
                                                                    <input className="second-check-box" checked="checked" type="checkbox" onClick={(e) => this.onCheckboxClick(e, item)} />
                                                                    :
                                                                    <input className="second-check-box" type="checkbox" onClick={(e) => this.onCheckboxClick(e, item)} />
                                                                }
                                                                <span className="checkmark101 green_CheckMark"></span>
                                                            </label>
                                                        </td> */}
                                                        <td className="text-center" onClick={(e) => this.onRowEdit(j, item)}>
                                                            <i className="zmdi zmdi-edit zmdi-hc-lg mr-4 pointer"></i>
                                                        </td>
                                                        {/* <td onClick={(e) => e.stopPropagation()}>
                                                            <label className="container101">
                                                                {(this.state.SelectALL || selectedItem.length > 0 && (selectedItem.indexOf(item.id) > -1)) ?
                                                                    <input className="second-check-box" checked="checked" type="checkbox" onClick={(e) => this.onCheckboxClick(e, item)} />
                                                                    :
                                                                    <input className="second-check-box" type="checkbox" onClick={(e) => this.onCheckboxClick(e, item)} />
                                                                }
                                                                <span className="checkmark101 green_CheckMark"></span>
                                                            </label>
                                                        </td> */}

                                                    </tr>
                                                    }
                                                    {this.state.editIndex === j &&
                                                        <tr>
                                                            <td> &nbsp; </td>
                                                            <td>
                                                                <Select
                                                                    name="employeeType"
                                                                    native
                                                                    label={"Employee Type"}
                                                                    fullWidth
                                                                    onChange={(e) => this.handleChange(e)}
                                                                >
                                                                    {this.state.editData != null && this.state.editData.employeeType === "Regular Employees" &&
                                                                        <React.Fragment>
                                                                            <option value={'Clergy'}>Minister</option>
                                                                            <option selected value={'Non-Clergy'}> Regular Employee </option>
                                                                        </React.Fragment>
                                                                    }

                                                                    {this.state.editData != null && this.state.editData.employeeType === "Minister" &&
                                                                        <React.Fragment>
                                                                            <option selected value={'Clergy'}>Minister</option>
                                                                            <option value={'Non-Clergy'}> Regular Employee </option>
                                                                        </React.Fragment>
                                                                    }
                                                                    {this.state.editData != null && (this.state.editData.employeeType == null || this.state.editData.employeeType === "") &&
                                                                        <React.Fragment>
                                                                            <option value={'Clergy'}>Minister</option>
                                                                            <option selected value={'Non-Clergy'}> Regular Employee </option>
                                                                        </React.Fragment>
                                                                    }
                                                                </Select>
                                                            </td>
                                                            <td colspan="2">
                                                                <div className="col-md-12">
                                                                    <Button variant="raised" className="jr-btn jr-btn-sm bg-teal text-white" onClick={() => this.onRowSave(j, item)}>SAVE</Button>
                                                                    <Button variant="raised" className="jr-btn jr-btn-sm bg-danger text-white" onClick={() => this.onRowCancel(j, item)}>CANCEL</Button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    }
                                                </React.Fragment>
                                            ))
                                            :
                                            <tr>
                                                <td colSpan="4" align="center">No issues here! Keep up the good work!</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <PaginationComponent
                                selectedPageNo={this.state.PageNo}
                                selectedSize={this.state.PageSize}
                                TotalRecords={this.state.TotalRecords}
                                ChangePageSize={this.ChangePageSize}
                                ChangePage={this.ChangePage}
                                CurrentRecords={this.state.MissingData.length}
                            >
                            </PaginationComponent>
                        </div>
                    </React.Fragment>
                }
            </div>
        )
    }

}

const mapStateToProps = (state) => {
 
    return {
        ReportData: state.ReportData.ReportData,
        Update: state.employee.Updated,
        employees: state.employee.employees,
        UpdateDescription: state.ReportData.UpdateDescription,
        ReviewData: state.ReportData.ReviewData,
        HistoryData: state.companyDashboardData.HistoryData,
        CommonUpdate: state.companyDashboardData.CommonUpdate
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        GET_EMPPAYROLL_REPORT_DATA,
        Updatetype,
        getEmployees,
        MarkASReviewedReport,
        GetReportReviewData,
        MarkMinistersApproved,
        GetApprovedMinistersHistory
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(MinisterList);

import React, { Component } from 'react';
import PaginationComponent from './../../../common/Pagination';
import { toast } from 'react-toastify';
import CircularProgress from './../../../../components/CircularProgress';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import AddQuestions from '../../../common/addQuestions';
import QuestionsAnsHistory from '../../../common/questionsAnsHistory';
import { GetQuestionList, ClosedQuestion } from "../../../../appRedux/actions/QuestionCommentsAction";
import { ReportType, ModuleType,LinkType } from "../../../common/commonServices";

class GeneralQueustionTagCPA extends Component {
    constructor(props) {
        super(props);
        this.state = {
            parantData: this.props.location.state ? this.props.location.state : null,
            QuesData: [],
            companyId: 0,
            PageNo: 1,
            PageSize: 10,
            ColorCode: "#46b29d",
            loading: false,            
            isAddQuestions: false,
            isQueAnsHistory: false,
            IsClosed: false,
            stSearch: null,
            TotalRecords: 0,
            selectedQuestion: null,
            isClosedRequest: false
        }
    }

    ChangePageSize = (size) => {
        this.setState({ loading: true, PageSize: parseInt(size), PageNo: 1 }, () => {
            this.GetQuestions();
        });
    }

    ChangePage = (page) => {
        this.setState({ loading: true, PageNo: page, }, () => {
            this.GetQuestions();
        });
    }
   
    OnAddQuestionsClick = () => {
        this.setState({ isAddQuestions: true });
    };

    onCloseAddQuestions = () => {
        this.setState({ isAddQuestions: false });
    };

    onCloseQueAnsHistory = () => {
        this.setState({ isQueAnsHistory: false });
    };

    OnSearchClick = () => {
        this.setState({
            loading: true
        }, () => {
            this.GetQuestions();
        });
    };   

    onResolvedClick = (e) => {
      this.setState({ IsClosed: e.target.checked, loading: true }, () => { this.GetQuestions() })
    }   
    
    OnRowClick = (data) => {
        this.setState({ isQueAnsHistory: true, selectedQuestion: data });
    }

    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('userData'));
        this.setState({
            companyId: user.companyId,
            loading: true
        }, () => {
            this.GetQuestions();
        });
    }

    componentWillReceiveProps(nextprops) {
        if (this.props.QuestionData !== nextprops.QuestionData) {
            if (nextprops.QuestionData.transactionStatus === 0) {
                let total = 0;
                if (nextprops.QuestionData.returnObject.length > 0) {
                    total = nextprops.QuestionData.returnObject[0].totalrows;
                }
                this.setState({ loading: false, QuesData: nextprops.QuestionData.returnObject, TotalRecords: total });
            }
            else {
                this.setState({ loading: false }, () => { toast.error(`${nextprops.QuestionData.resultMsg}`); });
            }
        }        
        if (this.props.ClosedUpdate !== nextprops.ClosedUpdate) {            
            if (nextprops.ClosedUpdate.transactionStatus === 0) {                
                this.setState({ isClosedRequest: false,isQueAnsHistory:false, loading: false }, () => { this.GetQuestions() });
                toast.success("Request process successfully");
            }
            else {
                this.setState({ loading: false }, () => { toast.error(`${nextprops.ClosedUpdate.resultMsg}`); });
            }
        }
    }
    GetQuestions = () => {

        let st = this.state;
        let body = {
            "companyID": st.companyId,
            "search": st.stSearch,
            "pageNumber": st.PageNo,
            "pageSize": st.PageSize,
            // "fromDate": "string",
            // "toDate": "string",            
            "status": st.IsClosed,
            "cpaReviewed": true,
            "reportType": ReportType.GeneralQuestionComments
        }
        this.props.GetQuestionList(body);

    }


    UpdateItemCommentCount = (id) => {
        let data = this.state.QuesData;
        data.forEach(function (item) {

            if (item.id === id) {
                item.commentCount++
            }
        });

        this.setState({ MissingData: data });
    }

    ResolvedQuestion = (id) => {        
        this.setState({ isClosedRequest: true, loading: true }, () => {
            this.props.ClosedQuestion(id);
        })
    }

    Reload = () => {
        this.setState({
            loading: true,
            isAddQuestions:false,
            isQueAnsHistory:false
        }, () => {
            this.GetQuestions();
        });
    }

    ChangeSearch = (e) => {
        this.setState({ stSearch: e.target.value })
    }

    render() {
        let selectedItem = this.state.SelectedRecords;
        return (
            <React.Fragment>
                {/* {this.state.isAddQuestions &&
                    <AddQuestions
                        onCloseAddQuestions={this.onCloseAddQuestions}
                        ColorCode={this.state.ColorCode}
                        CompanyId={this.state.companyId}
                        Reload={this.Reload}></AddQuestions>
                } */}
                {this.state.isQueAnsHistory &&
                    <QuestionsAnsHistory
                        onCloseQueAnsHistory={this.onCloseQueAnsHistory}
                        ColorCode={this.state.ColorCode}
                        QuestionDetails={this.state.selectedQuestion}
                        ReportType={ReportType.GeneralQuestionComments}
                        ModuleType={ModuleType.GeneralQuestion}
                        CompanyId={this.state.companyId}
                        IsEmbeddedPage={false}
                        ResolvedQuestion={this.ResolvedQuestion}
                        IsCLosed={this.state.IsClosed}
                        UpdateItemCommentCount={this.UpdateItemCommentCount}
                    ></QuestionsAnsHistory>
                }
                <div className="dashboardPage">
                    <div className="page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
                        <h2 className="title mb-3 mb-sm-0">{JSON.parse(localStorage.getItem('userData')) != null ? JSON.parse(localStorage.getItem('userData')).companyName : ""} | Other Questions tagged as "CPA"</h2>
                    </div>
                    <div className="padding-box app-wrapper">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div style={{ display: "flex" }}>
                                            <input type="text" className="form-control mr-2" placeholder="Search..." onChange={(e) => this.ChangeSearch(e)} />
                                            <Button variant="raised" className=" jr-btn graycolor text-white refresh-btn" onClick={() => this.OnSearchClick()}>Search</Button>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="checkbox pointer float-right" >
                                            <div className="chiller_cb">
                                                <input id="TFB" type="checkbox" onClick={(e) => this.onResolvedClick(e)} />
                                                <label for="TFB">
                                                    <p className="checkbox-title">Show Resolved</p>
                                                    <p className="parag-text"></p>
                                                </label>
                                                <span></span>
                                            </div>
                                        </div>
                                      
                                    </div>
                                    {/* <div className="col-md-4">
                                        <div className="float-right">                                            
                                            <Button variant="raised" className=" jr-btn text-white greencolor refresh-btn" onClick={() => this.OnAddQuestionsClick()}>Add Questions</Button>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        {this.state.loading ?
                            <CircularProgress></CircularProgress>
                            :

                            <React.Fragment>
                                <div className="table-responsive-material">
                                    <table className="table tablebox account-reconcilliation">
                                        <thead  >
                                            <tr style={{ backgroundColor: this.state.ColorCode }}>
                                                <th style={{ width: "2%" }}>Sr.No</th>
                                                <th className="td-width">Questions</th>
                                                <th className="td-width" > Last Comment </th>
                                                <th className="td-width-90 text-center"><img src="/vendors/WhiteCommnet.png" alt="comments" /></th>                                            
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(this.state.QuesData && this.state.QuesData.length > 0) && this.state.QuesData.map((item, j) => (
                                                <React.Fragment key={j}>
                                                    {<tr >
                                                        <td>{this.state.PageNo===1? j + 1:((this.state.PageNo*10)-10)+(j+1) }</td>
                                                        <td>{item.question}</td>
                                                        <td>{item.comment}</td>
                                                        <td className="text-center" >
                                                            <div className="panel-commentCount">
                                                                <img src="/vendors/Vector Smart Object.png" alt="comments"  onClick={() => this.OnRowClick(item)} className={item.commentCount === 0 ? "disableCommentsOpacity" : ""}></img>

                                                                {item.unreadComments !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">{item.unreadComments}</span>}
                                                            </div>
                                                        </td>                                                        
                                                    </tr>}
                                                </React.Fragment>
                                            ))}

                                            {this.state.QuesData === null || this.state.QuesData.length === 0 ?
                                                <tr>
                                                    <td className="text-center" colSpan="8">
                                                    No issues here! Keep up the good work!
                                                    </td>
                                                </tr>
                                                : ""}
                                        </tbody>
                                    </table>
                                </div>
                                <PaginationComponent
                                    selectedPageNo={this.state.PageNo}
                                    selectedSize={this.state.PageSize}
                                    TotalRecords={this.state.TotalRecords}
                                    ChangePageSize={this.ChangePageSize}
                                    ChangePage={this.ChangePage}
                                    CurrentRecords={this.state.QuesData.length}
                                >
                                </PaginationComponent>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }
}



const mapStateToProps = (state) => {
    return {
        QuestionData: state.QuestionCommentsData.QuestionData,
        ClosedUpdate: state.QuestionCommentsData.ClosedUpdate
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        ClosedQuestion,        
        GetQuestionList
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(GeneralQueustionTagCPA);
import React, {Component} from "react";
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AddRetirementPlan from '../../../../Forms/AddRetirementPlan';
import { Vendors } from '../../../../appRedux/actions/vendorAction';
import { getRetirementPlans,deleteRetirementPlan } from '../../../../appRedux/actions/retirementPlanAction';
import SearchBox from '../../../../components/SearchBox';
import CircularProgress from "../../../../components/CircularProgress";
import moment from 'moment';
import _ from 'lodash';
import "react-toastify/dist/ReactToastify.css";
import {Table, Pagination  } from 'antd';
import { Resizable } from 'react-resizable';
import "../../styles.css";

const ResizeableTitle = props => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable width={width} height={0} onResize={onResize}>
      <th {...restProps} />
    </Resizable>
  );
};

class RetirementPlanPage extends Component {
  constructor(props) {
    super(props);

    let column = [
    {
        title: 'Start Date',
        dataIndex: 'dateAdded',
        className:'table-heading',
        render: text => <span title={moment(text).format("LL")}>
          {moment(text).format("L") }
        </span>,
        sorter:  true,
        sorter:  true,
        width:100
    },{
      title: 'Service Provider',
      dataIndex: 'vendorName',
      className:'table-heading',
      sorter:  true,
      width:180,
  },
  {
    title: 'Plan Type',
    dataIndex: 'planType',
    className:'table-heading',
    sorter:  true,
    width:180,
},{
      title: 'Payroll Setup',
      dataIndex: 'payrollSetup',
      className:'table-heading',
      sorter:  true,
      width:120
  },
  {
    title: 'Participants',
    dataIndex: 'participants',
    className:'table-heading',
    sorter:  true,
    width:210,
},{
        title: 'Comments',
        dataIndex: 'comments',
        className:'table-heading',
        sorter:  true,
        width:210,
    },
    {
        title: 'Status',
        dataIndex: 'status',
        className:'table-heading',
        sorter:  true,
        width:100
    },
    
    {
        title: 'Termination Date',
        dataIndex: 'terminationDate',
        className:'table-heading',
        sorter:  true,
        render: text => <span title={moment(text).format("LL")}>
          {moment(text).format("L") }
        </span>,
    }

    ];

    this.state = {
      pageSize:50,
			currentPage:1,
			totalRowsInTable:0,
			fieldName:'retirementPlanId',
			sortFormat:'A',
      searchText:'',
      search:false,
      columns: column,
      isAdd: false,
      edit:false,
      retirementPlans:[],
	    loading:true,
      isUservisibile: false,
      selectedLease:'',
      companyId:'',
      selectedItemKey:0,
      vendors:[],
    }
  };


  componentDidMount(){

    this.listData();
    let user = JSON.parse(localStorage.getItem('userData'));
	this.props.Vendors(user.companyId);
  }
  listData()
  {
    let user = JSON.parse(localStorage.getItem('userData'));
    const CompanyId = user.companyId;
    this.setState({companyId:user.companyId})
    this.props.getRetirementPlans(CompanyId,this.state.searchText,this.state.currentPage,this.state.pageSize,this.state.fieldName,this.state.sortFormat);
  }
  componentWillReceiveProps(nextProps) {    
     if(nextProps.retirementPlans!==undefined && nextProps.retirementPlans !== this.props.retirementPlans ){
       if(nextProps.retirementPlans.length>0)
          this.state.totalRowsInTable = nextProps.retirementPlans[0].totalrows;
          this.setState({
            retirementPlans:nextProps.retirementPlans,loading:false
         });
      }
      if(nextProps.vendors !==undefined && nextProps.vendors !== this.props.vendors  ){
        this.setState({
          vendors:nextProps.vendors
        });
     }
     if(nextProps.reload==true)
       this.listData();

  }





  handleChange = (text) => {
    var self=this;
    if(text) {

      self.state.searchText = text;


      } else {
        self.state.searchText = '';

      }
      self.state.currentPage =1;
      this.setState({search:true});

    clearTimeout(self.state.timeout);

    // Make a new timeout set to go off in 800ms
    self.state.timeout = setTimeout(function () {
      self.listData();

    }, 500);

    }

  handleDelete = (id) =>  {
      this.props.deleteRetirementPlan(id);
       this.setState({
      edit:false,
    });
  }

  handleAddModal = () => {

    this.setState({
		selectedItem:false,
      isAdd: true,
      edit:false,
    });
  }

  handleEditModal= (record) =>{
    
      var id=record.key
      var data=this.state.retirementPlans;
      if(id!=0)
       {
          const selectedRetirementPlan = _.find(data, {retirementPlanID:id});          
          this.setState({
            selectedRetirementPlan:_.cloneDeep(selectedRetirementPlan),
              edit:true,
			  isAdd:false
          });
      }
  }

  handleAddCancel = () => {
    this.setState({ isAdd: false,edit:false});
  }

  handleTableChange = (pagination, filters, sorter) => {
    if(sorter.order)
    {
      this.state.fieldName = sorter.field;
      if(sorter.order =="ascend")
        this.state.sortFormat = "A";
      else
        this.state.sortFormat = "D";
    }
    else
    {
      this.state.fieldName = "retirementPlanId";
      this.state.sortFormat = "A";
    }
    this.listData();
  }

  onShowSizeChange(current, pageSize) {
		this.state.currentPage = current;
		this.state.pageSize = pageSize;
		this.listData();
	}
  handleResize = index => (e, { size }) => {
      this.setState(({ columns }) => {
        const nextColumns = [...columns];
        nextColumns[index] = {
          ...nextColumns[index],
          width: size.width,
        };
        return { columns: nextColumns };
      });
    };

  render() {
    const { searchText , selectedRetirementPlan,vendors} = this.state;
    let { retirementPlans } = this.state;
    const columns = this.state.columns.map((col, index) => ({
        ...col,
        onHeaderCell: column => ({
          width: column.width,
          onResize: this.handleResize(index),
        }),
      }));
      const components = {
        header: {
          cell: ResizeableTitle,
        },
      };
    let filteredData = retirementPlans.length && retirementPlans.map((d,index)=>({
        key:d.retirementPlanID,
        ...d
    }))
    return (
      <div style={{padding:15}}>
        <AddRetirementPlan
          vendors={vendors}
          companyID={this.state.companyId}
          handleSave={this.handleAddCancel}
          visible={this.state.isAdd || this.state.edit}
		  isEdit={this.state.edit}
          handleCancel={this.handleAddCancel}
		  selectedItem={selectedRetirementPlan}
		  handleDelete={this.handleDelete}
        />


        <Grid
          container
          spacing={24}
          style={{ 'marginBottom': 10 }}
        >
          <Grid item xs={12} sm={6} >
            <h2 className="user-title">Retirement Plans</h2>
          </Grid>
          <Grid item xs={12} sm={6} >
            <Grid
            container
             spacing={8}
             direction="row"
              justify="flex-end"
                 alignItems="flex-end"
            >
            <Grid item  >
            <SearchBox
               styleName="d-none d-lg-block"
               placeholder="Search..."
               value={searchText}
               onChange= { (e) => {  this.handleChange(e.target.value) }}
               style={{ background: '#eeeeeeb8'}}
             />
             </Grid>
             <Grid item  >
            <Button variant="contained" color="primary" onClick={this.handleAddModal}>
                 Add Retirement Plans
            </Button>
             </Grid>
            </Grid>
          </Grid>
        </Grid>
         {this.state.loading
              ?
               <CircularProgress/>
              :
              (<div><Table style={{maxHeight:'410px',overflowY:'scroll'}}
                  className="table-layout"
                  columns={columns}
                  components={components}
                  dataSource={filteredData}
                  rowClassName={ (record) =>  this.state.selectedItemKey == record.key  ? 'data-row selected' : 'data-row'  }
                  bordered={true}
                  size={"small"}
                  onRowClick={ (record, index, event) => this.handleEditModal(record) }
                  onRowDoubleClick={ (record, index, event) => this.handleEditModal(record) }
                  onChange={this.handleTableChange}
                  loading={this.state.loading}
                  pagination={false}
                  //scroll={{y:window.innerHeight-301}}
                  />
                  <Pagination
                  pageSizeOptions={['10','25','50','100']}
                  pageSize={this.state.pageSize}
                  style={{marginTop:'10px',marginBottom:'10px'}}
                  showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                  showSizeChanger onChange={(a,b)=>this.onShowSizeChange(a,b)} onShowSizeChange={(a,b)=>this.onShowSizeChange(a,b)} defaultCurrent={1} total={this.state.totalRowsInTable} />
                  </div>)
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    retirementPlans: state.retirementPlan.retirementPlans,
    reload: state.retirementPlan.reload,
    vendors:state.vendors.vendors
  }
}

const mapDispatchToProps=(dispatch)=>{
    return bindActionCreators({
      getRetirementPlans,
      deleteRetirementPlan,
      Vendors
    },dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(RetirementPlanPage);

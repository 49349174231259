import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import PaginationComponent from "../../../app/common/Pagination";
import CircularProgress from "../../../components/CircularProgress";
import { GetClientQuestion } from "../../../appRedux/actions/QuestionCommentsAction";
import { ReportType, ModuleType,Base64,LinkType } from "../../../app/common/commonServices";
import QuestionsAnsHistory from "../../../app/common/questionsAnsHistory";
import { Link } from 'react-router-dom';
import VimeoInstructionLoader from './../../../app/common/VimeoInstructionLoader'
import Badge from '@material-ui/core/Badge';
import queryString from 'query-string';

class GeneralQuesComm__Embedded extends Component {

    constructor(props) {
        super(props);
        this.state = {
            parantData: this.props.location.state ? this.props.location.state : null,
            QuesData: [],
            companyId: 0,
            PageNo: 1,
            PageSize: 10,
            ColorCode: "#46b29d",
            loading: false,            
            isQueAnsHistory: false,            
            stSearch: null,
            TotalRecords: 0,
            selectedQuestion: null,
            HomeLink: "javascript://",
            IsClosed:false,
            companyName: ""        
        }
    }

    ChangePageSize = (size) => {
        this.setState({ loading: true, PageSize: parseInt(size), PageNo: 1 }, () => {
            this.GetQuestions();
        });
    }

    ChangePage = (page) => {
        this.setState({ loading: true, PageNo: page, }, () => {
            this.GetQuestions();
        });
    }

    onCloseQueAnsHistory = () => {
        this.setState({ isQueAnsHistory: false });
    };

    OnSearchClick = () => {
        this.setState({
            loading: true
        }, () => {
            this.GetQuestions();
        });
    };    

    OnRowClick = (data) => {
        this.setState({ isQueAnsHistory: true, selectedQuestion: data });
    }

    componentDidMount() {        
        let value = queryString.parse(this.props.location.search);

        // localStorage.setItem("homeURL", window.location.href.replace(window.location.origin, ""))

        let dashboardURl = localStorage.getItem("homeURL");
        this.setState({
            loading: true,
            HomeLink: dashboardURl ? dashboardURl : "?q="+ value.q + "&p=" + value.p
        }, () => {
            this.getCompanyId();
        });
    }

    componentWillReceiveProps(nextprops) {
        if (this.props.QuestionData !== nextprops.QuestionData) {
            if (nextprops.QuestionData.transactionStatus === 0) {
                let total = 0;
                if (nextprops.QuestionData.returnObject.length > 0) {
                    total = nextprops.QuestionData.returnObject[0].totalrows;
                }
                this.setState({ loading: false, QuesData: nextprops.QuestionData.returnObject, TotalRecords: total });
            }
            else {
                this.setState({ loading: false }, () => { toast.error(`${nextprops.QuestionData.resultMsg}`); });
            }
        }        
        if (this.props.ClosedUpdate !== nextprops.ClosedUpdate) {            
            if (nextprops.ClosedUpdate.transactionStatus === 0) {                                
                this.setState({ isQueAnsHistory:false, loading: false }, () => { this.GetQuestions() });
                toast.success("Request process successfully");
            }
            else {
                this.setState({ loading: false }, () => { toast.error(`${nextprops.ClosedUpdate.resultMsg}`); });
            }
        }
    }

    getCompanyId = () => {
        let value = queryString.parse(this.props.location.search);
        localStorage.setItem("q", JSON.stringify(value));
        let data = localStorage.getItem("q");
        if (data != null && data != undefined) {
            data = JSON.parse(data);
            this.setState({
                companyId: parseInt(Base64.decode(data.q)),
                companyName:Base64.decode(data.p),
                loading: true
            }, () => {
                this.GetQuestions();
            });
        }
        else {
            this.setState({ loading: false });
        }
    }

    GetQuestions = () => {

        let st = this.state;
        let body = {
            "companyID": st.companyId,
            "search": st.stSearch,
            "pageNumber": st.PageNo,
            "pageSize": st.PageSize,
            // "fromDate": "string",
            // "toDate": "string",            
            "status": st.IsClosed,
            "cpaReviewed": false,
            "reportType": ReportType.GeneralQuestionComments
        }
        this.props.GetClientQuestion(body);
    }    

    Reload = () => {
        this.setState({
            loading: true,            
            isQueAnsHistory:false
        }, () => {
            this.GetQuestions();
        });
    }

    UpdateItemCommentCount = (id) => {
        let data = this.state.QuesData;
        data.forEach(function (item) {

            if (item.id === id) {
                item.commentCount++
            }
        });

        this.setState({ MissingData: data });
    }

    onResolvedClick = (e) => {
        this.setState({ IsClosed: e.target.checked, loading: true }, () => { this.GetQuestions() })
    }

    ChangeSearch = (e) => {
        this.setState({ stSearch: e.target.value })
    }

    render() {
        let selectedItem = this.state.SelectedRecords;
        return (
            <React.Fragment>                
                {this.state.isQueAnsHistory &&
                    <QuestionsAnsHistory
                        onCloseQueAnsHistory={this.onCloseQueAnsHistory}
                        ColorCode={this.state.ColorCode}
                        QuestionDetails={this.state.selectedQuestion}
                        ReportType={ReportType.GeneralQuestionComments}
                        ModuleType={ModuleType.GeneralQuestion}
                        CompanyId={this.state.companyId}
                        IsEmbeddedPage={true}                        
                        IsCLosed={this.state.IsClosed}
                        UpdateItemCommentCount={this.UpdateItemCommentCount}
                    ></QuestionsAnsHistory>
                }
                <div className="dashboardPage embeded-dashboard">
                    <div className="page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
                        <h2 className="title mb-3 mb-sm-0">{this.state.companyName} | {LinkType.OtherQueComm}</h2>
                        <Link className="home-link" to={this.state.HomeLink} > Home </Link>
                    </div>
                    <div className="padding-box app-wrapper">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div style={{ display: "flex" }}>
                                            <input type="text" className="form-control mr-2" placeholder="Search..." onChange={(e) => this.ChangeSearch(e)} />
                                            <Button variant="raised" className=" jr-btn graycolor text-white refresh-btn" onClick={() => this.OnSearchClick()}>Search</Button>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                    <div className="float-right" >  
                                            <VimeoInstructionLoader 
                                                //URL={"https://vimeo.com/687419407"} 
                                                URL={"https://player.vimeo.com/video/687419407?h=df22da040e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"} 
                                                buttonName="Instructions"
                                                buttonColor="greencolor"
                                                PageName={LinkType.OtherQueComm}
                                            ></VimeoInstructionLoader>  
                                        </div>  
                                        <div className="checkbox pointer float-right" >
                                            <div className="chiller_cb">
                                                <input id="TFB" type="checkbox" onClick={(e) => this.onResolvedClick(e)} />
                                                <label for="TFB">
                                                    <p className="checkbox-title">Show Resolved</p>
                                                    <p className="parag-text"></p>
                                                </label>
                                                <span></span>
                                            </div>
                                        </div>                          
                                    </div>                    
                                </div>
                            </div>
                        </div>
                        {this.state.loading ?
                            <CircularProgress></CircularProgress>
                            :

                            <React.Fragment>
                                <div className="table-responsive-material">
                                    <table className="table tablebox account-reconcilliation">
                                        <thead  >
                                            <tr style={{ backgroundColor: this.state.ColorCode }}>
                                                <th style={{ width: "2%" }}>Sr.No</th>
                                                <th className="td-width">Questions</th>
                                                <th className="td-width" > Last Comment </th>
                                                <th className="td-width-90 text-center"><img src="/vendors/WhiteCommnet.png" alt="comments" /></th>                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(this.state.QuesData && this.state.QuesData.length > 0) && this.state.QuesData.map((item, j) => (
                                                <React.Fragment key={j}>
                                                    {<tr >
                                                        <td>
                                                            {this.state.PageNo===1? j + 1:((this.state.PageNo*10)-10)+(j+1) }
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            {
                                                                item.accountId > 0 ?
                                                                <Badge badgeContent={"BS"} color="primary" style={{marginBottom : "5px"}}>
                                                                </Badge> 
                                                                :""
                                                            }
                                                        </td>
                                                        {/* <td>{item.question}</td> */}
                                                        <td>
                                                        {
                                                            item.accountId > 0 ? 
                                                            "Account " + item.accountId + " " + item.accountName + " : " + item.question
                                                            : item.question
                                                        }
                                                        </td>
                                                        <td>{item.comment}</td>
                                                        <td className="text-center" >
                                                            <div className="panel-commentCount">
                                                                <img src="/vendors/Vector Smart Object.png" onClick={() => this.OnRowClick(item)} alt="comments" className={item.commentCount === 0 ? "disableCommentsOpacity" : ""}></img>

                                                                {item.unreadComments !== 0 && <span class="badge badge-pill badge-danger ng-star-inserted">{item.unreadComments}</span>}
                                                            </div>
                                                        </td>                                                     
                                                    </tr>}
                                                </React.Fragment>
                                            ))}

                                            {this.state.QuesData === null || this.state.QuesData.length === 0 ?
                                                <tr>
                                                    <td className="text-center" colSpan="8">
                                                    No issues here! Keep up the good work!
                                                    </td>
                                                </tr>
                                                : ""}
                                        </tbody>
                                    </table>
                                </div>
                                <PaginationComponent
                                    selectedPageNo={this.state.PageNo}
                                    selectedSize={this.state.PageSize}
                                    TotalRecords={this.state.TotalRecords}
                                    ChangePageSize={this.ChangePageSize}
                                    ChangePage={this.ChangePage}
                                    CurrentRecords={this.state.QuesData.length}
                                >
                                </PaginationComponent>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        QuestionData: state.QuestionCommentsData.QuestionData,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ 
        GetClientQuestion
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralQuesComm__Embedded);



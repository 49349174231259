import React, { Component } from 'react';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { ReportType, MaskTaxIdentifier, Base64, numberWithCommas, Getsum,LinkType,GetLastQuarterEndDate } from "./../../../app/common/commonServices";
import moment from 'moment';
import { bindActionCreators } from 'redux';
import CircularProgress from './../../../components/CircularProgress';
import { ApproveWorkerClassifiedAsAnEmployee_BES } from "./../../../appRedux/actions/ReportDetailAction";
import { EmployeeStatusApprove_BES } from "./../../../appRedux/actions/LedgerAction";
import { toast } from 'react-toastify';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from 'react-router-dom';
import queryString from 'query-string';

class ApproveWorker_Embedded extends Component {

    constructor(props) {
        super(props)


        let eDate= new Date();
        let sDate= new Date(eDate.getFullYear(),eDate.getMonth(),1);
        // if(eDate.getMonth() <3)
        // {
        //     sDate= new Date(eDate.getFullYear()-1,0,1);
        //     eDate = new Date(eDate.getFullYear()-1,11,31);
        // }
        if (eDate.getMonth() <= 2) {
            sDate.setFullYear(eDate.getFullYear() - 1, 0, 1);
            eDate = new Date(eDate.getFullYear() - 1, 11, 31);
        }
        else {
            sDate = new Date(eDate.getFullYear(), 0, 1);
            eDate = GetLastQuarterEndDate();
        }


        this.state = {
            parantData: this.props.location.state ? this.props.location.state : null,
            ReportData: [],
            companyId: 0,
            sortField: 'date',
            sortFormat: "D",
            PageNo: 1,
            PageSize: 10000,
            ColorCode: "#46b29d",
            sDate: sDate,
            eDate: eDate,
            loading: false,
            CommentReferenceId: 0,
            IsEmbeddedPage: false,
            isEmployeeStatusApprovedbyClient: false,
            ShowApprovedInput: false,
            selectedListId: null,
            ApproverName: null,
            HomeLink: "",
            companyName: ""
        }
    }


    GetReportData(IsRefresh = false) {
        let state = this.state;
        let body = {
            companyID: state.companyId,
            reportType: ReportType.ApproveWorker,
            fromDate: moment(state.sDate).format("MM/DD/YYYY"),
            toDate: moment(state.eDate).format("MM/DD/YYYY"),
            sortField: state.sortField,
            sortFormat: state.sortFormat,
            pageNumber: state.PageNo,
            pageSize: state.PageSize,
            needUpdatQBORecords: IsRefresh
        };

        this.props.ApproveWorkerClassifiedAsAnEmployee_BES(body);
    }

    componentDidMount() {
        let value = queryString.parse(this.props.location.search);
        //localStorage.setItem("homeURL", window.location.href.replace(window.location.origin, ""))
        let dashboardURl = localStorage.getItem("homeURL");
        this.setState({
            loading: true,
            HomeLink: dashboardURl ? dashboardURl : "?q="+ value.q + "&p=" + value.p
        }, () => {
            this.getCompanyId();
        });
    }


    handleApprovedBy = (e) => {
        this.setState({ ApproverName: e.target.value });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.ApproveWorkerData !== this.props.ApproveWorkerData) {
            if (nextProps.ApproveWorkerData.transactionStatus === 0) {
                //if (nextProps.ApproveWorkerData.returnObject != null) {
                    this.setState({ loading: false, ReportData: nextProps.ApproveWorkerData.returnObject })
                // }
                // else {
                //     this.setState({ loading: false }, () => { toast.error(`No Data Found!!!`); });
                // }
            }
            else {
                this.setState({ loading: false }, () => { toast.error(`${nextProps.ApproveWorkerData.resultMsg}`); });
            }
        }

        if (nextProps.CommonUpdate !== this.props.CommonUpdate) {
            if (nextProps.CommonUpdate.transactionStatus === 0) {
                let data = this.state.ReportData;
                let selectedId = this.state.selectedListId;
                data.map((item, index) => {
                    if (item.listID === selectedId) {
                        item.isEmployeeStatusApprovedbyClient = this.state.isEmployeeStatusApprovedbyClient
                    }
                })

                this.setState({ loading: false, ApproverName: null, selectedListId: null, reportData: data }, () => { toast.success("Request Process Successfully"); });
            }
            else {
                this.setState({ loading: false }, () => { toast.error(`${nextProps.CommonUpdate.resultMsg}`); });
            }
        }
    }

    CancelUpdate = (item) => {

        let data = this.state.ReportData;
        let selectedId = this.state.selectedListId;
        data.map((item, index) => {
            if (item.listID === selectedId) {
                item.isEmployeeStatusApprovedbyClient = this.state.isEmployeeStatusApprovedbyClient ? false : true
            }
        })
        this.setState({ selectedListId: null, ApproverName: null, ReportData: data })

    }

    getCompanyId = () => {
        let value = queryString.parse(this.props.location.search);
        localStorage.setItem("q", JSON.stringify(value));
        let data = localStorage.getItem("q");
        if (data != null && data != undefined) {
            data = JSON.parse(data);
            this.setState({
                companyId: parseInt(Base64.decode(data.q)),
                companyName:Base64.decode(data.p),
                loading: true,
                ShowApprovedInput: false,
                selectedListId: null
            }, () => {
                this.GetReportData();
            });
        }
        else {
            this.setState({ loading: false });
        }
    }

    onCheckboxClick = (e, data) => {
        let items = this.state.ReportData;
        items.map((item, index) => {
            if (item.listID === data.listID) {
                item.isEmployeeStatusApprovedbyClient = e.target.checked
            }
        })
        this.setState({ isEmployeeStatusApprovedbyClient: e.target.checked, selectedListId: data.listID, ReportData: items });
    }

    UpdateStatus = () => {
        let approver = this.state.ApproverName;
        if (approver == null || approver.trim().replace(/\s/g, '') === "") {
            toast.error("Please enter the approver name");
            return false;
        }

        let body = {
            "companyID": this.state.companyId,
            "isEmployeeStatusApprovedbyClient": this.state.isEmployeeStatusApprovedbyClient,
            "createdByName": approver,
            "listID": this.state.selectedListId
        }

        this.setState({ loading: true }, () => { this.props.EmployeeStatusApprove_BES(body); });
    }

    render() {
        return (
            <div className="dashboardPage embeded-dashboard">
                <ToastContainer autoClose={3000} />


                <div className=" page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
                    <h2 className="title mb-3 mb-sm-0">{this.state.companyName} |  {LinkType.ApproveWorker}</h2>
                    <Link className="home-link" to={this.state.HomeLink} > Home </Link>
                </div>
                <div className="padding-box app-wrapper bgcolor">
                    {this.state.loading ? <CircularProgress></CircularProgress>
                        :
                        <React.Fragment>
                            {this.state.ReportData != null && this.state.ReportData.map((item, i) => (
                                <div>
                                    <div className="complince-review-box ebd">
                                        <div className="left-box">
                                            <h5>{item.vendor}</h5>
                                        </div>
                                        <div className="center-box">
                                            <p className="txidn"> Tax ID Number:<strong>{MaskTaxIdentifier(item.taxIdentifier)}</strong> </p>
                                        </div>
                                        <div className="right-box">
                                            <div className="row">
                                                <div className="d-flex alignCenter">
                                                    <p>Employee Status Approve </p>
                                                    <label className="container101">
                                                        {item.isEmployeeStatusApprovedbyClient ?
                                                            <input className="second-check-box" name={item.listID} checked="checked" type="checkbox" onChange={(e) => this.onCheckboxClick(e, item)} />
                                                            :
                                                            <input className="second-check-box" type="checkbox" name={item.listID} onChange={(e) => this.onCheckboxClick(e, item)} />
                                                        }
                                                        <span className="checkmark101 green_CheckMark"></span>
                                                    </label>
                                                    {this.state.selectedListId != null && this.state.selectedListId === item.listID &&                                                    
                                                        <div className="approved-box" >
                                                            <label><strong> Approved By </strong></label> &nbsp;&nbsp;
                                                                <input type="text" className="approver-name form-control" placeholder="enter your name" onChange={this.handleApprovedBy} />
                                                            {/* onClick={() => this.OnApplyApproved()} */}
                                                            <Button variant="raised" style={{ marginLeft: "10px" }} className=" jr-btn graycolor text-white refresh-btn emdbtn" onClick={() => this.UpdateStatus(item)} >Save</Button>
                                                            <Button variant="raised" style={{ padding: '8px 8px !important' }} className="jr-btn graycolor text-white refresh-btn emdbtn" onClick={() => this.CancelUpdate(item)}  >Cancel</Button>
                                                        </div>
                                                    
                                                }
                                                </div>
                                               
                                            </div>

                                        </div>
                                    </div>
                                    <div className="">
                                        <div className=""></div>
                                        <table className="table">
                                            <thead>
                                                <tr className="header-bg-color" style={{ backgroundColor: "#f0ca4c", color: "white" }}>
                                                    <th>Date</th>
                                                    <th> Type </th>
                                                    <th> Number </th>
                                                    <th> Memo/Description </th>
                                                    <th> Account </th>
                                                    <th> Amount </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {item.reportData != null & item.reportData.length > 0 ?
                                                    <React.Fragment key={i}>
                                                        {item.reportData != null && item.reportData.map((data, index) => (
                                                            <tr onClick={() => this.onLinkClick(data)}>
                                                                <td> {data.date}</td>
                                                                <td>{data.transactionType}</td>
                                                                <td >{data.num}</td>
                                                                <td>{data.memoOrDescription}</td>
                                                                <td>{data.accountValue}</td>
                                                                <td className="text-right pr-2">${numberWithCommas(data.amount)}</td>
                                                            </tr>
                                                        ))}
                                                        <tr>
                                                            <td colSpan="4"></td>
                                                            <td><h2 className="total-payments-text">Total Payments</h2></td>
                                                            <td className="text-right pr-2"><h1 className="total-text"> ${Getsum(item.reportData, "amount")} </h1></td>
                                                        </tr>
                                                    </React.Fragment>
                                                    :
                                                    <tr>
                                                        <td className="text-center" colSpan="6">
                                                        No issues here! Keep up the good work!
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            ))}
                             {(this.state.ReportData == null || this.state.ReportData.length === 0) &&
                                // <div>No data found</div>
                                <div className="">
                                        <div className=""></div>
                                        <table className="table">
                                            <thead>
                                            <tr className="header-bg-color" style={{ backgroundColor: "#f0ca4c", color: "white" }}>
                                                    <th>Date</th>
                                                    <th> Type </th>
                                                    <th> Number </th>
                                                    <th> Memo/Description </th>
                                                    <th> Account </th>
                                                    <th> Amount </th>
                                                </tr>
                                            </thead>
                                        <tbody>
                                                    <React.Fragment>
                                                        <tr>
                                                        <td className="text-center" colSpan="6">
                                                        No issues here! Keep up the good work!
                                                        </td>
                                                    </tr>
                                                    </React.Fragment>
                                                   

                                            </tbody>
                                        </table>
                                    </div>
                            }
                        </React.Fragment>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ApproveWorkerData: state.ReportData.ApproveWorkerData,
        CommonUpdate: state.ledgers.CommonUpdate,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        ApproveWorkerClassifiedAsAnEmployee_BES,
        EmployeeStatusApprove_BES
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(ApproveWorker_Embedded);
import baseService from './baseService';


export function getProfile(data){
    return baseService.get(`/User/Profile`);
}

export function updateProfile(data){
    return baseService.put(`/User/Profile`, data);
}


export function uploadImage(data){
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    return baseService.post(`/User/profileimage`,data,config);
}
export function getProfileImage(data){
    return baseService.get(`/User/profileimage`);
}



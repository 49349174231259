import React, { Component } from 'react'; //, useState
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import "./companydashboard.css";
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { LinkType, Base64 } from '../../app/common/commonServices';
// import { Table } from 'antd';
import ClearIcon from '@material-ui/icons/Clear';
import moment from 'moment';
import { GET_CompanyDashboard_BES } from "./../../appRedux/actions/CompanyDashboardAction";
import { Embebded_POST_updatecustomfield, PUT_QuickbookAccount_UpdateAccountStatus } from "./../../appRedux/actions/ReconcillitionAction";
import { getCompnay } from "./../../appRedux/actions/companiesAction";
import CircularProgress from "./../../components/CircularProgress";
import { toast } from "react-toastify";
import queryString from 'query-string';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import VimeoInstructionLoader from '../../app/common/VimeoInstructionLoader';
import RefreshDataModal from '../../components/RefreshDataModal'


const FontTooltip = withStyles((theme) => ({
    tooltip: {
        fontSize: 12
    },
}))(Tooltip);

class CompanyDashboard_Embeded extends Component {


    constructor(props) {
        super(props)
        this.sDateRef = React.createRef();
        let column = [{
            title: 'Account',
            dataIndex: 'accountName',
            className: 'table-heading',
            key: "accountName"
        },
        {
            title: 'Reconciled Through',
            dataIndex: 'reconciledThrough',
            className: 'table-heading',
            key: "reconciledThrough",
        },
        {
            title: 'Inactive Account',
            dataIndex: 'isExcludedReconciliation',
            className: 'table-heading',
            key: "isExcludedReconciliation",
        }];


        var Initaldata = {
            "lstAccountReconciliation": [],
            "preCheckIssue": {
                "missingVendorNameCount": 0,
                "missingVendorNameNewCommentCount": 0,
                "missingDescriptionCount": 0,
                "missingDescriptionNewCommentCount": 0,
                "oldOutstadingCount": 0,
                "oldOutstadingNewCommentCount": 0,
                missingReferenceNumberCount: 0,
                missingReferenceNumberCommentCount: 0,
                uncategorizedTransactionsCount: 0,
                reconciliationDiscrepanciesCount:0
            },
            "financialCheckup": {
                "needBetterDescriptionCount": 0,
                "needBetterDescriptionNewCommentCount": 0,
                "changeAccountNumberCount": 0,
                "changeAccountNumberNewCommentCount": 0,
                "accountsLineItemQueCommentsCount": 0,
                "accountsLineItemQueCommentsNewCommentCount": 0,
                "accountsGeneralQueCommentsCount": 0,
                "accountsGeneralQueCommentsNewCommentCount": 0,
                "otherQuestionsAndCommentsCommentsCount": 2,
                "otherQuestionsAndCommentsCount": 2,
                "otherQuestionsAndCommentsTaggedAsCPACommentsCount": 0,
                "otherQuestionsAndCommentsTaggedAsCPACount": 2,
            },
            "queriesForPayroll1099": {
                "assignTaxableFringeBenefitsToAnEmployeeCount": 0,
                "employeesMissingW4Count": 0,
                "pending4361FilingStatusForClergyCount": 0,
                "pendingBoardMeetingMinutesForHousingAllowanceCount": 0,
                "pendingEmployeeUseOfVehicleFormCount": 0,
                "updateEmployeeTypeCount": 0,
                "updateVendorTypeCount": 0,
                recordEmployeePaymentInQBPayrollSystemCommentsCount: 0,
                recordEmployeePaymentInQBPayrollSystemCount: 0,
                reviewIgnoredEmployeePaymentsCommentsCount: 0,
                reviewIgnoredEmployeePaymentsCount: 0,
                resolveExcessHousingAllowanceIssuesCount: 0,
                submitEmployeeUseOfVehicleFormCount: 0,
                recordEmployeeUseofVehicleCount: 0,
                recordEmployeeUseofVehicleTotalCount: 0,
                recordEmployeePaymentInPayrollSystemCommentsCount: 0,
                recordEmployeePaymentInPayrollSystemCount: 0,
                verifyListofMinistersCount: 0,
                verifyListofMinistersTotalCount: 0,
                assignTaxableFringeBenefitToCategoryCommentsCount: 0,
                assignTaxableFringeBenefitToCategoryCount: 0,
                assignTaxableFringeBenefitToEmployeeCommentsCount: 0,
                assignTaxableFringeBenefitToEmployeeCount: 0,
                assignTaxableFringeBenefitToEmployeeTotalCount: 0,
                recordTaxableFringeBenefitInPayrollSystemCommentsCount: 0,
                recordTaxableFringeBenefitInPayrollSystemCount: 0,
                recordTaxableFringeBenefitInPayrollSystemTotalCount: 0
            },
            queriesForVendor1099: {
                addtoEmployeeListCount: 0,
                approveWorkerClassifiedAsAnEmployeeCount: 0,
                approveWorkerClassifiedAsAnEmployeeTotalCount: 0,
                confirm1099WereMailedCount: 0,
                vendor1099MISCReadyToExportCount: 0,
                vendor1099NECReadyToExportCount: 0,
                vendor1099PendingAccountantApprovalCommentCount: 0,
                vendor1099PendingAccountantApprovalCount: 0,
                vendor1099PendingClientApprovalCommentCount: 0,
                vendor1099PendingClientApprovalCount: 0,
                vendor1099StatusReportCount: 0,
                vendorsMissingW9Count: 0,
                resolve1099VendorNameDiscrepanciesCount: 0,
                resolve1099VendorNameDiscrepanciesCommentCount: 0
            },
            allLastReviewedDatesModel: {
                lastGeneralLedgerReviewedDate: null,
                lastBalanceSheetReviewedDate: null,
                last1099ComplianceReviewedDate: null
            }
        };

        var currMonth = (new Date()).getMonth() + 1;


        this.state = {
            companyId: 0,
            companyName: "",
            getDashboardData: Initaldata,
            sDate: new Date(2019, 0, 1),
            eDate: new Date(),
            reconciledThroughDate: new Date(2019, 0, 1),
            columns: column,
            loading: false,
            IsToggleOpen: false,
            AR_Info: "",
            CompanyInfo: null,
            editIndex: -1,
            errorMsg: null,
            ShowRecEmpPayInPayrollCount: false,
            ShowRecEmpPayInQB: false,
            ShowVerifyMinister: false,//(currMonth === 2 || currMonth === 8) ? true : false,
            Show1099ComplianceIssues: false,
            ShowRecEmpThirdParty: false,
            isHumBurgerClickable : true,
            ShowPendingClientApproval: (currMonth === 1 || currMonth === 2 || currMonth === 3) ? true : false,
            ShowStatusReport: (currMonth === 1 || currMonth === 2 || currMonth === 3) ? true : false,
            ShowSubmitUseofVehicle: (currMonth === 11 || currMonth === 12 || currMonth === 1 || currMonth === 2) ? true : false,
            URLQueryParam:"",
            infusionsoftContactId:"",
            isRefreshDataModalOpen:false,
            DashboardData:false
        }
    }

    SetDisplayDate = (date) => {

        if (date != null && typeof date == "string") {
            date = new Date(date);
        }

        if (date == null || !typeof date.getHours === "function") {
            date = new Date();
        }

        const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date)
        const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(date)
        const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date)
        var hours = date.getHours();
        let mid = 'am';
        if (hours > 12) {
            mid = 'pm';
            hours = hours - 12;
        }

        return (da + " " + mo + ", " + ye + " - " + hours + ":" + date.getMinutes() + " " + mid);

    }

    getDashboardData(IsRefresh = false) {
        let state = this.state;
        let body = {
            companyId: state.companyId,
            fromDate: moment(state.sDate).format("MM/DD/YYYY"),
            toDate: moment(state.eDate).format("MM/DD/YYYY"),
            NeedUpdatQBORecords: IsRefresh
        }

        this.props.GET_CompanyDashboard_BES(body);
    }

    onRefreshDataClick = () => {
        // if (!this.state.loading) {
        //     this.setState({ loading: true }, () => {
        //         this.getDashboardData(true);
        //     });
        // }

        this.setState({isRefreshDataModalOpen:true})
    }

    CloseHeaderToggle = () => {
        this.setState({ IsToggleOpen: false });
    }


    OpenHeaderToggle = () => {
        this.setState({ IsToggleOpen: true });
    }

    getCompanyId = () => {
        let obj = {};
        let value = queryString.parse(this.props.location.search);
        localStorage.setItem("homeURL", window.location.href.replace(window.location.origin, ""))
        localStorage.setItem("q", JSON.stringify(value));
        try {
            if (value != null) {
                obj.companyId = parseInt(Base64.decode(value.q));
                obj.companyName = Base64.decode(value.p);
                obj.URLQueryParam = "?q="+value.q.toString()+"&p="+value.p.toString();
            }
        }
        catch (err) {
            console.log(err);
        }
        return obj;
    }

    accountStatusChange = (event, item, index) => {
        let isChecked = event.target.checked;
        if (parseInt(item.balance) !== 0 && !isChecked) {
            // toast.error("Error- This Account has a non-zero balance. you can not make as inactive.");
            this.setState({ errorMsg: "This account has a balance on your books. You may make this account inactive after the account balance is zero." }, () => {
                setTimeout(() => {
                    this.setState({ errorMsg: null });
                }, 6000);
            });
            //toast.error("Error- This account has a balance on your books  You may make this account inactive after the account balance is zero");
            return false;
        }
        let body = {
            "accountId": item.accountID,
            "isActive": isChecked
        }
        this.setState({ loading: true }, () => {
            this.props.PUT_QuickbookAccount_UpdateAccountStatus(body);
        });
    }

    handleDateChange = (e, target) => {
        if (this.state.editIndex > -1) {
            const editIndex = this.state.editIndex;
            var state = [...this.state.getDashboardData.lstAccountReconciliation];
            if (target === "fromDate") {
                this.setState({ reconciledThroughDate: new Date(e) }, () => {
                    var obj = {
                        "accountId": state[editIndex].accountID,
                        "reconciledThrough": moment(this.state.reconciledThroughDate).format('YYYY-MM-DD'),
                        "isExcludedReconciliation": state[editIndex].isExcludedReconciliation
                    }
                    this.setState({ loading: true }, () => {
                        this.props.Embebded_POST_updatecustomfield(obj);
                    });
                });
                // this.setState({ reconciledThroughDate: moment(new Date(e)).format("MM/DD/YYYY") });
            }
        }
    }

    setErrorMessage = () => {
        this.setState({ errorMsg: null });
    }

    handleFocus = (e, selecter) => {
        let control = null;
        if (selecter !== undefined) {
            if (selecter === "sDate") {
                control = this.sDateRef;
                if (control.current != null) {
                    control.current.setFocus();
                }
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.companyDashboardData !== this.props.companyDashboardData) {
            var returnObj = nextProps.companyDashboardData;
            if (returnObj.transactionStatus === 0) {
                var ShowRecEmpPay = false;
                var showEmpPayInQB = false;
                var showMinister = false;
                var showEmpThirdParty = false;
                var infusionsoftContactId = "";
                try {
                    if (returnObj.returnObject.company.payrollSoftware != null &&
                        (returnObj.returnObject.company.payrollSoftware === "ADP Full Service Payroll"
                            || returnObj.returnObject.company.payrollSoftware === "Other Full Service Payroll"
                        )
                    ) {
                        ShowRecEmpPay = true;
                    }

                    if (returnObj.returnObject.company.payrollSoftware != null &&
                        returnObj.returnObject.company.payrollSoftware.toLowerCase().indexOf("quickbook") > -1
                    ) {
                        showEmpPayInQB = true;
                    }

                    if (returnObj.returnObject.company.payrollSoftware != null &&
                        (returnObj.returnObject.company.payrollSoftware.toLowerCase().indexOf("quickbook") === -1
                          && returnObj.returnObject.company.payrollSoftware != "None"
                        )
                    ) {
                        //var a = returnObj.returnObject.company.payrollSoftware.toLowerCase().indexOf("quickbook");
                        showEmpThirdParty = true;
                    }


                    if (returnObj.returnObject.company.isReligeousOrganization != null) {
                        showMinister = returnObj.returnObject.company.isReligeousOrganization;
                    }

                    if (returnObj.returnObject.company.infusionsoftContactId != null) {
                        infusionsoftContactId = returnObj.returnObject.company.infusionsoftContactId;
                    }
                }
                catch (err) { }

                // var currentMonth = (new Date()).getMonth();
                // if (showEmpPayInQB && (currentMonth !== 2 || currentMonth !== 8)) {
                //     showMinister = false;
                // }
             
                // if(DateForMinisterList >= CurrDate){
                //     showMinister = true
                // }
                var currMonth = (new Date()).getMonth() + 1;
                var CurrDate = new Date();
                var ToDateForMinisterList =null;
                var FromDateForMinisterList =null;
                if(currMonth === 11){
                    ToDateForMinisterList = new Date((new Date()).getFullYear(),11-1,1);
                    FromDateForMinisterList = new Date(ToDateForMinisterList.getFullYear() + 1,2-1,10);
                }else if(currMonth === 1 || currMonth === 2){
                    ToDateForMinisterList = new Date((new Date()).getFullYear() - 1,11-1,1);
                    FromDateForMinisterList = new Date((new Date()).getFullYear(),2-1,10);
                }
                if(CurrDate >= ToDateForMinisterList && CurrDate <= FromDateForMinisterList ){
                    showMinister = true;
                }else{
                    showMinister = false
                }

                // var DateForMinisterList = new Date((new Date()).getFullYear(),11-1,1);
                // var CurrDate = new Date();
                // if(DateForMinisterList <= CurrDate){
                //     showMinister = true;
                // }else{
                //     showMinister = false
                // }

                if(returnObj.returnObject.isDateWiseApprovedMinister){
                    showMinister = false
                }
                this.setState({
                    getDashboardData: returnObj.returnObject,
                    ShowRecEmpPayInPayrollCount: ShowRecEmpPay,
                    ShowRecEmpPayInQB: showEmpPayInQB,
                    ShowVerifyMinister: showMinister,
                    ShowRecEmpThirdParty:showEmpThirdParty,
                    Show1099ComplianceIssues: returnObj.returnObject.is1099Payrollqueries,
                    LastUpdated: this.SetDisplayDate(returnObj.returnObject.lastSyncDate),
                    AR_Info: returnObj.returnObject.infAccountReconcilation,
                    infusionsoftContactId: infusionsoftContactId
                }, () => {
                    this.setState({ loading: false,DashboardData:false });
                });
            } else {
                this.setState({ loading: false,DashboardData:false }, () => {
                    toast.error(`${returnObj.resultMsg}`);
                });
            }

        }
        else if (nextProps.company !== this.props.company) {
            let name = this.state.companyName;
            if (nextProps.company != null) {
                name = nextProps.company.name + (nextProps.company.city != null ? ", " + nextProps.company.city : "") + (nextProps.company.state != null ? ", " + nextProps.company.state : "");
            }

            this.setState({ loading: false, CompanyInfo: nextProps.company, companyName: name });
        } else if (nextProps.Update_reconciled !== this.props.Update_reconciled) {
            this.setState({ loading: true, editIndex: -1 }, () => {
                this.getDashboardData();
            });
        } else if (nextProps.account_Inactive !== this.props.account_Inactive) {
            this.setState({ loading: true,DashboardData:true, editIndex: -1 }, () => {
                this.getDashboardData();
            });
        }
    }

    editReconcilThroughDate = (e, index, item) => {
    
        this.setState({ editIndex: index, reconciledThroughDate: item.reconciledThrough && (item.reconciledThrough.toLowerCase()).indexOf("never") <-1 && (item.reconciledThrough.toLowerCase()).indexOf("pending") <-1? new Date(item.reconciledThrough) : new Date() });
    }

    onClickCloseButtonDate = (e) => {
        this.setState({ editIndex: -1, reconciledThroughDate: new Date() });
    }


    DownloadReport = () => {
        toast.success("This will be Coming soon");
    }

    OpenLink = (link) => {

        // if (this.state.CompanyInfo != null && this.state.CompanyInfo.infusionsoftContactId != null && this.state.CompanyInfo.infusionsoftContactId !== "") {
        if (link === "RC") {
            if (this.state.CompanyInfo != null && this.state.CompanyInfo.infusionsoftContactId != null && this.state.CompanyInfo.infusionsoftContactId !== "") {
                // window.open("https://workplace.churchcpa.com?i4w_autologin=09cccd&force_login=09cccd&default_pass=secret&Id=" + this.state.CompanyInfo.infusionsoftContactId);
                window.open("https://access.churchcpa.com?i4w_autologin=09cccd&force_login=09cccd&default_pass=secret&Id=" + this.state.CompanyInfo.infusionsoftContactId);
            }
            else {
                toast.error("Infusionsoft ContactID not found");
            }
        }
        else if (link === "SC_APNT") {
            //console.log('UIRL', `https://churchcpa.com/workplace/?i4w_autologin=09cccd&force_login=09cccd&default_pass=secret&Id=${this.state.CompanyInfo.infusionsoftContactId}&redir=https://churchcpa.com/workplace/schedule-an-appointment/?soisContactID=${this.state.CompanyInfo.infusionsoftContactId}`)
            window.open("https://go.oncehub.com/churchcpa");
        }
        else if (link === "TS_SUMM") {
            //console.log('UIRL', `https://churchcpa.com/workplace/?i4w_autologin=09cccd&force_login=09cccd&default_pass=secret&Id=${this.state.CompanyInfo.infusionsoftContactId}&redir=https://churchcpa.com/workplace/schedule-an-appointment/?soisContactID=${this.state.CompanyInfo.infusionsoftContactId}`)
            window.open("https://vps.churchcpa.com/technicalsupport/index.php?id="+this.state.infusionsoftContactId);
        }
        
        // }
        // else {
        //     toast.error("Error- Infusionsoft ContactID not found");
        // }
    }

    componentDidMount() {

        var company = this.getCompanyId();
        if (company == null || company === undefined || isNaN(company.companyId) || company.companyId <= 0) {
            const { history } = this.props;
            history.push('/401');
        }

        this.setState({
            companyId: company.companyId,
            companyName: company.companyName,
            URLQueryParam:company.URLQueryParam,
            loading: true,DashboardData:true
        }, () => {
            this.getDashboardData();
            this.props.getCompnay(company.companyId);
        });
    }

    onClickHumBerger = () => {
        if(this.state.isHumBurgerClickable){
            this.setState({ isHumBurgerClickable: false });
        }else{
            this.setState({ isHumBurgerClickable: true });
        }
    }

    onClickRoute = (pagePath) => {
        
        const { history } = this.props;
        history.push({
            pathname: `${pagePath}`,
            search: this.state.URLQueryParam,
            state: { data: 'some data' }
          });
    }

    handleRefreshDataModal=()=>{
        this.setState({isRefreshDataModalOpen:false})
      }
    
      refreshData=()=>{
        if (!this.state.loading) {
            this.setState({ loading: true,DashboardData:true,isRefreshDataModalOpen:false }, () => {
                this.getDashboardData(true);
            });
        }

      }
    render() {
console.log("object",this.state);
        var preCheckIssue = this.state.getDashboardData.preCheckIssue;
        var financialCheckup = this.state.getDashboardData.financialCheckup;
        var allLastReviewedDatesModel = this.state.getDashboardData.allLastReviewedDatesModel;
        return (
            <React.Fragment>

                <RefreshDataModal visible={this.state.isRefreshDataModalOpen}
      handlePopUpClose={this.handleRefreshDataModal} refreshData={this.refreshData}/>
                <ToastContainer autoClose={3000} />
                <div className={this.state.isHumBurgerClickable ? 'main-box menu-active' : 'main-box'}>
               <div className='left-menu-box'>  
               <div className='hamburger-menu'>           
             <a href='#' className='humburger' onClick={() => this.onClickHumBerger()}><i class="zmdi zmdi-sort-amount-desc"></i></a> 
               </div>  
                <div className='left-menu'>
                    <ul>
                       
                        <li className='video-btn'>
                                            <VimeoInstructionLoader 
                                               //URL={"https://vimeo.com/687402925"} 
                                               URL={"https://player.vimeo.com/video/687402925?h=df22da040e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"} 
                                               buttonName="Video Tour"
                                               PageName="Dashboard"
                                               buttonColor="orangeColor"
                                               IsMenuVedio={true}
                                            ></VimeoInstructionLoader>
                            {/* <VimeoInstructionLoader 
                                URL={"https://player.vimeo.com/video/687402925?h=df22da040e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"} 
                                buttonName="Video Tour"
                                buttonColor="graycolor"
                                
                                IsMenuVedio={true}
                                >
                                    
                                    
                                    </VimeoInstructionLoader> */}
                                    </li>
                           

                        <li>
                        <Button onClick={() => this.OpenLink("RC")} variant="raised" className="btn-transparent"> <img src="/vendors/searchicon.png"></img><span className='btn-name'>Resource Center</span></Button>    
                            </li>
                        <li>
                        <Button onClick={() => this.OpenLink("SC_APNT")} className="btn-transparent" style={{textTransform:"inherit"}}><img src='vendors/Calendar-icon.png'></img><span className='btn-name'>Schedule an Appointment</span></Button>
                           </li>
                        <li>
                        <Button variant="raised" className="btn-transparent" onClick={() => this.onRefreshDataClick()}><img src='vendors/Reload.png'></img><span className='btn-name'>Refresh Data</span></Button>  
                           </li>
                           <li>
                        <Button onClick={() => this.OpenLink("TS_SUMM")} variant="raised" className="btn-transparent" ><img src='vendors/techsupporticon.png'></img><span className='btn-name'>Technical Support Summary</span></Button>
                           </li>
                    </ul>
                </div>
               </div>
                <div className={this.state.IsToggleOpen ? "dashboardpage-menu dashboardPage embeded-dashboard active-box" : "dashboardpage-menu dashboardPage embeded-dashboard"}>
                    {this.state.loading || this.state.DashboardData  ? <CircularProgress></CircularProgress>
                        :
                        <React.Fragment>
                            <div className="custome-modal">
                                <div className="close-btn-box">
                                    <Button variant="raised" className=" humburger-menu" onClick={() => this.CloseHeaderToggle()}>
                                        <span className="humburger closebtn-menu"></span>
                                        <span className="humburger closebtn-menu"></span>
                                    </Button>
                                </div>
                                <div className="modal-detail-box">
                                    <ul className="modal-detail-box">
                                        <li><a href="#">Resource Center</a></li>
                                        <li><a href="">Schedule an Appointment</a></li>
                                    </ul>
                                   
                                    {/* <div className="">
                                            <Button variant="raised" className="share-btn jr-btn bg-teal text-white refresh-btn">
                                                <img src="Download-img.png" className="download-icon" alt="download"></img>
                                                Download the Report</Button>
                                        </div> */}
                                </div>
                            </div>


                            <div className="page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
                                <h2 className="title mb-3 mb-sm-0">{this.state.companyName} </h2>
                                {/* <div className="hamburger-menu-box">
                                    <Button variant="raised" className="jr-btn bg-teal text-white humburger-menu" onClick={() => this.OpenHeaderToggle()} >
                                        <span className="humburger"></span>
                                        <span className="humburger"></span>
                                        <span className="humburger"></span>
                                    </Button>
                                </div> */}
                            </div>
                            <div className="padding-box app-wrapper">
                                <div className="row" style={{marginBottom:"-25px"}}>
                                    <div className="col-md-5">
                                       

                                        {/* <div className="float-right" style={{marginRight:"-80px"}}>
                                            <p style={{marginBottom:"-5px",fontWeight:"500"}}>Last General Review Period End Date: <span style={{marginLeft:"70px"}}> {allLastReviewedDatesModel.lastGeneralLedgerReviewedDate !=null ? moment(allLastReviewedDatesModel.lastGeneralLedgerReviewedDate).format("MM/DD/YYYY") : ""}</span></p>
                                            <p style={{marginBottom:"-5px",fontWeight:"500"}}>Last Balance Sheet Review Period End Date: <span style={{marginLeft:"28px"}}> {allLastReviewedDatesModel.lastBalanceSheetReviewedDate !=null ? moment(allLastReviewedDatesModel.lastBalanceSheetReviewedDate).format("MM/DD/YYYY") : ""}</span></p>
                                            <p style={{fontWeight:"500"}}>Last 1099 Compliance Review Date: <span style={{marginLeft:"79px"}}>{allLastReviewedDatesModel.last1099ComplianceReviewedDate !=null ? moment(allLastReviewedDatesModel.last1099ComplianceReviewedDate).format("MM/DD/YYYY") : ""}</span></p>
                                        </div> */}
                                    </div>
                                    {/* <div className="col-md-7">
                                        <div className="float-right">
                                            <Button onClick={() => this.OpenLink("SC_APNT")} variant="raised" className="jr-btn bg-teal text-white  refresh-btn schedule-appointment" > <img src="/vendors/Calendar.png" alt="download" className="download-icon" ></img>Schedule an Appointment</Button>
                                            <Button onClick={() => this.OpenLink("RC")} variant="raised" className="jr-btn bg-teal text-white refresh-btn resource-center  "><img src="/vendors/Setting.png" className="download-icon" alt="" ></img>Resource Center </Button>
                                            <VimeoInstructionLoader 
                                               //URL={"https://vimeo.com/687402925"} 
                                               URL={"https://player.vimeo.com/video/687402925?h=df22da040e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"} 
                                               buttonName="Video Tour"
                                               PageName="Dashboard"
                                               buttonColor="orangeColor"
                                            ></VimeoInstructionLoader>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="row mt-4 company_Dashboard_comment_count">
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6" >

                                    <div className="page-heading panel-box">
                                            <h2 className="panel-title panel-bg">
                                                <img src="vendors/Tick-mark.png" alt="checkmark" className="check-icon"></img> Financial Statements
                                                
                                            </h2>
                                            <div className="panel-detail-box">
                                            <div className="row">
                                                {/* <div className="col-md-4" style={{ color: "#46b29d" }}>
                                                    <div className="panel-left-box">
                                                        <img alt="" src="/vendors/Payroll-1099-Compliance-Issues.png"></img>
                                                        <h2 className="panel-sub-title">Payroll</h2>
                                                        <p className="panel-parag">Compliance Issues</p>
                                                    </div>
                                                </div> */}
                                                <div className="col-md-12" >                                            
                                                        <a className="panel-detail" href='https://app.qbo.intuit.com/app/reportv2?token=PANDL' target='_blank'>
                                                        <div className="panel-detail-content col-md-11">
                                                            <p> Profit and Loss Statement </p>
                                                        </div>
                                                        <div className="right-icon col-md-1">
                                                            <img alt="" className="icon-img" src="/vendors/Link-gray.png"></img>
                                                            <img alt="" className="hover-icon" src="/vendors/Link-darkBlue.png"></img>
                                                        </div>
                                                    </a>
                                                   
                                                </div>
                                                <div className="col-md-12" >                                            
                                                        <a className="panel-detail" href='https://app.qbo.intuit.com/app/reportv2?token=BAL_SHEET' target='_blank'>
                                                        <div className="panel-detail-content col-md-11">
                                                            <p> Balance Sheet </p>
                                                        </div>
                                                        <div className="right-icon col-md-1">
                                                            <img alt="" className="icon-img" src="/vendors/Link-gray.png"></img>
                                                            <img alt="" className="hover-icon" src="/vendors/Link-darkBlue.png"></img>
                                                        </div>
                                                    </a>
                                                   
                                                </div><div className="col-md-12" > 
                                                <div className="panel-detail panel-detail-content col-md-12">
                                                <p> Please request our team to review your financial reports before you submit them to your board or any financial institution.
                                                Make sure all accounts are reconciled and financial check-up issues have been resolved.</p>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                          
                                        </div>


                                    <div className="page-heading panel-box">
                                            <div className='clientdash-reconcilation d-flex panel-title panel-bg'>
                                                <h2>
                                                    <img src="/vendors/Tick-mark.png" alt="checkmark" className="check-icon"></img>Compliance Reviews                                              {/* Pending Current Quarter Account Reconciliations */}
                                                </h2>
                                                
                                            </div>
                                            
                                            <div className="panel-detail-box">                                              
                                                 <div className="panel-detail" >                                                           
                                                                <div className="panel-detail-content col-md-9">
                                                                    <p>Last General Review Period End Date:</p>
                                                                </div>                                                               
                                                                <div className="right-icon col-md-3 text-right compliance-review-text">
                                                                {allLastReviewedDatesModel.lastGeneralLedgerReviewedDate !=null ? moment(allLastReviewedDatesModel.lastGeneralLedgerReviewedDate).format("MM/DD/YYYY") : ""}
                                                                </div>                                                            
                                                        </div>
                                                        <div className="panel-detail" >                                                           
                                                                <div className="panel-detail-content col-md-9">
                                                                    <p>Last Balance Sheet Review Period End Date:</p>
                                                                </div>                                                               
                                                                <div className="right-icon col-md-3 text-right compliance-review-text">
                                                                {allLastReviewedDatesModel.lastBalanceSheetReviewedDate !=null ? moment(allLastReviewedDatesModel.lastBalanceSheetReviewedDate).format("MM/DD/YYYY") : ""}
                                                                </div>                                                            
                                                        </div>
                                                        <div className="panel-detail" >                                                           
                                                                <div className="panel-detail-content col-md-9">
                                                                    <p>Last 1099 Compliance Review Date:</p>
                                                                </div>                                                               
                                                                <div className="right-icon col-md-3 text-right compliance-review-text">
                                                                {allLastReviewedDatesModel.last1099ComplianceReviewedDate !=null ? moment(allLastReviewedDatesModel.last1099ComplianceReviewedDate).format("MM/DD/YYYY") : ""}
                                                                </div>                                                            
                                                        </div>
                                              
                                                
                                            </div>
                                        </div>
                                       
                                        <div className="page-heading panel-box finanical-check-up " >
                                            <h2 className="panel-title bg-color-panel">
                                                <img src="/vendors/Tick-mark.png" className="check-icon" alt=""></img> Financial Check-Up</h2>
                                            <div className="panel-detail-box">
                                                <div className="row">
                                                    {/* <div className="col-md-4" style={{ color: "#46b29d" }}>
                                                        <div className="panel-left-box">
                                                            <img src="/vendors/Financial-Check-up.png" alt=""></img>
                                                            <h2 className="panel-sub-title" style={{ color: "#46b29d" }} >Financial</h2>
                                                            <p className="panel-parag">  CHECK-UP</p>
                                                        </div>
                                                    </div> */}
                                                    <div className="col-md-12" >
                                                        <div>
                                                            <a className="panel-detail" onClick={() => this.onClickRoute('/companyDashboard/NBD')}>
                                                                <div className="panel-detail-content col-md-9">
                                                                    <h4>{financialCheckup.needBetterDescriptionCount}</h4>
                                                                    <p>{LinkType.TranNBD}</p>
                                                                </div>
                                                                <div className="panel-commentCount col-md-2" >
                                                                    <img style={{ height: '25px' }} className={financialCheckup.needBetterDescriptionNewCommentCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/green_comments.png" alt="comments"></img>
                                                                    {financialCheckup.needBetterDescriptionNewCommentCount !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">{financialCheckup.needBetterDescriptionNewCommentCount}</span>}
                                                                </div>
                                                                <div className="right-icon col-md-1">
                                                                    <img className="icon-img" src="/vendors/gray-arrow.png" alt=""></img>
                                                                    <img className="hover-icon" src="/vendors/Financial-check-up-arrow.png" alt=""></img>
                                                                </div>
                                                            </a>
                                                        </div>
                                                        <div>
                                                            <a className="panel-detail" onClick={() => this.onClickRoute('/companyDashboard/CAN')}>
                                                                <div className="panel-detail-content col-md-9">
                                                                    <h4>{financialCheckup.changeAccountNumberCount}</h4>
                                                                    <p>{LinkType.ChangeACNum}</p>
                                                                </div>
                                                                <div className="panel-commentCount col-md-2" >
                                                                    <img style={{ height: '25px' }} className={financialCheckup.changeAccountNumberNewCommentCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/green_comments.png" alt="comments"></img>
                                                                    {financialCheckup.changeAccountNumberNewCommentCount !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">{financialCheckup.changeAccountNumberNewCommentCount}</span>}
                                                                </div>
                                                                <div className="right-icon col-md-1">
                                                                    <img className="icon-img" src="/vendors/gray-arrow.png" alt=""></img>
                                                                    <img className="hover-icon" src="/vendors/Financial-check-up-arrow.png" alt=""></img>
                                                                </div>
                                                            </a>
                                                        </div>
                                                        <div>
                                                            <a className="panel-detail" onClick={() => this.onClickRoute('/companyDashboard/AACL')}>
                                                                <div className="panel-detail-content col-md-9">
                                                                    <h4>{financialCheckup.accountsLineItemQueCommentsCount}</h4>
                                                                    <p>  {LinkType.ACCLineItem}</p>
                                                                </div>
                                                                <div className="panel-commentCount col-md-2" >
                                                                    <img style={{ height: '25px' }} className={financialCheckup.accountsLineItemQueCommentsNewCommentCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/green_comments.png" alt="comments"></img>
                                                                    {financialCheckup.accountsLineItemQueCommentsNewCommentCount !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">{financialCheckup.accountsLineItemQueCommentsNewCommentCount}</span>}
                                                                </div>
                                                                <div className="right-icon col-md-1">
                                                                    <img className="icon-img" src="/vendors/gray-arrow.png" alt=""></img>
                                                                    <img className="hover-icon" src="/vendors/Financial-check-up-arrow.png" alt=""></img>
                                                                </div>
                                                            </a>
                                                        </div>
                                                        <div>
                                                            <a className="panel-detail" onClick={() => this.onClickRoute('/companyDashboard/GQA')}>
                                                                <div className="panel-detail-content col-md-9">
                                                                    <h4>{financialCheckup.otherQuestionsAndCommentsCount}</h4>
                                                                    <p> {LinkType.OtherQueComm}</p>
                                                                </div>
                                                                <div className="panel-commentCount col-md-2" >
                                                                    <img style={{ height: '25px' }} className={financialCheckup.otherQuestionsAndCommentsCommentsCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/green_comments.png" alt="comments"></img>
                                                                    {financialCheckup.otherQuestionsAndCommentsCommentsCount !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">{financialCheckup.otherQuestionsAndCommentsCommentsCount}</span>}
                                                                </div>
                                                                <div className="right-icon col-md-1">
                                                                    <img className="icon-img" src="/vendors/gray-arrow.png" alt=""></img>
                                                                    <img className="hover-icon" src="/vendors/Financial-check-up-arrow.png" alt=""></img>
                                                                </div>
                                                            </a>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.Show1099ComplianceIssues &&
                                            <div className="page-heading panel-box payroll-comliance-box" >
                                                <h2 className="panel-title bg-color-panel">
                                                    <img src="/vendors/Tick-mark.png" className="check-icon" alt="CheckMark"></img> Payroll Compliance Issues</h2>
                                                <div className="panel-detail-box">
                                                    <div className="row">
                                                        {/* <div className="col-md-4" style={{ color: "#46b29d" }}>
                                                            <div className="panel-left-box">
                                                                <img src="/vendors/Payroll-1099-Compliance-Issues.png" alt="compliance"></img>
                                                                <h2 className="panel-sub-title">Payroll</h2>
                                                                <p className="panel-parag">Compliance Issues</p>
                                                            </div>
                                                        </div> */}
                                                        <div className="col-md-12">
                                                            {!this.state.ShowRecEmpPayInPayrollCount &&
                                                            <a className="panel-detail" onClick={() => this.onClickRoute('/companyDashboard/EMW4')}>
                                                                <div className="panel-detail-content col-md-11">
                                                                    <h4>{this.state.getDashboardData.queriesForPayroll1099.employeesMissingW4Count}</h4>
                                                                    <p> {LinkType.EmployeeMissW4}</p>
                                                                </div>
                                                                <div className="right-icon col-md-1">
                                                                    <img className="icon-img" src="/vendors/gray-arrow.png" alt="arrow"></img>
                                                                    <img className="hover-icon" src="/vendors/Payroll-Arrow.png" alt="payroll"></img>
                                                                </div>
                                                            </a>
                                                            }
                                                            {this.state.ShowRecEmpThirdParty &&
                                                            <a className="panel-detail" onClick={() => this.onClickRoute('/companyDashboard/RCPC')}>
                                                                <div className="panel-detail-content col-md-9">
                                                                    <h4>{this.state.getDashboardData.queriesForPayroll1099.recordCheckInPayrollSystemCount}</h4>
                                                                    <p>  Record Check in Payroll System</p>
                                                                </div>
                                                                <div className="panel-commentCount col-md-2" >
                                                                        <img style={{ height: '25px' }} className={this.state.getDashboardData.queriesForPayroll1099.recordCheckInPayrollSystemCommentsCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/orange_comments.png" alt="comments"></img>
                                                                    {this.state.getDashboardData.queriesForPayroll1099.recordCheckInPayrollSystemCommentsCount !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">{this.state.getDashboardData.queriesForPayroll1099.recordCheckInPayrollSystemCommentsCount}</span>}
                                                                </div>
                                                                <div className="right-icon col-md-1">
                                                                    <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                                    <img alt="" className="hover-icon" src="/vendors/Payroll-Arrow.png"></img>
                                                                </div>
                                                            </a>
                                                            }
                                                            {/* {this.state.ShowRecEmpPayInQB &&
                                                                <a className="panel-detail" onClick={() => this.onClickRoute('/companyDashboard/REP')}>
                                                                    <div className="panel-detail-content col-md-9">
                                                                        <h4>{this.state.getDashboardData.queriesForPayroll1099.recordEmployeePaymentInQBPayrollSystemCount}</h4>
                                                                        <p> Record Employee Payment in QB Payroll System</p>
                                                                    </div>
                                                                    <div className="panel-commentCount col-md-2" >
                                                                        <img style={{ height: '25px' }} className={this.state.getDashboardData.queriesForPayroll1099.recordEmployeePaymentInQBPayrollSystemCommentsCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/orange_comments.png" alt="comments"></img>
                                                                        {this.state.getDashboardData.queriesForPayroll1099.recordEmployeePaymentInQBPayrollSystemCommentsCount !== 0 && <span className="badge badge-pill badge-danger  ng-star-inserted">{this.state.getDashboardData.queriesForPayroll1099.recordEmployeePaymentInQBPayrollSystemCommentsCount}</span>}
                                                                    </div>
                                                                    <div className="right-icon col-md-1">
                                                                        <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                                        <img alt="" className="hover-icon" src="/vendors/Payroll-Arrow.png"></img>
                                                                    </div>
                                                                </a>
                                                            } */}
                                                            {this.state.ShowSubmitUseofVehicle &&
                                                            <a className="panel-detail" onClick={() => this.onClickRoute('/companyDashboard/EVF')}>
                                                                <div className="panel-detail-content col-md-9">
                                                                    <h4>{this.state.getDashboardData.queriesForPayroll1099.submitEmployeeUseOfVehicleFormCount}</h4>
                                                                    <p>{LinkType.EmployeeVehicleForm}</p>
                                                                </div>
                                                                <div className="panel-commentCount col-md-2" >
                                                                    {/* <img style={{ height: '25px' }} className={this.state.getDashboardData.queriesForPayroll1099.recordCheckInPayrollSystemCommentsCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/orange_comments.png" alt="comments"></img>
                                                                {this.state.getDashboardData.queriesForPayroll1099.recordCheckInPayrollSystemCommentsCount !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">0</span>} */}
                                                                </div>
                                                                <div className="right-icon col-md-1">
                                                                    <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                                    <img alt="" className="hover-icon" src="/vendors/Payroll-Arrow.png"></img>
                                                                </div>
                                                                </a>
                                                             }
                                                            {this.state.ShowRecEmpPayInPayrollCount &&
                                                                <a className="panel-detail" onClick={() => this.onClickRoute('/companyDashboard/REPPE')}>
                                                                    <div className="panel-detail-content col-md-9">
                                                                        <h4>{this.state.getDashboardData.queriesForPayroll1099.recordEmployeePaymentInPayrollSystemCount}</h4>
                                                                        <p>Record Employee Payment in Payroll System</p>
                                                                    </div>
                                                                    <div className="panel-commentCount col-md-2" >
                                                                        <img style={{ height: '25px' }} className={this.state.getDashboardData.queriesForPayroll1099.recordEmployeePaymentInPayrollSystemCommentsCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/orange_comments.png" alt="comments"></img>
                                                                        {this.state.getDashboardData.queriesForPayroll1099.recordEmployeePaymentInPayrollSystemCommentsCount !== 0 && <span className="badge badge-pill badge-danger  ng-star-inserted">{this.state.getDashboardData.queriesForPayroll1099.recordEmployeePaymentInPayrollSystemCommentsCount}</span>}
                                                                    </div>
                                                                    <div className="right-icon col-md-1">
                                                                        <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                                        <img alt="" className="hover-icon" src="/vendors/Payroll-Arrow.png"></img>
                                                                    </div>
                                                                </a>
                                                            }
                                                            {this.state.ShowVerifyMinister &&
                                                                <a className="panel-detail"  onClick={() => this.onClickRoute('/companyDashboard/MINL')}>
                                                                    <div className="panel-detail-content col-md-9">
                                                                        {/* <h4>{this.state.getDashboardData.queriesForPayroll1099.verifyListofMinistersCount}/{this.state.getDashboardData.queriesForPayroll1099.verifyListofMinistersTotalCount} </h4> */}
                                                                        <h4>{this.state.getDashboardData.queriesForPayroll1099.verifyListofMinistersCount} </h4>
                                                                        <p>Verify List of Ministers</p>
                                                                    </div>
                                                                    <div className="panel-commentCount col-md-2" >
                                                                        {/* <img style={{ height: '25px' }} className={this.state.getDashboardData.queriesForPayroll1099.recordCheckInPayrollSystemCommentsCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/orange_comments.png" alt="comments"></img>
                                                                {this.state.getDashboardData.queriesForPayroll1099.recordCheckInPayrollSystemCommentsCount !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">0</span>} */}
                                                                    </div>
                                                                    <div className="right-icon col-md-1">
                                                                        <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                                        <img alt="" className="hover-icon" src="/vendors/Payroll-Arrow.png"></img>
                                                                    </div>
                                                                </a>
                                                            }
                                                            <a className="panel-detail" onClick={() => this.onClickRoute('/companyDashboard/APEM')}>
                                                                <div className="panel-detail-content col-md-9">
                                                                    {/* <h4>{this.state.getDashboardData.queriesForVendor1099.approveWorkerClassifiedAsAnEmployeeCount}/
                                                            {this.state.getDashboardData.queriesForVendor1099.approveWorkerClassifiedAsAnEmployeeTotalCount}</h4> */}
                                                                    <h4>{this.state.getDashboardData.queriesForVendor1099.approveWorkerClassifiedAsAnEmployeeCount}</h4>
                                                                    <p>{LinkType.ApproveWorker}</p>
                                                                </div>
                                                                <div className="panel-commentCount col-md-2" >
                                                                    {/* <img style={{ height: '25px' }} className={this.state.getDashboardData.queriesForPayroll1099.recordCheckInPayrollSystemCommentsCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/orange_comments.png" alt="comments"></img>
                                                                {this.state.getDashboardData.queriesForPayroll1099.recordCheckInPayrollSystemCommentsCount !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">0</span>} */}
                                                                </div>
                                                                <div className="right-icon col-md-1">
                                                                    <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                                    <img alt="" className="hover-icon" src="/vendors/Payroll-Arrow.png"></img>
                                                                </div>
                                                            </a>
                                                            <a className="panel-detail" onClick={() => this.onClickRoute('/companyDashboard/TFBEMP')}>
                                                                <div className="panel-detail-content col-md-9">
                                                                    {/* <h4> {this.state.getDashboardData.queriesForPayroll1099.assignTaxableFringeBenefitToEmployeeCount} /
                                                                    {this.state.getDashboardData.queriesForPayroll1099.assignTaxableFringeBenefitToEmployeeTotalCount}
                                                                    </h4> */}
                                                                    <h4> {this.state.getDashboardData.queriesForPayroll1099.assignTaxableFringeBenefitToEmployeeCount} 
                                                                    </h4>
                                                                    <p>{LinkType.AssignTFBToEmp}</p>
                                                                </div>
                                                                <div className="panel-commentCount col-md-2" >
                                                                    <img style={{ height: '25px' }} className={this.state.getDashboardData.queriesForPayroll1099.assignTaxableFringeBenefitToEmployeeCommentsCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/orange_comments.png" alt="comments"></img>
                                                                    {this.state.getDashboardData.queriesForPayroll1099.assignTaxableFringeBenefitToEmployeeCommentsCount !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">{this.state.getDashboardData.queriesForPayroll1099.assignTaxableFringeBenefitToEmployeeCommentsCount}</span>}
                                                                </div>
                                                                <div className="right-icon col-md-1">
                                                                    <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                                    <img alt="" className="hover-icon" src="/vendors/Payroll-Arrow.png"></img>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div className="page-heading panel-box">
                                            <div className='clientdash-reconcilation d-flex panel-title panel-bg'>
                                                <h2>
                                                    <img src="/vendors/Tick-mark.png" alt="checkmark" className="check-icon"></img> Past Due Account Reconciliations 
                                                    {/* Pending Current Quarter Account Reconciliations */}
                                                </h2>
                                                <div className="clientdash-reconcilation-intruction-btn" >
                                                        <VimeoInstructionLoader 
                                                           //URL={"https://vimeo.com/687625248"} 
                                                           URL = "https://player.vimeo.com/video/687625248?h=df22da040e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                                                           buttonName="Instructions"
                                                           buttonColor="graycolor"
                                                           PageName="Pending Account Reconciliations"
                                                           IsMenuVedio={false}
                                                        ></VimeoInstructionLoader>
                                                </div>
                                            </div>
                                            
                                            <div className="panel-detail-box">
                                                <div className="account-box-main">
                                                    {/* <div className="accunt-name-box">
                                                        <div className="account-img">
                                                            <img src="/vendors/Account-Reconciliation-Status.png" alt="Reconciliation"></img>
                                                        </div>
                                                        <div className="account-title-box" style={{ marginLeft: 20 }}>
                                                            <h2 className="panel-sub-title accuntant-text-color">Past Due</h2>
                                                            <p className="panel-parag"> Account Reconciliations</p>
                                                          
                                                        </div>
                                                    
                                                    </div>
                                                   */}
                                                   
                                                </div>
                                                
                                           
                                                {/* <FontTooltip className="float-right" id="tooltip-controlled" title={this.state.AR_Info} placement="bottom">
                                                        <div className="icon">
                                                            <i className="zmdi zmdi-info-outline"></i>
                                                        </div>
                                                </FontTooltip> */}
                                                <h5 style={{ marginTop: "20px" }}>
                                                    <strong>{this.state.AR_Info}</strong>
                                                </h5>   
                                                {/* <div className="float-right" style={{marginRight: "10px",marginTop:"5px" }}>
                                                    <VimeoInstructionLoader 
                                                       URL = "https://player.vimeo.com/video/687625248?h=df22da040e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                                                       buttonName="Instructions"
                                                       buttonColor="graycolor"
                                                       PageName="Pending Account Reconciliations"
                                                    ></VimeoInstructionLoader>
                                                </div> */}
                                                <div className="table-box" style={{ marginTop: "20px" }}>
                                                    <div >
                                                        {/* className="dashboardReconcilitationGrid" */}
                                                        {/* <Table columns={this.state.columns}
                                                            dataSource={this.state.getDashboardData.lstAccountReconciliation}
                                                            bordered={true}
                                                            loading={this.state.loading}
                                                            pagination={false}
                                                            size={"small"}
                                                        >
                                                        </Table> */}
                                                        {this.state.errorMsg && (<div className="mt-3 mb-3 alert alert-dark fade show">
                                                            {this.state.errorMsg}
                                                        </div>)}
                                                        <table className="table client_company_dashboard">
                                                            <tr>
                                                                <th className="custome-table-border"> Account</th>
                                                                <th className="custome-table-border"> Reconciled Through</th>
                                                                <th className="custome-table-border"> Account Status</th>
                                                            </tr>
                                                            <tbody>
                                                                {this.state.getDashboardData.lstAccountReconciliation && this.state.getDashboardData.lstAccountReconciliation.map((item, index) => (
                                                                    <tr>
                                                                        <td style={{ border: "1px solid #e8e8e8 !important" }} >{item.accountName}</td>
                                                                        <td style={{ border: "1px solid #e8e8e8 !important" }}>{this.state.editIndex === index ? '' : item.reconciledThrough}
                                                                            {(this.state.editIndex === -1) &&
                                                                                <span className=""
                                                                                    onClick={(e) => this.editReconcilThroughDate(e, index, item)}>
                                                                                    <i className="zmdi zmdi-edit zmdi-hc-lg ml-4 pointer"></i></span>}
                                                                            {( this.state.editIndex === index) &&
                                                                                <React.Fragment>
                                                                                    <div className="datepicker-box">
                                                                                        <div className="date-box">
                                                                                            <DatePicker
                                                                                                selected={this.state.reconciledThroughDate}
                                                                                                onChange={(e) => this.handleDateChange(e, 'fromDate')}
                                                                                                name="fromDate"
                                                                                                id="date"
                                                                                                required={true}
                                                                                                margin="normal"
                                                                                                disabledKeyboardNavigation
                                                                                                showMonthDropdown
                                                                                                showYearDropdown
                                                                                                yearDropdownItemNumber={5}
                                                                                                // maxDate={this.state.eDate}
                                                                                                ref={this.sDateRef}
                                                                                            />
                                                                                            <div className="date-icon" onClick={(e) => this.handleFocus(e, 'sDate')}>
                                                                                                <i className="zmdi zmdi-calendar-note"></i>
                                                                                            </div>
                                                                                        </div>
                                                                                        <Button className="jr-btn close-btn" onClick={(e) => this.onClickCloseButtonDate(e)}><ClearIcon /></Button>
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            }
                                                                        </td>
                                                                        <td className="custome-table-border">
                                                                            <Switch className={item.isActive ? 'switch-box-blue' : 'switch-box-blue switch-inactive'} size="small"
                                                                                checked={item.isActive} onChange={(e) => this.accountStatusChange(e, item, index)} />
                                                                            <span style={{color:'#273f4c'}} className={item.isActive ? '' : ''}>{item.isActive ? "Active" : "Inactive"}</span>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="page-heading panel-box pre-check-box" >
                                            <h2 className="panel-title" >
                                                <img src="/vendors/Tick-mark.png" className="check-icon" alt=""></img> Data Entry Issues</h2>
                                            <div className="panel-detail-box">
                                                <div className="row">
                                                    {/* <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4" style={{ color: "#ff7600" }}>
                                                        <div className="panel-left-box">
                                                            <img src="/vendors/PreCheckNewLogo.png" alt=""></img>
                                                            <h2 className="panel-sub-title">DATA ENTRY </h2>
                                                            <p className="panel-parag">ISSUES</p>
                                                        </div>
                                                    </div> */}
                                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                        <div >
                                                           <a className="panel-detail" onClick={() => this.onClickRoute('/companyDashboard/MVN')}>
                                                                <div className="panel-detail-content col-md-9">
                                                                    <h4>{preCheckIssue.missingVendorNameCount}</h4>
                                                                    <p>{LinkType.TranMissVendor}</p>
                                                                </div>
                                                                <div className="panel-commentCount col-md-2" >
                                                                    <img style={{ height: '25px' }} className={preCheckIssue.missingVendorNameNewCommentCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/orange_comments.png" alt="comments"></img>
                                                                    {preCheckIssue.missingVendorNameNewCommentCount !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">{preCheckIssue.missingVendorNameNewCommentCount}</span>}
                                                                </div>
                                                                <div className="right-icon col-md-1">
                                                                    <img className="icon-img" src="/vendors/gray-arrow.png" alt=""></img>
                                                                    <img className="hover-icon" src="/vendors/Payroll-Arrow.png" alt=""></img>
                                                                </div>
                                                            </a>
                                                        </div>
                                                        <div >
                                                            <a className="panel-detail" onClick={() => this.onClickRoute('/companyDashboard/MS')}>
                                                                <div className="panel-detail-content col-md-9">
                                                                    <h4>{preCheckIssue.missingDescriptionCount}</h4>
                                                                    <p>{LinkType.TranMissDescription}</p>
                                                                </div>
                                                                <div className="panel-commentCount col-md-2" >
                                                                    <img style={{ height: '25px' }} className={preCheckIssue.missingDescriptionNewCommentCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/orange_comments.png" alt="comments"></img>
                                                                    {preCheckIssue.missingDescriptionNewCommentCount !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">{preCheckIssue.missingDescriptionNewCommentCount}</span>}
                                                                </div>
                                                                <div className="right-icon col-md-1">
                                                                    <img className="icon-img" src="/vendors/gray-arrow.png" alt=""></img>
                                                                    <img className="hover-icon" src="/vendors/Payroll-Arrow.png" alt=""></img>
                                                                </div>
                                                            </a>
                                                        </div>
                                                        <div >
                                                            <a className="panel-detail" onClick={() => this.onClickRoute('/companyDashboard/OOS')}>
                                                                <div className="panel-detail-content col-md-9">
                                                                    <h4>{preCheckIssue.oldOutstadingCount}</h4>
                                                                    <p>{LinkType.OOTran}</p>
                                                                </div>
                                                                <div className="panel-commentCount col-md-2" >
                                                                    <img style={{ height: '25px' }} className={preCheckIssue.oldOutstadingNewCommentCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/orange_comments.png" alt="comments"></img>
                                                                    {preCheckIssue.oldOutstadingNewCommentCount !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">{preCheckIssue.oldOutstadingNewCommentCount}</span>}
                                                                </div>
                                                                <div className="right-icon col-md-1">
                                                                    <img className="icon-img" src="/vendors/gray-arrow.png" alt=""></img>
                                                                    <img className="hover-icon" src="/vendors/Payroll-Arrow.png" alt=""></img>
                                                                </div>
                                                            </a>
                                                        </div>
                                                        <div >
                                                            <a className="panel-detail" onClick={() => this.onClickRoute('/companyDashboard/REFNO')}>
                                                                <div className="panel-detail-content col-md-9">
                                                                    <h4>{preCheckIssue.missingReferenceNumberCount} </h4>
                                                                    <p>{LinkType.MissRefNo}</p>
                                                                </div>
                                                                <div className="panel-commentCount col-md-2" >
                                                                    <img style={{ height: '25px' }} className={preCheckIssue.missingReferenceNumberCommentCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/orange_comments.png" alt="comments"></img>
                                                                    {preCheckIssue.missingReferenceNumberCommentCount !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">{preCheckIssue.missingReferenceNumberCommentCount}</span>}
                                                                </div>
                                                                <div className="right-icon col-md-1">
                                                                    <img className="icon-img" src="/vendors/gray-arrow.png" alt=""></img>
                                                                    <img className="hover-icon" src="/vendors/Payroll-Arrow.png" alt=""></img>
                                                                </div>
                                                            </a>
                                                        </div>
                                                        <div >
                                                            <a className="panel-detail" onClick={() => this.onClickRoute('/companyDashboard/UCTEB')}>
                                                                <div className="panel-detail-content col-md-9">
                                                                    <h4>{preCheckIssue.uncategorizedTransactionsCount} </h4>
                                                                    <p>{LinkType.UnCategorizeTransaction}</p>
                                                                </div>
                                                                <div className="panel-commentCount col-md-2" >
                                                                    <img style={{ height: '25px' }} className={preCheckIssue.uncategorizedTransactionsCommentCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/orange_comments.png" alt="comments"></img>
                                                                    {preCheckIssue.uncategorizedTransactionsCommentCount !== 0 && <span className="badge badge-pill badge-danger  ng-star-inserted">{preCheckIssue.uncategorizedTransactionsCommentCount}</span>}
                                                                </div>
                                                                <div className="right-icon col-md-1">
                                                                    <img className="icon-img" src="/vendors/gray-arrow.png" alt=""></img>
                                                                    <img className="hover-icon" src="/vendors/Payroll-Arrow.png" alt=""></img>
                                                                </div>
                                                            </a>
                                                        </div>
                                                        {/* <div >
                                                            <a className="panel-detail" onClick={() => this.onClickRoute('/companyDashboard/RRDEB')}>
                                                                <div className="panel-detail-content col-md-9">
                                                                    <h4>{preCheckIssue.reconciliationDiscrepanciesCount} </h4>
                                                                    <p>{LinkType.ReviewReconciliationDiscrepancies}</p>
                                                                </div>
                                                                <div className="panel-commentCount col-md-2" >
                                                                    <img style={{ height: '25px' }} className={preCheckIssue.reconciliationDiscrepanciesCommentCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/orange_comments.png" alt="comments"></img>
                                                                    {preCheckIssue.reconciliationDiscrepanciesCommentCount !== 0 && <span className="badge badge-pill badge-danger  ng-star-inserted">{preCheckIssue.reconciliationDiscrepanciesCommentCount}</span>}
                                                                </div>
                                                                <div className="right-icon col-md-1">
                                                                    <img className="icon-img" src="/vendors/gray-arrow.png" alt=""></img>
                                                                    <img className="hover-icon" src="/vendors/Payroll-Arrow.png" alt=""></img>
                                                                </div>
                                                            </a>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                      
                                        {this.state.Show1099ComplianceIssues &&
                                            <div className="page-heading panel-box payroll-comliance-box" >
                                                <h2 className="panel-title bg-color-panel">
                                                    <img src="/vendors/Tick-mark.png" className="check-icon" alt="CheckMark"></img>1099 Compliance Issues</h2>
                                                <div className="panel-detail-box">
                                                    <div className="row">
                                                        {/* <div className="col-md-4" style={{ color: "#46b29d" }}>
                                                            <div className="panel-left-box">
                                                                <img src="/vendors/Payroll-1099-Compliance-Issues.png" alt="compliance"></img>
                                                                <h2 className="panel-sub-title">1099</h2>
                                                                <p className="panel-parag">Compliance Issues</p>
                                                            </div>
                                                        </div> */}
                                                        <div className="col-md-12" >
                                                            <a className="panel-detail" onClick={() => this.onClickRoute('/companyDashboard/VMW9')}>
                                                                <div className="panel-detail-content col-md-11">
                                                                    <h4>{this.state.getDashboardData.queriesForVendor1099.vendorsMissingW9Count}</h4>
                                                                    <p>{LinkType.VendorMissW9}</p>
                                                                </div>
                                                                <div className="right-icon col-md-1">
                                                                    <img className="icon-img" src="/vendors/gray-arrow.png" alt="arrow"></img>
                                                                    <img className="hover-icon" src="/vendors/Payroll-Arrow.png" alt="arrow"></img>
                                                                </div>
                                                            </a>
                                                            {this.state.ShowPendingClientApproval &&
                                                                <a className="panel-detail" onClick={() => this.onClickRoute('/companyDashboard/CLAPP1099')}>
                                                                <div className="panel-detail-content col-md-9">
                                                                    <h4> {this.state.getDashboardData.queriesForVendor1099.vendor1099PendingClientApprovalCount}
                                                                    </h4><p>{LinkType.PendingClientApproval1099}</p>
                                                                </div>
                                                                <div className="panel-commentCount col-md-2" >
                                                                    <img style={{ height: '25px' }} className={this.state.getDashboardData.queriesForVendor1099.vendor1099PendingClientApprovalCommentCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/orange_comments.png" alt="comments"></img>
                                                                    {this.state.getDashboardData.queriesForVendor1099.vendor1099PendingClientApprovalCommentCount !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">{this.state.getDashboardData.queriesForVendor1099.vendor1099PendingClientApprovalCommentCount}</span>}
                                                                </div>
                                                                <div className="right-icon col-md-1">
                                                                    <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                                    <img alt="" className="hover-icon" src="/vendors/Payroll-Arrow.png"></img>
                                                                </div>
                                                            </a>
                                                            }
                                                            
                                                            {this.state.ShowStatusReport &&
                                                            <a className="panel-detail" onClick={() => this.onClickRoute('/companyDashboard/STEMD')}>
                                                                <div className="panel-detail-content col-md-9">
                                                                    <h4>{this.state.getDashboardData.queriesForVendor1099.vendor1099StatusReportCount}</h4>
                                                                    <p>{LinkType.StatusReport1099}</p>
                                                                </div>
                                                                <div className="panel-commentCount col-md-2" >
                                                                    <img style={{ height: '25px' }} className={this.state.getDashboardData.queriesForVendor1099.vendor1099PendingClientApprovalCommentCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/orange_comments.png" alt="comments"></img>
                                                                    {this.state.getDashboardData.queriesForVendor1099.vendor1099PendingClientApprovalCommentCount !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">{this.state.getDashboardData.queriesForVendor1099.vendor1099PendingClientApprovalCommentCount}</span>}
                                                                </div>
                                                                {/* Payroll Compliance Issues */}
                                                                <div className="right-icon col-md-1">
                                                                    <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                                    <img alt="" className="hover-icon" src="/vendors/Payroll-Arrow.png"></img>
                                                                </div>
                                                            </a>
                                                            }

                                                            {/* <a className="panel-detail" onClick={() => this.onClickRoute('/companyDashboard/1099MTV')}>
                                                                <div className="panel-detail-content col-md-9">
                                                                    <h4>{this.state.getDashboardData.queriesForVendor1099.confirm1099WereMailedCount}</h4>
        
                                                                    <p>{LinkType.Confirm1099Mailed}</p>
                                                                </div>
                                                                <div className="panel-commentCount col-md-2" >
                                                                 
                                                                </div>
                                                                <div className="right-icon col-md-1">
                                                                    <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                                    <img alt="" className="hover-icon" src="/vendors/Payroll-Arrow.png"></img>
                                                                </div>
                                                            </a> */}
                                                            {/* <a className="panel-detail" onClick={() => this.onClickRoute('/companyDashboard/VenDisEMBD')}>
                                                                <div className="panel-detail-content col-md-9">
                                                                    <h4>{this.state.getDashboardData.queriesForVendor1099.resolve1099VendorNameDiscrepanciesCount}</h4>

                                                                    <p>{LinkType.Resolve1099VendorNameDiscrepancies}</p>
                                                                </div>
                                                                <div className="panel-commentCount col-md-2" >
                                                                    <img style={{ height: '25px' }} className={this.state.getDashboardData.queriesForVendor1099.resolve1099VendorNameDiscrepanciesCommentCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/orange_comments.png" alt="comments"></img>
                                                                    {this.state.getDashboardData.queriesForVendor1099.resolve1099VendorNameDiscrepanciesCommentCount !== 0 && <span className="badge badge-pill badge-danger  ng-star-inserted">{this.state.getDashboardData.queriesForVendor1099.resolve1099VendorNameDiscrepanciesCommentCount}</span>}
                                                                </div>
                                                                <div className="right-icon col-md-1">
                                                                    <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                                    <img alt="" className="hover-icon" src="/vendors/Payroll-Arrow.png"></img>
                                                                </div>
                                                            </a> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    }
                </div>
                </div>
            </React.Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        companyDashboardData: state.companyDashboardData.companyDashboardData,
        company: state.companyReducer.companyDeatils,
        Update_reconciled: state.reconcillition.Update_reconciled,
        account_Inactive: state.reconcillition.account_Inactive,
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        GET_CompanyDashboard_BES,
        getCompnay,
        Embebded_POST_updatecustomfield,
        PUT_QuickbookAccount_UpdateAccountStatus
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDashboard_Embeded);
import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';

class ReviewPopUp extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: props.data,
            IsReportData: props.IsReportData == undefined ? false : props.IsReportData
        }
    }

    CloseModel = () => {
        this.props.CloseReview();
    }
    render() {
        return (
            <React.Fragment>
                <Dialog
                    className="comment-Top-align"
                    maxWidth="sm"
                    fullWidth={true}
                    open={true}>
                    <DialogTitle><span className="log-history-title float-left"> Reviewed History</span>
                        <Button className="jr-btn close-btn float-right " onClick={this.CloseModel}><ClearIcon /></Button>
                    </DialogTitle>
                    <DialogContent>
                        {this.state.data.map((item, i) => (
                            <div className="history-box">
                                {this.state.IsReportData ?

                                    <p className="history-date-text"> <strong> Reviewed By </strong> {item.userName}<strong> on </strong>{item.stCreatedDate}</p>
                                    :
                                    <React.Fragment key={i}>
                                        <p className="history-item-text">Dates {item.stFromDate} - {item.stToDate}</p>
                                        <p className="history-date-text"> <strong> By </strong> {item.userName}<strong> on </strong>{item.stCreatedDate}</p>
                                    </React.Fragment>
                                }


                            </div>
                        ))}
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        )
    }


}

export default ReviewPopUp;
import baseService from './baseService';


export function GetBalanceSheetData(param) {
    return baseService.get("/api/BalanceSheet/data?"+param);
}

export function GetArchivedBalanceSheet(body)
{
    return baseService.post("/api/BalanceSheet/archiveList",body)
}

export function ArchievedBalanceSheet(data)
{
    return baseService.post("/api/BalanceSheet/archive",data);
}

export function UpdateCustomField(data)
{
    return baseService.put("/QuickbookAccount/UpdateRTDateAndComments",data);
}

export function GetArchivedById(id)
{
    return baseService.get("/api/BalanceSheet/ArchiveReportDetails?ID="+id);
}


export function UpdateArchivedCustomFields(data)
{
    return baseService.post("/api/BalanceSheet/EditArchiveAccountCustomField",data);
}

export function GetLastQuarterGLReviewedDate(CompanyID)
{
    return baseService.get("/api/BalanceSheet/GetLastQuarterGLReviewedDate?CompanyID="+CompanyID);
}
import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import IntlMessages from '../../util/IntlMessages';
import Button from '@material-ui/core/Button';
import CustomScrollbars from '../../util/CustomScrollbars';
import {
    companyDetail,
} from '../../appRedux/actions/companiesAction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';


class Menu extends Component {

    constructor(props) {
        super(props);

        this.state = {
            show: false,
        }
    }

    componentDidMount() {
        const { history } = this.props;
        const pathname = `#${history.location.pathname}`;// get current path
        const mainMenu = document.getElementsByClassName('nav-item');
        for (let i = 0; i < mainMenu.length; i++) {
            mainMenu[i].onclick = function () {
                for (let j = 0; j < mainMenu.length; j++) {
                    if (mainMenu[j].classList.contains('active')) {
                        mainMenu[j].classList.remove('active')
                    }
                }
                this.classList.toggle('active');
            }
        }
        const subMenuLi = document.getElementsByClassName('nav-arrow');
        for (let i = 0; i < subMenuLi.length; i++) {
            subMenuLi[i].onclick = function () {
                for (let j = 0; j < subMenuLi.length; j++) {
                    if (subMenuLi[j].classList.contains('active')) {
                        subMenuLi[j].classList.remove('active')
                    }
                }
                this.classList.toggle('active');
            }
        }
        const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
        try {
            const activeNav = this.closest(activeLi, 'ul'); // select closest ul
            if (activeNav.classList.contains('sub-menu')) {
                this.closest(activeNav, 'li').classList.add('active');
            } else {
                this.closest(activeLi, 'li').classList.add('active');
            }
            const parentNav = this.closest(activeNav, '.nav-item');
            if (parentNav) {
                parentNav.classList.add('active');
            }

        } catch (e) {

        }

    }

    closest(el, selector) {
        try {
            let matchesFn;
            // find vendor prefix
            ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
                if (typeof document.body[fn] == 'function') {
                    matchesFn = fn;
                    return true;
                }
                return false;
            });

            let parent;

            // traverse parents
            while (el) {
                parent = el.parentElement;
                if (parent && parent[matchesFn](selector)) {
                    return parent;
                }
                el = parent;
            }
        } catch (e) {

        }

        return null;
    }

    render() {
        let user = JSON.parse(localStorage.getItem('userData'));
        let decode = jwt_decode(user.Authorization);
        let companyId = user.companyId;
        let role = user.role;
        let show = user.showMenu || false;

        return (
            <div className="app-main-menu d-none d-md-block">
                {role == 'Contact' && show == false ? <ul className="navbar-nav navbar-nav-mega">
                    <li className="nav-item">
                        <NavLink className="prepend-icon" to="/dashboard">
                            <span className="nav-text">Home</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <a href="javascript:void(0)"> <IntlMessages id="sidebar.reports" /></a>
                        <ul className="sub-menu">
                            {/* <li>
                                <NavLink className="prepend-icon" to="/financialReview">
                                    <span className="nav-text"> <IntlMessages id="sidebar.reports.finCheck" /></span>
                                </NavLink>
                            </li> */}
                            <li>
                                <NavLink className="prepend-icon" to="/reportReview">
                                    <span className="nav-text"> <IntlMessages id="sidebar.accoutant.review" /></span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="prepend-icon" to="/taxableReview">
                                    <span className="nav-text"> <IntlMessages id="sidebar.accoutant.taxable" /></span>
                                </NavLink>
                            </li>
                        </ul>
                    </li>
                </ul> : ''}

                {role == 'Accountant' && show == false ? <ul className="navbar-nav navbar-nav-mega">

                    <li className="nav-item">
                        <NavLink className="prepend-icon" to="/home">
                            <span className="nav-text">Home</span>
                        </NavLink>
                    </li>

                </ul> : ''}

                {show == false && (role == 'SuperAdmin' || role == 'Admin') ? <ul className="navbar-nav navbar-nav-mega">

                    {role == 'SuperAdmin' || 'Admin' ? <li className="nav-item">
                        <NavLink className="prepend-icon" to="/home">
                            <span className="nav-text">Home</span>
                        </NavLink>
                    </li> : ''}

                    {role == 'SuperAdmin' || role == 'Admin' ? <li className="nav-item">
                        <NavLink className="prepend-icon" to="/companies">
                            <span className="nav-text"> Companies</span>
                        </NavLink>
                    </li> : ''}

                    {role == 'SuperAdmin' || role == 'Admin' ? <li className="nav-item">
                        <NavLink className="prepend-icon" to="/accountant">
                            <span className="nav-text">Assigned companies</span>
                        </NavLink>
                    </li> : ''}                   


                    {role == 'SuperAdmin' || role == 'Admin' ? <li className="nav-item">
                        <NavLink className="prepend-icon" to={`/users/0`}>
                            <span className="nav-text">Users</span>
                        </NavLink>
                    </li> : ''}

                    {role == 'SuperAdmin' ? <li className="nav-item">
                        <NavLink className="prepend-icon" to="/vendorType">
                            <span className="nav-text">Vendor Types</span>
                        </NavLink>
                    </li> : ''}

                    {role == 'SuperAdmin' ? <li className="nav-item">
                        <NavLink className="prepend-icon" to="/Wages">
                            <span className="nav-text">Wages Limit</span>
                        </NavLink>
                    </li> : ''}

                    {role == 'SuperAdmin' || role == 'Admin' ? <li className="nav-item">
                        <a href="javascript:void(0)"> <IntlMessages id="sidebar.accoutantant.report" /></a>
                        <ul className="sub-menu">
                            <li>
                                <NavLink className="prepend-icon" to="/ACCFCK">
                                    <span className="nav-text"> <IntlMessages id="sidebar.accoutantant.report.fcs" /></span>
                                </NavLink>
                            </li>                
                            <li>
                                <NavLink className="prepend-icon" to="/EARC">
                                    <span className="nav-text"> <IntlMessages id="sidebar.accoutantant.report.earc" /></span>
                                </NavLink>
                            </li> 
                            <li>
                                <NavLink className="prepend-icon" to="/BSA">
                                    <span className="nav-text"> <IntlMessages id="sidebar.accoutantant.report.bsa" /></span>
                                </NavLink>
                            </li> 
                            <li>
                                <NavLink className="prepend-icon" to="/AP">
                                    <span className="nav-text"> <IntlMessages id="sidebar.accoutantant.report.ap" /></span>
                                </NavLink>
                            </li> 
                            <li>
                                <NavLink className="prepend-icon" to="/TL">
                                    <span className="nav-text"> <IntlMessages id="sidebar.accoutantant.report.tl" /></span>
                                </NavLink>
                            </li> 
                            <li>
                                <NavLink className="prepend-icon" to="/LTL">
                                    <span className="nav-text"> <IntlMessages id="sidebar.accoutantant.report.ltl" /></span>
                                </NavLink>
                            </li> 
                            <li>
                                <NavLink className="prepend-icon" to="/OBSA">
                                    <span className="nav-text"> <IntlMessages id="sidebar.accoutantant.report.obsa" /></span>
                                </NavLink>
                            </li> 
                            <li>
                                <NavLink className="prepend-icon" to="/RBSN">
                                    <span className="nav-text"> <IntlMessages id="sidebar.accoutantant.report.rbsn" /></span>
                                </NavLink>
                            </li>   
                            <li>
                                <NavLink className="prepend-icon" to="/SPL">
                                    <span className="nav-text"> <IntlMessages id="sidebar.accoutantant.report.spl" /></span>
                                </NavLink>
                            </li> 
                            <li>
                                <NavLink className="prepend-icon" to="/CTI">
                                    <span className="nav-text"> <IntlMessages id="sidebar.accoutantant.report.cti" /></span>
                                </NavLink>
                            </li>                
                                       
                        </ul>
                    </li>
                     : ''}

                </ul> : ''}

                {show == true ? <ul className="navbar-nav navbar-nav-mega">

                    <li className="nav-item">
                        <NavLink className="prepend-icon" to="/dashboard">
                            <span className="nav-text">Home</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <a href="javascript:void(0)"> <IntlMessages id="sidebar.accoutant" /></a>
                        <ul className="sub-menu">
                            <li>
                                <NavLink className="prepend-icon" to="/ledgers">
                                    <span className="nav-text"> <IntlMessages id="sidebar.accoutant.ledger" /></span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="prepend-icon" to="/trialBalanceReview">
                                    <span className="nav-text"> <IntlMessages id="sidebar.accoutant.balancesheet" /></span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="prepend-icon" to="/accoutantReview">
                                    <span className="nav-text"> <IntlMessages id="sidebar.accoutant.review" /></span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="prepend-icon" to="/distributionReview">
                                    <span className="nav-text"> <IntlMessages id="sidebar.accoutant.distribution" /></span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="prepend-icon" to="/Status1099">
                                    <span className="nav-text"> <IntlMessages id="sidebar.accoutant.Status1099" /></span>
                                </NavLink>
                            </li>
                            {/* <li>
                                <NavLink className="prepend-icon" to="/ACCAPP1099">
                                    <span className="nav-text"> <IntlMessages id="sidebar.accoutant.1099ACCAproval" /></span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="prepend-icon" to="/CLAPP1099">
                                    <span className="nav-text"> <IntlMessages id="sidebar.accoutant.1099ClientAproval" /></span>
                                </NavLink>
                            </li> */}

                            {/* <li>
                                <NavLink className="prepend-icon" to="/accoutantTaxableReview">
                                    <span className="nav-text"> <IntlMessages id="sidebar.accoutant.taxable" /></span>
                                </NavLink>
                            </li> */}
                            <li>
                                <NavLink className="prepend-icon" to="/taxableWagesComputations">
                                    <span className="nav-text"> <IntlMessages id="sidebar.accoutant.wagesComputations" /></span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="prepend-icon" to="/accountreconcilliation">
                                    <span className="nav-text"> <IntlMessages id="sidebar.accoutant.reconciliation" /></span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="prepend-icon" to="/ACPYTC">
                                    <span className="nav-text"> <IntlMessages id="sidebar.accoutant.PayrollTaxComputations" /></span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="prepend-icon" to="/RTC">
                                    <span className="nav-text"> <IntlMessages id="sidebar.accoutant.RTC" /></span>
                                </NavLink>
                            </li>
                            {/* <li>
                                <NavLink className="prepend-icon" to="/financialReview">
                                    <span className="nav-text"> <IntlMessages id="sidebar.reports.finCheck" /></span>
                                </NavLink>
                            </li> */}
                        </ul>
                    </li>
                    {/* <li className="nav-item">
                        <a href="javascript:void(0)"> <IntlMessages id="sidebar.reports" /></a>
                        <ul className="sub-menu">                            
                            <li>
                                <NavLink className="prepend-icon" to="/reportReview">
                                    <span className="nav-text"> <IntlMessages id="sidebar.accoutant.review" /></span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="prepend-icon" to="/taxableReview">
                                    <span className="nav-text"> <IntlMessages id="sidebar.accoutant.taxable" /></span>
                                </NavLink>
                            </li>                            
                        </ul>
                    </li> */}
                    {(role == 'SuperAdmin' || role == 'Admin') &&
                        <li className="nav-item">
                            <a href="javascript:void(0)"> <IntlMessages id="sidebar.admin" /></a>
                            <ul className="sub-menu">
                                <li>
                                    <NavLink className="prepend-icon" to="/QuickBooks">
                                        <span className="nav-text">QuickBooks Data Sync</span>
                                    </NavLink>
                                </li>
                                {role == 'SuperAdmin' || role == 'Admin' || role == 'Accountant' ? <li>
                                    <NavLink className="prepend-icon" to="/companyDetail">
                                        <span className="nav-text"> <IntlMessages id="sidebar.admin.company" /></span>
                                    </NavLink>
                                </li> : ""
                                }
                                <li>
                                    <NavLink className="prepend-icon" to="/chartAccounts">
                                        <span className="nav-text"> <IntlMessages id="sidebar.admin.chart" /></span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className="prepend-icon" to="/vendor">
                                        <span className="nav-text"> <IntlMessages id="sidebar.admin.vendor" /></span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className="prepend-icon" to="/leases">
                                        <span className="nav-text"> <IntlMessages id="sidebar.admin.lease" /></span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className="prepend-icon" to="/loans">
                                        <span className="nav-text"> <IntlMessages id="sidebar.admin.loan" /></span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className="prepend-icon" to="/employees">
                                        <span className="nav-text"> Employees</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className="prepend-icon" to="/insurancePolicies">
                                        <span className="nav-text"> <IntlMessages id="sidebar.admin.insurance" /></span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className="prepend-icon" to="/retirementPlan">
                                        <span className="nav-text">Retirement Plan</span>
                                    </NavLink>
                                </li>
                                <li >
                                    <NavLink className="prepend-icon" to={`/users/${companyId}`}>
                                        <span className="nav-text">Contact</span>
                                    </NavLink>
                                </li>
                                {/* <li>
                                    <NavLink className="prepend-icon" to={`/Logs`}>
                                        <span className="nav-text">Logs</span>
                                    </NavLink>
                                </li> */}
                            </ul>
                        </li>
                    }
                </ul> : ''}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        showMenu: state.companyReducer.ShowMenu
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({

    }, dispatch)
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));

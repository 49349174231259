import React, { Component } from 'react';
import IntlMessages from "../../../../util/IntlMessages";
import SearchIcon from '@material-ui/icons/Search';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import RSelect from 'react-select';
import Button from '@material-ui/core/Button';
import _ from 'lodash';
import Switch from '@material-ui/core/Switch';
import {
  companyStatus,companyDetail
} from '../../../../appRedux/actions/companiesAction';
import {
   getCompnay,
   getStates,
   accountingsoftware,
   payrollsoftware,
   deleteCompany,
} from '../../../../appRedux/actions/companiesAction';
import {
    AccountTypes,
    CompanyAccountTypes,
    CompanyTransactionTypes,
    TransactionTypes
} from '../../../../appRedux/actions/accountAction';
import CircularProgress from "../../../../components/CircularProgress";
import browserHistory from '../../../../browserHistory';
import jwt_decode from 'jwt-decode';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditCompanyForm from '../../../../Forms/EditCompanyForm';
import DeleteCompanyForm from '../../../../Forms/DeleteCompanyForm';
import EditCompanyAccountsForm from '../../../../Forms/EditCompanyAccountsForm';
import EditCompanyWagesSettingForm from "../../../../Forms/EditCompanyWagesSetting";


class CompanyDetailPage extends Component {

    constructor(props) {
      super();
      this.state = {
        company:'',
        loading:true,
        isEdit: false,
        isDel: false,
        isEditAcc:false,
        isEditWages:false,
        accountingSoft: [],
        payrollSoft: [],
        states: [],
        accountTypes:[],
        transctionTypes:[],
        companyAccountTypes:[],
        companyTransactionTypes:[],
      };
    }

    componentDidMount() {
       let user = JSON.parse(localStorage.getItem('userData'));
        let companyId = user.companyId;
        this.props.getCompnay(companyId);
        this.props.getStates();
        this.props.accountingsoftware();
        this.props.payrollsoftware();
        this.props.AccountTypes();
        // this.props.companyDetail();
        this.props.TransactionTypes();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.states!==undefined && nextProps.states !== this.props.states) {
            this.setState({states:nextProps.states});
        }  else  if (nextProps.accountingSoft!==undefined && nextProps.accountingSoft !== this.props.accountingSoft) {
            this.setState({ accountingSoft:nextProps.accountingSoft});
        } else  if (nextProps.payrollSoft !==undefined && nextProps.payrollSoft !== this.props.payrollSoft) {
            this.setState({ payrollSoft:nextProps.payrollSoft});
        }  else  if (nextProps.accountTypes!==undefined && nextProps.accountTypes !== this.props.accountTypes) {
            this.setState({ accountTypes:nextProps.accountTypes.data});
        } else  if (nextProps.tranactionTypes!==undefined && nextProps.tranactionTypes !== this.props.tranactionTypes) {
            this.setState({ transctionTypes:nextProps.tranactionTypes.data});
        }  else  if (nextProps.company !==undefined && nextProps.company !== this.props.company) {
            this.setState({ loading: false , company:nextProps.company});
        } else if (nextProps.companyAccountTypes !== undefined && nextProps.companyAccountTypes !== this.props.companyAccountTypes) {

          this.setState({ companyAccountTypes:nextProps.companyAccountTypes.data});

        } else if(nextProps.companyTransactionTypes!==undefined && nextProps.companyTransactionTypes !== this.props.companyTransactionTypes) {

          this.setState({ isEdit:true,getAcc:false, companyTransactionTypes:nextProps.companyTransactionTypes.data});
        }
        if (nextProps.reload) {
          this.setState({ loading: false }, () => {
              this.props.companyDetail();
          });
      };
        
    }

     handleDelete = () => {

        this.setState({ isEdit: false, isEditAcc:false, isDel: true, isAdd: false });
    }

     handleDelModel = (record) => {
        var id = record.companyId;
        var data = this.props.company;
            this.setState({
                isDel: true, iAdd: false,
                CompanyId: id,
                selectedCompany: record,
            });
    }


    handleEditModel = (record) => {

        var id = record.companyId;

        this.props.CompanyAccountTypes(id);
        this.props.CompanyTransactionTypes(id);
        this.setState({
          CompanyId: id,
          selectedCompany: record,
         });

        if(this.state.getAcc === false) {
              this.setState({
                isEdit: true, iAdd: false, isDel: false
            });
        }
    }

     handleEditCancel = () => {
        this.setState({ isEdit: false ,  isEditAcc:false});
    }
    handleDelCancel = () => {
        this.setState({ isDel: false, isEditAcc:false, isEdit: false,isEditWages:false });
    }
    handleEditSave = (state) => {
      this.setState({
          isEditAcc: state
      });
    }
     handleDelSave = (state) => {
        this.setState({
            isDel: state
        });
    }
    accountStatusChange = (event) => {
      let isChecked = event.target.checked;
      this.setState(prevState => ({
        company: {
          ...prevState.company,
          isActive: isChecked
        }
      }),()=>{
        let data = {
          "companyId": this.state.company.companyId,
          "status": isChecked
      };
        this.props.companyStatus(data)
        
      }
      
      );
      
      
  }


    ShowWages = () =>{      
      this.setState({isEdit:false,isEditAcc:false,isEditWages:true})
    }

  render() {
    const { company, states, accountingSoft, payrollSoft } = this.state;
    var accountingSoftware = "";
    if(accountingSoft && accountingSoft.length>0)
    {
        var acc_o = _.find(accountingSoft, {id:company.accountingSoftwareId});
        if(acc_o)
          accountingSoftware = acc_o.name;
    }

    var payrollsoftware = "";
    if(payrollSoft && payrollSoft.length>0)
    {
        var acc_o = _.find(payrollSoft, {id:company.payrollSoftwareId});
        if(acc_o)
          payrollsoftware = acc_o.name;
    }

    


  return (
      <div style={{padding:15}}>

  <EditCompanyForm
        handleSave={this.handleEditSave}
        visible={this.state.isEdit}
        states={this.state.states}
        accountingSoft={this.state.accountingSoft}
        payrollSoft={this.state.payrollSoft}
        handleCancel={this.handleEditCancel}
        selectedItem={this.state.selectedCompany}
        nextForm={(fstep) => this.setState({
             isEditAcc: true, isAdd: false,
             isEdit: false, isDel: false,
             AccNext:fstep                
            })}
        ShowWages={this.ShowWages}
    />
   <DeleteCompanyForm
      handleSave={this.handleDelSave}
      visible={this.state.isDel}
      handleCancel={this.handleDelCancel}
      selectedItem={this.state.selectedCompany} />

  {this.state.isEditWages && <EditCompanyWagesSettingForm
  handleDelCancel={this.handleDelCancel}>

  </EditCompanyWagesSettingForm>}

  { this.state.isEditAcc == true ? <EditCompanyAccountsForm
    handleSave={this.handleEditSave}
    visible={this.state.isEditAcc}
    AccNext={this.state.AccNext}
    handleDelete={this.handleDelete}
    accountingSoft={this.state.accountingSoft}
    payrollSoft={this.state.payrollSoft}
    transctionTypes={this.state.transctionTypes}
    accountTypes={this.state.accountTypes}
    companyAccountTypes={this.state.companyAccountTypes}
    companyTransactionTypes={this.state.companyTransactionTypes}
    handleCancel={() => this.setState({  isAcc: false, isEditAcc:false,isAdd: false, isEdit: false, isDel: false })}
    selectedItem={this.state.selectedCompany}
    handleBack={() => {
        this.setState({
            isAcc: false, isEditAcc:false,  isEdit: true
        })
    }}
    /> :'' }

      <Grid
        container
        spacing={24}
        style={{ 'marginBottom': 10 , padding:10}}
      >
        <Grid item  sm={6}>
          <h2 className="user-title">Company Details Page</h2>
        </Grid>
         <Grid item  sm={6} >
           <Grid
            container
             spacing={8}
             direction="row"
             justify="flex-end"
             alignItems="flex-end"
            >
            <Grid item >
            <Button variant="contained" color="primary" onClick={() => this.handleEditModel(company)}>
                 Edit Company
             </Button>
              </Grid>
           </Grid>
        </Grid>
      </Grid>

  {this.state.loading == false?
          <Grid container spacing={24} style={{padding:40}}>
                <Grid item  sm={3}>
                 <h3><b>Name : </b> {company.name}</h3>
                 <h3><b>Legal Address : </b> {company.address}</h3>
                 <h3><b>City : </b> {company.city}</h3>
                 <h3><b>State : </b> {company.state}</h3>
                 <h3><b>Zip : </b> {company.postalCode}</h3>
                 <h3><b>EIN Number : </b> {company.eiNnumber}</h3>
                 <h3> <b>Religious Organization : </b> {company.isReligeousOrganization ? 'Yes' :'No'} </h3>
                 <h3><b>Beginning Year : </b> {company.quickbooksDataSyncBeginningYear}</h3>
                 <h3><b>Make Inactive : </b><Switch className={company.isActive ? 'switch-box-blue' : 'switch-box-blue switch-inactive'} size="small"
                  checked={company.isActive} onChange={(e) => this.accountStatusChange(e)} /></h3>

                </Grid>
                 <Grid item  sm={4}>
                 <h3> <b>Accounting Software : </b> {accountingSoftware}</h3>
                 <h3> <b>Payroll Software : </b> {payrollsoftware}</h3>
                 <h3> <b>Accounting Team Basecamp Email : </b> {company.mpwAccountantEmail}</h3>
                 <h3> <b>Infusionsoft ContactId :</b>  {company.infusionsoftContactId}</h3>
                 <h3> <b>Accouting Method : </b> Accrual </h3>
                 <h3> <b>Full Service Bookkeeping : </b> {company.isFullServiceBookkeeping ? 'Yes' :'No'} </h3>
                 <h3> <b>QB Company Id : </b> {company.realmID} </h3>
                 <h3> <b>QB Company Name : </b> {company.name} </h3>
                 
                  {/* {company.accountingMethod == "C" ? 'Cash' : 'Accrual'} */}
                 </Grid>
            </Grid> : <CircularProgress /> }
    </div>
  );
 }
}


const mapStateToProps = (state) => {
    return {
        company: state.companyReducer.companyDeatils,
        reload: state.companyReducer.reload,
        states:state.companyReducer.states,
        accountingSoft:state.companyReducer.accountingSoftwares,
        payrollSoft:state.companyReducer.payrollSoftwares,
        accountTypes:state.accounts.accountTypes,
        tranactionTypes:state.accounts.tranactionTypes,
        companyAccountTypes:state.accounts.companyAccountTypes,
        companyTransactionTypes:state.accounts.companyTransactionTypes,
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
         getCompnay,
         getStates,
         accountingsoftware,
         payrollsoftware,
         deleteCompany,
         AccountTypes,
        CompanyAccountTypes,
        CompanyTransactionTypes,
        TransactionTypes,
        companyStatus,
        companyDetail
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetailPage);

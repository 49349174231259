import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { updateAccocunt } from '../appRedux/actions/accountAction';
import { findIndex } from "lodash";
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import Textarea from 'react-expanding-textarea';
import InputLabel from '@material-ui/core/InputLabel';
import './form.css';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


class EditAcccountForm extends React.Component {

    constructor() {
        super()
        this.state = {
            "accountID": '',
            "companyID": '',
            "listID": "",
            "name": "",
            "accountNumber": "",
            "accountType": "",
            "accountSubType": "",
            "classification": "",
            "description": "",
            "reviewStatus": "",
            "comments": "",
            "lastReconciledTransactionDate": null,
            "reconciledThrough": null,

        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
    }
    componentWillReceiveProps(nextProps) {

        if (this.props.visible !== nextProps.visible) {

            this.setState({
                "companyID": nextProps.selectedItem.companyID,
                "accountID": nextProps.selectedItem.accountID,
                "listID": nextProps.selectedItem.listID,
                "name": nextProps.selectedItem.name,
                "accountNumber": nextProps.selectedItem.accountNumber,
                "accountType": nextProps.selectedItem.accountType,
                "accountSubType": nextProps.selectedItem.accountSubType,
                "classification": nextProps.selectedItem.classification,
                "description": nextProps.selectedItem.description,
                "reviewStatus": nextProps.selectedItem.reviewStatus,
                "comments": nextProps.selectedItem.comments,
                "lastReconciledTransactionDate": nextProps.selectedItem.lastReconciledTransactionDate ? new Date(nextProps.selectedItem.lastReconciledTransactionDate) : null,
                "reconciledThrough": nextProps.selectedItem.reconciledThrough ? new Date(nextProps.selectedItem.reconciledThrough) : null,
            });            
        }
    }

    // handleDelete = () => {
    //     this.props.handleDelete();
    // }

    handleCancel = () => {
        this.props.handleCancel()
    };

    handleSubmit = (e) => {
        e.preventDefault();
        let data = {
            "companyID": this.state.companyID,
            "accountID": this.state.accountID,
            "listID": this.state.listID,
            "name": this.state.name,
            "accountNumber": this.state.accountNumber,
            "accountType": this.state.accountType,
            "accountSubType": this.state.accountSubType,
            "subAccount": true,
            "classification": this.state.classification,
            "description": this.state.description,
            "isActive": true,
            "reviewStatus": this.state.reviewStatus,
            "comments": this.state.comments,
            "lastReconciledTransactionDate": this.state.lastReconciledTransactionDate != null ? moment(this.state.lastReconciledTransactionDate).format('YYYY-MM-DD') : null,
            "reconciledThrough": this.state.reconciledThrough != null ? moment(this.state.reconciledThrough).format('YYYY-MM-DD') : null,
        };
        this.props.updateAccocunt(data);
        this.props.handleSave(false);
    }

    handleFormChange = (e, target) => {

        if (!target) {
            this.setState({ [e.target.name]: e.target.value })
        }
        else if (target == "lastReconciledTransactionDate") {
            this.setState({ lastReconciledTransactionDate: new Date(e) })
        }
        else if (target == "reconciledThrough") {
            this.setState({ reconciledThrough: new Date(e) })
        }
    }
    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };

    render() {
        const { visible } = this.props;

        return (
            <Dialog
                maxWidth="md"
                fullWidth={true}
                open={visible}
                onClose={this.handleCancel}
            >
                <form method="post" onSubmit={this.handleSubmit} autoComplete="off" style={{ padding: 20 }}>
                    <DialogTitle>Edit Account</DialogTitle>
                    <DialogContent>
                        <fieldset>
                            <Grid container spacing={24} >
                                <Grid item sm={6} >
                                    <TextField
                                        name="name"
                                        label="Name"
                                        fullWidth
                                        required={true}
                                        onChange={this.handleFormChange}
                                        defaultValue={this.state.name}
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item sm={6} >
                                    <TextField
                                        name="accountNumber"
                                        label={"Account Number"}
                                        fullWidth
                                        onChange={this.handleFormChange}
                                        defaultValue={this.state.accountNumber}
                                        margin="normal"
                                    />
                                </Grid>

                            </Grid>
                            <Grid container spacing={24} >
                                <Grid item sm={6}>
                                    <TextField
                                        name="accountType"
                                        required={true}
                                        label={"Account Type"}
                                        fullWidth
                                        onChange={this.handleFormChange}
                                        defaultValue={this.state.accountType}
                                        margin="normal"
                                    />
                                </Grid>

                                <Grid item sm={6}>
                                    <TextField
                                        name="accountSubType"
                                        label={"Account SubType"}
                                        fullWidth
                                        onChange={this.handleFormChange}
                                        defaultValue={this.state.accountSubType}
                                        margin="normal"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={24} >
                                <Grid item sm={6}>
                                    <TextField
                                        name="classification"
                                        label={"Classification"}
                                        fullWidth
                                        onChange={this.handleFormChange}
                                        defaultValue={this.state.classification}
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item sm={6}>
                                    <TextField
                                        name="description"
                                        label={"Description"}
                                        fullWidth
                                        onChange={this.handleFormChange}
                                        defaultValue={this.state.description}
                                        margin="normal"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={24} >
                                <Grid item sm={6}>
                                    <InputLabel htmlFor="reviewStatus" className="labletext">Review Status</InputLabel>
                                    <Select
                                        name="reviewStatus"
                                        native
                                        label={"Review Status"}
                                        fullWidth
                                        onChange={this.handleChange('reviewStatus')}
                                        inputProps={{
                                            name: 'reviewStatus',
                                            id: 'reviewStatus',
                                        }}
                                        defaultValue={this.state.reviewStatus}

                                    >
                                        <option value=''>Select Status</option>
                                        <option value='Pending'>Pending</option>
                                        <option value='Reviewed'>Reviewed</option>
                                    </Select>

                                </Grid>
                                <Grid item sm={6}>
                                    <InputLabel htmlFor="comments" className="labletext">Comments</InputLabel>
                                    <Textarea
                                        value={this.state.comments}
                                        style={{ 'lineHeight': '19px', width: '100%', border: 'none', marginTop: '0px', resize: 'none', padding: '6px', paddingLeft: '10px', borderBottom: '1px solid rgba(0, 0, 0, 0.42)', overflow: 'hidden' }} resize={false} placeholder="Comment"
                                        onChange={(e) => { this.setState({ comments: e.target.value }); }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={24}>
                                <Grid item sm={6}>
                                    <InputLabel className="labletext">Last Reconciled Transaction Date</InputLabel>
                                    <DatePicker
                                        selected={this.state.lastReconciledTransactionDate}
                                        onChange={(e) => this.handleFormChange(e, 'lastReconciledTransactionDate')}
                                        label="Last Reconciled Transaction Date"
                                        id="date"
                                        name="lastReconciledTransactionDate"
                                        margin="normal"
                                    />
                                </Grid>


                                <Grid item sm={6}>
                                <InputLabel className="labletext">Reconciled Through</InputLabel>
                                    <DatePicker
                                        selected={this.state.reconciledThrough}
                                        onChange={(e) => this.handleFormChange(e, 'reconciledThrough')}
                                        label="Reconciled Through"
                                        id="date"
                                        name="reconciledThrough"
                                        margin="normal"
                                    />                                  
                                </Grid>
                            </Grid>
                        </fieldset>

                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" color="primary">
                            Update
                </Button>
                        <Button onClick={this.handleCancel} color="primary" autoFocus>
                            Cancel
                </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }
}



const mapStateToProps = (state) => {
    return {

    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        updateAccocunt
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAcccountForm);




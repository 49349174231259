import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
// import "./PrintReportForm.css";

class RefreshDataModal extends Component {
  constructor() {
    super();
    // this.state = {
    //   IsPayrollPaymentReportsSelected: false,
    //   IsPayrollFilingReportsSelected: false,
    // };
  }

//   handleprintReportChecked = (e) => {
//     this.setState({ [e.target.name]: e.target.checked });
//   };
//   handleSubmit = (e) => {
//     e.preventDefault();
//     this.props.printReports(this.state);
//   };
  render() {
    const { visible, handlePopUpClose,refreshData } = this.props;
    return (
      <div>
        <Dialog
          maxWidth="sm"
          fullWidth={true}
          open={visible}
          //  onClose={handlePopUpClose}
        >
          <DialogTitle>Confirm</DialogTitle>
            {/* <DialogTitle>Your company data is automatically refreshed daily overnight.  Refreshing your data will require retrieving all your company data from QuickBooks Online.  This process may take a few minutes or much longer depending on the amount of data in QuickBooks</DialogTitle> */}
            <DialogContent>
              <span>Your company data is automatically refreshed daily overnight.Refreshing your data will
                 require retrieving all your company data from QuickBooks Online.  This process may take a
                  few minutes or much longer depending on the amount of data in QuickBooks.</span>
            </DialogContent>
            <DialogActions>
              <Button type="submit" color="primary" onClick={refreshData}>
              Refresh My Data Now
              </Button>
              <Button onClick={handlePopUpClose} color="primary" autoFocus>
                Cancel
              </Button>
            </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      // insertCompanies
      // printReports
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RefreshDataModal);

import React from "react";
import { bindActionCreators } from "redux";
import { updateLoan } from '../appRedux/actions/loansAction';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { connect } from "react-redux";
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import moment from 'moment';


class Editloan extends React.Component {

    constructor() {
        super()
        this.state = {
            loanID:'',
            companyID:'',
            vendorID:'',
            financeCompany:'',
            loanDate:'',
            terms:'',
            amortizationPeriod:'',
            interestRate:'',
            monthlyPayments:'',
            balloonPayments:'',
            lastPaymentDueDate:'',
            propertyTypeID:'',
            propertyDetails:'',
            comments:'',
            alertDate:'',
            alertComments:'',
            loanReviewComplete:'',
        }
    }
     componentWillReceiveProps(nextProps) {

        this.setState({
            loanID:nextProps.selectedItem.loanID,
            companyID:nextProps.selectedItem.companyID,
            vendorID:nextProps.selectedItem.vendorID,
            financeCompany:nextProps.selectedItem.financeCompany,
            loanDate:moment(nextProps.selectedItem.loanDate).format('YYYY-MM-DD'),
            terms:nextProps.selectedItem.terms,
            amortizationPeriod:nextProps.selectedItem.amortizationPeriod,
            interestRate:nextProps.selectedItem.interestRate,
            monthlyPayments:nextProps.selectedItem.monthlyPayments,
            balloonPayments:nextProps.selectedItem.balloonPayments,
            lastPaymentDueDate:moment(nextProps.selectedItem.lastPaymentDueDate).format('YYYY-MM-DD'),
            propertyTypeID:nextProps.selectedItem.propertyTypeID,
            propertyDetails:nextProps.selectedItem.propertyDetails,
            comments:nextProps.selectedItem.comments,
            alertDate:moment(nextProps.selectedItem.alertDate).format('YYYY-MM-DD'),
            alertComments:nextProps.selectedItem.alertComments,
            loanReviewComplete:nextProps.selectedItem.loanReviewComplete,
        })
    }

    handleSubmit = (e) => {
         e.preventDefault();
        let data = {
            loanID:this.state.loanID,
            companyID:this.state.companyID,
            vendorID:this.state.vendorID,
            financeCompany:this.state.financeCompany,
            loanDate:this.state.loanDate,
            terms:this.state.terms,
            amortizationPeriod:this.state.amortizationPeriod,
            interestRate:this.state.interestRate,
            monthlyPayments:this.state.monthlyPayments,
            balloonPayments:this.state.balloonPayments,
            lastPaymentDueDate:this.state.lastPaymentDueDate,
            propertyTypeID:this.state.propertyTypeID,
            propertyDetails:this.state.propertyDetails,
            comments:this.state.comments,
            alertDate:this.state.alertDate,
            alertComments:this.state.alertComments,
            loanReviewComplete:this.state.loanReviewComplete,
        }
            this.props.updateLoan(data);
            this.props.handleSave(false);

    }
    handleChange = name => event => {
      this.setState({ [name]: event.target.value });
    };

    handleFormChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

  handleFormCheched = name => event => {

    this.setState({ [name]: event.target.checked });
  };

  handleDelete () {
    let id = this.state.loanID;

     this.props.handleDelete(id);
  }


    handleCancel = () => {
        this.props.handleCancel()
    };

    render() {
        const { visible,classes,propertyTypes,vendorTypes } = this.props;

        return (
            <Dialog
                maxWidth="md"
                fullWidth={true}
                open={visible}
                onClose={this.handleCancel}
            >
              <form method="post" onSubmit={this.handleSubmit}  autoComplete="off" style={{ padding: 20 }}>
                <DialogTitle>Edit Loan</DialogTitle>
                <DialogContent>
                        <fieldset>
                            <Grid container spacing={24} >
                                <Grid item sm={3} >
                                  <InputLabel htmlFor={""}>Vendor</InputLabel>
                                    <Select
                                        name="vendorID"
                                        native
                                        label={"Vendor"}
                                        fullWidth
                                        value={this.state.vendorID}
                                        onChange={this.handleChange('vendorID')}
                                        inputProps={{
                                            name: 'vendorName',
                                            id: 'account-native-simple',
                                        }}
                                        style={{ 'paddingTop': '10px' }}
                                    >
                                     {vendorTypes.length && vendorTypes.map((value, index)=>(
                                      <option value={value.vendorTypeId} key={index}>{value.name}</option>
                                     ))}
                                    </Select>
                                </Grid>
                                 <Grid item sm={3}>
                                    <InputLabel htmlFor={""}>Property Type</InputLabel>
                                    <Select
                                        name="propertyTypeID"
                                        native
                                        onChange={this.handleChange('propertyTypeID')}
                                        value={this.state.propertyTypeID}
                                        fullWidth
                                        inputProps={{
                                            name: 'propertyTypeID',
                                            id: 'account-native-simple',
                                        }}
                                        style={{'paddingTop': '10px' }}
                                    >
                                      {propertyTypes.length && propertyTypes.map((value, index)=>(
                                      <option value={value.propertyTypeID} key={index}>{value.type}</option>
                                     ))}
                                    </Select>
                                </Grid>
                                  <Grid item sm={3} >
                                    <TextField
                                        name="amortizationPeriod"
                                        required={true}
                                        label={"Amortization Period"}
                                        fullWidth
                                        margin="normal"
                                         onChange={this.handleFormChange}
                                        defaultValue={this.state.amortizationPeriod}
                                    />
                                </Grid>
                                  <Grid item sm={3} >
                                    <TextField
                                        name="interestRate"
                                        required={true}
                                        label={"Interest Rate"}
                                        fullWidth
                                        margin="normal"
                                         onChange={this.handleFormChange}
                                        defaultValue={this.state.interestRate}
                                    />
                                </Grid>
                                
                            </Grid>
                            <Grid container spacing={24}>
                             <Grid item sm={3} >
                                    <TextField
                                        name="financeCompany"
                                        required={true}
                                        label={"Financing Company"}
                                        fullWidth
                                        margin="normal"
                                         onChange={this.handleFormChange}
                                        defaultValue={this.state.financeCompany}
                                    />
                                </Grid>
                          
                                <Grid item sm={3} >
                                    <TextField
                                        name="monthlyPayments"
                                        onChange={this.handleFormChange}
                                        label="Monthly Payments"
                                        fullWidth
                                        required={true}
                                        margin="normal"
                                        defaultValue={this.state.monthlyPayments}
                                    />
                                </Grid>
                                 <Grid item sm={3} >
                                    <TextField
                                        name="balloonPayments"
                                        onChange={this.handleFormChange}
                                        label="Ballon Payments"
                                        fullWidth
                                        required={true}
                                        margin="normal"
                                        defaultValue={this.state.balloonPayments}
                                    />
                                </Grid>
                                
                                   <Grid item sm={3}>
                                    <TextField
                                        name="terms"
                                        onChange={this.handleFormChange}
                                        required={true}
                                        label={"Terms(Months)"}
                                        fullWidth
                                        margin="normal"
                                         defaultValue={this.state.terms}
                                    />
                                </Grid>
                               
                            </Grid>
                            <Grid container spacing={24}>
                                  
                                 <Grid item sm={3}>
                                    <TextField
                                        name="loanDate"
                                        id="date"
                                         required={true}
                                        defaultValue={this.state.loanDate}
                                        onChange={this.handleFormChange}
                                        className="leasedate"
                                        label="Loan Date"
                                        type="date"
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item sm={3}>
                                    <TextField
                                        name="alertDate"
                                        id="date"
                                         required={true}
                                        defaultValue={this.state.alertDate}
                                        onChange={this.handleFormChange}
                                        className="leasedate"
                                        label="Alert Date"
                                        type="date"
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                  <Grid item sm={3} >
                                    <TextField
                                        name="lastPaymentDueDate"
                                        id="date"
                                         required={true}
                                        margin="normal"
                                        onChange={this.handleFormChange}
                                        label="Last Due Date"
                                        type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                         defaultValue={this.state.lastPaymentDueDate}
                                    />
                                </Grid>
                                 <Grid item sm={3}>
                                    <TextField
                                        name="propertyDetails"
                                        required={true}
                                        defaultValue={this.state.propertyDetails}
                                        onChange={this.handleFormChange}
                                        label={"Property Details"}
                                        fullWidth
                                        margin="normal"

                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={24}>
                             
                                 <Grid item sm={3} >
                                    <TextField
                                        name="comments"
                                         defaultValue={this.state.comments}
                                        onChange={this.handleFormChange}
                                        required={true}
                                        label={"Comments"}
                                        fullWidth
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item sm={3}>
                                    <TextField
                                        name="alertComments"
                                        required={true}
                                        defaultValue={this.state.alertComments}
                                        onChange={this.handleFormChange}
                                        label={"Alert Comments"}
                                        fullWidth
                                        margin="normal"
                                    />
                                </Grid>
                                   <Grid item sm={3} style={{ 'paddingTop': '34px' }}  >
                                    <Checkbox
                                        className="label_lease"
                                        onChange={this.handleFormCheched('loanReviewComplete')}
                                        checked={this.state.loanReviewComplete}
                                    />
                                    <label>loan Review Complete </label>
                                </Grid>
                            </Grid>
                        </fieldset>
                   
                </DialogContent>
                <DialogActions>
                   
                    <Button type="submit" color="primary">
                       Update
                    </Button>
                     <Button color="primary" onClick={this.handleCancel}>
                        Cancel
                    </Button>
                    
                        <Button onClick={this.handleDelete} color="primary">
                        Delete
                      </Button> 

                </DialogActions>
                 </form>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
     
    }
}
const mapDispatchToProps = (dispatch) => {
     return bindActionCreators({
        updateLoan,
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Editloan);




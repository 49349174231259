import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { postSocialSecurityWageInsertUpdate } from '../appRedux/actions/vendorAction';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class WageLimitForm extends React.Component {


  constructor() {
    super()
    this.state = {
      amount: '',
      year: '',
      socialSecurityWageID: 0
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
  }

  handleCancel = (value = null) => {
    this.setState({
      amount: '',
      year: '',
      socialSecurityWageID: 0
    });
    this.props.handleCancel(value)
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.wagesUpdate !== nextProps.wagesUpdate) {
      if (nextProps.wagesUpdate.transactionStatus == 0) {
        toast.success("Record save successfully.");
        this.handleCancel('yes');
      } else {
        toast.error(` ${nextProps.wagesUpdate.resultMsg}`);
        this.handleCancel('yes');
      }
    }
    if (this.props.isEdit !== nextProps.isEdit) {
      this.setState({
        socialSecurityWageID: nextProps.selectedItem.socialSecurityWageID,
        year: nextProps.selectedItem.socialSecurityWageYear,
        amount: nextProps.selectedItem.socialSecurityWageLimit,
      });
    } else {
      this.setState({
        amount: '',
        year: '',
        socialSecurityWageID: 0
      });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const state = this.state;
    const re = /^[0-9\b]+$/;
    if (re.test(state.amount) && re.test(state.year)) {
      if (state.year && state.amount) {
        let data = {
          socialSecurityWageID: state.socialSecurityWageID,
          socialSecurityWageLimit: state.amount,
          socialSecurityWageYear: state.year
        };
        this.props.postSocialSecurityWageInsertUpdate(data);
      } else {
        toast.error("Year and amount is required.");
      }
    } else {
      toast.error("Only numeric value is allow.");
    }
  }

  handleFormChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleFormChecked = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  render() {
    const { visible, isEdit, entityTypeData, vendorTypeData, selectedItem } = this.props;

    return (
      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={visible}
        onClose={this.handleCancel}
      >
        <form method="post" onSubmit={this.handleSubmit} autoComplete="off" style={{ padding: 20 }}>
          <DialogTitle>{isEdit ? 'Edit' : 'Add'} Wages</DialogTitle>
          <DialogContent>
            <fieldset>
              <Grid container spacing={40} >
                <Grid item sm={6} >
                  <TextField
                    name="year"
                    label="Year"
                    fullWidth
                    required={true}
                    onChange={(e) => this.handleFormChange(e)}
                    defaultValue={this.state.year}
                    margin="normal"
                    inputProps={{ maxLength: 4 }}
                    disabled={this.props.isEdit}
                  />
                </Grid>
                <Grid item sm={12}>
                  {/* <InputLabel htmlFor="comments" style={{marginTop:0}}>Comments</InputLabel> */}
                  <TextField
                    name="amount"
                    label="Wage Limit Amount"
                    fullWidth
                    required={true}
                    onChange={(e) => this.handleFormChange(e)}
                    defaultValue={this.state.amount}
                    margin="normal"
                    inputProps={{ maxLength: 14 }}
                  />
                </Grid>
              </Grid>
            </fieldset>
          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary">
              {isEdit ? 'Update' : 'Save'}
            </Button>
            <Button onClick={this.handleCancel} color="primary" autoFocus>
              Cancel
                </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }


}

const mapStateToProps = (state) => {
  return {
    wagesUpdate: state.vendors.wagesUpdate
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    postSocialSecurityWageInsertUpdate
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(WageLimitForm);
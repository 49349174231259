import * as SyncLogService from './../services/SyncLogService';
import { toast } from "react-toastify";
export const GET_SYNC_DATA = 'GET_SYNC_DATA';
export const GET_SYNC_DETAILS = "GET_SYNC_DETAILS";


export const GetAllSyncLogs = (data) => {

    return (dispatch) => {
        SyncLogService.GetSyncData(data)
            .then((response) => {
                dispatch({ type: GET_SYNC_DATA, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`);
            });
    }
}


export const GetSyncDetails = (data) => {

    return (dispatch) => {
        SyncLogService.GetSyncDetails(data)
            .then((response) => {
                dispatch({ type: GET_SYNC_DETAILS, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`);
            });
    }
}
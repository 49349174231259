import {
    INSURANCE_SUCCESS,
    INSURANCE_ERROR,
    ADD_INSURANCE_SUCCESS,
    ADD_INSURANCE_ERROR,
    UPDATE_INSURANCE_SUCCESS,
    UPDATE_INSURANCE_ERROR,
    DELETE_INSURANCE_SUCCESS,
    DELETE_INSURANCE_ERROR,
    INSURANCE_POLICY_SUCCESS,
    INSURANCE_POLICY_ERROR
 } from '../actions/insuranceAction';


const initial_state = {
    insurances:[],
    policyTypes:[],
    error:'',
};

export default function insuranceReducer(state = initial_state, action) {
    switch (action.type) {
        case INSURANCE_SUCCESS:
            return {...state,
             insurances:action.payload.data,reload:false
         };
        case INSURANCE_POLICY_SUCCESS:
            return {
                ...state,
               policyTypes:action.payload.data
         };
         case 'RELOAD':
             return {...state, reload:true};
        default:
            return state;
    }
}

import React, { Component } from 'react';
import IntlMessages from "../../../../util/IntlMessages";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import SearchBox from '../../../../components/SearchBox';
import ReactTable from "react-table";
import "react-table/react-table.css";
import CircularProgress from "../../../../components/CircularProgress";
import AddInsurance from '../../../../Forms/AddInsuranceForm'
import { getInsurances, searchInsurance, deleteInsurance, getPolicyTypes} from "../../../../appRedux/actions/insuranceAction";
import { getAllEmployees } from '../../../../appRedux/actions/employeeAction';
import moment from 'moment';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Table, Pagination  } from 'antd';
import { vendorTypes,Vendors } from '../../../../appRedux/actions/vendorAction';
import { Resizable } from 'react-resizable';
import "../../styles.css";
import { TEXT } from 'react-dnd-html5-backend/lib/NativeTypes';

const ResizeableTitle = props => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable width={width} height={0} onResize={onResize}>
      <th {...restProps} />
    </Resizable>
  );
};

class InsurancePage extends Component {

    constructor(props) {
        super(props);
        let column = [{
            title: 'Insurance Company',
            dataIndex: 'vendorName',
            className:'table-heading',
            sorter:  true,
            width:180
        },{
            title: 'Policy Number',
            dataIndex: 'policyNumber',
            className:'table-heading',
            sorter:  true,
            width:180
        },{
          title: 'Policy Type',
          dataIndex: 'policyType',
          className:'table-heading',
          sorter:  true,
          width:180
      },
        {
            title: 'Employee Name',
            dataIndex: 'employeeName',
            className:'table-heading',
            sorter:  true,
            width:180
        },
        {
            title: 'Personal Beneficiary Percent',
            dataIndex: 'personalBeneficiaryPercent',
            className:'table-heading',
            render: text => <span title={text}>
              {text} %
            </span>,
            sorter:  true,
            sorter:  true,
            width:120
        },
        {
            title: 'Employee CoPay',
            dataIndex: 'employeeCoPay',
            className:'table-heading',
            sorter:  true,
            width:150
        },
        {
            title: 'Comments',
            dataIndex: 'comments',
            className:'table-heading',
            sorter:  true,
            width:250
        }
        ,{
            title: 'Termination Date',
            dataIndex: 'terminationDate',
            className:'table-heading',
            sorter:  true,
            width:160,
            render: text => <span>
              {!text?'':moment(text).format("L")}
            </span>,
        },{
          title: 'Policy Review Date',
          dataIndex: 'policyReviewDate',
          className:'table-heading',
          sorter:  true,
          width:160,
          render: text => <span>
            {!text?'':moment(text).format("L")}
          </span>,
      },{
            title: 'Policy Review Complete',
            dataIndex: 'isReviewComplete',
            className:'table-heading',
            sorter:  true,
            
            render: text => <span>
              {text ? "Yes" : ""}
            </span>,
        }


        ];


        this.state = {
            pageSize:50,
            currentPage:1,
            totalRowsInTable:0,
            fieldName:'insurancePolicyId',
            sortFormat:'A',
            searchText:'',
            search:false,
            isEdit: false,
            isAdd: false,
            isDel: false,
            loading: true,
            columns: column,
            selectedInsurance: false,
            policyTypes:[],
            insurancesData:[],
            companyId:'',
        }

        this.handleAddModel = this.handleAddModel.bind(this);
    }
    componentDidMount() {
         this.listData();
         this.props.getPolicyTypes();
         this.props.vendorTypes();
         let user = JSON.parse(localStorage.getItem('userData'));
         this.props.Vendors(user.companyId);
         
         this.props.getAllEmployees(user.companyId);
    }
    listData()
    {
      let user = JSON.parse(localStorage.getItem('userData'));
      const CompanyId = user.companyId;
      this.setState({companyId:user.companyId})
      this.props.getInsurances(CompanyId,this.state.searchText,this.state.currentPage,this.state.pageSize,this.state.fieldName,this.state.sortFormat);
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.insurances!==undefined && nextProps.insurances !== this.props.insurances) {
          if(nextProps.insurances.length>0)
             this.state.totalRowsInTable = nextProps.insurances[0].totalrows;
            this.setState({insurancesData: nextProps.insurances });
        }
        if (nextProps.policyTypes!==undefined && nextProps.policyTypes!==undefined && nextProps.policyTypes !== this.props.policyTypes) {
            this.setState({ loading: false, policyTypes: nextProps.policyTypes });
        }
        if(nextProps.reload==true)
          this.listData();
    }

     handleDelete = (record) => {
         var id = record.original.insurancePolicyId
        this.props.deleteInsurance(id);
    }

    handleCancel = () => {

        this.setState({ isDel: false, isEdit: false, isAdd: false });
    };

    handleAddModel = () => {
        this.setState({ isEdit: false, isAdd:true });
    }


    handleChange = (text) => {
      var self=this;
      if(text) {

        self.state.searchText = text;


        } else {
          self.state.searchText = '';

        }
        self.state.currentPage =1;
        this.setState({search:true});

      clearTimeout(self.state.timeout);

      // Make a new timeout set to go off in 800ms
      self.state.timeout = setTimeout(function () {
        self.listData();

      }, 500);

      }


    handleSaveUser = (state) => {this.setState({ isAdd:state })}

    handleSave = (state) => {this.setState({ isEdit:state, isAdd:state })}

    handleTableChange = (pagination, filters, sorter) => {
      if(sorter.order)
      {
        this.state.fieldName = sorter.field;
        if(sorter.order =="ascend")
          this.state.sortFormat = "A";
        else
          this.state.sortFormat = "D";
      }
      else
      {
        this.state.fieldName = "insurancePolicyId";
        this.state.sortFormat = "A";
      }
      this.listData();
    }
    onShowSizeChange(current, pageSize) {
		this.state.currentPage = current;
		this.state.pageSize = pageSize;
		this.listData();	  
	}
  handleEditModal= (record) =>{
       var id=record.key;
       var data=this.state.insurancesData;        
       if(id!=0)
        {
           const selectedInsurance = _.find(data, {insurancePolicyId:id});
           this.setState({
               selectedInsurance:_.cloneDeep(selectedInsurance),
               isAdd:false,
               isEdit:true,
           });
       }

   }
   handleResize = index => (e, { size }) => {
       this.setState(({ columns }) => {
         const nextColumns = [...columns];
         nextColumns[index] = {
           ...nextColumns[index],
           width: size.width,
         };
         return { columns: nextColumns };
       });
     };
    render() {
         let { searchText,selectedInsurance, insurancesData, search } = this.state;
         const columns = this.state.columns.map((col, index) => ({
             ...col,
             onHeaderCell: column => ({
               width: column.width,
               onResize: this.handleResize(index),
             }),
           }));
           const components = {
             header: {
               cell: ResizeableTitle,
             },
           };
          if(search) {
         insurancesData = insurancesData.length && insurancesData.filter(obj=> {
            if(obj.name.toLowerCase().includes(searchText.toLowerCase()) ===  true ) {
               return obj;
            }
        })
        }
        let filteredData = insurancesData.length && insurancesData.map((d,index)=>({
            key:d.insurancePolicyId,
            ...d
        }))
        return (
            <div className="" style={{padding:15}}>
              <AddInsurance
                  handleSave={this.handleCancel}
                  policyTypes={this.state.policyTypes}
                  vendorTypes={this.props.vendorType}
                  vendors={this.props.vendors}
                  employees={this.props.employees}
                  companyId={this.state.companyId}
                  handleCancel={this.handleCancel}
                  visible={this.state.isAdd || this.state.isEdit}
                  handleCancel={this.handleCancel}
             		  selectedItem={selectedInsurance}
             		  handleDelete={this.handleDelete}
             		  isEdit={this.state.isEdit}
               />



                <Grid
                    container
                    spacing={24}
                    style={{ 'marginBottom': 10 }}
                >
                    <Grid item xs={12} sm={6} >
                        <h2 className="user-title">Insurance Policies</h2>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid
                        container
                         spacing={8}
                         direction="row"
                         justify="flex-end"
                         alignItems="flex-end"
                        >
                        <Grid item  >
                        <SearchBox
                           styleName="d-none d-lg-block"
                           placeholder="Search... "
                           value={searchText}
                           onChange= { (e) => {  this.handleChange(e.target.value) }}
                           style={{ background: '#eeeeeeb8'}}
                         />
                         </Grid>
                         <Grid item  >
                        <Button variant="contained" color="primary" onClick={this.handleAddModel}>
                            Add Insurance Policy
                        </Button>
                         </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {this.state.loading
                    ?
                    <CircularProgress />
                    :
                    <div style={{overflowX:'auto'}}><Table style={{maxHeight:'410px',overflowY:'scroll'}}
                        className="table-layout"
                        columns={columns}
                        components={components}
                        dataSource={filteredData}
                        rowClassName={ (record) =>  this.state.selectedItemKey == record.key  ? 'data-row selected' : 'data-row'  }
                        bordered={true}
                        size={"small"}
                        onRowClick={ (record, index, event) => this.handleEditModal(record) }
                        onRowDoubleClick={ (record, index, event) => this.handleEditModal(record) }
                        onChange={this.handleTableChange}
                        loading={this.state.loading}
                        pagination={false}
                        //scroll={{y:window.innerHeight-301}}
                        />
                        <Pagination
                        pageSizeOptions={['10','25','50','100']}
                        pageSize={this.state.pageSize}
                        style={{marginTop:'10px',marginBottom:'10px'}}
                        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                        showSizeChanger onChange={(a,b)=>this.onShowSizeChange(a,b)} onShowSizeChange={(a,b)=>this.onShowSizeChange(a,b)} defaultCurrent={1} total={this.state.totalRowsInTable} />
                        </div>
                }
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        insurances: state.insurance.insurances,
        reload: state.insurance.reload,
        policyTypes:state.insurance.policyTypes,
        vendorType:state.vendors.vendorTypes,
        vendors:state.vendors.vendors,
        employees: state.employee.employees,
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getInsurances,
        getPolicyTypes,
        deleteInsurance,
        searchInsurance,
        vendorTypes,
        Vendors,
        getAllEmployees
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(InsurancePage);

import baseService from './baseService';

export function LedgerDetailService(companyId) {
    return baseService.get('/QuickbookGeneralLedger/all', { params: { companyId } });
}

export function GetUncategorizeData(data)
{
    return baseService.post("/QuickbookGeneralLedger/GetUncategorizedTransactionsData_ByCompany",data);
}
export function ReviewReconciliationDiscrepanciesData(data)
{
    return baseService.post("/QuickbookGeneralLedger/GetReviewReconciliationDiscrepancies_ByCompany",data);
}

export function MarkReportReview(companyId,reportType){
    return baseService.post("/CompanyDashboard/AddDataEntryIssuesReviewedHistory?companyID="+companyId+"&reportType="+reportType);
}

export function GetUncategorizeData_BES(data)
{
    return baseService.post("/QuickbookGeneralLedger/GetUncategorizedTransactionsData_ByCompany_BES",data);
}
export function GetReviewReconciliationDiscrepancies_BES(data)
{
    return baseService.post("/QuickbookGeneralLedger/GetReviewReconciliationDiscrepancies_ByCompany_BES",data);
}

export function GetLedgerDetailService(companyId, search, pageNumber, pageSize, sortField, sortFormat, fromDate, toDate, searchField, searchText) {
    return baseService.post('/QuickbookGeneralLedger/all_page', { companyId, search, pageNumber, pageSize, sortField, sortFormat, fromDate, toDate, searchField, searchText });
}

export function GetLedgerDetailService_New(data) {
    return baseService.post('/QuickbookGeneralLedger/all_page', data);
}

export function LedgerUpdateService(data, companyId) {
    return baseService.post('/QuickbookGeneralLedger', data, { params: { companyId } });
}


export function LedgerUpdateService_BES(data, companyId) {
    return baseService.post('/QuickbookGeneralLedger/Update_BES', data, { params: { companyId } });
}




export function LedgerLastLog(companyId) {
    return baseService.get('/QuickbookGeneralLedger/get_log?id=' + companyId);
}


export function MarkAsReview(data) {
    return baseService.post("/QuickbookGeneralLedger/MarkAsReview",data);
}

export function GetReportReviewedHistory(companyId,reportType) {
    return baseService.get("/CompanyDashboard/GetDataEntryIssuesReviewedHistory?companyID="+companyId+"&reportType="+reportType);
}

export function StartReview(data){
    return baseService.post("/QuickbookGeneralLedger/StartAsReview",data);
}

export function Get1099ComplianceReview(data) {
    return baseService.post("/Vendor1099Report/Vendor1099ComplianceReview",data);
}

export function Get1099ComplianceReview_BES(data) {
    return baseService.post("/Vendor1099Report/Vendor1099ComplianceReview_BES",data);
}

export function MarkVendorReview(companyId){
    return baseService.post("/Vendor1099Report/Vendor1099ComplianceReviewReviewed?CompanyID="+companyId);
}

export function GetReviewVendorHistory(companyId)
{
    return baseService.get("/Vendor1099Report/GetHistory1099ComplianceReviewReviewed?CompanyID="+companyId);
}

export function UpdateVendor1099(data){
    return baseService.post("/Vendor1099Report/Vendor1099StatusChange",data);
}

export function EmployeeStatusApprove(data)
{
	return baseService.post("/Vendor1099Report/EmployeeStatusApprovedStatusChange",data);
}

export function EmployeeStatusApprove_BES(data)
{
	return baseService.post("/Vendor1099Report/EmployeeStatusApprovedStatusChange_BES",data);
}

export function InsertUpdateComplianceReviewNotes(data){
    return baseService.post("/Vendor1099Report/InsertUpdateComplianceReviewNotes",data);
}

export function GetComplianceReviewNotes(CompanyID,ReportType)
{
    return baseService.get("/Vendor1099Report/GetComplianceReviewNotes?CompanyID=" + CompanyID + "&ReportType=" + ReportType);
}


export function UpdateBatchNewAccountService(data) {
    return baseService.post('/QuickbookGeneralLedger/UpdateBatchNewAccount', data);
}

export function UpdateBatchNewAccountService_BES(data) {
    return baseService.post('/QuickbookGeneralLedger/UpdateBatchNewAccount_BES', data);
}

export function UpdateBatchVendorService(data) {
    return baseService.post('/QuickbookGeneralLedger/UpdateBatchVendor', data);
}

export function UpdateBatchVendorService_BES(data) {
    return baseService.post('/QuickbookGeneralLedger/UpdateBatchVendor_BES', data);
}

export function Update1099ReviewExcludeTotalStatus(data) {
    return baseService.post('/Vendor1099Report/Update1099ReviewExcludeTotalStatus', data);
}

export function Update1099ReviewExcludeTotalStatus_BES(data) {
    return baseService.post('/Vendor1099Report/Update1099ReviewExcludeTotalStatus_BES', data);
}
import React, { Component } from 'react'; //, useState
import Button from '@material-ui/core/Button';
import CircularProgress from "./../../components/CircularProgress";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import TextArea from 'antd/lib/input/TextArea';
import { withStyles } from '@material-ui/core/styles';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


class CheckLedgerData extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            ShowDownload: false,
            jsonData: null,
            finalData: [],
            loading: false,
            HeadData: [],
            headers: [],
            csvData: [],
            FileName:"qbo.csv"
        }
    }

    componentDidMount() {

    }


    onRefreshDataClick = () => {

    }

    handleMessage = (event) => {
        let data = event.target.value;

        if (data != null && data.trim() != "") {
            this.setState({ jsonData: data });
        }
        else {
            this.setState({ finalData: [] });
        }
    };

    onGenerateClick = () => {
        let data = this.state.jsonData;
        if (data != null && data.trim() != "" && data.trim().length > 0) {
            try {

                var temp = JSON.parse(data);
                if (temp != null) {
                    let items = [];
                    let headerItems = [];
                    headerItems = this.GetHeader(temp.Columns.Column);
                    let convertedArray = [];
                    convertedArray=this.BindArray(items, temp, headerItems);                   
                    this.setState({ HeadData: headerItems, finalData:convertedArray,csvData:convertedArray})
                    
                }
                else {
                    toast.error("Unable to parse json");
                }
            }
            catch (err) {
                toast.error("Unable to parse json");
            }
        }
        else {
            toast.error("Please enter proper data");
        }
    }

    onExportClick = () =>{
        document.getElementById("csvlink").click();
    }

    GetHeader = (columns) => {
        let data = [];
        let exportHeader=[];
        for (let i = 0; i < columns.length; i++) {
            data.push(columns[i].ColTitle);
            exportHeader.push({ label: columns[i].ColTitle, key: columns[i].ColTitle})
        }

        this.setState({headers:exportHeader});
        return data;
    }

    BindArray = (existingArray, items, Headers) => {

        let temp = null;
        items.Rows.Row.map((data, index) => {


            if (data.Header !== undefined && data.Header != null) {
                temp = this.GetNewObjectByHeader(Headers);
                existingArray.push(temp);
            }
            if ((data.type === "Section") && data.Rows !== undefined && data.Rows.Row != undefined) {
                this.BindArray(existingArray, data, Headers);
            }

            if (data.type === "Data" && data.ColData != null && data.ColData.length > 0) {

                temp = this.GetNewObjectByHeader(Headers);

                for (let i = 0; i < Headers.length; i++) {
                    try {
                        temp[Headers[i]] = data.ColData[i].value;
                    }
                    catch (err) { }

                }
                existingArray.push(temp);
            }

            if (data.Summary != null && data.Summary !== undefined) {
                temp = this.GetNewObjectByHeader(Headers);
                for (let i = 0; i < Headers.length; i++) {
                    try {
                        temp[Headers[i]] = data.Summary.ColData[i].value
                    }
                    catch (err) { }

                }
                existingArray.push(temp);
            }
        });

        return existingArray;
    }

    GetNewObjectByHeader = (headers) => {
        let obj = {};
        if (headers != null) {
            for (let i = 0; i < headers.length; i++) {
                obj[headers[i]] = null;
            }
        }


        return obj;
    }


    render() {

        let headers = this.state.HeadData;
        return (
            <React.Fragment>
                <ToastContainer autoClose={3000} />
                <div className="dashboardPage embeded-dashboard">
                    <React.Fragment>
                        <div className="custome-modal">
                            <div className="close-btn-box">

                            </div>
                            <div className="modal-detail-box">

                            </div>
                        </div>
                        <div style={{ display: "none" }}>
                            {this.state.headers != null && this.state.headers.length > 0 &&
                                <CSVLink headers={this.state.headers} data={this.state.csvData} filename={this.state.FileName} id="csvlink">Download me</CSVLink>
                            }


                        </div>

                        <div className="page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
                            <h2 className="title mb-3 mb-sm-0">Checking Ledger Data</h2>
                        </div>
                        <div className="padding-box app-wrapper">
                            <div className="row">

                                <div className="col-md-8">
                                    <div >
                                        <TextArea rows="8" cols="80" placeholder="Enter data"
                                            onChange={this.handleMessage} value={this.state.jsonData} className="textarea-box">
                                        </TextArea>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <Button variant="raised" className="jr-btn bg-teal text-white refresh-btn greenncolor" onClick={() => this.onGenerateClick()}>Generate Data</Button>
                                    {this.state.csvData!= null && this.state.csvData.length>0 &&
                                        <Button variant="raised" className="jr-btn bg-teal text-white refresh-btn graycolor" onClick={() => this.onExportClick()}>Export</Button>
                                    }
                                </div>
                            </div>
                            {this.state.loading ? <CircularProgress></CircularProgress>
                                :
                                <div className="table-responsive-material" style={{ maxHeight: "500px" }}>
                                    <table className="table tablebox account-reconcilliation">
                                        <thead>
                                            <tr>
                                                {(this.state.HeadData && this.state.HeadData.length > 0) && this.state.HeadData.map((item, j) => (
                                                    <React.Fragment key={j}>
                                                        <th>{item}</th>
                                                    </React.Fragment>
                                                ))}
                                            </tr>

                                        </thead>
                                        <tbody>
                                            {this.state.finalData != null && this.state.finalData.length > 0 ?

                                                <React.Fragment>
                                                    {(this.state.finalData && this.state.finalData.length > 0) && this.state.finalData.map((item, j) => (
                                                        <React.Fragment key={j}>
                                                            {<tr>
                                                                {headers.map((itemdata, p) => (
                                                                    <React.Fragment key={j}>
                                                                        <td>{item[headers[p]]}</td>
                                                                    </React.Fragment>
                                                                ))}
                                                            </tr>}

                                                        </React.Fragment>
                                                    ))}
                                                </React.Fragment>
                                                :
                                                <tr>
                                                    <td colSpan="2"> No issues here! Keep up the good work!</td>
                                                </tr>

                                            }

                                        </tbody>
                                    </table>
                                </div>

                            }
                        </div>
                    </React.Fragment>

                </div>
            </React.Fragment>
        )

    }


}

export default CheckLedgerData;
import baseService from './baseService';

export function getLeaseList(companyId){
    return baseService.get(`/Lease/all`,{ params:{ companyId } });
}
export function getLeases(CompanyID,search,pageNumber,pageSize,sortField,sortFormat){
    return baseService.post(`/Lease/all_page`,{ CompanyID,search,pageNumber,pageSize,sortField,sortFormat });
}
export function getLeaseTypes(){
    return baseService.get(`/Lease/leasetypes`);
}

export function addLease(data){
    return baseService.post(`/Lease`, data);
}

export function updateLease(data){
    return baseService.put(`/Lease`, data);
}

export function deleteLease(value){
    return baseService.delete(`/Lease`, { params:{ leaseId:value } });
}

export function searchLease(companyId,text)
{
    return baseService.get(`Lease/${companyId}/search/${text}`);
}

import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import "./EmbeddedReports.css"


class EmbeddedReports extends Component{
    constructor(props) {
        super(props);
        this.state = {
            // bulKOpen:-1

        }
    }

 
    render() {
        const pathname = window.location.pathname;
        
        // const { hash, pathname, search } = location;
        return (
            <React.Fragment>
            {pathname == '/BSA'
             ? <iframe height='1200px' width='100%' frameborder='0' allowTransparency='true' scrolling='auto' src='https://creatorapp.zohopublic.com/bwhitfield/compliance-manager-data/report-embed/Grouped_Balance_Sheet/Xdu6v7vFrZVuX6TydEuzyVZGks92M1V98jmpdvEgOZX4jjzdCQWSrNjy6ssuUjTgAEvqxT0EhRaHKu7zdwbw6uY7tAGAa1W1eMCU'></iframe>
             : pathname == '/AP' 
             ? <iframe height='1200px' width='100%' frameborder='0' allowTransparency='true' scrolling='auto' src='https://creatorapp.zohopublic.com/bwhitfield/compliance-manager-data/report-embed/Accounts_Payable/qMRpVzfxqMdVS6x7FzWz6XqRPQKdUURah3P7w3k8YwChgTD5ByCFnyyfn398u4gR0OyUDPBOtOx6MmkfQFHeU68JUX7aff7AY68F'></iframe>
             : pathname == '/TL' 
             ? <iframe height='1200px' width='100%' frameborder='0' allowTransparency='true' scrolling='auto' src='https://creatorapp.zohopublic.com/bwhitfield/compliance-manager-data/report-embed/Tax_Liabilities/HjzD3Q63Mg0SmwZWO9JWVpNdqP2jxtYw5mt0MMZCpEA1QjArT26HuHsFxG0TVj5MJKvxnS7mxbt1xO9JSgDspgv0A3gW2sSdFsUT'></iframe>
             : pathname == '/LTL' 
             ? <iframe height='1200px' width='100%' frameborder='0' allowTransparency='true' scrolling='auto' src='https://creatorapp.zohopublic.com/bwhitfield/compliance-manager-data/report-embed/Long_Term_Liabilities/VtwZbPsU6kn6j5MgrGS9kY5ZBXkC6tJTDd44ZjsAmxkKD45Sdmx7UQsUbs2EyVVEDEhRgd5VEMJ0qFapRfRS0r8EVV6JubpsTOCU'></iframe>
             : pathname == '/OBSA' 
             ? <iframe height='1200px' width='100%' frameborder='0' allowTransparency='true' scrolling='auto' src='https://creatorapp.zohopublic.com/bwhitfield/compliance-manager-data/report-embed/Other_Balance_Sheet_Accounts/gmDQU9vmvn1X917JJDSwsmUKrfMk2dN5H4dFjdS01Kaum9JmajvxZVxdbSkeTw0KJXMtxTwUDA5RAWkfGS68XYF2wW7rDXChxV3r'></iframe>
             : pathname == '/RBSN' 
             ? <iframe height='1200px' width='100%' frameborder='0' allowTransparency='true' scrolling='auto' src='https://creatorapp.zohopublic.com/bwhitfield/compliance-manager-data/report-embed/Review_Balance_Sheet_Notes/Js1wh7gvhw073nMFskejUrF5W20X452PKfgNRNJw9JV1rGR1r56HtYwkf6ArG1DYP7BhzxQqNXsgnK4HhqByJJ5082yF9dxbp4yG'></iframe>
             : ""
            }
            </React.Fragment>
        )

    }

}


const mapStateToProps = (state) => {
    return {
    //   ComplianceReviewData: state.ledgers.ComplianceReviewData
    }
  }
  const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
    //   Get1099ComplianceReview
    }, dispatch)
  }

// export default VimeoInstructionLoader;
export default connect(mapStateToProps, mapDispatchToProps)(EmbeddedReports);
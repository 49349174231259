import React, { Component } from 'react';
import { post_TaxableWagesReport, InsertWagesQBOData, ArchivedTaxableWagesReport } from "../../../../appRedux/actions/companiesAction";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CircularProgress from "../../../../components/CircularProgress";
import { numberWithCommas, GetLastQuarterEndDate, totalTaxableWagesComputations, totalSocialSecurityWagesComputation, totalMedicareWagesComputation,LinkType } from '../../../common/commonServices';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { toast } from 'react-toastify';
import { stat } from 'fs';

class TaxableWagesComputations extends Component {
    constructor(props) {
        super(props);

        this.sDateRef = React.createRef();
        this.eDateRef = React.createRef();
        let eDate = new Date();
        let sDate = new Date(eDate.getFullYear(), 0, 1);
        if (eDate.getMonth() <= 2) {
            sDate.setFullYear(eDate.getFullYear() - 1, 0, 1);
            eDate = new Date(eDate.getFullYear() - 1, 11, 31);
        }
        else {
            var d = new Date();
            var quarter = Math.floor((d.getMonth() / 3))
            sDate = new Date(d.getFullYear(), quarter * 3 - 3, 1);
            eDate = GetLastQuarterEndDate();
        }

        this.allemployeeData = {
            salaryWages: 0, otherTaxIncome: 0, preTaxDeduction: 0, taxableWages: 0,
            retirementsPlan: 0, healthInsurance: 0, housingAllowance: 0, reimbursements: 0
        };

        this.nonClergyTotal = {
            salaryWages: 0, otherTaxIncome: 0, preTaxDeduction: 0, taxableWages: 0,
            retirementsPlan: 0, healthInsurance: 0, housingAllowance: 0, reimbursements: 0
        };


        this.state = {
            ColorCode: "#46b29d",
            loading: false,
            nonclgEmp: [],
            empTypeData: [],
            allemployeeData: this.allemployeeData,
            companyId: 0,
            eDate: eDate,
            sDate: sDate,
            Total: 0,
            ClergyCount: 0,
            nonClergyTotal: this.nonClergyTotal,
            TotalQBOWages: 0,
            TotalSecurityWages: 0,
            TotalMedicareWages: 0,
            TotalTaxDiff: 0,
            SecurityDiff: 0,
            MedicareDiff: 0,
            ArchivedList: [],
            IsArchiveProcessing: false,
            visibleArchived: false
        }
    }

    ApplyDate = () => {
        this.setState({ loading: true }, () => {
            this.GetReportData();
        });
    }

    handleFormChange = (e, type) => {
        let value = e.target.value;
        if (value === "" || value == null) {
            value = 0;
        }
        
        var exp = new RegExp(/^\d*\.?\d*$/);
        if (value != null) {
            let total = 0;
            if(!value.length===undefined)
            {
                value.substring(0, value.length - 1);
            }
            
            if (!exp.test(value)) {
                toast.error("Invalid Character"); return false;
            }
            if (type === "TotalMedicareWages") {
                let nonClery = this.state.nonClergyTotal;
                total = numberWithCommas((totalMedicareWagesComputation(nonClery.salaryWages, nonClery.otherTaxIncome, 0,
                    nonClery.healthInsurance, nonClery.preTaxDeduction) - value).toFixed(2));
                this.setState({ TotalMedicareWages: value, MedicareDiff: total });
            } else if (type === "TotalSecurityWages") {
                let nonClery = this.state.nonClergyTotal;
                total = numberWithCommas((totalSocialSecurityWagesComputation(nonClery.salaryWages, nonClery.otherTaxIncome, 0,
                    nonClery.healthInsurance, nonClery.preTaxDeduction) - value).toFixed(2));
                this.setState({ TotalSecurityWages: value, SecurityDiff: total });
            }
            else if (type === "TotalQBOWages") {
                total = numberWithCommas((totalTaxableWagesComputations(this.state.allemployeeData.salaryWages, this.state.allemployeeData.otherTaxIncome, 0, 0, this.state.allemployeeData.healthInsurance, this.state.allemployeeData.preTaxDeduction, this.state.allemployeeData.retirementsPlan) - value).toFixed(2));
                this.setState({ TotalQBOWages: value, TotalTaxDiff: total });
            }
        }
    }

    handleFocus = (e, selecter) => {
        let control = null;
        if (selecter !== undefined) {
            if (selecter === "sDate") {
                control = this.sDateRef;
                if (control.current != null) {
                    control.current.setFocus();
                }
            }
            else if (selecter === "eDate") {
                control = this.eDateRef;
                if (control.current != null) {
                    control.current.setFocus();
                }
            }
        }
    }

    handleDateChange = (e, target) => {
        if (target === "fromDate") {
            this.setState({ sDate: new Date(e), selectedDateRange: { startDate: moment(new Date(e)).format("MM/DD/YYYY"), endDate: moment(this.state.eDate).format("MM/DD/YYYY") } })
        }
        else if (target === "uptoDate") {
            this.setState({ eDate: new Date(e), selectedDateRange: { startDate: moment(this.state.sDate).format("MM/DD/YYYY"), endDate: moment(new Date(e)).format("MM/DD/YYYY") } })
        }
    }

    GetReportData = () => {
        let body = {
            companyID: this.state.companyId,
            pageNumber: 1,
            pageSize: 100,
            fromDate: moment(this.state.sDate).format("MM/DD/YYYY"),
            toDate: moment(this.state.eDate).format("MM/DD/YYYY"),
        }
        this.props.post_TaxableWagesReport(body);
    }

    componentWillReceiveProps(nextprops) {
        if (nextprops.taxableWagesData!==undefined && nextprops.taxableWagesData !== this.props.taxableWagesData && !this.state.IsArchiveProcessing) {  
            var returnArray = nextprops.taxableWagesData.returnObject.employeeWiseData;
            if (returnArray != null && returnArray.length > 0) {
                returnArray = returnArray.sort((a, b) => (a.employeeType > b.employeeType) ? 1 : ((b.employeeType > a.employeeType) ? -1 : 0));
            }

            this.allemployeeData = this.calculateAllEmployee(nextprops);

            if (nextprops.taxableWagesData.transactionStatus == 0 && returnArray) {
                returnArray.map(mainObj => {
                    var salaryWages = 0, otherTaxIncome = 0, healthInsurance = 0, preTaxDeduction = 0, taxableWages = 0, housingAllowance = 0, reimbursements = 0, retirementsPlan = 0;
                    mainObj.data.map(subObj => {
                        subObj.taxableWages = subObj.salaryWages + subObj.otherTaxIncome - subObj.healthInsurance - subObj.preTaxDeduction - subObj.retirementsPlan;
                        salaryWages += subObj.salaryWages;
                        otherTaxIncome += subObj.otherTaxIncome;
                        healthInsurance += subObj.healthInsurance;
                        preTaxDeduction += subObj.preTaxDeduction;
                        retirementsPlan += subObj.retirementsPlan;
                        taxableWages += subObj.taxableWages;
                        housingAllowance += subObj.housingAllowance;
                        // reimbursements += subObj.reimbursements;
                    });
                    mainObj.totalSalaryWages = salaryWages; mainObj.totalOtherTaxIncome = otherTaxIncome;
                    mainObj.totalHealthInsurance = healthInsurance; mainObj.totalPreTaxDeduction = preTaxDeduction;
                    mainObj.totalRetirementsPlan = retirementsPlan; mainObj.totalTaxableWages = taxableWages;
                    mainObj.totalHousingAllowance = housingAllowance; mainObj.totalReimbursements = reimbursements;
                });

                this.nonClergyTotal = this.calculateNonClergyTotal(returnArray, this.allemployeeData, nextprops);
                let CC = 0;
                if (returnArray && returnArray.length > 0) {
                    try {

                        let temp = returnArray.filter(function (a) { return a.employeeType === "Clergy" });
                        if (temp != null && temp.length > 0) {
                            CC = temp[0].data.length;
                        }
                    }
                    catch (err) { }
                }
                this.setState({ empTypeData: returnArray, IsArchiveProcessing: false, ArchivedList: nextprops.taxableWagesData.returnObject.taxableWagesArchive, nonClergyTotal: this.nonClergyTotal, loading: false, allemployeeData: this.allemployeeData, ClergyCount: CC });
            } else {
                this.setState({ empTypeData: [], IsArchiveProcessing: false, loading: false, allemployeeData: this.allemployeeData });
            }
        }

        if (nextprops.wagesUpdate!==undefined && nextprops.wagesUpdate !== this.props.wagesUpdate) {
            if (nextprops.wagesUpdate.transactionStatus === 0) {
                this.setState({ loading: false, IsArchiveProcessing: false }, () => { toast.success("Data save successfully"); });
            }
            else {
                this.setState({ loading: false, IsArchiveProcessing: false }, () => { toast.error( nextprops.wagesUpdate.resultMsg); });
            }
        }

        if (nextprops.wagesArchiveData!==undefined && nextprops.wagesArchiveData !== this.props.wagesArchiveData) {
            if (nextprops.wagesArchiveData.transactionStatus === 0) {
                this.setState({ loading: false, IsArchiveProcessing: false ,ArchivedList:nextprops.wagesArchiveData.returnObject}, () => { toast.success("Archived successfully"); });
            }
            else {
                this.setState({ loading: false, IsArchiveProcessing: false }, () => { toast.error(nextprops.wagesArchiveData.resultMsg); });
            }
        }
    }

    SaveTotal = () => {
        let body = {
            "companyID": this.state.companyId,
            "fromDate": moment(this.state.sDate).format("YYYY-MM-DD"),
            "toDate": moment(this.state.eDate).format("YYYY-MM-DD"),
            "totalTaxableWages": this.state.TotalQBOWages,
            "totalSocialSecurityWages": this.state.TotalSecurityWages,
            "totalMedicareWages": this.state.TotalMedicareWages
        }

        this.setState({ loading: true }, () => { this.props.InsertWagesQBOData(body) });

    }

    calculateAllEmployee = (data) => {

        let mainObj = {
            salaryWages: 0, otherTaxIncome: 0, preTaxDeduction: 0, taxableWages: 0,
            retirementsPlan: 0, healthInsurance: 0, housingAllowance: 0, reimbursements: 0
        };

        if (data.taxableWagesData.returnObject.allEmployeeData != null) {
            mainObj.salaryWages = data.taxableWagesData.returnObject.allEmployeeData.salaryWages;
            mainObj.otherTaxIncome = data.taxableWagesData.returnObject.allEmployeeData.otherTaxIncome;
            mainObj.healthInsurance = data.taxableWagesData.returnObject.allEmployeeData.healthInsurance;
            mainObj.preTaxDeduction = data.taxableWagesData.returnObject.allEmployeeData.preTaxDeduction;
            mainObj.retirementsPlan = data.taxableWagesData.returnObject.allEmployeeData.retirementsPlan;
            mainObj.housingAllowance = data.taxableWagesData.returnObject.allEmployeeData.housingAllowance;
            mainObj.reimbursements = data.taxableWagesData.returnObject.allEmployeeData.reimbursements;
            mainObj.taxableWages = totalTaxableWagesComputations(data.taxableWagesData.returnObject.allEmployeeData.salaryWages, data.taxableWagesData.returnObject.allEmployeeData.otherTaxIncome,
                0, 0, data.taxableWagesData.returnObject.allEmployeeData.healthInsurance,
                data.taxableWagesData.returnObject.allEmployeeData.preTaxDeduction, data.taxableWagesData.returnObject.allEmployeeData.retirementsPlan);

        }
        let qbototal = 0;
        if (data.taxableWagesData.returnObject != null && data.taxableWagesData.returnObject.taxableWagesQBOValue != null) {
            qbototal = data.taxableWagesData.returnObject.taxableWagesQBOValue.totalTaxableWages;
        }
        this.setState({ TotalTaxDiff: numberWithCommas((mainObj.taxableWages - qbototal).toFixed(2)), TotalQBOWages: numberWithCommas(qbototal.toFixed(2)) });
        return mainObj;

    }

    calculateNonClergyTotal = (data, allEmpData, nextprops) => {
        let mainObj = {
            salaryWages: 0, otherTaxIncome: 0, preTaxDeduction: 0, taxableWages: 0,
            retirementsPlan: 0, healthInsurance: 0, housingAllowance: 0, reimbursements: 0
        };

        if (data != null) {
            data = data.filter(function (a) { return a.employeeType === "Clergy" });

            if (data.length > 0) {
                mainObj.salaryWages = allEmpData.salaryWages - data[0].totalSalaryWages;
                mainObj.otherTaxIncome = allEmpData.otherTaxIncome - data[0].totalOtherTaxIncome;
                mainObj.healthInsurance = allEmpData.healthInsurance - data[0].totalHealthInsurance;
                mainObj.preTaxDeduction = allEmpData.preTaxDeduction - data[0].totalPreTaxDeduction;
                mainObj.reimbursements = 0; //allEmpData.reimbursements - data[0].totalReimbursements;
                mainObj.housingAllowance = allEmpData.housingAllowance - data[0].totalHousingAllowance;
                mainObj.retirementsPlan = allEmpData.retirementsPlan - data[0].totalRetirementsPlan;
                mainObj.taxableWages = (allEmpData.salaryWages - data[0].totalSalaryWages)
                    + (allEmpData.otherTaxIncome - data[0].totalOtherTaxIncome)
                    - (allEmpData.healthInsurance - data[0].totalHealthInsurance)
                    - (allEmpData.preTaxDeduction - data[0].totalPreTaxDeduction)
                    - (allEmpData.retirementsPlan - data[0].totalRetirementsPlan);
                // - (allEmpData.reimbursements - data[0].totalReimbursements);
            }
            else {
                mainObj.salaryWages = allEmpData.salaryWages;
                mainObj.otherTaxIncome = allEmpData.otherTaxIncome;
                mainObj.healthInsurance = allEmpData.healthInsurance;
                mainObj.preTaxDeduction = allEmpData.preTaxDeduction;
                mainObj.taxableWages = allEmpData.taxableWages;

                mainObj.reimbursements = allEmpData.reimbursements;
                mainObj.housingAllowance = allEmpData.housingAllowance;
                mainObj.retirementsPlan = allEmpData.retirementsPlan;
            }
        }

        let sectotal, medtotal = 0;
        if (nextprops.taxableWagesData.returnObject != null && nextprops.taxableWagesData.returnObject.taxableWagesQBOValue != null) {
            medtotal = nextprops.taxableWagesData.returnObject.taxableWagesQBOValue.totalMedicareWages;
            sectotal = nextprops.taxableWagesData.returnObject.taxableWagesQBOValue.totalSocialSecurityWages;
        }

        this.setState({
            MedicareDiff: numberWithCommas((mainObj.taxableWages - medtotal).toFixed(2)), SecurityDiff: numberWithCommas((mainObj.taxableWages - sectotal).toFixed(2)),
            TotalSecurityWages: sectotal.toFixed(2),
            TotalMedicareWages: medtotal.toFixed(2)
        });
        return mainObj;
    }

    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('userData'));
        this.setState({
            companyId: user.companyId,
            loading: true
        }, () => {
            this.GetReportData();
        });
    }

    showArchived = (e) => {
        this.setState({ visibleArchived: e.target.checked });
    }

    GeneratePDF = () => {
        this.setState({ IsArchiveProcessing: true }, () => {
            html2canvas(document.querySelector("#Mypdf")).then(canvas => {
                document.body.appendChild(canvas);
                const pdf = new jsPDF('p', 'pt', 'a4')
                let width = pdf.internal.pageSize.getWidth();
                let height = pdf.internal.pageSize.getHeight();
                let _dataURL = canvas.toDataURL('image/jpeg', 1.0);
                pdf.addImage(_dataURL, 'jpg', 0, 0, width, height);
                pdf.save("download.pdf");
                this.archivedData(_dataURL);
            });
        });
    }

    archivedData = (base64) => {
        let body = {
            "base64FileContent": base64,
            "contentType": "image/jpeg",
            "companyId": this.state.companyId,
            "fromDate": moment(this.state.sDate).format("YYYY-MM-DD"),
            "toDate": moment(this.state.eDate).format("YYYY-MM-DD")
        }

        this.setState({ loading: true }, () => { this.props.ArchivedTaxableWagesReport(body) });
    }
    render() {

        let allEmpData = this.state.allemployeeData;
        let cCount = this.state.ClergyCount;
        let nonclergytotal = this.state.nonClergyTotal;
        return (

            <React.Fragment key="1">
                <div id="Mypdf">
                    <div className="page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
                        <h2 className="title mb-3 mb-sm-0">{JSON.parse(localStorage.getItem('userData')) != null ? JSON.parse(localStorage.getItem('userData')).companyName : ""} | Taxable Wages Test for QB Payroll</h2>
                    </div>
                    <div className="row ml-3">
                        <div className="col-md-4">
                            <div className="select-quater-box">
                                <p className="sub-title">Date Selection</p>
                                <div className="row">
                                    <div className="col-sm-4 col-md-12">
                                        <div className="datepicker-box">
                                            <div className="date-box">
                                                <DatePicker
                                                    selected={this.state.sDate}
                                                    onChange={(e) => this.handleDateChange(e, 'fromDate')}
                                                    name="fromDate"
                                                    id="date"
                                                    required={true}
                                                    margin="normal"
                                                    disabledKeyboardNavigation
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    yearDropdownItemNumber={5}
                                                    maxDate={this.state.eDate}
                                                    ref={this.sDateRef}
                                                />
                                                <div className="date-icon" onClick={(e) => this.handleFocus(e, 'sDate')}>
                                                    <i className="zmdi zmdi-calendar-note"></i>
                                                </div>
                                            </div>
                                            <div className="to-box">
                                                <span className="text-center ledgerto-text">To</span>
                                            </div>
                                            <div className="date-box">
                                                <DatePicker
                                                    selected={this.state.eDate}
                                                    onChange={(e) => this.handleDateChange(e, 'uptoDate')}
                                                    name="uptoDate"
                                                    id="date"
                                                    required={true}
                                                    margin="normal"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    yearDropdownItemNumber={5}
                                                    disabledKeyboardNavigation
                                                    minDate={this.state.sDate}
                                                    maxDate={new Date()}
                                                    ref={this.eDateRef}
                                                />
                                                <div className="date-icon" onClick={(e) => this.handleFocus(e, 'eDate')}>
                                                    <i className="zmdi zmdi-calendar-note"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="genera-apply-btn" style={{ marginTop: "5px" }}>
                                            {!this.state.IsArchiveProcessing ?
                                                <React.Fragment>
                                                    <Button variant="raised" className="greencolor jr-btn bg-teal text-white refresh-btn" onClick={(e) => this.ApplyDate()} >apply</Button>
                                                </React.Fragment>
                                                : ""
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                        </div>
                        <div className="col-md-3">
                            <div className="row" style={{ marginTop: "20px" }}>
                                <div className="float-right">
                                    <div className="checkbox pointer float-right" >
                                        <div className="chiller_cb">
                                            <input id="TFB" type="checkbox" onClick={(e) => this.showArchived(e)} />
                                            <label for="TFB">
                                                <p className="checkbox-title"><strong> Show Archived</strong></p>
                                                <p className="parag-text"></p>
                                            </label>
                                            <span></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="float-right">
                                    {!this.state.IsArchiveProcessing ?
                                        <React.Fragment>
                                            <Button variant="raised" className="graycolor jr-btn bg-teal text-white refresh-btn" onClick={(e) => this.GeneratePDF()} >Archived</Button>
                                        </React.Fragment>
                                        : ""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.loading ? <CircularProgress /> :
                        <React.Fragment key="2">
                            <div >
                                {this.state.visibleArchived && <div className="row padding-box app-wrapper" style={{paddingBottom:0}}>
                                    <div className="col-md-4" >
                                        <table className="table tablebox account-reconcilliation">
                                            <thead>
                                                <tr>
                                                    <th className="text-center"> Archived On</th>
                                                    <th className="text-center "><img src="/vendors/Link-arrow_header.png" alt="link" /></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.ArchivedList && this.state.ArchivedList.length > 0 && this.state.ArchivedList.map((item, j) => (
                                                    <tr>
                                                        <td className="text-center"> {item.stArchiveDate} </td>
                                                        <td className="text-center"> <a href={item.absoluteUri} target="_blank"><img src="/vendors/grrenLink.png" alt="link" /></a> </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                    </div>
                                </div>}

                                <div className="row padding-box app-wrapper">
                                    <div className="col-4">
                                        <div className="table-responsive-material">
                                            <table className="table tablebox account-reconcilliation">
                                                <thead>
                                                    <tr style={{ backgroundColor: "#46b29d" }}>
                                                        <th className="td-width-90">Taxable Wages Test for QB Payroll</th>
                                                        <th className="td-width-90"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(this.state.empTypeData && this.state.empTypeData.length > 0) &&
                                                        <React.Fragment>
                                                            <tr>
                                                                <td><strong> Add: </strong>  All Employee Salaries and Wages</td>
                                                                <td className="text-right">{numberWithCommas(allEmpData.salaryWages)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong> Add: </strong> All Employee Other Taxable Income</td>
                                                                <td className="text-right">{numberWithCommas(allEmpData.otherTaxIncome)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td> <strong> Add: </strong>  All Employees Other Taxable Fringe Benefits</td>
                                                                <td className="text-right">{numberWithCommas(0)}</td>
                                                            </tr>

                                                            {/* <tr>
                                                        <td> <strong> Less: </strong>  Housing Allowance</td>
                                                        <td className="text-right">{numberWithCommas(this.state.allemployeeData.housingAllowance)}</td>
                                                    </tr> */}

                                                            <tr>
                                                                <td><strong>Less:</strong> All Employees Health Insurance Co-Pay</td>
                                                                <td className="text-right">{numberWithCommas(allEmpData.healthInsurance)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>Less:</strong> All Employee Other Pre-Tax Deductions</td>
                                                                <td className="text-right">{numberWithCommas(allEmpData.preTaxDeduction)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>Less:</strong> All Employee Retirement Plan Deductions</td>
                                                                <td className="text-right">{numberWithCommas(allEmpData.retirementsPlan)}</td>
                                                            </tr>
                                                            {/* <tr>
                                                                <td><strong> Less: </strong> Reimbursements</td>
                                                                <td className="text-right">{numberWithCommas(allEmpData.reimbursements)}</td>
                                                            </tr> */}
                                                            <tr>
                                                                <td className="font-weight-bold">Total Taxable Wages (941 - Line 2; 944 - Line 1, W-3 Box 1)</td>
                                                                <td className="text-right font-weight-bold">{numberWithCommas(totalTaxableWagesComputations(allEmpData.salaryWages,
                                                                    allEmpData.otherTaxIncome, 0, 0, allEmpData.healthInsurance, allEmpData.preTaxDeduction, allEmpData.retirementsPlan))}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="font-weight-bold">Total
                                                            QBO Taxable Wages</td>
                                                                <td> <TextField
                                                                    name="TotalQBOWages"
                                                                    label=""
                                                                    fullWidth
                                                                    onChange={(e) => this.handleFormChange(e, "TotalQBOWages")}
                                                                    value={this.state.TotalQBOWages}
                                                                    margin="normal"
                                                                    inputProps={{ maxLength: 20 }}
                                                                /></td>
                                                            </tr>
                                                            <tr>
                                                                <td className="font-weight-bold"> Difference</td>
                                                                <td>{this.state.TotalTaxDiff}</td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan="2">
                                                                    {!this.state.IsArchiveProcessing &&
                                                                        <Button variant="raised" className="greencolor jr-btn bg-teal text-white refresh-btn" onClick={() => this.SaveTotal()} >Save Total</Button>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    }

                                                    {(this.state.empTypeData && this.state.empTypeData.length == 0) &&
                                                        <tr>
                                                            <td className="text-center" colSpan="2">No data found</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="table-responsive-material">
                                            <table className="table tablebox account-reconcilliation">
                                                <thead>
                                                    <tr style={{ backgroundColor: "#e27b40" }}>
                                                        <th className="td-width-90">Social Security Wages Computations</th>
                                                        <th className="td-width-90"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(this.state.empTypeData && this.state.empTypeData.length == 0) ?
                                                        <tr>
                                                            <td className="text-center" colSpan="2">No data found</td>
                                                        </tr>
                                                        :
                                                        <React.Fragment key={100}>
                                                            <tr>
                                                                <td> <strong>Add:</strong> Non Clergy Employee Salaries and Wages</td>
                                                                <td className="text-right">{numberWithCommas(nonclergytotal.salaryWages)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td> <strong>Add:</strong> Non Clergy Employee Other Taxable Income</td>
                                                                <td className="text-right">{numberWithCommas(nonclergytotal.otherTaxIncome)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>Add:</strong> Non Clergy Employee Other Taxable Fringe Benefits</td>
                                                                <td className="text-right">{numberWithCommas(0)}</td>
                                                            </tr>

                                                            <tr>
                                                                <td> <strong>Less:</strong> Non Clergy Employee Health Insurance Co-Pay</td>
                                                                <td className="text-right">{numberWithCommas(nonclergytotal.healthInsurance)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>  <strong>Less:</strong> Non Clergy Employee Other Pre-Tax Deductions</td>
                                                                <td className="text-right">{numberWithCommas(nonclergytotal.preTaxDeduction)}</td>
                                                            </tr>
                                                            {/* <tr>
                                                                <td>Non Clergy Employee Retirement Plan Deductions</td>
                                                                <td className="text-right">{numberWithCommas(item.totalRetirementsPlan)}</td>
                                                            </tr> */}
                                                            <tr>
                                                                <td> <strong>Less:</strong> Wages in Excess of Social Security Wage Limit</td>
                                                                <td className="text-right">{numberWithCommas(0)}</td>
                                                            </tr>
                                                            {/* <tr>
                                                                <td> <strong> Less: </strong> Reimbursements</td>
                                                                <td className="text-right">{numberWithCommas(nonclergytotal.reimbursements)}</td>
                                                            </tr> */}
                                                            <tr>
                                                                <td className="font-weight-bold">Total Social Security Wages (941 Line 5a, Column 1, 944 Line 4a Column 1, and W-3 Box 3)</td>
                                                                <td className="text-right font-weight-bold">{numberWithCommas(totalSocialSecurityWagesComputation(nonclergytotal.salaryWages,
                                                                    nonclergytotal.otherTaxIncome, 0,
                                                                    nonclergytotal.healthInsurance, nonclergytotal.preTaxDeduction))}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="font-weight-bold">Total
                                                            QBO Security Wages</td>
                                                                <td> <TextField
                                                                    name="TotalSecurityWages"
                                                                    label=""
                                                                    fullWidth
                                                                    onChange={(e) => this.handleFormChange(e, "TotalSecurityWages")}
                                                                    value={this.state.TotalSecurityWages}
                                                                    margin="normal"
                                                                    inputProps={{ maxLength: 20 }}
                                                                /></td>
                                                            </tr>
                                                            <tr>
                                                                <td className="font-weight-bold"> Difference</td>
                                                                <td>{this.state.SecurityDiff}</td>
                                                            </tr>

                                                        </React.Fragment>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="table-responsive-material">
                                            <table className="table tablebox account-reconcilliation">
                                                <thead>
                                                    <tr style={{ backgroundColor: "#334c5a" }}>
                                                        <th className="td-width-90">Medicare Wages Computations</th>
                                                        <th className="td-width-90"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {(this.state.empTypeData && this.state.empTypeData.length == 0) ?

                                                        <tr>
                                                            <td className="text-center" colSpan="2">No data found</td>
                                                        </tr>
                                                        :
                                                        <React.Fragment>
                                                            <tr>
                                                                <td> <strong>Add:</strong> Non Clergy Employee Salaries and Wages</td>
                                                                <td className="text-right">{numberWithCommas(nonclergytotal.salaryWages)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td> <strong>Add:</strong> Non Clergy Employee Other Taxable Income</td>
                                                                <td className="text-right">{numberWithCommas(nonclergytotal.otherTaxIncome)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td> <strong>Add:</strong> Non Clergy Employee Taxable Fringe Benefits</td>
                                                                <td className="text-right">{numberWithCommas(0)}</td>
                                                            </tr>

                                                            <tr>
                                                                <td> <strong>Less:</strong> Non Clergy Employee Health Insurance Co-Pay</td>
                                                                <td className="text-right">{numberWithCommas(nonclergytotal.healthInsurance)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td> <strong>Less:</strong> Non Clergy Employee Other Pre-Tax Deductions</td>
                                                                <td className="text-right">{numberWithCommas(nonclergytotal.preTaxDeduction)}</td>
                                                            </tr>
                                                            {/* <tr>
                                                                <td>Non Clergy Retirement Plan Deductions</td>
                                                                <td className="text-right">{nonclergytotal.reti}</td>
                                                            </tr> */}
                                                            {/* <tr>
                                                                <td> <strong> Less: </strong> Reimbursements</td>
                                                                <td className="text-right">{numberWithCommas(nonclergytotal.reimbursements)}</td>
                                                            </tr> */}
                                                            <tr>
                                                                <td className="font-weight-bold">Total Medicare Wages (941 Line 5b, Column 1, 944 Line 4b Column 1, and W-3 Box 5)</td>
                                                                <td className="text-right font-weight-bold">{numberWithCommas(totalMedicareWagesComputation(nonclergytotal.salaryWages,
                                                                    nonclergytotal.otherTaxIncome, 0,
                                                                    nonclergytotal.healthInsurance, nonclergytotal.preTaxDeduction))}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="font-weight-bold">Total
                                                               QBO Medicare Wages</td>
                                                                <td> <TextField
                                                                    name="TotalMedicareWages"
                                                                    label=""
                                                                    fullWidth
                                                                    // required={true}
                                                                    onChange={(e) => this.handleFormChange(e, "TotalMedicareWages")}
                                                                    value={this.state.TotalMedicareWages}
                                                                    margin="normal"
                                                                    inputProps={{ maxLength: 8 }}
                                                                /></td>
                                                            </tr>
                                                            <tr>
                                                                <td className="font-weight-bold"> Difference</td>
                                                                <td>{this.state.MedicareDiff}</td>
                                                            </tr>
                                                        </React.Fragment>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="padding-box app-wrapper">
                                    <div className="table-responsive-material">
                                        <table className="table tablebox account-reconcilliation">
                                            <thead>
                                                <tr style={{ backgroundColor: "#46b29d" }}>
                                                    <th className=""></th>
                                                    <th className=" text-right" colSpan="3">&nbsp;</th>
                                                    <th className=" text-center" colSpan="3" style={{ borderBottom: "1px solid white" }}>Deductions will be subtract</th>
                                                    <th className=" text-right" colSpan="2">&nbsp;</th>
                                                </tr>
                                                <tr style={{ backgroundColor: "#46b29d" }}>
                                                    <th className=""></th>
                                                    <th className=" text-right">Salaries & <br></br> Wages</th>
                                                    <th className=" text-right">Other Taxable<br></br> Income</th>
                                                    <th className=" text-right">Taxable Fringe<br></br> Benefits</th>
                                                    <th className=" text-right">Qualified Pre-Tax Health<br></br> Insurance Co-Pay Deductions</th>
                                                    <th className=" text-right">Other Pre-Tax <br></br> Deductions</th>
                                                    <th className=" text-right">Qualified Pre-Tax Retirement<br></br> Plan Deductions</th>
                                                    <th className=" text-right">Total Taxable<br></br> Wages</th>
                                                    <th className=" text-right">Housing Allowance<br></br> (W-2 Box 14)</th>
                                                    {/* <th className="td-width text-right">Reimbursements and <br></br> Other Non-Taxable Compensation</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(this.state.empTypeData && this.state.empTypeData.length > 0) &&
                                                    <tr>
                                                        <td className="font-weight-bold">All Employees</td>
                                                        <td className="text-right font-weight-bold">{numberWithCommas(this.state.allemployeeData.salaryWages)}</td>
                                                        <td className="text-right font-weight-bold">{numberWithCommas(this.allemployeeData.otherTaxIncome)}</td>
                                                        <td className="text-right font-weight-bold">{numberWithCommas(0)}</td>
                                                        <td className="text-right font-weight-bold">{numberWithCommas(this.allemployeeData.healthInsurance)}</td>
                                                        <td className="text-right font-weight-bold">{numberWithCommas(this.allemployeeData.preTaxDeduction)}</td>
                                                        <td className="text-right font-weight-bold">{numberWithCommas(this.allemployeeData.retirementsPlan)}</td>
                                                        <td className="text-right font-weight-bold">{numberWithCommas(this.allemployeeData.taxableWages)}</td>
                                                        <td className="text-right font-weight-bold">{numberWithCommas(this.allemployeeData.housingAllowance)}</td>
                                                        {/* <td className="text-right font-weight-bold">{numberWithCommas(this.allemployeeData.reimbursements)}</td> */}
                                                    </tr>
                                                }
                                                {(this.state.empTypeData && this.state.empTypeData.length > 0) && this.state.empTypeData.map((item, i) => (
                                                    <React.Fragment key={300 + i}>

                                                        {cCount === 0 ?
                                                            <React.Fragment key={400 + i}>
                                                                <tr>
                                                                    <td className="font-weight-bold">Clergy Employees Total</td>
                                                                    <td className="text-right font-weight-bold">0</td>
                                                                    <td className="text-right font-weight-bold">0</td>
                                                                    <td className="text-right font-weight-bold">0</td>
                                                                    <td className="text-right font-weight-bold">0</td>
                                                                    <td className="text-right font-weight-bold">0</td>
                                                                    <td className="text-right font-weight-bold">0</td>
                                                                    <td className="text-right font-weight-bold">0</td>
                                                                    <td className="text-right font-weight-bold">0</td>
                                                                    {/* <td className="text-right font-weight-bold">0</td> */}
                                                                </tr>
                                                                <tr>
                                                                    <td className="font-weight-bold">Non-Clergy Employees Total</td>
                                                                    <td className="text-right font-weight-bold">{numberWithCommas(allEmpData.salaryWages == null ? 0 : allEmpData.salaryWages)}</td>
                                                                    <td className="text-right font-weight-bold">{numberWithCommas(allEmpData.otherTaxIncome == null ? 0 : allEmpData.otherTaxIncome)}</td>
                                                                    <td className="text-right font-weight-bold">{numberWithCommas(0)}</td>
                                                                    <td className="text-right font-weight-bold">{numberWithCommas(allEmpData.healthInsurance == null ? 0 : allEmpData.healthInsurance)}</td>
                                                                    <td className="text-right font-weight-bold">{numberWithCommas(allEmpData.preTaxDeduction == null ? 0 : allEmpData.preTaxDeduction)}</td>
                                                                    <td className="text-right font-weight-bold">{numberWithCommas(allEmpData.retirementsPlan == null ? 0 : allEmpData.retirementsPlan)}</td>
                                                                    <td className="text-right font-weight-bold"> {numberWithCommas(allEmpData.taxableWages)}</td>
                                                                    <td className="text-right font-weight-bold">{numberWithCommas(allEmpData.housingAllowance == null ? 0 : allEmpData.housingAllowance)}</td>
                                                                    {/* <td className="text-right font-weight-bold">{numberWithCommas(allEmpData.reimbursements == null ? 0 : allEmpData.reimbursements)}</td> */}
                                                                </tr>
                                                            </React.Fragment>
                                                            :
                                                            <React.Fragment key="8">
                                                                {item.employeeType === "Clergy" &&
                                                                    <tr>
                                                                        <td className="font-weight-bold" colSpan="9">{item.employeeType} Employees</td>
                                                                    </tr>
                                                                }

                                                                {(item.data && item.data.length > 0 && item.employeeType === "Clergy") && item.data.map((subdata, j) => (
                                                                    <tr key={i}>
                                                                        <td>{subdata.name}</td>
                                                                        <td className="text-right">{numberWithCommas(subdata.salaryWages)}</td>
                                                                        <td className="text-right">{numberWithCommas(subdata.otherTaxIncome)}</td>
                                                                        <td className="text-right">{numberWithCommas(0)}</td>
                                                                        <td className="text-right">{numberWithCommas(subdata.healthInsurance)}</td>
                                                                        <td className="text-right">{numberWithCommas(subdata.preTaxDeduction)}</td>
                                                                        <td className="text-right">{numberWithCommas(subdata.retirementsPlan)}</td>
                                                                        <td className="text-right">{numberWithCommas(subdata.taxableWages)}</td>
                                                                        <td className="text-right">{numberWithCommas(subdata.housingAllowance)}</td>
                                                                        {/* <td className="text-right">{numberWithCommas(subdata.reimbursements)}</td> */}
                                                                    </tr>
                                                                ))}

                                                                {item.employeeType === "Clergy" ?
                                                                    <React.Fragment key="12">
                                                                        <tr>
                                                                            <td className="font-weight-bold">{item.employeeType} Employees Total</td>
                                                                            <td className="text-right font-weight-bold">{numberWithCommas(item.totalSalaryWages)}</td>
                                                                            <td className="text-right font-weight-bold">{numberWithCommas(item.totalOtherTaxIncome)}</td>
                                                                            <td className="text-right font-weight-bold">{numberWithCommas(0)}</td>
                                                                            <td className="text-right font-weight-bold">{numberWithCommas(item.totalHealthInsurance)}</td>
                                                                            <td className="text-right font-weight-bold">{numberWithCommas(item.totalPreTaxDeduction)}</td>
                                                                            <td className="text-right font-weight-bold">{numberWithCommas(item.totalRetirementsPlan)}</td>
                                                                            <td className="text-right font-weight-bold">{numberWithCommas(item.totalTaxableWages)}</td>
                                                                            <td className="text-right font-weight-bold">{numberWithCommas(item.totalHousingAllowance)}</td>
                                                                            {/* <td className="text-right font-weight-bold">{numberWithCommas(item.totalReimbursements)}</td> */}
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="font-weight-bold">Non-Clergy Employees Total</td>
                                                                            <td className="text-right font-weight-bold">{numberWithCommas(nonclergytotal.salaryWages)}</td>
                                                                            <td className="text-right font-weight-bold">{numberWithCommas(nonclergytotal.otherTaxIncome)}</td>
                                                                            <td className="text-right font-weight-bold">{numberWithCommas(0)}</td>
                                                                            <td className="text-right font-weight-bold">{numberWithCommas(nonclergytotal.healthInsurance)}</td>
                                                                            <td className="text-right font-weight-bold">{numberWithCommas(nonclergytotal.preTaxDeduction)}</td>
                                                                            <td className="text-right font-weight-bold">{numberWithCommas(nonclergytotal.retirementsPlan)}</td>
                                                                            <td className="text-right font-weight-bold">{numberWithCommas(nonclergytotal.taxableWages)}</td>
                                                                            <td className="text-right font-weight-bold">{numberWithCommas(nonclergytotal.housingAllowance)}</td>
                                                                            {/* <td className="text-right font-weight-bold">{numberWithCommas(nonclergytotal.reimbursements)}</td> */}
                                                                        </tr>
                                                                    </React.Fragment>
                                                                    : ""}
                                                            </React.Fragment>
                                                        }
                                                    </React.Fragment>
                                                ))}
                                                {(this.state.empTypeData == null || this.state.empTypeData.length == 0) &&
                                                    <tr>
                                                        <td className="text-center" colSpan="10">No data found</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        taxableWagesData: state.companyReducer.taxableWagesData,
        wagesUpdate: state.companyReducer.wagesUpdate,
        wagesArchiveData: state.companyReducer.wagesArchiveData
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        post_TaxableWagesReport,
        InsertWagesQBOData,
        ArchivedTaxableWagesReport
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TaxableWagesComputations);
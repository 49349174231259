import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { deleteUser } from '../appRedux/actions/companiesAction';
import { findIndex } from "lodash";
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';



class DeleteUserForm extends React.Component {

    constructor() {
        super()
        this.state = {
            Reason: ''
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
    }

    handleCancel = () => {
        this.props.handleCancel()
    };

    handleSubmit = (e) => {
        e.preventDefault();
        let data = {
            companyId: this.props.companyId,
            userId: this.props.userId,
            Reason: this.state.Reason
        };

        this.props.deleteUser(data);
        this.props.handleSave(false);
    }

    handleFormChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    componentDidMount() {
        this.setState({ Reason: '' });
    }

    render() {
        const { visible, isEdit, selectedItem } = this.props;

        return (
            <Dialog
                fullWidth={true}
                open={visible}
                onClose={this.handleCancel}
            >
                <form method="post" onSubmit={this.handleSubmit} autoComplete="off" style={{ padding: 20 }}>
                    <DialogTitle>Delete User</DialogTitle>
                    <DialogContent>
                        <fieldset>
                            <Grid container spacing={24} >
                                <Grid item sm={12} >
                                    <TextField
                                        name="Reason"
                                        label="Reason"
                                        fullWidth
                                        required={true}
                                        onChange={this.handleFormChange}
                                        defaultValue={this.state.Reason}
                                        margin="normal"
                                    />
                                </Grid>
                            </Grid>
                        </fieldset>

                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" color="primary">
                            Delete
                </Button>
                        <Button onClick={this.handleCancel} color="primary" autoFocus>
                            Cancel
                </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }
}



const mapStateToProps = (state) => {
    return {

    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        deleteUser
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteUserForm);




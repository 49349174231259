import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CircularProgress from './../../../../components/CircularProgress';
import { toast } from 'react-toastify';
import moment from 'moment';
import PaginationComponent from './../../../common/Pagination';
import { GGET_ACCFINANCIAL_REPORT } from '../../../../appRedux/actions/ReportDetailAction';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from '@material-ui/core/Button';
import { GetLastQuarterEndDate } from '../../../common/commonServices';

class ACCFinancialCheckup extends Component {


    constructor(props) {
        super(props);
        var endDate = GetLastQuarterEndDate();
        let sDate = new Date(endDate.getFullYear(), 0, 1);
        this.sDateRef = React.createRef();
        this.eDateRef = React.createRef();

        this.state = {
            parantData: this.props.location.state ? this.props.location.state : null,
            MissingData: [],
            companyId: 0,
            PageNo: 1,
            PageSize: 10,
            ColorCode: "#46b29d",
            sDate: sDate,
            eDate: endDate,
            loading: false,
            TotalRecords: 0,
        }
    }

    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('userData'));
        this.setState({
            companyId: user.companyId,
            loading: true
        }, () => {
            this.GetReportData();
        });
    }

    ApplyDate = () => {
        this.setState({
            loading: true,
        }, () => {
            this.GetReportData();
        });
    }

    handleDateChange = (e, target) => {

        if (target === "fromDate") {

            this.setState({ sDate: new Date(e) });
        }
        else if (target === "uptoDate") {
            this.setState({ eDate: new Date(e) });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.ReportData !== this.props.ReportData) {
            if (nextProps.ReportData.transactionStatus === 0) {
                var totalrecords = 0;
                if (nextProps.ReportData.returnObject != null && nextProps.ReportData.returnObject.length > 0) {
                    totalrecords = nextProps.ReportData.returnObject[0].totalrows
                }
                this.setState({ MissingData: nextProps.ReportData.returnObject, TotalRecords: totalrecords, PageSize: this.state.PageSize, PageNo: this.state.PageNo, loading: false });
            }
            else {
                toast.error(nextProps.ReportData.resultMsg);
                this.setState({ loading: false });
            }
        }
    }
    GetReportData(IsRefresh = false) {
        let state = this.state;
        let body = {
            fromDate: moment(state.sDate).format("MM/DD/YYYY"),
            toDate: moment(state.eDate).format("MM/DD/YYYY"),
            pageSize: state.PageSize,
            pageNumber: state.PageNo
        };

        this.props.GGET_ACCFINANCIAL_REPORT(body);
    }
    onRefreshDataClick = () => {
        if (!this.state.loading) {
            this.setState({ loading: true }, () => {
                this.GetReportData(true);
            });
        }
    }

    handleFocus = (e, selecter) => {
        let control = null;
        if (selecter !== undefined) {
            if (selecter === "sDate") {
                control = this.sDateRef;
                if (control.current != null) {
                    control.current.setFocus();
                }
            }
            else if (selecter === "eDate") {
                control = this.eDateRef;
                if (control.current != null) {
                    control.current.setFocus();
                }
            }
        }
    }

    ChangePage = (page) => {
        this.setState({ loading: true, PageNo: page, }, () => {
            this.GetReportData();
        });
    }

    ChangePageSize = (size) => {

        this.setState({ loading: true, PageSize: parseInt(size), PageNo: 1 }, () => {
            this.GetReportData();
        });
    }
    render() {

        return (
            <div className="dashboardPage">
                <div className="page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
                    <h2 className="mb-3 mb-sm-0">Financial Check-Up Status</h2>
                </div>
                {this.state.loading ? <CircularProgress />
                    :
                    <div className="padding-box app-wrapper">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="select-quater-box">
                                    <p className="sub-title">Date Selection</p>
                                    <div className="row">
                                        <div className="col-sm-4 col-md-12 ">
                                            <div className="datepicker-box">
                                                <div className="date-box">
                                                    <DatePicker
                                                        selected={this.state.sDate}
                                                        onChange={(e) => this.handleDateChange(e, 'fromDate')}
                                                        name="fromDate"
                                                        id="date"
                                                        required={true}
                                                        margin="normal"
                                                        disabledKeyboardNavigation
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        yearDropdownItemNumber={5}
                                                        maxDate={this.state.eDate}
                                                        ref={this.sDateRef}
                                                    />
                                                    <div className="date-icon" onClick={(e) => this.handleFocus(e, 'sDate')}>
                                                        <i className="zmdi zmdi-calendar-note"></i>
                                                    </div>
                                                </div>
                                                <div className="to-box">
                                                    <span className="text-center ledgerto-text">To</span>
                                                </div>
                                                <div className="date-box">
                                                    <DatePicker
                                                        selected={this.state.eDate}
                                                        onChange={(e) => this.handleDateChange(e, 'uptoDate')}
                                                        name="uptoDate"
                                                        id="date"
                                                        required={true}
                                                        margin="normal"
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        yearDropdownItemNumber={5}
                                                        disabledKeyboardNavigation
                                                        minDate={this.state.sDate}
                                                        maxDate={new Date()}
                                                        ref={this.eDateRef}
                                                    />
                                                    <div className="date-icon" onClick={(e) => this.handleFocus(e, 'eDate')}>
                                                        <i className="zmdi zmdi-calendar-note"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="genera-apply-btn" style={{ marginTop: "5px" }}>
                                                <Button variant="raised" className="greencolor jr-btn bg-teal text-white refresh-btn" onClick={(e) => this.ApplyDate()} >apply</Button>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                            </div>
                            <div className="col-md-3 pt-4">
                                <div className="button-right float-right">
                                    <Button variant="raised" className="jr-btn bg-teal text-white refresh-btn greencolor" onClick={() => this.onRefreshDataClick()}   ><i className="zmdi zmdi-refresh "></i> Refresh Data</Button>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive-material">
                            <table className="table tablebox account-reconcilliation">
                                <thead  >
                                    <tr style={{ backgroundColor: this.state.ColorCode }}>
                                        <th className="td-width" ><p> Company</p> </th>
                                        <th className="td-width" ><p>GL Review Date</p> </th>
                                        <th className="td-width" ><p>GL  Review By</p> </th>
                                        <th className="td-width" ><p>Balance sheet <br></br> Review Date</p> </th>
                                        <th className="td-width" ><p>Balance sheet <br></br> Review By</p> </th>
                                        <th  ><p > NBD</p></th>
                                        <th ><p > TQC</p></th>
                                        <th  ><p > QC-(CPA)</p> </th>
                                        <th  ><p > OQC</p></th>
                                        <th ><p > OQC-CPA</p></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.loading ? <CircularProgress></CircularProgress> :
                                        <React.Fragment>
                                            {this.state.MissingData.map((item, i) => (
                                                <React.Fragment key={i}>
                                                    <tr>
                                                        < td>{item.companyName}</td>
                                                        <td>{item.stReviewedDate}</td>
                                                        <td>{item.glReviewedBy}</td>
                                                        <td>{item.stArchiveDate}</td>
                                                        <td>{item.archiveBy}</td>
                                                        <td>{item.needBetterDescriptionCount}</td>
                                                        <td>{item.transactionswithQuestionsAndCommentsCount}</td>
                                                        <td>{item.transactionsTaggedAsCPACount}</td>
                                                        <td>{item.otherQuestionsAndCommentsCount}</td>
                                                        <td>{item.otherQuestionsAndCommentsTaggedAsCPACount}</td>
                                                    </tr>
                                                </React.Fragment>
                                            ))}
                                            {this.state.MissingData.length == 0 && <tr>
                                                <td className="text-center" colSpan="10">
                                                    No data found          </td>
                                            </tr>}
                                        </React.Fragment>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <PaginationComponent
                            selectedPageNo={this.state.PageNo}
                            selectedSize={this.state.PageSize}
                            TotalRecords={this.state.TotalRecords}
                            ChangePageSize={this.ChangePageSize}
                            ChangePage={this.ChangePage}
                            CurrentRecords={this.state.MissingData.length}
                        >
                        </PaginationComponent>
                    </div>
                }
            </div>
        )

    }

}


const mapStateToProps = (state) => {
    return {
        ReportData: state.ReportData.ReportData
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        GGET_ACCFINANCIAL_REPORT

    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(ACCFinancialCheckup);
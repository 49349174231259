import React, { Component } from "react";
import IntlMessages from "../../../../util/IntlMessages";
import { getSocialSecurityWageAll } from '../../../../appRedux/actions/vendorAction';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import ReactTable from "react-table";
import "react-table/react-table.css";
import CircularProgress from "../../../../components/CircularProgress";
import WageLimitForm from '../../../../Forms/AddWageLimitForm';
import SearchBox from '../../../../components/SearchBox';
import Button from '@material-ui/core/Button';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Table, Pagination } from 'antd';

import "../../styles.css";
let compareByAlph = (a, b) => {
    if (a < b) { return -1; }
    if (a > b) { return 1; }
    return 0;
}


class WagesListPage extends Component {

    constructor(props) {

        super(props);

        let column = [{
            title: 'Year',
            dataIndex: 'socialSecurityWageYear',
            className: 'table-heading',
            sorter: false,
            width: 200,
            sorter: (a, b) => compareByAlph(a.socialSecurityWageYear, b.socialSecurityWageYear),
        }, {
            title: 'Wage Limit Amount',
            dataIndex: 'socialSecurityWageLimit',
            className: 'table-heading',
            sorter: false,
            width: 200,
            sorter: (a, b) => compareByAlph(a.socialSecurityWageLimit, b.socialSecurityWageLimit),
        }];

        this.state = {
            pageSize: 50,
            currentPage: 1,
            totalRowsInTable: 0,
            fieldName: 'socialSecurityWageYear',
            sortFormat: 'A',
            columns: column,
            loading: false,
            searchText: '',
            search: false,
            selectedWageLimit: '',
            isOpen: false,
            isAddOpen: false,
            isEdit: false,
            companyId: '',
            Columns: column,
            wagesList: []
        }
    }

    componentDidMount() {
        this.listData();
    }

    listData() {
        this.setState({ loading: true }, () => {
            this.props.getSocialSecurityWageAll();
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.wagesList !==undefined && nextProps.wagesList !== this.props.wagesList) {
            this.setState({ loading: false, wagesList: nextProps.wagesList.returnObject });
        }
        //if (nextProps.reload == this.props.reload) { this.listData(); }
    }

    handleCancel = (value) => {
        this.setState({ isOpen: false, isEdit: false, isAddOpen: false });
        if (value && value == 'yes') { this.listData(); }
    };

    handleAddModal = () => {
        this.setState({ isAddOpen: true, isEdit: false });
    }

    handleEditModal = (record) => {
        var id = record.socialSecurityWageID;
        var data = this.state.wagesList;
        if (id != 0) {
            const selectedWageLimit = _.find(data, { socialSecurityWageID: id });
            this.setState({ isEdit: true, isAddOpen: true, selectedWageLimit: selectedWageLimit });
        }
    }


    handleSave = (state) => {
        this.setState({
            isOpen: state,
            isEdit: state,
            isAddOpen: false
        });
    }


    render() {

        let { searchText, wagesList, columns } = this.state;
        var wages = wagesList;
        if (searchText) {
            wages = wagesList.length && wagesList.filter(obj => {
                if (obj.socialSecurityWageYear !== null && obj.socialSecurityWageLimit !== null) {
                    if (obj.socialSecurityWageYear.toString().toLowerCase().includes(searchText.toLowerCase()) === true || obj.socialSecurityWageLimit.toString().toLowerCase().includes(searchText.toLowerCase()) === true) {
                        return obj;
                    }
                }
            });
        }
        let filteredData = wages.length && wages.map((d, index) => ({
            key: d.socialSecurityWageID,
            ...d
        }))

        return (
            <div className="vendorsPage" style={{ padding: 15 }}>
                <Grid
                    container
                    spacing={24}
                    style={{ 'marginBottom': 10 }}
                >
                    <Grid item xs={12} sm={6}>
                        <h3 className="user-title">Wages Limit</h3>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid
                            container
                            spacing={8}
                            direction="row"
                            justify="flex-end"
                            alignItems="flex-end">
                            <Grid item>
                                <SearchBox
                                    styleName="d-none d-lg-block"
                                    placeholder="Search... "
                                    value={this.state.searchText}
                                    onChange={(e) => { this.setState({ searchText: e.target.value }) }}
                                    style={{ background: '#eeeeeeb8' }}
                                />
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color="primary" onClick={this.handleAddModal} >
                                    Add Wages
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {this.state.loading == false ?
                    <WageLimitForm
                        isEdit={this.state.isEdit}
                        handleSave={this.handleSave}
                        visible={this.state.isAddOpen}
                        handleCancel={this.handleCancel}
                        selectedItem={this.state.selectedWageLimit}
                    />
                    : ''}
                {this.state.loading
                    ?
                    <CircularProgress />
                    : <div>
                        <Table
                            className="table-layout"
                            columns={this.state.Columns}
                            dataSource={filteredData}
                            // rowClassName={ (record) =>  this.state.selectedItemKey == record.key  ? 'data-row selected' : 'data-row'  }
                            bordered={true}
                            size={"small"}
                            // onRowClick={(record, index, event) => this.handleEditModal(record)}
                            onRowDoubleClick={(record, index, event) => this.handleEditModal(record)}
                            //   onChange={this.handleTableChange}
                            loading={this.state.loading}
                            pagination={false}
                            scroll={{ y: window.innerHeight - 301 }}
                        />

                    </div>
                }
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        wagesList: state.vendors.wagesList,
        reload: state.vendors.reload
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getSocialSecurityWageAll
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(WagesListPage);
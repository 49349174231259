import React, { Component } from 'react';
import { numberWithCommas } from "../../../common/commonServices";
import Button from '@material-ui/core/Button';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TextArea from 'antd/lib/input/TextArea';
import { number } from 'prop-types';

class BalanceSheetNodeComponent extends Component {

    constructor(props) {
        super(props)
        this.Reconciled = React.createRef();
        this.state = {
            lst_data: props.data,
            // lst_quedata: props.quedata,
            level: props.level,
            CollapseGroup: null,
            Expanded: false,
            selectedlevel: -1,
            editedData: null,
            expandedaccount: null,
            ExpandedList: [],
            IsCompared: props.IsCompared
        }
    }

    onRowUpdateClick = () => {
        this.props.UpdateFields(this.state.editedData)
    }

    onRowCancelClick = () => {
        this.setState({ selectedlevel: -1, editedData: null });
    }

    handleFormChange = (e, target) => {
        if (target == "ReconciledThrough") {
            let ed = this.state.editedData;
            ed.ReconciledThrough = new Date(e)
            this.setState({ editedData: ed })
        }

    }

    handleMessage = (event) => {
        let ed = this.state.editedData;
        ed.Notes = event.target.value;
        this.setState({ editedData: ed });
    }

    handleQuestion = (event) => {
        let ed = this.state.editedData;
        ed.question = event.target.value;
        this.setState({ editedData: ed });
    }

    showChild = (groupName, data, queReport) => {
        if (data.IsData) {

            //let queReport = Object.values(this.state.quedata);
            let item = null;

            // var DataQ = queReport.filter(Q => Q.accountId === data.AccountId)
            // if (DataQ.length > 0) {
            //     item = { AccountId: data.AccountId, ReconciledThrough: (data.ReconciledThrough == "" || data.ReconciledThrough == null) ? null : new Date(data.ReconciledThrough), Notes: data.Notes, questionID: DataQ[0].id, question: DataQ[0].question };
            // } else {
            //     item = { AccountId: data.AccountId, ReconciledThrough: (data.ReconciledThrough == "" || data.ReconciledThrough == null) ? null : new Date(data.ReconciledThrough), Notes: data.Notes, questionID: 0, question: '' };
            // }
            item = { AccountId: data.AccountId, ReconciledThrough: (data.ReconciledThrough == "" || data.ReconciledThrough == null) ? null : new Date(data.ReconciledThrough), Notes: data.Notes, questionID: data.QuestionId, question: data.Question};

            this.setState({ selectedlevel: data.AccountId, editedData: item });
        }
        else {
            let items = this.state.ExpandedList;
            if (this.IsExpaned(data.Account)) {
                items.splice(items.indexOf(data.Account), 1);
                this.setState({ CollapseGroup: null, Expanded: false, editedData: null, selectedlevel: -1, expandedaccount: data.Account, ExpandedList: items });
            }
            else {
                items.push(data.Account);
                this.setState({ CollapseGroup: groupName, Expanded: true, editedData: null, selectedlevel: -1, expandedaccount: data.Account, ExpandedList: items });
            }
        }
    }

    handleFocus = (e, selecter) => {
        let control = null;
        if (selecter !== undefined) {
            control = this.Reconciled;
            if (control.current != null) {
                control.current.setFocus();
            }
        }
    }

    componentDidMount() {
        let expandedData = this.state.ExpandedList;
        let accounts = this.state.lst_data;
        for (let i = 0; i < accounts.length; i++) {
            if (expandedData.length === 0 || expandedData.indexOf(accounts[i].Account) < 0) {
                expandedData.push(accounts[i].Account);
            }
        }
        this.setState({ ExpandedList: expandedData });
    }


    IsExpaned = (accountName, removed = null, add = null) => {

        return (this.state.ExpandedList.indexOf(accountName) > -1);
    }

    render() {

        const { UpdateFields } = this.props;
        return (
            <React.Fragment>
                {(this.state.lst_data.length === 0 && this.state.i === 1) ?
                    <tr>
                        <td colSpan="6">No Data Found!!!</td>
                    </tr>
                    :
                    <React.Fragment>
                        {this.state.lst_data.map((item, i) => (

                            <React.Fragment key={this.state.level + "_" + i} >
                                <tr className={(item.IsHeader) ? item.Account.replace(/\s+/g, '') + "_header" : (item.IsSummary) ? item.Account.replace(/\s+/g, '') + "_total" : ""}
                                    onClick={() => this.showChild(item.Account, item)}>
                                    {item.IsHeader &&
                                        <React.Fragment>
                                            {this.state.IsCompared ?
                                                <React.Fragment>
                                                    <td style={{ paddingLeft: item.level + "0px" }}>
                                                        <h3>
                                                            {item.IsHeader ? <span>{this.IsExpaned(item.Account) ? <i className="zmdi zmdi-minus-circle-outline"></i> : <i className="zmdi zmdi-plus-circle-o"></i>}</span> : ""}
                                                            &nbsp; &nbsp;    {item.Account}
                                                        </h3>
                                                    </td>
                                                    <td className="text-right"> {item.GLBalance != 0 && <span>{numberWithCommas(item.GLBalance)}</span>} </td>
                                                    <td className="text-right" > {item.Balance != 0 && <span> {numberWithCommas(item.Balance)} </span>} </td>
                                                    <td colSpan="3">
                                                        &nbsp;
                                                    </td>
                                                </React.Fragment>
                                                :
                                                <React.Fragment>
                                                    <td colSpan="4" style={{ paddingLeft: item.level + "0px" }}>
                                                        <h3>
                                                            {item.IsHeader ? <span>{this.IsExpaned(item.Account) ? <i className="zmdi zmdi-minus-circle-outline"></i> : <i className="zmdi zmdi-plus-circle-o"></i>}</span> : ""}
                                                            &nbsp; &nbsp;    {item.Account}
                                                        </h3>
                                                    </td>
                                                    <td className="text-right" > {item.Balance != 0 && <span> {numberWithCommas(item.Balance)} </span>} </td>
                                                    <td >
                                                        &nbsp;
                                                    </td>
                                                </React.Fragment>
                                            }
                                        </React.Fragment>

                                    }
                                    {
                                        item.IsSummary && <React.Fragment>
                                            {this.state.IsCompared ?
                                                <React.Fragment>
                                                    <td colSpan="1" style={{ paddingLeft: item.level + "0px" }}>
                                                        <h4 style={{ fontWeight: "500" }}>
                                                            {item.IsHeader ? <span>{this.IsExpaned(item.Account) ? <i className="zmdi zmdi-minus-circle-outline"></i> : <i className="zmdi zmdi-plus-circle-o"></i>}</span> : ""}
                                                            &nbsp; &nbsp;    {item.Account}

                                                        </h4>
                                                    </td>
                                                    <td className="text-right"> ${numberWithCommas(item.GLBalance)} </td>
                                                    <td className="text-right" > {item.Balance != "" ? <span>  ${numberWithCommas(item.Balance)} </span> : <span>$0.00</span>} </td>
                                                    <td className="text-right">      {item.Balance != item.GLBalance ?
                                                        <span className="diff_Amount">${numberWithCommas(item.GLBalance - item.Balance)}</span>
                                                        :
                                                        ""
                                                    } </td>
                                                    <td></td>
                                                    <td></td>
                                                </React.Fragment>
                                                :
                                                <React.Fragment>
                                                    <td colSpan="2" style={{ paddingLeft: item.level + "0px" }}>
                                                        <h4 style={{ fontWeight: "500" }}>
                                                            {item.IsHeader ? <span>{this.IsExpaned(item.Account) ? <i className="zmdi zmdi-minus-circle-outline"></i> : <i className="zmdi zmdi-plus-circle-o"></i>}</span> : ""}
                                                            &nbsp; &nbsp;    {item.Account}
                                                        </h4>
                                                    </td>
                                                    <td className="text-right">  ${numberWithCommas(item.Balance)} </td>
                                                    <td colSpan="3"></td>
                                                </React.Fragment>
                                            }

                                        </React.Fragment>
                                    }
                                    {
                                        item.IsSummary === false && item.IsHeader === false ?
                                            <React.Fragment>
                                                {this.state.IsCompared ?

                                                    <React.Fragment>
                                                        <td style={{ paddingLeft: (item.level) + "0px" }}>{item.Account} </td>
                                                        {/* <td>
                                                            {
                                                                this.state.lst_quedata.filter(Q => Q.accountId === item.AccountId).length > 0
                                                                    ? this.state.lst_quedata.filter(Q => Q.accountId === item.AccountId)[0].question
                                                                    : ""
                                                            }
                                                        </td> */}
                                                        <td className="text-right">   {item.GLBalance != "" ? <span> {numberWithCommas(item.GLBalance)} </span> : <span>0.00</span>} </td>
                                                        <td className="text-right">{item.Balance != "" ? <span> {numberWithCommas(item.Balance)} </span> : <span>0.00</span>}</td>
                                                        <td className="text-right">
                                                            {item.Balance != item.GLBalance ?
                                                                <span className="diff_Amount">{numberWithCommas(item.GLBalance - item.Balance)}</span>
                                                                :
                                                                ""
                                                            }
                                                        </td>
                                                        <td>{item.Notes}</td>
                                                        <td>{item.Question}</td>
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment>
                                                        <td style={{ paddingLeft: (item.level) + "0px" }}>{item.Account} </td>
                                                        <td>{item.Type}</td>
                                                        <td className="text-right">
                                                            {numberWithCommas(item.Balance)}
                                                        </td>
                                                        <td>{item.STRT}</td>
                                                        <td>{item.Notes}</td>
                                                        <td>{item.Question}</td>
                                                        {/* <td>
                                                            {
                                                                this.state.lst_quedata.filter(Q => Q.accountId === item.AccountId).length > 0
                                                                    ? this.state.lst_quedata.filter(Q => Q.accountId === item.AccountId)[0].question
                                                                    : ""
                                                            }
                                                        </td> */}
                                                        
                                                    </React.Fragment>
                                                }

                                            </React.Fragment>
                                            : ""
                                    }
                                </tr>
                                {this.state.selectedlevel == item.AccountId &&
                                    <React.Fragment>
                                        {/* {this.state.IsCompared ?
                                            <tr>
                                                <td colSpan="3">
                                                    <div>Reconciled Through</div>
                                                    <div className="date-box" style={{ border: '1px solid rgb(225, 123, 64)', borderRadius: '6px', display: 'inherit' }}>
                                                        <DatePicker
                                                            selected={this.state.editedData.ReconciledThrough}
                                                            onChange={(e) => this.handleFormChange(e, 'ReconciledThrough')}
                                                            label="Loan Date"
                                                            id="date"
                                                            name="ReconciledThrough"
                                                            margin="normal"
                                                            maxDate={new Date()}
                                                            ref={this.Reconciled}
                                                        />
                                                        <div className="date-icon" onClick={(e) => this.handleFocus(e, 'ReconciledThrough')}>
                                                            <i className="zmdi zmdi-calendar-note"></i>
                                                        </div>
                                                    </div>

                                                </td>
                                                <td>
                                                    Notes :
                                                    <TextArea placeholder="Enter Notes" onChange={this.handleMessage} value={this.state.editedData.Notes} className="textarea-box" >
                                                    </TextArea>
                                                    <div style={{ padding: "10px" }}>
                                                        <Button className="jr-btn jr-btn-sm bg-teal text-white" onClick={() => this.onRowUpdateClick()}>SAVE</Button>
                                                        <Button className="jr-btn jr-btn-sm bg-danger text-white" onClick={() => this.onRowCancelClick()}>CANCEL</Button>
                                                    </div>
                                                </td>
                                                <td>
                                                    Questions and Comments:
                                                    <TextArea placeholder="Enter Questions" onChange={this.handleQuestion} value={this.state.editedData.question} className="textarea-box" >
                                                    </TextArea>
                                                </td>
                                            </tr>
                                            : */}
                                            <tr>
                                                <td colSpan="3">&nbsp;</td>
                                                <td>
                                                    <div>Reconciled Through</div>
                                                    <div className="date-box" style={{ border: '1px solid rgb(225, 123, 64)', borderRadius: '6px' }}>
                                                        <DatePicker
                                                            selected={this.state.editedData.ReconciledThrough}
                                                            onChange={(e) => this.handleFormChange(e, 'ReconciledThrough')}
                                                            label="Loan Date"
                                                            id="date"
                                                            name="ReconciledThrough"
                                                            margin="normal"
                                                            maxDate={new Date()}
                                                            ref={this.Reconciled}
                                                        />
                                                        <div className="date-icon" onClick={(e) => this.handleFocus(e, 'ReconciledThrough')}>
                                                            <i className="zmdi zmdi-calendar-note"></i>
                                                        </div>
                                                    </div>

                                                </td>
                                                <td>
                                                    Notes :
                                                    <TextArea placeholder="Enter Notes" onChange={this.handleMessage} value={this.state.editedData.Notes} className="textarea-box" >
                                                    </TextArea>
                                                    <div style={{ padding: "10px" }}>
                                                        <Button className="jr-btn jr-btn-sm bg-teal text-white" onClick={() => this.onRowUpdateClick()}>SAVE</Button>
                                                        <Button className="jr-btn jr-btn-sm bg-danger text-white" onClick={() => this.onRowCancelClick()}>CANCEL</Button>
                                                    </div>
                                                </td>
                                                <td>
                                                    Questions and Comments:
                                                    <TextArea placeholder="Enter Questions" onChange={this.handleQuestion} value={this.state.editedData.question} className="textarea-box" >
                                                    </TextArea>
                                                </td>
                                            </tr>

                                        {/* } */}

                                        {/* <tr>
                                        <td colSpan="5">
                                            Questions and Comments:
                                            <TextArea placeholder="Enter Notes" onChange={this.handleMessage} value={this.state.editedData.Notes} className="textarea-box" >
                                                </TextArea>
                                                <div style={{ padding: "5px" }}>
                                                    <Button className="jr-btn jr-btn-sm bg-teal text-white" onClick={() => this.onRowUpdateClick()}>SAVE</Button>
                                                    <Button className="jr-btn jr-btn-sm bg-danger text-white" onClick={() => this.onRowCancelClick()}>CANCEL</Button>
                                                </div>
                                            </td>
                                        </tr> */}
                                    </React.Fragment>
                                }
                                {item.Items.length > 0 &&
                                    this.IsExpaned(item.Account)
                                    // ((item.Account === this.state.CollapseGroup))
                                    && <BalanceSheetNodeComponent
                                        data={item.Items}
                                        // quedata={this.state.lst_quedata}
                                        level={this.state.level + 1}
                                        UpdateFields={UpdateFields}
                                        IsCompared={this.state.IsCompared}
                                    >
                                    </BalanceSheetNodeComponent>
                                }
                            </React.Fragment>
                        ))}
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
}


export default BalanceSheetNodeComponent;
import * as AccountantDashboardService from "../services/AccountantDashboardService";
import React from 'react';


export const GET_FINANICAL_ISSUES_DETAILS = 'GET_FINANICAL_ISSUES_DETAILS';
export const GET_PRECHECK_ISSUES_DETAILS = 'GET_PRECHECK_ISSUES_DETAILS';
export const GET_REVIEW_COMMENTS_DETAILS = 'GET_REVIEW_COMMENTS_DETAILS';
export const GET_1099_ISSUES_DETAILS = 'GET_1099_ISSUES_DETAILS';
export const GET_COMPANT_TAX_DETAILS = 'GET_COMPANT_TAX_DETAILS';
export const GET_PAYROLLADMINISTRATION_DETAILS = 'GET_PAYROLLADMINISTRATION_DETAILS';
export const RELOAD = 'RELOAD';

export const Get_FinancialIssues = (data) => {
    return (dispatch) => {
        AccountantDashboardService.GetFinancialCheckupIssues(data)
            .then((response) => {
                dispatch({ type: GET_FINANICAL_ISSUES_DETAILS, payload: response });
            })
    }
}

export const Get_PrecheckIssues = (data) => {
    return (dispatch) => {
        AccountantDashboardService.GetPreCheckIssues(data)
            .then((response) => {
                dispatch({ type: GET_PRECHECK_ISSUES_DETAILS, payload: response });
            })
    }
}

export const Get_AccountantDashboard_GetReviewCommentsSummary = (data) => {
    return (dispatch) => {
        AccountantDashboardService.AccountantDashboard_GetReviewCommentsSummary(data)
            .then((response) => {
                dispatch({ type: GET_REVIEW_COMMENTS_DETAILS, payload: response });
            })
    }
}

export const GetPayrollAdministrationSummary =(data) => {
    return (dispatch) => {
        AccountantDashboardService.GetPayrollAdministrationSummary(data)
            .then((response) => {
                dispatch({ type: GET_PAYROLLADMINISTRATION_DETAILS, payload: response });
            })
    }
}

export const Get1099AdministrationSummary =(data) => {
    return (dispatch) => {
        AccountantDashboardService.Get1099AdministrationSummary(data)
            .then((response) => {
                dispatch({ type: GET_1099_ISSUES_DETAILS, payload: response });
            })
    }
}

// export const CompanyTaxInformation =(data) => {
//     return (dispatch) => {
//         AccountantDashboardService.CompanyTaxInformation(data)
//             .then((response) => {
//                 dispatch({ type: GET_COMPANT_TAX_DETAILS, payload: response });
//             })
//     }
// }
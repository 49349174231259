import React, { Component } from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl'
import 'react-big-calendar/lib/less/styles.less';
import '../styles/bootstrap.scss'
import '../styles/app.scss';
import '../styles/app-rtl.scss';
import defaultTheme from './themes/defaultTheme';
import AppLocale from '../lngProvider';
import ForgotPassword from "./ForgotPassword";
import MainApp from '../app/index';
import SignIn from './SignIn';
import SignUp from './SignUp';
import CompanyDashboard_Embeded from './EmbededComponents/companyDashboard_embeded';
import CompanyDashboard_Bookkeeping_Embeded from './EmbededComponents/companyDashboard_Bookkeeping_embeded';
import OldOutstanding_Embedded from './EmbededComponents/Link_Embedded_Report/OldOutStanding';
import NeedProperDescription_Embedded from './EmbededComponents/Link_Embedded_Report/NeedProperDescription';
import MissingDescription_Embedded from './EmbededComponents/Link_Embedded_Report/MissingDescription';
import MissingVenderName_Embedded from './EmbededComponents/Link_Embedded_Report/MissingVendorName';
import AccountLineItem_Embedded from './EmbededComponents/Link_Embedded_Report/AccountLineItem';
import EmpPay_Embeded from "./EmbededComponents/Link_Embedded_Report/EmpPay_Embeded";
import EmpVehicle_Embedded from "./EmbededComponents/Link_Embedded_Report/EmpVehicle_Embedded";
import UnauthorizeComponent from './Unauthoirize';
import ChangeAccountNumber_Embedded from './EmbededComponents/Link_Embedded_Report/ChangeAccountNumber';
import EmployeeMissW4Component_Embedded from './EmbededComponents/Link_Embedded_Report/EmployeeMissW4_embd';
import VendorMissW9Component_Embedded from './EmbededComponents/Link_Embedded_Report/VendorMissW9_embd';
import RecordCheckPC_Embd from './EmbededComponents/Link_Embedded_Report/recordCheckPC_embd';
import TransactionTagCPA_Embd from './EmbededComponents/Link_Embedded_Report/transactionTagCPA_embd';
import GeneralQuesComm__Embedded from "./EmbededComponents/Link_Embedded_Report/GeneralQuesComm";
import RecEmpPayPayroll_Embedded from "./EmbededComponents/Link_Embedded_Report/RecEmpPayPayroll_Embedded";
import VerifyMinisterList_Embedded from "./EmbededComponents/Link_Embedded_Report/VerifyMinisterList_Embedded";
import ApproveWorker_Embedded from "./EmbededComponents/Link_Embedded_Report/ApproveWorker_Embedded";
import CheckLedgerData from "./EmbededComponents/CheckLedgerData";
import RTL from '../util/RTL';
import asyncComponent from '../util/asyncComponent';
import AssignTFBtoEMP_Embedded from "./EmbededComponents/Link_Embedded_Report/AssignTFBtoEMP_Embedded";
import Confirm1099Mailed_Embedded from "./EmbededComponents/Link_Embedded_Report/Confirm1099Mailed_Embedded";
import MissRefNo_Embedded from "./EmbededComponents/Link_Embedded_Report/MissRefNo_Embedded";
import CLApproval1099 from "./EmbededComponents/Link_Embedded_Report/CLApproval1099";
import Report1099Status_Embedded from "./EmbededComponents/Link_Embedded_Report/Report1099Status_Embedded";
import VendorDiscrepancies_Embedded from "./EmbededComponents/Link_Embedded_Report/VendorDiscrepancies_Embedded";
import UnCategorizedTran_Embedded from "./EmbededComponents/Link_Embedded_Report/UnCategorizedTransactions_Embedded";
import ReviewReconciliationDiscrepancies_Embedded from "./EmbededComponents/Link_Embedded_Report/ReviewReconciliationDiscrepancies_Embedded";
import queryString from 'query-string';
import Footer from '../components/Footer';

const RestrictedRoute = ({ component: Component, authUser, ...rest }) =>
    <Route
        {...rest}
        render={props =>
            authUser
                ? <Component {...props} />
                : <Redirect
                    to={{
                        pathname: '/signin',
                        state: { from: props.location }
                    }}
                />}
    />;

class App extends Component {

    render() {
        const { match, location, locale, authUser, initURL, isDirectionRTL } = this.props;
        let user = JSON.parse(localStorage.getItem('q'));  
        // let URLParam = "";
        // let URLParamLength = 1;
        // if(user != null && user != undefined){
        //     if(user.q != undefined && user.p != undefined){
        //         URLParam = "?q="+user.q.toString()+"&p="+user.p.toString();
        //         if(this.props.location.search!= null && this.props.location.search!=""){
        //             let value = queryString.parse(this.props.location.search);
        //             if (value != null) {
        //                 URLParamLength = value.p.toString().split("/").length;
        //             }
        //         }
        //     }
          
        // }
        if(authUser==null && this.props.location.search!= null && this.props.location.search!="")
        {
            localStorage.setItem("redirectURL",window.location.href.replace(window.location.origin,''));
        }
        if (location.pathname === '/') {
            if (authUser === null) {
                return (<Redirect to={'/signin'} />);
            } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
                return (<Redirect to={'/dashboard'} />);
            } else {
                return (<Redirect to={initURL} />);
            }
        }
        const applyTheme = createMuiTheme(defaultTheme);

        if (isDirectionRTL) {
            applyTheme.direction = 'rtl';
            document.body.classList.add('rtl')
        } else {
            document.body.classList.remove('rtl');
            applyTheme.direction = 'ltr';
        }



        const currentAppLocale = AppLocale[locale.locale];        
        let updatedDate = localStorage.getItem("LastUpdateDate");
        let latestUpdate = "Mon May 23 2022 11:16:33 GMT+0530 (India Standard Time)";
        if (updatedDate == null || (updatedDate != null && updatedDate !== latestUpdate)) {            
            localStorage.setItem("LastUpdateDate", latestUpdate);
            window.location.reload(true);
        }
        let clientDashordParam = location.pathname.split("/")[1];
        
        return (
            <MuiThemeProvider theme={applyTheme}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <IntlProvider
                        locale={currentAppLocale.locale}
                        messages={currentAppLocale.messages}>
                        <RTL>
                        <main className="app-main-content-wrapper">
                        <div className="app-main-content">
                        <div className="app-main">
                                <Switch>
                                    <Route path='/forgotPassword' component={ForgotPassword} />
                                    <Route path='/signin' component={SignIn} />
                                    <Route path='/signup' component={SignUp} />
                                    <Route exact path={'/companyDashboard'} component={CompanyDashboard_Embeded} />
                                    <Route exact path='/companyBookkeepingDashboard' component={CompanyDashboard_Bookkeeping_Embeded} />
                                    <Route path={`/${clientDashordParam}/MVN`} component={MissingVenderName_Embedded} />
                                    <Route path={`/${clientDashordParam}/UCTEB`} component={UnCategorizedTran_Embedded} /> 
                                    {/* <Route path={`/${clientDashordParam}/RRDEB`} component={ReviewReconciliationDiscrepancies_Embedded} />                             */}
                                    <Route path={`/${clientDashordParam}/MS`} component={MissingDescription_Embedded} />
                                    <Route path={`/${clientDashordParam}/OOS`} component={OldOutstanding_Embedded} />
                                    <Route path={`/${clientDashordParam}/NBD`} component={NeedProperDescription_Embedded} />
                                    <Route path={`/${clientDashordParam}/CAN`} component={ChangeAccountNumber_Embedded} />
                                    <Route path={`/${clientDashordParam}/AACL`} component={AccountLineItem_Embedded} />
                                    <Route path={`/${clientDashordParam}/EMW4`} component={EmployeeMissW4Component_Embedded} />
                                    <Route path={`/${clientDashordParam}/VMW9`} component={VendorMissW9Component_Embedded} />
                                    <Route path={`/${clientDashordParam}/STEMD`} component={Report1099Status_Embedded} />
                                    <Route path={`/${clientDashordParam}/VenDisEMBD`} component={VendorDiscrepancies_Embedded} />                                    
                                    <Route path={`/${clientDashordParam}/RCPC`} component={RecordCheckPC_Embd} /> 
                                    <Route path={`/${clientDashordParam}/REP`} component={EmpPay_Embeded} />
                                    <Route path={`/${clientDashordParam}/EVF`} component={EmpVehicle_Embedded} />
                                    <Route path={`/${clientDashordParam}/TTCPA`} component={TransactionTagCPA_Embd} />
                                    <Route path={`/${clientDashordParam}/GQA`} component={GeneralQuesComm__Embedded} />
                                    <Route path={`/${clientDashordParam}/REPPE`} component={RecEmpPayPayroll_Embedded} />
                                    <Route path={`/${clientDashordParam}/MINL`} component={VerifyMinisterList_Embedded} />
                                    <Route path={`/${clientDashordParam}/APEM`} component={ApproveWorker_Embedded} />
                                    <Route path={`/${clientDashordParam}/TFBEMP`} component={AssignTFBtoEMP_Embedded} />
                                    <Route path={`/${clientDashordParam}/1099MTV`} component={Confirm1099Mailed_Embedded} />
                                    <Route path={`/${clientDashordParam}/REFNO`} component={MissRefNo_Embedded} />
                                    <Route path={`/${clientDashordParam}/CLAPP1099`} component={CLApproval1099}/>
                                    <Route path={'/401'} component={UnauthorizeComponent} />
                                    <Route path={"/CLD"} component={CheckLedgerData} />
                                    <RestrictedRoute
                                        path={`${match.url}`}
                                        authUser={authUser}
                                        component={MainApp}
                                    />
                                    <Route component={asyncComponent(() => import('../components/Error404'))} />
                                </Switch>
                            </div>
                        </div>   
                            <Footer />
                           </main>
                        </RTL>
                    </IntlProvider>
                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
        );
    }
}

const mapStateToProps = ({ settings, auth }) => {
    const { sideNavColor, locale, isDirectionRTL } = settings;
    const { authUser, initURL } = auth;
    return { sideNavColor, locale, isDirectionRTL, authUser, initURL }
};

export default connect(mapStateToProps, null)(App);


import React,{Component} from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import CommonComments from './../../../common/Comments';
import PaginationComponent from './../../../common/Pagination';
import { GET_REPORT_DATA, MARK_IGNORED_DATA,Revert_Ignored_DATA } from '../../../../appRedux/actions/ReportDetailAction';
import { ReportType, ModuleType, makeLinkForQuickbooks, numberWithCommas,LinkType } from '../../../common/commonServices'
import moment from 'moment';
import { bindActionCreators } from 'redux';
import CircularProgress from './../../../../components/CircularProgress';
import { toast } from 'react-toastify';
import { Vendors,AddNewVendor  } from '../../../../appRedux/actions/vendorAction';
import RSelect,{ components } from 'react-select';
import _ from 'lodash';
import { UpdateBatchVendor } from '../../../../appRedux/actions/LedgerAction';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import { de } from 'date-fns/locale';
import {MarkReportReview,GetReportReviewedHistory } from '../../../../appRedux/actions/LedgerAction';
import ClearIcon from '@material-ui/icons/Clear';

class TranMissVendorComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            parantData: this.props.location.state ? this.props.location.state : null,
            MissingData: [],
            companyId: 0,
            sortField: 'date',
            sortFormat: "D",
            PageNo: 1,
            PageSize: 10,
            ColorCode: "#e27b40",
            IsCommentModelOpen: false,
            sDate: new Date(2019, 0, 1),
            eDate: new Date(),
            loading: false,
            TotalRecords: 0,
            CommentReferenceId: 0,
            IsEmbeddedPage: false,
            SelectALL: false,
            SelectedRecords: [],
            IsIgnoredFilter:false,
            vendors:[],
            DisplayVendors: [],
            selectedVendors: { value: 0, label: "Select" },
            isOpen: false,
            DisplayName: "",
            ShowHistory:true,
            HistoryData:[]
        }
    }


    SelectALL = (e) => {
        if (this.state.MissingData.length > 0) {
            if (e.target.checked) {
                let all = [];
                let data = this.state.MissingData;
                for (let i = 0; i < data.length; i++) {
                    all.push(data[i].id);
                }
                this.setState({ SelectALL: true, SelectedRecords: all });
            }
            else {
                this.setState({ SelectALL: false, SelectedRecords: [] });

            }

        }

    }

    onSortChange = (fieldName) => {
        if (this.state.sortField == fieldName) {
            let formate = this.state.sortFormat == 'A' ? 'D' : 'A';
            this.setState({ sortFormat: formate, loading: true }, () => {
                this.GetReportData();
            });
        } else {
            this.setState({ sortField: fieldName, sortFormat: 'A', loading: true }, () => {
                this.GetReportData();
            });
        }
    }

    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('userData'));
        this.setState({
            companyId: user.companyId,
            loading: true
        }, () => {
            this.props.GetReportReviewedHistory(user.companyId,ReportType.MissingVendorName)
            this.GetReportData();
            this.props.Vendors(user.companyId);
        });
    }

    ChangePageSize = (size) => {

        this.setState({ loading: true, PageSize: parseInt(size), PageNo: 1 }, () => {
            this.GetReportData();
        });
    }

    ChangePage = (page) => {
        this.setState({ loading: true, PageNo: page, }, () => {
            this.GetReportData();
        });
    }

    LoadComments = (item) => {
        this.setState({ IsCommentModelOpen: true, CommentReferenceId: item.id });
    }
    HandleClose = () => {
        this.setState({ ShowHistory: false });
      }
    CloseComments = () => {
        this.setState({ IsCommentModelOpen: false });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.ReportData !== this.props.ReportData) {
            if (nextProps.ReportData.transactionStatus === 0) {
                var totalrecords = 0;
                if (nextProps.ReportData.returnObject != null && nextProps.ReportData.returnObject.length > 0) {
                    totalrecords = nextProps.ReportData.returnObject[0].totalrows
                }
                this.setState({ MissingData: nextProps.ReportData.returnObject, TotalRecords: totalrecords, PageSize: this.state.PageSize, PageNo: this.state.PageNo, loading: false, SelectALL: false, SelectedRecords: [] });
            } else {
                toast.error(nextProps.ReportData.resultMsg);
                this.setState({ loading: false });
            }
        }

        if (nextProps.ReportReviewdHistory !== undefined && nextProps.ReportReviewdHistory !== this.props.ReportReviewdHistory) {
            if (nextProps.ReportReviewdHistory.transactionStatus === 0) {
                    this.setState({ShowHistory: true,HistoryData:nextProps.ReportReviewdHistory.returnObject});
            } 
        }

        // test   -----------------------
        if (nextProps.CommonUpdate !== undefined && nextProps.CommonUpdate !== this.props.CommonUpdate) {
            if (nextProps.CommonUpdate.transactionStatus === 0) {
            //   let data = this.state.ReportData;
            //   if (this.state.IsReviewProcessing == false) {
            //     let obj = this.state.editedData;
            //     data.map((item, index) => {
            //       if (item.listID === obj.listID) {
            //         item.vendor1099 = obj.vendor1099
            //       }
            //     })
      
            //     this.setState({editedData: null, IsReviewProcessing: false, reportData: data }, () => { toast.success("Request Process Successfully");
            //     // this.GetReportData();
            //    });
            //   }
            //   else {
                this.setState({ IsReviewProcessing: false}, () => {
                  toast.success("Reviewed Successfully");
                this.props.GetReportReviewedHistory(this.state.companyId,ReportType.MissingVendorName)
                });
            //   }
            }
            else {
              this.setState({ loading: false }, () => { toast.error(`${nextProps.CommonUpdate.resultMsg}`); });
            }
      
          }
        if (nextProps.UpdateDescription !== this.props.UpdateDescription) {
            if (nextProps.UpdateDescription.transactionStatus === 0) {
                this.setState({
                    loading: true, editData: null, editIndex: -1, SelectALL: false, SelectedRecords: [],
                }, () => {
                    toast.success(`${nextProps.UpdateDescription.resultMsg}`);
                    this.GetReportData();
                });
            }
            else {
                this.setState({
                    loading: false,
                }, () => {
                    toast.error(`${nextProps.UpdateDescription.resultMsg}`);
                });
            }
        }

        if(nextProps.vendors !==undefined && nextProps.vendors !== this.props.vendors  ){

            let searchableVendors = [];
            if (nextProps.vendors != null && nextProps.vendors.length > 0) {
                searchableVendors = _.sortBy(nextProps.vendors, ['displayName']).map((value, index) => (
                    { value: value.vendorID, label: value.displayName }
                ))
            }
            let defaultValue = { value: 0, label: "Select" };
            searchableVendors.unshift(defaultValue);
            this.setState({
                vendors: nextProps.vendors,
                DisplayVendors: searchableVendors
            });

            // this.setState({
            //   vendors:nextProps.vendors
            // });
         }

         if (nextProps.BATCH_LEDGER_UPDATE != undefined && nextProps.BATCH_LEDGER_UPDATE !== this.props.BATCH_LEDGER_UPDATE) {
            if (nextProps.BATCH_LEDGER_UPDATE.transactionStatus === 0) {
                this.setState({
                    loading: true, editData: null, editIndex: -1, SelectedRecords: [], SelectALL: false,selectedVendors:{ value: 0, label: "Select" }
                }, () => {
                    toast.success("Record updated successfully");
                    this.GetReportData();
                });
            }
            else {
                this.setState({
                    loading: false, SelectedRecords: [], SelectALL: false
                }, () => {
                    toast.error(`${nextProps.BATCH_LEDGER_UPDATE.resultMsg}`);
                });
    
            }
        }

        if (nextProps.vendorAdd != undefined && nextProps.vendorAdd !== this.props.vendorAdd) {
            if (nextProps.vendorAdd.transactionStatus === 0) {
                this.setState({
                    loading: true, editData: null, editIndex: -1, SelectedRecords: [], SelectALL: false,selectedVendors:{ value: 0, label: "Select" },isOpen:false,DisplayName:""
                }, () => {
                    toast.success("Vendor Added successfully");
                    this.GetReportData();
                    this.props.Vendors(this.state.companyId);
                });
            }
            else {
                this.setState({
                    loading: false, SelectedRecords: [], SelectALL: false
                }, () => {
                    toast.error(`${nextProps.vendorAdd.resultMsg}`);
                });
            }
        }

    }

    onRefreshDataClick = () => {
        if (!this.state.loading) {
            this.setState({ loading: true }, () => {
                this.GetReportData(true);
            });
        }
    }
    onMarkReviewd = () => {
        this.setState({ 
            IsReviewProcessing: true,
          //  loading: true
          loading: false
           }, () => {
          this.props.MarkReportReview(this.state.companyId,ReportType.MissingVendorName);
        });
      }

    GetReportData(IsRefresh = false) {
        let state = this.state;
        let body = {
            companyID: state.companyId,
            reportType: ReportType.MissingVendorName,
            fromDate: moment(state.sDate).format("MM/DD/YYYY"),
            toDate: moment(state.eDate).format("MM/DD/YYYY"),
            sortField: state.sortField,
            sortFormat: state.sortFormat,
            pageNumber: state.PageNo,
            pageSize: state.PageSize,
            needUpdatQBORecords: IsRefresh,
            isAllIgnoreUnignore:state.IsIgnoredFilter
        };

        this.props.GET_REPORT_DATA(body);
       
    }

    UpdateItemCommentCount = (id) => {
        let data = this.state.MissingData;
        data.forEach(function (item) {

            if (item.id === id) {
                item.commentCount++
            }
        });

        this.setState({ MissingData: data });
    }

    onLinkClick = (rowData) => {
        let origin = makeLinkForQuickbooks();
        window.open(origin + rowData.transactionLink);
    }

    OnApplyIgnored = () => {
        let selectedData = this.state.SelectedRecords;
        if (selectedData == null || selectedData.length === 0) {
            toast.error("Please select atleast 1 record for processing request");
            return false;
        }

        let body = {
            "ids": selectedData,
            "reportType": ReportType.MissingVendorName,
            "companyID": this.state.companyId
        }

        this.setState({ loading: true }, () => {
            if(this.state.IsIgnoredFilter)
            {
                this.props.Revert_Ignored_DATA(body);                
            }
            else
            {
                this.props.MARK_IGNORED_DATA(body);                
            }      
        });
    }

    onCheckboxClick = (e, item) => {
        e.stopPropagation();
        let existInArray = false;
        let data = this.state.SelectedRecords;
        if (data != null && data.length > 0 && data.indexOf(item.id) > -1) {
            existInArray = true;
        }

        if (e.target.checked) {
            if (!existInArray) {
                data.push(item.id);
            }
        }
        else {
            if (existInArray) {
                data.splice(data.indexOf(item.id), 1);
            }
        }

        let all = (data.length == this.state.MissingData.length) ? true : false;
        this.setState({ SelectedRecords: data, SelectALL: all, editIndex: -1 });

    }

    HandleIgnored = (event) => {
        
        this.setState({ IsIgnoredFilter: (event.target.value=="true"?true:false),loading:true },()=>{ this.GetReportData()});
    }

    handalBatchUpdateVendorDropdown = (e) => {
        if (e.value == null || e.value == 0) {
            this.setState({ selectedVendors: e });
        }
        else {
            this.setState({ selectedVendors: e });
        }
    }

    OnAssignVendor = () => {
        
        let selectedData = this.state.SelectedRecords;
        let selectedVendor = this.state.selectedVendors;
        if (selectedData == null || selectedData.length === 0) {
            toast.error("Please select atleast 1 record for processing request");
            return false;
        }

        if (selectedVendor.value == null || selectedVendor.value == 0) {
            toast.error("Please select vendor");
            return false;
        }

        let body = {
            // "ids": selectedData,
            // "reportType": ReportType.UnCategorizeTransaction,
            // "companyID": this.state.companyId
            "txnId":selectedVendor.value,
            "glIds":selectedData,
            "companyID": this.state.companyId
        }

        this.setState({ loading: true }, () => {
             this.props.UpdateBatchVendor(body);  
        });
    }

    handleCancel = () => {
        this.setState({ isOpen: false ,DisplayName:""});
    };

    onPopupOpen = () => {
        this.setState({ isOpen: true,DisplayName:"" });
    };

    handleDisplayName = (e) => {
        this.setState({ DisplayName: e.target.value });
    }

    AddVendor = () => {
        let DisplayName = this.state.DisplayName;
        if (DisplayName == null || DisplayName == "") {
            toast.error("Display name required");
            return false;
        }

        let body = {
            "displayName":DisplayName,
            "companyId": this.state.companyId
        }

        this.setState({ loading: true,isOpen:false }, () => {
            this.props.AddNewVendor(body); 
        });
    }

    render() {
        const msgStyles = {
            background: "#334c5a",
            color: "white"
          };
          
          const NoOptionsMessage = props => {
            return (
              <components.NoOptionsMessage {...props}>
                <span className="custom-css-class" style={{cursor:"pointer"}} onClick={() => this.onPopupOpen()}><i class="zmdi zmdi-plus-circle"></i> Add new</span> 
              </components.NoOptionsMessage>
            );
          };
          

        let selectedItem = this.state.SelectedRecords;
        return (
            <React.Fragment>
                 {this.state.ShowHistory && this.state.HistoryData != null && this.state.HistoryData.length > 0 &&
          <React.Fragment>
            <Dialog
              className="comment-Top-align"
              maxWidth="sm"
              fullWidth={true}
              open={true}>
              <React.Fragment>
                <div className="Comments modal-header"><span className="log-history-title float-left">Reviewed History </span>
                  <Button className="jr-btn close-btn float-right" onClick={this.HandleClose}><ClearIcon /></Button>
                </div>
                <DialogContent>
                  {this.state.HistoryData.length == 0 ?
                    <div className="history-box">
                      <p className="history-item-text">No History Found</p>
                    </div>
                    :
                    <React.Fragment>
                      {this.state.HistoryData.map((item, i) => (
                        <div className="history-box">
                          {/* <p className="history-item-text">
                           
                          </p>
                           */}
                          <p className="history-date-text"> <strong style={{ color: "black" }}>  Last Reviewed  </strong> By <strong> {item.userName}</strong> <strong> on </strong>{item.stCreatedDate}</p>
                        </div>
                      ))}
                    </React.Fragment>
                  }
                </DialogContent>
              </React.Fragment>
            </Dialog>
          </React.Fragment>

        }
                <Dialog
                maxWidth="sm"
                fullWidth={true}
                open={this.state.isOpen}
                onClose={this.handleCancel}
            >
                
                    <DialogTitle>Add Vendor</DialogTitle>
                    <DialogContent>
                        <fieldset>
                            
                            <Grid container spacing={10} >

                                <Grid item sm={10} >
                                    <TextField
                                        name="displayname"
                                        label={"Display Name"}
                                        fullWidth
                                        onChange={(e) => this.handleDisplayName(e)}
                                        //defaultValue={this.state.printOnCheckName}
                                        value={this.state.DisplayName}
                                        margin="normal"
                                    />
                                </Grid>

                                
                            </Grid>
                            
                        </fieldset>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.AddVendor} color="primary">
                            Add
                       </Button>
                        <Button onClick={this.handleCancel} color="primary" autoFocus>
                            Cancel
                       </Button>
                    </DialogActions>
               
                </Dialog>
                {this.state.IsCommentModelOpen ?
                    <CommonComments
                        CloseComments={this.CloseComments}
                        ColorCode={this.state.ColorCode}
                        ModuleType={ModuleType.GeneralReportType}
                        ReportType={ReportType.MissingVendorName}
                        CompanyId={this.state.companyId}
                        RecordId={this.state.CommentReferenceId}
                        IsEmbeddedPage={this.state.IsEmbeddedPage}
                        UpdateItemCommentCount={this.UpdateItemCommentCount}
                    >
                    </CommonComments> : ""}
               
                    <div className="dashboardPage">
                        <div className="page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
                            <h2 className="mb-3 mb-sm-0">{JSON.parse(localStorage.getItem('userData')) != null ? JSON.parse(localStorage.getItem('userData')).companyName : ""} | {LinkType.TranMissVendor}</h2>
                        </div>
                        {this.state.loading ? <CircularProgress /> :
                            <div className="padding-box app-wrapper">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="float-right">
                                        <Button variant="raised" className="graycolor jr-btn bg-teal text-white refresh-btn" onClick={() => this.onMarkReviewd()}>Mark as Reviewed</Button>
                                            <Button variant="raised" className="share-btn orangeColor jr-btn bg-teal text-white refresh-btn" onClick={() => this.onRefreshDataClick()}  ><i className="zmdi zmdi-refresh "></i> Refresh Data</Button>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-12">
                                        <div className="float-right">
                                        <h4>Show Previously Ignored Transactions: </h4>
                                                <select style={{ backgroundColor: "#fff", marginRight: "10px" }} className="select-box arrow-box" onChange={this.HandleIgnored} value={this.state.IsIgnoredFilter} >
                                                    <option value={true}> YES </option>
                                                    <option value={false}> NO </option>                                                    
                                                </select>
                                        <h4>Assign Vendor: </h4>
                                        <RSelect
                                                    required={true}
                                                    name="BatchVendorID"
                                                    value={this.state.selectedVendors}
                                                    onChange={(e) => this.handalBatchUpdateVendorDropdown(e)}
                                                    options={this.state.DisplayVendors}
                                                    //defaultValue={{ value: 0, label: "select" }}
                                                    placeholder="Select"
                                                    components={{ NoOptionsMessage }}
                                                    styles={{ noOptionsMessage: base => ({ ...base, ...msgStyles }) }}
                                                  />
                                            <Button variant="raised" className=" jr-btn graycolor text-white refresh-btn" onClick={() => this.OnAssignVendor()} >Save</Button>
                                            <Button variant="raised" className=" jr-btn graycolor text-white refresh-btn" onClick={() => this.OnApplyIgnored()} >{this.state.IsIgnoredFilter?"Revert":"Ignore"}</Button>
                                           
                                        </div>
                                    </div> */}
                                </div>
                                <div class="row mt-2">
                                    <div className="col-md-12 ">
                                        <div className='d-flex justify-content-end align-items-center'>
                                            <div className='show-previously-box'>
                                            <div class="form-group d-flex mb-0">                                            
                                            <h4 for="staticEmail" class=" col-form-label">Show Previously Ignored Transactions:</h4>
                                            <div class="dropdown-input-box">
                                                <select style={{ backgroundColor: "#fff" }} className="select-box arrow-box" onChange={this.HandleIgnored} value={this.state.IsIgnoredFilter} >
                                                                                            <option value={true}> YES </option>
                                                                                            <option value={false}> NO </option>                                                    
                                                                                        </select>
                                            </div>
                                            </div>
                                            </div>
                                            <div className='assign-vendor-box'>
                                            <div class="form-group d-flex mb-0"> 
                                            <h4 for="staticEmail" class=" col-form-label">Assign Vendor Name:</h4>
                                            <div className='rselect-box'>
                                            <RSelect
                                                    required={true}
                                                    name="BatchVendorID"
                                                    value={this.state.selectedVendors}
                                                    onChange={(e) => this.handalBatchUpdateVendorDropdown(e)}
                                                    options={this.state.DisplayVendors}
                                                    //defaultValue={{ value: 0, label: "select" }}
                                                    placeholder="Select"
                                                    components={{ NoOptionsMessage }}
                                                    styles={{ noOptionsMessage: base => ({ ...base, ...msgStyles }) }}
                                                  />
                                            </div>
                                            </div>
                                            </div>
                                            <div className=''>
                                            <Button variant="raised" className=" jr-btn graycolor text-white refresh-btn" onClick={() => this.OnAssignVendor()} >Save</Button>
                                                <Button variant="raised" className=" jr-btn graycolor text-white refresh-btn" onClick={() => this.OnApplyIgnored()} >{this.state.IsIgnoredFilter?"Revert":"Ignore"}</Button>
                                            </div>
                                            </div>
                                    </div>  
                                </div>
                                <div className="table-responsive-material">
                                    <table className="table tablebox account-reconcilliation ">
                                        <thead  >
                                            <tr style={{ backgroundColor: this.state.ColorCode }}>
                                                <th className={`td-width ${this.state.sortField == 'date' ? this.state.sortFormat == 'A'
                                                    ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('date')}><p className="table-th-title"> Date</p> </th>
                                                <th className={`td-width ${this.state.sortField == 'transactionType' ? this.state.sortFormat == 'A'
                                                    ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('transactionType')}><p className="table-th-title">Type</p></th>
                                                <th className={`td-width-amount ${this.state.sortField == 'num' ? this.state.sortFormat == 'A'
                                                    ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('num')}><p className="table-th-title"> Number</p></th>
                                                <th className={`td-width-box ${this.state.sortField == 'account' ? this.state.sortFormat == 'A'
                                                    ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('account')}><p className="table-th-title">A/C No.</p></th>
                                                <th className={` ${this.state.sortField == 'memoOrDescription' ? this.state.sortFormat == 'A'
                                                    ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('memoOrDescription')}><p className="table-th-title"> Memo/Desc.</p></th>
                                                <th className={`td-width-amount ${this.state.sortField == 'amount' ? this.state.sortFormat == 'A'
                                                    ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('amount')}><p className="table-th-title"> Amount</p></th>
                                                <th className="td-width-90 text-center">
                                                    <label className="container101 header-check">
                                                        {this.state.SelectALL ?
                                                            <input className="second-check-box" checked="checked" type="checkbox" onClick={(e) => this.SelectALL(e)} />
                                                            :
                                                            <input className="second-check-box" type="checkbox" onClick={(e) => this.SelectALL(e)} />
                                                        }
                                                        <span className="checkmark101 white_border"></span>
                                                    </label>
                                                </th>
                                                <th className="td-width-90 text-center"><img src="/vendors/WhiteCommnet.png" alt="comments" /></th>
                                                <th className="td-width-90 text-center "><img src="/vendors/Link-arrow_header.png" alt="link" /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(this.state.MissingData && this.state.MissingData.length > 0) && this.state.MissingData.map((item, j) => (
                                                <React.Fragment key={j}>
                                                    {<tr>
                                                        <td>{item.date}</td>
                                                        <td>{item.transactionType}</td>
                                                        <td>{item.num}</td>
                                                        <td>{item.account}</td>
                                                        <td>{item.memoOrDescription}</td>
                                                        <td className="text-center">{numberWithCommas(item.amount)}</td>
                                                        <td className="text-center">
                                                            <label className="container101">
                                                                {(this.state.SelectALL || selectedItem.length > 0 && (selectedItem.indexOf(item.id) > -1)) ?
                                                                    <input className="second-check-box" checked="checked" type="checkbox" onClick={(e) => this.onCheckboxClick(e, item)} />
                                                                    :
                                                                    <input className="second-check-box" type="checkbox" onClick={(e) => this.onCheckboxClick(e, item)} />
                                                                }
                                                                <span className="checkmark101"></span>
                                                            </label>
                                                        </td>
                                                        <td className="text-center">
                                                            <div className="panel-commentCount">
                                                                <img className={item.commentCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/Vector Smart Object.png" alt="comments" onClick={() => this.LoadComments(item)}></img>
                                                                {item.unreviewCommentCount !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">{item.unreviewCommentCount}</span>}
                                                            </div>
                                                        </td>
                                                        <td className="text-center"><img src="/vendors/Shape 1.png" alt="link" onClick={() => this.onLinkClick(item)}></img></td>
                                                    </tr>}
                                                </React.Fragment>
                                            ))}

                                            {this.state.MissingData.length == 0 ?
                                                <tr>
                                                    <td className="text-center" colSpan="9">
                                                    No issues here! Keep up the good work!
                                                    </td>
                                                </tr>
                                                : ""}
                                        </tbody>
                                    </table>
                                </div>
                                <PaginationComponent
                                    selectedPageNo={this.state.PageNo}
                                    selectedSize={this.state.PageSize}
                                    TotalRecords={this.state.TotalRecords}
                                    ChangePageSize={this.ChangePageSize}
                                    ChangePage={this.ChangePage}
                                    CurrentRecords={this.state.MissingData.length}
                                >
                                </PaginationComponent>
                            </div>
                        }
                    </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ReportData: state.ReportData.ReportData,
        UpdateDescription: state.ReportData.UpdateDescription,
        vendors:state.vendors.vendors,
        BATCH_LEDGER_UPDATE : state.ledgers.BATCH_LEDGER_UPDATE,
        vendorAdd:state.vendors.vendorAdd,
        CommonUpdate: state.ledgers.CommonUpdate,
        ReportReviewdHistory:state.ledgers.ReportReviewedHistory
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        GET_REPORT_DATA,
        MARK_IGNORED_DATA,
        Revert_Ignored_DATA,
        Vendors,
        MarkReportReview,
        UpdateBatchVendor,
        GetReportReviewedHistory,
        AddNewVendor
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TranMissVendorComponent);
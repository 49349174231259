import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import CommonComments from './../../../common/Comments';
import PaginationComponent from './../../../common/Pagination';
import { GET_REPORT_DATA } from '../../../../appRedux/actions/ReportDetailAction';
import { ReportType, ModuleType, makeLinkForQuickbooks, numberWithCommas,LinkType} from '../../../common/commonServices'
import moment from 'moment';
import { bindActionCreators } from 'redux';
import CircularProgress from './../../../../components/CircularProgress';
import { toast } from 'react-toastify';
import {MarkReportReview,GetReportReviewedHistory } from '../../../../appRedux/actions/LedgerAction';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ClearIcon from '@material-ui/icons/Clear';

class OOTranComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            parantData: this.props.location.state ? this.props.location.state : null,
            MissingData: [],
            sortField: 'date',
            sortFormat: "D",
            PageNo: 1,
            PageSize: 10,
            ColorCode: "#e27b40",
            sDate: new Date(2019, 0, 1),
            eDate: new Date(),
            loading: false,
            TotalRecords: 0,
            CommentReferenceId: 0,
            IsEmbeddedPage: false,
            ShowHistory:true,
            HistoryData:[]
        }
    }

    onSortChange = (fieldName) => {
        if (this.state.sortField == fieldName) {
            let formate = this.state.sortFormat == 'A' ? 'D' : 'A';
            this.setState({ sortFormat: formate, loading: true }, () => {
                this.GetReportData();
            });
        } else {
            this.setState({ sortField: fieldName, sortFormat: 'A', loading: true }, () => {
                this.GetReportData();
            });
        }
    }

    HandleClose = () => {
        this.setState({ ShowHistory: false });
      }
    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('userData'));
        this.setState({
            companyId: user.companyId,
            loading: true
        }, () => {
            this.props.GetReportReviewedHistory(user.companyId,ReportType.OutstandingTransection)
            this.GetReportData();
        });
    }

    ChangePageSize = (size) => {

        this.setState({ loading: true, PageSize: parseInt(size), PageNo: 1 }, () => {
            this.GetReportData();
        });
    }

    ChangePage = (page) => {
        this.setState({ loading: true, PageNo: page, }, () => {
            this.GetReportData();
        });
    }

    LoadComments = (item) => {
        this.setState({ IsCommentModelOpen: true, CommentReferenceId: item.id });
    }

    CloseComments = () => {
        this.setState({ IsCommentModelOpen: false });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.ReportData !== this.props.ReportData) {
            if (nextProps.ReportData.transactionStatus === 0) {
                var totalrecords = 0;
                if (nextProps.ReportData.returnObject != null && nextProps.ReportData.returnObject.length > 0) {
                    totalrecords = nextProps.ReportData.returnObject[0].totalrows
                }

                this.setState({ MissingData: nextProps.ReportData.returnObject, TotalRecords: totalrecords, PageSize: this.state.PageSize, PageNo: this.state.PageNo, loading: false });
            } else {
                toast.error(nextProps.ReportData.resultMsg);
                this.setState({ loading: false });
            }
        }
        if (nextProps.ReportReviewdHistory !== undefined && nextProps.ReportReviewdHistory !== this.props.ReportReviewdHistory) {
            if (nextProps.ReportReviewdHistory.transactionStatus === 0) {
                    this.setState({ShowHistory: true,HistoryData:nextProps.ReportReviewdHistory.returnObject});
            } 
        }
        if (nextProps.CommonUpdate !== undefined && nextProps.CommonUpdate !== this.props.CommonUpdate) {
            if (nextProps.CommonUpdate.transactionStatus === 0) {
            //   let data = this.state.ReportData;
            //   if (this.state.IsReviewProcessing == false) {
            //     let obj = this.state.editedData;
            //     data.map((item, index) => {
            //       if (item.listID === obj.listID) {
            //         item.vendor1099 = obj.vendor1099
            //       }
            //     })
      
            //     this.setState({editedData: null, IsReviewProcessing: false, reportData: data }, () => { toast.success("Request Process Successfully");
            //     // this.GetReportData();
            //    });
            //   }
            //   else {
                this.setState({ IsReviewProcessing: false}, () => {
                  toast.success("Reviewed Successfully");
                  this.props.GetReportReviewedHistory(this.state.companyId,ReportType.OutstandingTransection)
                });
            //   }
            }
            else {
              this.setState({ loading: false }, () => { toast.error(`${nextProps.CommonUpdate.resultMsg}`); });
            }
      
          }
    }

    onRefreshDataClick = () => {
        if (!this.state.loading) {
            this.setState({ loading: true }, () => {
                this.GetReportData(true);
            });
        }
    }

    onMarkReviewd = () => {
        this.setState({ 
            // IsReviewProcessing: true,
          //  loading: true
          loading: false
           }, () => {
          this.props.MarkReportReview(this.state.companyId,ReportType.OutstandingTransection);
        });
      }

    GetReportData(IsRefresh = false) {
        let state = this.state;
        let body = {
            companyID: state.companyId,
            reportType: ReportType.OutstandingTransection,
            fromDate: moment(state.sDate).format("MM/DD/YYYY"),
            toDate: moment(state.eDate).format("MM/DD/YYYY"),
            sortField: state.sortField,
            sortFormat: state.sortFormat,
            pageNumber: state.PageNo,
            pageSize: state.PageSize,
            needUpdatQBORecords: IsRefresh
        };

        this.props.GET_REPORT_DATA(body);
    }

    onLinkClick = (rowData) => {
        let origin = makeLinkForQuickbooks();
        window.open(origin + rowData.transactionLink);
    }


    UpdateItemCommentCount = (id) => {
        let data = this.state.MissingData;
        data.forEach(function (item) {

            if (item.id === id) {
                item.commentCount++
            }
        });

        this.setState({ MissingData: data });
    }

    render() {
        return (
            <React.Fragment>
                 {this.state.ShowHistory && this.state.HistoryData != null && this.state.HistoryData.length > 0 &&
          <React.Fragment>
            <Dialog
              className="comment-Top-align"
              maxWidth="sm"
              fullWidth={true}
              open={true}>
              <React.Fragment>
                <div className="Comments modal-header"><span className="log-history-title float-left">Reviewed History </span>
                  <Button className="jr-btn close-btn float-right" onClick={this.HandleClose}><ClearIcon /></Button>
                </div>
                <DialogContent>
                  {this.state.HistoryData.length == 0 ?
                    <div className="history-box">
                      <p className="history-item-text">No History Found</p>
                    </div>
                    :
                    <React.Fragment>
                      {this.state.HistoryData.map((item, i) => (
                        <div className="history-box">
                          {/* <p className="history-item-text">
                           
                          </p>
                           */}
                          <p className="history-date-text"> <strong style={{ color: "black" }}>  Last Reviewed  </strong> By <strong> {item.userName}</strong> <strong> on </strong>{item.stCreatedDate}</p>
                        </div>
                      ))}
                    </React.Fragment>
                  }
                </DialogContent>
              </React.Fragment>
            </Dialog>
          </React.Fragment>

        }
                {this.state.IsCommentModelOpen ?
                    <CommonComments
                        CloseComments={this.CloseComments}
                        ColorCode={this.state.ColorCode}
                        ModuleType={ModuleType.GeneralReportType}
                        ReportType={ReportType.OutstandingTransection}
                        CompanyId={this.state.companyId}
                        RecordId={this.state.CommentReferenceId}
                        IsEmbeddedPage={this.state.IsEmbeddedPage}
                        UpdateItemCommentCount={this.UpdateItemCommentCount}

                    >
                    </CommonComments> : ""}


                <div className="dashboardPage">
                    <div className="page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
                        <h2 className="title mb-3 mb-sm-0">{JSON.parse(localStorage.getItem('userData')) != null ? JSON.parse(localStorage.getItem('userData')).companyName : ""} | {LinkType.OOTran}</h2>
                    </div>
                    {this.state.loading ? <CircularProgress /> :
                        <div className="padding-box app-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="float-right">
                                    <Button variant="raised" className="graycolor jr-btn bg-teal text-white refresh-btn" onClick={() => this.onMarkReviewd()}>Mark as Reviewed</Button>
                                        <Button variant="raised" className="share-btn jr-btn bg-teal text-white refresh-btn" onClick={() => this.onRefreshDataClick()}  ><i className="zmdi zmdi-refresh "></i> Refresh Data</Button>

                                    </div>
                                    {/* <div className="row">
                                        <div className="col-md-8">
                                        </div>
                                        </div> */}
                                </div>
                            </div>
                            <div className="table-responsive-material">
                                <table className="table tablebox account-reconcilliation">
                                    <thead  >
                                        <tr style={{ backgroundColor: this.state.ColorCode }}>
                                            <th className={`td-width ${this.state.sortField == 'date' ? this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('date')}><p className="table-th-title"> Date</p> </th>
                                            <th className={`td-width ${this.state.sortField == 'transactionType' ? this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('transactionType')}><p className="table-th-title"> Type</p></th>
                                            <th className={`td-width-amount ${this.state.sortField == 'num' ? this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('num')}><p className="table-th-title"> Number</p></th>
                                            <th className={`td-width ${this.state.sortField == 'name' ? this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('name')}><p className="table-th-title"> Name</p></th>
                                            <th className={` ${this.state.sortField == 'memoOrDescription' ? this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('memoOrDescription')}><p className="table-th-title"> Memo/Desc.</p></th>
                                            <th className={`td-width-amount text-center  ${this.state.sortField == 'amount' ? this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('amount')}><p className="table-th-title"> Amount</p></th>
                                            <th className={`td-width-box ${this.state.sortField == 'account' ? this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('account')}><p className="table-th-title"> A/C No.</p></th>

                                            <th className="td-width-90 text-center"><img src="/vendors/WhiteCommnet.png" alt="comments" /></th>
                                            <th className="td-width-90 text-center "><img src="/vendors/Link-arrow_header.png" alt="link" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(this.state.MissingData && this.state.MissingData.length > 0) && this.state.MissingData.map((item, j) => (
                                            <React.Fragment key={j}>
                                                {<tr>
                                                    <td>{item.date}</td>
                                                    <td>{item.transactionType}</td>
                                                    <td>{item.num}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.memoOrDescription}</td>
                                                    <td className="text-center">{numberWithCommas(item.amount)}</td>
                                                    <td>{item.account}</td>
                                                    <td className="text-center">
                                                        <div className="panel-commentCount">
                                                            <img className={item.commentCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/Vector Smart Object.png" alt="New Comments" onClick={() => this.LoadComments(item)}></img>
                                                            {item.unreviewCommentCount !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">{item.unreviewCommentCount}</span>}
                                                        </div>
                                                    </td>
                                                    <td className="text-center"><img src="/vendors/Shape 1.png" alt="QBDlink" onClick={() => this.onLinkClick(item)}></img></td>
                                                </tr>}
                                            </React.Fragment>
                                        ))}

                                        {this.state.MissingData.length == 0 ?
                                            <tr>
                                                <td className="text-center" colSpan="9">
                                                No issues here! Keep up the good work!
                                            </td>
                                            </tr>
                                            : ""}
                                    </tbody>
                                </table>
                            </div>
                            <PaginationComponent
                                selectedPageNo={this.state.PageNo}
                                selectedSize={this.state.PageSize}
                                TotalRecords={this.state.TotalRecords}
                                ChangePageSize={this.ChangePageSize}
                                ChangePage={this.ChangePage}
                                CurrentRecords={this.state.MissingData.length}
                            >
                            </PaginationComponent>
                        </div>
                    }
                </div>

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ReportData: state.ReportData.ReportData,
        CommonUpdate: state.ledgers.CommonUpdate,
        ReportReviewdHistory:state.ledgers.ReportReviewedHistory
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        GET_REPORT_DATA,
        MarkReportReview,
        GetReportReviewedHistory
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(OOTranComponent);
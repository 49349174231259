import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';

class ArchivedHistoryComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: props.data
        }
    }

    CloseModel = () =>{
        this.props.CloseHistory();
    }

    render() {
        return (
            <React.Fragment>
                <Dialog
                    className="comment-Top-align"
                    maxWidth="sm"
                    fullWidth={true}
                    open={true}>
                    <DialogTitle><span className="log-history-title float-left"> Archived History</span>
                        <Button className="jr-btn close-btn float-right " onClick={this.CloseModel}><ClearIcon /></Button>
                    </DialogTitle>
                    <DialogContent>                        
                        {this.state.data.map((item, i) => (
                            <div className="history-box">
                                <p className="history-item-text">Balance sheet report as on {item.stReportDate}  was archived </p> 
                                <p className="history-date-text"> <strong> By </strong> {item.userName}<strong> on </strong>{item.stArchiveDate}</p>
                            </div>
                        ))}
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        )
    }
}

export default ArchivedHistoryComponent;
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import PaginationComponent from "./../../../../src/app/common/Pagination";
import { ReportType, Base64, EmployeeStatus,LinkType } from "./../../../app/common/commonServices";
import moment from 'moment';
import { bindActionCreators } from 'redux';
import CircularProgress from './../../../components/CircularProgress';
import { GET_CompanyDashboard_PayrollEmployeeReportDetailList_BES, UPDATE_EMPLOYEE_W4_DATA_BES } from "./../../../appRedux/actions/ReportDetailAction";
import { getCompnay } from './../../../appRedux/actions/companiesAction';
import { toast } from 'react-toastify';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import RefreshDataModal from '../../../components/RefreshDataModal'

class EmployeeMissW4Component_Embedded extends Component {
    constructor(props) {
        super(props);

        let endDate = new Date();
        let startDate = new Date(endDate.getFullYear(), 0, 1);

        if (endDate.getMonth() <= 2) {
            startDate = new Date(startDate.getFullYear() - 1, 0, 1);
            endDate = new Date(endDate.getFullYear() - 1, 11, 31);
        }

        this.state = {
            parantData: this.props.location.state ? this.props.location.state : null,
            MissingData: [],
            companyId: 0,
            sortField: 'name',
            sortFormat: "A",
            PageNo: 1,
            PageSize: 10,
            ColorCode: "#f0ca4c",
            IsCommentModelOpen: false,
            sDate: startDate,//new Date(2019, 0, 1),
            eDate:endDate,// new Date(),
            loading: false,
            TotalRecords: 0,
            CommentReferenceId: 0,
            IsEmbeddedPage: false,
            SelectedRecords: [],
            SelectALL: false,
            HomeLink: "",
            ApproverName: "",
            ShowApprovedInput: false,
            ShowComplete: true,
            companyName: "",
            isRefreshDataModalOpen:false
        }
    }

    onSortChange = (fieldName) => {
        if (this.state.sortField == fieldName) {
            let formate = this.state.sortFormat == 'A' ? 'D' : 'A';
            this.setState({ sortFormat: formate, loading: true }, () => {
                this.GetReportData();
            });
        } else {
            this.setState({ sortField: fieldName, sortFormat: 'A', loading: true }, () => {
                this.GetReportData();
            });
        }
    }

    refreshData = () => {
        if (!this.state.loading) {
            this.setState({ loading: true,isRefreshDataModalOpen:false }, () => {
                this.GetReportData(true);
            });
        }
    }

    onRefreshDataClick = () => {
        
        this.setState({isRefreshDataModalOpen:true})
    }

    handleRefreshDataModal=()=>{
        this.setState({isRefreshDataModalOpen:false})
      }

    ChangePageSize = (size) => {

        this.setState({ loading: true, PageSize: parseInt(size), PageNo: 1 }, () => {
            this.GetReportData();
        });
    }

    ChangePage = (page) => {
        this.setState({ loading: true, PageNo: page, }, () => {
            this.GetReportData();
        });
    }

    GetReportData(IsRefresh = false) {
        let state = this.state;
        let body = {
            companyID: state.companyId,
            reportType: ReportType.EmployeeMissingW4,
            fromDate: moment(state.sDate).format("MM/DD/YYYY"),
            toDate: moment(state.eDate).format("MM/DD/YYYY"),
            sortField: state.sortField,
            sortFormat: state.sortFormat,
            pageNumber: state.PageNo,
            pageSize: state.PageSize,
            needUpdatQBORecords: IsRefresh
        };

        this.props.GET_CompanyDashboard_PayrollEmployeeReportDetailList_BES(body);
    }

    getCompanyId = () => {
        let value = queryString.parse(this.props.location.search);
        localStorage.setItem("q", JSON.stringify(value));
        let data = localStorage.getItem("q");

        if (data != null && data != undefined) {
            data = JSON.parse(data);
            this.setState({
                companyId: parseInt(Base64.decode(data.q)),
                companyName:Base64.decode(data.p),
                loading: true
            }, () => {
                this.GetReportData();
                this.props.getCompnay(parseInt(Base64.decode(data.q)));
            });
        }
        else {
            this.setState({ loading: false });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.ReportData !== this.props.ReportData) {
            if (nextProps.ReportData.transactionStatus === 0) {
                var totalrecords = 0;
                if (nextProps.ReportData.returnObject != null && nextProps.ReportData.returnObject.length > 0) {
                    totalrecords = nextProps.ReportData.returnObject[0].totalrows
                }
                this.setState({ MissingData: nextProps.ReportData.returnObject, TotalRecords: totalrecords, PageSize: this.state.PageSize, PageNo: this.state.PageNo, loading: false });
            }
            else {
                toast.error(nextProps.ReportData.resultMsg);
                this.setState({ loading: false });
            }
        }

        if (nextProps.UpdateDescription !== this.props.UpdateDescription) {
            if (nextProps.UpdateDescription.transactionStatus === 0) {
                this.setState({
                    loading: true, editData: null, editIndex: -1, SelectedRecords: [],
                    ShowApprovedInput: false, ApproverName: null, SelectALL: false
                }, () => {
                    toast.success(`${nextProps.UpdateDescription.resultMsg}`);
                    this.GetReportData();
                });
            }
            else {
                this.setState({ loading: false, SelectedRecords: [], ShowApprovedInput: false, ApproverName: null, SelectALL: false }, () => {
                    toast.error(`${nextProps.UpdateDescription.resultMsg}`);
                });
            }
        }

        if (nextProps.company !== this.props.company) {
            let showAdd = true;
            if (nextProps.company.payrollSoftware === "QuickBooks Payroll" || nextProps.company.payrollSoftware === "QuickBooks Full Service") {
                showAdd = false;
            }
            this.setState({ loading: false, company: nextProps.company, ShowComplete: showAdd });
        }
    }

    componentDidMount() {
        let value = queryString.parse(this.props.location.search);
        //localStorage.setItem("homeURL", window.location.href.replace(window.location.origin, ""))
        let dashboardURl = localStorage.getItem("homeURL");
        this.setState({
            loading: true,
            HomeLink: dashboardURl ? dashboardURl : "?q="+ value.q + "&p=" + value.p
        }, () => {
            this.getCompanyId();
        });
    }


    OnApplyApproved = () => {
        let selectedData = this.state.SelectedRecords;
        if (selectedData == null || selectedData.length === 0) {
            toast.error("Please select atleast 1 record for processing request");
            return false;
        }

        let approver = this.state.ApproverName;
        if (approver == null || approver.trim().replace(/\s/g, '') === "") {
            toast.error("Please enter the approver name");
            return false;
        }

        let body = {
            "ids": selectedData,
            "reportType": ReportType.EmployeeMissingW4,
            "companyID": this.state.companyId,
            "createdByName": "string"
        }

        this.setState({ loading: true }, () => {
            this.props.UPDATE_EMPLOYEE_W4_DATA_BES(body);
        });
    }

    SelectALL = (e) => {
        if (this.state.MissingData.length > 0) {
            if (e.target.checked) {
                let all = [];
                let data = this.state.MissingData;
                for (let i = 0; i < data.length; i++) {
                    all.push(data[i].employeeID);
                }
                this.setState({ SelectALL: true, SelectedRecords: all, ShowApprovedInput: true });
            }
            else {
                this.setState({ SelectALL: false, SelectedRecords: [], ShowApprovedInput: false });
            }
        }
    }

    onCheckboxClick = (e, item) => {
        e.stopPropagation();

        let existInArray = false;
        let data = this.state.SelectedRecords;
        if (data != null && data.length > 0 && data.indexOf(item.employeeID) > -1) {
            existInArray = true;
        }

        if (e.target.checked) {
            if (!existInArray) {
                data.push(item.employeeID);
            }
        }
        else {
            if (existInArray) {
                data.splice(data.indexOf(item.employeeID), 1);
            }
        }
        let all = (data.length === this.state.MissingData.length) ? true : false;
        let showinput = data.length > 0 ? true : false;
        this.setState({ SelectedRecords: data, ShowApprovedInput: showinput, SelectALL: all });
    }

    handleApprovedBy = (e) => {
        this.setState({ ApproverName: e.target.value });
    }


    render() {

        let selectedItem = this.state.SelectedRecords;

        return (
            <div className="dashboardPage embeded-dashboard">
                <RefreshDataModal visible={this.state.isRefreshDataModalOpen}
                 handlePopUpClose={this.handleRefreshDataModal} refreshData={this.refreshData}/>
                <ToastContainer autoClose={3000} />
                <div className=" page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
                    <h2 className="mb-3 mb-sm-0">{this.state.companyName} |  {LinkType.EmployeeMissW4}</h2>
                    <Link className="home-link" to={this.state.HomeLink} > Home </Link>
                </div>
                {this.state.loading ? <CircularProgress></CircularProgress>
                    :
                    <React.Fragment>
                        <div className="padding-box app-wrapper bgcolor">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="float-right">
                                                {this.state.ShowComplete &&
                                                    <Button variant="raised" className=" jr-btn g-teal  text-white refresh-btn graycolor" onClick={() => this.OnApplyApproved()}  >Completed</Button>
                                                }
                                                <Button variant="raised" style={{ backgroundColor: this.state.ColorCode }} className="jr-btn text-white refresh-btn" onClick={() => this.onRefreshDataClick()}  ><i className="zmdi zmdi-refresh "></i> Refresh Data</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.state.ShowApprovedInput ?
                                <div className="row" style={{ marginTop: "20px" }}>
                                    <div className="col-md-12">
                                        <div className="float-right">
                                            <label><strong> Approved By </strong></label> &nbsp;&nbsp;
                                            <input type="text" placeholder="enter your name" onChange={this.handleApprovedBy} />
                                        </div>
                                    </div>
                                </div>
                                : ""}
                            <div className="table-responsive-material">
                                <table className="table tablebox account-reconcilliation">
                                    <thead>
                                        <tr style={{ backgroundColor: this.state.ColorCode }}>
                                            <th className={`td-width ${this.state.sortField === 'name' ? this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('name')}><p className="table-th-title"> Name</p> </th>
                                            <th className={`td-width ${this.state.sortField === 'status' ? this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('status')}><p className="table-th-title"> W-4 Status</p></th>
                                            {this.state.ShowComplete &&
                                                <th className="td-width text-center">
                                                    <label className="container101 header-check">
                                                        {this.state.SelectALL ?
                                                            <input className="second-check-box" checked="checked" type="checkbox" onClick={(e) => this.SelectALL(e)} />
                                                            :
                                                            <input className="second-check-box" type="checkbox" onClick={(e) => this.SelectALL(e)} />
                                                        }
                                                        <span className="checkmark101 yellowMark white_border"></span>
                                                    </label>
                                                </th>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.MissingData.map((item, i) => (
                                            <tr>
                                                <td>{item.name}</td>
                                                <td>{item.status === EmployeeStatus.Pending ? 'Pending' : item.status === EmployeeStatus.Completed ? 'Completed' : 'Not Selected'}</td>
                                                {this.state.ShowComplete &&
                                                    <td className="text-center">
                                                        <label claclassNamess="container101">
                                                            {(this.state.SelectALL || selectedItem.length > 0 && (selectedItem.indexOf(item.id) > -1)) ?
                                                                <input className="second-check-box" checked="checked" type="checkbox" onClick={(e) => this.onCheckboxClick(e, item)} />
                                                                :
                                                                <input className="second-check-box" type="checkbox" onClick={(e) => this.onCheckboxClick(e, item)} />
                                                            }
                                                            <span className="checkmark101 yellow_CheckMark"></span>
                                                        </label>
                                                    </td>
                                                }
                                            </tr>
                                        ))}
                                        {this.state.MissingData.length === 0 && <tr>
                                            <td className="text-center" colSpan="6">
                                            No issues here! Keep up the good work!
                                    </td>
                                        </tr>}
                                    </tbody>
                                </table>
                            </div>
                            <PaginationComponent
                                selectedPageNo={this.state.PageNo}
                                selectedSize={this.state.PageSize}
                                TotalRecords={this.state.TotalRecords}
                                ChangePageSize={this.ChangePageSize}
                                ChangePage={this.ChangePage}
                                CurrentRecords={this.state.MissingData.length}
                            >
                            </PaginationComponent>
                        </div>
                    </React.Fragment>
                }

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ReportData: state.ReportData.ReportData,
        UpdateDescription: state.ReportData.UpdateDescription,
        company: state.companyReducer.companyDeatils
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        GET_CompanyDashboard_PayrollEmployeeReportDetailList_BES,
        UPDATE_EMPLOYEE_W4_DATA_BES,
        getCompnay
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeMissW4Component_Embedded);
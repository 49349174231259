import * as loansService from '../services/loansService';
import React from 'react';
import { toast } from "react-toastify";

export const LOANS_SUCCESS = "LOANS_SUCCESS";
export const LOANS_ERROR = "LOANS_ERROR";

export const ADDLOANS_SUCCESS = "ADDLOANS_SUCCESS";
export const ADDLOANS_ERROR = "ADDLOANS_ERROR";

export const UPDATELOANS_SUCCESS = "UPDATELOANS_SUCCESS";
export const UPDATELOANS_ERROR = "UPDATELOANS_ERROR";

export const DELETELOANS_SUCCESS = "DELETELOANS_SUCCESS";
export const DELETELOANS_ERROR = "DELETELOANS_ERROR";


export const PROPERTY_SUCCESS = "PROPERTY_SUCCESS";
export const PROPERTY_ERROR = "PROPERTY_ERROR";



function loansSuccess (payload){
    return{
        type: LOANS_SUCCESS,
        payload
    }
}

function propertySuccess (payload){
    return{
        type: PROPERTY_SUCCESS,
        payload
    }
}

function propertyError (payload){
    return{
        type: PROPERTY_ERROR,
        payload
    }
}

function loansError (payload){
    return{
        type: LOANS_ERROR,
        payload
    }
}


function getCompanyId () {
    let user = JSON.parse(localStorage.getItem('userData'));
    //let decode = jwt_decode(user.Authorization);
    const CompanyId = user.companyId;
    return CompanyId;
}


export const searchLoans = (companyId,text) => {
  return (dispatch) => {
        loansService.searchLoans(companyId,text)
            .then((response)=>{
                return dispatch(loansSuccess(response))
            })
            .catch((err)=>{
                 toast.error(`${err.response.data}`)
            })
    }
}

export function propertyTypes(){
    return (dispatch) => {
        // dispatch()
        loansService.getPropertyTypes()
        .then((res) => {
            return dispatch(propertySuccess(res))
        })
        .catch((err) => {
             toast.error(`${err.response.data}`)
        })
    }
}

export function getLoans(companyId,search,pageNumber,pageSize,sortField,sortFormat){
    return (dispatch) => {
        loansService.getLoans(companyId,search,pageNumber,pageSize,sortField,sortFormat)
        .then((res) => {
            return dispatch(loansSuccess(res))
        })
        .catch((err) => {
              toast.error(`${err.response.data}`)
        })
    }
}

export function addLoan(data){
    return (dispatch) => {
        loansService.addLoans(data)
        .then(res => {
            dispatch({type:'RELOAD',payload:data});
            toast.success("Loan Added Successfully.");
        })
        .catch(err => {
            toast.error(`${err.response.data}`)
        })
    }
}

export function updateLoan(data){
    return (dispatch) => {
        loansService.updateLoans(data)
        .then(res => {
            dispatch({type:'RELOAD',payload:res});
             toast.success("Loan Updated Successfully.");

        })
        .catch(err => {
             toast.error(`${err.response.data}`)
        })
    }
}

export function deleteLoan(value){
    return (dispatch) => {
        loansService.deleteLoans(value)
        .then(res => {
             dispatch({type:'RELOAD',payload:res});
            toast.success("Loan Delete Successfully.");
        })
        .catch(err => {
            toast.error(`${err.response.data}`)
        })
    }
}

import baseService from './baseService';

export function isConnected(id) {
    return baseService.get('quickbooks/isConnected?companyId=' + id);
}
export function quickbooksSyncStatus() {
    return baseService.get('quickbooks/syncMessages');
}
export function quickbooksSync(data) {
	var obj= {
		"startDate":data.StartDate,
		"endDate": data.EndDate,
		"accountingMethod": data.AccountingMethod,
		"syncCompanyInfo": data.syncCompanyInfo,
		"syncDeposits": data.syncDeposits,
		"syncInvoices": data.syncInvoices,
		"syncCustomers": data.SyncCustomers,
		"syncVendors": data.syncVendors,
		"syncAccounts": data.syncAccounts,
		"syncGeneralLedgerReview": data.syncLedgers,
		"syncEmployee": data.SyncEmployee,
		// "syncGeneralLedgerTransaction": data.syncGeneralLedgerTransaction,
		// "syncBill": data.syncBill,
		// "syncPayment": data.syncPayment,
		// "syncLedgers": data.syncLedgers,
		
		// "syncTrialBalance": data.syncTrialBalance,
		// "syncPurchase": data.syncPurchase,
		// "syncEmployee": data.syncEmployee,
		// "syncJournalEntry": data.syncJournalEntry,
		// "saveJsonInFile": data.saveJsonInFile,
		// "CompanyID":data.CompanyID
	  }
	return baseService.post('quickbooks/sync?CompanyID='+data.CompanyId,obj);

	// + data.CompanyId,
	// null,
	//  { params:{
	//  CompanyID:data.CompanyId,
	// 	 StartDate:data.StartDate,
	// 	 EndDate:data.EndDate,
	// 	 AccountingMethod:data.AccountingMethod,
	// 	SyncGeneralLedgerReview:data.syncLedgers,
	// 	syncAccounts:data.syncAccounts,
	// 	syncVendors:data.syncVendors,
	// 	syncClass:data.syncClass,
	// 	syncBill:data.syncBill,
	// 	syncInvoices:data.syncInvoices,
	// 	syncDeposits:data.syncDeposits,
	// 	syncPayment:data.syncPayment,
	// 	SyncCustomers:data.SyncCustomers,
	// 	SyncCompanyInfo:data.SyncCompanyInfo,
	// 	SaveJsonInFile:data.SaveJsonInFile,
	// 	SyncPurchase:data.SyncPurchase,
	// 	SyncJournalEntry:data.SyncJournalEntry,
	// 	SyncTrialBalance:data.SyncTrialBalance,
	// 	SyncEmployee:data.SyncEmployee
	//   }
	//  }
}
export function getTempToken(id) {
    return baseService.get('quickbooks/oauth/grant?companyId=' + id);
}
export function quickbooksLogout(id) {
    return baseService.get('quickbooks/logout?companyId=' + id);
}


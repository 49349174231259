import {ACCOUNTANT_GET} from '../actions/accountantsAction';

const initial_state = {
     accountantsData:[],
};


export default function authReducer(state = initial_state, action) {
    switch (action.type) {
        case ACCOUNTANT_GET:
            return {
               ...state,
            	accountantsData:action.payload,
                loading:false,reload:false
             };
       case 'RELOAD':
           return {...state, reload:true};
        default:
            return state;
    }
}

import {
    EMPLOYEE_SUCCESS, EMPLOYEE_ERROR,
    ADDEMPLOYEE_SUCCESS, ADDEMPLOYEE_ERROR,
    UPDATEEMPLOYEE_SUCCESS, UPDATEEMPLOYEE_ERROR,
    DELETEEMPLOYEE_SUCCESS, DELETEEMPLOYEE_ERROR,
    FLAGEMPLOYEE_SUCCESS, FLAGEMPLOYEE_ERROR,
    BULK_UPDATE,
    GET_HOUSINGALLOWNCE_HISTORY
} from '../actions/employeeAction';


const initial_state = {
    employees:[],
    HousingAllowanceHistoryData:[],
    Updated:null
};

export default function employeeReducer(state = initial_state, action) {
    switch (action.type) {
        case EMPLOYEE_SUCCESS:
            return {...state,
                employees:action.payload.data,reload:false
         };
        case EMPLOYEE_ERROR:
            return {...state};
        case ADDEMPLOYEE_SUCCESS:
            return {...state, reload:true};
        case ADDEMPLOYEE_ERROR:
            return {...state};
        case DELETEEMPLOYEE_SUCCESS:
            return {...state, reload:true};
        case DELETEEMPLOYEE_ERROR:
            return {...state};
        case UPDATEEMPLOYEE_SUCCESS:
            return {...state, reload:true};
        case UPDATEEMPLOYEE_ERROR:
            return {...state};
        case FLAGEMPLOYEE_SUCCESS:
            return {...state, reload:true};
        case FLAGEMPLOYEE_ERROR:
            return {...state};
        case 'RELOAD':
            return {...state, reload:true};
        case BULK_UPDATE:
          return { ...state, Updated: action.payload.data };
        case GET_HOUSINGALLOWNCE_HISTORY:
            return {...state,
                HousingAllowanceHistoryData:action.payload.data,reload:false
         };
        default:
            return state;
    }
}

import { LEDGER_GET,VENDOR1099COMPLIANCEREVIEW,COMMONUPDATE,REPORT_REVIEWD_HISTORY,REVIEWHISTORY,LEDGER_UPDATE,ComplianceReviewNotesUpdate,GetComplianceReviewNotesData,BATCH_LEDGER_UPDATE } from '../actions/LedgerAction';
const initial_state = {
    ComplianceReviewData:null,
    CommonUpdate:null,
    HistoryData:null,
    LEDGER_UPDATE:null,
    ComplianceReviewNotesUpdate: null,
    GetComplianceReviewNotesData: null,
    BATCH_LEDGER_UPDATE : null,
    ReportReviewedHistory:null
};

export default function authReducer(state = initial_state, action) {
    switch (action.type) {
        case LEDGER_GET:
            //action.payload.data.reload = false;
            return { ...action.payload.data };
        case VENDOR1099COMPLIANCEREVIEW:
            return { ...state,ComplianceReviewData:action.payload.data};
        case COMMONUPDATE:
        return { ...state,CommonUpdate:action.payload.data};
        case REVIEWHISTORY:
        return { ...state,HistoryData:action.payload.data};
        case 'RELOAD':
            return { ...state, reload: true };
        case ComplianceReviewNotesUpdate:
            return { ...state, ComplianceReviewNotesUpdate: action.payload.data };
        case GetComplianceReviewNotesData:
            return { ...state, GetComplianceReviewNotesData: action.payload.data };
        case LEDGER_UPDATE:
            return { ...state, LEDGER_UPDATE: action.payload.data };
        case BATCH_LEDGER_UPDATE:
            return { ...state, BATCH_LEDGER_UPDATE: action.payload.data };
        case REPORT_REVIEWD_HISTORY:
            return { ...state, ReportReviewedHistory: action.payload.data };
        default:
            return state;
    }
}

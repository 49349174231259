import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CircularProgress from '../../../../components/CircularProgress';
import { GetArchivedData } from "../../../../appRedux/actions/BalanceSheetAction";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';


import moment from 'moment';
import { toast } from 'react-toastify';


class ArchivedBalanceSheetsComponent extends Component {

    constructor(props) {

        super(props)

        this.sDateRef = React.createRef();
        this.eDateRef = React.createRef();
        let endDate = new Date();
        let fromDate = new Date(endDate.getFullYear() - 1, 0, 1);

        this.state = {
            loading: false,
            companyId: 0,
            lst_data: [],
            sDate: fromDate,
            eDate: endDate,
            PageNo: 1,
            PageSize: 500
        }
    }

    ApplyDate = () => {
        this.setState({
            loading: true,
        }, () => {
            this.GetReportData()
        });
    }

    handleFocus = (e, selecter) => {
        let control = null;
        if (selecter !== undefined) {
            if (selecter === "sDate") {
                control = this.sDateRef;
                if (control.current != null) {
                    control.current.setFocus();
                }
            }
            else if (selecter === "eDate") {
                control = this.eDateRef;
                if (control.current != null) {
                    control.current.setFocus();
                }
            }
        }
    }
    handleDateChange = (e, target) => {

        if (target === "fromDate") {

            this.setState({ sDate: new Date(e), selectedDateRange: { startDate: moment(new Date(e)).format("MM/DD/YYYY"), endDate: moment(this.state.eDate).format("MM/DD/YYYY") } })
        }
        else if (target === "uptoDate") {
            this.setState({ eDate: new Date(e), selectedDateRange: { startDate: moment(this.state.sDate).format("MM/DD/YYYY"), endDate: moment(new Date(e)).format("MM/DD/YYYY") } })
        }
    }

    BackToReport = () =>{
        this.props.history.push("trialBalanceReview");
    }

    componentWillReceiveProps(nextprops) {

        if (nextprops.ArchivedData!==undefined && this.props.ArchivedData !== nextprops.ArchivedData) {
            if (nextprops.ArchivedData.transactionStatus === 0 && nextprops.ArchivedData.returnObject != null) {
                this.setState({ loading: false, lst_data: nextprops.ArchivedData.returnObject });
            }
            else {
                this.setState({ loading: false }, () => { toast.error(nextprops.ArchivedData.resultMsg) });
            }
        }
        else { this.setState({ loading: false }); }
    }

    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('userData'));
        this.setState({ loading: true, companyId: user.companyId }, () => {
            this.GetReportData()
        });
    }

    GetReportData = () => {
        let statedata = this.state;
        let body = {
            "companyID": statedata.companyId,
            "pageNumber": statedata.PageNo,
            "pageSize": statedata.PageSize,
            "fromDate": moment(statedata.sDate).format("MM/DD/YYYY"),
            "toDate": moment(statedata.eDate).format("MM/DD/YYYY"),
        }
        this.props.GetArchivedData(body);
    }


    render() {

        return (
            <React.Fragment>
                <div className="page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
                    <h2 className="title mb-sm-0 page-title"> Balance Sheet Accounts Review</h2>
                </div>
                <div className="padding-box app-wrapper padding-b0">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="select-quater-box">
                                <p className="sub-title">Archived Balance Sheets</p>
                                <div className="row">
                                    <div className="col-sm-4 col-md-12 ">
                                        <div className="datepicker-box">
                                            <div className="date-box">
                                                <DatePicker
                                                    selected={this.state.sDate}
                                                    onChange={(e) => this.handleDateChange(e, 'fromDate')}
                                                    name="fromDate"
                                                    id="date"
                                                    required={true}
                                                    margin="normal"
                                                    disabledKeyboardNavigation
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    yearDropdownItemNumber={5}
                                                    maxDate={this.state.eDate}
                                                    ref={this.sDateRef}
                                                />
                                                <div className="date-icon" onClick={(e) => this.handleFocus(e, 'sDate')}>
                                                    <i className="zmdi zmdi-calendar-note"></i>
                                                </div>
                                            </div>
                                            <div className="to-box">
                                                <span className="text-center ledgerto-text">To</span>
                                            </div>
                                            <div className="date-box">
                                                <DatePicker
                                                    selected={this.state.eDate}
                                                    onChange={(e) => this.handleDateChange(e, 'uptoDate')}
                                                    name="uptoDate"
                                                    id="date"
                                                    required={true}
                                                    margin="normal"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    yearDropdownItemNumber={5}
                                                    disabledKeyboardNavigation
                                                    minDate={this.state.sDate}
                                                    maxDate={new Date()}
                                                    ref={this.eDateRef}
                                                />
                                                <div className="date-icon" onClick={(e) => this.handleFocus(e, 'eDate')}>
                                                    <i className="zmdi zmdi-calendar-note"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="genera-apply-btn" style={{ marginTop: "5px" }}>
                                            <Button variant="raised" className="greencolor jr-btn bg-teal text-white refresh-btn" onClick={(e) => this.ApplyDate()} >apply</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="float-right"> 
                                <Button className="greencolor jr-btn bg-teal text-white refresh-btn view-btn" onClick={(e) => this.BackToReport()} >Back</Button>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ padding: "10px" }}>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr className="header-bg-color archived_sheets">
                                        <th >Details</th>
                                        <th>Archived On</th>
                                        <th>Archived By</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.loading ? <CircularProgress></CircularProgress>
                                        :
                                        <React.Fragment>
                                            {this.state.lst_data != null && this.state.lst_data.length > 0 ?
                                                this.state.lst_data.map((item, index) => (
                                                    <tr>
                                                        <td>   
                                                            <Link style={{textDecoration:"underline"}} to={{ pathname: '/trialBalanceReview', state: { ArchivedData: item } }}>  {item.details} </Link>
                                                            </td>                                                        
                                                        <td>{item.stArchiveDate}</td>
                                                        <td>{item.userName}</td>
                                                    </tr>
                                                ))
                                                :
                                                <tr>
                                                    <td colSpan="3" className="text-center"> No Data Found </td>
                                                </tr>
                                            }
                                        </React.Fragment>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ArchivedData: state.BalanceSheetData.ArchivedData
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        GetArchivedData
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ArchivedBalanceSheetsComponent);
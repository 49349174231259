import baseService from './baseService';

export function GetFinancialCheckupIssues(data) {
    return baseService.post("/AccountantDashboard/GetClientsReadyForFinancialCheckup", data);
}

export function GetPreCheckIssues(data) {
    return baseService.post("/AccountantDashboard/GetPreCheckIssueSummary", data);
}

export function AccountantDashboard_GetReviewCommentsSummary(data) {
    return baseService.post("/AccountantDashboard/GetReviewCommentsSummary", data);
}

export function GetPayrollAdministrationSummary(data) {
    return baseService.post("/AccountantDashboard/GetPayrollAdministrationSummary", data);
}

export function Get1099AdministrationSummary(data) {
    return baseService.post("/AccountantDashboard/Get1099AdministrationSummary",data);
}

// export function CompanyTaxInformation(data) {
//     return baseService.post("/QuickbookAccount/GetCompaniesTaxInformation",data);
// }

import { GET_SYNC_DETAILS,GET_SYNC_DATA} from './../actions/SyncLogAction';
const initial_state = {
  syncdata:null,
  syncdetails:null
};

export default function authReducer(state = initial_state, action) {
    switch (action.type) {
        case GET_SYNC_DATA:
            return { ...state, syncdata: action.payload.data };
        case GET_SYNC_DETAILS:
            return { ...state, syncdetails: action.payload.data };        
        default:
            return state;
    }
}
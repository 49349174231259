import * as LeaseService from '../services/leaseService';
import  jwt_decode from 'jwt-decode';
import React from 'react';
import { toast } from "react-toastify";

export const LEASES_SUCCESS = "LEASES_SUCCESS";
export const LEASES_ERROR = "LEASES_ERROR";

export const ADDLEASE_SUCCESS = "ADDLEASE_SUCCESS";
export const ADDLEASE_ERROR = "ADDLEASE_ERROR";

export const UPDATELEASE_SUCCESS = "UPDATELEASE_SUCCESS";
export const UPDATELEASE_ERROR = "UPDATELEASE_ERROR";

export const DELETELEASE_SUCCESS = "DELETELEASE_SUCCESS";
export const DELETELEASE_ERROR = "DELETELEASE_ERROR";

export const LEASES_TYPE_SUCCESS = "LEASES_TYPE_SUCCESS";
export const LEASES_TYPE_ERROR = "LEASES_TYPE_ERROR";


function leasesSuccess (payload){
    return{
        type: LEASES_SUCCESS,
        payload
    }
}


function leasesTypesSuccess (payload){
    return{
        type: LEASES_TYPE_SUCCESS,
        payload
    }
}

function leasesError (payload){
    return{
        type: LEASES_ERROR,
        payload
    }
}
function leasesTypesError (payload){
    return{
        type: LEASES_TYPE_ERROR,
        payload
    }
}

function getCompanyId () {
    let user = JSON.parse(localStorage.getItem('userData'));
    const CompanyId = user.companyId;
    return CompanyId;
}

export const searchLease = (companyId,text) => {
  return (dispatch) => {
        LeaseService.searchLease(companyId,text)
            .then((response)=>{
              return dispatch(leasesSuccess(response))
            })
            .catch((err)=>{
              toast.error(`${err.response.data}`)
            })
    }
}

export function getLeases(companyId,search,pageNumber,pageSize,sortField,sortFormat){
    return (dispatch) => {
        // dispatch()
        LeaseService.getLeases(companyId,search,pageNumber,pageSize,sortField,sortFormat)
        .then((res) => {
            return dispatch(leasesSuccess(res))
        })
        .catch(err => {
             toast.error(`${err.response.data}`)
        })
    }
}

export function addLease(data){
    return (dispatch) => {
        LeaseService.addLease(data)
        .then(res => {
            dispatch({type:'RELOAD',payload:data});
            toast.success("Lease Added Successfully.");
        })
        .catch(err => {
             toast.error(`${err.response.data}`)
        })
    }
}

export function updateLease(data){
    return (dispatch) => {
        LeaseService.updateLease(data)
        .then(res => {
            dispatch({type:'RELOAD',payload:data});
            toast.success("Lease Updated Successfully.");
        })
        .catch(err => {
             toast.error(`${err.response.data}`)
        })
    }
}

export function deleteLease(value){
    return (dispatch) => {
        LeaseService.deleteLease(value)
        .then(res => {
            return dispatch({type:'RELOAD',payload:res});
            toast.success("Lease Deleted Successfully.");
        })
        .catch(err => {
             toast.error(`${err.response.data}`)
        })
    }
}

export function getLeaseTypes(companyId){
    return (dispatch) => {
        // dispatch()
        LeaseService.getLeaseTypes(companyId)
        .then((res) => {
            return dispatch(leasesTypesSuccess(res))
        })
        .catch(err => {
              toast.error(`${err.response.data}`)
        })
    }
}

import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ClearIcon from '@material-ui/icons/Clear';
import { GET_HISTORY_DATA } from "../../appRedux/actions/CommentsAction";
import CircularProgress from "../../components/CircularProgress";
import { toast } from 'react-toastify';


class HistoryComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            history: [],
            PreText: props.PreText,
            ModuleType: props.ModuleType ? props.ModuleType : 0,            
            RecordId: props.RecordId,
            CompanyId: props.CompanyId,
            loading: false,
        }
    }


    HandleClose = () => {
        this.props.CloseHistory();
    }

    componentDidMount() {

        this.setState({
            loading: true
        }, () => {
            this.GetCommentsData();
        });

    }

    GetCommentsData() {
        let body = {
            CompanyID: this.state.CompanyId,
            Module: this.state.ModuleType,
            RecordID: this.state.RecordId,
        }
        this.props.GET_HISTORY_DATA(body);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.HistoryData!== undefined && nextProps.HistoryData != this.props.HistoryData) {
            this.setState({ history: nextProps.HistoryData.returnObject, loading: false });
        }
    }

    render() {

        let pretext = this.state.PreText;
        return (
            <React.Fragment>
                <Dialog
                    className="comment-Top-align"
                    maxWidth="sm"
                    fullWidth={true}
                    open={true}>
                    <React.Fragment>
                        <div className="Comments modal-header"><span className="log-history-title float-left">History </span>
                            <Button className="jr-btn close-btn float-right" onClick={this.HandleClose}><ClearIcon /></Button>
                        </div>
                        <DialogContent>

                            {this.state.loading ? <CircularProgress></CircularProgress>
                                :
                                <React.Fragment>
                                    {this.state.history.length == 0 ?
                                        <React.Fragment>
                                            <div className="history-box">
                                                <p className="history-item-text">No History Found</p>
                                            </div>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            {this.state.history.map((item, i) => (
                                                <div className="history-box">
                                                    <p className="history-item-text">
                                                    {pretext== null?
                                                        item.message
                                                        :                                                        
                                                        pretext
                                                    }
                                                    {pretext}
                                                    </p>
                                                    <p className="history-date-text"> By <strong> {item.createdByName ? item.createdByName : "System"} </strong> <strong> on </strong>{item.stCreatedDate}</p>
                                                </div>
                                            ))}
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                            }
                        </DialogContent>
                    </React.Fragment>
                </Dialog>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        HistoryData: state.CommentData.HistoryData
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        GET_HISTORY_DATA
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(HistoryComponent);
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import SearchBox from '../../../../components/SearchBox';
import ReactTable from "react-table";
import "react-table/react-table.css";
import CircularProgress from "../../../../components/CircularProgress";
import EditUserForm from '../../../../Forms/EditUserForm'
import AddUserForm from '../../../../Forms/AddUserForms'
import DeleteUserForm from '../../../../Forms/DeleteUserForm'

import { getUsersAll, getUsers, getUsersByCompany, companyDetail, searchUser, updateUser, addUser, assignUserCompanies, userStatus } from "../../../../appRedux/actions/companiesAction";
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from '@material-ui/core/TextField';
import RSelect from 'react-select';
import { Table, Pagination } from 'antd';
import Switch from '@material-ui/core/Switch';
import "../../styles.css";
import "./style.css";

class Users extends Component {

  constructor(props) {
    super(props);
    let user = JSON.parse(localStorage.getItem('userData'));
    let role = user.role;

    let column = [
      {
        title: 'First Name',
        dataIndex: 'firstName',
        className: 'table-heading',
        sorter: true,
        width: 200
      },
      {
        title: 'Last Name',
        dataIndex: 'lastName',
        className: 'table-heading',
        sorter: true,
        width: 200
      },
      // {
      //   title: 'User Name',
      //   dataIndex: 'userName',
      //   className: 'table-heading',
      //   sorter: true,
      //   width: 250
      // },
      {
        title: 'Email',
        dataIndex: 'email',
        className: 'table-heading',
        sorter: true,
        width: 450
      }, {
        title: 'Role',
        dataIndex: 'role',
        className: 'table-heading',
        sorter: true,
        width: 100
      }, {
        title: 'Status',
        dataIndex: 'isActive',
        className: 'table-heading',
        sorter: true,
        width: 130,
        render: (text, record, index) => <span>
          {
            (record.role!='Contact' && !record.emailconfirmed)? 'Pending' :< div>
                    <Switch size="small" className={text ? 'switch-box' : 'switch-box switch-inactive'} checked={text} onChange={(event) => this.userStatusChange(event, record, index)} /> <span className={text ? 'text-success' : ''}>{text ? "Active" : "Inactive"}</span>
          </div>
          }          
        </span>,
      }

    ];


    this.state = {
      pageSize: 50,
      currentPage: 1,
      totalRowsInTable: 0,
      fieldName: 'id',
      sortFormat: 'A',
      searchText: '',
      search: false,
      isOpen: false,
      isEdit: false,
      isAdd: false,
      isDel: false,
      isAddOpen: false,
      loading: true,
      columns: column,
      companiesData: [],
      data: '',
      usersData: [],
      CompanyID: '',
      selectedUserId: 0,
      isEditOpen: false,
      selectedUser: '',
      userId: '',
      companyId: '',
      firstName: '',
      lastName: '',
      userName: '',
      email: '',
      role: '',
      index: -1,
      selectedCompanies: []
    }
    this.deleteUser = this.deleteUser.bind(this);

  }

  handleSave(record) {


    if (this.state.selectedCompanies.length > 0) {
      let comps = this.state.selectedCompanies;
      let comps1 = [];

      let coms = comps.map((val, i) => {

        let neData;
        if (i == 0) {
          neData = {
            "CompanyId": val.value,
            "IsDefaultCompany": true
          }
        } else {
          neData = {
            "CompanyId": val.value,
          }
        }

        comps1.push(neData);
        return comps1;
      });

      this.props.assignUserCompanies(comps1, this.state.userId);

    }

    let data = {
      userId: this.state.userId,
      companyId: this.state.companyId,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      userName: this.state.userName,
      email: this.state.email,
      role: this.state.role,
    };

    this.setState({ index: -1, loading: true });
    this.props.addUser(data);

  }

  userStatusChange = (event, record, index) => {
    event.stopPropagation();
    let isChecked = event.target.checked;
    var userData = [...this.state.usersData];
    userData[index].isActive = isChecked;
    this.setState({ userData: userData, loading: true }, () => {
      let data = {
        "id": record.id,
        "status": isChecked
      };
      this.props.userStatus(data);
    });
  }

  handleCompChange = (selectedCompanies) => {

    this.setState({ selectedCompanies, err: false });
  }
  handleFormChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }
  componentDidMount() {
    let id = this.props.match.params.id;
    this.setState({ companyId: id, loading: true })
    this.listData();
    this.props.companyDetail();
  }
  listData() {
    let id = this.props.match.params.id;
    this.props.getUsersAll(id, this.state.searchText, this.state.currentPage, this.state.pageSize, this.state.fieldName, this.state.sortFormat);
  }

  componentWillReceiveProps(nextProps) {
  
    this.setState({ isOpen: nextProps.isOpen, isAddOpen: nextProps.isAddOpen, isEditOpen: nextProps.isEditOpen })
    if (nextProps.users!==undefined && nextProps.users !== this.props.users) {
      if (nextProps.users.length > 0)
        this.state.totalRowsInTable = nextProps.users[0].totalrows;
      this.setState({ usersData: nextProps.users, loading: false });
    }
    if (nextProps.company!==undefined && nextProps.company !== this.props.company) {

      let options = nextProps.company.filter(data1 => {
        return data1.companyId !== 1 && data1.isActive == true;
      }).map(data => {
        return {
          value: data.companyId,
          label: data.name
        }
      });

      this.setState({ loading: false, companiesData: options });
    }

    if (nextProps.reload == true) {
      this.setState({ loading: false }, () => {
        this.handleCancel();
        this.listData();
      });
    }


  }

  handleDelete = () => {
    // var id = record.id
    this.setState({
      isDel: true,
      edit: false,
      isEdit: false,
      CompanyID: this.state.CompanyID,
      selectedUserId: this.state.selectedItem.id
    });
  }

  handleCancel = () => {

    this.setState({ isDel: false, isEdit: false, isAdd: false });
  };

  handleAddModal = () => {
    this.setState({ isEdit: false, isAdd: true, CompanyID: this.state.CompanyID, selectedItem: false, });
  }
  handleEditModal = (record) => {
    var id = record.id;    
    var data = this.state.usersData;
    if (id != 0) {
      const selectedItem = _.find(data, { id: id });
      this.setState({
        selectedItem: _.cloneDeep(selectedItem),
        isAdd: false,
        isEdit: true,
      });
    }

  }


  handleChange = (text) => {
    var self = this;
    if (text) {

      self.state.searchText = text;


    } else {
      self.state.searchText = '';

    }
    self.state.currentPage = 1;
    this.setState({ search: true });

    clearTimeout(self.state.timeout);

    // Make a new timeout set to go off in 800ms
    self.state.timeout = setTimeout(function () {
      self.listData();

    }, 500);

  }

  handleDelSave = () => {
    this.setState({ isDel: false, isEdit: false });
  }
  handleDelCancel = () => { this.setState({ isDel: false, isEdit: false }) }

  handleSaveUser = (state) => { this.setState({ isAdd: state }) }


  deleteUser() {
    this.setState({ isDel: true, isEdit: false });
  }

  handleTableChange = (pagination, filters, sorter) => {
    if (sorter.order) {
      this.state.fieldName = sorter.field;
      if (sorter.order == "ascend")
        this.state.sortFormat = "A";
      else
        this.state.sortFormat = "D";
    }
    else {
      this.state.fieldName = "id";
      this.state.sortFormat = "A";
    }
    this.listData();
  }

  onShowSizeChange(current, pageSize) {
    this.state.currentPage = current;
    this.state.pageSize = pageSize;
    this.listData();    
  }
  render() {
    let { columns, searchText, usersData, search, selectedItem } = this.state;
    let filteredData = usersData.length && usersData.map((d, index) => ({
      key: index,
      ...d
    }))
    
    return (
      <div className="" style={{ padding: 15 }}>
        <AddUserForm
          handleSave={this.handleSave}
          visible={this.state.isAdd || this.state.isEdit}
          handleCancel={this.handleCancel}
          companyId={this.state.companyId}
          companiesData={this.state.companiesData}
          selectedItem={selectedItem}
          handleDelete={this.handleDelete}
          isEdit={this.state.isEdit}
        />
        <DeleteUserForm
          handleSave={this.handleDelSave}
          visible={this.state.isDel}
          handleCancel={this.handleDelCancel}
          handleDelete={this.handleDelete}
          companyId={this.state.companyId}
          userId={this.state.selectedUserId} />
        <Grid
          container
          spacing={24}
          style={{ 'marginBottom': 10 }}
        >
          <Grid item xs={12} sm={6} >
            <h2 className="user-title">{this.state.companyId == 0 ? 'Users' : 'Contacts'}</h2>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid
              container
              spacing={8}
              direction="row"
              justify="flex-end"
              alignItems="flex-end"
            >
              <Grid item  >
                <SearchBox
                  styleName="d-none d-lg-block"
                  placeholder="Search... "
                  value={searchText}
                  onChange={(e) => { this.handleChange(e.target.value) }}
                  style={{ background: '#eeeeeeb8' }}
                />
              </Grid>
              <Grid item >
                <Button variant="contained" color="primary" onClick={this.handleAddModal}>
                  {this.state.companyId == 0 ? 'Add User' : 'Add Contact'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {this.state.loading
          ?
          <CircularProgress />
          :
          <div><Table
            className="table-layout"
            columns={this.state.columns}
            dataSource={filteredData}
            rowClassName={(record) => this.state.selectedItemKey == record.key ? 'data-row selected' : 'data-row'}
            bordered={true}
            size={"small"}
            onChange={this.handleTableChange}
            loading={this.state.loading}
            pagination={false}
            //onRowClick={(record, index, event) => this.handleEditModal(record)}
            onRowDoubleClick={(record, index, event) => this.handleEditModal(record)}
            scroll={{ y: window.innerHeight - 301 }}
          />
            <Pagination
              pageSizeOptions={['10', '25', '50', '100']}
              pageSize={this.state.pageSize}
              style={{ marginTop: '10px', marginBottom: '10px', float: 'right' }}
              showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
              showSizeChanger onChange={(a, b) => this.onShowSizeChange(a, b)} onShowSizeChange={(a, b) => this.onShowSizeChange(a, b)} defaultCurrent={1} total={this.state.totalRowsInTable} />
          </div>
        }
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    users: state.companyReducer.users,
    companyId: state.companyReducer.companyId,
    companyName: state.companyReducer.companyName,
    company: state.companyReducer.companies,
    reload: state.companyReducer.reload,
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getUsers,
    userStatus,
    updateUser,
    getUsersByCompany,
    addUser,
    companyDetail,
    searchUser,
    assignUserCompanies,
    getUsersAll
  }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Users);

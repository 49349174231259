import React from 'react';
import {ConnectedRouter} from 'react-router-redux';
import {Provider} from 'react-redux';
import {Route, Switch} from 'react-router-dom';
import configureStore, {history} from './appRedux/store';
import {LOGIN_SUCCESS} from './appRedux/reducers/Auth';
//import './firebase/firebase';
import App from './containers/App';
import jwt_decode from 'jwt-decode';
export const store = configureStore();




if(localStorage.getItem('userData')){
	const user=JSON.parse(localStorage.getItem('userData'));
	store.dispatch({type: LOGIN_SUCCESS, payload:user});
}


const MainApp = () =>
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <Switch>
                <Route path="/" component={App}/>
            </Switch>
        </ConnectedRouter>
    </Provider>;


export default MainApp;
import React, { Component } from 'react';
import {withRouter } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import {
  companyDetail,
  showMenu,
  refreshToken
} from '../../appRedux/actions/companiesAction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import RSelect from 'react-select';
import history from '../../browserHistory';
import queryString from 'query-string';

class CompanySelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      companiesData: [],
      role: '',
      companyName: '',
      companyId: '',
      show: false,
      selectedCompany: ''

    }
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { history } = this.props;

    let user = JSON.parse(localStorage.getItem('userData'));
    this.setState({
      role: user.role,
    });
    if (user.role !== "User") {
      this.props.companyDetail();
    }
   
    let decode = jwt_decode(user.Authorization);
    let companyMain = decode.CompanyId;
    // if not on acc dashboard and deselect company
    if(window.location.href.replace(window.location.origin).toLowerCase().indexOf("/home")>-1 && (companyMain!= user.companyId))
    {

      history.push("/dashboard");
    }
    
    if((companyMain== user.companyId) &&window.location.href.replace(window.location.origin).toLowerCase().indexOf("/home")==-1 && (this.props.location.search==null || this.props.location.search==""))
    {
      history.push("/home");
    }

    if (user.showMenu == true) {
      this.setState({
        show: true, companyId: user.companyId, selectedCompany: {
          value: user.companyId,
          label: user.companyName
        }
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.company !== this.props.company) {
      
      if(this.props.location.search!= null && this.props.location.search!="")
      {
        let value = queryString.parse(this.props.location.search);
        if(value!= null && value.cid!=undefined)
        {
          const selectedcompany = _.find(nextProps.company, function (o) { return o.companyId == parseInt(value.cid) ; }); 
          let  reportCompany= {
            value: selectedcompany.companyId,
            label: selectedcompany.name
          };  
          
          this.setState({ companiesData: nextProps.company },() =>{
            this.handleChange(reportCompany,true);
          });
        }
      }
      else
      {
        this.setState({ companiesData: nextProps.company });
      }
      
    }
  }

  handleChange(selectedCompany,isEvent=false) {
    const { history } = this.props;
    let user = JSON.parse(localStorage.getItem('userData'));
    let decode = jwt_decode(user.Authorization);
    let companyMain = decode.CompanyId;
      
    let role = decode['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];        
    if (selectedCompany.value == 0) {

      let user = JSON.parse(localStorage.getItem('userData'));
      let newUser = { ...user, companyId: 1, companyName: '', showMenu: false }

      this.setState({ show: false, companyId: '', selectedCompany: '' });
      localStorage.setItem('userData', JSON.stringify(newUser));

      history.push('/home');

    } else {
            
      this.setState({ companyId: selectedCompany.value, selectedCompany, show: true });
      let data = this.props.company;
      let id = selectedCompany.value;

      const selectedcompany = _.find(data, function (o) { return o.companyId == id; });

      let user = JSON.parse(localStorage.getItem('userData'));
      
      let newUser = { ...user, companyId: id, companyName: selectedcompany.name, showMenu: true }

      //this.props.refreshToken(id);
      localStorage.setItem('userData', JSON.stringify(newUser));
      if(!isEvent || typeof(isEvent)=='object')
      {
        history.push('/dashboard');
      }     
    }
  }

  render() {
    
    let user = JSON.parse(localStorage.getItem('userData'));
    let decode = jwt_decode(user.Authorization);
    let companiesMenu;
    let companyMain = decode.CompanyId;
    let { companiesData, selectedCompany, name, companyId, role, show } = this.state;


    // let options = companiesData.filter(data1 => {
    //  return data1.companyId !== 1;
    // }).map(data => {
    //    return {
    //      value:data.companyId,
    //      label:data.name
    //    }
    // });
    let options = companiesData.filter(x => { return x.isActive == true }).map(data => {
      return {
        value: data.companyId,
        label: data.name
      }
    });

    if (show == true) {
      let master = {
        value: 0,
        label: "Accountant's Dashboard"
      }

      options = [master, ...options]
    }

    return (
      role == 'SuperAdmin' || role == 'Admin' || role == 'Accountant' ?
        <div className="comp" style={{ minWidth: 200, color: '#000' }}>
          <RSelect
            value={selectedCompany}
            onChange={this.handleChange}
            options={options}
            // isClearable={true}
            placeholder="Select Company..."
          />

        </div> : null
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.companyReducer.companies,
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    companyDetail,
    showMenu,
    refreshToken
  }, dispatch)
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompanySelector));
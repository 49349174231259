import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const FontTooltip = withStyles((theme) => ({
    tooltip: {
        fontSize: 12
    },
}))(Tooltip);

class QuaterDatePickerModel extends Component {

    constructor(props) {
        super(props);
        
        this.searchSDate = React.createRef();
        this.searchEDate = React.createRef();
        this.state = {
            quater: this.props.options,
            sDate: this.props.sDate,
            eDate: this.props.eDate            
        }
    }

    handleDateFocus = (e,selecter) => {
        let control = null;
        if(selecter!== undefined)
        {
            if(selecter==="sDate")
            {
                control= this.searchSDate;                
                if(control.current!= null)
                {
                    control.current.setFocus();
                }                
            }
            else if(selecter==="eDate") 
            {
                control = this.searchEDate;
                if(control.current!= null)
                {
                    control.current.setFocus();
                }                
            }
        } 
    }

    handleApply = (event, picker) => {        
        // if (picker) {
            var range = { "startdate": this.state.sDate, "enddate": this.state.eDate };
            this.props.onDatepickerCancel(range);
        // }

    }
    onSelectOptions = (item) => {
        this.props.onDatepickerCancel(item);
    }

    handleCancel = () => {
        this.props.onDatepickerCancel();
    };

    handleDateChange = (e, target) => {
        
        if (target === "fromDate") {
            
            this.setState({ sDate: new Date(e) })
        }
        else if (target === "uptoDate") {
            
            this.setState({ eDate: new Date(e) })
        }
    }

    render() {
        return (
            <React.Fragment>
                <Dialog className="custome-modal-box"
                    maxWidth="sm"
                    fullWidth={true}
                    open={true}
                    onClose={this.handleCancel}
                >
                    <DialogTitle>Year To Date:</DialogTitle>
                    <DialogContent>
                        <div className="ml-6 row">
                        <div className="row">
                                    <div className="col-sm-4 col-md-12 "> 
                                    <div className="datepicker-box">
                                    <div className="date-box">                                    
                                        <DatePicker
                                            selected={this.state.sDate}
                                            onChange={(e) => this.handleDateChange(e, 'fromDate')}
                                            name="fromDate"
                                            id="date"
                                            required={true}
                                            margin="normal"
                                            maxDate={this.state.eDate}
                                            ref={this.searchSDate}
                                        />
                                        <div className="date-icon" onClick={(e) => this.handleDateFocus(e,'sDate')}>
                                        <i className="zmdi zmdi-calendar-note"></i>
                                        </div>
                                        </div>   
                                        <div className="to-box">
                                        <span className="text-center ledgerto-text">To</span> 
                                        </div>
                                        <div className="date-box">                                                                  
                                        <DatePicker
                                            selected={this.state.eDate}
                                            onChange={(e) => this.handleDateChange(e, 'uptoDate')}
                                            name="uptoDate"
                                            id="date"
                                            required={true}
                                            margin="normal"
                                            minDate={this.state.sDate}
                                            maxDate={new Date()}
                                            ref={this.searchEDate}
                                        />
                                         <div className="date-icon" onClick={(e) => this.handleDateFocus(e,'eDate')}>
                                        <i className="zmdi zmdi-calendar-note"></i>
                                        </div>
                                    </div>
                                    </div>
                                    
                                    </div>      
                                    <div className="col-md-2">
                                       
                                        </div>                              
                                    <div className="col-md-5 pl-0">  
                                       
                                  
                                    </div> 
                                </div>
                            {/* {this.state.quater && this.state.quater.month.map((item, i) => (
                                <FontTooltip id="tooltip-icon" title={`${moment(item.startdate).format("MM/DD/YYYY")} - ${moment(item.enddate).format("MM/DD/YYYY")}`} placement="top-start">
                                    <Button variant="raised" className="jr-btn bg-deep-purple text-white"
                                        onClick={() => this.onSelectOptions(item)}>{item.monthStr}</Button>
                                </FontTooltip>
                            ))}
                            <FontTooltip id="tooltip-icon" title="custom" placement="top-start">
                                <DateRangePicker minDate={this.state.quater.quarterStartDate} maxDate={this.state.quater.quarterEndDate} 
                                startDate={this.state.quater.quarterStartDate} endDate={this.state.quater.quarterEndDate} onApply={this.handleApply} >
                                    <Button variant="raised" className="jr-btn bg-deep-purple text-white">Custom</Button>
                                </DateRangePicker>
                            </FontTooltip> */}
                        </div>
                    </DialogContent>
                    <DialogActions>
                    <Button variant="raised" className="jr-btn bg-teal text-white refresh-btn" onClick={this.handleApply}>Apply</Button>
                    
                        <Button variant="raised" className="jr-btn bg-grey refresh-btn text-white" onClick={this.handleCancel}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment >
        );
    }
}

export default QuaterDatePickerModel;
import React, {Component} from "react";
import IntlMessages from "../../../../util/IntlMessages";
import {Vendors,searchVendor,vendorTypes, entitytypes,GetVendors} from '../../../../appRedux/actions/vendorAction';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import _ from 'lodash';
import ReactTable from "react-table";
import "react-table/react-table.css";
import CircularProgress from "../../../../components/CircularProgress";
import VendorTypeForm from '../../../../Forms/VendorTypeForm.js';
import SearchBox from '../../../../components/SearchBox';
import Button from '@material-ui/core/Button';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Table, Pagination  } from 'antd';

import "../../styles.css";
let compareByAlph = (a, b) => {
    if (a > b) { return -1; }
    if (a < b) { return 1; }
    return 0;
}
class VendorTypePage extends Component {
    constructor(props) {
        super(props);
        let column = [{
            title: 'Vendor Type',
            dataIndex: 'name',
            className:'table-heading',
            sorter:  false,
            width:200,
            sorter:  (a, b) => compareByAlph(a.name,b.name),

        }


        ];

        this.state = {
            pageSize:50,
            currentPage:1,
            totalRowsInTable:0,
            fieldName:'vendorTypeId',
            sortFormat:'A',
            columns:column,
            loading:true,
            searchText:'',
            search:false,
            selectedVendorType:'',
            isOpen:false,
            isAddOpen:false,
            isEdit:false,
            companyId:'',
            vendorTypeData:[],
        }
    }
    componentDidMount() {
        this.listData();
        
        
    }
    listData()
    {
      this.props.vendorTypes();
    }
    componentWillReceiveProps(nextProps){
         if(nextProps.vendorTypeData!==undefined && nextProps.vendorTypeData !== this.props.vendorTypeData){
          this.setState({loading:false, vendorTypeData:nextProps.vendorTypeData });
        }
    }
    handleCancel = () => {
        this.setState({isOpen:false,isEdit:false,isAddOpen:false});
    };

    handleAddModal = () => {
        this.setState({isAddOpen:true,isEdit:false});
        }

    handleEditModal= (record) =>{
        var id=record.key;
        var data=this.state.vendorTypeData;        
        if(id!=0)
         {
            const selectedVendorType = _.find( data, {vendorTypeId:id});
            this.setState({
                selectedVendorType:selectedVendorType,
                isEdit:true,
            });
        }
 
    }

    
    handleSave = (state) => {
        this.setState({
            isOpen:state,
            isEdit:state,
            isAddOpen:false
        });
    }
                              

    render() {

       let { searchText, vendorTypeData,columns } = this.state;
       var vendors = vendorTypeData;
		if(searchText)
          {
         vendors = vendorTypeData.length && vendorTypeData.filter(obj=> {
          if(obj.name !==null ){
            if(obj.name.toLowerCase().includes(searchText.toLowerCase()) ===  true
                ) {
               return obj;
            }
          }
        })

        }
        let filteredData = vendors.length && vendors.map((d,index)=>({
            key:d.vendorTypeId,
            ...d
        }))
        return (
            <div className="vendorsPage" style={{padding:15}}>
             <Grid
               container
               spacing={24}
               style={{ 'marginBottom': 10 }}
             >
                <Grid item xs={12} sm={6}>
                   <h3 className="user-title">Vendor Types</h3>
                </Grid>
                 <Grid item xs={12} sm={6}>
                <Grid
                 container
                 spacing={8}
                 direction="row"
                 justify="flex-end"
                 alignItems="flex-end"
                >
                <Grid item  >
                <SearchBox
                   styleName="d-none d-lg-block"
                   placeholder="Search... "
                   value={searchText}
                   onChange= { (e) => {  this.setState({searchText:e.target.value}) }}
                   style={{ background: '#eeeeeeb8'}}
                 />
                 </Grid>
                  <Grid item  >
                 <Button variant="contained" color="primary" onClick={this.handleAddModal} >
                      Add Vendor Type
                     </Button>
                   </Grid>
            </Grid>
          </Grid>
             </Grid>
              {this.state.loading == false ?
               <VendorTypeForm
               isEdit={this.state.isEdit}
               handleSave={this.handleSave}
               visible={this.state.isEdit || this.state.isAddOpen}
               handleCancel={this.handleCancel}
               selectedItem={this.state.selectedVendorType}
               />  : ''}
             {this.state.loading
              ?
               <CircularProgress/>
              :<div>
              <Table
                  className="table-layout"
                  columns={columns}
                  dataSource={filteredData}
                  rowClassName={ (record) =>  this.state.selectedItemKey == record.key  ? 'data-row selected' : 'data-row'  }
                  bordered={true}
                  size={"small"}
                  onRowClick={ (record, index, event) => this.handleEditModal(record) }
                  onRowDoubleClick={ (record, index, event) => this.handleEditModal(record) }
                  onChange={this.handleTableChange}
                  loading={this.state.loading}

                  pagination={false}
                  scroll={{y:window.innerHeight-301}}
                  />
                  
                  </div>
            }
            </div>
        );
    }
}

const mapStateToProps=(state)=>{
    return {
        vendorTypeData:state.vendors.vendorTypes
    }
}
const mapDispatchToProps=(dispatch)=>{
    return bindActionCreators({
        vendorTypes
    },dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(VendorTypePage);

import baseService from './baseService';

export function getLoansList(companyId){
    return baseService.get(`/Loan/all`,{ params:{ companyId }});
}

export function getLoans(CompanyID,search,pageNumber,pageSize,sortField,sortFormat){
    return baseService.post(`/Loan/all_page`,{ CompanyID,search,pageNumber,pageSize,sortField,sortFormat });
}

export function addLoans(data){
    return baseService.post(`/Loan`, data);
}

export function updateLoans(data){
    return baseService.put(`/Loan`, data);
}

export function deleteLoans(value){
    return baseService.delete(`/Loan`, {params:{loanId:value}});
}

export function getPropertyTypes(value){
    return baseService.get(`/Loan/propertytypes`);
}

export function searchLoans(companyId,text)
{
    return baseService.get(`Loan/${companyId}/search/${text}`);
}

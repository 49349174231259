import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import IntlMessages from "../../../../util/IntlMessages";
import ReactTable from "react-table";
import "react-table/react-table.css";
import _ from 'lodash';
import {
    companyDetail,
    getUsers,
    insertCompanies,
    deleteCompany,
    searchCompany,
    getStates,
    accountingsoftware,
    payrollsoftware,
    companyStatus
} from '../../../../appRedux/actions/companiesAction';
import {
    AccountTypes,
    CompanyAccountTypes,
    CompanyTransactionTypes,
    TransactionTypes
} from '../../../../appRedux/actions/accountAction';
import CircularProgress from "../../../../components/CircularProgress";
import browserHistory from '../../../../browserHistory';
import SearchBox from '../../../../components/SearchBox';
import AddCompanyForm from '../../../../Forms/AddCompanyForm';
import EditCompanyForm from '../../../../Forms/EditCompanyForm';
import DeleteCompanyForm from '../../../../Forms/DeleteCompanyForm';
import CompanyAccountsForm from '../../../../Forms/CompanyAccountsForm';
import EditCompanyAccountsForm from '../../../../Forms/EditCompanyAccountsForm';
import EditCompanyWagesSettingForm from "../../../../Forms/EditCompanyWagesSetting";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Table, EditOutlined } from 'antd';
//import { Table, Switch, EditOutlined } from 'antd';
import Switch from '@material-ui/core/Switch';
import Badge from '@material-ui/core/Badge';
//import 'antd/dist/antd.css';
import { FaPencilAlt } from 'react-icons/fa';
import "./style.css";

let compareByAlph = (a, b) => {
    if (a > b) { return -1; }
    if (a < b) { return 1; }
    return 0;
}
class CompanyPage extends Component {

    constructor(props) {
        super(props);
        let column = [{
            title: 'Company Name',
            dataIndex: 'name',
            className: 'table-heading',
            sorter: (a, b) => compareByAlph(a.name, b.name),
            width: 150
        },
        // {
        //     title: 'Contacts',
        //     dataIndex: 'customContactsCount',
        //     className: 'table-heading text-center',
        //     sorter: (a, b) => compareByAlph(a.contacts, b.contacts),
        //     width: 100,
        //     render: (text, record) => <span>
        //         <Link to={`/users/${record.companyId}`}>
        //             <span style={{ 'color': 'blue', 'textDecoration': 'underline' }}>{text}</span>
        //             {/* <Badge badgeContent={text} color="primary" showZero>
        //             </Badge> */}
        //         </Link>
        //     </span>
        // },
        // {
        //     title: 'Accountants',
        //     dataIndex: 'accountants',
        //     className: 'table-heading',
        //     sorter: (a, b) => compareByAlph(a.accountants, b.accountants),
        //     width: 450
        // },
            // {
            //     title: 'Address',
            //     dataIndex: 'address',
            //     className:'table-heading',
            //     sorter:  (a, b) => compareByAlph(a.address,b.address),
            //     width:150
            // },{
            //     title: 'City',
            //     dataIndex: 'city',
            //     className:'table-heading',
            //     sorter:  true,
            //     sorter:  (a, b) => compareByAlph(a.city,b.city),
            //     width:100
            // },{
            //     title: 'State',
            //     dataIndex: 'state',
            //     className:'table-heading',
            //     sorter:  true,
            //     sorter:  (a, b) => compareByAlph(a.state,b.state),
            //     width:100
            // },{
            //     title: 'Zip Code',
            //     dataIndex: 'postalCode',
            //     className:'table-heading',
            //     sorter:  true,
            //     sorter:  (a, b) => compareByAlph(a.postalCode,b.postalCode),
            //     width:120,
            // },
            {
                title: 'Payroll software',
                dataIndex: 'payrollSoftware',
                className:'table-heading',
                sorter:  (a, b) => compareByAlph(a.payrollSoftware,b.payrollSoftware),
                sorter:  true,
            },
            {
                title: 'Accounting Team Basecamp Email',
                dataIndex: 'mpwAccountantEmail',
                className:'table-heading',
                sorter:  (a, b) => compareByAlph(a.mpwAccountantEmail,b.mpwAccountantEmail),
                sorter:  true,
            },
            {
                title: 'Salaries & Wages Account',
                dataIndex: 'salariesWagesAccountsName',
                className:'table-heading',
                sorter:  (a, b) => compareByAlph(a.salariesWagesAccountsName,b.salariesWagesAccountsName),
                sorter:  true,
                width:140,
            }, 
             {
                title: 'Housing Allowance Account',
                dataIndex: 'housingAllowanceAccountsName',
                className:'table-heading',
                sorter:  (a, b) => compareByAlph(a.housingAllowanceAccountsName,b.housingAllowanceAccountsName),
                sorter:  true,
                width:140,
            },
            {
                title: 'Infusionsoft Contact ID',
                dataIndex: 'infusionsoftContactId',
                className:'table-heading',
                sorter:  (a, b) => compareByAlph(a.infusionsoftContactId,b.infusionsoftContactId),
                sorter:  true,
                width:140,
            }, 
            {
                title: 'Status',
                dataIndex: 'isActive',
                className: 'table-heading',
                sorter: true,
                width: 100,
                render: (text, record, index) => <span>
                    <div>
                        <Switch className={text ? 'switch-box' : 'switch-box switch-inactive'} size="small" checked={text} onChange={(e) => this.companyStatusChange(e, record, index)} /> <span className={text ? 'text-success' : ''}>{text ? "Active" : "Inactive"}</span>
                        {/* <Switch size="small" className={text ? 'bg-success' : ''} checked={text} onChange={(e) => this.companyStatusChange(e, record, index)} /> <span className={text ? 'text-success' : ''}>{text ? "Active" : "InActive"}</span> */}
                    </div>
                </span>,
           }
        //    ,{
        //          title: 'Actions',
        //          dataIndex: '',
        //          className: 'table-heading',
        //          sorter: false,
        //          width: 80,
        //          render: (text, record) => <span>
        //              {/* <EditFilled /> */} <i className="zmdi zmdi-edit zmdi-hc-lg mr-4 pointer" onClick={() => this.handleEditModel(record)}></i>
        //              <i className="zmdi zmdi-delete zmdi-hc-lg pointer" onClick={() => this.handleDelModel(record)}></i>
        //              {/* <Button
        //                  className=""
        //                  color="primary"
        //                  onClick={() => this.handleEditModel(record)}>
        //                  Edit
        //            </Button>
        //              <Button
        //                  className=""
        //                  color="secondary"
        //                  onClick={() => this.handleDelModel(record)}>
        //                  Delete
        //            </Button> */}
        //          </span>,
        //    }
        ];


        this.state = {
            isEdit: false,
            isDel: false,
            isAdd: false,
            isAcc: false,
            isSalWag: false,
            isEditAcc: false,
            columns: column,
            companiesData: [],
            states: [],
            CompanyId: '',
            isUservisibile: false,
            cancelAdd: false,
            users: null,
            loading: true,
            searchText: '',
            search: false,
            selectedCompanyId: 0,
            selectedCompany: '',
            accountingSoft: [],
            payrollSoft: [],
            accountTypes: [],
            transctionTypes: [],
            companyAccountTypes: [],
            companyTransactionTypes: [],
            fstep: '',
            getAcc: true,
            AccNext: ''
        }

        this.handleEditModel = this.handleEditModel.bind(this);
    }

    componentDidMount() {
        this.props.getStates();
        this.props.companyDetail();
        this.props.accountingsoftware();
        this.props.payrollsoftware();
        this.props.AccountTypes();
        this.props.TransactionTypes();
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.companies!==undefined && nextProps.companies !== this.props.companies) {
            this.setState({ companiesData: nextProps.companies });
        }
        else if (nextProps.accountingSoft!==undefined && nextProps.accountingSoft !== this.props.accountingSoft) {
            this.setState({ accountingSoft: nextProps.accountingSoft });
        }
        else if (nextProps.payrollSoft!==undefined && nextProps.payrollSoft !== this.props.payrollSoft) {
            this.setState({ payrollSoft: nextProps.payrollSoft });
        }
        else if (nextProps.accountTypes!==undefined && nextProps.accountTypes !== this.props.accountTypes) {
            this.setState({ accountTypes: nextProps.accountTypes.data });
        }
        else if (nextProps.tranactionTypes!==undefined && nextProps.tranactionTypes !== this.props.tranactionTypes) {
            this.setState({ transctionTypes: nextProps.tranactionTypes.data });
        }
        else if (nextProps.states!==undefined && nextProps.states !== this.props.states) {
            this.setState({ loading: false, states: nextProps.states });
        } else if (nextProps.companyAccountTypes !==undefined && nextProps.companyAccountTypes !== this.props.companyAccountTypes) {

            this.setState({ companyAccountTypes: nextProps.companyAccountTypes.data });

        } else if (nextProps.companyTransactionTypes!==undefined && nextProps.companyTransactionTypes !== this.props.companyTransactionTypes) {

            this.setState({ isEdit: true, getAcc: false, companyTransactionTypes: nextProps.companyTransactionTypes.data });
        }
        if (nextProps.reload) {
            this.setState({ loading: false }, () => {
                this.props.companyDetail();
            });
        };

    }

    companyStatusChange = (event, record, index) => {
        let isChecked = event.target.checked;
        var companydata = [...this.state.companiesData];
        companydata[index].isActive = isChecked;
        this.setState({ companiesData: companydata, loading: true }, () => {
            let data = {
                "companyId": record.companyId,
                "status": isChecked
            };
            this.props.companyStatus(data);
        })
    }

    handleCancel = () => {
        this.setState({ isDelOpen: false, isOpen: false, iseditopen: false, isSalWag: false });
    }


    handleChange = (text) => {
        if (text) {
            this.props.searchCompany(text);
            this.setState({ searchText: text.trim() })
        } else {
            this.props.companyDetail();
            this.setState({ searchText: '' })
        }
    }


    selectedCompanyUsers(id) {

        this.props.history.push('/users/' + id);
    }

    handleSave = (state) => {
        this.setState({
            isOpen: state.isOpen,
            iseditopen: state.iseditopen
        });
    }

    handleAddModal = () => {
        this.setState({
            isAdd: true,
            isDel: false,
            isEdit: false
        });
    }
    handleEditModel = (record) => {

        var id = record.companyId;

        this.props.CompanyAccountTypes(id);
        this.props.CompanyTransactionTypes(id);

        var data = this.props.companies;
        if (id != 0) {
            const selectedcompany = _.find(data, { companyId: id });
            this.setState({
                CompanyId: id,
                selectedCompany: selectedcompany,
            });
        }
        if (this.state.getAcc === false) {
            this.setState({
                isEdit: true, iAdd: false, isDel: false
            });
        }
    }

    handleDelete = () => {

        this.setState({ isEdit: false, isEditAcc: false, isDel: true, isAdd: false });
    }

    handleAddCancel = () => {
        this.setState({ cancelAdd: false, isAdd: false });
    }
    handleEditCancel = () => {
        this.setState({ isEdit: false, isEditAcc: false });
    }
    handleDelCancel = () => {
        this.setState({ isDel: false, isEditAcc: false, isEdit: false });
    }

    handleAddSave = (state) => {
        this.setState({
            isAdd: state,
            isAcc: state
        });
    }
    handleEditSave = (state) => {
        this.setState({
            isEditAcc: state
        });
    }

    handleDelModel = (record) => {
        var id = record.companyId;
        var data = this.props.companies;
        if (id != 0) {
            const selectedcompany = _.find(data, { companyId: id });
            this.setState({
                isDel: true, iAdd: false,
                CompanyId: id,
                selectedCompany: selectedcompany,
            });
        }
    }

    handleDelSave = (state) => {
        this.setState({
            isDel: state
        });
    }

    ShowWages = () => {
        this.setState({ isEdit: false, isDel: false, isAdd: false, isAcc: false, isSalWag: true, isEditAcc: false, })
    }

    render() {
        const { fields, edtfields, delFields } = this.state;
        let { searchText, companiesData, search } = this.state;

        if (search)
            companiesData = companiesData.length && companiesData.filter(obj => {
                if (obj.name.toLowerCase().includes(searchText.toLowerCase()) === true) {
                    return obj;
                }
            })

        let filteredData = companiesData.length && companiesData.map((d, index) => ({
            key: index,
            customContactsCount: ("0" + d.contacts).slice(-2),
            ...d
        }))

        return (
            <div style={{ padding: 15 }}>
                <AddCompanyForm
                    handleSave={this.handleAddSave}
                    cancel={this.state.cancelAdd}
                    visible={this.state.isAdd}
                    states={this.state.states}
                    accountingSoft={this.state.accountingSoft}
                    payrollSoft={this.state.payrollSoft}
                    handleCancel={this.handleAddCancel}
                    nextForm={(fstep) => this.setState({
                        isAcc: true, isAdd: false,
                        isEdit: false, isDel: false,
                        fstep: fstep
                    })}
                />

                <EditCompanyForm
                    handleSave={this.handleEditSave}
                    visible={this.state.isEdit}
                    states={this.state.states}
                    accountingSoft={this.state.accountingSoft}
                    payrollSoft={this.state.payrollSoft}
                    handleCancel={this.handleEditCancel}
                    selectedItem={this.state.selectedCompany}
                    nextForm={(fstep) => this.setState({
                        isEditAcc: true, isAdd: false,
                        isEdit: false, isDel: false,
                        AccNext: fstep
                    })}
                    ShowWages={this.ShowWages}
                />

                <DeleteCompanyForm
                    handleSave={this.handleDelSave}
                    visible={this.state.isDel}
                    handleCancel={this.handleDelCancel}
                    selectedItem={this.state.selectedCompany} />

                <CompanyAccountsForm
                    handleSave={this.handleAddSave}
                    visible={this.state.isAcc}
                    fstep={this.state.fstep}
                    accountingSoft={this.state.accountingSoft}
                    payrollSoft={this.state.payrollSoft}
                    handleCancel={() => this.setState({ cancelAdd: true, isAcc: false, isAdd: false, isEdit: false, isDel: false })}
                    selectedItem={this.state.selectedCompany}
                    handleBack={() => {
                        this.setState({
                            isAcc: false, isAdd: true, isEdit: false, isDel: false
                        })
                    }}
                />

                {this.state.isSalWag && <EditCompanyWagesSettingForm
                    CompanyId={this.state.CompanyId}
                    handleDelCancel={this.handleCancel}>

                </EditCompanyWagesSettingForm>}

                {this.state.isEditAcc == true ? <EditCompanyAccountsForm
                    handleSave={this.handleEditSave}
                    visible={this.state.isEditAcc}
                    AccNext={this.state.AccNext}
                    handleDelete={this.handleDelete}
                    accountingSoft={this.state.accountingSoft}
                    payrollSoft={this.state.payrollSoft}
                    transctionTypes={this.state.transctionTypes}
                    accountTypes={this.state.accountTypes}
                    companyAccountTypes={this.state.companyAccountTypes}
                    companyTransactionTypes={this.state.companyTransactionTypes}
                    handleCancel={() => this.setState({ isAcc: false, isEditAcc: false, isAdd: false, isEdit: false, isDel: false })}
                    selectedItem={this.state.selectedCompany}
                    handleBack={() => {
                        this.setState({
                            isAcc: false, isEditAcc: false, isAdd: false, isEdit: true, isDel: false
                        })
                    }}
                /> : ''}
                <Grid
                    container
                    spacing={24}
                    style={{ 'marginBottom': 10 }}
                >
                    <Grid item xs={12} sm={6} >
                        <h2 className="user-title">Companies</h2>
                    </Grid>
                    <Grid item xs={12} sm={6} >
                        <Grid
                            container
                            spacing={8}
                            direction="row"
                            justify="flex-end"
                            alignItems="flex-end"
                        >
                            <Grid item>
                                <SearchBox
                                    styleName="d-none d-lg-block"
                                    placeholder="Search... "
                                    value={searchText}
                                    onChange={(e) => { this.handleChange(e.target.value) }}
                                    style={{ background: '#eeeeeeb8' }}
                                />
                            </Grid>
                            <Grid item >
                                <Button variant="contained" color="primary" onClick={this.handleAddModal}>
                                    Add Company
                        </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {this.state.loading
                    ?
                    <CircularProgress />
                    :
                    <Table
                        className="table-layout"
                        columns={this.state.columns}
                        dataSource={filteredData}
                        bordered={true}
                        size={"small"}
                        loading={this.state.loading}
                        pagination={false}
                    // scroll={{ y: window.innerHeight - 301 }}
                    />
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        companies: state.companyReducer.companies,
        reload: state.companyReducer.reload,
        error: state.companyReducer.error,
        states: state.companyReducer.states,
        users: state.companyReducer.users,
        accountingSoft: state.companyReducer.accountingSoftwares,
        payrollSoft: state.companyReducer.payrollSoftwares,
        accountTypes: state.accounts.accountTypes,
        tranactionTypes: state.accounts.tranactionTypes,
        companyAccountTypes: state.accounts.companyAccountTypes,
        companyTransactionTypes: state.accounts.companyTransactionTypes,
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        companyDetail,
        companyStatus,
        deleteCompany,
        insertCompanies,
        searchCompany,
        accountingsoftware,
        payrollsoftware,
        AccountTypes,
        CompanyAccountTypes,
        CompanyTransactionTypes,
        getStates,
        TransactionTypes
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(CompanyPage);

import * as CompanyService from '../services/companiesService';
import React from 'react';
import { toast } from "react-toastify";

export const COMPANY_GET = 'COMPANY_GET';
export const COMPANY_STATUS = "COMPANY_STATUS";
export const COMPANY_ERROR = 'COMPANY_ERROR';
export const USERS_GET = 'USERS_GET';
export const USER_STATUS = 'USER_STATUS';
export const DELETE_COMP = 'DELETE_COMP';
export const SET_COMPANYID = 'SET_COMPANYID';
export const USERS_CHECK = 'USERS_CHECK';
export const COMPANY_ADD = 'COMPANY_ADD';
export const CHECK_FAILED = 'CHECK_FAILED';
export const ADDUSER_SUCCESS = 'ADDUSER_SUCCESS';
export const ADDUSER_ERROR = 'ADDUSER_ERROR';
export const SHOW_MENU = 'SHOW_MENU';
export const ACCOUNT_SOFTWARE = 'ACCOUNT_SOFTWARE';
export const PAYROLL_SOFTWARE = 'PAYROLL_SOFTWARE';
export const STATES_GET = "STATES_GET";
export const SINGLE_COMPANY = "SINGLE_COMPANY";
export const DELETE_USER = "DELETE_USER";
export const GET_ACCOUNT_ASYNC_BY_COMPANY = "GET_ACCOUNT_ASYNC_BY_COMPANY";
export const UPDATE_COMPANY_TAXABLE_WAGES_ACCOUNT_ASYNC = "UPDATE_COMPANY_TAXABLE_WAGES_ACCOUNT_ASYNC";
export const GET_TAXABLE_WAGES = 'GET_TAXABLE_WAGES';
export const GET_WAGES_QBO_UPDATE="GET_WAGES_QBO_UPDATE";
export const GET_WAGES_ARCHIVED_DATA="GET_WAGES_ARCHIVED_DATA";

function getCompanyId() {
    let user = JSON.parse(localStorage.getItem('userData'));
    const CompanyId = user.companyId;
    return CompanyId;
}

export const showMenu = () => {
    return (dispatch) => {
        dispatch({ type: SHOW_MENU, payload: { show: true } });
    }
}


export const accountingsoftware = () => {
    return (dispatch) => {
        CompanyService.accountingsoftware()
            .then((response) => {
                dispatch({ type: ACCOUNT_SOFTWARE, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response.data.message}`)
            })
    }
}

export const payrollsoftware = () => {
    return (dispatch) => {
        CompanyService.payrollsoftware()
            .then((response) => {
                dispatch({ type: PAYROLL_SOFTWARE, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response.data.message}`)
            })
    }
}


export const searchUser = (text) => {
    return (dispatch) => {
        CompanyService.searchUser(text)
            .then((response) => {
                dispatch({ type: USERS_GET, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response.data.message}`);
            })
    }
}



export const searchCompany = (text) => {
    return (dispatch) => {
        CompanyService.searchCompany(text)
            .then((response) => {
                dispatch({ type: COMPANY_GET, payload: response });
            })
            .catch((err) => {
                
                toast.error(`${err.response.data.message}`)
            })
    }
}
export const getStates = () => {
    return (dispatch) => {
        CompanyService.getStates()
            .then((response) => {
                dispatch({ type: STATES_GET, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response.data.message}`)
            })
    }
}

export const getCompnay = (id) => {
    return (dispatch) => {
        CompanyService.getCompnay(id)
            .then((response) => {
                dispatch({ type: SINGLE_COMPANY, payload: response });
            })
            .catch(err => {
                toast.error(`${err.response}`)
            })
    }
}

export const companyDetail = () => {
    return (dispatch) => {
        CompanyService.companyDetail()
            .then((response) => {
                console.log(response);
                dispatch({ type: COMPANY_GET, payload: response });

            })
            .catch((err) => {
                console.log(err)
                // toast.error(`${err.response.data.message}`)
            })
    }
}

export const companyStatus = (data) => {
    return (dispatch) => {
        CompanyService.companyStatus(data)
            .then((response) => {
                if (response.data.status == 1)
                    toast.success(response.data.message);
                else
                    toast.error(`${response.data.message}`);
                dispatch({ type: COMPANY_STATUS, payload: response });
            })
            .catch((err) => {
                if (err.response.status == 400) {
                    toast.error(`${err.response.data}`)
                    dispatch({ type: COMPANY_STATUS });
                } else {
                    toast.error(`${err.response.data.message}`)
                }
            })
    }
}

export const assignUserCompanies = (value, userId) => {
    return (dispatch) => {
        CompanyService.assignUserCompanies(value, userId)
            .then((res) => {
                toast.success("Company Assigned Successfully.");
                // dispatch({type: ADDUSER_SUCCESS, payload: res});
            })
            .catch(err => {
                dispatch({ type: ADDUSER_ERROR, payload: err.response });
                toast.error(`${err.response.data.message}`)
            })
    }
}

export const getUsers = () => {

    return (dispatch) => {
        CompanyService.getUsers()
            .then((response) => {
                dispatch({ type: USERS_GET, payload: response });

            })
            .catch((err) => {
                toast.error(`${err.response.data.message}`)
            })
    }
}

export const getUsersAll = (CompanyID, search, pageNumber, pageSize, sortField, sortFormat) => {

    return (dispatch) => {
        CompanyService.getUsersAll(CompanyID, search, pageNumber, pageSize, sortField, sortFormat)
            .then((response) => {
                dispatch({ type: USERS_GET, payload: response });

            })
            .catch((err) => {
                toast.error(`${err.response.data.message}`);
            })
    }
}

export const userStatus = (data) => {

    return (dispatch) => {
        CompanyService.userStatus(data)
            .then((response) => {
                if (response.data.status == 1)
                    toast.success(response.data.message);
                else
                    toast.error(`${response.data.message}`);
                dispatch({ type: USER_STATUS, payload: response });

            })
            .catch((err) => {
                if (err.response.status == 400) {
                    toast.error(`${err.response.data}`);
                    dispatch({ type: USER_STATUS });
                } else {
                    toast.error(`${err.response.data.message}`)
                }
            })
    }
}



export const getUsersByCompany = (id) => {

    return (dispatch) => {
        dispatch({ type: SET_COMPANYID, payload: { id: id } });
        CompanyService.getUsersByCompany(id)
            .then((response) => {
                dispatch({ type: USERS_GET, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response.data.message}`)
            })
    }
}

export const getCompanyTaxableWagesAccountsAsyncByCompanyId = (companyId) => {
    return (dispatch) => {
        CompanyService.getCompanyTaxableWagesAccountsAsync(companyId)
            .then((response) => {
                dispatch({ type: GET_ACCOUNT_ASYNC_BY_COMPANY, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response.data.message}`)
            })
    }
}

export const updateCompanyTaxableWagesAccountsAsync = (data) => {
    return (dispatch) => {
        CompanyService.updateCompanyTaxableWagesAccountsAsync(data)
            .then((response) => {
                dispatch({ type: UPDATE_COMPANY_TAXABLE_WAGES_ACCOUNT_ASYNC, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response.statusText}`)
            })
    }
}

export const post_TaxableWagesReport = (data) => {
    return (dispatch) => {
        CompanyService.post_TaxableWagesReport(data)
            .then((response) => {
                dispatch({ type: GET_TAXABLE_WAGES, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const ArchivedTaxableWagesReport = (data) =>{
    
    return (dispatch) => {
        CompanyService.ArchivedTaxableWagesReport(data)
            .then((response) => {
                dispatch({ type: GET_WAGES_ARCHIVED_DATA, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const deleteCompany = (data) => {
    return (dispatch) => {
        CompanyService.deleteCompany(data)
            .then((response) => {
                dispatch(companyDetail());
                toast.success("Company Deleted Successfully.");
            })
            .catch((err) => {
                toast.error(`${err.response.data[0].description}`)
            })
    }
}

export const deleteUser = (value) => {
    return (dispatch) => {
        CompanyService.deleteUser(value)
            .then((response) => {
                //dispatch({type: DELETE_COMP,payload: response});
                toast.success("User Deleted Successfully.");
                //dispatch(getUsers(value.companyId));
                dispatch({ type: DELETE_USER });
            })
            .catch((err) => {
                if (err.response.status == 400) {
                    toast.error(`${err.response.data.message}`)
                } else {
                    toast.error(`${err.response.data.message}`)
                }
            })
    }
}

export const addUser = (value) => {
    return (dispatch) => {
        dispatch({ type: 'ADDUSER_START', payload: value });
        CompanyService.addUser(value)
            .then((res) => {
                //  dispatch(getUsers(value.companyId));
                if (value.userId && value.userId != "")
                    toast.success("User Edited Successfully.");
                else
                    toast.success("User Added Successfully.");
                dispatch({ type: ADDUSER_SUCCESS, payload: value });
            })
            .catch(err => {
                dispatch({ type: ADDUSER_ERROR, payload: err.response });
                if (err.response.status == 400) {
                    toast.error(`${err.response.data.message}`)
                } else { toast.error(`${err.response.data.message}`) }
            })
    }
}

export const insertCompanies = (model) => {
    return (dispatch) => {
        CompanyService.insertCompanies(model)
            .then((response) => {
                dispatch(companyDetail());
                toast.success("Company Inserted Successfully.");
            })
            .catch((err) => {
                dispatch(companyDetail());
                toast.error(`${err.response.data[0].description}`);
                // dispatch({type:COMPANY_ERROR, payload:err.response});
            })
    }
}



export const updateCompanies = (model, companyid) => {    
    return (dispatch) => {
        CompanyService.updateCompanies(model, companyid)
            .then((response) => {
                dispatch(companyDetail());
                dispatch(getCompnay(getCompanyId()));
                toast.success("Company Updated Successfully.");
                // dispatch({type: COMPANY_ADD, response});
            })
            .catch((err) => {
                dispatch(companyDetail());
                toast.error(`${err.response.data[0].description}`)
                //dispatch({type: COMPANY_ERROR, payload:err.response});
            })
    }
}

export const updateUser = (model) => {
    return (dispatch) => {
        CompanyService.updateUser(model)
            .then((response) => {
                toast.success("User Updated Successfully.");
                dispatch(getUsers(model.companyId));
                // dispatch({type: COMPANY_ADD, response});
            })
            .catch((err) => {
                if (err.response.status == 400) {
                    toast.error(`${err.response.data.message}`)
                } else if (err.response.status == 403) {
                    toast.error(`${err.response.data.message}`)
                    dispatch(getUsers(model.companyId));
                } else { toast.error(`${err.response.data.message}`) }
            })
    }
}
export const refreshToken = (companyid) => {
    return (dispatch) => {
        CompanyService.refreshToken(companyid)
            .then((response) => {

            })
            .catch((err) => {
                toast.error(`${err.response.data.message}`)
            })
    }
}

export const InsertWagesQBOData = (data) => {
    return (dispatch) => {
        CompanyService.InsertTaxableWagesQBOValues(data)
            .then((response) => {
                dispatch({ type:GET_WAGES_QBO_UPDATE, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response.data.message}`)
            })
    }
}

export const printReports = (model) => {
    return (dispatch) => {
        

        CompanyService.printReports(model)
            .then((response) => {
               
                // dispatch(companyDetail());
                toast.success("Print Report Successfull.");
            })
            .catch((err) => {
                console.log(err)
                // dispatch(companyDetail());
                // toast.error(`${err.response.data[0].description}`);
                // dispatch({type:COMPANY_ERROR, payload:err.response});
            })
    }
}
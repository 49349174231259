import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import CircularProgress from './../../../../components/CircularProgress';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import { toast } from 'react-toastify';
// import PaginationComponent from './../../../common/Pagination';
import { GetRecordTFBInPayroll, MarkTFBRecordedInPayrollSystem } from '../../../../appRedux/actions/ReportDetailAction';
import { ReportType, numberWithCommas, Getsum, makeLinkForQuickbooks, ModuleType,LinkType,firstTimeGetPrevQuater, GetLastQuarterEndDate } from '../../../common/commonServices';
import CommonComments from './../../../common/Comments';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import QuaterDatePickerModel from '../../../common/quaterDatePickerModel';
class RecTFBInPayroll extends Component {

    constructor(props) {
        super(props);
        this.sDateRef = React.createRef();
        this.eDateRef = React.createRef();
        var quater = firstTimeGetPrevQuater();
        // let eDate = new Date();
        // let sDate = new Date(eDate.getFullYear(), 0, 1);
        // if (eDate.getMonth() <= 2) {
        //     sDate.setFullYear(eDate.getFullYear() - 1, 0, 1);
        //     eDate = new Date(eDate.getFullYear() - 1, 11, 31);
        // }
        // else {
        //     sDate = new Date(eDate.getFullYear(), 0, 1);
        //     eDate = GetLastQuarterEndDate();
        // }
        let eDate = new Date().getMonth() === 0 ? new Date(new Date().getFullYear()-1,11,31) :new Date();
        let sDate = new Date().getMonth() === 0 ? new Date(new Date().getFullYear()-1,0,1) :new Date(new Date().getFullYear(),0,1);
        this.state = {
            parantData: this.props.location.state ? this.props.location.state : null,
            MissingData: [],
            PendingData: [],
            InPayrollData: [],
            companyId: 0,
            sortField: 'date',
            sortFormat: "A",
            PageNo: 1,
            PageSize: 10000,
            ColorCode: "#f0ca4c",
            // sDate: new Date(2019, 0, 1),
            // eDate: new Date(),
            loading: false,
            TotalRecords: 0,
            CommentReferenceId: 0,
            IsEmbeddedPage: false,
            SelectedRecords: [],
            SelectALL: false,
            ExpandedList: [],
            isOpenDatepicker: false,
            eDate: eDate,
            sDate: sDate,
        }
    }

    componentDidMount() {

        let user = JSON.parse(localStorage.getItem('userData'));
        this.setState({
            companyId: user.companyId,
            loading: true
        }, () => {
            this.GetReportData();
        });
    }

    onLinkClick = (rowData) => {
        let origin = makeLinkForQuickbooks();
        window.open(origin + rowData.transactionLink);
    }

    LoadComments = (item) => {
        this.setState({ IsCommentModelOpen: true, CommentReferenceId: item.id });
    }

    UpdateItemCommentCount = (id) => {
        let inPayrolldata = this.state.InPayrollData;

        if(inPayrolldata!= null && inPayrolldata.employeeGroups.length>0)
        {
            inPayrolldata.employeeGroups.forEach(function (item) {
                
                item.tfbCategoryGroups.forEach(function (cat) {
                
                    cat.list.forEach(function (tran) {
                    
                        if (tran.id === id) {
                            tran.commentCount++
                        }
                    }); 
                    
                }); 
            });
        }
        
        let pendingData = this.state.PendingData;

        if(pendingData!= null && pendingData.employeeGroups.length>0)
        {
            pendingData.employeeGroups.forEach(function (item) {
                
                item.tfbCategoryGroups.forEach(function (cat) {
                
                    cat.list.forEach(function (tran) {
                    
                        if (tran.id === id) {
                            tran.commentCount++
                        }
                    }); 
                    
                }); 
            });
        }

         this.setState({ PendingData:pendingData,InPayrollData:inPayrolldata });
    }

    CloseComments = () => {
        this.setState({ IsCommentModelOpen: false });
    }


    


    onRefreshDataClick = () => {
        if (!this.state.loading) {
            this.setState({ loading: true }, () => {
                this.GetReportData(true);
            });
        }
    }


    componentWillReceiveProps(nextProps) {

        if (nextProps.ReportData !== this.props.ReportData) {
            if (nextProps.ReportData.transactionStatus === 0 && nextProps.ReportData.returnObject != null) {
                this.setState({
                    MissingData: nextProps.ReportData.returnObject, PendingData: nextProps.ReportData.returnObject.pendingRecordedTFB,
                    InPayrollData: nextProps.ReportData.returnObject.inPayrollrecordTFB, loading: false
                })
            }
            else {
                this.setState({ loading: false }, () => {
                    toast.error(nextProps.ReportData.resultMsg);
                });
            }
        }

        if (nextProps.UpdateDescription !== this.props.UpdateDescription) {
            if (nextProps.UpdateDescription.transactionStatus === 0) {
                this.setState({
                    loading: true, SelectedRecords: [], SelectALL: false, editedIndex: -1
                }, () => {
                    toast.success(`${nextProps.UpdateDescription.resultMsg}`);
                    this.GetReportData();
                });
            }
            else {
                this.setState({
                    loading: false, SelectedRecords: [], SelectALL: false
                }, () => {
                    toast.error(`${nextProps.UpdateDescription.resultMsg}`);
                });
            }
        }
    }

    GetReportData = (IsRefresh = false) => {
        let state = this.state;
        let body = {
            companyID: state.companyId,
            reportType: ReportType.RecordTaxableFringeBenefitInPayrollSystem,
            fromDate: moment(state.sDate).format("MM/DD/YYYY"),
            toDate: moment(state.eDate).format("MM/DD/YYYY"),
            sortField: state.sortField,
            sortFormat: state.sortFormat,
            pageNumber: state.PageNo,
            pageSize: state.PageSize,
            needUpdatQBORecords: IsRefresh,
            IsIgnore: true
        };

        this.props.GetRecordTFBInPayroll(body);
    }

    OnApplyApproved = (empId, cat) => {

        let body = {
            "ids": [
                {
                    "item1": empId,
                    "item2": cat.tfbCategoryID
                }
            ],
            "reportType": ReportType.RecordTaxableFringeBenefitInPayrollSystem,
            "companyID": this.state.companyId
        }

        this.setState({ loading: true }, () => {
            this.props.MarkTFBRecordedInPayrollSystem(body);
        });
    }

    handleFocus = (e, selecter) => {
        let control = null;
        if (selecter !== undefined) {
            if (selecter === "sDate") {
                control = this.sDateRef;
                if (control.current != null) {
                    control.current.setFocus();
                }
            }
            else if (selecter === "eDate") {
                control = this.eDateRef;
                if (control.current != null) {
                    control.current.setFocus();
                }
            }
        }
    }
    handleDateChange = (e, target) => {

        if (target === "fromDate") {

            this.setState({ sDate: new Date(e), selectedDateRange: { startDate: moment(new Date(e)).format("MM/DD/YYYY"), endDate: moment(this.state.eDate).format("MM/DD/YYYY") } })
        }
        else if (target === "uptoDate") {
            this.setState({ eDate: new Date(e), selectedDateRange: { startDate: moment(this.state.sDate).format("MM/DD/YYYY"), endDate: moment(new Date(e)).format("MM/DD/YYYY") } })
        }
    }
    onSearchDateFocus = () => {
        this.setState({ isOpenDatepicker: true });
    }
    onDatepickerCancel = (range) => {
        if (range != undefined) {
            var rangeDate = range ? `${moment(range.startdate).format("MM/DD/YYYY")} - ${moment(range.enddate).format("MM/DD/YYYY")}` : '';
            var startDate = range ? `${moment(range.startdate).format("MM/DD/YYYY")}` : this.state.quater.quarterStartDate;
            var endDate = range ? `${moment(range.enddate).format("MM/DD/YYYY")}` : this.state.quater.quarterEndDate;
            this.setState({
                searchDate: rangeDate,
                isOpenDatepicker: false,
                SearchDateOBJ: {
                    from: range.startdate,
                    to: range.enddate
                },
                loading: true
            }, () => {
                this.getGLData(false, range.startdate, range.enddate);
            });
        }
        else {
            this.setState({ isOpenDatepicker: false });
        }
    }
    ApplyDate = () => {
        this.setState({
            loading: true,
            searchDate: "",
            SearchDateOBJ: {
                from: null,
                to: null
            }
        }, () => {
            this.GetReportData();
        });
    }

    render() {
        return (
            <div className="dashboardPage">
                <div className="page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
                    <h2 className="mb-3 mb-sm-0">{JSON.parse(localStorage.getItem('userData')) != null ? JSON.parse(localStorage.getItem('userData')).companyName : ""} | {LinkType.TFBInPayroll}</h2>
                </div>
                {this.state.IsCommentModelOpen ? <CommonComments
                    CloseComments={this.CloseComments}
                    ColorCode={this.state.ColorCode}
                    ModuleType={ModuleType.GeneralReportType}
                    ReportType={ReportType.AssignTaxableFringeBenefitToEmployee}
                    CompanyId={this.state.companyId}
                    RecordId={this.state.CommentReferenceId}
                    IsEmbeddedPage={this.state.IsEmbeddedPage}
                    UpdateItemCommentCount={this.UpdateItemCommentCount}
                ></CommonComments> : ""}

                {this.state.isOpenDatepicker &&
                    <QuaterDatePickerModel
                        options={this.state.quater}
                        onDatepickerCancel={this.onDatepickerCancel}
                        sDate={this.state.sDate}
                        eDate={this.state.eDate}
                    >
                    </QuaterDatePickerModel>
                }
                {this.state.loading ? <CircularProgress></CircularProgress>
                    :
                    <React.Fragment>
                        <div className="padding-box app-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-12">
                                        <div className="col-sm-4 col-md-12 ">
                                        <p className="sub-title">Date Selection</p>
                                        <div className="datepicker-box d-flex justify-content-between">
                                            <div className='datepicker-sub-box d-flex'>
                                            <div className="date-box">
                                                <DatePicker
                                                    selected={this.state.sDate}
                                                    onChange={(e) => this.handleDateChange(e, 'fromDate')}
                                                    name="fromDate"
                                                    id="date"
                                                    required={true}
                                                    margin="normal"
                                                    disabledKeyboardNavigation
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    yearDropdownItemNumber={5}
                                                    maxDate={this.state.eDate}
                                                    ref={this.sDateRef}
                                                />
                                                <div className="date-icon" onClick={(e) => this.handleFocus(e, 'sDate')}>
                                                    <i className="zmdi zmdi-calendar-note"></i>
                                                </div>
                                            </div>
                                            <div className="to-box">
                                                <span className="text-center ledgerto-text">To</span>
                                            </div>
                                            <div className="date-box">
                                                <DatePicker
                                                    selected={this.state.eDate}
                                                    onChange={(e) => this.handleDateChange(e, 'uptoDate')}
                                                    name="uptoDate"
                                                    id="date"
                                                    required={true}
                                                    margin="normal"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    yearDropdownItemNumber={5}
                                                    disabledKeyboardNavigation
                                                    minDate={this.state.sDate}
                                                    maxDate={new Date()}
                                                    ref={this.eDateRef}
                                                />
                                                <div className="date-icon" onClick={(e) => this.handleFocus(e, 'eDate')}>
                                                    <i className="zmdi zmdi-calendar-note"></i>
                                                </div>
                                            </div>
                                            <div className="ml-3" style={{marginTop:"-8px"}}>
                                            <Button variant="raised" className="greencolor jr-btn bg-teal text-white refresh-btn" onClick={(e) => this.ApplyDate()} >apply</Button>

                                            </div>
                                            </div>
                                            <div className='apply-box d-flex'>
                                           
                                            <div className="float-right ml-3">
                                                {/* <Button variant="raised" className=" jr-btn g-teal  text-white refresh-btn graycolor" onClick={() => this.OnApplyApproved()}>Assign</Button> */}
                                                <Button variant="raised" style={{ backgroundColor: this.state.ColorCode }} className="jr-btn text-white refresh-btn" onClick={() => this.onRefreshDataClick()}  ><i className="zmdi zmdi-refresh "></i> Refresh Data</Button>
                                            </div>
                                            </div>
                                        </div>
                                       
                                      
                                    </div>
                                             
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive-material">

                                <table className="table tablebox account-reconcilliation">
                                    {/* <thead style={{ backgroundColor: "#f0ca4c" }}>
                                        <tr>
                                            <th className=""> <p style={{ fontSize: "larger" }}> {this.state.parantData.type}  </p> </th>
                                        </tr>
                                    </thead> */}
                                    <tbody>


                                        <tr className="query_type">
                                            <td className="text-left"> <p style={{ fontSize: "larger", fontWeight: "400" }} > To be Recorded </p></td>
                                        </tr>

                                        {this.state.PendingData != null && this.state.PendingData.employeeGroups != null && this.state.PendingData.employeeGroups.length > 0 ?
                                            <React.Fragment>
                                                {this.state.PendingData.employeeGroups.map((item, j) => (
                                                    <React.Fragment key={"EMP_" + j}>
                                                        <tr className="emp_group"   >
                                                            <td className="text-left" style={{ paddingLeft: "20px" }}>
                                                                <p  >
                                                                    <b> {j + 1}) Employee : </b> {item.employeeName}
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        {item.tfbCategoryGroups !== undefined && item.tfbCategoryGroups != null && item.tfbCategoryGroups.length > 0 &&
                                                            item.tfbCategoryGroups.map((cat, p) => (
                                                                <React.Fragment key={"Cat_" + p}>
                                                                    <tr className="cat_group">
                                                                        <td style={{ paddingLeft: "30px" }}>
                                                                            <p >
                                                                                <b> {j + 1}.{p + 1}) Category : </b> {cat.tfbCategory}
                                                                            </p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ paddingLeft: "30px" }}>
                                                                            <table>
                                                                                <thead style={{ backgroundColor: "#f0ca4c" }}>
                                                                                    <tr>
                                                                                        <th className="td-width">Date</th>
                                                                                        <th className="td-width">Name</th>
                                                                                        <th className="td-width-150">Memo/Desc</th>
                                                                                        <th className="td-width text-center">Amount</th>
                                                                                        <th className="td-width-90 text-center"><img src="/vendors/WhiteCommnet.png" alt="comments" /></th>
                                                                                        <th className="td-width-90 text-center "><img src="/vendors/Link-arrow_header.png" alt="link" /></th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {cat.list != null && cat.list.length > 0 && cat.list.map((tran, k) => (
                                                                                        <React.Fragment key={"tran" + k}>
                                                                                            <tr>
                                                                                                <td>{tran.date}</td>
                                                                                                <td>{tran.name}</td>
                                                                                                <td>{tran.memoOrDescription}</td>
                                                                                                <td className="text-center" >{numberWithCommas(tran.amount)}</td>
                                                                                                <td className="text-center" onClick={(e) => e.stopPropagation()}>
                                                                                                    <div className="panel-commentCount">
                                                                                                        <img src="/vendors/Vector Smart Object.png" alt="comments" className={tran.commentCount === 0 ? "disableCommentsOpacity" : ""} onClick={() => this.LoadComments(tran)}></img>
                                                                                                        {tran.unreviewCommentCount !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">{tran.unreviewCommentCount}</span>}
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td className="text-center" onClick={(e) => e.stopPropagation()}><img src="/vendors/Yellow-link.png" alt="link" onClick={() => this.onLinkClick(tran)}></img></td>
                                                                                            </tr>
                                                                                        </React.Fragment>
                                                                                    ))}
                                                                                    {cat.list != null && cat.list.length &&
                                                                                        <tr>
                                                                                            <td colspan="3" className="text-right weight400"><b> Sub Total : </b> </td>
                                                                                            <td className="text-center weight400" ><b>  {Getsum(cat.list, "amount")}</b> </td>
                                                                                            <td colspan="2" className="text-right"> <Button variant="raised" className=" jr-btn g-teal  text-white refresh-btn graycolor handlebtnsize" onClick={() => this.OnApplyApproved(item.employeeID, cat)}  >Recorded in Payroll System</Button> </td>
                                                                                        </tr>
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </React.Fragment>
                                                            ))
                                                        }
                                                    </React.Fragment>
                                                ))}
                                            </React.Fragment>
                                            :
                                            <tr> <td className="text-center" >No issues here! Keep up the good work!</td></tr>
                                        }

                                        <tr className="query_type">
                                            <td className="text-left"> <p style={{ fontSize: "larger", fontWeight: "400" }} > Recorded in Payroll System  </p></td>
                                        </tr>
                                        {this.state.InPayrollData != null && this.state.InPayrollData.employeeGroups != null && this.state.InPayrollData.employeeGroups.length > 0 ?
                                            <React.Fragment>
                                                {this.state.InPayrollData.employeeGroups.map((item, j) => (
                                                    <React.Fragment key={"EMP_" + j}>
                                                        <tr className="emp_group" >
                                                            <td className="text-left" style={{ paddingLeft: "20px" }}>
                                                                <p  >
                                                                    <b> {j + 1})  Employee : </b> {item.employeeName}
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        {item.tfbCategoryGroups !== undefined && item.tfbCategoryGroups != null && item.tfbCategoryGroups.length > 0 &&
                                                            item.tfbCategoryGroups.map((cat, p) => (
                                                                <React.Fragment key={"Cat_" + p}>
                                                                    <tr className="cat_group">
                                                                        <td style={{ paddingLeft: "30px" }}>
                                                                            <p >
                                                                                <b> {j + 1}.{p + 1}) Category : </b> {cat.tfbCategory}
                                                                            </p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ paddingLeft: "30px" }}>
                                                                            <table>
                                                                                <thead style={{ backgroundColor: "#f0ca4c" }}>
                                                                                    <tr>
                                                                                        <th className="td-width">Date</th>
                                                                                        <th className="td-width">Name</th>
                                                                                        <th  className="td-width-150">Memo/Desc</th>
                                                                                        <th className="td-width text-center">Amount</th>
                                                                                        <th className="td-width-90 text-center"><img src="/vendors/WhiteCommnet.png" alt="comments" /></th>
                                                                                        <th className="td-width-90 text-center "><img src="/vendors/Link-arrow_header.png" alt="link" /></th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {cat.list != null && cat.list.length > 0 && cat.list.map((tran, k) => (
                                                                                        <React.Fragment key={"tran" + k}>
                                                                                            <tr>
                                                                                                <td>{tran.date}</td>
                                                                                                <td>{tran.name}</td>
                                                                                                <td>{tran.memoOrDescription}</td>
                                                                                                <td className="text-center">{numberWithCommas(tran.amount)}</td>
                                                                                                <td className="text-center" onClick={(e) => e.stopPropagation()}>
                                                                                                    <div className="panel-commentCount">
                                                                                                    <img src="/vendors/Vector Smart Object.png" alt="comments" className={tran.commentCount === 0 ? "disableCommentsOpacity" : ""} onClick={() => this.LoadComments(tran)}></img>
                                                                                                        {tran.unreviewCommentCount !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">{tran.unreviewCommentCount}</span>}
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td className="text-center" onClick={(e) => e.stopPropagation()}><img src="/vendors/Yellow-link.png" alt="link" onClick={() => this.onLinkClick(tran)}></img></td>
                                                                                            </tr>
                                                                                        </React.Fragment>

                                                                                    ))}
                                                                                    {cat.list != null && cat.list.length &&
                                                                                        <tr>
                                                                                          <td colspan="3" className="text-right weight400"><b> Sub Total : </b> </td>
                                                                                            <td className="text-center weight400" ><b>  {Getsum(cat.list, "amount")}</b> </td>
                                                                                            <td colspan="2">&nbsp;</td>
                                                                                            {/* <td> <Button style={{ padding: "8px 8px important" }} variant="raised" className=" jr-btn g-teal  text-white refresh-btn graycolor" onClick={() => this.OnApplyApproved(item.employeeID, cat)}>Recorded in Payroll System</Button> </td> */}
                                                                                        </tr>
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>

                                                                </React.Fragment>
                                                            ))
                                                        }
                                                    </React.Fragment>
                                                ))}
                                            </React.Fragment>
                                            :
                                            <tr> <td className="text-center" >No issues here! Keep up the good work!</td></tr>
                                        }
                                    </tbody>
                                </table >
                            </div>
                        </div>
                    </React.Fragment>
                }
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        ReportData: state.ReportData.ReportData,
        UpdateDescription: state.ReportData.UpdateDescription
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        GetRecordTFBInPayroll,
        MarkTFBRecordedInPayrollSystem
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(RecTFBInPayroll);
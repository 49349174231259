// import { da } from "date-fns/locale";
// import { min, max } from "date-fns";
// import { switchCase } from "@babel/types";
// import { Switch } from "@material-ui/core";

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
export function quarterDates(date) {
     var date = new Date(date);
    var month = date.getMonth();
    var quarterMonth = (Math.floor(month / 3) * 3) + 1;
    var year = date.getFullYear();
    var quarterStartDate = (quarterMonth < 10) ? quarterMonth + '/1/' + year : quarterMonth + '/1/' + year;
    var dt = new Date(quarterStartDate);
    dt.setMonth(dt.getMonth() + 2);
    var lastDate = new Date(dt.getFullYear(), dt.getMonth() + 1, 0);
    var quarterEndDate = `${lastDate.getMonth() + 1}/${lastDate.getDate()}/${lastDate.getFullYear()}`;
    var obj = {
        quater: quarterMonth === 4 ? 2 : quarterMonth === 7 ? 3 : quarterMonth === 10 ? 4 : quarterMonth,
        quarterMonth: quarterMonth, year: year, quarterStartDate: quarterStartDate, quarterEndDate: quarterEndDate, month: []
    };
    var monthDate = quaterMonthDate(quarterMonth, obj.year);
    obj.month = monthDate;
    return obj;
}

export function firstTimeGetPrevQuater() {
    var date = new Date();
    var month = date.getMonth();
    var quarterMonth = (Math.floor(month / 3) * 3) + 1;
    var year = date.getFullYear();
    var quarterStartDate = (quarterMonth < 10) ? quarterMonth + '/1/' + year : quarterMonth + '/1/' + year;
    var dt = new Date(quarterStartDate);
    dt.setDate(dt.getDate() - 1);
    var quater = quarterDates(`${dt.getMonth() + 1}/${dt.getDate()}/${dt.getFullYear()}`);
    return quater;
}

export function GetQuaterEndDateByList(data, keyName) {
    var result = null; var dates = [];
    if (data != null) {
        dates = GetDates(data, keyName);
    }
    if (dates.length > 0) {
        result = dates.reduce(function (a, b) {
            return a > b ? a : b;
        });

    }
    else {
        result = new Date();
    }

    var month = GetQuaterEndMonth(result.getMonth());
    result.setMonth(month);
    if (month === 11) {
        result.setDate(31);
    }
    else {
        result = new Date(result.getFullYear(), result.getMonth() + 1, 0)
    }

    return result;
}

export function GetQuaterEndMonth(month) {
    var result = null;
    if (month <= 2) {
        result = 2;
    }
    else if (month > 2 && month <= 5) {
        result = 5;
    }
    else if (month > 5 && month <= 8) {
        result = 8;
    }
    else if (month > 8 && month <= 11) {
        result = 11;
    }

    return result;
}
export function GetQuaterStartMonth(month) {
    var result = null;
    if (month <= 2) {
        result = 0;
    }
    else if (month > 2 && month <= 5) {
        result = 3;
    }
    else if (month > 5 && month <= 8) {
        result = 6;
    }
    else if (month > 8 && month <= 11) {
        result = 9;
    }

    return result;
}

export function GetPreviousQuaterEndDate(date) {
    var result = null;
    if (date != null && typeof date.getMonth === "function") {
        let day = date.getDate();
        let month = date.getMonth();
        if (month < 2 || (month === 2 && day < 25)) {
            result = new Date(date.getFullYear() - 1, 11, 31);
        }
        else if ((month === 2 && day >= 25) || (month < 5 || (month === 5 && day < 25))) {
            result = new Date(date.getFullYear(), 2, 31);
        }
        else if ((month === 5 && day >= 25) || (month < 8 || (month === 8 && day < 25))) {
            result = new Date(date.getFullYear(), 5, 30);
        }
        else if ((month === 8 && day >= 25) || (month < 11 || (month === 11 && day < 15))) {
            result = new Date(date.getFullYear(), 8, 30);
        }
        else if (month === 11 && day >= 15) {
            result = new Date(date.getFullYear(), 11, 31);
        }
    }

    return result;
}

export function GetLastQuarterEndDate() {
    var date = new Date();
    var result = null;
    let month = date.getMonth();

    if (month <= 2) {
        result = new Date(date.getFullYear() - 1, 11, 31);
    }
    else if (month > 2 && month <= 5) {
        result = new Date(date.getFullYear(), 2, 31);
    }
    else if (month > 5 && month <= 8) {
        result = new Date(date.getFullYear(), 5, 30);
    }
    else if (month > 8 && month <= 11) {
        result = new Date(date.getFullYear(), 8, 30);
    }
    return result;
}

export function CheckDatesInQuater(data, key, qtEnddate) {
    var result = true; var dates = [];
    var maxMonth = qtEnddate.getMonth();
    var temp = 0;
    var minMonth = GetQuaterStartMonth(maxMonth);
    if (data != null) {
        dates = GetDates(data, key);
    }
    for (let i = 0; i < dates.length; i++) {
        temp = dates[i].getMonth();
        if (temp > maxMonth || (temp < minMonth) || dates[i].getFullYear() !== qtEnddate.getFullYear()) {
            result = false;
        }
    }

    return result;
}

export function GetDates(data, key,excludeKey=null) {
    var result = [];
    for (let i = 0; i < data.length; i++) {
        if (data[i][key] != null && data[i][key] !== undefined) {
            try {
                if(excludeKey!= null)
                {
                    if(data[i][excludeKey]!== undefined && data[i][excludeKey]===true)
                    {
                        continue;
                    }
                }

                var date = new Date(data[i][key]);
                if (checkValidDate(date)) {
                    result.push(date);
                }
            }
            catch (err) { }
        }
    }
    return result;
}

export function checkValidDate(date) {
    let result = true;
    if (Object.prototype.toString.call(date) === "[object Date]") {
        if (isNaN(date.getTime())) {
            result = false;
        }
    } else {
        result = false;
    }

    return result;
}

export function GetRequiredDate(data, keyName, isMaxDate,excludeKey=null) {
    var result = null; var dates = [];
    if (data != null) {
        if (data != null) {
            dates = GetDates(data, keyName,excludeKey);
        }
    }
    if (dates.length > 0) {
        if (isMaxDate) {
            result = dates.reduce(function (a, b) {
                return a > b ? a : b;
            });
        }
        else {
            result = dates.reduce(function (a, b) {
                return a < b ? a : b;
            });
        }
    }

    return result;
}

function quaterMonthDate(quater, year) {
    var monthDate = [];
    for (let i = 0; i < 3; i++) {
        var startdate = new Date(`${year}/${quater}/01`);
        var month = startdate.getMonth();
        var enddate = new Date(startdate.getFullYear(), startdate.getMonth() + 1, 0);
        monthDate.push({ month: month + 1, monthStr: monthNames[month] + ', ' + year, startdate: startdate, enddate: enddate });
        quater++;
    }
    return monthDate;
}

export function makeLinkForQuickbooks() {
    var origin = window.origin;
    let url = origin.includes('cpa.compliancecenter') ? 'https://qbo.intuit.com' : 'https://sandbox.qbo.intuit.com';
    return url;
}
export function numberWithCommas(x=0) {    
    
    if ((x != null && x !== "") || x === 0) {
        return Number(parseFloat(x).toFixed(2)).toLocaleString('en', {
            minimumFractionDigits: 2
        });
        // if (typeof (x) != "string") {
        //     x = x.toFixed(2);
        // }
        // return x.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    else return x;
}

export function totalTaxableWagesComputations(salaryWages, otherTaxIncome, reimbursements, housingAllowance, healthInsurance, preTaxDeduction, retirementsPlan) {
    return salaryWages + otherTaxIncome - reimbursements - healthInsurance - preTaxDeduction - retirementsPlan;
}

export function totalSocialSecurityWagesComputation(salaryWages, otherTaxIncome, reimbursements, healthInsurance, preTaxDeduction) {
    return salaryWages + otherTaxIncome - reimbursements - healthInsurance - preTaxDeduction;
}

export function totalMedicareWagesComputation(salaryWages, otherTaxIncome, reimbursements, healthInsurance, preTaxDeduction) {
    return salaryWages + otherTaxIncome - reimbursements - healthInsurance - preTaxDeduction;
}

export function Getsum(array, prop) {
    var total = 0
    for (var i = 0; i < array.length; i++) {
        total += parseFloat(array[i][prop]);
    }
    return total.toFixed(2);
}

export function CheckTaxFormat(tax) {
    let result = false;
    if (new RegExp("\\d{3}\\-\\d{2}\\-\\d{4}$").test(tax) === false && new RegExp("\\d{2}\\-\\d{7}$").test(tax) === false
        && new RegExp("[Xx]{6}\\d{4}$").test(tax) === false && new RegExp("[Xx]{7}\\d{4}$").test(tax) === false) {
        result = true;
    }
    return result;
}

export function MaskTaxIdentifier(x) {

    if (x != null && x !== "") {
        if (x.indexOf("X") > -1 || x.indexOf("x") > -1) {
            return x;
        }

        let maskData = "";
        for (let i = 0; i < (x.length - 4); i++) {
            if (x[i] === "-") {
                maskData = maskData + x[i];
            }
            else {
                maskData = maskData + "X";
            }
        }

        return maskData = maskData + x.substr(x.length - 4);

    }
    else return "";
}

export function generateRandomColor() {
    var randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
    return randomColor;
}

export function GetSyncDateFormat(date) {
    if (date != null && typeof date == "string") {
        date = new Date(date);
    }

    if (date == null || !typeof date.getHours === "function") {
        date = new Date();
    }

    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date)
    const mo = new Intl.DateTimeFormat('en', { month: 'long' }).format(date)
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date)
    var hours = date.getHours();
    let mid = 'am';
    if (hours > 12) {
        mid = 'pm';
        hours = hours - 12;
    }

    return (mo + " " + da + ", " + ye + " - " + ("0" + hours).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2) + " " + mid);

}

export function passArrayToCommaSepratedString(array, keyName) {
    return Array.prototype.map.call(array, function (item) { if (item) return item[keyName]; }).join(",")
}

export function getTwoArrayOfFilterData(splitarray, mainarray) {
    var filterArray = [];
    splitarray.map(x => {
        mainarray.find(f => {             
            if (f.value == x)
            {
                 filterArray.push(f); }
            });
    });
    return filterArray;
}

export const PageSizeOption = [{ value: 10, label: '10 / Page' }, { value: 20, label: '20 / Page' }, { value: 50, label: '50 / Page' }, { value: 100, label: '100 / Page' }]

export const DropdownOption = [{ value: 'QT', label: 'Quarterly' }, { value: 'YTD', label: 'npmYear To Date' }];

export const TransactionsType = [
    { value: 'All', label: 'All' },
    { value: 'Credit Card', label: 'Credit Card' },
    { value: 'Credit Bill', label: 'Credit Bill' },
    { value: 'Bill', label: 'Bill' },
    { value: 'Sales Receipt', label: 'Sales Receipt' },
    { value: 'Sales Tax Payment', label: 'Sales Tax Payment' },
    { value: 'Check', label: 'Check' },
    { value: 'Journal Entry', label: 'Journal Entry' },
    { value: 'Credit Memo', label: 'Credit Memo' },
    { value: 'Payment', label: 'Payment' },
    { value: 'Inventory Qty Adjust', label: 'Inventory Qty Adjust' },
    { value: 'Transfer', label: 'Transfer' },
    { value: 'Invoice', label: 'Invoice' },
    { value: 'Deposit', label: 'Deposit' },
    { value: 'Bill Payment', label: 'Bill Payment' },
    { value: 'Refund', label: 'Refund' },
    { value: 'Expense', label: 'Expense' },
    { value: 'Credit Card Expense', label: 'Credit Card Expense' },
    { value: 'Cash Expense', label: 'Cash Expense' }
]

export const LinkType = {
    TranMissVendor: 'Please Add a Vendor Name for These Payments',
    TranMissDescription: 'Please Add a Description for These Transactions',//Transactions Missing a Description
    // OOTran: 'Old Outstanding Transactions',
    OOTran: 'Please Investigate These Old Unreconciled Transactions',//Old Unreconciled Transactions
    TranNBD: 'Please Add a Good Description for These Transactions',
    ChangeACNum: 'Please Approve These Account Category Corrections',//Transactions that Need Account Number Corrections
    ACCLineItem: "We have Questions About These Transactions",
    VendorMissW9: "Please Request a W-9 From These Vendors",
    EmployeeMissW4: "Please Obtain a W-4 from These Employees", //Employees Missing W-4
    RecordCheckPC: "Record Check in Payroll System",
    OtherQueComm: "Please Review These Other Questions and Comments",//Other Questions and Comments
    TranTagCPA: "Transactions tagged as “CPA”",
    RecEmpPayment: "Record Employee Payment in QB Payroll System",
    IgnoreEmpPayment: "Review Ignored Employee Payments",
    HousingAllowanceIssues: "Resolve Excess Housing Allowance Issues",
    EmployeeVehicleForm: "Please Submit Employee Use of Vehicle Form",//Submit Employee Use of Vehicle Form
    RecordEmpVehicleUse: "Record Employee Use of Vehicle",
    AddToEmployeeList: "Add to Employee List",
    RecEmpPayPayroll: "Record Employee Payment in Payroll System",
    MinisterList: "Verify List of Ministers",
    ApproveWorker: "Please Approve Workers as Employees",//Approve Worker Classified as an Employee
    AssignTFBToEmp:"Please Let Us Know Which Employee These Taxable Fringe Benefits Were For",//Assign Taxable Fringe Benefit to Employee and Category
    AssignTFBtoCategory:"Assign Taxable Fringe Benefit to a Category",
    TFBInPayroll:"Record Taxable Fringe Benefit in Payroll System",
    MissRefNo:"Please Add a Check Number in the Reference Field for these Checks",//Checks Missing Reference Number
    IssueHAMemo:"Issue Housing Allowance Memo",
    PendingAccountantApproval1099:"1099's Pending Accountant's Approval",
    PendingClientApproval1099:"Please Approve These 1099's",
    NEC1099Export:"1099 NEC's Ready to Export",
    MISC1099Export:"1099 MISC's Ready to Export",
    Confirm1099Mailed:"Confirm 1099's Were Mailed",
    StatusReport1099:"Here's the Status of Your 1099's",//1099 Status Report
    Resolve1099VendorNameDiscrepancies:"Resolve 1099 Vendor Name Discrepancies",
    ReviewTransactionComments:"Review Transaction Comments",
    UnCategorizeTransaction:"Please Review Uncategorized Transactions",
    ReviewReconciliationDiscrepancies:"Please Review Reconciliation Discrepancies",
    CompanyName:JSON.parse(localStorage.getItem('userData')) != null ? JSON.parse(localStorage.getItem('userData')).companyName : "",
}

export const ReportType = {
    GL: 0,
    MissingVendorName: 1,
    MissingDiscription: 2,
    OutstandingTransection: 3,
    NeedForBetterDescrption: 4,
    AccountNumberChange: 5,
    AccountantsQueAndComments: 6,
    VendorMissW9: 7,
    UpdateVendorType: 8,
    EmployeeMissingW4: 9,
    UpdateEmployeeType: 10,
    Pending4361FilingStatusforClergy: 11,
    PendingEmployeeUseOfVehicleForm: 12,
    PendingBoardMeetingMinutesForHousingAllowance: 13,
    Vendor1099sPendingApprovalByAccountant: 14,
    Vendor1099sPendingApprovalByClient: 15,
    AssignTaxableFringeBenefitsToAnEmployee: 16,
    Vendor1099ComplianceReview: 17,
    TransactionsTaggedAsCPA: 18,
    GeneralQuestionTaggedAsCPA: 19,
    RecordCheckInPayrollSystem: 20,
    GeneralQuestionComments: 21,
    RecordEmployeePaymentInQBPayroll: 22,
    HousingAllwance: 23,
    SubmitEMPVehicleUse: 24,
    RecordEMPVehicleUse: 25,
    VerifyMinisters: 26,
    ApproveWorker:27,
    AddToEmployee:28,
    RecordEmployeePaymentInPayroll: 29,
    AssignTaxableFringeBenefitToEmployee : 30,
    AssignTaxableFringeBenefitToACategory : 31,
    RecordTaxableFringeBenefitInPayrollSystem : 32,
    ChecksMissingReferenceNumber : 33,
    IssueHousingAllowanceMemo : 34,
    Vendor1099ReviewReport : 35,
    Vendor1099PendingAccountantApproval : 36,
    Vendor1099PendingClientApproval : 37,
    Vendor1099NECReadyToExport : 38,
    Vendor1099MISCReadyToExport : 39,
    Confirm1099WereMailed : 40,
    Vendor1099StatusReport : 41,
    Resolve1099VendorNameDiscrepancies : 42,
    UnCategorizeTransaction:43,
    ReviewReconciliationDiscrepancies:44

}

export const AccountDashboardTabsType = {
    ClientsReadyForFinancialCheckup: 1,
    PreCheckIssues: 2,
    OtherIssues: 3,
    PayrollAdministaration: 4,
    Administaration_1099: 5,
    ReviewComments: 6,
    CompanyTaxInformation:7
}

export const ModuleType = {
    GeneralReportType: 1,
    Vendors: 51,
    Employees: 52,
    GeneralQuestion: 2
}

export const LogType = {
    GL: 1,
    Dashboard: 2,
    Admin: 3,
    DailySync: 4,
    Webhook: 5
}

export const EmployeeStatus = {
    Pending: "Clergy",
    Completed: "Non-Clergy"
}

export function GetLogTypeName(type)
{    
    switch(type)
    {
        case LogType.GL: return "GeneralLedger";
        case LogType.Webhook : return "Webhook Call";
        case LogType.Dashboard : return  "Company Dashboard";
        case LogType.Admin :return  "Admin";
        case LogType.DailySync : return "Daily sync";
        default:return "";
    }
  
}

export var Base64 = (function () {
    "use strict";

    var _keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

    var _utf8_encode = function (string) {

        var utftext = "", c, n;

        string = string.replace(/\r\n/g, "\n");

        for (n = 0; n < string.length; n++) {

            c = string.charCodeAt(n);

            if (c < 128) {

                utftext += String.fromCharCode(c);

            } else if ((c > 127) && (c < 2048)) {

                utftext += String.fromCharCode((c >> 6) | 192);
                utftext += String.fromCharCode((c & 63) | 128);

            } else {

                utftext += String.fromCharCode((c >> 12) | 224);
                utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                utftext += String.fromCharCode((c & 63) | 128);

            }

        }

        return utftext;
    };

    var _utf8_decode = function (utftext) {
        var string = "", i = 0, c = 0, c1 = 0, c2 = 0;

        while (i < utftext.length) {

            c = utftext.charCodeAt(i);

            if (c < 128) {

                string += String.fromCharCode(c);
                i++;

            } else if ((c > 191) && (c < 224)) {

                c1 = utftext.charCodeAt(i + 1);
                string += String.fromCharCode(((c & 31) << 6) | (c1 & 63));
                i += 2;

            } else {

                c1 = utftext.charCodeAt(i + 1);
                c2 = utftext.charCodeAt(i + 2);
                string += String.fromCharCode(((c & 15) << 12) | ((c1 & 63) << 6) | (c2 & 63));
                i += 3;
            }

        }

        return string;
    };

    var _hexEncode = function (input) {
        var output = '', i;

        for (i = 0; i < input.length; i++) {
            output += input.charCodeAt(i).toString(16);
        }

        return output;
    };

    var _hexDecode = function (input) {
        var output = '', i;

        if (input.length % 2 > 0) {
            input = '0' + input;
        }

        for (i = 0; i < input.length; i = i + 2) {
            output += String.fromCharCode(parseInt(input.charAt(i) + input.charAt(i + 1), 16));
        }

        return output;
    };

    var encode = function (input) {
        var output = "", chr1, chr2, chr3, enc1, enc2, enc3, enc4, i = 0;

        input = _utf8_encode(input);

        while (i < input.length) {

            chr1 = input.charCodeAt(i++);
            chr2 = input.charCodeAt(i++);
            chr3 = input.charCodeAt(i++);

            enc1 = chr1 >> 2;
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            enc4 = chr3 & 63;

            if (isNaN(chr2)) {
                enc3 = enc4 = 64;
            } else if (isNaN(chr3)) {
                enc4 = 64;
            }

            output += _keyStr.charAt(enc1);
            output += _keyStr.charAt(enc2);
            output += _keyStr.charAt(enc3);
            output += _keyStr.charAt(enc4);

        }

        return output;
    };

    var decode = function (input) {
        var output = "", chr1, chr2, chr3, enc1, enc2, enc3, enc4, i = 0;

        input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");

        while (i < input.length) {

            enc1 = _keyStr.indexOf(input.charAt(i++));
            enc2 = _keyStr.indexOf(input.charAt(i++));
            enc3 = _keyStr.indexOf(input.charAt(i++));
            enc4 = _keyStr.indexOf(input.charAt(i++));

            chr1 = (enc1 << 2) | (enc2 >> 4);
            chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
            chr3 = ((enc3 & 3) << 6) | enc4;

            output += String.fromCharCode(chr1);

            if (enc3 !== 64) {
                output += String.fromCharCode(chr2);
            }
            if (enc4 !== 64) {
                output += String.fromCharCode(chr3);
            }

        }

        return _utf8_decode(output);
    };

    var decodeToHex = function (input) {
        return _hexEncode(decode(input));
    };

    var encodeFromHex = function (input) {
        return encode(_hexDecode(input));
    };

    return {
        'encode': encode,
        'decode': decode,
        'decodeToHex': decodeToHex,
        'encodeFromHex': encodeFromHex
    };
}());
import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import TextArea from 'antd/lib/input/TextArea';
import ClearIcon from '@material-ui/icons/Clear';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CircularProgress from "./../../components/CircularProgress";
import { toast } from 'react-toastify';
import { GET_COMMENTS_DATA, ADD_COMMENTS_DATA, ADD_CLIENTCOMMENTS_DATA, Post_Comment_unread } from "./../../appRedux/actions/CommentsAction";

class QuestionsAnsHistory extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            reply: '',
            RecordId: props.QuestionDetails == null ? 0 : props.QuestionDetails.id,
            Question: props.QuestionDetails == null ? "" : props.QuestionDetails.question,
            ColorCode: props.ColorCode,
            CompanyId: props.CompanyId,
            loading: false,
            ModuleType: props.ModuleType == null ? 0 : props.ModuleType,
            ReportType: props.ReportType == null ? 0 : props.ReportType,
            IsButtonEnable: false,
            IsEmbeddedPage: props.IsEmbeddedPage,
            UpdatedBy: "",
            Comments: [],
            IsCLosed: props.IsCLosed
        }

    }

    componentWillMount() {
        //this.props.onCloseQueAnsHistory();
    }

    HandleClose = () => {
        this.props.onCloseQueAnsHistory();
    };

    handleMessage = (event) => {
        let data = event.target.value;
        if (data != null && data.trim() != "" && data.trim().length > 0) {
            this.setState({ IsButtonEnable: true, message: data });
        }
        else {
            this.setState({ IsButtonEnable: false, message: null });
        }
    };

    componentDidMount() {
        this.setState({
            loading: true
        }, () => {
            this.GetCommentsData();
        });
    }

    GetCommentsData() {
        let body = {
            CompanyID: this.state.CompanyId,
            Module: this.state.ModuleType,
            RecordID: this.state.RecordId,
            ReportType: this.state.ReportType
        }
        this.props.GET_COMMENTS_DATA(body);
    }

    onClickUnreadReview = (e, item, index) => {
        var IsChecked = e.target.checked;
        var comments = [...this.state.Comments];
        comments.map(x => { x.isUnreadFlagCustom = false });
        if (IsChecked) {
            comments[index].isUnreadFlagCustom = IsChecked;
        }
        this.setState({ Comments: comments });
    }

    onClickMarkAsUnread = () => {
        var comments = [...this.state.Comments];
        var commentobj = comments.find(x => x.isUnreadFlagCustom == true);
        if (commentobj) {
            let obj = {
                "companyID": this.state.CompanyId,
                "module": this.state.ModuleType,
                "reportType": this.state.ReportType,
                "recordID": this.state.RecordId,
                "unreviewTillDate": commentobj.createdDate
            }
            this.setState({ loading: true }, () => {
                this.props.Post_Comment_unread(obj);
            });
        } else {
            toast.error(`Please select the comment.`);
        }
    }

    handleUpdatedBy = (e) => {
        this.setState({ UpdatedBy: e.target.value });
    }

    SaveComment = () => {
        let updatedBy = this.state.UpdatedBy
        if (this.state.IsEmbeddedPage && (updatedBy == null || updatedBy.trim().replace(/\s/g, '') == "")) {
            toast.error(`Please enter your name`);
            return false;
        }
        let obj =
        {
            "companyID": this.state.CompanyId,
            "module": this.state.ModuleType,
            "reportType": this.state.ReportType,
            "recordID": this.state.RecordId,
            "message": this.state.message,
            "createdByName": this.state.IsEmbeddedPage ? updatedBy : ""
        };


        if (this.state.IsEmbeddedPage) {
            this.setState({ loading: true }, () => {
                this.props.ADD_CLIENTCOMMENTS_DATA(obj);
                this.props.UpdateItemCommentCount(this.state.RecordId);
                this.GetCommentsData();
            });
        }
        else {
            this.setState({ loading: true }, () => {
                this.props.ADD_COMMENTS_DATA(obj);
                this.props.UpdateItemCommentCount(this.state.RecordId);
                this.GetCommentsData();
            });
        }
    }

    ClosedQuestion = () => {
        this.props.ResolvedQuestion(this.state.RecordId);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.CommentData!==undefined && nextProps.CommentData != this.props.CommentData) {
            nextProps.CommentData.returnObject.map(x => {
                x.isUnreadFlagCustom = false;
            });
            this.setState({ Comments: nextProps.CommentData.returnObject, loading: false, IsButtonEnable: false, message: null });
        }
        if (nextProps.MarkAdUnread!==undefined && nextProps.MarkAdUnread != this.props.MarkAdUnread) {
            this.setState({ Comments: [] }, () => {
                nextProps.MarkAdUnread.returnObject.map(x => {
                    x.isUnreadFlagCustom = false;
                });
                this.setState({ Comments: nextProps.MarkAdUnread.returnObject, loading: false, IsButtonEnable: false, message: null });
            });
        }
    }


    render() {
        return (
            <React.Fragment>
                <Dialog
                    className="comment-Top-align"
                    maxWidth="sm"
                    fullWidth={true}
                    open={true}>
                    <React.Fragment>
                        <div className="Comments modal-header"><span className="log-history-title float-left">Other Questions Answers </span>
                            <Button className="jr-btn close-btn float-right" onClick={this.HandleClose}><ClearIcon /></Button>
                        </div>
                        <DialogContent>
                            <div className="font-weight-bold" style={{ color: this.state.ColorCode }}>
                                {this.state.Question}
                            </div>

                            {this.state.loading ? <CircularProgress></CircularProgress>
                                :
                                <React.Fragment>
                                    {this.state.Comments.map((item, i) => (
                                        <React.Fragment>
                                            <div key={i} className={`row ${item.isUnreviewed ? "unread-comments-box" : "comments-box"}`}>
                                                <div className="col-1 text-center" style={{ padding: '5px' }}>
                                                    <i className={`zmdi zmdi-hc-2x zmdi-check-all ${item.isUnreviewed ? "zmdi-check-all-unread" : "zmdi-check-all-read"}`}></i>
                                                </div>
                                                <div className="col-10">
                                                    <p className="comment-title">{item.message}</p>
                                                    <p className="comment-text" style={{ color: this.state.ColorCode }}><strong> Commented By:</strong>  {item.createdByName}, on &nbsp;
                                                {/* {moment(item.createdDate).format('MM/DD/YYYY hh:mm A')} */}
                                                        {item.stCreatedDate}
                                                    </p>
                                                    {this.state.ReportType === 0 ?
                                                        <p> <strong>Report type: </strong> {item.stReportType}</p>
                                                        : ""
                                                    }
                                                </div>
                                                {!this.state.IsCLosed &&
                                                    <div className="col-1 text-center" style={{ padding: '5px' }}>
                                                        <label className="container101">
                                                            <input className="second-check-box" checked={item.isUnreadFlagCustom} type="checkbox" onChange={(e) => this.onClickUnreadReview(e, item, i)} />
                                                            <span className="checkmark101 green_CheckMark"></span>
                                                        </label>
                                                    </div>
                                                }
                                            </div>
                                        </React.Fragment>
                                    ))}
                                    {!this.state.IsCLosed &&
                                        <React.Fragment>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <TextArea placeholder="Enter Reply"
                                                        onChange={this.handleMessage} value={this.state.message} className="textarea-box"
                                                        style={{ border: "1px solid " + this.state.ColorCode }}>
                                                    </TextArea>
                                                </div>
                                            </div>
                                            {this.state.IsEmbeddedPage ?
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <label><strong> Name  </strong></label>
                                                        <input type="text" style={{ border: " 1px solid " + this.state.ColorCode }} placeholder="Enter Name" className="textarea-box" onChange={this.handleUpdatedBy} />
                                                    </div>
                                                </div>
                                                : ""}
                                            <div className="add-comment-btn-box">
                                                {this.state.IsButtonEnable ?
                                                    <Button className="add-comment-btn" style={{ backgroundColor: this.state.ColorCode }} onClick={this.SaveComment}> Reply </Button>
                                                    :
                                                    <Button disabled className="add-comment-btn" style={{ backgroundColor: this.state.ColorCode, opacity: 0.5 }}> Reply </Button>
                                                }
                                                {(this.state.Comments && this.state.Comments.length > 0) &&
                                                    <Button className="jr-btn bg-grey text-white  float-right" onClick={() => this.onClickMarkAsUnread()}>  Mark as Unread</Button>
                                                }

                                                {!this.state.IsEmbeddedPage &&
                                                    <Button style={{ marginLeft: "5px" }} className="add-comment-btn redishOrangecolor" onClick={() => this.ClosedQuestion()}> Resolved </Button>
                                                }

                                            </div>
                                        </React.Fragment>

                                    }

                                </React.Fragment>
                            }
                        </DialogContent>
                    </React.Fragment>
                </Dialog>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        CommentData: state.CommentData.CommentsData,
        ADD_COMMENT: state.CommentData.AddComments,
        MarkAdUnread: state.CommentData.MarkAdUnread,
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        GET_COMMENTS_DATA,
        ADD_COMMENTS_DATA,
        ADD_CLIENTCOMMENTS_DATA,
        Post_Comment_unread,
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(QuestionsAnsHistory);
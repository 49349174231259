import baseService from './baseService';


export function getRetirementPlans(CompanyID,search,pageNumber,pageSize,sortField,sortFormat){
    return baseService.post(`/RetirementPlan/all_page`,{ CompanyID,search,pageNumber,pageSize,sortField,sortFormat });
}

export function addRetirementPlan(data){
    return baseService.post(`/RetirementPlan`, data);
}

export function updateRetirementPlan(data){
    return baseService.put(`/RetirementPlan`, data);
}

export function deleteRetirementPlan(value){
    return baseService.delete(`/RetirementPlan`, { params:{ id:value } });
}


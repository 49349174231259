import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { addAccountant } from '../appRedux/actions/accountantsAction';
import { findIndex } from "lodash";
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import RSelect from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


class AddAccoutantForm extends React.Component {

  constructor() {
    super()
    this.state = {
      userId: '',
      companyId: '',
      selectedAccoutant: null,
      selectedCompanies: [],
      err: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.handleFormChange = this.handleFormChange.bind(this);
  }
  handleDelete = () => {
    this.props.handleDelete();
  }

  handleCancel = () => {
    this.props.handleCancel();
    this.resetForm();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.selectedAccoutant && this.state.selectedCompanies.length > 0) {

      let id = this.state.selectedAccoutant.value;
      let comps = this.state.selectedCompanies;

      let data1 = comps.map((val, i) => {
        return val.value
      });

      let data = {
        userId: id,
        body: data1
      };

      this.props.addAccountant(data);
      this.props.handleSave(false);
      this.resetForm();

    } else {
      this.setState({ err: true })
    }
  }


  handleCompChange = (selectedCompanies) => {
    this.setState({ selectedCompanies, err: false });
  }
  handleAccountChange = (selectedAccoutant) => {
    var data = [];
    let findObj = this.props.accountantsData.find(x => x.userId == selectedAccoutant.value);
    if (findObj && findObj.companies && findObj.companies.length > 0) {
      findObj.companies.map(x => {
        data.push({ value: x.companyId, label: x.name });
      });
    }
    this.setState({ selectedAccoutant, err: false, selectedCompanies: data });
  }
  resetForm = () => {
    this.setState({
      ...this.state,
      userId: '',
      companyId: '',
      selectedAccoutant: null,
      selectedCompanies: [],
      err: false,
    })
  }

  render() {
    const { visible, companiesData, accountantsData, usersData } = this.props;
    const { selectedCompanies, selectedAccoutant } = this.state;

    const options = companiesData.filter(data1 => {
      return data1.companyId !== 1 && data1.isActive == true;
    }).map(data => {
      return {
        value: data.companyId,
        label: data.name
      }
    });
    const options1 = accountantsData.map(data => {
      return {
        value: data.userId,
        label: data.userName
      }
    });


    return (
      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={visible}
        onClose={this.handleCancel}
      >

        <DialogTitle>Assigned companies</DialogTitle>
        <DialogContent>
          <form autoComplete="off" style={{ minHeight: 300, padding: 10 }}>
            <fieldset>
              <Grid container spacing={24} >
                <Grid item xs sm={6}>
                  <InputLabel htmlFor="tax-native-simple">Select Accountant</InputLabel>
                  <RSelect
                    value={selectedAccoutant}
                    onChange={this.handleAccountChange}
                    options={options1}
                    isClearable={true}
                  />
                </Grid>
                <Grid item xs sm={6}>
                  <InputLabel htmlFor="tax-native-simple">Select Company</InputLabel>
                  <RSelect
                    value={selectedCompanies}
                    onChange={this.handleCompChange}
                    options={options}
                    isMulti={true}
                  />
                </Grid>

              </Grid>
              {this.state.err ?
                <div style={{ padding: 10, marginTop: 20 }}>
                  <p style={{ color: 'red' }}>Please Select Accountant and Companies</p>
                </div> : ''}

            </fieldset>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleSubmit} color="primary">
            Assign
                </Button>
          <Button onClick={this.handleCancel} color="primary" autoFocus>
            Cancel
                </Button>
        </DialogActions>
      </Dialog>
    );
  }
}



const mapStateToProps = (state) => {
  return {

  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    addAccountant
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AddAccoutantForm);




import baseService from './baseService';



export function GetSyncData(data) {
    return baseService.post("/quickbooks/GetAllSyncMaster", data);
}

export function GetSyncDetails(data)
{
    return baseService.post("/quickbooks/GetAllSyncDetails",data);

}
import * as authService from '../services/auth';
import baseService from '../services/baseService';
import history from '../../browserHistory';
import { LOGIN_SUCCESS, LOGIN_FAILED, LOGIN_REQUEST, RESET_FAILED, RESET_SUCCESS } from '../reducers/Auth';
import jwt_decode from 'jwt-decode';
import Snackbar from '@material-ui/core/Snackbar';
import React from 'react';
import { toast } from "react-toastify";


export const loginUser = (credentials) => {
    return (dispatch) => {

        dispatch({ type: LOGIN_REQUEST, payload: { loading: false } });
        authService.login(credentials)
            .then((response) => {
                let userData = { Authorization: '', role: '', companyId: '' };

                userData.Authorization = response.data.token;
                let decode = jwt_decode(response.data.token);
                var role = decode['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
                var name = decode['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'];
                let companyId = decode.CompanyId;
                let authData = { role, companyId };
                userData.role = role;
                userData.companyId = companyId;
                localStorage.setItem('userData', JSON.stringify(userData));
                dispatch({ type: LOGIN_SUCCESS, payload: userData });
                baseService.addAuthToken();

                let redirectURL=localStorage.getItem("redirectURL");
                if(redirectURL!= null && redirectURL!="")
                {
                    localStorage.removeItem("redirectURL");
                    history.push(redirectURL);
                }
                else{
                    if (role === 'SuperAdmin' || role == 'Admin' || 'Accountant') {
                        history.push('/home');
                    } else {
                        history.push('/dashboard');
                    }
                }

                
            })
            .catch((error) => {
                dispatch({ type: LOGIN_FAILED, payload: error.response });
            });
    }
};

export const logoutUser = () => {
    return (dispatch) => {
        authService.logout()
            .then((response) => {
                baseService.removeAuthToken();
            })
            .catch((error) => {
                toast.error(`${error.response.data}`)
            });
    }
}

export const ForgotPassword = (username) => {
    return (dispatch) => {
        authService.ForgotPassword(username)
            .then((response) => {
                toast.success(`Forgot Password instructions sent to your Registered Email.`)
            })
            .catch((error) => {
                if (error.response.status == 400) {
                    toast.error(`${error.response.data.message}`)
                } else {
                    toast.error(`${error.response.data}`)
                }
            })
    }
}
export const ResetPassword = (data) => {
    return (dispatch) => {
        authService.ResetPassword(data)
            .then((response) => {
                let userData = { Authorization: '', role: '', companyId: '' };

                userData.Authorization = response.data.token;
                let decode = jwt_decode(response.data.token);
                var role = decode['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
                var name = decode['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'];
                let companyId = decode.CompanyId;
                let authData = { role, companyId };
                userData.role = role;
                userData.companyId = companyId;
                localStorage.setItem('userData', JSON.stringify(userData));
                dispatch({ type: LOGIN_SUCCESS, payload: userData });
                baseService.addAuthToken();
                if (role === 'SuperAdmin' || role == 'Admin' || role == 'Accountant') {
                    history.push('/home');
                } else {
                    history.push('/dashboard');
                }
                toast.success(`Password Changed Successfully`)
                dispatch({ type: RESET_SUCCESS, payload: userData });
            })
            .catch((error) => {
                if (error.response.status == 400) {
                    toast.error(`${error.response.data.message}`)
                } else {
                    toast.error(`${error.response.data.message}`)
                }

                // dispatch({type: RESET_FAILED, payload:error.response});

            })
    }
}

import React, { Component } from 'react';
import _, { forEach } from 'lodash';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import CommonComments from "./../../../app/common/Comments";
import Textarea from 'react-expanding-textarea';
import { GetLastQuarterEndDate, numberWithCommas, makeLinkForQuickbooks, ModuleType, ReportType, Base64, LinkType } from "./../../../app/common/commonServices";
import moment from 'moment';
import { bindActionCreators } from 'redux';
import CircularProgress from './../../../components/CircularProgress';
import { GetUncategorizeData_BES,LedgerUpdate_BES } from "./../../../appRedux/actions/LedgerAction";
import {Accounts_BES} from "./../../../appRedux/actions/accountAction";
import { toast } from 'react-toastify';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import RSelect from 'react-select';
import { he } from 'date-fns/esm/locale';
import "../companydashboard.css";
import { UPDATE_DESCRIPTION_DATA_BES, UPDATE_ACCOUNT_APPROVED_DATA } from "./../../../appRedux/actions/ReportDetailAction";
import queryString from 'query-string';
import RefreshDataModal from '../../../components/RefreshDataModal'


class UnCategorizedTran_Embedded extends Component {

    constructor(props) {

        super(props);
        this.sDateRef = React.createRef();
        this.eDateRef = React.createRef();
        // let eDate = new Date();
        // let sDate = new Date(eDate.getFullYear(), 0, 1);
        // if (eDate.getMonth() <= 2) {
        //     sDate.setFullYear(eDate.getFullYear() - 1, 0, 1);
        //     eDate = new Date(eDate.getFullYear() - 1, 11, 31);
        // }
        // else {
        //     sDate = new Date(eDate.getFullYear(), 0, 1);
        //     eDate = GetLastQuarterEndDate();
        // }

        this.state = {
            parantData: this.props.location.state ? this.props.location.state : null,
            lst_GL: [],
            companyId: 0,
            sortField: 'date',
            sortFormat: "D",
            PageNo: 1,
            PageSize: 10,
            ColorCode: "#e27b40",
            IsCommentModelOpen: false,
            sDate: new Date(2019,0,1),
            eDate: new Date(),
            loading: false,
            TotalRecords: 0,
            CommentReferenceId: 0,
            IsEmbeddedPage: true,
            HomeLink: "",
            DisplayAccounts: [],
            MaxWidth:0,
            MaxHeight:0,
            selectedAccount: { value: 0, label: "Select" },
            editedBy: "",
            editData: null,
            editIndex: -1,
            ShowApprovedInput: false,
            ApproverName: null,
            SelectALL: false,
            SelectedRecords: [],
            companyName: "",
            isRefreshDataModalOpen:false
        }
    }

    componentDidMount() {
        let value = queryString.parse(this.props.location.search);

        // localStorage.setItem("homeURL", window.location.href.replace(window.location.origin, ""));
        let dashboardURl = localStorage.getItem("homeURL");
        let height = window.innerHeight-100;
        let width= window.innerWidth-50;                
        this.setState({
            loading: true,
            HomeLink: dashboardURl ? dashboardURl : "?q="+ value.q + "&p=" + value.p,
            MaxHeight:height,
            MaxWidth:width
        }, () => {
            this.getCompanyId();
        });
    }

    LoadComments = (item) => {
        this.setState({ ShowComment: true, CommentReferenceId: item.id });
    }

    getCompanyId = () => {
        let value = queryString.parse(this.props.location.search);
        localStorage.setItem("q", JSON.stringify(value));
        let data = localStorage.getItem("q");

        if (data != null && data != undefined) {
            data = JSON.parse(data);
            this.setState({
                companyId: parseInt(Base64.decode(data.q)),
                companyName:Base64.decode(data.p),
                loading: true,
                ShowApprovedInput: false
            }, () => {
                this.GetReportData();
                this.props.Accounts_BES(parseInt(Base64.decode(data.q)));
            });
        }
        else {
            this.setState({ loading: false });
        }
    }

    SelectALL = (e) => {
        var dataOfGL = [...this.state.lst_GL];
        let editIndex1 = this.state.editIndex1;
        let editIndex = this.state.editIndex;
        if (this.state.lst_GL.length > 0) {
           
            if (e.target.checked) {
                let all = [];
                let data = this.state.lst_GL;
                for (let j = 0; j < data.length; j++) {
                    let data2 = data[j].data;
                    for (let i = 0; i < data2.length; i++) {
                        if (data2[i].newAccountName) {
                           all.push(data2[i].id);
                        }
                    }
                }
                this.setState({ SelectALL: true, SelectedRecords: all, ShowApprovedInput: true });
            }
            else {
                this.setState({ SelectALL: false, SelectedRecords: [],ShowApprovedInput: false,ApproverName:null});

            }

        }

    }

    onCheckboxClick = (e, item) => {
        e.stopPropagation();

        if (item.newAccountID == null || item.newAccountID === "") {
            e.preventDefault();
        }
        else {
            let existInArray = false;
            let data = this.state.SelectedRecords;
            if (data != null && data.length > 0 && data.indexOf(item.id) > -1) {
                existInArray = true;
            }

            if (e.target.checked) {
                if (!existInArray) {
                    data.push(item.id);
                }
            }
            else {
                if (existInArray) {
                    data.splice(data.indexOf(item.id), 1);
                }
            }
            let count = 0;
            let Maindata = this.state.lst_GL;
            for (let j = 0; j < Maindata.length; j++) {
                let data2 = Maindata[j].data;
                for (let i = 0; i < data2.length; i++) {
                    if (data2[i].newAccountName) {
                        count++;
                    }
                }
            }
            let all = (data.length == count) ? true : false;
            let showinput = data.length > 0 ? true : false;
            this.setState({ SelectedRecords: data, ShowApprovedInput: showinput, SelectALL: all });
        }
    }

    handleApprovedBy = (e) => {
        this.setState({ ApproverName: e.target.value });
    }

    OnApplyApproved = () => {
        let selectedData = this.state.SelectedRecords;
        if (selectedData == null || selectedData.length === 0) {
            toast.error("Please select atleast 1 record for processing request");
            return false;
        }

        let approver = this.state.ApproverName;
        if (approver == null || approver.trim().replace(/\s/g, '') === "") {
            toast.error("Please enter the approver name");
            return false;
        }

        let body = {
            "ids": selectedData,
            "createdByName": approver,
            "companyID": this.state.companyId,
            "reportType": ReportType.UnCategorizeTransaction,
        }

        //this.setState({ loading: true }, () => {
            this.props.UPDATE_ACCOUNT_APPROVED_DATA(body)
        //});
    }
    
    handleFocus = (e, selecter) => {
        let control = null;
        if (selecter !== undefined) {
            if (selecter === "sDate") {
                control = this.sDateRef;
                if (control.current != null) {
                    control.current.setFocus();
                }
            }
            else if (selecter === "eDate") {
                control = this.eDateRef;
                if (control.current != null) {
                    control.current.setFocus();
                }
            }
        }
    }

    CloseComments = () => {
        this.setState({ ShowComment: false });
    }

    UpdateItemCommentCount = (id) => {
        let item = this.state.lst_GL;
        item.forEach(function (records) {

            records.data.forEach(function (details) {
                if (details.id === id) {
                    details.commentCount++
                }
            });
        });

        this.setState({ lst_GL: item });
    }

    GetReportData(IsRefresh = false) {
        let state = this.state;
        let body = {
            "companyID": state.companyId,
            "sortField": state.sortField,
            "sortFormat": state.sortFormat,
            "fromDate": moment(state.sDate).format("MM/DD/YYYY"),
            "toDate": moment(state.eDate).format("MM/DD/YYYY"),
            "needUpdatQBORecords": IsRefresh
        };
        this.props.GetUncategorizeData_BES(body);
    }

    componentWillReceiveProps(nextProps) {
       
        if (nextProps.ledgers !== undefined && nextProps.ledgers !== this.props.ledgers) {
            if (nextProps.ledgers.transactionStatus === 0) {
                this.setState({ 
                    loading: false, lst_GL: nextProps.ledgers.returnObject.uncategorizedTransactionsGL,
                    ShowApprovedInput: false, ApproverName: null, SelectALL: false
                })
            }
            else {
                this.setState({ loading: false, lst_GL: nextProps.ledgers.returnObject.uncategorizedTransactionsGL }, () => { toast.error(nextProps.ReportData.resultMsg); })
            }
        }

        if (nextProps.accounts != undefined && nextProps.accounts !== this.props.accounts) {

            let searchableAccounts = [];
            if (nextProps.accounts.data != null && nextProps.accounts.data.length > 0) {
                searchableAccounts = _.sortBy(nextProps.accounts.data, ['accountNumber', 'name']).map((value, index) => (
                    { value: value.accountID, label: (value.accountNumber != null ? value.accountNumber + ' ' + value.name : value.name) }
                ));
            }
            let defaultValue = { value: 0, label: "Select" };
            searchableAccounts.unshift(defaultValue);
            this.setState({
                accounts: nextProps.accounts.data,
                DisplayAccounts: searchableAccounts
            });
        }

        if (nextProps.UpdateDescription !== this.props.UpdateDescription) {
            if (nextProps.UpdateDescription.transactionStatus === 0) {
                this.setState({
                    loading: true, editData: null,editIndex1:-1, editIndex: -1, SelectedRecords: [],
                    ShowApprovedInput: false, ApproverName: null, SelectALL: false
                }, () => {
                    toast.success(`${nextProps.UpdateDescription.resultMsg}`);
                    this.GetReportData();
                });
            }
            else {
                this.setState({
                    loading: false, SelectedRecords: [], ShowApprovedInput: false, ApproverName: null, SelectALL: false
                }, () => {
                    toast.error(`${nextProps.UpdateDescription.resultMsg}`);
                });
            }
        }
    }

    onRowExpandCollapseClick = (accountIndex, rowData) => {
        var dataOfGL = [...this.state.lst_GL];
        dataOfGL[accountIndex].isExpand = !dataOfGL[accountIndex].isExpand;
        if (this.state.editData && this.state.editIndex1 > -1 && this.state.editIndex > -1) {
            var dataOfGL = [...this.state.lst_GL];
            let editIndex1 = this.state.editIndex1;
            let editIndex = this.state.editIndex;
            let editData = this.state.editData;
            dataOfGL[editIndex1].data[editIndex].vendor1099 = editData.vendor1099;
            dataOfGL[editIndex1].data[editIndex].taxableFringeBenefit = editData.tfb;
            dataOfGL[editIndex1].data[editIndex].needsBetterDescription = editData.nbd;
            dataOfGL[editIndex1].data[editIndex].transferCodedIncorrectly = editData.tci;
            dataOfGL[editIndex1].data[editIndex].accountantComment = editData.ac;
            dataOfGL[editIndex1].data[editIndex].internalNotes = editData.in;
            dataOfGL[editIndex1].data[editIndex].newAccountID = editData.newAccId;
            dataOfGL[editIndex1].data[editIndex].newAccountName = editData.newAccName;
            dataOfGL[editIndex1].data[editIndex].cpaReview = editData.cpa;
            dataOfGL[editIndex1].data[editIndex].pcCheck = editData.pc;
            this.setState({
                lst_GL: dataOfGL, editIndex1: -1,
                editIndex: -1,
                editData: null
            });
        } else {
            this.setState({
                lst_GL: dataOfGL,
                editIndex1: -1,
                editIndex: -1,
                editData: null
            });
        }
    }

    ApplyDate = () => {
        this.setState({
            loading: true,
        }, () => {
            this.GetReportData();
        });
    }

    onLinkClick = (e, accountIndex, rowIndex, rowData) => {
        e.stopPropagation()
        let origin = makeLinkForQuickbooks();
        window.open(origin + rowData.transactionLink);
    }

    handleDateChange = (e, target) => {

        if (target === "fromDate") {

            this.setState({ sDate: new Date(e) });
        }
        else if (target === "uptoDate") {
            this.setState({ eDate: new Date(e) });
        }
    }

    refreshData = () => {
        if (!this.state.loading) {
            this.setState({ loading: true,isRefreshDataModalOpen:false }, () => {
                this.GetReportData(true);
            });
        }
    }

    onRefreshDataClick = () => {
        
        this.setState({isRefreshDataModalOpen:true})
    }

    handleRefreshDataModal=()=>{
        this.setState({isRefreshDataModalOpen:false})
      }
    onRowSave = (accountIndex, rowIndex, data) => {
        let editedData = this.state.editData;
        if (editedData.memoOrDescription == null || editedData.memoOrDescription.trim() == "") {
            toast.error(`Please enter description`);
            return false;
        }
        let user = this.state.editedBy;
        if (user == null || user.trim() == "" || user.replace(/\s/g, '') == "") {
            toast.error(`Please enter your name`);
            return false;
        }

        let body =
        {
            "id": data.id,
            "memoOrDescription": editedData.memoOrDescription,
            "reportType": ReportType.UnCategorizeTransaction,
            "createdByName": user
        };
        this.props.UPDATE_DESCRIPTION_DATA_BES(body);
        // this.setState({
        //     loading: true
        // }, () => {
        //     this.props.UPDATE_DESCRIPTION_DATA_BES(body);
        // });
    }

    onRowCancel = (accountIndex, rowIndex, rowData) => {
        rowData.memoOrDescription = this.state.OriginalDesc;
        this.setState({editIndex1:null, editData: null, editIndex: -1 });
    }

    onRowEdit = (e,accountIndex, rowIndex, rowData) => {
        this.setState({ editData: rowData,editIndex1:accountIndex, editIndex: rowIndex, OriginalDesc: rowData.memoOrDescription });
    };


    ChangeDescription = (e,accountIndex, rowIndex, rowdata) => {

        var data = this.state.editData;
        data.memoOrDescription = e.target.value
        this.setState({ editData: data });
    }

    GetEditBy = (e) => {
        this.setState({ editedBy: e.target.value });
    }

    onSortChange = (fieldName) => {
        if (this.state.sortField === fieldName) {
            let formate = this.state.sortFormat === 'A' ? 'D' : 'A';
            this.setState({ sortFormat: formate, loading: true ,ShowApprovedInput: false}, () => {
                this.GetReportData();
            });
        } else {
            this.setState({ sortField: fieldName, sortFormat: 'A', loading: true ,ShowApprovedInput: false}, () => {
                this.GetReportData();
            });
        }
    }

    render() {
        let selectedItem = this.state.SelectedRecords;
        console.log("h : "+this.state.MaxHeight)
        return (

            <React.Fragment>
                <RefreshDataModal visible={this.state.isRefreshDataModalOpen}
                 handlePopUpClose={this.handleRefreshDataModal} refreshData={this.refreshData}/>
                {this.state.ShowComment ?
                    <CommonComments
                        CloseComments={this.CloseComments}
                        ColorCode={this.state.ColorCode}
                        ModuleType={ModuleType.GeneralReportType}
                        ReportType={ReportType.UnCategorizeTransaction}
                        CompanyId={this.state.companyId}
                        RecordId={this.state.CommentReferenceId}
                        IsEmbeddedPage={this.state.IsEmbeddedPage}
                        UpdateItemCommentCount={this.UpdateItemCommentCount}
                    >
                    </CommonComments> : ""}


                <div className="dashboardPage embeded-dashboard">
                    <ToastContainer autoClose={3000} />
                    {this.state.loading ? <CircularProgress></CircularProgress>
                        :
                        <React.Fragment>
                            <div className=" page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
                                <h2 className="mb-3 mb-sm-0">{this.state.companyName} | Please Review Uncategorized Transactions</h2>
                                <Link className="home-link" to={this.state.HomeLink} > Home </Link>
                            </div>
                            <div className="padding-box app-wrapper bgcolor"
                            //  style={{maxHeight:this.state.MaxHeight,maxWidth:this.state.MaxWidth,overflowY:"scroll",overflowX:"scroll"}}
                             >
                                <div className="row">
                                    {/* <div className="col-md-6">
                                         <div className="select-quater-box">
                                            <p className="sub-title">Date Selection</p>
                                            <div className="row">
                                                <div className="col-sm-4 col-md-12 ">
                                                    <div className="datepicker-box">
                                                        <div className="date-box">
                                                            <DatePicker
                                                                selected={this.state.sDate}
                                                                onChange={(e) => this.handleDateChange(e, 'fromDate')}
                                                                name="fromDate"
                                                                id="date"
                                                                required={true}
                                                                margin="normal"
                                                                disabledKeyboardNavigation
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                yearDropdownItemNumber={5}
                                                                maxDate={this.state.eDate}
                                                                ref={this.sDateRef}
                                                            />
                                                            <div className="date-icon" onClick={(e) => this.handleFocus(e, 'sDate')}>
                                                                <i className="zmdi zmdi-calendar-note"></i>
                                                            </div>
                                                        </div>
                                                        <div className="to-box">
                                                            <span className="text-center ledgerto-text">To</span>
                                                        </div>
                                                        <div className="date-box">
                                                            <DatePicker
                                                                selected={this.state.eDate}
                                                                onChange={(e) => this.handleDateChange(e, 'uptoDate')}
                                                                name="uptoDate"
                                                                id="date"
                                                                required={true}
                                                                margin="normal"
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                yearDropdownItemNumber={5}
                                                                disabledKeyboardNavigation
                                                                minDate={this.state.sDate}
                                                                maxDate={new Date()}
                                                                ref={this.eDateRef}
                                                            />
                                                            <div className="date-icon" onClick={(e) => this.handleFocus(e, 'eDate')}>
                                                                <i className="zmdi zmdi-calendar-note"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="genera-apply-btn" style={{ marginTop: "5px" }}>
                                                        <Button variant="raised" className="orangeColor jr-btn bg-teal text-white refresh-btn" onClick={(e) => this.ApplyDate()} >apply</Button>

                                                    </div>

                                                </div>
                                            </div>
                                        </div> 
                                    </div> */}
                                   
                                 
                                    <div className="col-md-12">
                                    <div className="float-right">
                                    <Button variant="raised" className=" jr-btn graycolor text-white refresh-btn" style={{textTransform:'lowercase'}} onClick={() => {window.open('https://go.oncehub.com/churchcpa')}}> Schedule an Appointment to Review Uncategorized Transactions</Button>
                                        <Button variant="raised" className=" jr-btn graycolor text-white refresh-btn" onClick={() => this.OnApplyApproved()}>Apply Changes</Button>
                                        <Button variant="raised" className=" tr-bgcolor orangeColor jr-btn bg-teal text-white refresh-btn" onClick={() => this.onRefreshDataClick()} >
                                                <i className="zmdi zmdi-refresh "></i> Refresh Data</Button>

                                                </div>
                                    </div>
                                </div>
                                {this.state.ShowApprovedInput ?
                                        <div className="row" style={{ marginTop: "20px" }}>
                                            <div className="col-md-12">
                                                <div className="float-right">
                                                    <label><strong> Approved By </strong></label> &nbsp;&nbsp;
                                            <input type="text" placeholder="enter your name" onChange={this.handleApprovedBy} />
                                                </div>
                                            </div>
                                        </div>
                                        : ""}
                                <div className="table-responsive-material "  >
                                    <table className={`table tablebox account-reconcilliation `}>
                                        <thead>
                                            <tr style={{ backgroundColor: this.state.ColorCode }}>
                                                <th className={`w-10P ${this.state.sortField == 'date' ? this.state.sortFormat == 'A'
                                                    ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('date')}>
                                                    <p className="table-th-title">Date</p>
                                                </th>
                                                <th className={`w-15P ${this.state.sortField == 'transactionType' ?
                                                    this.state.sortFormat == 'A'
                                                        ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('transactionType')}>
                                                    <p className="table-th-title"> Type</p>
                                                </th>
                                                <th className={`w-10P ${this.state.sortField == 'num' ?
                                                    this.state.sortFormat == 'A'
                                                        ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('num')}>
                                                    <p className="table-th-title">Num</p>
                                                </th>

                                                <th className={`w-10P ${this.state.sortField == 'name' ?
                                                    this.state.sortFormat == 'A'
                                                        ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('name')}>
                                                    <p className="table-th-title">Name</p>
                                                </th>
                                                <th className={`w-20P ${this.state.sortField == 'memoOrDescription' ?
                                                    this.state.sortFormat == 'A'
                                                        ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('memoOrDescription')}>
                                                    <p className="table-th-title">Memo/Description</p>

                                                </th>
                                                <th className={`w-10P text-center  ${this.state.sortField == 'amount' ?
                                                    this.state.sortFormat == 'A'
                                                        ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('amount')}>
                                                    <p className="table-th-title"> Amount </p>

                                                </th>
                                                <th className={`w-10P ${this.state.sortField === 'newAccountName' ?
                                                    this.state.sortFormat === 'A'
                                                        ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('newAccountName')}>
                                                    <p className="table-th-title">  New Account</p>

                                                </th>

                                                <th className="w-5P">
                                                        <label className="container101 header-check ">
                                                            {this.state.SelectALL ?
                                                                <input className="second-check-box" checked="checked" type="checkbox" onClick={(e) => this.SelectALL(e)} />
                                                                :
                                                                <input className="second-check-box" type="checkbox" onClick={(e) => this.SelectALL(e)} />
                                                            }
                                                            <span className="checkmark101 white_border greenMark "></span>
                                                        </label>

                                                    </th>
                                                <th className="w-5P">
                                                    <div className="panel-commentCount col-md-2">
                                                        <img src="/vendors/WhiteCommnet.png" alt=""></img>
                                                    </div>
                                                </th>
                                                <th className="w-5P text-center "><img src="/vendors/Link-arrow_header.png" alt="link" /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <React.Fragment>
                                                {this.state.lst_GL.map((account, i) => (
                                                    <React.Fragment key={i}>
                                                        <tr className={account.isExpand ? "bg-active" : ""} onClick={() => this.onRowExpandCollapseClick(i, account)}>
                                                            <td className="title-name" colSpan="10">
                                                                {
                                                                    account.isExpand ? <i className="zmdi zmdi-minus-circle-outline"></i> : <i className="zmdi zmdi-plus-circle-o"></i>
                                                                }
                                                                <span className="account-text">{account.account}</span>
                                                            </td>
                                                        </tr>
                                                        {account.isExpand && account.isBeginningAmount &&
                                                            <tr>
                                                                <td colSpan="5" className="table-sub-title">Beginning Balance</td>
                                                                <td className="text-center ">{numberWithCommas(account.beginningAmount)}</td>
                                                                <td colSpan="3"></td>
                                                            </tr>}
                                                        {account.isExpand && <React.Fragment>
                                                            {(account.data && account.data.length > 0) && account.data.map((item, j) => (
                                                                <React.Fragment key={j}>
                                                                    <tr className={item.vendor1099 ? 'greenrow' : ''} onClick={(e) => this.onRowEdit(e, i, j, item)}  >
                                                                        <td>{moment(item.date).format("MM/DD/YYYY")}</td>
                                                                        <td>{item.transactionType}</td>
                                                                        <td>
                                                                        {item.num}
                                                                            
                                                                        </td>
                                                                        <td>{item.name}</td>
                                                                       
                                                                        <td className="textOverFlow">{item.memoOrDescription}</td>
                                                                        <td className="text-center">{numberWithCommas(item.amount)}</td>
                                                                        <td>{item.newAccountName}</td>
                                                                        { item.newAccountName == null || item.newAccountName == "" ?<td></td> :
                                                                        <td onClick={(e) => e.stopPropagation()}>
                                                                         <label className="container101">
                                                                             {(this.state.SelectALL || selectedItem.length > 0 && (selectedItem.indexOf(item.id) > -1)) ?
                                                                                 <input className="second-check-box" checked="checked" type="checkbox" onClick={(e) => this.onCheckboxClick(e, item)} />
                                                                                 :
                                                                                 <input className="second-check-box" type="checkbox" onClick={(e) => this.onCheckboxClick(e, item)} />
                                                                             }
                                                                             <span className="checkmark101 green_CheckMark"></span>
                                                                         </label>
                                                                        </td>
                                                                        }
                                                                        
                                                                        <td>
                                                                            <div className="panel-commentCount col-md-2" >
                                                                                <img src="/vendors/Vector Smart Object.png" alt="" className={item.commentCount === 0 ? "disableCommentsOpacity" : ""} onClick={(e) => this.LoadComments(item)}></img>
                                                                                {item.unreviewCommentCount !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">{item.unreviewCommentCount}</span>}
                                                                            </div>
                                                                        </td>
                                                                        <td className="text-center"><img src="/vendors/Shape 1.png" alt="QBDlink" onClick={(e) => this.onLinkClick(e, i, j, item)}></img></td>

                                                                    </tr>
                                                                    {/* {this.state.editIndex1 === i && this.state.editIndex === j &&
                                                                        <React.Fragment>
                                                                            <tr className="editRowColor">
                                                                                <td colSpan="2">
                                                                                    <label>New Account</label>
                                                                                    <RSelect
                                                                                        required={true}
                                                                                        name="NewAccountID"
                                                                                        value={this.state.selectedAccount}
                                                                                        onChange={(e) => this.handalAccountDropdown(e, i, j, item)}
                                                                                        options={this.state.DisplayAccounts}
                                                                                        //defaultValue={{ value: 0, label: "select" }}
                                                                                        placeholder="Select"
                                                                                    />
                                                                                </td>
                                                                                <td colSpan="4">
                                                                                    <label>Memo/Description</label>
                                                                                    <Textarea className="placheholder-color"
                                                                                                        value={item.memoOrDescription} name="memoOrDescription"
                                                                                                        style={{ 'lineHeight': '20px', width: '100%', border: 'none', resize: 'none', padding: '6px', paddingLeft: '10px', borderRadius: '3px', border: '1px solid #ccc', overflow: 'hidden' }}
                                                                                                        resize={"false"} placeholder=""
                                                                                                        onChange={(e) => this.handalTextArea(e, i, j, item)} />
                                                                                </td>
                                                                             
                                                            
                                                       
                                                                                <td >
                                                                                    <div className="chiller_cb " style={{ marginTop: "20px" }}>
                                                                                        <input id="needsBetterDescription1" name="needsBetterDescription" type="checkbox" checked={item.needsBetterDescription} onChange={(e) => this.handalCheckbox(e, i, j, item)} />
                                                                                        <label for="needsBetterDescription1">
                                                                                            <p className="checkbox-title">NBD {item.needsBetterDescription}</p>
                                                                                        </label>
                                                                                        <span></span>
                                                                                    </div>
                                                                                </td>
                                                                                <td >
                                                                                    <div className="chiller_cb " style={{ marginTop: "20px" }}>
                                                                                        <input id="cpaReview1" name="cpaReview" type="checkbox" checked={item.cpaReview} onChange={(e) => this.handalCheckbox(e, i, j, item)} />
                                                                                        <label for="cpaReview1">
                                                                                            <p className="checkbox-title">CPA {item.cpaReview}</p>
                                                                                        </label>
                                                                                        <span></span>
                                                                                    </div>
                                                                                </td>
                                                                                <td colSpan="5">&nbsp;</td>
                                                                            </tr>
                                                                            <tr className="editRowColor">
                                                                                <td colSpan="9">
                                                                                    <div className="row">
                                                                                        <div className="col-md-5">
                                                                                            <div className="row">
                                                                                                <div className="col-md-3">
                                                                                                    <label className="input-title">Questions and Comments:</label>
                                                                                                </div>
                                                                                                <div className="col-md-9">
                                                                                                    <Textarea className="placheholder-color"
                                                                                                        value={item.accountantComment} name="accountantComment"
                                                                                                        style={{ 'lineHeight': '20px', width: '100%', border: 'none', resize: 'none', padding: '6px', paddingLeft: '10px', borderRadius: '3px', border: '1px solid #ccc', overflow: 'hidden' }}
                                                                                                        resize={"false"} placeholder=""
                                                                                                        onChange={(e) => this.handalTextArea(e, i, j, item)} />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-5">
                                                                                            <div className="row">
                                                                                                <div className="col-md-3">
                                                                                                    <label className="input-title">Internal Notes:</label>
                                                                                                </div>
                                                                                                <div className="col-md-9">
                                                                                                    <Textarea className="placheholder-color"
                                                                                                        value={item.internalNotes} name="internalNotes"
                                                                                                        style={{ 'lineHeight': '20px', width: '100%', border: 'none', resize: 'none', padding: '6px', paddingLeft: '10px', borderRadius: '3px', border: '1px solid #ccc', overflow: 'hidden' }}
                                                                                                        resize={"false"} placeholder=""
                                                                                                        onChange={(e) => this.handalTextArea(e, i, j, item)} />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-2">
                                                                                            <Button variant="raised" className="jr-btn jr-btn-sm bg-teal text-white" onClick={() => this.onRowUpdateClick(i, j, item)}>SAVE</Button>
                                                                                            <Button variant="raised" className="jr-btn jr-btn-sm bg-danger text-white" onClick={() => this.onRowCancelClick(i, j, item)}>CANCEL</Button>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </React.Fragment>
                                                                    } */}
                                                                     {this.state.editIndex1 === i && this.state.editIndex === j &&
                                                                        <tr >
                                                                            <td> <strong>Memo/Description </strong></td>
                                                                            <td colSpan="4">
                                                                                <textarea cols="50" value={item.memoOrDescription} onChange={(e) => this.ChangeDescription(e,i, j, item)}></textarea>
                                                                            </td>
                                                                            <td><strong>Updated By </strong></td>
                                                                            <td>
                                                                                <input type="text" onChange={(e) => this.GetEditBy(e,i, j, item)} />
                                                                            </td>
                                                                            <td colSpan="4">
                                                                                <div className="col-md-12">
                                                                                    <Button variant="raised" className="jr-btn jr-btn-sm bg-teal text-white" onClick={() => this.onRowSave(i,j, item)}>SAVE</Button>
                                                                                    <Button variant="raised" className="jr-btn jr-btn-sm bg-danger text-white" onClick={() => this.onRowCancel(i,j, item)}>CANCEL</Button>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                     }
                                                                </React.Fragment>
                                                            ))}
                                                            {(!account.data || account.data.length === 0) &&
                                                                <tr>
                                                                    <td className="text-center" colSpan="9">
                                                                    No issues here! Keep up the good work!
                                                   </td>
                                                                </tr>
                                                            }
                                                        </React.Fragment>}
                                                        {account.isExpand &&
                                                            <tr>
                                                                <td colSpan="5" className="table-sub-title">Total for {account.account}</td>
                                                                <td className="text-center">$
                                                       {/* total-amount-text */}
                                                                    {account.totalAmount != null ?
                                                                        <React.Fragment>
                                                                            {numberWithCommas(account.totalAmount)}
                                                                        </React.Fragment>
                                                                        : ""
                                                                    }
                                                                </td>
                                                                <td colSpan="5"></td>
                                                            </tr>}
                                                    </React.Fragment>
                                                ))}
                                                {this.state.lst_GL.length == 0 &&
                                                    <tr>
                                                        <td className="text-center" colSpan="10">
                                                        No issues here! Keep up the good work!
                                       </td>
                                                    </tr>
                                                }
                                            </React.Fragment>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </React.Fragment>
                    }
                </div>

            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        ledgers: state.ledgers,
        accounts: state.accounts.accountsData,
        UpdateDescription: state.ReportData.UpdateDescription
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        GetUncategorizeData_BES,
        Accounts_BES,
        LedgerUpdate_BES,
        UPDATE_DESCRIPTION_DATA_BES,
        UPDATE_ACCOUNT_APPROVED_DATA
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(UnCategorizedTran_Embedded);
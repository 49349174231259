import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import * as userAction from '../appRedux/actions/Auth';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import IntlMessages from '../util/IntlMessages';
import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

class ForgotPassword extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      userName : '',
      error:false,
      errorMessage:'',
      isValid:false
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (e) => {
     let userName =  e.target.value;
      this.setState({userName,error:false, errorMessage:''});
  };

  handleSubmit = (e) => {

    e.preventDefault();

    let userName = this.state.userName;
    let data = { userName }

    this.props.actions.user.ForgotPassword(data);

  };


  render() {
    const { userName, error , errorMessage} = this.state;
    return (
    <div style={{ display:'flex', width: '100%', 'backgroundImage': 'url(/vendors/compBack.png)'}}>
         <div
            className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
            <div className="app-login-main-content">

                <div className="app-logo-content d-flex align-items-center justify-content-center">
                    <Link className="logo-lg" to="/" title="Compliance">
                        <img style={{'maxWidth':220}} src="/vendors/Logo1.png" alt="Compliance" title="Compliance"/>
                    </Link>
                </div>

                <div className="app-login-content">
                    <div className="app-login-header mb-4">
                        <h1><IntlMessages id="appModule.forgotPassword"/></h1>
                    </div>

                    <div className="app-login-form">
                        <form onSubmit={ this.handleSubmit } method="post">
                            <fieldset>
                                <TextField
                                    label={<IntlMessages id="appModule.username"/>}
                                    fullWidth
                                    required={true}
                                    error={error}
                                    onChange={this.handleChange}
                                    defaultValue={userName}
                                    helperText={errorMessage}
                                    margin="normal"
                                    name="userName"
                                />
                            </fieldset>
                             <div className="mb-3 d-flex align-items-center justify-content-between" style={{marginTop:20}}>
                                    <Button type="submit"
                                     variant="contained" color="primary">
                                        <IntlMessages id="appModule.forgotPassword"/>
                                    </Button>

                                    <Link to="/signin">
                                        <IntlMessages id="appModule.signIn"/>
                                    </Link>
                                </div>
                        </form>
                    </div>
                </div>
            </div>
             <ToastContainer autoClose={3000} />
        </div>
      </div>
    );
  }
}


const mapDispatchToProps = dispatch => ({
    actions: {
        user: bindActionCreators(userAction, dispatch)
    }
});

export default connect(null, mapDispatchToProps)(ForgotPassword);

import React, { Component } from 'react';
import PaginationComponent from './../../../common/Pagination';
import { toast } from 'react-toastify';
import CircularProgress from './../../../../components/CircularProgress';
// import moment from 'moment';
import { GetQuestionList, ClosedQuestion,MarkAsCPAReview,MarkAsResolvedQueAndComm,AddQuestion } from "../../../../appRedux/actions/QuestionCommentsAction";
import { ReportType, ModuleType ,LinkType} from "../../../common/commonServices";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import AddQuestions from '../../../common/addQuestions';
import QuestionsAnsHistory from '../../../common/questionsAnsHistory';
import Badge from '@material-ui/core/Badge';
import Textarea from 'antd/lib/input/TextArea';

class OtherQueComm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            parantData: this.props.location.state ? this.props.location.state : null,
            QuesData: [],
            companyId: 0,
            PageNo: 1,
            PageSize: 10,
            ColorCode: "#46b29d",
            loading: false,
            SelectALL: false,
            SelectedRecords: [],
            isAddQuestions: false,
            isQueAnsHistory: false,
            IsClosed: false,
            stSearch: null,
            TotalRecords: 0,
            selectedQuestion: null,
            isClosedRequest: false,
            question: '',
            editIndex : -1,
            isEditQue:false
        }
    }

    ChangePageSize = (size) => {

        this.setState({ loading: true, PageSize: parseInt(size), PageNo: 1, SelectALL: false,
            SelectedRecords: [] }, () => {
            this.GetQuestions();
        });
    }

    ChangePage = (page) => {
        this.setState({ loading: true, PageNo: page, SelectALL: false,
            SelectedRecords: [] }, () => {
            this.GetQuestions();
        });
    }



    OnCPAReviewClick = () => {
        let selectedData = this.state.SelectedRecords;
        if (selectedData == null || selectedData.length === 0) {
            toast.error("Please select atleast 1 record for processing request");
            return false;
        }
        let body = {
            "ids": selectedData,
            "reportType": ReportType.GeneralQuestionComments,
            "companyID": this.state.companyId,
            "createdByName": "",
            "isRemoveFromNBD": false
          }
          
        this.setState({ loading: true }, () => {
            this.props.MarkAsCPAReview(body)
        });
    };

    OnAddQuestionsClick = () => {
        this.setState({ isAddQuestions: true });
    };

    onCloseAddQuestions = () => {
        this.setState({ isAddQuestions: false });
    };

    onCloseQueAnsHistory = () => {
        this.setState({ isQueAnsHistory: false });
    };

    OnSearchClick = () => {
        this.setState({
            loading: true
        }, () => {
            this.GetQuestions();
        });
    };

    onSortChange = (fieldName) => {
        if (this.state.sortField == fieldName) {
            let formate = this.state.sortFormat == 'A' ? 'D' : 'A';
            this.setState({ sortFormat: formate, loading: true }, () => {
                // this.GetReportData();
            });
        } else {
            this.setState({ sortField: fieldName, sortFormat: 'A', loading: true }, () => {
                // this.GetReportData();
            });
        }
    }

    onResolvedClick = (e) => {

       this.setState({ IsClosed: e.target.checked, loading: true }, () => { this.GetQuestions() })

    }


    onCheckboxClick = (e, item) => {
        e.stopPropagation();

        let existInArray = false;
        let data = this.state.SelectedRecords;
        if (data != null && data.length > 0 && data.indexOf(item.id) > -1) {
            existInArray = true;
        }

        if (e.target.checked) {
            if (!existInArray) {
                data.push(item.id);
            }
        }
        else {
            if (existInArray) {
                data.splice(data.indexOf(item.id), 1);
            }
        }
        let all = (data.length == this.state.QuesData.length) ? true : false;
        this.setState({ SelectedRecords: data, SelectALL: all });
    }

    SelectALL = (e) => {
        if (this.state.QuesData.length > 0) {
            if (e.target.checked) {
                let all = [];
                let data = this.state.QuesData;
                for (let i = 0; i < data.length; i++) {
                    all.push(data[i].id);
                }
                this.setState({ SelectALL: true, SelectedRecords: all });
            }
            else {
                this.setState({ SelectALL: false, SelectedRecords: [] });

            }
        }
    }

    OnRowClick = (data) => {
        this.setState({ isQueAnsHistory: true, selectedQuestion: data });
    }

    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('userData'));
        this.setState({
            companyId: user.companyId,
            loading: true
        }, () => {
            this.GetQuestions();
        });
    }

    componentWillReceiveProps(nextprops) {
        if (this.props.QuestionData !== nextprops.QuestionData) {
            if (nextprops.QuestionData.transactionStatus === 0) {
                let total = 0;
                if (nextprops.QuestionData.returnObject.length > 0) {
                    total = nextprops.QuestionData.returnObject[0].totalrows;
                }
                this.setState({ loading: false, QuesData: nextprops.QuestionData.returnObject, TotalRecords: total });
            }
            else {
                this.setState({ loading: false }, () => { toast.error(`${nextprops.QuestionData.resultMsg}`); });
            }
        } 

        if (this.props.ClosedUpdate !== nextprops.ClosedUpdate) {            
            if (nextprops.ClosedUpdate.transactionStatus === 0) {                                
                this.setState({ isClosedRequest: false,SelectALL:false,isQueAnsHistory:false, loading: false,SelectedRecords:[] }, () => { this.GetQuestions() });
                 toast.success("Request process successfully");
                //toast.success(`${nextprops.ClosedUpdate.resultMsg}`);
            }
            else {
                this.setState({ loading: false }, () => { toast.error(`${nextprops.ClosedUpdate.resultMsg}`); });
            }
        }

        if (nextprops.AddComman!== undefined && this.props.AddComman !== nextprops.AddComman) {
            if(this.state.isEditQue){
                if (nextprops.AddComman.transactionStatus === 0) {
                    toast.success("Question Updated Successfully");                
                    this.setState({loading:false,editIndex: -1,question:'',isEditQue:false},() => { this.GetQuestions();});
                }
                else 
                {
                    this.setState({loading:false,isEditQue:false},() => { toast.error(`${nextprops.AddComman.resultMsg}`);});
                }
            }
        }
    }
    GetQuestions = () => {

        let st = this.state;
        let body = {
            "companyID": st.companyId,
            "search": st.stSearch,
            "pageNumber": st.PageNo,
            "pageSize": st.PageSize,
            // "fromDate": "string",
            // "toDate": "string",            
            "status": st.IsClosed,
            "cpaReviewed": false,
            "reportType": ReportType.GeneralQuestionComments
        }
        this.props.GetQuestionList(body);

    }


    ResolvedQuestion = (id) => {        
        this.setState({ isClosedRequest: true, loading: true }, () => {
            this.props.ClosedQuestion(id);
        })
    }

    Reload = () => {
        this.setState({
            loading: true,
            isAddQuestions:false,
            isQueAnsHistory:false
        }, () => {
            this.GetQuestions();
        });
    }

    ChangeSearch = (e) => {
        this.setState({ stSearch: e.target.value })
    }

    UpdateItemCommentCount = (id) => {
        let data = this.state.QuesData;
        data.forEach(function (item) {

            if (item.id === id) {
                item.commentCount++
            }
        });

        this.setState({ MissingData: data });
    }


    UpdateQues = (data) => {
       
        let body = {
            "id": data.id,
            "question": this.state.question,
            "companyID": this.state.companyId
        }

        this.setState({ loading: true,isEditQue:true }, () => {
            this.props.AddQuestion(body);
        })

    }

    isExpand = (j,data) => {
        this.setState({ editIndex: j, question: data.question });
    }
    onRowCancelClick = () => {
        this.setState({ editIndex: -1,question:''});
    }

    handalTextArea = (e) => {
        this.setState({ question:  e.target.value });
    }

    OnBatchMarkAsResolved = () => {
        let selectedData = this.state.SelectedRecords;
        if (selectedData == null || selectedData.length === 0) {
            toast.error("Please select atleast 1 record for processing request");
            return false;
        }
        let body = {
            "ids": selectedData,
            "reportType": ReportType.GeneralQuestionComments,
            "companyID": this.state.companyId,
            "createdByName": "",
            "isRemoveFromNBD": false
          }
          
        this.setState({ loading: true }, () => {
            this.props.MarkAsResolvedQueAndComm(body)
        });
    };
    render() {
        let selectedItem = this.state.SelectedRecords;
        return (
            <React.Fragment>
                {this.state.isAddQuestions &&
                    <AddQuestions
                        onCloseAddQuestions={this.onCloseAddQuestions}
                        ColorCode={this.state.ColorCode}
                        CompanyId={this.state.companyId}
                        Reload={this.Reload}></AddQuestions>
                }
                {this.state.isQueAnsHistory &&
                    <QuestionsAnsHistory
                        onCloseQueAnsHistory={this.onCloseQueAnsHistory}
                        ColorCode={this.state.ColorCode}
                        QuestionDetails={this.state.selectedQuestion}
                        ReportType={ReportType.GeneralQuestionComments}
                        ModuleType={ModuleType.GeneralQuestion}
                        CompanyId={this.state.companyId}
                        IsEmbeddedPage={false}
                        ResolvedQuestion={this.ResolvedQuestion}
                        IsCLosed={this.state.IsClosed}
                        UpdateItemCommentCount={this.UpdateItemCommentCount}
                    ></QuestionsAnsHistory>
                }
                <div className="dashboardPage">
                    <div className="page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
                        <h2 className="title mb-3 mb-sm-0">{JSON.parse(localStorage.getItem('userData')) != null ? JSON.parse(localStorage.getItem('userData')).companyName : ""} | {LinkType.OtherQueComm}</h2>
                    </div>
                    <div className="padding-box app-wrapper">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div style={{ display: "flex" }}>
                                            <input type="text" className="form-control mr-2" placeholder="Search..." onChange={(e) => this.ChangeSearch(e)} />
                                            <Button variant="raised" className=" jr-btn graycolor text-white refresh-btn" onClick={() => this.OnSearchClick()}>Search</Button>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-4">
                                        <div className="checkbox pointer float-right" >
                                            <div className="chiller_cb">
                                                <input id="TFB" type="checkbox" onClick={(e) => this.onResolvedClick(e)} />
                                                <label for="TFB">
                                                    <p className="checkbox-title">Show Resolved</p>
                                                    <p className="parag-text"></p>
                                                </label>
                                                <span></span>
                                            </div>
                                        </div>                                      
                                    </div> */}
                                    <div className="col-md-8">
                                        <div className="float-right">
                                            <Button variant="raised" className=" jr-btn graycolor text-white refresh-btn" onClick={() => this.OnCPAReviewClick()}>Mark as CPA Review</Button>
                                            <Button variant="raised" className=" jr-btn graycolor text-white refresh-btn" onClick={() => this.OnBatchMarkAsResolved()}>Mark as Resolved</Button>
                                            <Button variant="raised" className=" jr-btn text-white greencolor refresh-btn" onClick={() => this.OnAddQuestionsClick()}>Add Questions</Button>
                                        </div>
                                        <div className="checkbox pointer float-right" >
                                            <div className="chiller_cb">
                                                <input id="TFB" type="checkbox" onClick={(e) => this.onResolvedClick(e)} />
                                                <label for="TFB">
                                                    <p className="checkbox-title">Show Resolved</p>
                                                    <p className="parag-text"></p>
                                                </label>
                                                <span></span>
                                            </div>
                                        </div>   
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.loading ?
                            <CircularProgress></CircularProgress>
                            :

                            <React.Fragment>
                                <div className="table-responsive-material">
                                    <table className="table tablebox account-reconcilliation">
                                        <thead  >
                                            <tr style={{ backgroundColor: this.state.ColorCode }}>
                                                <th style={{ width: "2%" }}>Sr.No</th>
                                                <th className="td-width">Questions</th>
                                                <th className="td-width" > Last Comment </th>
                                                <th className="td-width-90 text-center"><img src="/vendors/WhiteCommnet.png" alt="comments" /></th>
                                                <th className="td-width-90">
                                                    <label className="container101 header-check ">
                                                        {this.state.SelectALL ?
                                                            <input className="second-check-box" checked="checked" type="checkbox" onClick={(e) => this.SelectALL(e)} />
                                                            :
                                                            <input className="second-check-box" type="checkbox" onClick={(e) => this.SelectALL(e)} />
                                                        }
                                                        <span className="checkmark101 white_border greenMark "></span>
                                                    </label>
                                                </th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(this.state.QuesData && this.state.QuesData.length > 0) && this.state.QuesData.map((item, j) => (
                                                <React.Fragment key={j}>
                                                    {<tr onClick={() => this.isExpand(j,item)}>
                                                        <td>
                                                        {this.state.PageNo===1? j + 1:((this.state.PageNo*10)-10)+(j+1) }
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        {
                                                            item.accountId > 0 ?
                                                            <Badge badgeContent={"BS"} color="primary" style={{marginBottom : "5px"}}>
                                                            </Badge> 
                                                            :""
                                                        }
                                                        </td>
                                                        <td>
                                                        {
                                                            item.accountId > 0 ? 
                                                              item.accountNumber ?   
                                                                "Account " + item.accountNumber + " " + item.accountName + " : " + item.question 
                                                                : "Account " + item.accountName + " : " + item.question                           
                                                            : item.question
                                                        }
                                                        </td>
                                                        <td>{item.comment}</td>
                                                        <td className="text-center" >
                                                            <div className="panel-commentCount">
                                                                <img src="/vendors/Vector Smart Object.png" onClick={() => this.OnRowClick(item)} alt="comments" className={item.commentCount === 0 ? "disableCommentsOpacity" : ""}></img>

                                                                {item.unreadComments !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">{item.unreadComments}</span>}
                                                            </div>
                                                        </td>
                                                        <td onClick={(e) => e.stopPropagation()}>
                                                            <label className="container101">
                                                                {(this.state.SelectALL || selectedItem.length > 0 && (selectedItem.indexOf(item.id) > -1)) ?
                                                                    <input className="second-check-box" checked="checked" type="checkbox" onClick={(e) => this.onCheckboxClick(e, item)} />
                                                                    :
                                                                    <input className="second-check-box" type="checkbox" onClick={(e) => this.onCheckboxClick(e, item)} />
                                                                }
                                                                <span className="checkmark101 green_CheckMark"></span>
                                                            </label>
                                                        </td>

                                                    </tr>}

                                                    {this.state.editIndex === j &&
                                                                <React.Fragment>
                                                                    <tr className="editRowColor">
                                                                        <td colSpan="9">
                                                                            <div className="row">
                                                                                <div className="col-md-5">
                                                                                    <div className="row">
                                                                                        <div className="col-md-3">
                                                                                            <label className="input-title">Questions:</label>
                                                                                        </div>
                                                                                        <div className="col-md-9">
                                                                                            <Textarea className="placheholder-color"
                                                                                                value={this.state.question} name="question"
                                                                                                style={{ 'lineHeight': '20px', width: '100%', border: 'none', resize: 'none', padding: '6px', paddingLeft: '10px', borderRadius: '3px', border: '1px solid #ccc', overflow: 'hidden' }}
                                                                                                resize={"false"} placeholder=""
                                                                                                onChange={(e) => this.handalTextArea(e)} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {/* <div className="col-md-5">
                                                                                    <div className="row">
                                                                                        <div className="col-md-3">
                                                                                            <label className="input-title">Internal Notes:</label>
                                                                                        </div>
                                                                                        <div className="col-md-9">
                                                                                            <Textarea className="placheholder-color"
                                                                                                value={item.internalNotes} name="internalNotes"
                                                                                                style={{ 'lineHeight': '20px', width: '100%', border: 'none', resize: 'none', padding: '6px', paddingLeft: '10px', borderRadius: '3px', border: '1px solid #ccc', overflow: 'hidden' }}
                                                                                                resize={"false"} placeholder=""
                                                                                                onChange={(e) => this.handalTextArea(e, i, j, item)} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div> */}
                                                                                <div className="col-md-2">
                                                                                    <Button variant="raised" className="jr-btn jr-btn-sm bg-teal text-white" onClick={() => this.UpdateQues(item)}>SAVE</Button>
                                                                                    <Button variant="raised" className="jr-btn jr-btn-sm bg-danger text-white" onClick={() => this.onRowCancelClick(j, item)}>CANCEL</Button>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </React.Fragment>
                                                            }
                                                </React.Fragment>
                                            ))}

                                            {this.state.QuesData === null || this.state.QuesData.length === 0 ?
                                                <tr>
                                                    <td className="text-center" colSpan="8">
                                                    No issues here! Keep up the good work!
                                                    </td>
                                                </tr>
                                                : ""}
                                        </tbody>
                                    </table>
                                </div>
                                <PaginationComponent
                                    selectedPageNo={this.state.PageNo}
                                    selectedSize={this.state.PageSize}
                                    TotalRecords={this.state.TotalRecords}
                                    ChangePageSize={this.ChangePageSize}
                                    ChangePage={this.ChangePage}
                                    CurrentRecords={this.state.QuesData.length}
                                >
                                </PaginationComponent>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        QuestionData: state.QuestionCommentsData.QuestionData,
        ClosedUpdate: state.QuestionCommentsData.ClosedUpdate,
        AddComman: state.QuestionCommentsData.AddComman
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        ClosedQuestion,
        MarkAsCPAReview,
        GetQuestionList,
        AddQuestion,
        MarkAsResolvedQueAndComm
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(OtherQueComm);
import React, { Component } from "react";
import Grid from '@material-ui/core/Grid';
// import IntlMessages from "../../../../util/IntlMessages";
// import SearchIcon from '@material-ui/icons/Search';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
// import ReactTable from "react-table";
import "react-table/react-table.css";
import CircularProgress from '../../../../components/CircularProgress';
import SearchBox from '../../../../components/SearchBox';
import { Accountants } from '../../../../appRedux/actions/accountantsAction';
import Button from '@material-ui/core/Button';
import EditAccoutantForm from '../../../../Forms/EditAccoutantForm';
import AddAccountantForm from '../../../../Forms/AddAccountantForm';
import { companyDetail, getUsers } from '../../../../appRedux/actions/companiesAction';
// import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Table } from 'antd';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

class AccountantPage extends Component {
  constructor(props) {
    super(props);
    let column = [{
      title: 'First Name',
      dataIndex: 'firstName',
      className: 'table-heading',

      width: 200
    }, {
      title: 'Last Name',
      dataIndex: 'lastName',
      className: 'table-heading',
      width: 200
    }, {
      title: 'Email',
      dataIndex: 'userName',
      className: 'table-heading',
      sorter: true,
      width: 200
    }, {
      title: 'Assigned companies',
      dataIndex: 'assignAccountStr',
      className: 'table-heading',
      sorter: true,

    }, {
      title: 'Actions',
      dataIndex: 'userName',
      className: 'table-heading',
      sorter: false,
      width: 100,
      render: (text, record) => <span>
        <i className="zmdi zmdi-edit zmdi-hc-lg mr-4 pointer" onClick={() => this.handleEditModel(record)}></i>
        {/* <Button className="" color="primary" onClick={() => this.handleEditModel(record)}>Edit</Button> */}
      </span>,
    }
    ];


    this.state = {
      columns: column,
      accountantsData: [],
      companiesData: [],
      usersData: [],
      CompanyId: '',
      loading: true,
      searchText: '',
      edit: false,
      isAdd: false,
      search: false,
      selectedUserId: '',
      selectedUser: ''
    }
    this.handleAddModel = this.handleAddModel.bind(this);

  }

  componentDidMount() {
    let user = JSON.parse(localStorage.getItem('userData'));

    const CompanyId = user.companyId;
    this.setState({ CompanyId })
    this.props.companyDetail();
    this.props.Accountants();
    //this.props.getUsers(CompanyId);
  }
  componentWillReceiveProps(nextProps) {

    if (nextProps.accountant!==undefined && nextProps.accountant !== this.props.accountant) {

      this.setState({ accountantsData: nextProps.accountant.data });
    }
    if (nextProps.company!==undefined && nextProps.company !== this.props.company) {
      this.setState({ loading: false, companiesData: nextProps.company });
    }
    //  if (nextProps.users !== this.props.users) {
    //     this.setState({ ,usersData: nextProps.users });
    // }
  }

  handleChange = (text) => {
    if (text) {
      this.setState({ searchText: text.trim(), search: true })
    } else {
      this.setState({ searchText: '', search: false })
    }
  }
  renderEditable = (cellInfo) => {
    return (
      <div
        style={{ backgroundColor: "#fafafa" }}
        contentEditable
        suppressContentEditableWarning
        onBlur={e => {
          const data = [...this.state.accountantsData];
          data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
          this.setState({ accountantsData: data });
        }}
        dangerouslySetInnerHTML={{
          __html: this.state.accountantsData[cellInfo.index][cellInfo.column.id]
        }}
      />
    );
  }
  handleAddModel = () => {
    this.setState({ isEdit: false, isAdd: true });
  }

  handleSave = (state) => { this.setState({ isEdit: state, isAdd: state }) }


  handleEditModel = (record) => {

    this.setState({ selectedUserId: record.userId });
    var id = record.userId
    var data = this.state.accountantsData;

    if (id != 0) {
      const selectedUser = _.find(data, { userId: id });
      let comp = [];
      selectedUser.companies.map(val => {
        comp.push({
          value: val.companyId,
          label: val.name
        });
      });

      this.setState({
        isEdit: true,
        CompanyId: this.state.CompanyId,
        selectedUser: selectedUser,
        selectedCompanies: { value: comp }
      });
    }
  }

  handleCancel = () => {

    this.setState({ isEdit: false, isAdd: false });
  };


  render() {
    const { searchText, search } = this.state;
    let { accountantsData } = this.state;

    if (search) {
      accountantsData = accountantsData.length && accountantsData.filter(obj => {
        if (obj.firstName.toLowerCase().includes(searchText.toLowerCase()) === true) {
          return obj;
        }
      })
    }

    let filteredData = accountantsData.length && accountantsData.map((d, index) => ({
      key: index,
      assignAccountStr: Array.prototype.map.call(d.companies, s => s.name).toString(),
      ...d
    }))

    return (
      <div className="vendorsPage" style={{ padding: 15 }}>

        {this.state.loading == false && this.state.isEdit ? <EditAccoutantForm
          handleSave={this.handleSave}
          visible={this.state.isEdit}
          handleCancel={this.handleCancel}
          companyId={this.state.CompanyId}
          userId={this.state.selectedUserId}
          companiesData={this.state.companiesData}
          selectedItem={this.state.selectedUser}
          selectedCompanies={this.state.selectedCompanies} /> : ''}

        {this.state.loading == false && this.state.isAdd ? <AddAccountantForm
          handleSave={this.handleSave}
          accountantsData={this.state.accountantsData}
          visible={this.state.isAdd}
          handleCancel={this.handleCancel}
          usersData={this.state.usersData}
          companiesData={this.state.companiesData}
        /> : ''}
        <Grid
          container
          spacing={24}
          style={{ 'marginBottom': 10 }}
        >
          <Grid item xs={12} sm={6}>
            <h3 className="user-title">Accountants</h3>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid
              container
              spacing={8}
              direction="row"
              justify="flex-end"
              alignItems="flex-end"
            >
              <Grid item  >
                <SearchBox
                  styleName="d-none d-lg-block"
                  placeholder="Search... "
                  value={searchText}
                  onChange={(e) => { this.handleChange(e.target.value) }}
                  style={{ background: '#eeeeeeb8' }}
                />
              </Grid>
              <Grid item  >
                <Button variant="contained" color="primary" onClick={this.handleAddModel}>
                  Assigned companies
                        </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {this.state.loading
          ?
          <CircularProgress />
          :
          <Table
            className="table-layout"
            columns={this.state.columns}
            dataSource={filteredData}
            bordered={true}
            size={"small"}
            loading={this.state.loading}
            pagination={false}
            // scroll={{ y: window.innerHeight - 301 }}
          />
        }
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    accountant: state.accountants.accountantsData,
    company: state.companyReducer.companies,
    users: state.companyReducer.users,
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    Accountants,
    companyDetail,
    getUsers
  }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(AccountantPage);

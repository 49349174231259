import axios from 'axios';
import history from '../../browserHistory';
// import cookies from '../cookies';
import * as config from '../../env-config.json';
export const baseUrl = `${config.api}/`;
//export const baseUrl = "http://localhost:5000/";


const baseService = axios.create({
    baseURL: baseUrl
});


baseService.addAuthToken = () => {
    let user = JSON.parse(localStorage.getItem('userData'));
    baseService.defaults.headers.common['Authorization'] = 'bearer '+`${user.Authorization}`;
};

baseService.removeAuthToken = () => {
    localStorage.removeItem('userData');
    baseService.defaults.headers.common['Authorization'] = '';
    history.push('/signin');
};

baseService.interceptors.response.use((config) => {
    return config;
}, (error) => {
    if (error.response.status === 401) {
        baseService.removeAuthToken();
    }
    return window.Promise.reject(error);
});

if (localStorage.getItem('userData')) {
    baseService.addAuthToken();
}

export default baseService;

import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from 'react-router-dom';
import moment from 'moment';
import { getCompnay } from './../../../appRedux/actions/companiesAction';
import PaginationComponent from "./../../../../src/app/common/Pagination";
import { bindActionCreators } from 'redux';
import { GET_CompanyDashboard_PayrollEmployeeReportDetailList_BES } from "./../../../appRedux/actions/ReportDetailAction";
import CircularProgress from './../../../components/CircularProgress';
import { ReportType,Base64, LinkType } from "./../../../app/common/commonServices";
import queryString from 'query-string';
import RefreshDataModal from '../../../components/RefreshDataModal'


class EmpVehicle_Embedded extends Component {

    constructor(props) {
        super(props)
        let endDate = new Date();
        let startDate = new Date(endDate.getFullYear(), 0, 1);

        if (endDate.getMonth() <= 2) {
            startDate = new Date(startDate.getFullYear() - 1, 0, 1);
            endDate = new Date(endDate.getFullYear() - 1, 11, 31);
        }
        this.state = {
            parantData: this.props.location.state ? this.props.location.state : null,
            MissingData: [],
            companyId: 0,
            sortField: 'name',
            sortFormat: "D",
            PageNo: 1,
            PageSize: 10,
            ColorCode: "#f0ca4c",
            IsCommentModelOpen: false,
            sDate:startDate,// new Date(2019, 0, 1),
            eDate:endDate,// new Date(),
            loading: false,
            TotalRecords: 0,
            IsEmbeddedPage: true,
            HomeLink: "",
            companyName: "",
            isRefreshDataModalOpen:false
        }
    }


    onSortChange = (fieldName) => {
        if (this.state.sortField == fieldName) {
            let formate = this.state.sortFormat == 'A' ? 'D' : 'A';
            this.setState({ sortFormat: formate, loading: true }, () => {
                this.GetReportData();
            });
        } else {
            this.setState({ sortField: fieldName, sortFormat: 'A', loading: true }, () => {
                this.GetReportData();
            });
        }
    }

    refreshData = () => {
        if (!this.state.loading) {
            this.setState({ loading: true,isRefreshDataModalOpen:false }, () => {
                this.GetReportData(true);
            });
        }
    }

    onRefreshDataClick = () => {
        
        this.setState({isRefreshDataModalOpen:true})
    }

    handleRefreshDataModal=()=>{
        this.setState({isRefreshDataModalOpen:false})
      }

    ChangePageSize = (size) => {

        this.setState({ loading: true, PageSize: parseInt(size), PageNo: 1 }, () => {
            this.GetReportData();
        });
    }

    ChangePage = (page) => {
        this.setState({ loading: true, PageNo: page, }, () => {
            this.GetReportData();
        });
    }


    getCompanyId = () => {
        let value = queryString.parse(this.props.location.search);
        localStorage.setItem("q", JSON.stringify(value));
        let data = localStorage.getItem("q");

        if (data != null && data != undefined) {
            data = JSON.parse(data);
            this.setState({
                companyId: parseInt(Base64.decode(data.q)),
                companyName:Base64.decode(data.p),
                loading: true
            }, () => {
                this.GetReportData();
                // this.props.getCompnay(parseInt(Base64.decode(data.q)));
            });
        }
        else {
            this.setState({ loading: false });
        }
    }

    componentDidMount() {
        let value = queryString.parse(this.props.location.search);

        // localStorage.setItem("homeURL", window.location.href.replace(window.location.origin, ""))
        let dashboardURl = localStorage.getItem("homeURL");
        this.setState({
            //loading: true,
            HomeLink: dashboardURl ? dashboardURl : "?q="+ value.q + "&p=" + value.p
        }, () => {
            this.getCompanyId();
        });
    }

    GetReportData = (IsRefresh = false) => {
        let state = this.state;
        let body = {
            companyID: state.companyId,
            reportType: ReportType.SubmitEMPVehicleUse,
            fromDate: moment(state.sDate).format("MM/DD/YYYY"),
            toDate: moment(state.eDate).format("MM/DD/YYYY"),
            sortField: state.sortField,
            sortFormat: state.sortFormat,
            pageNumber: state.PageNo,
            pageSize: state.PageSize,
            needUpdatQBORecords: IsRefresh
        };

        this.props.GET_CompanyDashboard_PayrollEmployeeReportDetailList_BES(body);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.company !== this.props.company) {
            let showAdd = true;
            if (nextProps.company.payrollSoftware === "QuickBooks Payroll" || nextProps.company.payrollSoftware === "QuickBooks Full Service") {
                showAdd = false;
            }
            this.setState({ loading: false, company: nextProps.company, ShowComplete: showAdd });
        }
        if (nextProps.ReportData !== this.props.ReportData) {
            if (nextProps.ReportData.transactionStatus === 0) {
                var totalrecords = 0;
                if (nextProps.ReportData.returnObject != null && nextProps.ReportData.returnObject.length > 0) {
                    totalrecords = nextProps.ReportData.returnObject[0].totalrows
                }                
                this.setState({ MissingData: nextProps.ReportData.returnObject, TotalRecords: totalrecords, PageSize: this.state.PageSize, PageNo: this.state.PageNo, loading: false });
            }
            else {
                toast.error(nextProps.ReportData.resultMsg);
                this.setState({ loading: false });
            }
        }


    }

    render() {
        return (
            <div className="dashboardPage embeded-dashboard">
                <RefreshDataModal visible={this.state.isRefreshDataModalOpen}
                 handlePopUpClose={this.handleRefreshDataModal} refreshData={this.refreshData}/>
                <ToastContainer autoClose={3000} />
                {this.state.loading ? <CircularProgress></CircularProgress>
                    :
                    <React.Fragment>
                        <div className=" page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
                            <h2 className="mb-3 mb-sm-0">{this.state.companyName} | {LinkType.EmployeeVehicleForm}</h2>
                            <Link className="home-link" to={this.state.HomeLink} > Home </Link>
                        </div>
                        <div className="padding-box app-wrapper bgcolor">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="float-right">
                                        <Button variant="raised" style={{ backgroundColor: this.state.ColorCode }} className="jr-btn text-white refresh-btn" href='https://vps.churchcpa.com/docs/accounting/Employee_Use_of_Vehicle.pdf' target='_blank' >
                                          <i className="zmdi zmdi-download "></i>&nbsp; Download Form
                                        </Button>
                                        <Button variant="raised" style={{ backgroundColor: this.state.ColorCode }} className="jr-btn text-white refresh-btn" onClick={() => this.onRefreshDataClick()}  ><i className="zmdi zmdi-refresh "></i> Refresh Data</Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive-material">
                                <table className="table tablebox account-reconcilliation table-hover">
                                    <thead>
                                        <tr style={{ backgroundColor: this.state.ColorCode }}>
                                            <th className={`td-width  ${this.state.sortField == 'name' ? this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('name')}><p className="table-th-title"> Employee Name</p> </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(this.state.MissingData && this.state.MissingData.length > 0) && this.state.MissingData.map((item, j) => (
                                            <React.Fragment key={j}>
                                                {<tr>
                                                    <td >{item.name}</td>
                                                </tr>
                                                }
                                            </React.Fragment>
                                        ))}

                                        {this.state.MissingData.length == 0 ?
                                            <tr>
                                                <td className="text-center">
                                                No issues here! Keep up the good work!
                                                    </td>
                                            </tr>
                                            : ""}
                                    </tbody>
                                </table>
                            </div>
                            <PaginationComponent
                                selectedPageNo={this.state.PageNo}
                                selectedSize={this.state.PageSize}
                                TotalRecords={this.state.TotalRecords}
                                ChangePageSize={this.ChangePageSize}
                                ChangePage={this.ChangePage}
                                CurrentRecords={this.state.MissingData.length}
                            >
                            </PaginationComponent>
                        </div>
                    </React.Fragment>
                }
            </div>
        )
    }



}

const mapStateToProps = (state) => {
    return {
        ReportData: state.ReportData.ReportData,
        company: state.companyReducer.companyDeatils
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        GET_CompanyDashboard_PayrollEmployeeReportDetailList_BES,
        getCompnay
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(EmpVehicle_Embedded);
import {
    COMPANY_ERROR,
    SHOW_MENU, COMPANY_GET, COMPANY_STATUS,
    USERS_GET, COMPANY_ADD, SET_COMPANYID,
    USERS_CHECK, CHECK_FAILED, ADDUSER_ERROR,
    USER_STATUS, ADDUSER_SUCCESS,
    SINGLE_COMPANY, DELETE_USER,
    ACCOUNT_SOFTWARE,
    STATES_GET,
    PAYROLL_SOFTWARE,
    GET_ACCOUNT_ASYNC_BY_COMPANY,
    UPDATE_COMPANY_TAXABLE_WAGES_ACCOUNT_ASYNC,
    GET_TAXABLE_WAGES,
    GET_WAGES_QBO_UPDATE,
    GET_WAGES_ARCHIVED_DATA
} from '../actions/companiesAction';

const initial_state = {
    companies: '',
    states: [],
    users: null,
    insertCompanies: [],
    companyId: 0,
    companyName: '',
    checkName: [],
    companyDeatils: "",
    error: {},
    ShowMenu: '',
    accountingSoftwares: [],
    payrollSoftwares: [],
    accountAsyncByCompany: null,
    updateaccountAsyncByCompany: null,
    taxableWagesData: null,
    loading: false,
    wagesUpdate: null,
    wagesArchiveData: null
};
export default function authReducer(state = initial_state, action) {
    switch (action.type) {
        case STATES_GET:
            return { ...state, states: action.payload.data }
        case SINGLE_COMPANY: {
            return { ...state, companyDeatils: action.payload.data }
        }
        case ACCOUNT_SOFTWARE:
            return { ...state, accountingSoftwares: action.payload.data }
        case PAYROLL_SOFTWARE:
            return { ...state, payrollSoftwares: action.payload.data };
        case SHOW_MENU:
            return { ...state, ShowMenu: action.payload }
        case COMPANY_GET:
            return { ...state, companies: action.payload.data, reload: false };
        case USERS_GET:
            return { ...state, users: action.payload.data, checkName: [], reload: false };
        case USERS_CHECK:
            return { ...state, checkName: action.payload.data };
        case CHECK_FAILED:
            return { ...state, checkName: [] };
        case COMPANY_ADD:
            return { ...state, insertCompanies: [...state.insertCompanies, action.payload.data[0]] }
        case COMPANY_ERROR:
            return { ...state, error: action.payload };
        case SET_COMPANYID:
            return { ...state, companyId: action.payload.id, companyName: action.payload.name };
        case ADDUSER_ERROR:
            return { ...state, reload: true, loading: false, error: action.payload.data };
        case 'ADDUSER_START':
            return { ...state, loading: true };
        case ADDUSER_SUCCESS:
            return { ...state, reload: true, loading: false };
        case DELETE_USER:
            return { ...state, reload: true };
        case COMPANY_STATUS:
            return { ...state, reload: true };
        case GET_TAXABLE_WAGES:
            return { ...state, taxableWagesData: action.payload.data, reload: true };
        case GET_WAGES_ARCHIVED_DATA:
            return { ...state, wagesArchiveData: action.payload.data, reload: true };
        case USER_STATUS:
            return { ...state, reload: true };
        case GET_ACCOUNT_ASYNC_BY_COMPANY:
            return { ...state, accountAsyncByCompany: action.payload.data, reload: true };
        case UPDATE_COMPANY_TAXABLE_WAGES_ACCOUNT_ASYNC:
            return { ...state, updateaccountAsyncByCompany: action.payload.data, reload: true };
        case GET_WAGES_QBO_UPDATE:
            return { ...state, wagesUpdate: action.payload.data, reload: true };
        default:
            return state;
    }
}

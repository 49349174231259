import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import CircularProgress from './../../../../components/CircularProgress';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import { GET_EMPPAYROLL_REPORT_DATA,MarkAsIgnoreResolveExcessHousingAllowanceTxn,RevertMarkAsUnIgnoreResolveExcessHousingAllowanceTxn } from '../../../../appRedux/actions/ReportDetailAction';
import { ReportType,numberWithCommas ,LinkType} from '../../../common/commonServices'
import { toast } from 'react-toastify';
import PaginationComponent from './../../../common/Pagination';
// import { numericLiteral } from '@babel/types';

class HousingAllowanceIssues extends Component {

    constructor(props) {
        super(props)
        let endDate = new Date();
        let startDate = new Date(endDate.getFullYear(), 0, 1);

        if (endDate.getMonth() <= 2) {
            startDate = new Date(startDate.getFullYear() - 1, 0, 1);
            endDate = new Date(endDate.getFullYear() - 1, 11, 31);
        }

        this.state = {
            parantData: this.props.location.state ? this.props.location.state : null,
            MissingData: [],
            companyId: 0,
            sortField: 'name',
            sortFormat: "A",
            PageNo: 1,
            PageSize: 10,
            ColorCode: "#f0ca4c",
            sDate:startDate,// new Date(2019, 0, 1),
            eDate:endDate,// new Date(),
            loading: false,
            TotalRecords: 0,
            IgnoredSelectedRecords: [],
            UnIgnoredSelectedRecords: [],
            SelectAllIgnore: false,
            SelectAllUnIgnore: false,
            IgnoredData:[],
            UnIgnoredData:[],
            IgnoredTotalRecords: 0,
            UnIgnoredTotalRecords: 0,
        }
    }

    onSortChange = (fieldName) => {
        if (this.state.sortField === fieldName) {
            let formate = this.state.sortFormat == 'A' ? 'D' : 'A';
            this.setState({ sortFormat: formate, loading: true }, () => {
                this.GetReportData();
            });
        } else {
            this.setState({ sortField: fieldName, sortFormat: 'A', loading: true }, () => {
                this.GetReportData();
            });
        }
    }


    onRefreshDataClick = () => {
        if (!this.state.loading) {
            this.setState({ loading: true }, () => {
                this.GetReportData(true);
            });
        }
    }

    GetReportData = (IsRefresh = false) => {
        let state = this.state;
        let body = {
            companyID: state.companyId,
            reportType: ReportType.HousingAllwance,
            fromDate: moment(state.sDate).format("MM/DD/YYYY"),
            toDate: moment(state.eDate).format("MM/DD/YYYY"),
            sortField: state.sortField,
            sortFormat: state.sortFormat,
            pageNumber: state.PageNo,
            pageSize: state.PageSize,
            needUpdatQBORecords: IsRefresh
        };

        this.props.GET_EMPPAYROLL_REPORT_DATA(body);
    }

    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('userData'));
        this.setState({
            companyId: user.companyId,
            loading: true
        }, () => {
            this.GetReportData();
        });
    }

    ChangePageSizeIgnored = (size) => {

        this.setState({ loading: true, PageSize: parseInt(size), PageNo: 1 }, () => {
            this.GetReportData();
        });
    }

    ChangePageSizeUnIgnored = (size) => {

        this.setState({ loading: true, PageSize: parseInt(size), PageNo: 1 }, () => {
            this.GetReportData();
        });
    }

    ChangePage = (page) => {
        this.setState({ loading: true, PageNo: page, }, () => {
            this.GetReportData();
        });
    }
    componentWillReceiveProps(nextProps) {
        
        if (nextProps.ReportData !== this.props.ReportData) {
            if (nextProps.ReportData.transactionStatus === 0) {
                var totalrecords = 0;
                var Ignoredtotalrecords = 0;
                var Unignoredtotalrecords = 0;
                var Ignoredrecords = [];
                var Unignoredrecords = [];
                if (nextProps.ReportData.returnObject != null) {
                    //totalrecords = nextProps.ReportData.returnObject[0].totalrows;
                    Ignoredrecords = nextProps.ReportData.returnObject.ignoredResolveExcessHousingAllowanceData;
                    Unignoredrecords = nextProps.ReportData.returnObject.unIgnoredResolveExcessHousingAllowanceData;
                    if(Ignoredrecords !=null && Ignoredrecords.length > 0){
                        Ignoredtotalrecords = nextProps.ReportData.returnObject.ignoredResolveExcessHousingAllowanceData[0].totalrows;
                    }
                    if(Unignoredrecords !=null && Unignoredrecords.length > 0){
                        Unignoredtotalrecords = nextProps.ReportData.returnObject.unIgnoredResolveExcessHousingAllowanceData[0].totalrows;
                    }
                }
                this.setState({
                    MissingData: nextProps.ReportData.returnObject,
                    TotalRecords: totalrecords,
                    PageSize: this.state.PageSize,
                    PageNo: this.state.PageNo, loading: false, SelectALL: false,
                    SelectAllIgnore: false, IgnoredSelectedRecords: [],IgnoredData:Ignoredrecords,IgnoredTotalRecords: Ignoredtotalrecords,
                    SelectAllUnIgnore: false, UnIgnoredSelectedRecords: [] ,UnIgnoredData:Unignoredrecords,UnIgnoredTotalRecords: Unignoredtotalrecords
                });
            }
            else {
                toast.error(nextProps.ReportData.resultMsg);
                this.setState({ loading: false });
            }
        }

        if (nextProps.UpdateDescription !== this.props.UpdateDescription) {
            if (nextProps.UpdateDescription.transactionStatus === 0) {
                this.setState({
                    loading: true, editData: null, editIndex: -1,SelectAllIgnore: false, IgnoredSelectedRecords: [] ,
                    SelectAllUnIgnore: false, UnIgnoredSelectedRecords: [] 
                }, () => {
                    toast.success(`${nextProps.UpdateDescription.resultMsg}`);
                    this.GetReportData();
                });
            }
            else {
                this.setState({
                    loading: false,SelectAllIgnore: false, IgnoredSelectedRecords: [],
                    SelectAllUnIgnore: false, UnIgnoredSelectedRecords: [] 
                }, () => {
                    toast.error(`${nextProps.UpdateDescription.resultMsg}`);
                });
            }
        }
    }

    SelectAllIgnored = (e) => {
        if (this.state.IgnoredData.length > 0) {
            if (e.target.checked) {
                let all = [];
                let data = this.state.IgnoredData;
                for (let i = 0; i < data.length; i++) {
                    all.push(data[i].employeeID);
                }
                this.setState({ SelectAllIgnore: true, IgnoredSelectedRecords: all });
            }
            else {
                this.setState({ SelectAllIgnore: false, IgnoredSelectedRecords: [] });

            }

        }

    }

    onIgnoredCheckboxClick = (e, item) => {
        
        e.stopPropagation();
        let existInArray = false;
        let data = this.state.IgnoredSelectedRecords;
        if (data != null && data.length > 0 && data.indexOf(item.employeeID) > -1) {
            existInArray = true;
        }

        if (e.target.checked) {
            if (!existInArray) {
                data.push(item.employeeID);
            }
        }
        else {
            if (existInArray) {
                data.splice(data.indexOf(item.employeeID), 1);
            }
        }

        let all = (data.length == this.state.IgnoredData.length) ? true : false;
        this.setState({ IgnoredSelectedRecords: data, SelectAllIgnore: all, editIndex: -1 });

    }

    onUnIgnoredCheckboxClick = (e, item) => {
        e.stopPropagation();
        let existInArray = false;
        let data = this.state.UnIgnoredSelectedRecords;
        if (data != null && data.length > 0 && data.indexOf(item.employeeID) > -1) {
            existInArray = true;
        }

        if (e.target.checked) {
            if (!existInArray) {
                data.push(item.employeeID);
            }
        }
        else {
            if (existInArray) {
                data.splice(data.indexOf(item.employeeID), 1);
            }
        }

        let all = (data.length == this.state.UnIgnoredData.length) ? true : false;
        this.setState({ UnIgnoredSelectedRecords: data, SelectAllUnIgnore: all, editIndex: -1 });

    }
    SelectAllUnIgnored = (e) => {
        
        if (this.state.UnIgnoredData.length > 0) {
            if (e.target.checked) {
                let all = [];
                let data = this.state.UnIgnoredData;
                for (let i = 0; i < data.length; i++) {
                    all.push(data[i].employeeID);
                }
                this.setState({ SelectAllUnIgnore: true, UnIgnoredSelectedRecords: all });
            }
            else {
                this.setState({ SelectAllUnIgnore: false, UnIgnoredSelectedRecords: [] });

            }

        }

    }

    OnApplyIgnored = () => {
        
        let selectedData = this.state.UnIgnoredSelectedRecords;
        if (selectedData == null || selectedData.length === 0) {
            toast.error("Please select atleast 1 record for processing request");
            return false;
        }

        let body = {
            "ids": selectedData,
            "reportType": ReportType.HousingAllwance,
            "companyID": this.state.companyId
        }

        this.setState({ loading: true }, () => {
            this.props.MarkAsIgnoreResolveExcessHousingAllowanceTxn(body);    
        });
    }

    OnApplyUnIgnored = () => {
        
        let selectedData = this.state.IgnoredSelectedRecords;
        if (selectedData == null || selectedData.length === 0) {
            toast.error("Please select atleast 1 record for processing request");
            return false;
        }

        let body = {
            "ids": selectedData,
            "reportType": ReportType.HousingAllwance,
            "companyID": this.state.companyId
        }

        this.setState({ loading: true }, () => {
            this.props.RevertMarkAsUnIgnoreResolveExcessHousingAllowanceTxn(body);    
        });
    }

    render() {
        let selectedItemIgnored = this.state.IgnoredSelectedRecords;
        let selectedItemUnIgnored = this.state.UnIgnoredSelectedRecords;
        return (
            <div className="dashboardPage">
                <div className="page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
                    <h2 className="mb-3 mb-sm-0">{JSON.parse(localStorage.getItem('userData')) != null ? JSON.parse(localStorage.getItem('userData')).companyName : ""} | {LinkType.HousingAllowanceIssues}</h2>
                </div>
                {this.state.loading ? <CircularProgress></CircularProgress>
                    :
                    <React.Fragment>
                        <div className="padding-box app-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="float-right">
                                                {/* <Button variant="raised" className=" jr-btn g-teal  text-white refresh-btn graycolor" onClick={() => this.OnApplyApproved()}  >Approve</Button> */}
                                                <Button variant="raised" className=" jr-btn graycolor text-white refresh-btn" onClick={() => this.OnApplyIgnored()} >Ignore</Button>
                                                <Button variant="raised" style={{ backgroundColor: this.state.ColorCode }} className="jr-btn text-white refresh-btn" onClick={() => this.onRefreshDataClick()}  ><i className="zmdi zmdi-refresh "></i> Refresh Data</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="" style={{minHeight:"0px !important"}}>
                                <table className="table tablebox account-reconcilliation">
                                    <thead>
                                        <tr style={{ backgroundColor: this.state.ColorCode }}>
                                            <th className={`td-width ${this.state.sortField === 'name' ? this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('name')}><p className="table-th-title">Name</p> </th>
                                            <th className={`td-width text-center ${this.state.sortField == 'housingAllowance' ? this.state.sortFormat === 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('housingAllowance')}><p className="table-th-title">Board Approved Housing Allowance</p> </th>
                                            <th className={`td-width text-center ${this.state.sortField == 'allowancePaid' ? this.state.sortFormat === 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('allowancePaid')}><p className="table-th-title">Housing Allowance Paid</p> </th>
                                            <th className={`td-width text-center ${this.state.sortField == 'excessHousingAllowancePaid' ? this.state.sortFormat === 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('excessHousingAllowancePaid')}><p className="table-th-title"> Excess Housing Allowance Paid</p> </th>
                                            <th className="td-width-90 text-center">
                                                    <label className="container101 header-check">
                                                        {this.state.SelectAllUnIgnore ?
                                                            <input className="second-check-box" checked="checked" type="checkbox" onClick={(e) => this.SelectAllUnIgnored(e)} />
                                                            :
                                                            <input className="second-check-box" type="checkbox" onClick={(e) => this.SelectAllUnIgnored(e)} />
                                                        }
                                                        <span className="checkmark101 white_border"></span>
                                                    </label>
                                                </th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {this.state.UnIgnoredData.length > 0 ?
                                            (this.state.UnIgnoredData && this.state.UnIgnoredData.length > 0) && this.state.UnIgnoredData.map((item, j) => (
                                                <React.Fragment key={j}>
                                                    {<tr >
                                                        <td>{item.name}</td>
                                                        <td className="text-center">{item.housingAllowance!=null?numberWithCommas(item.housingAllowance):0.00}</td>
                                                        <td className="text-center">{item.allowancePaid!= null? numberWithCommas(item.allowancePaid):0.00}</td>
                                                        <td className="text-center"> {item.excessHousingAllowancePaid!=null?numberWithCommas(item.excessHousingAllowancePaid):0.00}</td>
                                                        <td className="text-center">
                                                            <label className="container101">
                                                                {(this.state.SelectAllUnIgnore || selectedItemUnIgnored.length > 0 && (selectedItemUnIgnored.indexOf(item.employeeID) > -1)) ?
                                                                    <input className="second-check-box" checked="checked" type="checkbox" onClick={(e) => this.onUnIgnoredCheckboxClick(e, item)} />
                                                                    :
                                                                    <input className="second-check-box" type="checkbox" onClick={(e) => this.onUnIgnoredCheckboxClick(e, item)} />
                                                                }
                                                                <span className="checkmark101"></span>
                                                            </label>
                                                        </td>
                                                    </tr>
                                                    }
                                                </React.Fragment>
                                            ))
                                            :
                                            <tr>
                                                <td colSpan="5" align="center">No issues here! Keep up the good work!</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <PaginationComponent
                                    selectedPageNo={this.state.PageNo}
                                    selectedSize={this.state.PageSize}
                                    TotalRecords={this.state.UnIgnoredTotalRecords}
                                    ChangePageSize={this.ChangePageSizeUnIgnored}
                                    ChangePage={this.ChangePage}
                                    CurrentRecords={this.state.UnIgnoredData.length}
                                >
                                </PaginationComponent>
                        </div>

                        <div className="padding-box app-wrapper" >
                        <div className="row" style={{marginBottom:"-25px"}}>
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="float-left arc_summary">
                                        <h3>Ignored Excess Housing Allowance</h3>
                                        </div>
                                        <div className="float-right">
                                            <Button variant="raised" className=" jr-btn graycolor text-white refresh-btn" onClick={() => this.OnApplyUnIgnored()} >Revert</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            <div className="" style={{minHeight:'0px !important'}}>
                                <table className="table tablebox account-reconcilliation">
                                    <thead>
                                        <tr style={{ backgroundColor: this.state.ColorCode }}>
                                            <th className={`td-width ${this.state.sortField === 'name' ? this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('name')}><p className="table-th-title">Name</p> </th>
                                            <th className={`td-width text-center ${this.state.sortField == 'housingAllowance' ? this.state.sortFormat === 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('housingAllowance')}><p className="table-th-title">Board Approved Housing Allowance</p> </th>
                                            <th className={`td-width text-center ${this.state.sortField == 'allowancePaid' ? this.state.sortFormat === 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('allowancePaid')}><p className="table-th-title">Housing Allowance Paid</p> </th>
                                            <th className={`td-width text-center ${this.state.sortField == 'excessHousingAllowancePaid' ? this.state.sortFormat === 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('excessHousingAllowancePaid')}><p className="table-th-title"> Excess Housing Allowance Paid</p> </th>
                                            <th className="td-width-90 text-center">
                                                    <label className="container101 header-check">
                                                        {this.state.SelectAllIgnore ?
                                                            <input className="second-check-box" checked="checked" type="checkbox" onClick={(e) => this.SelectAllIgnored(e)} />
                                                            :
                                                            <input className="second-check-box" type="checkbox" onClick={(e) => this.SelectAllIgnored(e)} />
                                                        }
                                                        <span className="checkmark101 white_border"></span>
                                                    </label>
                                                </th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {this.state.IgnoredData.length > 0 ?
                                            (this.state.IgnoredData && this.state.IgnoredData.length > 0) && this.state.IgnoredData.map((item, j) => (
                                                <React.Fragment key={j}>
                                                    {<tr >
                                                        <td>{item.name}</td>
                                                        <td className="text-center">{item.housingAllowance!=null?numberWithCommas(item.housingAllowance):0.00}</td>
                                                        <td className="text-center">{item.allowancePaid!= null? numberWithCommas(item.allowancePaid):0.00}</td>
                                                        <td className="text-center"> {item.excessHousingAllowancePaid!=null?numberWithCommas(item.excessHousingAllowancePaid):0.00}</td>
                                                        <td className="text-center">
                                                            <label className="container101">
                                                                {(this.state.SelectAllIgnore || selectedItemIgnored.length > 0 && (selectedItemIgnored.indexOf(item.employeeID) > -1)) ?
                                                                    <input className="second-check-box" checked="checked" type="checkbox" onClick={(e) => this.onIgnoredCheckboxClick(e, item)} />
                                                                    :
                                                                    <input className="second-check-box" type="checkbox" onClick={(e) => this.onIgnoredCheckboxClick(e, item)} />
                                                                }
                                                                <span className="checkmark101"></span>
                                                            </label>
                                                        </td>
                                                    </tr>
                                                    }
                                                </React.Fragment>
                                            ))
                                            :
                                            <tr>
                                                <td colSpan="5" align="center">No issues here! Keep up the good work!</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            {/* <PaginationComponent
                                    selectedPageNo={this.state.PageNo}
                                    selectedSize={this.state.PageSize}
                                    TotalRecords={this.state.IgnoredTotalRecords}
                                    ChangePageSize={this.ChangePageSizeIgnored}
                                    ChangePage={this.ChangePage}
                                    CurrentRecords={this.state.IgnoredData.length}
                                >
                                </PaginationComponent> */}
                        </div>
                    </React.Fragment>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ReportData: state.ReportData.ReportData,
        UpdateDescription: state.ReportData.UpdateDescription,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        GET_EMPPAYROLL_REPORT_DATA,
        MarkAsIgnoreResolveExcessHousingAllowanceTxn,
        RevertMarkAsUnIgnoreResolveExcessHousingAllowanceTxn
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(HousingAllowanceIssues);
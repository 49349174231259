import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {printReports} from '../appRedux/actions/companiesAction';
import "./PrintReportForm.css"

 class PrintReportForm extends Component {
    constructor() {
        super()
        this.state = {
            IsPayrollPaymentReportsSelected:false,
            IsPayrollFilingReportsSelected:false
        }
      }

        handleprintReportChecked = (e) => {
          this.setState({[e.target.name]:e.target.checked});
        }
        handleSubmit = (e) => {
          e.preventDefault();
          this.props.printReports(this.state);
        console.log(this.state)
      }
  render() {
    const { visible,handlePopUpClose} = this.props;
    return (
      <div>
        <Dialog
        maxWidth="sm"
        fullWidth={true}
        open={visible}
      //  onClose={handlePopUpClose}
      >
 <form method="post" onSubmit={this.handleSubmit} autoComplete="off" style={{ padding: 20 }}>
          <DialogTitle>Which payroll reports do you need?</DialogTitle>
          <DialogContent>
          <Grid container spacing={24} >
          <Grid item sm={8} style={{marginTop:"2%"}}>
                                    <Checkbox 
                                    style={{color:'green'}}
                                    className='printreportcheckbox'
                                        name="IsPayrollPaymentReportsSelected"
                                        // value={this.state.isFullServiceBookkeeping}
                                        // checked={this.state.isFullServiceBookkeeping}
                                        onChange={(e) => this.handleprintReportChecked(e)}
                                />
                  <InputLabel htmlFor={""}>Payroll Payment Reports</InputLabel>
               </Grid>
            
            <Grid item sm={8} style={{marginTop:"2%"}}>
                                    <Checkbox 
                                    style={{color:'green'}}
                                    className='printreportcheckbox'
                                        name="IsPayrollFilingReportsSelected"
                                        // value={this.state.isFullServiceBookkeeping}
                                        // checked={this.state.isFullServiceBookkeeping}
                                        onChange={(e) => this.handleprintReportChecked(e)}
                                />
                  <InputLabel htmlFor={""}>Payroll Filing Reports</InputLabel>
               </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
                    <Button type='submit'  color="primary" onClick={handlePopUpClose}>
                                           Print Reports
                                   </Button>
                    <Button onClick={handlePopUpClose} color="primary" autoFocus>
                        Cancel
                </Button>
                </DialogActions>
          </form>

      </Dialog>
      </div>
    )
  }
}



const mapStateToProps = (state) => {
    return {
    
    }
    };
    
    const mapDispatchToProps=(dispatch)=>{
        return bindActionCreators({
            // insertCompanies
            printReports
        },dispatch)
    }
    
    export default connect(mapStateToProps, mapDispatchToProps)(PrintReportForm);
    


import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import {deleteCompany} from '../appRedux/actions/companiesAction';
import {findIndex} from "lodash";
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';



class DeleteCompnayForm extends React.Component {

    constructor() {
        super()
        this.state = {
            reason:''
        }
        this.handleSubmit=this.handleSubmit.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
    }

    handleCancel = () => {
        this.props.handleCancel();
        this.setState({reason:''});

    };
    
    handleSubmit = (e) => {
         e.preventDefault();
       let data = {
            id:this.props.selectedItem.companyId,
            reason:this.state.reason
       };

        this.props.deleteCompany(data);
        this.props.handleSave(false);
         this.setState({reason:''});
     }

    handleFormChange = (e) => {
       this.setState({[e.target.name]:e.target.value})
    }
        
    render() {
        const {visible,isEdit,selectedItem} = this.props;
       
        return (
            <Dialog
            fullWidth={true}
            open={visible}
            onClose={this.handleCancel}
            >
            <form method="post"  onSubmit={this.handleSubmit}  autoComplete="off" style={{ padding: 20 }}>
            <DialogTitle>Delete Company</DialogTitle>
             <DialogContent>
                 <fieldset>
                     <Grid container spacing={24} >
                        <Grid item sm={12} >
                            <TextField
                                name="reason"
                                label="Reason"
                                fullWidth
                                required={true}
                                onChange={this.handleFormChange}
                                defaultValue={this.state.reason}
                                margin="normal"
                            />
                        </Grid>  
                    </Grid>
                    </fieldset>
                 </DialogContent>
                <DialogActions>
                <Button type="submit" color="primary">
                  Delete
                </Button>
                <Button onClick={this.handleCancel} color="primary" autoFocus>
                  Cancel
                </Button>
              </DialogActions>
                </form>
            </Dialog>
        );
    }
}

   

const mapStateToProps = (state) => {
return {
    
}
};

const mapDispatchToProps=(dispatch)=>{
    return bindActionCreators({
        deleteCompany
    },dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteCompnayForm);




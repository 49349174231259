import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
//import CommonComments from './../../../common/Comments';
import PaginationComponent from './../../../common/Pagination';
import { GET_CompanyDashboard_PayrollVendorReportDetailList, Vendor1099Mailed, AccountReconciliationReviewSummery } from '../../../../appRedux/actions/ReportDetailAction';
import { ReportType, MaskTaxIdentifier, numberWithCommas,LinkType } from '../../../common/commonServices'
import moment from 'moment';
import { bindActionCreators } from 'redux';
import CircularProgress from './../../../../components/CircularProgress';
import { toast } from 'react-toastify';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import ClearIcon from '@material-ui/icons/Clear';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import { getCompnay } from '../../../../appRedux/actions/companiesAction';

const FontTooltip = withStyles((theme) => ({
    tooltip: {
        fontSize: 12
    },
}))(Tooltip);


class Confirm1099Mailed extends Component {

    constructor(props) {
        super(props);

        let eDate = new Date();
        let sDate = new Date(eDate.getFullYear(), 0, 1);

        if (eDate.getMonth() <= 2) {
            sDate = new Date(sDate.getFullYear() - 1, 0, 1);
            eDate = new Date(eDate.getFullYear() - 1, 11, 31);
        }
        const years = [];
    const currentYear = new Date().getFullYear();
    for (let year = currentYear ; year >= currentYear - 25; year--) {
        if (year >= 2020) {
            years.push(year);
          }
    }
    this.years = years
        this.state = {
            parantData: this.props.location.state ? this.props.location.state : null,
            MissingData: [],
            companyId: 0,
            sortField: 'name',
            sortFormat: "A",
            PageNo: 1,
            PageSize: 10,
            ColorCode: "#f0ca4c",
            IsCommentModelOpen: false,
            sDate: sDate,
            eDate: eDate,
            loading: false,
            TotalRecords: 0,
            CommentReferenceId: 0,
            IsEmbeddedPage: false,
            SelectedRecords: [],
            SelectALL: false,
            ShowComplete: true,
            ShowGuidlines: false,
            ACRSummary: "No",
            lastReconciledThrough:null
        }
    }

    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('userData'));
        this.setState({
            companyId: user.companyId,
            loading: true
        }, () => {
            this.props.getCompnay(user.companyId);
            this.props.AccountReconciliationReviewSummery(user.companyId);
            this.GetReportData();
        });
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.ReportData !== this.props.ReportData) {
            if (nextProps.ReportData.transactionStatus === 0) {
                var totalrecords = 0;
                let items = nextProps.ReportData.returnObject;
                if (nextProps.ReportData.returnObject != null && nextProps.ReportData.returnObject.length > 0) {
                    totalrecords = nextProps.ReportData.returnObject[0].totalrows
                    items.map((dt, index) => {
                        items[index].MaskTax = MaskTaxIdentifier(dt.taxIdentifier);
                    });
                }
                this.setState({ MissingData: items, TotalRecords: totalrecords, PageSize: this.state.PageSize, SelectedRecords: [], SelectALL: false, PageNo: this.state.PageNo, loading: false });
            }
            else {
                toast.error(nextProps.ReportData.resultMsg);
                this.setState({ loading: false });
            }
        }
        if (nextProps.company !== this.props.CompanyInfo) {
            this.setState({ loading: false, CompanyInfo: nextProps.company});
        }
        if (nextProps.UpdateDescription !== this.props.UpdateDescription) {
            if (nextProps.UpdateDescription.transactionStatus === 0) {
                toast.success(`Vendor Marked as Mailed successfully`);
                this.GetReportData();
            }
            else {
                this.setState({
                    loading: false, SelectedRecords: [], SelectALL: false
                }, () => {
                    toast.error(`${nextProps.UpdateDescription.resultMsg}`);
                });
            }
        }
        if (nextProps.ARCRSummary !== this.props.ARCRSummary) {
            if (nextProps.ARCRSummary.transactionStatus === 0) 
            {
                if(nextProps.ARCRSummary.returnObject.length>0)
                {
                    this.setState({ loading: false,ACRSummary:nextProps.ARCRSummary.returnObject[0].haveAllCheckingAccounts?"Yes":"No",lastReconciledThrough:nextProps.ARCRSummary.returnObject[0].lastReconciledThrough});
                }                
            }
            else
            {
                this.setState({ loading: false},() =>{ toast.error(`${nextProps.ARCRSummary.resultMsg}`)});
            }
            
        }
    }

    ChangePageSize = (size) => {
        this.setState({ loading: true, PageSize: parseInt(size), PageNo: 1 }, () => {
            this.GetReportData();
        });
    }

    ChangePage = (page) => {
        this.setState({ loading: true, PageNo: page, }, () => {
            this.GetReportData();
        });
    }

    GetReportData(IsRefresh = false) {
        let state = this.state;
        let body = {
            companyID: state.companyId,
            reportType: ReportType.Confirm1099WereMailed,
            fromDate: moment(state.sDate).format("MM/DD/YYYY"),
            toDate: moment(state.eDate).format("MM/DD/YYYY"),
            sortField: state.sortField,
            sortFormat: state.sortFormat,
            pageNumber: state.PageNo,
            pageSize: state.PageSize,
            needUpdatQBORecords: IsRefresh
        };

        this.props.GET_CompanyDashboard_PayrollVendorReportDetailList(body);
    }


    SelectALL = (e) => {
        if (this.state.MissingData.length > 0) {
            if (e.target.checked) {
                let all = [];
                let data = this.state.MissingData;
                for (let i = 0; i < data.length; i++) {
                    all.push(data[i].listID);
                }
                this.setState({ SelectALL: true, SelectedRecords: all });
            }
            else {
                this.setState({ SelectALL: false, SelectedRecords: [] });
            }
        }
    }


    onSortChange = (fieldName) => {
        if (this.state.sortField == fieldName) {
            let formate = this.state.sortFormat == 'A' ? 'D' : 'A';
            this.setState({ sortFormat: formate, loading: true }, () => {
                this.GetReportData();
            });
        } else {
            this.setState({ sortField: fieldName, sortFormat: 'A', loading: true }, () => {
                this.GetReportData();
            });
        }
    }
    onRefreshDataClick = () => {
        if (!this.state.loading) {
            this.setState({ loading: true }, () => {
                this.GetReportData(true);
            });
        }
    }

    OnApplyApproved = () => {
        let selectedData = this.state.SelectedRecords;
        if (selectedData == null || selectedData.length === 0) {
            toast.error("Please select atleast 1 record for processing request");
            return false;
        }
        let body = {
            "ids": selectedData,
            "reportType": ReportType.Confirm1099WereMailed,
            "companyID": this.state.companyId,
        }

        this.setState({ loading: true }, () => {
            this.props.Vendor1099Mailed(body);
        });
    }

    onCheckboxClick = (e, item) => {
        e.stopPropagation();
        let existInArray = false;
        let data = this.state.SelectedRecords;
        if (data != null && data.length > 0 && data.indexOf(item.listID) > -1) {
            existInArray = true;
        }

        if (e.target.checked) {
            if (!existInArray) {
                data.push(item.listID);
            }
        }
        else {
            if (existInArray) {
                data.splice(data.indexOf(item.listID), 1);
            }
        }
        let all = (data.length == this.state.MissingData.length) ? true : false;
        this.setState({ SelectedRecords: data, SelectALL: all, editIndex: -1 });
    }
    YearChange = (e) => {

        this.setState({ eDate: new Date(e.target.value, 11, 31),sDate: new Date(e.target.value, 0, 1)},() => {
    
          this.GetReportData();
        });
      }

    CloseInstruction = () => {
        this.setState({ ShowGuidlines: false })
    }

    ShowWarning = () => {
        this.setState({ ShowGuidlines: true })
    }

    render() {
        let selectedItem = this.state.SelectedRecords;
        return (
            <div className="dashboardPage">
                <div className="page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
                    <h2 className="mb-3 mb-sm-0">{JSON.parse(localStorage.getItem('userData')) != null ? JSON.parse(localStorage.getItem('userData')).companyName : ""} | {LinkType.Confirm1099Mailed}</h2>
                </div>
                {this.state.ShowGuidlines &&
                    <Dialog
                        className="comment-Top-align"
                        maxWidth="sm"
                        fullWidth={true}
                        open={true}>
                        <React.Fragment>
                            <div className="Comments modal-header"><span className="log-history-title float-left">WARNING </span>
                                <Button className="jr-btn close-btn float-right" onClick={this.CloseInstruction}><ClearIcon /></Button>
                            </div>
                            <DialogContent style={{ margin: "8px" }} >
                                <p >
                                    The 1099 amount for this vendor has changed.  However, a 1099 was exported and/or mailed for this vendor.  Check the tax software to determine if the 1099 was filed electronically and discuss this matter with the CPA.
                                </p>
                                <p>
                                    <ol>
                                        <li>If the 1099 has been mailed to the vendor, contact the client. The client must notify the vendor that they will receive a corrected 1099. Add a ""Corrected"" watermark at the top of the 1099 before mailing to the vendor. </li>
                                        <li>If the 1099 has not been filed electronically, make sure the tax software reflects the updated amount.  </li>
                                        <li>If the 1099 has been filed electronically, use the 1099 correction procedures in the tax software to prepare and e-file a corrected 1099. </li>
                                    </ol>
                                </p>
                            </DialogContent>
                        </React.Fragment>
                    </Dialog>
                }
                {this.state.loading ? <CircularProgress></CircularProgress>
                    :
                    <div className="padding-box app-wrapper">
                        <div className="row">
                            <div className="col-md-12">
                            <div className="row">
                      <div className="col-md-12">
                        <div className="float-left arc_summary">
                            
                        {this.state.CompanyInfo ? 
                    <Grid container spacing={24} style={{ paddingBottom: 13 , columnGap:40 }}>
                      <Grid item>
                        <h3>
                          <b>Company Legal Name : </b> {this.state.CompanyInfo.name ? this.state.CompanyInfo.name : ''}
                        </h3>
                        <h3>
                          <b>Legal Address : </b> {this.state.CompanyInfo.address ? this.state.CompanyInfo.address :""}
                        </h3> 
                      </Grid>
                      <Grid item>
                        <h3>
                          <b>City : </b> {this.state.CompanyInfo.city ? this.state.CompanyInfo.city :``}
                        </h3>
                        <h3>
                          <b>State : </b> {this.state.CompanyInfo.state ? this.state.CompanyInfo.state :``}
                        </h3> 
                        </Grid>
                        <Grid item>
                        <h3>
                          <b>Zip : </b> {this.state.CompanyInfo.postalCode ? this.state.CompanyInfo.postalCode :``}
                        </h3>
                        <h3>
                          <b>EIN Number : </b> {this.state.CompanyInfo.eiNnumber ? this.state.CompanyInfo.eiNnumber : ``}
                        </h3> 
                        
                        </Grid>
                    </Grid> :``}
                        </div>
                        </div>
                        </div>                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="float-left arc_summary">
                                        <span>Have all checking accounts been reconciled through {this.state.lastReconciledThrough}?: {this.state.ACRSummary}  </span>
                                        </div>
                                        <div className="float-right">
                                        <span>Select Year:</span>
                                        <select className="select-box arrow-box" name="year" onChange={(e) => this.YearChange(e)} value={this.state.eDate.getFullYear()} style={{marginRight:"10px",height:'40px'}}>
                                         {this.years.map((option, index) => (
                                            <option key={index} value={option}>
                                              {option}
                                            </option>
                                          ))}
                                        </select>
                                            <Button variant="raised" className=" jr-btn g-teal noTitleCase  text-white refresh-btn graycolor" onClick={() => this.OnApplyApproved()} style={{ textTransform: "none !important" }}> Mark as Mailed</Button>
                                            <Button variant="raised" style={{ backgroundColor: this.state.ColorCode }} className="jr-btn text-white refresh-btn" onClick={() => this.onRefreshDataClick()}  ><i className="zmdi zmdi-refresh "></i> Refresh Data</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive-material">
                            <table className="table tablebox account-reconcilliation">
                                <thead>
                                    <tr style={{ backgroundColor: this.state.ColorCode }}>
                                        <th className={`td-width ${this.state.sortField == 'name' ? this.state.sortFormat == 'A'
                                            ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('name')}><p className="table-th-title">First Name</p> </th>
                                        <th className={`td-width  ${this.state.sortField === 'familyName' ? this.state.sortFormat == 'A'
                                            ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('familyName')}><p className="table-th-title"> Last Name</p></th>
                                        <th className={`td-width ${this.state.sortField == 'companyName' ? this.state.sortFormat == 'A'
                                            ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('companyName')}><p className="table-th-title">Company Name</p> </th>
                                        <th className={`td-width  ${this.state.sortField === 'address' ? this.state.sortFormat == 'A'
                                            ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('address')}><p className="table-th-title"> Address</p></th>
                                        <th className={`td-width  ${this.state.sortField === 'taxIdentifier' ? this.state.sortFormat == 'A'
                                            ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('taxIdentifier')}><p className="table-th-title"> Tax Identifier</p></th>
                                        <th className={`td-width  ${this.state.sortField === 'vendorType' ? this.state.sortFormat == 'A'
                                            ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('vendorType')}><p className="table-th-title"> Vendor Type</p></th>
                                        <th className={`td-width text-center ${this.state.sortField === 'totalPayment' ? this.state.sortFormat == 'A'
                                            ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('totalPayment')}><p className="table-th-title"> Total Payment</p></th>
                                        <th className="td-width-90 text-center">
                                            <label className="container101 header-check">
                                                {this.state.SelectALL ?
                                                    <input className="second-check-box" checked="checked" type="checkbox" onClick={(e) => this.SelectALL(e)} />
                                                    :
                                                    <input className="second-check-box" type="checkbox" onClick={(e) => this.SelectALL(e)} />
                                                }
                                                <span className="checkmark101 yellowMark white_border"></span>
                                            </label>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.MissingData != null && this.state.MissingData.length > 0 ?
                                        <React.Fragment>
                                            {this.state.MissingData.map((item, i) => (
                                                <tr>
                                                    <td>{item.name}</td>
                                                    <td>{item.familyName}</td>
                                                    <td>{item.companyName}</td>
                                                    <td>{item.address}</td>
                                                    <td>{item.taxIdentifier}</td>
                                                    <td>{item.vendorType}</td>
                                                    <td className="text-center">
                                                        {numberWithCommas(item.totalPayment)}
                                                        {item.alreadyExported &&
                                                            <img className="amt_updated" onClick={() => this.ShowWarning()} src="/vendors/Caution.png" alt="caution" />
                                                        }
                                                    </td>
                                                    <td className="text-center">
                                                        <label className="container101">
                                                            {(this.state.SelectALL || selectedItem.length > 0 && (selectedItem.indexOf(item.id) > -1)) ?
                                                                <input className="second-check-box" checked="checked" type="checkbox" onClick={(e) => this.onCheckboxClick(e, item)} />
                                                                :
                                                                <input className="second-check-box" type="checkbox" onClick={(e) => this.onCheckboxClick(e, item)} />
                                                            }
                                                            <span className="checkmark101 yellow_CheckMark"></span>
                                                        </label>
                                                    </td>
                                                </tr>
                                            ))}
                                        </React.Fragment>
                                        :
                                        <tr>

                                            <td className="text-center" colSpan="8">
                                            No issues here! Keep up the good work!
                                            </td>
                                        </tr>
                                    }

                                </tbody>
                            </table>
                        </div>
                        <PaginationComponent
                            key={this.state.MissingData.length}
                            selectedPageNo={this.state.PageNo}
                            selectedSize={this.state.PageSize}
                            TotalRecords={this.state.TotalRecords}
                            ChangePageSize={this.ChangePageSize}
                            ChangePage={this.ChangePage}
                            CurrentRecords={this.state.MissingData.length}
                        >
                        </PaginationComponent>
                    </div>
                }
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        ReportData: state.ReportData.ReportData,
        UpdateDescription: state.ReportData.UpdateDescription,
        company: state.companyReducer.companyDeatils,
        ARCRSummary: state.ReportData.ARCRSummary
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        GET_CompanyDashboard_PayrollVendorReportDetailList,
        Vendor1099Mailed,
        AccountReconciliationReviewSummery,
        getCompnay
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Confirm1099Mailed);
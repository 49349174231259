import React, { Component } from 'react';
import LogHistory from '../../../common/logHistoryModel';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import CommonComments from './../../../common/Comments';
import PaginationComponent from './../../../common/Pagination';
import { GET_REPORT_DATA, MARK_RESOLVED_DATA } from '../../../../appRedux/actions/ReportDetailAction';
import { ReportType, ModuleType, makeLinkForQuickbooks, numberWithCommas,LinkType } from '../../../common/commonServices'
import moment from 'moment';
import { bindActionCreators } from 'redux';
import CircularProgress from './../../../../components/CircularProgress';
import { toast } from 'react-toastify';
import RSelect from 'react-select';
import Textarea from 'react-expanding-textarea';
import _ from 'lodash';
import { Accounts } from '../../../../appRedux/actions/accountAction';
import { LedgerUpdate } from '../../../../appRedux/actions/LedgerAction';

class ReviewNBDComponenet extends Component {
    constructor(props) {
        super(props);

        this.state = {
            parantData: this.props.location.state ? this.props.location.state : null,
            MissingData: [],
            companyId: 0,
            sortField: 'date',
            sortFormat: "D",
            PageNo: 1,
            PageSize: 10,
            ColorCode: "#46b29d",
            IsCommentModelOpen: false,
            sDate: new Date(2019, 0, 1),
            eDate: new Date(),
            loading: false,
            TotalRecords: 0,
            CommentReferenceId: 0,
            IsEmbeddedPage: false,
            SelectedRecords: [],
            SelectALL: false,
            DisplayAccounts: [],
            selectedAccount: { value: 0, label: "Select" },
        }
    }

    onLinkClick = (rowData) => {
        let origin = makeLinkForQuickbooks();
        window.open(origin + rowData.transactionLink);
    }

    onSortChange = (fieldName) => {
        if (this.state.sortField == fieldName) {
            let formate = this.state.sortFormat == 'A' ? 'D' : 'A';
            this.setState({ sortFormat: formate, loading: true }, () => {
                this.GetReportData();
            });
        } else {
            this.setState({ sortField: fieldName, sortFormat: 'A', loading: true }, () => {
                this.GetReportData();
            });
        }
    }

    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('userData'));
        this.setState({
            companyId: user.companyId,
            loading: true
        }, () => {
            this.GetReportData();
            this.props.Accounts(this.state.companyId);
        });
    }

    ChangePageSize = (size) => {

        this.setState({ loading: true, PageSize: parseInt(size), PageNo: 1 }, () => {
            this.GetReportData();
        });
    }

    ChangePage = (page) => {
        this.setState({ loading: true, PageNo: page, }, () => {
            this.GetReportData();
        });
    }

    LoadComments = (item) => {
        this.setState({ IsCommentModelOpen: true, CommentReferenceId: item.id });
    }

    CloseComments = () => {
        this.setState({ IsCommentModelOpen: false });
    }

    componentWillReceiveProps(nextProps) {
   
        if (nextProps.ReportData !== this.props.ReportData) {
            if (nextProps.ReportData.transactionStatus === 0) {
                var totalrecords = 0;
                if (nextProps.ReportData.returnObject != null && nextProps.ReportData.returnObject.length > 0) {
                    totalrecords = nextProps.ReportData.returnObject[0].totalrows
                }
                this.setState({
                    MissingData: nextProps.ReportData.returnObject, TotalRecords: totalrecords,
                    PageSize: this.state.PageSize, PageNo: this.state.PageNo, loading: false, SelectALL: false
                });
            } else {
                toast.error(nextProps.ReportData.resultMsg);
                this.setState({ loading: false });
            }

        }

        if (nextProps.UpdateDescription !== this.props.UpdateDescription) {
            if (nextProps.UpdateDescription.transactionStatus === 0) {
                this.setState({
                    loading: true, editData: null, editIndex: -1, SelectedRecords: [], SelectALL: false
                }, () => {
                    toast.success(`${nextProps.UpdateDescription.resultMsg}`);
                    this.GetReportData();
                });
            }
            else {
                this.setState({
                    loading: false, SelectedRecords: [], SelectALL: false
                }, () => {
                    toast.error(`${nextProps.UpdateDescription.resultMsg}`);
                });

            }
        }

        if (nextProps.accounts != undefined && nextProps.accounts !== this.props.accounts) {

            let searchableAccounts = [];
            if (nextProps.accounts.data != null && nextProps.accounts.data.length > 0) {
                searchableAccounts = _.sortBy(nextProps.accounts.data, ['accountNumber', 'name']).map((value, index) => (
                    { value: value.accountID, label: (value.accountNumber != null ? value.accountNumber + ' ' + value.name : value.name) }
                ))
            }
            let defaultValue = { value: 0, label: "Select" };
            searchableAccounts.unshift(defaultValue);
            this.setState({
                accounts: nextProps.accounts.data,
                DisplayAccounts: searchableAccounts
            });
        }

       
        if (nextProps.LEDGER_UPDATE !== this.props.LEDGER_UPDATE) {
            if (nextProps.LEDGER_UPDATE.transactionStatus === 0) {
                this.setState({
                    loading: true, editData: null, editIndex: -1, SelectedRecords: [], SelectALL: false
                }, () => {
                    toast.success("Record updated successfully");
                    this.GetReportData();
                });
            }
            else {
                this.setState({
                    loading: false, SelectedRecords: [], SelectALL: false
                }, () => {
                    toast.error(`${nextProps.LEDGER_UPDATE.resultMsg}`);
                });
    
            }
        }

    }

    SelectALL = (e) => {
        if (this.state.MissingData.length > 0) {
            if (e.target.checked) {
                let all = [];
                let data = this.state.MissingData;
                for (let i = 0; i < data.length; i++) {
                    all.push(data[i].id);
                }
                this.setState({ SelectALL: true, SelectedRecords: all });
            }
            else {
                this.setState({ SelectALL: false, SelectedRecords: [] });

            }

        }

    }

    onRefreshDataClick = () => {
        if (!this.state.loading) {
            this.setState({ loading: true }, () => {
                this.GetReportData(true);
            });
        }
    }

    GetReportData(IsRefresh = false) {
        let state = this.state;
        let body = {
            companyID: state.companyId,
            reportType: ReportType.NeedForBetterDescrption,
            fromDate: moment(state.sDate).format("MM/DD/YYYY"),
            toDate: moment(state.eDate).format("MM/DD/YYYY"),
            sortField: state.sortField,
            sortFormat: state.sortFormat,
            pageNumber: state.PageNo,
            pageSize: state.PageSize,
            needUpdatQBORecords: IsRefresh,
            IsEdited: true
        };

        this.props.GET_REPORT_DATA(body);
    }

    onCheckboxClick = (e, item) => {
        e.stopPropagation();

        if (item.newAccountId == null || item.newAccountId === "") {
            e.preventDefault();
        }
        else {
            let existInArray = false;
            let data = this.state.SelectedRecords;
            if (data != null && data.length > 0 && data.indexOf(item.id) > -1) {
                existInArray = true;
            }

            if (e.target.checked) {
                if (!existInArray) {
                    data.push(item.id);
                }
            }
            else {
                if (existInArray) {
                    data.splice(data.indexOf(item.id), 1);
                }
            }

            let all = (data.length == this.state.MissingData.length) ? true : false;
            this.setState({ SelectedRecords: data, SelectALL: all });
        }
    }


    OnApplyApproved = () => {
        let selectedData = this.state.SelectedRecords;
        if (selectedData == null || selectedData.length === 0) {
            toast.error("Please select atleast 1 record for processing request");
            return false;
        }

        let body = {
            "ids": selectedData,
            "reportType": ReportType.NeedForBetterDescrption,
            "companyID": this.state.companyId
        }

        this.setState({ loading: true }, () => {
            this.props.MARK_RESOLVED_DATA(body);
        });
    }


    UpdateItemCommentCount = (id) => {
        let data = this.state.MissingData;
        data.forEach(function (item) {

            if (item.id === id) {
                item.commentCount++
            }
        });

        this.setState({ MissingData: data });
    }

    
onRowEdit = (j, rowData) => {
 
    if (this.state.editData && this.state.editIndex > -1) {
        var dataOfGL = [...this.state.MissingData];
        //let editIndex1 = this.state.editIndex1;
        let editIndex = this.state.editIndex;
        let editData = this.state.editData;
        dataOfGL[editIndex].vendor1099 = editData.vendor1099;
        dataOfGL[editIndex].taxableFringeBenefit = editData.tfb;
        dataOfGL[editIndex].needsBetterDescription = editData.nbd;
        dataOfGL[editIndex].transferCodedIncorrectly = editData.tci;
        dataOfGL[editIndex].accountantComment = editData.ac;
        dataOfGL[editIndex].internalNotes = editData.in;
        dataOfGL[editIndex].newAccountId = editData.newAccId;
        dataOfGL[editIndex].newAccountName = editData.newAccName;
        dataOfGL[editIndex].cpaReview = editData.cpa;
        dataOfGL[editIndex].pcCheck = editData.pc;
        dataOfGL[editIndex].memoOrDescription = editData.memoOrDescription;
        this.setState({ MissingData: dataOfGL });
    }
    let data = {
        tfb: rowData.taxableFringeBenefit,
        vendor1099: rowData.vendor1099,
        nbd: rowData.needsBetterDescription,
        tci: rowData.transferCodedIncorrectly,
        ac: rowData.accountantComment,
        in: rowData.internalNotes,
        newAccId: rowData.newAccountId,
        newAccName: rowData.newAccountName,
        cpa: rowData.cpaReview,
        pc: rowData.pcCheck,
        memoOrDescription : rowData.memoOrDescription
    }
    this.setState({
         editData: data, 
         editIndex: j,
         selectedAccount:{ value: data.newAccId, label: data.newAccName }
    });
};
handalAccountDropdown = (e, rowIndex, rowData) => {

    if (e.value == null || e.value == 0) {            
        var data = [...this.state.MissingData];
        data[rowIndex].newAccountId = null;
        data[rowIndex].newAccountName = null;
        this.setState({ MissingData: data, selectedAccount: e });
    }
    else {
        let obj = this.state.accounts.find(x => x.accountID == e.value);
        var data = [...this.state.MissingData];
        data[rowIndex].newAccountId = e.value;
        data[rowIndex].newAccountName = obj.name;
        this.setState({ MissingData: data, selectedAccount: e });
    }
}

handalCheckbox = (e, rowIndex, rowData) => {
    var data = [...this.state.MissingData];
    data[rowIndex][e.target.name] = e.target.checked;
    this.setState({ MissingData: data });
}


 handalTextArea = (e, rowIndex, rowData) => {
    var data = [...this.state.MissingData];
    data[rowIndex][e.target.name] = e.target.value;
    this.setState({ MissingData: data });
}
onRowUpdateClick = (rowIndex, rowData) => {
  
    let data = {
        id: rowData.id,
        transactionTypeId: rowData.transactionTypeId,
        transactionType: rowData.transactionType,
        accountId: rowData.accountId,
        newAccountId: Number(rowData.newAccountId == '' ? 0 : rowData.newAccountId),
        needsBetterDescription: rowData.needsBetterDescription,
        transferCodedIncorrectly: rowData.transferCodedIncorrectly,
        cpaReview: rowData.cpaReview,
        pcCheck: rowData.pcCheck,
        accountantComment: rowData.accountantComment,
        internalNotes: rowData.internalNotes,
        taxableFringeBenefit: rowData.taxableFringeBenefit,
        vendor1099: rowData.vendor1099,
        taxReporting: rowData.taxReporting,
        isArchived: rowData.isArchived || false,
        benefitTypeId: rowData.benefitTypeId || 0,
        employeeName: rowData.employeeName || '',
        clientComment: rowData.clientComment || '',
        accountChangeApprovedByClient: rowData.accountChangeApprovedByClient || false,
        clientReviewStatus: rowData.clientReviewStatus || false,
        accountantReviewStatus: rowData.accountantReviewStatus || false,
        descriptionNeedsEmployeeName: rowData.descriptionNeedsEmployeeName || false,
        clientApprovedTaxableFringeBenefit: rowData.clientApprovedTaxableFringeBenefit || false,
        accountantPostedTaxableFringeBenefit: rowData.accountantPostedTaxableFringeBenefit || false,
        memoOrDescription:rowData.memoOrDescription,
    };

    this.props.LedgerUpdate(data, this.state.companyId,"Review transaction updated successfully.");
    this.setState({
        editIndex1: -1,
        editIndex: -1,
        editData: null
    });
}

onRowCancelClick = (rowIndex, rowData) => {
    var data = [...this.state.MissingData];
    let editData = this.state.editData;
    data[rowIndex].vendor1099 = editData.vendor1099;
    data[rowIndex].taxableFringeBenefit = editData.tfb;
    data[rowIndex].needsBetterDescription = editData.nbd;
    data[rowIndex].transferCodedIncorrectly = editData.tci;
    data[rowIndex].accountantComment = editData.ac;
    data[rowIndex].internalNotes = editData.in;
    data[rowIndex].newAccountId = editData.newAccId;
    data[rowIndex].newAccountName = editData.newAccName;
    data[rowIndex].cpaReview = editData.cpa;
    data[rowIndex].pcCheck = editData.pc;
    data[rowIndex].memoOrDescription = editData.memoOrDescription;
    this.setState({
        MissingData: data,
        editIndex1: -1,
        editIndex: -1,
        editData: null
    });
}
    render() {

        let selectedItem = this.state.SelectedRecords;

        return (
            <React.Fragment>
                {this.state.IsCommentModelOpen ?
                    <CommonComments
                        CloseComments={this.CloseComments}
                        ColorCode={this.state.ColorCode}
                        ModuleType={ModuleType.GeneralReportType}
                        ReportType={ReportType.NeedForBetterDescrption}
                        CompanyId={this.state.companyId}
                        RecordId={this.state.CommentReferenceId}
                        IsEmbeddedPage={this.state.IsEmbeddedPage}
                        UpdateItemCommentCount={this.UpdateItemCommentCount}
                    >
                    </CommonComments> : ""}
               
                    <div className="dashboardPage">
                        <div className="page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
                            <h2 className="mb-3 mb-sm-0">{JSON.parse(localStorage.getItem('userData')) != null ? JSON.parse(localStorage.getItem('userData')).companyName : ""} | Review Updated Transactions Descriptions</h2>
                        </div>
                        {this.state.loading ? <CircularProgress /> :
                            <div className="padding-box app-wrapper">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-4">
                                            </div>
                                            <div className="col-md-8">
                                                <div className="float-right">
                                                    <Button variant="raised" className=" jr-btn graycolor text-white refresh-btn" onClick={() => this.OnApplyApproved()} >Approve</Button>
                                                    <Button variant="raised" className="refresh-btn greencolor jr-btn bg-teal text-white" onClick={() => this.onRefreshDataClick()}  ><i className="zmdi zmdi-refresh "></i> Refresh Data</Button>

                                                </div>
                                            </div>
                                            {/* <div className="col-md-4">
                                        </div>
                                        <div className="col-md-4">
                                        </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive-material">
                                    <table className="table tablebox account-reconcilliation">
                                        <thead  >
                                            <tr style={{ backgroundColor: this.state.ColorCode }}>
                                                <th className={`td-width ${this.state.sortField == 'date' ? this.state.sortFormat == 'A'
                                                    ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('date')}><p className="table-th-title"> Date</p> </th>
                                                <th className={`td-width ${this.state.sortField == 'transactionType' ? this.state.sortFormat == 'A'
                                                    ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('transactionType')}><p className="table-th-title">Type</p></th>
                                                <th className={`td-width-amount ${this.state.sortField == 'num' ? this.state.sortFormat == 'A'
                                                    ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('num')}><p className="table-th-title"> Number</p></th>
                                                <th className={`td-width ${this.state.sortField == 'name' ? this.state.sortFormat == 'A'
                                                    ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('name')}><p className="table-th-title"> Name</p></th>
                                                <th className={`td-width-box ${this.state.sortField == 'account' ? this.state.sortFormat == 'A'
                                                    ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('account')}><p className="table-th-title"> A/C No.</p></th>
                                                <th className={` ${this.state.sortField == 'memoOrDescription' ? this.state.sortFormat == 'A'
                                                    ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('memoOrDescription')}><p className="table-th-title"> Memo/Desc.</p></th>
                                                <th className={`td-width-amount text-center  ${this.state.sortField == 'amount' ? this.state.sortFormat == 'A'
                                                    ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('amount')}><p className="table-th-title"> Amount</p></th>
                                                <th>
                                                    <label className="container101 header-check ">
                                                        {this.state.SelectALL ?
                                                            <input className="second-check-box" checked="checked" type="checkbox" onClick={(e) => this.SelectALL(e)} />
                                                            :
                                                            <input className="second-check-box" type="checkbox" onClick={(e) => this.SelectALL(e)} />
                                                        }
                                                        <span className="checkmark101 white_border greenMark "></span>
                                                    </label>
                                                </th>
                                                <th className="td-width-90 text-center"><img src="/vendors/WhiteCommnet.png" alt="comments" /></th>
                                                <th className="td-width-90 text-center "><img src="/vendors/Link-arrow_header.png" alt="link" /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(this.state.MissingData && this.state.MissingData.length > 0) && this.state.MissingData.map((item, j) => (
                                                <React.Fragment key={j}>
                                                    {<tr onClick={(e)=> this.onRowEdit(j, item)}>
                                                        <td className={item.isEdited ? "icon-check" : ""}>{item.date}</td>
                                                        <td>{item.transactionType}</td>
                                                        <td>{item.num}</td>
                                                        <td>{item.name}</td>
                                                        <td>{item.account}</td>
                                                        <td>{item.memoOrDescription}</td>
                                                        <td className="text-center">{numberWithCommas(item.amount)}</td>
                                                        <td>
                                                            <label className="container101">
                                                                {(this.state.SelectALL || selectedItem.length > 0 && (selectedItem.indexOf(item.id) > -1)) ?
                                                                    <input className="second-check-box" checked="checked" type="checkbox" onClick={(e) => this.onCheckboxClick(e, item)} />
                                                                    :
                                                                    <input className="second-check-box" type="checkbox" onClick={(e) => this.onCheckboxClick(e, item)} />
                                                                }
                                                                <span className="checkmark101 green_CheckMark"></span>
                                                            </label>
                                                        </td>
                                                        <td className="text-center">
                                                            <div className="panel-commentCount">
                                                                <img src="/vendors/Vector Smart Object.png" alt="comments" className={item.commentCount === 0 ? "disableCommentsOpacity" : ""} onClick={() => this.LoadComments(item)} />
                                                                {item.unreviewCommentCount !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">{item.unreviewCommentCount}</span>}
                                                            </div>
                                                        </td>
                                                        <td className="text-center"><img src="/vendors/grrenLink.png" alt="link" onClick={() => this.onLinkClick(item)} /></td>
                                                    </tr>}
                                                    {this.state.editIndex === j &&
                                                            <React.Fragment>
                                                                <tr className="editRowColor">
                                                                    <td colSpan="2">
                                                                        <label>New Account</label>
                                                                        <RSelect
                                                                            required={true}
                                                                            name="newAccountId"
                                                                            value={this.state.selectedAccount}
                                                                            onChange={(e) => this.handalAccountDropdown(e, j, item)}
                                                                            options={this.state.DisplayAccounts}
                                                                            //defaultValue={{ value: 0, label: "select" }}
                                                                            placeholder="Select"
                                                                        />
                                                                    </td>
                                                                    <td colSpan="5">
                                                                                <label>Memo/Description</label>
                                                                                <Textarea className="placheholder-color"
                                                                                                    value={item.memoOrDescription} name="memoOrDescription"
                                                                                                    style={{ 'lineHeight': '20px', width: '100%', border: 'none', resize: 'none', padding: '6px', paddingLeft: '10px', borderRadius: '3px', border: '1px solid #ccc', overflow: 'hidden' }}
                                                                                                    resize={"false"} placeholder=""
                                                                                                    onChange={(e) => this.handalTextArea(e, j, item)} />
                                                                    </td>
                                                                    <td >                                                                        
                                                                            <div className="chiller_cb " style={{marginTop:"20px"}}>
                                                                                <input id="needsBetterDescription1" name="needsBetterDescription" type="checkbox" checked={item.needsBetterDescription} onChange={(e) => this.handalCheckbox(e, j, item)} />
                                                                                <label for="needsBetterDescription1">
                                                                                <p className="checkbox-title">NBD {item.needsBetterDescription}</p>
                                                                                </label>
                                                                                <span></span>
                                                                            </div>                                                                            
                                                                    </td>
                                                                    <td >                                                                        
                                                                            <div className="chiller_cb " style={{marginTop:"20px"}}>
                                                                                <input id="cpaReview1" name="cpaReview" type="checkbox" checked={item.cpaReview } onChange={(e) => this.handalCheckbox(e, j, item)} />
                                                                                <label for="cpaReview1">
                                                                                    <p className="checkbox-title">CPA {item.cpaReview}</p>
                                                                                </label>
                                                                                <span></span>
                                                                            </div>                                                                            
                                                                    </td>  
                                                                    <td colSpan="5">&nbsp;</td>                                                                      
                                                                </tr>
                                                                <tr className="editRowColor">
                                                                    <td colSpan="10">
                                                                        <div className="row">
                                                                            <div className="col-md-5">
                                                                                <div className="row">
                                                                                    <div className="col-md-3">
                                                                                        <label className="input-title">Questions and Comments:</label>
                                                                                    </div>
                                                                                    <div className="col-md-9">
                                                                                        <Textarea className="placheholder-color"
                                                                                            value={item.accountantComment} name="accountantComment"
                                                                                            style={{ 'lineHeight': '20px', width: '100%', border: 'none', resize: 'none', padding: '6px', paddingLeft: '10px', borderRadius: '3px', border: '1px solid #ccc', overflow: 'hidden' }}
                                                                                            resize={"false"} placeholder=""
                                                                                            onChange={(e) => this.handalTextArea(e, j, item)} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-5">
                                                                                <div className="row">
                                                                                    <div className="col-md-3">
                                                                                        <label className="input-title">Internal Notes:</label>
                                                                                    </div>
                                                                                    <div className="col-md-9">
                                                                                        <Textarea className="placheholder-color"
                                                                                            value={item.internalNotes} name="internalNotes"
                                                                                            style={{ 'lineHeight': '20px', width: '100%', border: 'none', resize: 'none', padding: '6px', paddingLeft: '10px', borderRadius: '3px', border: '1px solid #ccc', overflow: 'hidden' }}
                                                                                            resize={"false"} placeholder=""
                                                                                            onChange={(e) => this.handalTextArea(e, j, item)} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <Button variant="raised" className="jr-btn jr-btn-sm bg-teal text-white" onClick={() => this.onRowUpdateClick(j, item)}>SAVE</Button>
                                                                                <Button variant="raised" className="jr-btn jr-btn-sm bg-danger text-white" onClick={() => this.onRowCancelClick(j, item)}>CANCEL</Button>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </React.Fragment>
                                                        }
                                                </React.Fragment>
                                            ))}

                                            {this.state.MissingData.length == 0 ?
                                                <tr>
                                                    <td className="text-center" colSpan="10">
                                                    No issues here! Keep up the good work!
                                                    </td>
                                                </tr>
                                                : ""}
                                        </tbody>
                                    </table>
                                </div>
                                <PaginationComponent
                                    selectedPageNo={this.state.PageNo}
                                    selectedSize={this.state.PageSize}
                                    TotalRecords={this.state.TotalRecords}
                                    ChangePageSize={this.ChangePageSize}
                                    ChangePage={this.ChangePage}
                                    CurrentRecords={this.state.MissingData.length}
                                >
                                </PaginationComponent>
                            </div>
                        }
                    </div>
                
            </React.Fragment>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        ReportData: state.ReportData.ReportData,
        UpdateDescription: state.ReportData.UpdateDescription,
        accounts: state.accounts.accountsData,
        LEDGER_UPDATE:state.ledgers.LEDGER_UPDATE
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        GET_REPORT_DATA,
        MARK_RESOLVED_DATA,
        Accounts,
        LedgerUpdate
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewNBDComponenet);
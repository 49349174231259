import baseService from './baseService';

export function Accountants(){
    return baseService.get('Accountant/Get/all');
}

export function UpdateAccountant(data){
    return baseService.put('/Accountant/Update',null, { params: { UserId:data.userId,CompanyId:data.companyId}});
}

export function AddAccountant(data){
    return baseService.post('/Accountant/Assign',data.body,{ params: { UserId:data.userId}});
}

export function RemoveAccountant(data){
    return baseService.delete('/Accountant/Delete',{ params: { UserId:data.userId,CompanyId:data.companyId}});
}

import * as UserService from '../services/userService';
import  jwt_decode from 'jwt-decode';
import React from 'react';
import { toast } from "react-toastify";


export const PROFILE_GET = "PROFILE_GET";

export const PROFILE_LOAD = "PROFILE_LOAD";

export const PROFILE_UPDATE = "PROFILE_UPDATE";


export function getProfile(data){
    return (dispatch) => {
        UserService.getProfile(data)
        .then(res => {
            dispatch({type:'PROFILE_GET',payload:res.data});
        })
        .catch(err => {
             toast.error(`${err.response.data}`)
        })
    }
}

export function updateProfile(data){
    return (dispatch) => {
        dispatch({type:'PROFILE_LOAD'});
        UserService.updateProfile(data)
        .then(res => {
            dispatch({type:'PROFILE_UPDATE',payload:data});
            toast.success("Profile Updated Successfully.");
        })
        .catch(err => {
             toast.error(`${err.response.data}`)
        })
    }
}


export function uploadImage(data){
    return (dispatch) => {
        
        UserService.uploadImage(data)
        .then(res => {
            dispatch({type:'PROFILE_IMAGE_SUCCESS',payload:res.data});
            toast.success("Profile Image Uploaded Successfully.");
        })
        .catch(err => {
             toast.error(`${err.response.data}`)
        })
    }
}
export function getProfileImage(){
    return (dispatch) => {
        
        UserService.getProfileImage()
        .then(res => {
            dispatch({type:'PROFILE_IMAGE_SUCCESS',payload:res.data});
        })
        .catch(err => {                        
            if(err.response!=null) {
                toast.error(`${err.response.data}`)
            }
            else{
                toast.error(`${err.response}`)
            }
             
        })
    }
}





import {
    RETIREMENTPLAN_SUCCESS, RETIREMENTPLAN_ERROR,
    ADDRETIREMENTPLAN_SUCCESS, ADDRETIREMENTPLAN_ERROR,
    UPDATERETIREMENTPLAN_SUCCESS, UPDATERETIREMENTPLAN_ERROR,
    DELETERETIREMENTPLAN_SUCCESS, DELETERETIREMENTPLAN_ERROR,
} from '../actions/retirementPlanAction';


const initial_state = {
    retirementPlans:[],
};

export default function retirementPlanReducer(state = initial_state, action) {
    switch (action.type) {
        case RETIREMENTPLAN_SUCCESS:
            return {...state,
               retirementPlans:action.payload.data,reload:false
         };
        case RETIREMENTPLAN_ERROR:
            return {...state};
        case ADDRETIREMENTPLAN_SUCCESS:
            return {...state, reload:true};
        case ADDRETIREMENTPLAN_ERROR:
            return {...state};
        case DELETERETIREMENTPLAN_SUCCESS:
            return {...state, reload:true};
        case DELETERETIREMENTPLAN_ERROR:
            return {...state};
        case UPDATERETIREMENTPLAN_SUCCESS:
            return {...state, reload:true};
        case UPDATERETIREMENTPLAN_ERROR:
            return {...state};
        case 'RELOAD':
            return {...state, reload:true};
        default:
            return state;
    }
}

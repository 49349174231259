import * as EmployeeService from '../services/EmployeeService';
import  jwt_decode from 'jwt-decode';
import React from 'react';
import { toast } from "react-toastify";
import { resetWarningCache } from 'prop-types';

export const EMPLOYEE_SUCCESS = "EMPLOYEE_SUCCESS";
export const EMPLOYEE_ERROR = "EMPLOYEE_ERROR";

export const ADDEMPLOYEE_SUCCESS = "ADDEMPLOYEE_SUCCESS";
export const ADDEMPLOYEE_ERROR = "ADDEMPLOYEE_ERROR";

export const UPDATEEMPLOYEE_SUCCESS = "UPDATEEMPLOYEE_SUCCESS";
export const UPDATEEMPLOYEE_ERROR = "UPDATEEMPLOYEE_ERROR";

export const DELETEEMPLOYEE_SUCCESS = "DELETEEMPLOYEE_SUCCESS";
export const DELETEEMPLOYEE_ERROR = "DELETEEMPLOYEE_ERROR";

export const EMPLOYEE_TYPE_SUCCESS = "EMPLOYEE_TYPE_SUCCESS";
export const EMPLOYEE_TYPE_ERROR = "EMPLOYEE_TYPE_ERROR";

export const FLAGEMPLOYEE_SUCCESS ="FLAGEMPLOYEE_SUCCESS";
export const FLAGEMPLOYEE_ERROR ="FLAGEMPLOYEE_ERROR";

export const GET_HOUSINGALLOWNCE_HISTORY ="GET_HOUSINGALLOWNCE_HISTORY";

export const BULK_UPDATE="BULK_UPDATE";

function EmployeeSuccess (payload){
    return{
        type: EMPLOYEE_SUCCESS,
        payload
    }
}

export function getEmployees(companyId,search,pageNumber,pageSize,sortField,sortFormat,Active){
    return (dispatch) => {
        // dispatch()
        EmployeeService.getEmployees(companyId,search,pageNumber,pageSize,sortField,sortFormat,Active)
        .then((res) => {
            return dispatch(EmployeeSuccess(res))
        })
        .catch(err => {
             toast.error(`${err.response.data}`)
        })
    }
}

export function getEmployees_BES(companyId,search,pageNumber,pageSize,sortField,sortFormat){
    return (dispatch) => {
        // dispatch()
        EmployeeService.getEmployees_BES(companyId,search,pageNumber,pageSize,sortField,sortFormat)
        .then((res) => {
            return dispatch(EmployeeSuccess(res))
        })
        .catch(err => {
             toast.error(`${err.response.data}`)
        })
    }
}





export function getAllEmployees(companyId){
    return (dispatch) => {
        // dispatch()
        EmployeeService.getAllEmployees(companyId)
        .then((res) => {
            return dispatch(EmployeeSuccess(res))
        })
        .catch(err => {
             toast.error(`${err.response.data}`)
        })
    }
}

export function addEmployee(data){
    return (dispatch) => {
        EmployeeService.addEmployee(data)
        .then(res => {
            dispatch({type:'RELOAD',payload:data});
            toast.success("Employee Added Successfully.");
        })
        .catch(err => {
             toast.error(`${err.response.data}`)
        })
    }
}

export function updateEmployee(data){
    return (dispatch) => {       
        EmployeeService.updateEmployee(data)
        .then(res => {
            dispatch({type:'RELOAD',payload:resetWarningCache});
            toast.success("Employee Updated Successfully.");
        })
        .catch(err => {           
             toast.error(`${err}`);
        })
    }
}

export function deleteEmployee(value){
    return (dispatch) => {
        EmployeeService.deleteEmployee(value)
        .then(res => {
            toast.success("Employee Deleted Successfully.");
            return dispatch({type:'RELOAD',payload:res});            
        })
        .catch(err => {
             toast.error(`${err}`)
        })
    }
}

export function SetEmployeeFlag(data)
{
    return (dispatch) => {
        EmployeeService.FlagEmployee(data)
        .then(res => {
            toast.success("Updated Successfully.");
            return dispatch({type:'RELOAD',payload:res});            
        })
        .catch(err => {
             toast.error(`${err}`)
        })
    }
}

export  function VehicleFormReceive(data)
{    
    return (dispatch) => {
        EmployeeService.UpdateVehicleFormReceived(data)
        .then(res => {            
            return dispatch({type:BULK_UPDATE,payload:res});            
        })
        .catch(err => {
             toast.error(`${err.response.data}`)
        })
    }    
}

export  function AddToPayroll(data)
{    
    return (dispatch) => {
        EmployeeService.IsAddedPayroll(data)
        .then(res => {            
            return dispatch({type:BULK_UPDATE,payload:res});            
        })
        .catch(err => {
             toast.error(`${err}`);
        })
    }    
}

export function Updatetype(data)
{
    //
    return (dispatch) => {
        EmployeeService.UpdateEmployeeType(data)
        .then(res => {            
            return dispatch({type:BULK_UPDATE,payload:res});            
        })
        .catch(err => {
             toast.error(`${err}`);
        })
    }   

}

export function Updatetype_BES(data)
{
    //
    return (dispatch) => {
        EmployeeService.UpdateEmployeeType_BES(data)
        .then(res => {            
            return dispatch({type:BULK_UPDATE,payload:res});            
        })
        .catch(err => {
             toast.error(`${err}`);
        })
    }   

}


export function SetBatchEmployeeFlag(data)
{
    return (dispatch) => {
        EmployeeService.SetBatchEmployeeFlag(data)
        .then(res => {
            toast.success("Updated Successfully.");
            return dispatch({type:'RELOAD',payload:res});            
        })
        .catch(err => {
             toast.error(`${err}`)
        })
    }
}

export function GET_HousingAllowanceHistory_DATA(data){
    return (dispatch) => {
        // dispatch()
        EmployeeService.GET_HousingAllowanceHistory_DATA(data)
        .then((res) => {
            dispatch({ type: GET_HOUSINGALLOWNCE_HISTORY, payload: res });
        })
        .catch(err => {
             toast.error(`${err}`)
        })
    }
}
import {
    PAYROLL_TAX_COMPUTATION,
    MARKSTATUS
} from '../actions/PayrollTaxComputationAction';

const initial_state = {
    PYTaxData:null,
    MarkStatus:null
};
export default function authReducer(state = initial_state, action) {
    switch (action.type) {        
        case PAYROLL_TAX_COMPUTATION:        
            return {... state, PYTaxData : action.payload.data};
            // return {...state,PYTaxData: action.payload.data }
           // return { ...action.payload.data };
        case MARKSTATUS: 
           return {... state, MarkStatus : action.payload.data};
        default:
            return state;
    }
}

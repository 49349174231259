export const chartData = [
  {name: '6.00', uv: 500, pv: 1100, amt: 20},
  {name: '7.00', uv: 600, pv: 1600, amt: 21},
  {name: '8.00', uv: 500, pv: 1900, amt: 29},
  {name: '9.00', uv: 600, pv: 2100, amt: 20},
  {name: '10.00', uv: 700, pv: 2500, amt: 28},
  {name: '11.00', uv: 800, pv: 2200, amt: 20},
  {name: '12.00', uv: 700, pv: 2000, amt: 20},
  {name: '13.00', uv: 800, pv: 1900, amt: 20},
  {name: '14.00', uv: 850, pv: 1700, amt: 20},
  {name: '15.00', uv: 1000, pv: 2100, amt: 20},
];

export const chartData1 = [
  {name: 'Let A', uv: 20, pv: 40, amt: 20},
  {name: 'Let B', uv: 60, pv: 25, amt: 21},
  {name: 'Let C', uv: 20, pv: 98, amt: 29},
  {name: 'Let D', uv: 50, pv: 39, amt: 20},
  {name: 'Let E', uv: 30, pv: 60, amt: 28},
  {name: 'Let F', uv: 70, pv: 38, amt: 20},
  {name: 'Let G', uv: 56, pv: 43, amt: 20},
];
export const chartData2 = [
  {name: 'Let A', uv: 40, pv: 24, amt: 20},
  {name: 'Let B', uv: 10, pv: 13, amt: 21},
  {name: 'Let C', uv: 60, pv: 98, amt: 29},
  {name: 'Let D', uv: 27, pv: 15, amt: 20},
  {name: 'Let E', uv: 56, pv: 45, amt: 28},
  {name: 'Let F', uv: 23, pv: 56, amt: 20},
  {name: 'Let G', uv: 56, pv: 43, amt: 20},
];

export const expanseData = [
  {name: 'Jan', Income: 10, Expanse: 22},
  {name: 'Feb', Income: 5, Expanse: 20},
  {name: 'March', Income: 15, Expanse: 26},
  {name: 'April', Income: 13, Expanse: 22},
  {name: 'May', Income: 35, Expanse: 26},
  {name: 'June', Income: 25, Expanse: 20},
  {name: 'July', Income: 20, Expanse: 32},
  {name: 'Aug', Income: 25, Expanse: 35},
];

export const siteTrafficData = [
  {name: 'Page A', newVisitor: 4064, repeatedUser: 2423, subscriber: 2423, share: 1578},
  {name: 'Page B', newVisitor: 3045, repeatedUser: 1398, subscriber: 2234, share: 1145},
  {name: 'Page C', newVisitor: 2045, repeatedUser: 9845, subscriber: 2290, share: 956},
  {name: 'Page D', newVisitor: 2784, repeatedUser: 3908, subscriber: 2076, share: 1800},
  {name: 'Page E', newVisitor: 1890, repeatedUser: 4834, subscriber: 2181, share: 1246},
  {name: 'Page F', newVisitor: 2393, repeatedUser: 3875, subscriber: 2553, share: 1083},
  {name: 'Page G', newVisitor: 3490, repeatedUser: 4334, subscriber: 2104, share: 1683},
];

export const expanseData1 = [
  {name: 'Jan', Income: 32, Expanse: 3},
  {name: 'Feb', Income: 5, Expanse: 74},
  {name: 'March', Income: 100, Expanse: 2},
  {name: 'April', Income: 3, Expanse: 56},
  {name: 'May', Income: 126, Expanse: 15},
  {name: 'June', Income: 16, Expanse: 80},
  {name: 'July', Income: 140, Expanse: 2},
  {name: 'Aug', Income: 12, Expanse: 135},
  {name: 'Sep', Income: 95, Expanse: 5},
  {name: 'Oct', Income: 5, Expanse: 80},
  {name: 'Nov', Income: 126, Expanse: 20},
];

export const pieChartData = [
  {name: 'J', amt: 24},
  {name: 'F', amt: 18},
  {name: 'M', amt: 22},
  {name: 'A', amt: 17},
  {name: 'M', amt: 25},
  {name: 'J', amt: 12},
  {name: 'J', amt: 21},
];
export const post = [
  {
    image: 'http://via.placeholder.com/500x330',
    title: "Up unpacked friendly",
    description: "Contrary to popular belief, Lorem Ipsum is not simply random text."
  },
  {
    image: 'http://via.placeholder.com/500x330',
    title: "It allowance prevailed",
    description: "It is a long established fact that a reader will be distracted"
  },
  {
    image: 'http://via.placeholder.com/500x330',
    title: "Case read they must",
    description: "There are many variations of passages of Lorem Ipsum available"
  },
  {
    image: 'http://via.placeholder.com/500x330',
    title: "Too carriage attended",
    description: "The standard chunk of Lorem Ipsum used since the 1500s"
  },
];

export const countryList1 = [
  {name: 'United State', flagCode: 'us', badge: '27', badgeColor: 'primary'},
  {name: 'France', flagCode: 'fr', badge: '32', badgeColor: 'teal'},
  {name: 'Germany', flagCode: 'gm', badge: '18', badgeColor: 'purple'},
  {name: 'Spain', flagCode: 'es', badge: '30', badgeColor: 'cyan'},
];
export const countryList2 = [
  {name: 'United Kingdom', flagCode: 'gm', badge: '27', badgeColor: 'secondary'},
  {name: 'Brazil', flagCode: 'br', badge: '32', badgeColor: 'success'},
  {name: 'India', flagCode: 'in', badge: '18', badgeColor: 'warning'},
  {name: 'Canada', flagCode: 'gm', badge: '30', badgeColor: 'danger'},
];
export const cardData = {
  img: 'http://via.placeholder.com/80x95',
  title: '23',
  subTitle: 'iDeas',
};
export const cardData1 = {
  img: 'http://via.placeholder.com/80x95',
  title: '387',
  subTitle: 'Docs',
};
export const cardData2 = {
  img: 'http://via.placeholder.com/80x95',
  title: 'New Customers',
  subTitle: '543',
};

export const cardData3 = {
  img: 'http://via.placeholder.com/80x95',
  title: 'Popular Products',
  subTitle: '22 new products',
};

export const data1 = [
  {name: 'Let A', uv: 40, pv: 24, amt: 20},
  {name: 'Let B', uv: 60, pv: 13, amt: 21},
  {name: 'Let C', uv: 30, pv: 98, amt: 29},
  {name: 'Let D', uv: 50, pv: 39, amt: 20},
  {name: 'Let E', uv: 20, pv: 48, amt: 28},
  {name: 'Let F', uv: 70, pv: 38, amt: 20},
  {name: 'Let G', uv: 40, pv: 43, amt: 20},
];

export const connections = [
  {
    id: 1,
    image: 'http://via.placeholder.com/150x150',
    name: "Domnic Brown",
    about: "@domnic.brown",
    detail: "Unfollow",
    color: "primary"
  },
  {
    id: 2,
    image: 'http://via.placeholder.com/150x150',
    name: "Stella Jhonson",
    about: "@stella.jhonson",
    detail: "Follow",
    color: "light"
  },
  {
    id: 3,
    image: 'http://via.placeholder.com/150x150',
    name: "John Smith",
    about: "@john.s",
    detail: "Unfollow",
    color: "primary"
  }
];

export const todoData = [
  {
    'id': 1,
    'notes': 'Create wireframes for Mouldifi',
    'selected': false,
  },
  {
    'id': 2,
    'notes': 'Design PSD files for Jambo',
    'selected': true,
  },
  {
    'id': 3,
    'notes': 'Bring the latest idea to the digital paper and\n' +
    'send this for the selection.',
    'selected': false,
  },
  {
    'id': 4,
    'notes': 'Deploy existing code to example.com',
    'selected': false,
  },
  {
    'id': 5,
    'notes': 'New logo design for Mouldifi project',
    'selected': true,
  },
  {
    'id': 6,
    'notes': 'Deploy existing code to example.com',
    'selected': false,
  }
];


export const FinancialData = [
  {
    id: 1,
    'Title': 'Transactions Needs a Better Description',
    RecordCount: 10,
    link:'/ledgers'
  },

  {
    id: 2,
    'Title': 'Change Account Number',
    RecordCount: 20,
    link:'/vendor'
  },

  {
    id: 3,
    'Title': 'Transaction Questions',
    RecordCount: 10,
    link:'/dashboard'
  },

  {
    id: 4,
    'Title': ' General Questions',
    RecordCount: 5,
    link:'/dashboard'
  },

];


export const ComplianceData = [
  {
    id: 1,
    'Title': ' Update Employee Type',
    RecordCount: 2,
    link:"/dashboard"
  },

  {
    id: 2,
    'Title': 'Update Vendor Type',
    RecordCount: 5,
    link:"/dashboard"
  },

  {
    id: 3,
    'Title': 'Vendors Pending W-9 report',
    RecordCount: 15,
    link:"/reportReview"
  },

  {
    id: 4,
    'Title': 'Employees Pending W-4 report',
    RecordCount: 10,
    link:"/reportReview"
  },
  {
    id: 5,
    'Title': 'Taxable Fringe Benefits Report',
    RecordCount: 20,
    link:"/taxableReview"
  },
  {
     id: 6,
    'Title': "1099’s Pending Approval Report",
    RecordCount: 10,
    link:"/accoutantReview"
  },

];



export const chartDataWithoutAxis = [
  {name: 'J', amt: 1400},
  {name: 'F', amt: 2210},
  {name: 'M', amt: 1000},
  {name: 'A', amt: 2000},
  {name: 'M', amt: 1400},
  {name: 'J', amt: 2300},
  {name: 'J', amt: 500},
  {name: 'A', amt: 2000},
  {name: 'S', amt: 1500},
  {name: 'O', amt: 1000},
  {name: 'N', amt: 2000},
  {name: 'D', amt: 500},
  {name: 'M', amt: 1000},
  {name: 'A', amt: 2000},
  {name: 'M', amt: 1400},

];

export const lineChartData = [
  {name: 'J', amt: 24244},
  {name: 'F', amt: 18123},
  {name: 'M', amt: 22765},
  {name: 'A', amt: 17097},
  {name: 'M', amt: 25529},
  {name: 'J', amt: 18669},
  {name: 'J', amt: 21812},
  {name: 'S', amt: 14988},
  {name: 'O', amt: 18693},
  {name: 'N', amt: 22900},
  {name: 'D', amt: 25313},
];
export const radialData = [
  {name: 'Monthly Data', data: 48845, fill: '#3367d6'},
  {name: 'Orders Weekly', data: 8875, fill: '#83a6ed'},
  {name: 'Average Revenue', data: 21342, fill: '#8dd1e1'},
  {name: 'Total Revenue', data: 51875, fill: '#ffc658'},
  {name: 'Total Orders', data: 9223, fill: '#a4de6c'},
  {name: 'Past Orders', data: 8543, fill: '#d0ed57'},
];
import {
    GET_FINANICAL_ISSUES_DETAILS, GET_PRECHECK_ISSUES_DETAILS, GET_REVIEW_COMMENTS_DETAILS,
    GET_PAYROLLADMINISTRATION_DETAILS, GET_1099_ISSUES_DETAILS,GET_COMPANT_TAX_DETAILS
} from "../actions/AccountantDashboardAction";
import { AutoComplete } from "antd";

const initial_state = {
    FinancialData: null,
    PrecheckData: null,
    ReviewCommentsData: null,
    Adminstration1099Data: null,
    PayrollAdminstrationData: null,
    CompanyTaxData:null
};


export default function authReducer(state = initial_state, action) {
    switch (action.type) {
        case GET_FINANICAL_ISSUES_DETAILS:
            return { ...state, FinancialData: action.payload.data };
        case GET_PRECHECK_ISSUES_DETAILS:
            return { ...state, PrecheckData: action.payload.data };
        case GET_REVIEW_COMMENTS_DETAILS:
            return { ...state, ReviewCommentsData: action.payload.data };
        case GET_1099_ISSUES_DETAILS:
            return { ...state, Adminstration1099Data: action.payload.data };
        case GET_PAYROLLADMINISTRATION_DETAILS:
            return { ...state, PayrollAdminstrationData: action.payload.data };
            case GET_COMPANT_TAX_DETAILS:
            return { ...state, CompanyTaxData: action.payload.data };
        default:
            return state;
    }
}

import baseService from './baseService';

export function Vendors(companyId) {
    return baseService.get('Vendor/all', { params: { companyId } });
}

export function Vendors_BES(companyId) {
    return baseService.get('Vendor/all_BES', { params: { companyId } });
}

export function getVendors(CompanyID, search, pageNumber, pageSize, sortField, sortFormat) {
    return baseService.post(`/Vendor/all_page`, { CompanyID, search, pageNumber, pageSize, sortField, sortFormat });
}
export function updateVendor(data) {
    return baseService.put(`Vendor?companyId=` + data.companyID, data);
}

export function updateCustomFileds(data) {
    return baseService.put(`Vendor/updatecustomfield`, data);
}

export function updateVendorType(data) {
    return baseService.post(`Vendor/vendortypes`, data);
}

export function getVendorTypes(value) {
    return baseService.get(`/Vendor/vendortypes`);
}

export function getDoNotIssueDropdownValues() {
    return baseService.get(`/Vendor/VendorW9And1099ReportingIssuesValues`);
}

export function getSocialSecurityWageAll(value) {
    return baseService.get(`/SocialSecurityWage/all`);
}
export function postSocialSecurityWageInsertUpdate(data) {
    return baseService.post(`/SocialSecurityWage/InsertUpdate`, data);
}

export function getEntitytypes() {
    return baseService.get(`/Vendor/entitytypes`);
}

export function searchVendor(companyId, text) {
    return baseService.get(`Vendor/${companyId}/search/${text}`);
}

export function SetVendorType(data)
{
    return baseService.put('/Vendor/SetVendorType',data);
}

export function AddNewVendor(data)
{
    return baseService.post('/Vendor/AddNewVendor',data);
}

export function AddNewVendor_BES(data)
{
    return baseService.post('/Vendor/AddNewVendor_BES',data);
}

export function uploadVendorAttachment(form,data){
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    return baseService.post(`/Vendor/UploadVendorAttachment?companyId=`+ data.companyId + '&EntityId='+data.entityId+ '&ContentType='+data.contentType,form,config);
}

export function uploadVendorAttachment_BES(form,data){
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    return baseService.post(`/Vendor/UploadVendorAttachment_BES?companyId=`+ data.companyId + '&EntityId='+data.entityId+ '&ContentType='+data.contentType,form,config);
}

export function GetVendorHistory(data) {
    let url = "/Vendor/GetVendorW9And1099ReportingIssuesHistory?companyId="+data.CompanyID+"&vendorId="+data.VendorID
    return baseService.get(url)
}
import * as VendorService from '../services/VendorService';


import React, { useDebugValue } from 'react';
import { toast } from "react-toastify";

export const VENDOR_GET = 'VENDOR_GET';
export const VENDOR_TYPES_GET = 'VENDOR_TYPES_GET';
export const ENTITY_TYPES_GET = 'ENTITY_TYPES_GET';
export const WAGES_LIST_GET = 'WAGES_LIST_GET';
export const WAGES_UPDATE = 'WAGES_UPDATE';
export const VENDOR_ADD = 'VENDOR_ADD';
export const ATTACHMENT_UPLOAD = 'ATTACHMENT_UPLOAD';
export const GET_VENDOR_HISTORY = 'GET_VENDOR_HISTORY';
export const GET_DONOTISSUE_VALUES = 'GET_DONOTISSUE_VALUES';

function getCompanyId() {
    let user = JSON.parse(localStorage.getItem('userData'));
    const CompanyId = user.companyId;
    return CompanyId;
}

export const Vendors = (id) => {
    return (dispatch) => {
        VendorService.Vendors(id)
            .then((response) => {
                dispatch({ type: VENDOR_GET, payload: response });
            })
            .catch((error) => {
                toast.error(`${error.response.data}`)
            })
    }
}

export const Vendors_BES = (id) => {
    return (dispatch) => {
        VendorService.Vendors_BES(id)
            .then((response) => {
                dispatch({ type: VENDOR_GET, payload: response });
            })
            .catch((error) => {
                toast.error(`${error.response.data}`)
            })
    }
}

export const GetVendors = (CompanyID, search, pageNumber, pageSize, sortField, sortFormat) => {
    return (dispatch) => {
        VendorService.getVendors(CompanyID, search, pageNumber, pageSize, sortField, sortFormat)
            .then((response) => {
                dispatch({ type: VENDOR_GET, payload: response });
            })
            .catch((error) => {
                toast.error(`${error.response.data}`)
            })
    }
}

export const searchVendor = (companyId, text) => {
    return (dispatch) => {
        VendorService.searchVendor(companyId, text)
            .then((response) => {
                dispatch({ type: VENDOR_GET, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response.data}`)
            })
    }
}

export const getSocialSecurityWageAll = () => {
    return (dispatch) => {
        VendorService.getSocialSecurityWageAll()
            .then((response) => {
                dispatch({ type: WAGES_LIST_GET, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response.data}`)
            })
    }
}

export const updateVendor = (data) => {
    return (dispatch) => {
        VendorService.updateVendor(data)
            .then((response) => {
                dispatch({ type: 'RELOAD', payload: response });
            })
            .catch((err) => {
                if (err.response.data != null) {
                    toast.error(`please fill all the details`);
                }
                else {
                    toast.error(`${err.response.data}`);
                }
            })
    }
}


export const updateCustomFileds = (data) => {
    return (dispatch) => {
        VendorService.updateCustomFileds(data)
            .then((response) => {
                dispatch({ type: 'RELOAD', payload: response });
                toast.success("Vendor Updated Successfully.");
            })
            .catch((err) => {
                toast.error(`${err.response.data}`)
            })
    }
}

export const postSocialSecurityWageInsertUpdate = (data) => {
    return (dispatch) => {
        VendorService.postSocialSecurityWageInsertUpdate(data)
            .then((response) => {
                dispatch({ type: WAGES_UPDATE, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response.data}`)
            })
    }
}

export const vendorTypes = () => {
    return (dispatch) => {
        VendorService.getVendorTypes()
            .then((response) => {
                dispatch({ type: VENDOR_TYPES_GET, payload: response });
            })
            .catch((err) => {
                alert(JSON.stringify(err));
                toast.error(`${err.response.data}`)
            });
    }
}

export const doNotIssueDeopdownValues = () => {
    return (dispatch) => {
        VendorService.getDoNotIssueDropdownValues()
            .then((response) => {
                dispatch({ type: GET_DONOTISSUE_VALUES, payload: response });
            })
            .catch((err) => {
                // alert(JSON.stringify(err));
                toast.error(`${err.response.data}`)
            });
    }
}

export const entitytypes = () => {
    return (dispatch) => {
        VendorService.getEntitytypes()
            .then((response) => {
                dispatch({ type: ENTITY_TYPES_GET, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response.data}`)
            })
    }
}
export const updateVendorType = (data) => {
    return (dispatch) => {
        VendorService.updateVendorType(data)
            .then((response) => {
                dispatch(vendorTypes());
                toast.success("Vendor Type Updated Successfully.");
            })
            .catch((err) => {
                toast.error(`${err.response.data}`)
            })
    }
}

export const SetVendorType = (data) => {
    return (dispatch) => {
        VendorService.SetVendorType(data)
            .then((response) => {                
                dispatch({ type: WAGES_UPDATE, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response.data}`)
            })
    }
}

export const AddNewVendor = (data) => {
    return (dispatch) => {
        VendorService.AddNewVendor(data)
            .then((response) => {                
                dispatch({ type: VENDOR_ADD, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response.data}`)
            })
    }
}

export const AddNewVendor_BES = (data) => {
    return (dispatch) => {
        VendorService.AddNewVendor_BES(data)
            .then((response) => {                
                dispatch({ type: VENDOR_ADD, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response.data}`)
            })
    }
}

export function uploadVendorAttachment(form,data){
    return (dispatch) => {
        VendorService.uploadVendorAttachment(form,data)
        .then(res => {
            dispatch({type:ATTACHMENT_UPLOAD,payload:res});
            toast.success("Attachment Uploaded Successfully.");
        })
        .catch(err => {
             toast.error(`${err.response.data}`)
        })
    }
}

export function uploadVendorAttachment_BES(form,data){
    return (dispatch) => {
        VendorService.uploadVendorAttachment_BES(form,data)
        .then(res => {
            dispatch({type:ATTACHMENT_UPLOAD,payload:res});
            toast.success("Attachment Uploaded Successfully.");
        })
        .catch(err => {
             toast.error(`${err.response.data}`)
        })
    }
}

export const GET_VENDORS_HISTORY_DATA = (data) => {

    return (dispatch) => {
        VendorService.GetVendorHistory(data)
            .then((response) => {
                dispatch({ type: GET_VENDOR_HISTORY, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`);
            });
    }
}
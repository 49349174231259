import baseService from './baseService';
export function CommentsData(data) {
    let url = "/Comment/data?CompanyID=" + data.CompanyID + "&Module=" + data.Module;
    url += "&RecordID=" + data.RecordID + "&ReportType=" + data.ReportType;
    return baseService.get(url);
}

export function CommentsData_BES(data) {
    let url = "/comment/data_BES?CompanyID=" + data.CompanyID + "&Module=" + data.Module;
    url += "&RecordID=" + data.RecordID + "&ReportType=" + data.ReportType;
    return baseService.get(url);
}


export function AddComment(data) {
    return baseService.post("/Comment/add", data);
}

export function AddClientComment(data) {
    return baseService.post("/Comment/addforclient", data);
}

export function Comment_unread(data) {
    return baseService.post("/Comment/unread", data);
}

export function Comment_unread_BES(data) {
    return baseService.post("/Comment/unread_BES", data);
}


export function GetHistory(data) {
    let url = "/Comment/getHistory?CompanyID=" + data.CompanyID;
    url += "&Module=" + data.Module;
    url += "&RecordID=" + data.RecordID;
    return baseService.get(url)
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import CommonComments from './../../../common/Comments';
import PaginationComponent from './../../../common/Pagination';
import { GET_RTC_REPORT_DATA } from '../../../../appRedux/actions/ReportDetailAction';
import { ReportType, ModuleType, makeLinkForQuickbooks, numberWithCommas,LinkType } from '../../../common/commonServices'
import moment from 'moment';
import { bindActionCreators } from 'redux';
import CircularProgress from './../../../../components/CircularProgress';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import RSelect from 'react-select';
import Textarea from 'react-expanding-textarea';
import { LedgerUpdate } from '../../../../appRedux/actions/LedgerAction';
import { Accounts } from '../../../../appRedux/actions/accountAction';
import _ from 'lodash';

class ReviewTransactionComment extends Component {

    constructor(props) {
        super(props);

        this.state = {
           
            MissingData: [],
            companyId: 0,
            sortField: 'date',
            sortFormat: "D",
            PageNo: 1,
            PageSize: 10,
            ColorCode: "#46b29d",
            IsCommentModelOpen: false,
            sDate: null,
            eDate: null,
            loading: false,
            TotalRecords: 0,
            CommentReferenceId: 0,
            IsEmbeddedPage: false,
            SelectedRecords: [],
            SelectALL: false,
            editedData: null,
            OriginalComment: null,
            editedIndex: -1,
            ReadFilter:2,
            DisplayAccounts: [],
            accounts: [],
            selectedAccount: { value: 0, label: "Select" },
        }

    }


    handleDateChange = (e, target) => {

        if (target === "fromDate") {

            this.setState({ sDate:e== null?null: new Date(e)})
        }
        else if (target === "uptoDate") {
            this.setState({ eDate:e== null?null: new Date(e) })
        }
    }


    HandleRead = (event) => {
        this.setState({ ReadFilter: event.target.value,loading:true },()=>{ this.GetReportData()});
    }


    onSortChange = (fieldName) => {
        if (this.state.sortField == fieldName) {
            let formate = this.state.sortFormat == 'A' ? 'D' : 'A';
            this.setState({ sortFormat: formate, loading: true }, () => {
                this.GetReportData();
            });
        } else {
            this.setState({ sortField: fieldName, sortFormat: 'A', loading: true }, () => {
                this.GetReportData();
            });
        }
    }

    LoadComments = (item) => {
        this.setState({ IsCommentModelOpen: true, CommentReferenceId: item.id });
    }

    CloseComments = () => {
        //this.setState({ IsCommentModelOpen: false ,loading:true},()=>{ this.GetReportData()});
        this.setState({ IsCommentModelOpen: false});
    }


    ChangeComments = (e, index, rowdata) => {
        var data = this.state.editedData;
        data.accountantComment = e.target.value
        this.setState({ editedData: data });
    }


    UpdateItemCommentCount = (id) => {
        let data = this.state.MissingData;
        data.forEach(function (item) {

            if (item.id === id) {
                item.commentCount++
            }
        });

        this.setState({ MissingData: data });
    }


    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('userData'));
        this.setState({
            companyId: user.companyId,
            loading: true
        }, () => {
            this.props.Accounts(this.state.companyId);
            this.GetReportData();
        });
    }

    ApplyDate = () => {
        this.setState({ loading: true }, () => {
            this.GetReportData();
        });
    }
    ChangePageSize = (size) => {

        this.setState({ loading: true, PageSize: parseInt(size), PageNo: 1 }, () => {
            this.GetReportData();
        });
    }

    ChangePage = (page) => {
        this.setState({ loading: true, PageNo: page, }, () => {
            this.GetReportData();
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.ReportData !== this.props.ReportData) {
            if (nextProps.ReportData.transactionStatus === 0) {
                var totalrecords = 0;
                if (nextProps.ReportData.returnObject != null && nextProps.ReportData.returnObject.length > 0) {
                    totalrecords = nextProps.ReportData.returnObject[0].totalrows
                }
                this.setState({
                    MissingData: nextProps.ReportData.returnObject, TotalRecords: totalrecords, PageSize: this.state.PageSize,
                    PageNo: this.state.PageNo, loading: false, SelectALL: false
                });
            }
            else {
                toast.error(nextProps.ReportData.resultMsg);
                this.setState({ loading: false });
            }
        }

        if (nextProps.LEDGER_UPDATE != undefined && nextProps.LEDGER_UPDATE !== this.props.LEDGER_UPDATE) {
            
            if (nextProps.LEDGER_UPDATE.transactionStatus === 0) {
                this.setState({
                    editedData: null, editedIndex: -1,loading: false
                }, () => {
                    toast.success("Record updated successfully");
                });
            }
            else {
                this.setState({
                    loading: false
                }, () => {
                    toast.error(`${nextProps.LEDGER_UPDATE.resultMsg}`);
                });
    
            }
        }
        if (nextProps.accounts != undefined && nextProps.accounts !== this.props.accounts) {

            let searchableAccounts = [];
            if (nextProps.accounts.data != null && nextProps.accounts.data.length > 0) {
                searchableAccounts = _.sortBy(nextProps.accounts.data, ['accountNumber', 'name']).map((value, index) => (
                    { value: value.accountID, label: (value.accountNumber != null ? value.accountNumber + ' ' + value.name : value.name) }
                ))
            }
            let defaultValue = { value: 0, label: "Select" };
            searchableAccounts.unshift(defaultValue);
            this.setState({
                accounts: nextProps.accounts.data,
                DisplayAccounts: searchableAccounts
            });
        }
    }

    

    onLinkClick = (rowData) => {
        let origin = makeLinkForQuickbooks();
        window.open(origin + rowData.transactionLink);
    }

    onRefreshDataClick = () => {
        if (!this.state.loading) {
            this.setState({ loading: true }, () => {
                this.GetReportData(true);
            });
        }
    }         

    GetReportData(IsRefresh = false) {
        let state = this.state;
        let body = 
        {
            "companyID": state.companyId,
            "fromDate": state.sDate== null?null:moment(state.sDate).format("MM/DD/YYYY"),
            "toDate": state.eDate==null?null:moment(state.eDate).format("MM/DD/YYYY"),
            "pageNumber": state.PageNo,
            "pageSize": state.PageSize,
            "readUnreadFlag": state.ReadFilter,
            "sortField":  state.sortField,
            "sortFormat": state.sortFormat                                                                                                          
        };

        this.props.GET_RTC_REPORT_DATA(body);
    }

    onRowEdit = (j, rowData) => {
        //this.setState({ editedData: rowData, OriginalComment: rowData.accountantComment, editedIndex: j });
    
        if (this.state.editedData && this.state.editedIndex > -1) {
            var dataOfGL = [...this.state.MissingData];
            //let editIndex1 = this.state.editIndex1;
            let editIndex = this.state.editedIndex;
            let editData = this.state.editedData;
            dataOfGL[editIndex].vendor1099 = editData.vendor1099;
            dataOfGL[editIndex].taxableFringeBenefit = editData.taxableFringeBenefit;
            dataOfGL[editIndex].needsBetterDescription = editData.needsBetterDescription;
            dataOfGL[editIndex].transferCodedIncorrectly = editData.transferCodedIncorrectly;
            dataOfGL[editIndex].accountantComment = editData.accountantComment;
            dataOfGL[editIndex].internalNotes = editData.internalNotes;
            dataOfGL[editIndex].newAccountId = editData.newAccountId;
            dataOfGL[editIndex].newAccount = editData.newAccount;
            dataOfGL[editIndex].cpaReview = editData.cpaReview;
            dataOfGL[editIndex].pcCheck = editData.pcCheck;
            dataOfGL[editIndex].memoOrDescription = editData.memoOrDescription;
            this.setState({ MissingData: dataOfGL });
        }
        let data = {
            taxableFringeBenefit: rowData.taxableFringeBenefit,
            vendor1099: rowData.vendor1099,
            needsBetterDescription: rowData.needsBetterDescription,
            transferCodedIncorrectly: rowData.transferCodedIncorrectly,
            accountantComment: rowData.accountantComment,
            internalNotes: rowData.internalNotes,
            newAccountId: rowData.newAccountId,
            newAccount: rowData.newAccount,
            cpaReview: rowData.cpaReview,
            pcCheck: rowData.pcCheck,
            memoOrDescription:rowData.memoOrDescription
        }
        this.setState({
             editedData: data, 
             editedIndex: j,
             selectedAccount:{ value: data.newAccountId, label: data.newAccount }
        });
    };

    onRowUpdateClick = (rowIndex, rowData) => {
   
        let data = {
            id: rowData.id,
            transactionTypeId: rowData.transactionTypeId,
            transactionType: rowData.transactionType,
            accountId: rowData.accountId,
            newAccountId: Number(rowData.newAccountId == '' ? 0 : rowData.newAccountId),
            needsBetterDescription: rowData.needsBetterDescription,
            transferCodedIncorrectly: rowData.transferCodedIncorrectly,
            cpaReview: rowData.cpaReview,
            pcCheck: rowData.pcCheck,
            accountantComment: rowData.accountantComment,
            internalNotes: rowData.internalNotes,
            taxableFringeBenefit: rowData.taxableFringeBenefit,
            vendor1099: rowData.vendor1099,
            taxReporting: rowData.taxReporting,
            isArchived: rowData.isArchived || false,
            benefitTypeId: rowData.benefitTypeId || 0,
            employeeName: rowData.employeeName || '',
            clientComment: rowData.clientComment || '',
            accountChangeApprovedByClient: rowData.accountChangeApprovedByClient || false,
            clientReviewStatus: rowData.clientReviewStatus || false,
            accountantReviewStatus: rowData.accountantReviewStatus || false,
            descriptionNeedsEmployeeName: rowData.descriptionNeedsEmployeeName || false,
            clientApprovedTaxableFringeBenefit: rowData.clientApprovedTaxableFringeBenefit || false,
            accountantPostedTaxableFringeBenefit: rowData.accountantPostedTaxableFringeBenefit || false,
            memoOrDescription:rowData.memoOrDescription,
        };

        this.props.LedgerUpdate(data, this.state.companyId);
        this.setState({
           // editedData: -1,
            editedIndex: -1,
            editedData: null,
            loading:true
        });
    }

    onRowCancelClick = (rowIndex, rowData) => {
        var data = [...this.state.MissingData];
        let editData = this.state.editedData;
        data[rowIndex].vendor1099 = editData.vendor1099;
        data[rowIndex].taxableFringeBenefit = editData.taxableFringeBenefit;
        data[rowIndex].needsBetterDescription = editData.needsBetterDescription;
        data[rowIndex].transferCodedIncorrectly = editData.transferCodedIncorrectly;
        data[rowIndex].accountantComment = editData.accountantComment;
        data[rowIndex].internalNotes = editData.internalNotes;
        data[rowIndex].newAccountId = editData.newAccountId;
        data[rowIndex].newAccount = editData.newAccount;
        data[rowIndex].cpaReview = editData.cpaReview;
        data[rowIndex].pcCheck = editData.pcCheck;
        data[rowIndex].memoOrDescription = editData.memoOrDescription;
        this.setState({
            MissingData: data,
            //editIndex1: -1,   
            editedIndex: -1,
            editedData: null
        });
    }

   
    handalAccountDropdown = (e, rowIndex) => {

        if (e.value == null || e.value == 0) {            
            var data = [...this.state.MissingData];
            data[rowIndex].newAccountId = null;
            data[rowIndex].newAccount = null;
            this.setState({ MissingData: data, selectedAccount: e });
        }
        else {
            let obj = this.state.accounts.find(x => x.accountID == e.value);
            var data = [...this.state.MissingData];
            data[rowIndex].newAccountId = e.value;
            data[rowIndex].newAccount = obj.name;
            this.setState({ MissingData: data, selectedAccount: e });
        }
    }

    handalCheckbox = (e, rowIndex) => {
        var data = [...this.state.MissingData];
        data[rowIndex][e.target.name] = e.target.checked;
        this.setState({ MissingData: data });
    }
    
    
     handalTextArea = (e, rowIndex) => {
        var data = [...this.state.MissingData];
        data[rowIndex][e.target.name] = e.target.value;
        this.setState({ MissingData: data });
    }
    render() {
        let selectedItem = this.state.SelectedRecords;
        return (
            <React.Fragment>
                {this.state.IsCommentModelOpen ? <CommonComments
                    CloseComments={this.CloseComments}
                    ColorCode={this.state.ColorCode}
                    ModuleType={ModuleType.GeneralReportType}
                    ReportType={ReportType.GL}
                    CompanyId={this.state.companyId}
                    RecordId={this.state.CommentReferenceId}
                    IsEmbeddedPage={false}
                    UpdateItemCommentCount={this.UpdateItemCommentCount}
                ></CommonComments> : ""}

                <div className="dashboardPage">
                    <div className="page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
                        <h2 className="mb-3 mb-sm-0">{JSON.parse(localStorage.getItem('userData')) != null ? JSON.parse(localStorage.getItem('userData')).companyName : ""} | { LinkType.ReviewTransactionComments}</h2>
                    </div>
                    {this.state.loading ? <CircularProgress />
                        :
                        <div className="padding-box app-wrapper">
                            <div className="row">
                            <div className="col-md-4  ">
                                        <div className="datepicker-box">
                                            <div className="date-box">
                                                <DatePicker
                                                    selected={this.state.sDate}
                                                    onChange={(e) => this.handleDateChange(e, 'fromDate')}
                                                    name="fromDate"
                                                    id="date"
                                                    required={true}
                                                    margin="normal"
                                                    disabledKeyboardNavigation
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    yearDropdownItemNumber={5}
                                                    maxDate={this.state.eDate}
                                                    ref={this.sDateRef}
                                                />
                                                <div className="date-icon" onClick={(e) => this.handleFocus(e, 'sDate')}>
                                                    <i className="zmdi zmdi-calendar-note"></i>
                                                </div>
                                            </div>
                                            <div className="to-box">
                                                <span className="text-center ledgerto-text">To</span>
                                            </div>
                                            <div className="date-box">
                                                <DatePicker
                                                    selected={this.state.eDate}
                                                    onChange={(e) => this.handleDateChange(e, 'uptoDate')}
                                                    name="uptoDate"
                                                    id="date"
                                                    required={true}
                                                    margin="normal"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    yearDropdownItemNumber={5}
                                                    disabledKeyboardNavigation
                                                    minDate={this.state.sDate}
                                                    maxDate={new Date()}
                                                    ref={this.eDateRef}
                                                />
                                                <div className="date-icon" onClick={(e) => this.handleFocus(e, 'eDate')}>
                                                    <i className="zmdi zmdi-calendar-note"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="genera-apply-btn" style={{ marginTop: "5px" }}>
                                            <Button variant="raised" className="greencolor jr-btn bg-teal text-white refresh-btn" onClick={(e) => this.ApplyDate()} >apply</Button>

                                        </div>

                                    </div>
                                <div className="col-md-8">
                                    <div className="float-right">
                                    <span> Read/Unread : </span>
                                                <select style={{ backgroundColor: "#fff", marginRight: "10px" }} className="select-box arrow-box" onChange={this.HandleRead} value={this.state.ReadFilter} >
                                                    <option value={0}> All </option>
                                                    <option value={1}> Read </option>
                                                    <option value={2}> Unread </option>
                                                </select>
                                        <Button variant="raised" className="jr-btn bg-teal text-white refresh-btn greencolor" onClick={() => this.onRefreshDataClick()}   ><i className="zmdi zmdi-refresh "></i> Refresh Data</Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive-material">
                                <table className="table tablebox account-reconcilliation">
                                    <thead  >
                                        <tr style={{ backgroundColor: this.state.ColorCode }}>
                                            <th className={`td-width ${this.state.sortField == 'date' ? this.state.sortFormat === 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('date')}><p className="table-th-title"> Date</p> </th>
                                            <th className={`td-width ${this.state.sortField == 'transactionType' ? this.state.sortFormat === 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('transactionType')}><p className="table-th-title"> Type</p></th>
                                            <th className={`td-width ${this.state.sortField == 'num' ? this.state.sortFormat === 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('num')}><p className="table-th-title"> Number</p></th>
                                            <th className={`td-width ${this.state.sortField == 'name' ? this.state.sortFormat === 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('name')}><p className="table-th-title"> Name</p> </th>
                                            <th className={`td-width-box ${this.state.sortField == 'account' ? this.state.sortFormat === 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('account')}><p className="table-th-title"> A/C No.</p></th>
                                            <th className={`td-width text-center ${this.state.sortField == 'newAccount' ? this.state.sortFormat === 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('newAccount')}><p className="table-th-title"> New A/C</p></th>
                                            <th className={`td-width ${this.state.sortField == 'memoOrDescription' ? this.state.sortFormat === 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('memoOrDescription')}><p className=" table-th-title"> Memo/Desc.</p></th>
                                            <th className={`td-width text-center ${this.state.sortField == 'amount' ? this.state.sortFormat === 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('amount')}><p className="table-th-title"> Amount</p></th>
                                            <th className={`td-width  ${this.state.sortField == 'accountantComment' ? this.state.sortFormat === 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('accountantComment')}><p className="table-th-title"> Questions and Comments</p></th>                                             
                                            <th className={`td-width  ${this.state.sortField == 'internalNotes' ? this.state.sortFormat === 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('internalNotes')}><p className="table-th-title"> Internal Comments</p></th>                                           
                                            <th className="td-width-90 text-center"><img src="/vendors/WhiteCommnet.png" alt="comments" /></th>
                                            <th className="td-width-90 text-center "><img src="/vendors/Link-arrow_header.png" alt="link" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(this.state.MissingData && this.state.MissingData.length > 0) && this.state.MissingData.map((item, j) => (
                                            <React.Fragment key={j}>
                                                {<tr onClick={(e) => this.onRowEdit(j, item)}>
                                                    <td>{item.date}</td>

                                                    <td>{item.transactionType}</td>
                                                    <td>{item.num}</td>
                                                    <td>{item.name} </td>
                                                    <td >{item.account}</td>
                                                    <td>{item.newAccount}</td>
                                                    <td>{item.memoOrDescription}</td>
                                                    <td className="text-center">{numberWithCommas(item.amount)}</td>
                                                    <td>{item.accountantComment}</td>
                                                    <td>{item.internalNotes}</td>
                                                    <td className="text-center" onClick={(e) => e.stopPropagation()}>
                                                        <div className="panel-commentCount">
                                                            <img src="/vendors/Vector Smart Object.png" alt="comments" className={item.commentCount === 0 ? "disableCommentsOpacity" : ""} onClick={() => this.LoadComments(item)}></img>
                                                            {item.unreviewCommentCount !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">{item.unreviewCommentCount}</span>}
                                                        </div>
                                                    </td>
                                                    <td className="text-center" onClick={(e) => e.stopPropagation()}><img src="/vendors/grrenLink.png" alt="link" onClick={() => this.onLinkClick(item)}></img></td>
                                                </tr>}

                                                {this.state.editedIndex === j &&
                                                            <React.Fragment>
                                                                <tr className="editRowColor">
                                                                    <td colSpan="2">
                                                                        <label>New Account</label>
                                                                        <RSelect
                                                                            required={true}
                                                                            name="newAccountId"
                                                                            value={this.state.selectedAccount}
                                                                            onChange={(e) => this.handalAccountDropdown(e, j, item)}
                                                                            options={this.state.DisplayAccounts}
                                                                            //defaultValue={{ value: 0, label: "select" }}
                                                                            placeholder="Select"
                                                                        />
                                                                    </td>
                                                                    <td colSpan="4">
                                                                                <label>Memo/Description</label>
                                                                                <Textarea className="placheholder-color"
                                                                                                    value={item.memoOrDescription} name="memoOrDescription"
                                                                                                    style={{ 'lineHeight': '20px', width: '100%', border: 'none', resize: 'none', padding: '6px', paddingLeft: '10px', borderRadius: '3px', border: '1px solid #ccc', overflow: 'hidden' }}
                                                                                                    resize={"false"} placeholder=""
                                                                                                    onChange={(e) => this.handalTextArea(e, j, item)} />
                                                                    </td>
                                                                    <td >                                                                        
                                                                            <div className="chiller_cb " style={{marginTop:"20px"}}>
                                                                                <input id="taxableFringeBenefit" name="taxableFringeBenefit" type="checkbox" checked={item.taxableFringeBenefit} onChange={(e) => this.handalCheckbox(e, j, item)} />
                                                                                <label for="taxableFringeBenefit">
                                                                                <p className="checkbox-title">TFB {item.taxableFringeBenefit}</p>
                                                                                </label>
                                                                                <span></span>
                                                                            </div>                                                                            
                                                                    </td>
                                                                    <td >                                                                        
                                                                            <div className="chiller_cb " style={{marginTop:"20px"}}>
                                                                                <input id="needsBetterDescription1" name="needsBetterDescription" type="checkbox" checked={item.needsBetterDescription} onChange={(e) => this.handalCheckbox(e, j, item)} />
                                                                                <label for="needsBetterDescription1">
                                                                                <p className="checkbox-title">NBD {item.needsBetterDescription}</p>
                                                                                </label>
                                                                                <span></span>
                                                                            </div>                                                                            
                                                                    </td>
                                                                    <td >                                                                        
                                                                            <div className="chiller_cb " style={{marginTop:"20px"}}>
                                                                                <input id="transferCodedIncorrectly" name="transferCodedIncorrectly" type="checkbox" checked={item.transferCodedIncorrectly} onChange={(e) => this.handalCheckbox(e, j, item)} />
                                                                                <label for="transferCodedIncorrectly">
                                                                                <p className="checkbox-title">TCI {item.transferCodedIncorrectly}</p>
                                                                                </label>
                                                                                <span></span>
                                                                            </div>                                                                            
                                                                    </td>
                                                                    <td >                                                                        
                                                                            <div className="chiller_cb " style={{marginTop:"20px"}}>
                                                                                <input id="pcCheck" name="pcCheck" type="checkbox" checked={item.pcCheck} onChange={(e) => this.handalCheckbox(e, j, item)} />
                                                                                <label for="pcCheck">
                                                                                <p className="checkbox-title">PC {item.pcCheck}</p>
                                                                                </label>
                                                                                <span></span>
                                                                            </div>                                                                            
                                                                    </td>
                                                                    <td >                                                                        
                                                                            <div className="chiller_cb " style={{marginTop:"20px"}}>
                                                                                <input id="cpaReview" name="cpaReview" type="checkbox" checked={item.cpaReview } onChange={(e) => this.handalCheckbox(e, j, item)} />
                                                                                <label for="cpaReview">
                                                                                    <p className="checkbox-title">CPA {item.cpaReview}</p>
                                                                                </label>
                                                                                <span></span>
                                                                            </div>                                                                            
                                                                    </td>  
                                                                    <td colSpan="2">&nbsp;</td>                                                                      
                                                                </tr>
                                                                <tr className="editRowColor">
                                                                    <td colSpan="12">
                                                                        <div className="row">
                                                                            <div className="col-md-5">
                                                                                <div className="row">
                                                                                    <div className="col-md-3">
                                                                                        <label className="input-title">Questions and Comments:</label>
                                                                                    </div>
                                                                                    <div className="col-md-9">
                                                                                        <Textarea className="placheholder-color"
                                                                                            value={item.accountantComment} name="accountantComment"
                                                                                            style={{ 'lineHeight': '20px', width: '100%', border: 'none', resize: 'none', padding: '6px', paddingLeft: '10px', borderRadius: '3px', border: '1px solid #ccc', overflow: 'hidden' }}
                                                                                            resize={"false"} placeholder=""
                                                                                            onChange={(e) => this.handalTextArea(e, j, item)} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-5">
                                                                                <div className="row">
                                                                                    <div className="col-md-3">
                                                                                        <label className="input-title">Internal Notes:</label>
                                                                                    </div>
                                                                                    <div className="col-md-9">
                                                                                        <Textarea className="placheholder-color"
                                                                                            value={item.internalNotes} name="internalNotes"
                                                                                            style={{ 'lineHeight': '20px', width: '100%', border: 'none', resize: 'none', padding: '6px', paddingLeft: '10px', borderRadius: '3px', border: '1px solid #ccc', overflow: 'hidden' }}
                                                                                            resize={"false"} placeholder=""
                                                                                            onChange={(e) => this.handalTextArea(e, j, item)} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <Button variant="raised" className="jr-btn jr-btn-sm bg-teal text-white" onClick={() => this.onRowUpdateClick(j, item)}>SAVE</Button>
                                                                                <Button variant="raised" className="jr-btn jr-btn-sm bg-danger text-white" onClick={() => this.onRowCancelClick(j, item)}>CANCEL</Button>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </React.Fragment>
                                                        }
                                                {/* {this.state.editedIndex === j && <tr>
                                                    <td colSpan="5"> &nbsp; </td>
                                                    <td></td>
                                                    <td>
                                                        <textarea cols="50" value={this.state.editedData.accountantComment} onChange={(e) => this.ChangeComments(e, j, item)}></textarea>
                                                    </td>
                                                    <td colSpan="2">
                                                        <div className="col-md-12">
                                                            <Button variant="raised" className="jr-btn jr-btn-sm bg-teal text-white" onClick={() => this.onRowSave(j, item)}>SAVE</Button>
                                                            <Button variant="raised" className="jr-btn jr-btn-sm bg-danger text-white" onClick={() => this.onRowCancel(j, item)}>CANCEL</Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                } */}
                                            </React.Fragment>
                                        ))}
                                        {this.state.MissingData.length == 0 ?
                                            <tr>
                                                <td className="text-center" colSpan="12">
                                                No issues here! Keep up the good work!
                                                    </td>
                                            </tr>
                                            : ""}
                                    </tbody>
                                </table>
                            </div>
                            <PaginationComponent
                                selectedPageNo={this.state.PageNo}
                                selectedSize={this.state.PageSize}
                                TotalRecords={this.state.TotalRecords}
                                ChangePageSize={this.ChangePageSize}
                                ChangePage={this.ChangePage}
                                CurrentRecords={this.state.MissingData.length}
                            >
                            </PaginationComponent>
                        </div>
                    }
                </div>

            </React.Fragment>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        ReportData: state.ReportData.ReportData,
        LEDGER_UPDATE:state.ledgers.LEDGER_UPDATE,
        accounts: state.accounts.accountsData,
    };
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        GET_RTC_REPORT_DATA,
        LedgerUpdate,
        Accounts
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(ReviewTransactionComment);
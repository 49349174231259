import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CircularProgress from './../../../../components/CircularProgress';
import { toast } from 'react-toastify';
// import moment from 'moment';
import PaginationComponent from './../../../common/Pagination';
import { GET_CTI_REPORT_DATA } from '../../../../appRedux/actions/ReportDetailAction';
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import Button from '@material-ui/core/Button';
import { GetLastQuarterEndDate } from '../../../common/commonServices';

class CompanyTaxInformation extends Component {


    constructor(props) {
        super(props);
        var endDate = GetLastQuarterEndDate();
        let sDate = new Date(endDate.getFullYear(), 0, 1);
        this.sDateRef = React.createRef();
        this.eDateRef = React.createRef();

        this.state = {
            parantData: this.props.location.state ? this.props.location.state : null,
            MissingData: [],
            companyId: 0,
            PageNo: 1,
            PageSize: 10,
            ColorCode: "#46b29d",
            sDate: sDate,
            eDate: endDate,
            loading: false,
            TotalRecords: 0,
        }
    }
    //  convertToMMDDYYYY(isoDateString) {
    //     const date = new Date(isoDateString);
      
    //     const month = date.getMonth() + 1; // Adding 1 because getMonth() returns zero-based month (0-11)
    //     const day = date.getDate();
    //     const year = date.getFullYear();
      
    //     const mmddyyyyFormat = `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
      
    //     return mmddyyyyFormat;
    //   }

    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('userData'));
        this.setState({
            companyId: user.companyId,
            loading: true
        }, () => {
            this.GetReportData();
        });
    }

    // ApplyDate = () => {
    //     this.setState({
    //         loading: true,
    //     }, () => {
    //         this.GetReportData();
    //     });
    // }

    // handleDateChange = (e, target) => {

    //     if (target === "fromDate") {

    //         this.setState({ sDate: new Date(e) });
    //     }
    //     else if (target === "uptoDate") {
    //         this.setState({ eDate: new Date(e) });
    //     }
    // }

    componentWillReceiveProps(nextProps) {
        if (nextProps.ReportData !== this.props.ReportData) {
            if (nextProps.ReportData.transactionStatus === 0) {
                var totalrecords = 0;
                if (nextProps.ReportData.returnObject != null && nextProps.ReportData.returnObject.length > 0) {
                    totalrecords = nextProps.ReportData.returnObject[0].totalrows
                }
                this.setState({ 
                    // MissingData: nextProps.ReportData.returnObject,
                    TotalRecords: totalrecords, PageSize: this.state.PageSize, PageNo: this.state.PageNo,
                    MissingData: nextProps.ReportData.returnObject,
                    loading: false });
            }
            else {
                toast.error(nextProps.ReportData.resultMsg);
                this.setState({ loading: false });
            }
        }
    }
    
    GetReportData(IsRefresh = false) {
        let state = this.state;
        let body = {
            pageSize: state.PageSize,
            pageNumber: state.PageNo
        };
        this.props.GET_CTI_REPORT_DATA(body);
    }
    // onRefreshDataClick = () => {
    //     if (!this.state.loading) {
    //         this.setState({ loading: true }, () => {
    //             this.GetReportData(true);
    //         });
    //     }
    // }

    // handleFocus = (e, selecter) => {
    //     let control = null;
    //     if (selecter !== undefined) {
    //         if (selecter === "sDate") {
    //             control = this.sDateRef;
    //             if (control.current != null) {
    //                 control.current.setFocus();
    //             }
    //         }
    //         else if (selecter === "eDate") {
    //             control = this.eDateRef;
    //             if (control.current != null) {
    //                 control.current.setFocus();
    //             }
    //         }
    //     }
    // }

    ChangePage = (page) => {
        this.setState({ loading: true, PageNo: page, }, () => {
            this.GetReportData();
        });
    }

    ChangePageSize = (size) => {

        this.setState({ loading: true, PageSize: parseInt(size), PageNo: 1 }, () => {
            this.GetReportData();
        });
    }
    render() {

        return (
            <div className="dashboardPage">
                <div className="page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
                    <h2 className="mb-3 mb-sm-0">Company Tax Information</h2>
                </div>
                {this.state.loading ? <CircularProgress />
                    :
                    <div className="padding-box app-wrapper">
                        
                        <div className="table-responsive-material">
                            <table className="table tablebox account-reconcilliation">
                                <thead  >
                                    <tr style={{ backgroundColor: this.state.ColorCode }}>
                                    <th > Company Name </th>
                                    <th > Company Legal Name </th>
                                    <th > Legal Address </th>
                                    <th > EIN </th>
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.loading ? <CircularProgress></CircularProgress> :
                                        <React.Fragment>
                                            {this.state.MissingData.map((item, i) => (
                                                <React.Fragment key={i}>
                                                    <tr>
                                                     <td className="text-left"> {item.name ? `${item.name}` : ''}</td>
                                                        <td className="text-left"> {item.legalName ? `${item.legalName}` : ''}</td>
                                                        <td className="text-left"><span>
                                                        {item.address ? `${item.address} \u00A0` : ''}
                                                            {item.city ? `${item.city}, ` : ''} 
                                                            {item.state ? `${item.state} ` : ''} 
                                                            {item.postalCode ? `${item.postalCode}` : ''}
                                                            </span>
                                                        </td>
                                                        <td className="text-left">
                                                            {item.eiNnumber? `${item.eiNnumber}` : ''}
                                                        </td>
                                                        
                                                    </tr>
                                                </React.Fragment>
                                            ))}
                                            {this.state.MissingData.length == 0 && <tr>
                                                <td className="text-center" colSpan="10">
                                                    No data found          </td>
                                            </tr>}
                                        </React.Fragment>
                                    }
                                </tbody>
                            </table>
                        </div>
                       
                        <PaginationComponent
                            selectedPageNo={this.state.PageNo}
                            selectedSize={this.state.PageSize}
                            TotalRecords={this.state.TotalRecords}
                            ChangePageSize={this.ChangePageSize}
                            ChangePage={this.ChangePage}
                            CurrentRecords={this.state.MissingData.length}
                        >
                        </PaginationComponent>
                        
                    </div>
                }
            </div>
        )

    }

}


const mapStateToProps = (state) => {
    return {
        ReportData: state.ReportData.ReportData
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        GET_CTI_REPORT_DATA

    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(CompanyTaxInformation);

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import Button from '@material-ui/core/Button';
import {
    BELOW_THE_HEADER,
    COLLAPSED_DRAWER,
    FIXED_DRAWER,
    HORIZONTAL_NAVIGATION,
    INSIDE_THE_HEADER
} from '../../constants/ActionTypes';
import SearchBox from '../SearchBox';
import MailNotification from '../MailNotification/index';
import AppNotification from '../AppNotification/index';
import CardHeader from '../dashboard/Common/CardHeader/index';
import { switchLanguage, toggleCollapsedNav } from '../../appRedux/actions/Setting';
import { logoutUser } from "../../appRedux/actions/Auth";
import IntlMessages from '../../util/IntlMessages';
import LanguageSwitcher from '../LanguageSwitcher/index';
import Menu from '../TopNav/Menu';
import CompanySelector from '../TopNav/CompanySelector';
import UserInfo from '../UserInfo';
import UserInfoPopup from '../UserInfo/UserInfoPopup.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { bindActionCreators } from 'redux';
import * as config from '../../env-config.json';

const TIMEOUT = config.intervalTime;

class Header extends React.Component {

    onAppNotificationSelect = () => {
        this.setState({
            appNotification: !this.state.appNotification
        })
    };
    onMailNotificationSelect = () => {
        this.setState({
            mailNotification: !this.state.mailNotification
        })
    };
    onLangSwitcherSelect = (event) => {
        this.setState({
            langSwitcher: !this.state.langSwitcher, anchorEl: event.currentTarget
        })
    };
    onSearchBoxSelect = () => {
        this.setState({
            searchBox: !this.state.searchBox
        })
    };
    onUserInfoSelect = () => {
        this.setState({
            userInfo: !this.state.userInfo
        })
    };
    handleRequestClose = () => {
        this.setState({
            langSwitcher: false,
            userInfo: false,
            mailNotification: false,
            appNotification: false,
            searchBox: false
        });
    };


    constructor() {
        super();
        this.state = {
            anchorEl: undefined,
            searchBox: false,
            visible: false,
            timeLeft: 10,
            searchText: '',
            mailNotification: false,
            userInfo: false,
            langSwitcher: false,
            appNotification: false,
        }
    }

    onToggleCollapsedNav = (e) => {
        const val = !this.props.navCollapsed;
        this.props.toggleCollapsedNav(val);
    };

    updateSearchText(evt) {
        this.setState({
            searchText: evt.target.value,
        });
    }

    render() {

        let self = this;
        let user = JSON.parse(localStorage.getItem('userData'));
        let companyId = user.companyId;
        let role = user.role;
        let show = user.showMenu || false;

        const { drawerType, locale, navigationStyle, horizontalNavPosition } = this.props;
        const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-block d-xl-none' : drawerType.includes(COLLAPSED_DRAWER) ? 'd-block' : 'd-none';
        window.lastActivity = Date.now();
        let count = 0;

        document.addEventListener('click', function () {
            window.lastActivity = Date.now();
        })
        document.addEventListener('keydown', function () {
            window.lastActivity = Date.now();
        })
        document.addEventListener('scroll', function () {
            window.lastActivity = Date.now();
        })
        document.addEventListener('mousemove', function () {
            window.lastActivity = Date.now();
        })


        var activityChecker = setInterval(check, 2000);
        var timeClock;

        function check() {
            var currentTime = Date.now();
            if (currentTime - window.lastActivity > TIMEOUT) {
                count = count + 1;
                if (count <= 1) {
                    self.setState({ visible: true })
                    timeClock = setInterval(countdown, 1000);
                }
            }
        }

        function countdown() {
            if (self.state.timeLeft == 0) {
                self.setState({ visible: false })
                clearInterval(activityChecker);
                clearInterval(timeClock);
                self.props.logoutUser()
            }
            if (self.state.visible == true && self.state.timeLeft > 0) {
                self.setState({ timeLeft: self.state.timeLeft - 1 })
            } else {
                clearInterval(timeClock);
            }
        }
        function handleOk() {
            clearInterval(timeClock);
            self.setState({ visible: false, timeLeft: 20 })
            count = 0;

        }
        function handleCancel() {
            clearInterval(activityChecker);
            clearInterval(timeClock);
            self.setState({ visible: false })
            self.props.logoutUser()
        }

        return (
            <div>
                <Dialog
                    fullWidth={true}
                    open={this.state.visible}
                    onClose={handleCancel}
                >
                    <DialogTitle>Sesssion Expired Due to No Activity</DialogTitle>
                    <DialogContent>
                        <div>
                            <h2>Your Session will logout in {self.state.timeLeft} seconds.</h2>
                            <h3>Do You Want to Continue?</h3>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleOk} color="primary">
                            Continue
                </Button>
                        <Button onClick={handleCancel} color="primary" autoFocus>
                            Logout
                </Button>
                    </DialogActions>
                </Dialog>
                <AppBar
                    className={`app-main-header ${(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) ? 'app-main-header-top' : ''}`}>
                    <Toolbar className="app-toolbar" disableGutters={false}>
                        {navigationStyle === HORIZONTAL_NAVIGATION ?
                            <div className="d-block d-md-none pointer mr-3" onClick={this.onToggleCollapsedNav}>
                                <span className="jr-menu-icon">
                                    <span className="menu-icon" />
                                </span>
                            </div>
                            :
                            <IconButton className={`jr-menu-icon mr-3 ${drawerStyle}`} aria-label="Menu"
                                onClick={this.onToggleCollapsedNav}>
                                <span className="menu-icon" />
                            </IconButton>
                        }

                        <Link to={show == false ? `/home` : `/dashboard`}>
                            <div className="app-logos mr-2 d-none d-sm-block" >
                                <img style={{ 'maxWidth': 130 }} src="/vendors/Logo1.png" alt="Compliance" title="Compliance" />
                            </div>
                        </Link>

                        <ul className="header-notifications list-inline" style={{ marginLeft: 30 }}>
                            <li className="list-inline-item app-tour">
                                <CompanySelector />
                            </li>
                        </ul>

                        <ul className="header-notifications list-inline mx-auto">

                            {(navigationStyle === HORIZONTAL_NAVIGATION &&
                                horizontalNavPosition === INSIDE_THE_HEADER) &&
                                <li className="list-inline-item app-tour">
                                    <Menu />
                                </li>
                            }
                        </ul>



                        <ul className="header-notifications list-inline ml-auto">
                            {/*
                       <li className="d-inline-block d-lg-none list-inline-item">
                            <Dropdown
                                className="quick-menu nav-searchbox"
                                isOpen={this.state.searchBox}
                                toggle={this.onSearchBoxSelect.bind(this)}>

                                <DropdownToggle
                                    className="d-inline-block"
                                    tag="span"
                                    data-toggle="dropdown">
                                    <IconButton className="icon-btn size-30">
                                        <i className="zmdi zmdi-search zmdi-hc-fw"/>
                                    </IconButton>
                                </DropdownToggle>

                                <DropdownMenu right className="p-0">
                                    <SearchBox styleName="search-dropdown" placeholder=""
                                               onChange={this.updateSearchText.bind(this)}
                                               value={this.state.searchText}/>
                                </DropdownMenu>
                            </Dropdown>
                        </li>

                        <li className="list-inline-item">
                            <Dropdown
                                className="quick-menu"
                                isOpen={this.state.langSwitcher}
                                toggle={this.onLangSwitcherSelect.bind(this)}>

                                <DropdownToggle
                                    className="d-inline-block"
                                    tag="span"
                                    data-toggle="dropdown">
                                    <div className="d-flex align-items-center pointer pt-1">
                                        <i className={`flag flag-24 flag-${locale.icon}`}/>
                                    </div>
                                </DropdownToggle>

                                <DropdownMenu right className="w-50">
                                    <LanguageSwitcher switchLanguage={this.props.switchLanguage}
                                                      handleRequestClose={this.handleRequestClose}/>
                                </DropdownMenu>
                            </Dropdown>
                            </li>

   <li className="list-inline-item mail-tour">
                            <Dropdown
                                className="quick-menu"
                                isOpen={this.state.mailNotification}
                                toggle={this.onMailNotificationSelect.bind(this)}
                              >
                               <DropdownToggle
                                    className="d-inline-block"
                                    tag="span"
                                    data-toggle="dropdown">

                                    <IconButton className="icon-btn size-20 font-size-20">
                                        <i className="zmdi zmdi-comment-alt-text icon-alert zmdi-hc-fw"/>
                                    </IconButton>
                                </DropdownToggle>

                                <DropdownMenu right>
                                    <CardHeader styleName="align-items-center"
                                                heading={<IntlMessages id="mailNotification.title"/>}/>
                                    <MailNotification/>
                                </DropdownMenu>
                            </Dropdown>
                        </li>
                       */}
                            {/*<li className="list-inline-item">
                         <SearchBox styleName="d-none d-lg-block" placeholder=""
                             onChange={this.updateSearchText.bind(this)}
                             value={this.state.searchText}
                           />
                       </li>*/}


                            <li className="list-inline-item app-tour">
                                <Dropdown
                                    className="quick-menu"
                                    isOpen={this.state.appNotification}
                                    toggle={this.onAppNotificationSelect.bind(this)}>

                                    <DropdownToggle
                                        className="d-inline-block"
                                        tag="span"
                                        data-toggle="dropdown">
                                        <IconButton className="icon-btn size-20 font-size-20">
                                            <i className="zmdi zmdi-notifications-active icon-alert animated infinite wobble" />
                                        </IconButton>
                                    </DropdownToggle>

                                    <DropdownMenu right>
                                        <CardHeader styleName="align-items-center"
                                            heading={<IntlMessages id="appNotification.title" />} />
                                        <AppNotification />
                                    </DropdownMenu>
                                </Dropdown>
                            </li>


                            {navigationStyle === HORIZONTAL_NAVIGATION &&
                                <li className="list-inline-item user-nav">
                                    <UserInfo />
                                </li>}
                        </ul>


                    </Toolbar>
                </AppBar>
            </div>
        );
    }

}

const mapStateToProps = ({ settings }) => {
    const { drawerType, locale, navigationStyle, horizontalNavPosition } = settings;
    return { drawerType, locale, navigationStyle, horizontalNavPosition }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        logoutUser,
        toggleCollapsedNav,
        switchLanguage
    }, dispatch)
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));

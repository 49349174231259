import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import {updateCompanies} from '../appRedux/actions/companiesAction';
import { AssignAccountTypes, AssignTransactionTypes } from '../appRedux/actions/accountAction';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Snackbar from '@material-ui/core/Snackbar';
import _ from 'lodash';

class CompanyAccountsForm extends React.Component {

    constructor() {
        super()
        this.state = {
            error:'',
            vertical: 'top',
            horizontal: 'center',
            open: false,
            AccountsPayable:true,
            AccountsReceivable:true,
            Bank:false,
            CostOfGoodsSold:true,
            CreditCard:false,
            Equity:true,
            Expense:true,
            FixedAsset:true,
            Income:true,
            LongTermLiability:true,
            NonPosting:false,
            OtherAsset:true,
            OtherCurrentAsset:true,
            OtherCurrentLiability:true,
            OtherExpense:true,
            OtherIncome:true,
            Paycheck:true,
            accountTypes:[],
            transctionTypes:[],
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
    }

     componentDidMount() {

          let param1 = this.props.accountTypes ;
          let param2 = this.props.companyAccountTypes;
          let tranParam1 = this.props.transctionTypes;
          let tranParam2 = this.props.companyTransactionTypes;
          let accountTypes= [];
          let transctionTypes= [];

          var result3 = _(param1) 
          .differenceBy(param2, 'accTypeID', 'accType')
          .map(_.partial(_.pick, _, 'accTypeID', 'accType'))
          .value();

          var result4 = _(tranParam1) 
          .differenceBy(tranParam2, 'trnTypeId', 'trnType')
          .map(_.partial(_.pick, _, 'trnTypeId', 'trnType'))
          .value();

        
          if(param2.length == 0) {
            // this.props.accountTypes.map(data => {
            // if(data.accType !== 'Bank' && data.accType !== 'CreditCard' && data.accType !== 'NonPosting') {
            //   accountTypes.push(data.accTypeID)
            //  } 
            // });
           

             param1.map(data => {
                accountTypes.push(data.accTypeID);
                 this.setState({[data.accType]:false})
            });
              // this.setState({accountTypes})
          } else {

            _.forEach(param1, a => {
                var o = _.find(param2, b => a.accType === b.accType);
                if (o) {
                    o.accType = a.accType;
                }
            });

            param2.map(data => {
                accountTypes.push(data.accTypeID);
                 this.setState({[data.accType]:true})
            })

            result3.map(data => {
                this.setState({[data.accType]:false})
            })

            this.setState({accountTypes})
         }

          if(tranParam2.length == 0) {

            this.props.transctionTypes.map(data => {
               transctionTypes.push(data.trnTypeId)
               this.setState({[data.trnType]:false})
             });
          //  this.setState({transctionTypes})

          } else {
            _.forEach(tranParam1, a => {
                var o = _.find(tranParam2, b => a.trnType === b.trnType);
                if (o) {
                    o.trnType = a.trnType;
                }
            });

            tranParam2.map(data => {
                 transctionTypes.push(data.trnTypeId);
                 this.setState({[data.trnType]:true})
            })

            result4.map(data => {
                this.setState({[data.trnType]:false})
            })

            this.setState({transctionTypes})
        }
           
    }

    handleDelete = () => {
      this.props.handleDelete()
    }

    handleCancel = () => {
       this.setState({
            error:'',
            vertical: 'top',
            horizontal: 'center',
            open: false,
            AccountsPayable:true,
            AccountsReceivable:true,
            Bank:false,
            CostOfGoodsSold:true,
            CreditCard:false,
            Equity:true,
            Expense:true,
            FixedAsset:true,
            Income:true,
            LongTermLiability:true,
            NonPosting:false,
            OtherAsset:true,
            OtherCurrentAsset:true,
            OtherCurrentLiability:true,
            OtherExpense:true,
            OtherIncome:true,
            Paycheck:true,
            accountTypes:[],
            transctionTypes:[],
        });
        this.props.handleCancel()
    };

    handleSubmit = (e) => {      
      e.preventDefault();
      let compID = this.props.AccNext.companyId;
      let data = {
            "name": this.props.AccNext.name,
            "state": this.props.AccNext.state,
            "city": this.props.AccNext.city,
            "postalCode": this.props.AccNext.postalCode,
            "eiNnumber": this.props.AccNext.eiNnumber,
            "address": this.props.AccNext.address,
            "companyId":this.props.AccNext.companyId,
            "quickbookOnline": true,
            "accountantId": 0,
            "mpwAccountantEmail": this.props.AccNext.mpwAccountantEmail,
            "infusionsoftContactId": this.props.AccNext.infusionsoftContactId,
            "accountingMethod":this.props.AccNext.accountingMethod,
            "accountingSoftwareId":Number(this.props.AccNext.accountingSoftwareId),
            "payrollSoftwareId": Number(this.props.AccNext.payrollSoftwareId),
            "isActive": true,
            "isReligeousOrganization": this.props.AccNext.isReligeousOrganization,
            "isFullServiceBookkeeping": this.props.AccNext.isFullServiceBookkeeping,
            "QuickbooksDataSyncBeginningYear": this.props.AccNext.QuickbooksDataSyncBeginningYear ? this.props.AccNext.QuickbooksDataSyncBeginningYear.toString():null

        }      
        this.props.updateCompanies(data);
        console.log("🚀 ~ file: EditCompanyAccountsForm.js:197 ~ CompanyAccountsForm ~ data:", data)
        this.props.AssignAccountTypes(compID, this.state.accountTypes);
        this.props.AssignTransactionTypes(compID, this.state.transctionTypes);
        this.props.handleSave(false);


        this.setState({
            error:'',
            vertical: 'top',
            horizontal: 'center',
            open: false,
            AccountsPayable:true,
            AccountsReceivable:true,
            Bank:false,
            CostOfGoodsSold:true,
            CreditCard:false,
            Equity:true,
            Expense:true,
            FixedAsset:true,
            Income:true,
            LongTermLiability:true,
            NonPosting:false,
            OtherAsset:true,
            OtherCurrentAsset:true,
            OtherCurrentLiability:true,
            OtherExpense:true,
            OtherIncome:true,
            Paycheck:true,
            accountTypes:[],
            transctionTypes:[],
        });
     }

    handleClose = () => {
      this.setState({ open: false });
    };

    handleFormChange = (e) => {
       this.setState({[e.target.name]:e.target.value})

    }
    handleFormChecked = name => event => {

      if( event.target.checked === true ) {
        this.state.accountTypes.push(Number(event.target.value))
      } else {
        let newArr = [...this.state.accountTypes];
        var index = newArr.indexOf(Number(event.target.value));
        if (index > -1) {
         newArr.splice(index, 1);
         this.setState({accountTypes:newArr});
        }
      }
      this.setState({ [name]: event.target.checked });
    };

     handleFormChecked1 = name => event => {
       if( event.target.checked === true ) {
        this.state.transctionTypes.push(Number(event.target.value))
      } else {
         let newArr = [...this.state.transctionTypes];
       var index = newArr.indexOf(Number(event.target.value));
        if (index > -1) {
           newArr.splice(index, 1);
          this.setState({transctionTypes:newArr});
        }
      }
      this.setState({ [name]: event.target.checked });
    };

    render() {
        const {visible,accountTypes,transctionTypes } = this.props;
        const {error, vertical, horizontal, open} = this.state;
        
        let accountType = accountTypes.map((data,i) => {
		let	label = data.accType.replace(/([A-Z])/g, ' $1').trim();
          return(
             <Grid key={i} item sm={3}>
              <Checkbox
                  name="accountTypes"
                  value={data.accTypeID}
                  checked={this.state[data.accType]}
                  onChange={this.handleFormChecked(data.accType)}
              />
              <label>{label}</label>
            </Grid>
            );
        });

        let transType = transctionTypes.map((data,i) => {
			let	label = data.trnType.replace(/([A-Z])/g, ' $1').trim();
            return(<Grid key={i} item sm={3}>
              <Checkbox
                  name="transctionTypes"
                  value={data.trnTypeId}
                  checked={this.state[data.trnType]}
                  onChange={this.handleFormChecked1(data.trnType)}
              />
              <label>{label}</label>
           </Grid>
            );
        })

        return (
            <Dialog
                maxWidth="md"
                fullWidth={true}
                open={visible}
                onClose={this.handleCancel}
            >
             <form method="post" onSubmit={this.handleSubmit}  autoComplete="off" style={{ padding: 10 }}>
             <DialogContent>
              <fieldset>
               <Grid container spacing={24}>
                    <Grid item sm={12}>
                      <h2>Account Types for General Ledger Review</h2>
                    </Grid>

                    {accountType}
                   
                     </Grid>
                     <Grid container spacing={24}>
                    <Grid item sm={12}>
                       <h2>Transaction Types To Exclude from General Ledger Review</h2>
                       
                    </Grid>
                      {transType}
                 </Grid>
                </fieldset>
            </DialogContent>
                <DialogActions>
                 <Button onClick={this.props.handleBack} color="primary">
                  Back
                </Button>
                <Button type="submit" color="primary">
                  Update
                </Button>
                <Button onClick={this.handleCancel} color="primary" autoFocus>
                  Cancel
                </Button>
                 <Button onClick={this.handleDelete} color="primary">
                  Delete
                </Button>
              </DialogActions>
                </form>
                <Snackbar
                    anchorOrigin={{ vertical, horizontal }}
                    open={open}
                    onClose={this.handleClose}
                    ContentProps={{
                      'aria-describedby': 'message-id',
                    }}

                    message={<div id="message-id">{open ? error.data[0].description : ''}</div>}
                  />
            </Dialog>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        error: state.companyReducer.error,
    }
}
const mapDispatchToProps=(dispatch)=>{
    return bindActionCreators({
        updateCompanies,
        AssignAccountTypes,
        AssignTransactionTypes
    },dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyAccountsForm);




import React, { Component } from 'react';
import { GetRequiredDate, GetLastQuarterEndDate, numberWithCommas, GetPreviousQuaterEndDate, checkValidDate, LinkType } from '../../../common/commonServices';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { GET_AccountReconciliations, POST_updatecustomfield,MarkAccountReconciliationReview,GetAccountReconciliationReviewedHistory,GetGeneralReportNotes,InsertUpdateGeneralReportNotes } from '../../../../appRedux/actions/ReconcillitionAction';
import Button from '@material-ui/core/Button';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import CircularProgress from "../../../../components/CircularProgress";
import { catchClause } from '@babel/types';
import Switch from '@material-ui/core/Switch';
import { PUT_QuickbookAccount_UpdateAccountStatus } from "../../../../appRedux/actions/ReconcillitionAction";
import TextArea from 'antd/lib/input/TextArea';
import { AddQuestion } from "../../../../appRedux/actions/QuestionCommentsAction";
import { UpdateCustomField } from "../../../../appRedux/actions/BalanceSheetAction";
import "./Reconcilliation.css"
import Textarea from 'react-expanding-textarea';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
class AccountReconcilliationPage extends Component {
    constructor(props) {
        super(props);

        let data = { accountlist: [] }

        this.state = {
            loading: false,
            companyId: 0,
            lst_reconData: data,
            Title: "Account Reconciliation Review",
            defaultSelection: "QT",
            IsDateModelOpen: false,
            expandall: true,
            collapsall: false,
            editIndex1: -1,
            editIndex2: -1,
            editData: null,
            lastQuaterAllBankAccount: {
                key: 'Last quarter all bank and credit card accounts were reconciled: ',
                value: "Never Reconciled"
            },
            allCheckingReconThrogh: {
                key: "Have all checking accounts been reconciled through ",
                value: "No"
            },
            lastQuarterAllCheckingBanksAccount: {
                key: "Last quarter all checking accounts were reconciled:",
                value: "00/00/0000"
            },
            lastQuarterOtherBanksAccount: {
                key: "Last quarter all bank accounts were reconciled:",
                value: "00/00/0000"
            },
            lastQuarterCreditCardsAccount: {
                key: "Last quarter all credit card accounts were reconciled:",
                value: "00/00/0000"
            },
            selectdeDate: null, //new Date(),
            SaveButtonEnable: true,
            // IsDateRemove: false,
            errorMsg: null,
            editedData: null,
            Notes : "",
            prevNotes:"",
            ShowHistory:true,
            HistoryData:[]
        }
    }

    GetAccountDataList = (data, checkingonly = false, otherBanks = false, creditCards = false) => {
        var items = [];
        for (let i = 0; i < data.accountlist.length; i++) {

            if (checkingonly) {
                for (let k = 0; k < data.accountlist[i].data.length; k++) {

                    if (data.accountlist[i].data[k].isExcludedReconciliation === false
                        && data.accountlist[i].isChecking) {
                        items.push(data.accountlist[i].data[k]);
                    }
                }
            }
            else if (otherBanks) {
                for (let k = 0; k < data.accountlist[i].data.length; k++) {

                    if ((data.accountlist[i].data[k].isExcludedReconciliation === false
                        && data.accountlist[i].accountType === "Other Bank Accounts") ||
                        (data.accountlist[i].data[k].isExcludedReconciliation === false
                            && data.accountlist[i].accountType === "Checking Accounts")) {
                        items.push(data.accountlist[i].data[k]);
                    }
                }
            }
            else if (creditCards) {
                for (let k = 0; k < data.accountlist[i].data.length; k++) {

                    if (data.accountlist[i].data[k].isExcludedReconciliation === false
                        && data.accountlist[i].accountType === "Credit Card Accounts") {
                        items.push(data.accountlist[i].data[k]);
                    }
                }
            }
            else {
                for (let k = 0; k < data.accountlist[i].data.length; k++) {
                    if (data.accountlist[i].data[k].isExcludedReconciliation === false) {
                        items.push(data.accountlist[i].data[k]);
                    }
                }
            }

        }
        return items;
    }

    HandleClose = () => {
        this.setState({ ShowHistory: false });
      }
    // handleDateChanges = (e) => {
    //     if (e != null) {
    //         this.setState({ SaveButtonEnable: true, IsDateRemove: false });
    //         this.setState({ selectdeDate: new Date(e) });
    //     }
    //     else {
    //         this.setState({ selectdeDate: null, IsDateRemove: true });
    //         // toast.error("Please select proper date");
    //         // this.setState({ SaveButtonEnable: false });
    //     }

    // }

    // onRowEdit = (i, j, rowData) => {

    //     //if (rowData.lastReconciledTransection != null && rowData.lastReconciledTransection.trim() != "") {
    //     if (rowData.reconciledThrough != null && rowData.reconciledThrough && rowData.reconciledThrough.toLowerCase().indexOf('never') < 0 && rowData.reconciledThrough.toLowerCase().indexOf('pending') < 0) {
    //         this.setState({ selectdeDate: new Date(rowData.reconciledThrough) });
    //     }
    //     else {
    //         this.setState({ selectdeDate: null });
    //     }
    //     this.setState({ editData: rowData, editIndex1: i, editIndex2: j });
    //     // }
    // };

    // onRowCancel = (i, j, rowData) => {
    //     this.setState({ editIndex1: -1, editIndex2: -1, editData: null });
    // };

    // onRowSave = (i, j) => {
    //     var data = { ...this.state.lst_reconData };
    //     data.accountlist[i].data[j].reconciledThrough = this.state.selectdeDate != null && this.state.selectdeDate != "" ? moment(this.state.selectdeDate).format('MM/DD/YYYY') : null;
    //     data.accountlist[i].data[j].lastReconciledEnd = moment(GetPreviousQuaterEndDate(this.state.selectdeDate)).format('MM/DD/YYYY');
    //     var obj = {
    //         "accountId": data.accountlist[i].data[j].accountID,
    //         "reconciledThrough": this.state.selectdeDate != null && this.state.selectdeDate != "" ? moment(this.state.selectdeDate).format('MM/DD/YYYY') : null,
    //         "isExcludedReconciliation": data.accountlist[i].data[j].isExcludedReconciliation
    //     }
    //     this.setState({ loading: true, editData: data.accountlist[i].data[j] }, () => {
    //         this.props.POST_updatecustomfield(obj);
    //     });
    // };

    onChangeExeFromAcc = (e, i, j, rowData) => {
        var data = { ...this.state.lst_reconData };
        data.accountlist[i].data[j].isExcludedReconciliation = e.target.checked;
        this.setState({ lst_reconData: data });
    }

    SetLastQuaterOtherBank = (data) => {
        var items = this.GetAccountDataList(data, false, true, false)
        var minDate = null;
        if (items != null && items.length > 0) {
            minDate = GetRequiredDate(items, "lastReconciledEnd", false, "isExcludedReconciliation");
            let displayText = "00/00/0000";
            if (minDate != null && checkValidDate(minDate)) {
                displayText = this.CheckALltransactionComplete(minDate, items);
            }

            this.setState({
                lastQuarterOtherBanksAccount: {
                    key: this.state.lastQuarterOtherBanksAccount.key,
                    value: displayText
                }
            });
        } else {
            this.setState({
                lastQuarterOtherBanksAccount: {
                    key: this.state.lastQuarterOtherBanksAccount.key,
                    value: "00/00/0000"
                }
            });
        }
    }

    SetLastQuaterCheckingAccounts = (data) => {
        var items = this.GetAccountDataList(data, true, false, false)
        var minDate = null;
        if (items != null && items.length > 0) {
            minDate = GetRequiredDate(items, "lastReconciledEnd", false, "isExcludedReconciliation");
            let displayText = "00/00/0000";
            if (minDate != null && checkValidDate(minDate)) {
                displayText = this.CheckALltransactionComplete(minDate, items);
            }

            this.setState({
                lastQuarterAllCheckingBanksAccount: {
                    key: this.state.lastQuarterAllCheckingBanksAccount.key,
                    value: displayText
                }
            });
        } else {
            this.setState({
                lastQuarterAllCheckingBanksAccount: {
                    key: this.state.lastQuarterAllCheckingBanksAccount.key,
                    value: "00/00/0000"
                }
            });
        }
    }

    SetLastQuaterCreditCardsAccount = (data) => {
        var items = this.GetAccountDataList(data, false, false, true);
        var minDate = null;
        if (items != null && items.length > 0) {
            minDate = GetRequiredDate(items, "lastReconciledEnd", false, "isExcludedReconciliation");
            let displayText = "00/00/0000";
            if (minDate != null && checkValidDate(minDate)) {
                displayText = this.CheckALltransactionComplete(minDate, items);
            }

            this.setState({
                lastQuarterCreditCardsAccount: {
                    key: this.state.lastQuarterCreditCardsAccount.key,
                    value: displayText
                }
            });
        } else {
            this.setState({
                lastQuarterCreditCardsAccount: {
                    key: this.state.lastQuarterCreditCardsAccount.key,
                    value: "00/00/0000"
                }
            });
        }
    }

    SetlastQuaterAllBankAccount = (data) => {
        var items = this.GetAccountDataList(data)
        var minDate = null;
        if (items != null && items.length > 0) {
            minDate = GetRequiredDate(items, "lastReconciledEnd", false, "isExcludedReconciliation");
            let displayText = "00/00/0000";
            if (minDate != null && checkValidDate(minDate)) {
                displayText = this.CheckALltransactionComplete(minDate, items);
            }

            this.setState({
                lastQuaterAllBankAccount: {
                    key: 'Last quarter all bank and credit card accounts were reconciled:',
                    value: displayText
                }
            });
        } else {
            this.setState({
                lastQuaterAllBankAccount: {
                    key: 'Last quarter all bank and credit card accounts were reconciled:',
                    value: "00/00/0000"
                }
            });
        }
        return minDate;
    }

    CheckALltransactionComplete = (minDate, items) => {
        let result = moment(minDate).format("MM/DD/YYYY");
        let temp = null;
        for (let k = 0; k < items.length; k++) {
            try {
                temp = new Date(items[k].lastReconciledEnd);
                if (items[k].lastReconciledEnd == null || items[k].lastReconciledEnd === ""
                    || items[k].lastReconciledEnd === "Pending Review"
                    || items[k].lastReconciledEnd === "Never Reconciled") {
                    result = "00/00/0000";
                    break;
                }
            }
            catch (err) {
                result = "00/00/0000";
                break;
            }
        }
        return result;
    }

    CheckAllAccountReconciled = (data) => {
        var items = this.GetAccountDataList(data, true);
        var result = "No";
        let minDate = GetRequiredDate(items, "lastReconciledEnd", false);
        var lastQuaterEndDate = GetLastQuarterEndDate();
        if (items != null && items.length > 0) {

            if (minDate != null && typeof minDate.getTime == "function" && minDate.getTime() >= lastQuaterEndDate.getTime()) {
                result = "Yes";
            }

            this.setState({
                allCheckingReconThrogh: {
                    key: "Have all checking accounts been reconciled through " + moment(lastQuaterEndDate).format("MM/DD/YYYY") + "?",
                    value: result
                }
            });
        }
        else {
            this.setState({
                allCheckingReconThrogh: {
                    key: "Have all checking accounts been reconciled through " + moment(lastQuaterEndDate).format("MM/DD/YYYY") + "?",
                    value: result
                }
            });
        }
    }

    LoadAccountReconcillition = () => {
        this.props.GET_AccountReconciliations(this.state.companyId);
    }


    onCheckboxClick = (e, i, j, item) => {
        var data = { ...this.state.lst_reconData };
        data.accountlist[i].data[j].isExcludedReconciliation = e.target.checked ? true : false;
        var obj = {
            "accountId": data.accountlist[i].data[j].accountID,
            "reconciledThrough": null,
            "isExcludedReconciliation": e.target.checked ? true : false
        }

        if (data.accountlist[i].data[j].reconciledThrough != null && data.accountlist[i].data[j].reconciledThrough
            && data.accountlist[i].data[j].reconciledThrough != "Never Reconciled") {
            try {
                let temp = new Date(data.accountlist[i].data[j].reconciledThrough);
                if (typeof temp.getTime == "function") {
                    obj.reconciledThrough = moment(new Date(temp)).format('YYYY-MM-DD');
                }
            }
            catch (err) {

                obj.reconciledThrough = null;
            }
        }
        if (e.target.checked) {
            if (data.accountlist[i].data[j].lastReconciledTransection != null && data.accountlist[i].data[j].lastReconciledTransection !== "") {
                data.accountlist[i].data[j].reconciledThrough = null;
                data.accountlist[i].data[j].lastReconciledEnd = "Pending Review";
            }
            else {
                data.accountlist[i].data[j].reconciledThrough = "Never Reconciled";
                data.accountlist[i].data[j].lastReconciledEnd = "Never Reconciled";
            }
        }
        else {
            // to do check
        }

        this.setState({ loading: true, editData: data.accountlist[i].data[j] }, () => {
            this.props.POST_updatecustomfield(obj);
        });

    }

    updateList = (data, response) => {
        let edited = this.state.editData;

        for (let i = 0; i < data.accountlist.length; i++) {
            for (let j = 0; j < data.accountlist[i].data.length; j++) {
                if (data.accountlist[i].data[j].accountID === edited.accountID) {
                    //&& data.accountlist[i].data[j].isExcludedReconciliation===false
                    data.accountlist[i].data[j].reconciledThrough = response.returnObject.reconciledThrough;
                    data.accountlist[i].data[j].lastReconciledEnd = response.returnObject.lastReconciledEnd;
                }
            }
        }
        return data;
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.reconcillition !== undefined && nextProps.reconcillition !== this.props.reconcillition) {
            if (nextProps.reconcillition.transactionStatus !== undefined && nextProps.reconcillition.transactionStatus == 0) {
                var accountlist = nextProps.reconcillition.returnObject;
                var data = {
                    accountlist: accountlist,
                    // lastQuaterAllBankAccount: { key: 'Last quater All Bank Account Reconcilled', value: '' },
                    // allCheckingReconThrogh: { key: 'having all checking account been reconcilled ', value: '' }
                }
                if (data.accountlist != null) {
                    this.SetlastQuaterAllBankAccount(data);
                    this.SetLastQuaterOtherBank(data);
                    this.SetLastQuaterCheckingAccounts(data)
                    this.SetLastQuaterCreditCardsAccount(data);
                    this.CheckAllAccountReconciled(data);
                    this.setState({
                        lst_reconData: data,
                        loading: false,
                        questionData: null,
                        editIndex1: -1,
                        editIndex2: -1,
                        editedData: null
                    });
                }
                else {
                    toast.error("No issues here! Keep up the good work!");
                    this.setState({
                        loading: false
                    });
                }

            } else {
                this.setState({ loading: false }, () => {
                    toast.error(`${nextProps.reconcillition.resultMsg}`);
                });
            }
        }
        if (nextProps.Update_reconciled !== undefined && nextProps.Update_reconciled !== this.props.Update_reconciled) {
            var returnObj = nextProps.Update_reconciled;
            if (returnObj.transactionStatus == 0) {

                if (this.state.IsDateRemove) {
                    toast.success(`${returnObj.resultMsg}`);
                    this.setState({ IsDateRemove: false }, () => {
                        setTimeout(() => {
                            this.setState({ loading: true, editIndex1: -1, editIndex2: -1, editData: null }, () => { this.LoadAccountReconcillition(); });
                        }, (1000));
                    })
                }
                else {

                    var data = { ...this.state.lst_reconData };
                    data = this.updateList(data, returnObj);
                    this.SetLastQuaterOtherBank(data);
                    this.SetLastQuaterCheckingAccounts(data)
                    this.SetLastQuaterCreditCardsAccount(data);
                    this.SetlastQuaterAllBankAccount(data);
                    this.CheckAllAccountReconciled(data);
                    this.setState({
                        lst_reconData: data,
                        editIndex1: -1, editIndex2: -1, editData: null,
                        loading: false
                    }, () => {
                        toast.success(`${returnObj.resultMsg}`);
                    });
                }


            } else {
                this.setState({ loading: false }, () => {
                    toast.error(`${returnObj.resultMsg}`);
                });
            }
        }

        if (nextProps.GetGeneralReportNotesData !== undefined  && nextProps.GetGeneralReportNotesData != this.props.GetGeneralReportNotesData) {
      
            if (nextProps.GetGeneralReportNotesData.transactionStatus === 0) {
              if(nextProps.GetGeneralReportNotesData.returnObject != null){
                this.setState({ loading: false, Notes: nextProps.GetGeneralReportNotesData.returnObject.notes });
              }else{
                this.setState({ loading: false, Notes: "" });
              }
            }
          }

          if (nextProps.UpdateGeneralReportNotes !== undefined && nextProps.UpdateGeneralReportNotes !== this.props.UpdateGeneralReportNotes) {
      
            if (nextProps.UpdateGeneralReportNotes.transactionStatus === 0) {
                this.setState({ editNotes: false,Notes:this.state.prevNotes }, () => {toast.success(`${nextProps.UpdateGeneralReportNotes.resultMsg}`);});
                this.props.GetGeneralReportNotes(this.state.companyId,"AccountReconciliation");
            }
            else {
              this.setState({ loading: false }, () => { toast.error(`${nextProps.UpdateGeneralReportNotes.resultMsg}`);});
            }
          }
        if (nextProps.account_Inactive !== undefined && nextProps.account_Inactive !== this.props.account_Inactive) {
            this.setState({ loading: true, questionData: null, editIndex1: -1, editIndex2: -1, editedData: null }, () => {
                this.LoadAccountReconcillition();
            });
        }

        if (nextProps.AddComman !== undefined && this.props.AddComman !== nextProps.AddComman) {
            if (nextProps.AddComman.transactionStatus === 0) {
                this.setState({ loading: true, questionData: null, editIndex1: -1, editIndex2: -1, editedData: null }, () => {
                    toast.success("Record saved successfully.");
                    this.LoadAccountReconcillition();
                });
            }
            else {
                this.setState({ loading: false, isRequestProcessing: false }, () => { toast.error(`${nextProps.AddComman.resultMsg}`); });
            }
        }
        if (nextProps.ReconciliedAccountHistory !== undefined && nextProps.ReconciliedAccountHistory !== this.props.ReconciliedAccountHistory) {
            if (nextProps.ReconciliedAccountHistory.transactionStatus === 0) {
                    this.setState({ShowHistory: true,HistoryData:nextProps.ReconciliedAccountHistory.returnObject});
            } 
        }

        if (nextProps.CommonUpdate !== undefined && nextProps.CommonUpdate !== this.props.CommonUpdate) {
            if (nextProps.CommonUpdate.transactionStatus === 0) {
            
                this.setState({ IsReviewProcessing: false}, () => {
                  toast.success("Reviewed Successfully");
                this.props.GetAccountReconciliationReviewedHistory(this.state.companyId)
                });
            //   }
            }
            else {
              this.setState({ loading: false }, () => { toast.error(`${nextProps.CommonUpdate.resultMsg}`); });
            }
      
          }

        if (nextProps.UpdateData !== undefined && this.props.UpdateData !== nextProps.UpdateData) {
            if (nextProps.UpdateData.transactionStatus === 0) {

                if (this.state.questionData != null) {
                    this.props.AddQuestion(this.state.questionData);
                } else {
                    this.setState({ loading: true, editIndex1: -1, editIndex2: -1, editedData: null }, () => {
                        toast.success("Record saved successfully.");
                        this.LoadAccountReconcillition();
                    });
                }
            }
            else {
                this.setState({ loading: false, isRequestProcessing: false }, () => { toast.error(`${nextProps.UpdateData.resultMsg}`); });
            }
        }
    }

    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('userData'));
        this.setState({ companyId: user.companyId, loading: true }, () => {
            this.props.GetGeneralReportNotes(user.companyId,"AccountReconciliation")
            this.props.GetAccountReconciliationReviewedHistory(user.companyId)
            this.LoadAccountReconcillition();
        });
    }

    accountStatusChange = (event, item) => {
        event.stopPropagation();
        let isChecked = event.target.checked;
        if (parseInt(item.balance) !== 0 && !isChecked) {
            // toast.error("Error- This Account has a non-zero balance. you can not make as inactive.");
            this.setState({ errorMsg: "This account has a balance on your books. You may make this account inactive after the account balance is zero." }, () => {
                setTimeout(() => {
                    this.setState({ errorMsg: null });
                }, 6000);
            });
            //toast.error("Error- This account has a balance on your books  You may make this account inactive after the account balance is zero");
            return false;
        }
        let body = {
            "accountId": item.accountID,
            "isActive": isChecked
        }
        this.setState({ loading: true }, () => {
            this.props.PUT_QuickbookAccount_UpdateAccountStatus(body);
        });
    }


    // onRowEdit = (i, j, data) => {
    //     let item = { AccountId: data.accountID, ReconciledThrough: (data.reconciledThrough == "" || data.reconciledThrough == null) ? null : new Date(data.reconciledThrough), Notes: data.comments, questionID: data.questionId, question: data.question };
    //     this.setState({ editedData: item, editIndex1: i, editIndex2: j });
    // };

    onRowEdit = (i, j, data) => {
        // debugger;
        let item = {
            AccountId: data.accountID,
            ReconciledThrough: data.reconciledThrough != null && data.reconciledThrough && data.reconciledThrough.toLowerCase().indexOf('never') < 0 && data.reconciledThrough.toLowerCase().indexOf('pending') < 0 ? new Date(data.reconciledThrough) : null,
            Notes: data.comments,
            questionID: data.questionId,
            question: data.question
        };
        this.setState({ editedData: item, editIndex1: i, editIndex2: j });
    };

    onRowUpdateClick = () => {
        
        let data = this.state.editedData;
        let body = {
            "accountId": data.AccountId,
            "reconciledThrough": (data.ReconciledThrough == null || data.ReconciledThrough === "") ? null : moment(data.ReconciledThrough).format("MM/DD/YYYY"),
            "comments": data.Notes
        }

        let QueBody = {
            "id": data.questionID != null && data.questionID != '' ? data.questionID : 0,
            "question": data.question,
            "status": false,
            "cpaReview": false,
            "companyID": this.state.companyId,
            "accountID": data.AccountId
        }

        // sprint 11 create a branch test
        this.setState({ loading: true, questionData: QueBody }, () => {
            this.props.UpdateCustomField(body);
        });
    }

    onRowCancelClick = () => {
        this.setState({ editedData: null, editIndex1: -1, editIndex2: -1 });
    }

    handleFormChange = (e, target) => {
        if (target == "ReconciledThrough") {
            let ed = this.state.editedData;
            if (e != null) {
                ed.ReconciledThrough = new Date(e);
                this.setState({ editedData: ed })
            }else{
                ed.ReconciledThrough = null;
                this.setState({ editedData: ed })
            }
        }
    }

    handleMessage = (event) => {
        let ed = this.state.editedData;
        ed.Notes = event.target.value;
        this.setState({ editedData: ed });
    }

    handleQuestion = (event) => {
        let ed = this.state.editedData;
        ed.question = event.target.value;
        this.setState({ editedData: ed });
    }


    editNotesData = () => {
        this.setState({ editNotes: true ,prevNotes:this.state.Notes});
      }
      handalTextArea = (e) => {
        this.setState({ Notes: e.target.value });
      } 

      UpdateNotes = () => {

        var obj =
        {
          "companyID": this.state.companyId,
          "reportType": "AccountReconciliation",
          "notes": this.state.Notes
        }
    
        this.setState({ 
          // loading: true
          loading: false
         }, () => {
          this.props.InsertUpdateGeneralReportNotes(obj)
        });
    
      }

      onClickCloseButtonDate = (e) => {
        this.setState({ editNotes: false,Notes:this.state.prevNotes });
      }
      onMarkReviewd = () => {
        this.setState({ 
            // IsReviewProcessing: true,
          //  loading: true
          loading: false
           }, () => {
          this.props.MarkAccountReconciliationReview(this.state.companyId);
        });
      }
    render() {
        return (
            <React.Fragment>
                {this.state.ShowHistory && this.state.HistoryData != null && this.state.HistoryData.length > 0 &&
          <React.Fragment>
            <Dialog
              className="comment-Top-align"
              maxWidth="sm"
              fullWidth={true}
              open={true}>
              <React.Fragment>
                <div className="Comments modal-header"><span className="log-history-title float-left">Reviewed History</span>
                  <Button className="jr-btn close-btn float-right" onClick={this.HandleClose}><ClearIcon /></Button>
                </div>
                <DialogContent>
                  {this.state.HistoryData.length == 0 ?
                    <div className="history-box">
                      <p className="history-item-text">No History Found</p>
                    </div>
                    :
                    <React.Fragment>
                      {this.state.HistoryData.map((item, i) => (
                        <div className="history-box">
                          {/* <p className="history-item-text">
                           
                          </p>
                           */}
                          <p className="history-date-text"> <strong style={{ color: "black" }}>  Last Reviewed  </strong> By <strong> {item.userName}</strong> <strong> on </strong>{item.stCreatedDate}</p>
                        </div>
                      ))}
                    </React.Fragment>
                  }
                </DialogContent>
              </React.Fragment>
            </Dialog>
          </React.Fragment>

        }
                <div className="dashboardPage accReconcilationreport">
                    <div className="page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
                        <h2 className="mb-3 mb-sm-0">{JSON.parse(localStorage.getItem('userData')) != null ? JSON.parse(localStorage.getItem('userData')).companyName : ""} | {this.state.Title}</h2>
                    </div>
                    {this.state.errorMsg && (<div className="mt-3 mb-3 alert alert-dark fade show">
                        {this.state.errorMsg}
                    </div>)}
                    {this.state.loading ? <CircularProgress /> :
                        <div className="padding-box app-wrapper account-reconcilliation">
                           
                        <div className="left-box" style={{ marginBottom: "5px" }}>

                            {!this.state.editNotes ? 
                               <div className="col-md-12">
                                <h5>Notes: {this.state.Notes}
                                <span className="" onClick={(e) => this.editNotesData()}>
                                <i className="zmdi zmdi-edit zmdi-hc-lg ml-4 pointer"></i>
                                </span>
                                </h5>
                               </div>
                              :
                               <div className="col-md-12">
                                <div className="row">
                                <div className="col-md-1" style={{marginTop:"5px"}}>
                                    <h5>Notes:</h5>
                                </div>
                                <div className="col-md-7" style={{marginLeft:"-60px"}}>
                                    <Textarea className="placheholder-color"
                                    value={this.state.Notes} name="Notes"
                                    style={{ 'lineHeight': '20px', width: '100%', border: 'none', resize: 'none', padding: '6px', paddingLeft: '10px', borderRadius: '3px', border: '1px solid #ccc', overflow: 'hidden' }}
                                    resize={"false"} placeholder=""
                                    onChange={(e) => this.handalTextArea(e)} />
                                    </div>
                                    <div className="col-md-2" style={{marginLeft:"-10px",marginTop:"4px"}}>
                                    {/* <Button variant="raised" className="graycolor jr-btn bg-teal text-white refresh-btn" onClick={() => this.UpdateNotes()} >Save</Button> */}
                                    <Button className="jr-btn close-btn" onClick={(e) => this.UpdateNotes(e)}><CheckIcon /></Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button className="jr-btn close-btn" onClick={(e) => this.onClickCloseButtonDate(e)}><ClearIcon /></Button>
                                    
                                    </div>
                                </div>
                                            </div>
                            } 
                            
                        </div>
                        <div className="col-md-12" style={{ marginBottom: "10px" }}>
                            <div  style={{display:'flex',justifyContent:'end'}}>
                                {this.state.loading === false ?
                                <Button variant="raised" className="graycolor jr-btn bg-teal text-white refresh-btn" onClick={() => this.onMarkReviewd()} >Mark as Reviewed</Button>
                                : ""}
                            </div>
                            
                        </div>
                            
                            <div className="table-responsive-material">
                                <table className="table" >
                                    <thead className="panel-bg white_header_text">
                                        {/* <tr > */}
                                            <th>Accounts</th>
                                            <th className="text-right">Balance</th>
                                            <th>Last Reconciled<br></br>Transaction</th>
                                            <th>Last Quarter<br></br>Reconciled Through</th>
                                            <th>Reconciled<br></br>Through</th>
                                            <th>Exclude From Account<br></br>Reconciliation Review</th>
                                            <th style={{width:"112px"}}>Account<br></br>Status</th>
                                            <th>Notes</th>
                                            <th>Questions &<br></br> Comments</th>
                                        {/* </tr> */}
                                    </thead>
                                    <tbody>
                                        {this.state.lst_reconData.accountlist.map((mainData, i) => (
                                            <React.Fragment key={i}>
                                                {mainData.accountType && <tr>
                                                    <td className="table-sub-title" colSpan="9">{mainData.accountType}</td>
                                                </tr>}
                                                {mainData.data.map((item, j) => (
                                                    <React.Fragment key={j}>
                                                        {
                                                            <tr onClick={(e) => this.onRowEdit(i, j, item)}>
                                                                <td>{item.accountName}</td>
                                                                <td className="text-right">${numberWithCommas(item.balance)}</td>
                                                                <td>{item.lastReconciledTransection}</td>
                                                                <td>{item.lastReconciledEnd}</td>
                                                                <td>{item.reconciledThrough}</td>
                                                                <td>
                                                                    {item.isExcludedReconciliation ?
                                                                        <input checked="checked" className="second-check-box" type="checkbox" onClick={(e) => this.onCheckboxClick(e, i, j, item)} />
                                                                        :
                                                                        <input className="second-check-box" type="checkbox" onClick={(e) => this.onCheckboxClick(e, i, j, item)} />
                                                                    }
                                                                    {/* onClick={(e) => e.stopPropagation()} */}
                                                                </td>
                                                                <td className="custome-table-border">
                                                                    <Switch className={item.isActive ? 'newswitch-box-blue' : 'newswitch-box-blue newswitch-inactive'} size="small"
                                                                        checked={item.isActive} onClick={(e) => this.accountStatusChange(e, item)} />
                                                                    <span style={{ color: '#273f4c' }} className={item.isActive ? '' : ''}>{item.isActive ? "Active" : "Inactive"}</span>
                                                                </td>
                                                                <td>{item.comments}</td>
                                                                <td>{item.question}</td>
                                                            </tr>
                                                        }
                                                        {this.state.editIndex1 === i && this.state.editIndex2 === j &&
                                                            <tr>
                                                                <td>
                                                                    Reconciled Through:<br />
                                                                    <DatePicker
                                                                        // onChange={(e) => this.handleDateChanges(e)}
                                                                        onChange={(e) => this.handleFormChange(e, 'ReconciledThrough')}
                                                                        label="Reconciled Through"
                                                                        id="date"
                                                                        name="reconciledThrough"
                                                                        margin="normal"
                                                                        maxDate={new Date()}
                                                                        // selected={this.state.selectdeDate}
                                                                        selected={this.state.editedData.ReconciledThrough}
                                                                    />
                                                                </td>
                                                                <td colSpan="3">
                                                                    Notes:
                                                                    <TextArea placeholder="Enter Notes" onChange={this.handleMessage} value={this.state.editedData.Notes} className="textarea-box" >
                                                                    </TextArea>
                                                                </td>
                                                                <td colSpan="3">
                                                                    Questions and Comments:
                                                                    <TextArea placeholder="Enter Questions" onChange={this.handleQuestion} value={this.state.editedData.question} className="textarea-box" >
                                                                    </TextArea>
                                                                </td>
                                                                <td colSpan="2" style={{ verticalAlign: "middle" }}>
                                                                    <div className="col-md-12">
                                                                        {this.state.SaveButtonEnable ?
                                                                            <Button variant="raised" className="jr-btn jr-btn-sm bg-teal text-white" onClick={() => this.onRowUpdateClick()}>SAVE</Button>
                                                                            :
                                                                            <Button variant="raised" disabled="disabled" className="jr-btn jr-btn-sm bg-teal text-white" onClick={() => this.onRowUpdateClick()}>SAVE</Button>
                                                                        }

                                                                        <Button variant="raised" className="jr-btn jr-btn-sm bg-danger text-white" onClick={() => this.onRowCancelClick()}>CANCEL</Button>
                                                                    </div>
                                                                </td>
                                                            </tr>}
                                                    </React.Fragment>

                                                ))}
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="page-heading ">
                                <div className="row">
                                    <div className="col-md-7 table-sub-title">
                                        {this.state.lastQuarterAllCheckingBanksAccount.key}
                                    </div>
                                    <div className="col-md-2 table-sub-title">
                                        <span>{this.state.lastQuarterAllCheckingBanksAccount.value}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="page-heading ">
                                <div className="row">
                                    <div className="col-md-7 table-sub-title">
                                        {this.state.lastQuarterOtherBanksAccount.key}
                                    </div>
                                    <div className="col-md-2 table-sub-title">
                                        <span>{this.state.lastQuarterOtherBanksAccount.value}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="page-heading ">
                                <div className="row">
                                    <div className="col-md-7 table-sub-title">
                                        {this.state.lastQuarterCreditCardsAccount.key}
                                    </div>
                                    <div className="col-md-2 table-sub-title">
                                        <span>{this.state.lastQuarterCreditCardsAccount.value}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="page-heading ">
                                <div className="row">
                                    <div className="col-md-7 table-sub-title">
                                        {this.state.lastQuaterAllBankAccount.key}
                                    </div>
                                    <div className="col-md-2 table-sub-title">
                                        <span>{this.state.lastQuaterAllBankAccount.value}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="page-heading ">
                                <div className="row">
                                    <div className="col-md-7 table-sub-title">
                                        {this.state.allCheckingReconThrogh.key}
                                    </div>
                                    <div className="col-md-2 table-sub-title">
                                        <span>{this.state.allCheckingReconThrogh.value}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        reconcillition: state.reconcillition.reconcillition,
        Update_reconciled: state.reconcillition.Update_reconciled,
        account_Inactive: state.reconcillition.account_Inactive,
        GetGeneralReportNotesData:state.reconcillition.GetGeneralReportNotesData,
        UpdateGeneralReportNotes:state.reconcillition.UpdateGeneralReportNotes,
        AddComman: state.QuestionCommentsData.AddComman,
        UpdateData: state.BalanceSheetData.UpdateData,
        ReconciliedAccountHistory:state.reconcillition.ReconciliedAccountHistory,
        CommonUpdate:state.reconcillition.CommonUpdate,
    };
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        GET_AccountReconciliations,
        POST_updatecustomfield,
        PUT_QuickbookAccount_UpdateAccountStatus,
        UpdateCustomField,
        GetAccountReconciliationReviewedHistory,
        MarkAccountReconciliationReview,
        GetGeneralReportNotes,
        InsertUpdateGeneralReportNotes,
        AddQuestion
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(AccountReconcilliationPage);
import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {Base64} from "./commonServices";
import { toast } from 'react-toastify';
import $ from 'jquery';

class VimeoInstructionLoader extends Component{
     constructor(props) {
        super(props)        
        let url = props.URL;
        let buttonName = props.buttonName;
        let PageName = props.PageName;
        let buttonColor = props.buttonColor+" jr-btn text-white refresh-btn";
        let IsMenuVedio = props.IsMenuVedio;
        this.state = {    
            iframeURL : url,
            IsEmbeddedModelOpen: false,
            buttonName:buttonName,
            PageName : PageName,
            buttonColor:buttonColor,
            IsMenuVedio:IsMenuVedio
        }
    }

    handleCancel = () => {
        this.CloseModel();
    };    
    
    CloseModel = () => {
        this.setState({ IsEmbeddedModelOpen: false });
    }

    OpenEmbeddedModel = () => {

        this.setState({ IsEmbeddedModelOpen: true });
    }
    
    render() {
       
        return (
                
            <React.Fragment >
                
             {this.state.IsEmbeddedModelOpen ?
                <Dialog style={{bottom:"inherit"}} className="modal-box"
                    maxWidth={false}
                    //fullWidth={true}
                    open={true}
                    onClose={this.handleCancel}
                >
                    <div className="embedded-code-box">
                        <div className="modal-header modal-header-box">
                            <DialogTitle className="embedded-title">{this.state.PageName}&nbsp;{this.state.buttonName}
                    </DialogTitle>
                            <DialogActions>
                            <a href={this.state.iframeURL} title='Show fullscreen video' style={{marginRight: 15}} target='_blank'><i class="zmdi zmdi-fullscreen-alt" ></i>&nbsp; <span><b>Show fullscreen</b></span> </a>
                            <a variant="raised" className="close-btn" style={{cursor:'pointer'}}><i class="zmdi zmdi-close" onClick={this.handleCancel}></i>
</a>
                             
                            </DialogActions>
                        </div>
                     
                        <iframe src = {this.state.iframeURL} width="800" height="400" frameborder="0" allow="autoplay; fullscreen" webkitallowfullscreen = "true" mozallowfullscreen = "true" allowfullscreen = "true"></iframe>
                        
                        </div>
                </Dialog>
                :""}
   
                <Button variant="raised" className={this.state.buttonColor} onClick={() => this.OpenEmbeddedModel()} >
                <img className='none-img' src="/vendors/play-button.png" style={{width:"30px", marginRight:"5px"}}></img>
                {this.state.IsMenuVedio &&  <img className='play-icon' src="/vendors/play-button-icon.png " ></img> }
                <span class="btn-name">{this.state.buttonName}</span> </Button>
                      
            </React.Fragment>
                
        )
    }
}


export default VimeoInstructionLoader;

import React from "react";

const Footer = () => {
        return (
            <footer className="app-footer">
                <div className="d-flex flex-row justify-content-between">
                    {/* <span> Build Number : 03-04-2020 1.0.035, Copyright Company Name &copy; 2019</span> */}
                    <span> Copyright Whitfield & Associates LLC &copy; {new Date().getFullYear()}</span>
                </div>
            </footer>
        );
    }
;

export default Footer;

import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import {updateVendor} from '../appRedux/actions/vendorAction';
import {findIndex} from "lodash";
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {uploadImage} from  "../appRedux/actions/userAction";
import { toast } from "react-toastify";


import Snackbar from '@material-ui/core/Snackbar';


class UserImage extends React.Component {

    constructor() {
        super()
        this.state = {
            showError:false,
            open: false,
            vertical: 'top',
            horizontal: 'center',
            error:'',
            files: []
        }
        this.uploadJustFile=this.uploadJustFile.bind(this);
        this.filesOnChange = this.filesOnChange.bind(this);
    }
    componentWillReceiveProps(nextProps)
    {
        
    }
    uploadJustFile(e) {
        e.preventDefault();
        let state = this.state;
        this.setState({
            ...state,
            justFileServiceResponse: 'Please wait'
        });
        if (!state.hasOwnProperty('files')) {
            this.setState({
                ...state,
                justFileServiceResponse: 'First select a file!'
            });
            return;
        }
        let form = new FormData();
        for (var index = 0; index < state.files.length; index++) {
            var element = state.files[index];
            form.append('file', element);
        }
        this.props.uploadImage(form);
        this.props.handleSave(false);
        
    }
    filesOnChange(sender) {
        let files = sender.target.files;
        let state = this.state;
        this.setState({
            ...state,
            files: files
        });
    }
        
    render() {
        const {visible,handleCancel} = this.props;
        const {firstName,lastName,email,vertical, error,horizontal, open} = this.state;
        const open1 = visible ? visible : false;;
        return (
            <Dialog
               maxWidth="sm"
              fullWidth={true}
              open={open1}
              onClose={handleCancel}
             >
             <form method="post" onSubmit={this.uploadJustFile}  autoComplete="off" style={{ padding: 20 }}>
             <DialogTitle>User Profile</DialogTitle>
             <DialogContent>
             <DialogContentText>
                 <fieldset>
                     <Grid container spacing={24} 
                       direction="row"
                       justify="flex-start"
                       alignItems="flex-start"
                      >
                        <Grid item xs>
                        <input type="file" id="case-one" onChange={this.filesOnChange} />
                        
                        </Grid>
                        <Grid item xs>
                            
                        </Grid> 
                        </Grid>
                          
                    </fieldset>
              
                </DialogContentText>
                 </DialogContent>
                <DialogActions>
                <Button type="submit" color="primary">
                  Upload
                </Button>
                <Button onClick={handleCancel} color="primary" autoFocus>
                  Cancel
                </Button>
              </DialogActions>
                </form>
               
                
            </Dialog>
        );
    }
}

   

const mapStateToProps = (state) => {
return {
    loading:state.user.loading,
    profile:state.user.profile
}
};

const mapDispatchToProps=(dispatch)=>{
    return bindActionCreators({
        uploadImage
    },dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UserImage);




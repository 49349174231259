import React from "react";
import { bindActionCreators } from "redux";
import { updateLease } from '../appRedux/actions/leaseAction';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { connect } from "react-redux";
import moment from 'moment';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


class Editlease extends React.Component {

    constructor() {
        super()
        this.state = {
            leaseID:0,
            companyID:'',
            vendorID:'',
            financeCompany:'',
            terms:'',
            lastPaymentDate:new Date(),
            monthlyPayments:'',
            automaticRenewal:'',
            securityDeposit:'',
            propertyTypeID:'',
            leaseType:'',
            comments:'',
            alertDate:new Date(),
            alertComments:'',
            leaseReviewComplete:'',
            completedBy:'',
            lastUpdatedDate:new Date(),
        }
    }
     componentWillReceiveProps(nextProps) {

        this.setState({
            leaseID:nextProps.selectedItem.leaseID,
            companyID:nextProps.selectedItem.companyID,
            vendorID:nextProps.selectedItem.vendorID,
            financeCompany:nextProps.selectedItem.financeCompany,
            terms:nextProps.selectedItem.terms,
            lastPaymentDate:nextProps.selectedItem.lastPaymentDate,//moment().format('YYYY-MM-DD'),
            monthlyPayments:nextProps.selectedItem.monthlyPayments,
            automaticRenewal:nextProps.selectedItem.automaticRenewal,
            securityDeposit:nextProps.selectedItem.securityDeposit,
            propertyTypeID:nextProps.selectedItem.propertyTypeID,
            leaseType:nextProps.selectedItem.leaseType,
            comments:nextProps.selectedItem.comments,
            alertDate:nextProps.selectedItem.alertDate,//moment().format('YYYY-MM-DD'),
            alertComments:nextProps.selectedItem.alertComments,
            leaseReviewComplete:nextProps.selectedItem.leaseReviewComplete,
            completedBy:nextProps.selectedItem.completedBy,
            lastUpdatedDate:nextProps.selectedItem.lastUpdatedDate,//moment().format('YYYY-MM-DD')
        })
    }

    handleSubmit = (e) => {
         e.preventDefault();
        let data = {
            leaseID:this.state.leaseID,
            companyID:this.state.companyID,
            vendorID:this.state.vendorID,
            financeCompany:this.state.financeCompany,
            terms:this.state.terms,
            lastPaymentDate:moment(this.state.lastPaymentDate).format('YYYY-MM-DD') ,
            monthlyPayments:Number(this.state.monthlyPayments),
            automaticRenewal:this.state.automaticRenewal,
            securityDeposit:Number(this.state.securityDeposit),
            propertyTypeID:this.state.propertyTypeID,
            leaseType:this.state.leaseType,
            comments:this.state.comments,
            alertDate: moment(this.state.alertDate).format('YYYY-MM-DD'),
            alertComments:this.state.alertComments,
            leaseReviewComplete:this.state.leaseReviewComplete,
            completedBy:this.state.completedBy,
            lastUpdatedDate: moment(this.state.lastUpdatedDate).format('YYYY-MM-DD')
        }
            this.props.updateLease(data);
            this.props.handleSave(false);

    }
    handleChange = name => event => {
      this.setState({ [name]: event.target.value });
    };

    handleFormChange = (e,target) => {        
        if(!target)
        {
        this.setState({ [e.target.name]: e.target.value })
        }
        else if(target=="alertDate")
        {
        this.setState({ alertDate: new Date(e)  })       //moment(e).format('YYYY-MM-DD')
        }
        else if(target=="lastUpdatedDate")
        {
        
        this.setState({ lastUpdatedDate: new Date(e) })       
        }
        else if(target=="lastPaymentDate")
        {
        this.setState({ lastPaymentDate: new Date(e) })       //moment(e).format('YYYY-MM-DD')
        }
    }

  handleFormCheched = name => event => {

    this.setState({ [name]: event.target.checked });
  };

  handleDelete = () => {
    let id = this.state.leaseID;

     this.props.handleDelete(id);
  }


    handleCancel = () => {
        this.props.handleCancel()
    };

    render() {
        const { visible,classes,propertyTypes,leaseTypes,vendorTypes } = this.props;
        return (
            <Dialog
                maxWidth="md"
                fullWidth={true}
                open={visible}
                onClose={this.handleCancel}
            >
              <form method="post" onSubmit={this.handleSubmit}  autoComplete="off" style={{ padding: 20 }}>
                <DialogTitle>Edit Lease</DialogTitle>
                <DialogContent>
                        <fieldset>
                            <Grid container spacing={24} >
                                <Grid item sm={4} >
                                  <InputLabel htmlFor={""}>Vendor</InputLabel>
                                    <Select
                                        name="vendorID"
                                        native
                                        label={"Vendor"}
                                        fullWidth
                                        value={this.state.vendorID}
                                        onChange={this.handleChange('vendorID')}
                                        inputProps={{
                                            name: 'vendorName',
                                            id: 'account-native-simple',
                                        }}
                                        style={{ 'paddingTop': '10px' }}
                                    >
                                     {vendorTypes.length && vendorTypes.map((value, index)=>(
                                      <option value={value.vendorTypeId} key={index}>{value.name}</option>
                                     ))}
                                    </Select>
                                </Grid>
                                 <Grid item sm={4}>
                                    <InputLabel htmlFor={""}>Lease Type</InputLabel>
                                    <Select
                                        name="leaseType"
                                        native
                                        value={this.state.leaseType}
                                        onChange={this.handleChange('leaseType')}
                                        inputProps={{
                                            name: 'propertytype',
                                            id: 'account-native-simple',
                                        }}
                                        fullWidth
                                         style={{'paddingTop': '10px' }}
                                    >
                                      {leaseTypes.length && leaseTypes.map((value, index)=>(
                                      <option value={value.leaseTypeID} key={index}>{value.type}</option>
                                     ))}

                                    </Select>
                                </Grid>
                                 <Grid item sm={4}>
                                    <InputLabel htmlFor={""}>Property Type</InputLabel>
                                    <Select
                                        name="propertyTypeID"
                                        native
                                        onChange={this.handleChange('propertyTypeID')}
                                        value={this.state.propertyTypeID}
                                        fullWidth
                                        inputProps={{
                                            name: 'propertyTypeID',
                                            id: 'account-native-simple',
                                        }}
                                        style={{'paddingTop': '10px' }}
                                    >
                                      {propertyTypes.length && propertyTypes.map((value, index)=>(
                                      <option value={value.propertyTypeID} key={index}>{value.type}</option>
                                     ))}
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid container spacing={24}>
                             <Grid item sm={3} >
                                    <TextField
                                        name="financeCompany"
                                        required={true}
                                        label={"Financing Company"}
                                        fullWidth
                                        margin="normal"
                                         onChange={this.handleFormChange}
                                        defaultValue={this.state.financeCompany}
                                    />
                                </Grid>
                          
                                <Grid item sm={3} >
                                    <TextField
                                        name="monthlyPayments"
                                        onChange={this.handleFormChange}
                                        label="Monthly Payments"
                                        fullWidth                                        
                                        required={true}
                                        margin="normal"
                                        defaultValue={this.state.monthlyPayments}
                                    />
                                </Grid>
                          
                                <Grid item sm={3}>
                                    <TextField
                                        name="securityDeposit"
                                        required={true}
                                        defaultValue={this.state.securityDeposit}
                                        onChange={this.handleFormChange}
                                        label={"Security Deposit"}
                                        fullWidth
                                        margin="normal"

                                    />
                                </Grid>

                                   <Grid item sm={3}>
                                    <TextField
                                        name="terms"
                                        onChange={this.handleFormChange}
                                        required={true}
                                        label={"Terms(Months)"}
                                        fullWidth
                                        margin="normal"
                                         defaultValue={this.state.terms}
                                    />
                                </Grid>
                                
                               
                            </Grid>
                            <Grid container spacing={24}>                               
                                <Grid item sm={3} >
                                    <TextField
                                        name="comments"
                                         defaultValue={this.state.comments}
                                        onChange={this.handleFormChange}
                                        required={true}
                                        label={"Comments"}
                                        fullWidth
                                        margin="normal"
                                    />
                                </Grid>
                                  <Grid item sm={3}>
                                  <InputLabel className="labletext">Last Review Date </InputLabel>
                                  <DatePicker                      
                                        selected = {this.state.lastUpdatedDate}
                                        onChange={(e) => this.handleFormChange(e,'lastUpdatedDate')}
                                        label="Last Review Date"
                                        name="lastUpdatedDate"
                                        id="date"
                                        required={true}
                                        margin="normal"
                                        />
                                </Grid>
                             
                                  <Grid item sm={3} >
                                  <InputLabel className="labletext">Last Payment Due Date </InputLabel>
                                  <DatePicker
                                        selected={this.state.lastPaymentDate}
                                        onChange={(e) => this.handleFormChange(e,'lastPaymentDate')}
                                        label="Last Payment Due Date"
                                        name="lastPaymentDate"
                                        id="date"
                                        required={true}
                                        margin="normal"                       
                                    />
                                </Grid>
                                <Grid item sm={3}>
                                <InputLabel className="labletext">Alert Date</InputLabel>
                                <DatePicker
                                    selected={this.state.alertDate}
                                    onChange={(e) => this.handleFormChange(e,"alertDate")}
                                    label="Alert Date"
                                    name="alertDate"
                                    id="date"
                                    margin="normal"
                                    />
                                </Grid>
                               
                            </Grid>
                            <Grid container spacing={24}>
                                <Grid item sm={3}>
                                    <TextField
                                        name="alertComments"
                                        required={true}
                                        defaultValue={this.state.alertComments}
                                        onChange={this.handleFormChange}
                                        label={"Alert Comments"}
                                        fullWidth
                                        margin="normal"
                                    />
                                </Grid>
                               <Grid item sm={3}>
                                    <TextField
                                        name="completedBy"
                                        defaultValue={this.state.completedBy}
                                        onChange={this.handleFormChange}
                                        required={true}
                                        label={"Completed by"}
                                        fullWidth
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item sm={3} style={{ 'paddingTop': '34px' }}  >
                                    <Checkbox
                                        className="label_lease"
                                        onChange={this.handleFormCheched('automaticRenewal')}
                                        checked={this.state.automaticRenewal}
                                    />
                                    <label>Automatic Renewal </label>
                                </Grid>
                                   <Grid item sm={3} style={{ 'paddingTop': '34px' }}  >
                                    <Checkbox
                                        className="label_lease"
                                        onChange={this.handleFormCheched('leaseReviewComplete')}
                                        checked={this.state.leaseReviewComplete}
                                    />
                                    <label>Lease Review </label>
                                </Grid>
                            </Grid>
                        </fieldset>
                   
                </DialogContent>
                <DialogActions>
                   
                    <Button type="submit" color="primary">
                       Update
                    </Button>
                     <Button color="primary" onClick={this.handleCancel}>
                        Cancel
                    </Button>
                        <Button onClick={this.handleDelete} color="primary">
                        Delete
                      </Button> 

                </DialogActions>
                 </form>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
     
    }
}
const mapDispatchToProps = (dispatch) => {
     return bindActionCreators({
        updateLease,
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Editlease);




import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import CircularProgress from './../../../../components/CircularProgress';
import { connect } from 'react-redux';
import { GetApproveWorkerClassifiedEmployee } from '../../../../appRedux/actions/ReportDetailAction';
import { EmployeeStatusApprove } from '../../../../appRedux/actions/LedgerAction';
import { ReportType, numberWithCommas, MaskTaxIdentifier, Getsum,LinkType,GetLastQuarterEndDate } from '../../../common/commonServices';
import moment from 'moment';
import { toast } from 'react-toastify';

class ApproveWorker extends Component {

    constructor(props) {
        super(props)

        let eDate= new Date();
        let sDate= new Date(eDate.getFullYear(),eDate.getMonth(),1);
        // if(eDate.getMonth() <3)
        // {
        //     sDate= new Date(eDate.getFullYear()-1,0,1);
        //     eDate = new Date(eDate.getFullYear()-1,11,31);
        // }
        if (eDate.getMonth() <= 2) {
            sDate.setFullYear(eDate.getFullYear() - 1, 0, 1);
            eDate = new Date(eDate.getFullYear() - 1, 11, 31);
        }
        else {
            sDate = new Date(eDate.getFullYear(), 0, 1);
            eDate = GetLastQuarterEndDate();
        }



        this.state = {
            parantData: this.props.location.state ? this.props.location.state : null,
            ReportData: [],
            companyId: 0,
            sortField: 'date',
            sortFormat: "D",
            PageNo: 1,
            PageSize: 10000,
            ColorCode: "#f0ca4c",
            IsCommentModelOpen: false,
            sDate: sDate,
            eDate: eDate,
            loading: false,
            TotalRecords: 0,            
            IsEmbeddedPage: false,                        
            isEmployeeStatusApprovedbyClient:false,
            SelectedListId:null
        }
    }

 
    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('userData'));
        this.setState({
            companyId: user.companyId,
            loading: true
        }, () => {
            this.GetReportData();
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.ApproveWorkerData !== this.props.ApproveWorkerData) {
            if (nextProps.ApproveWorkerData.transactionStatus === 0) {
                
                //if (nextProps.ApproveWorkerData.returnObject != null) {
                    this.setState({ loading: false, ReportData: nextProps.ApproveWorkerData.returnObject })
                // }
                // else {
                //     this.setState({ loading: false }, () => { toast.error(`No Data Found!!!`); });
                // }
            }
            else {
                this.setState({ loading: false }, () => { toast.error(`${nextProps.ApproveWorkerData.resultMsg}`); });
            }
        }

        if (nextProps.CommonUpdate !== this.props.CommonUpdate) {
            if (nextProps.CommonUpdate.transactionStatus === 0) {
              let data = this.state.ReportData;
              let obj = this.state.editedData;
              let selectedListId = this.state.SelectedListId;
              data.map((item, index) => {                  
                if (item.listID === selectedListId) {
                  item.isEmployeeStatusApprovedbyClient =this.state.isEmployeeStatusApprovedbyClient
                }
              })
      
              this.setState({ loading: false, SelectedListId: null, reportData: data }, () => { toast.success("Request Process Successfully"); });
            }
            else {
              this.setState({ loading: false }, () => { toast.error(`${nextProps.CommonUpdate.resultMsg}`); });
            }      
          }
    }


    GetReportData(IsRefresh = false) {
        let state = this.state;
        let body = {
            companyID: state.companyId,
            reportType: ReportType.ApproveWorker,
            fromDate: moment(state.sDate).format("MM/DD/YYYY"),
            toDate: moment(state.eDate).format("MM/DD/YYYY"),
            sortField: state.sortField,
            sortFormat: state.sortFormat,
            pageNumber: state.PageNo,
            pageSize: state.PageSize,
            needUpdatQBORecords: IsRefresh
        };

        this.props.GetApproveWorkerClassifiedEmployee(body);
    }

    onCheckboxClick = (e,data) =>
    {
        let body ={
            "companyID": this.state.companyId,
            "isEmployeeStatusApprovedbyClient":  e.target.checked,          
            "listID": data.listID
          };
          
        this.setState({isEmployeeStatusApprovedbyClient:e.target.checked,loading:true,SelectedListId:data.listID}, ()=> { this.props.EmployeeStatusApprove(body)});
    }

    render() {
        return (
            <div className="dashboardPage">
                <div className="page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
                    <h2 className="mb-3 mb-sm-0">{JSON.parse(localStorage.getItem('userData')) != null ? JSON.parse(localStorage.getItem('userData')).companyName : ""} | { LinkType.ApproveWorker}</h2>
                </div>
                <div className="padding-box app-wrapper compliance-review">
                    {this.state.loading ? <CircularProgress></CircularProgress>
                        :
                        <React.Fragment>
                            {this.state.ReportData != null && this.state.ReportData.map((item, i) => (
                                <div>
                                    <div className="complince-review-box">
                                        <div className="left-box">
                                            <h5>{item.vendor}</h5>
                                        </div>
                                        <div className="center-box">
                                            <p>Tax ID Number:<strong>{MaskTaxIdentifier(item.taxIdentifier)}</strong> </p>
                                        </div>
                                        <div className="right-box">
                                            <div className="row">
                                                {/* <div className='' >
                                                    <span><strong>Type:</strong> </span>
                                                    <select className="select-box" name={item.listID} onChange={(e) => this.ChangeType(e, item.listID)} style={{ background: "white", marginTop: "-10px", marginRight: "10px" }} >
                                                        <option value="" >Select</option>
                                                        {this.state.vendorTypeData.map(type => (
                                                            <option value={type.vendorTypeId} selected={type.vendorTypeId === item.vendorTypeId ? "selected" : ""}>
                                                                {type.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div> */}
                                                <div className="d-flex">
                                                    <p>Employee Status Approve </p>
                                                    <label className="container101">
                                                        {item.isEmployeeStatusApprovedbyClient ?
                                                            <input className="second-check-box" name={item.listID} checked="checked" type="checkbox" onChange={(e) => this.onCheckboxClick(e, item)} />
                                                            :
                                                            <input className="second-check-box" type="checkbox" name={item.listID} onChange={(e) => this.onCheckboxClick(e, item)} />
                                                        }
                                                        <span className="checkmark101 green_CheckMark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className=""></div>
                                        <table className="table">
                                            <thead>
                                                <tr className="header-bg-color" style={{backgroundColor:"#f0ca4c"}}>
                                                    <th>Date</th>
                                                    <th> Type </th>
                                                    <th> Number </th>
                                                    <th> Memo/Description </th>
                                                    <th> Account </th>
                                                    <th> Amount </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {item.reportData != null & item.reportData.length > 0 ?
                                                    <React.Fragment key={i}>
                                                        {item.reportData != null && item.reportData.map((data, index) => (
                                                            <tr onClick={() => this.onLinkClick(data)}>
                                                                <td> {data.date}</td>
                                                                <td>{data.transactionType}</td>
                                                                <td >{data.num}</td>
                                                                <td>{data.memoOrDescription}</td>
                                                                <td>{data.accountValue}</td>
                                                                <td className="text-right pr-2">${numberWithCommas(data.amount)}</td>
                                                            </tr>
                                                        ))}
                                                        <tr>
                                                            <td colSpan="4"></td>
                                                            <td><h2 className="total-payments-text">Total Payments</h2></td>
                                                            <td className="text-right pr-2"><h1 className="total-text"> ${Getsum(item.reportData, "amount")} </h1></td>
                                                        </tr>
                                                    </React.Fragment>
                                                    :
                                                    <tr>
                                                        <td className="text-center" colSpan="6">
                                                        No issues here! Keep up the good work!
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            ))}
                             {(this.state.ReportData == null || this.state.ReportData.length === 0) &&
                                // <div>No data found</div>
                                <div className="">
                                        <div className=""></div>
                                        <table className="table">
                                            <thead>
                                                <tr className="header-bg-color" style={{backgroundColor:"#f0ca4c"}}>
                                                    <th>Date</th>
                                                    <th> Type </th>
                                                    <th> Number </th>
                                                    <th> Memo/Description </th>
                                                    <th> Account </th>
                                                    <th> Amount </th>
                                                </tr>
                                            </thead>
                                        <tbody>
                                                    <React.Fragment>
                                                        <tr>
                                                        <td className="text-center" colSpan="6">
                                                        No issues here! Keep up the good work!
                                                        </td>
                                                    </tr>
                                                    </React.Fragment>
                                                   

                                            </tbody>
                                        </table>
                                    </div>
                            }
                        </React.Fragment>
                    }

                </div>
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        ApproveWorkerData: state.ReportData.ApproveWorkerData,
        CommonUpdate: state.ledgers.CommonUpdate,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        GetApproveWorkerClassifiedEmployee,
        EmployeeStatusApprove
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(ApproveWorker);
import { GET_TAXABLE_WAGES } from '../actions/TaxableWagesComputionAction';

const initial_state = {
    taxableWagesData: [],
    reload: false
};

export default function taxableWagesComputionReducer(state = initial_state, action) {
    switch (action.type) {
        case GET_TAXABLE_WAGES:
            return {
                ...state,
                taxableWagesData: action.payload, reload: false
            };
        case 'RELOAD':
            return { ...state, reload: true };
        default:
            return state;
    }
}